import React, { useState, useEffect } from "react";
import {
  Card,
  Form,
  Button,
  Row,
  Col,
  Radio,
  Divider,
  message,
  Collapse,
  notification,
} from "antd";
import ImgCrop from "antd-img-crop";
import { Upload } from "antd";

import FloatInput from "../../../../providers/FloatInput";
import { faSave, faUserFriends } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import ComponentHeader from "../../Components/ComponentHeader";
import ModalProfileChangePassword from "../../Components/ModalProfileChangePassword";

import FloatSelect from "../../../../providers/FloatSelect";
import { GET, GETMANUAL, POST } from "../../../../providers/useAxiosQuery";
import companyInfo from "../../../../providers/companyInfo";
import optionCountryCodes from "../../../../providers/optionCountryCodes";
import optionStateCodesUnitedState from "../../../../providers/optionStateCodesUnitedState";
import optionStateCodesCanada from "../../../../providers/optionStateCodesCanada";
import FloatInputPasswordStrength from "../../../../providers/FloatInputPasswordStrength";
import ModalProfileChangePasswordAssessor from "../../Components/ModalProfileChangePasswordAssessor";

// import { useHistory } from "react-router-dom";

export default function PageAssessorAddEdit(props) {
  // const history = useHistory();
  const { match, permission } = props;
  const [form] = Form.useForm();
  const { Panel } = Collapse;
  // const userdata = companyInfo().userData;
  // const token = companyInfo().token;
  // const encryptor = companyInfo().encryptor;

  //   console.log("match", match.params.id);

  const validator = {
    require: {
      required: true,
      message: "This field is required.",
    },
  };

  const [fileList, setFileList] = useState([]);
  const [radioData, setRadioData] = useState(1);
  const [imageCrop, setImageCrop] = useState({
    width: 1,
    height: 1,
  });

  const handleResize = (val) => {
    console.log("val", val.target.value);
    setRadioData(val.target.value);
    if (val.target.value === 1) {
      setImageCrop({
        width: 1,
        height: 1,
      });
    } else if (val.target.value === 2) {
      setImageCrop({
        width: 3.9,
        height: 2.6,
      });
    } else if (val.target.value === 3) {
      setImageCrop({
        width: 1,
        height: 1.5,
      });
    }
  };

  const onChange = ({ fileList: newFileList }) => {
    var _file = newFileList;
    // console.log(_file);
    if (_file.length !== 0) {
      _file[0].status = "done";
      setFileList(_file);
      form.submit();
    } else {
      setFileList([]);
    }
  };

  const onPreview = async (file) => {
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow.document.write(image.outerHTML);
  };

  const beforeUpload = (file) => {
    const isJpgOrPng =
      file.type === "image/jpeg" ||
      file.type === "image/png" ||
      file.type === "image/gif" ||
      file.type === "image/jpg";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG, PNG, GIF, JPEG file!");
      setFileList([]);
      return;
    }
    const isLt2M = file.size / 102400 / 102400 < 10;
    if (!isLt2M) {
      message.error("Image must smaller than 10MB!");
      setFileList([]);
      return;
    }
    return isJpgOrPng && isLt2M;
  };

  const onFinish = (values) => {
    // console.log("onFinish", values);
    const data = new FormData();
    data.append("id", values.id);
    data.append("username", values.username);
    data.append("email", values.email);
    data.append("password", values.password ? values.password : "");
    data.append("role", values.role);

    // address
    data.append("address1", values.address1);
    data.append("address2", values.address2 ? values.address2 : "");
    data.append("country", values.country);
    data.append("state", values.state);
    data.append("city", values.city);
    data.append("zip_code", values.zip_code);

    if (fileList.length !== 0) {
      if (fileList[0].uid !== "-1") {
        data.append(
          "profile_image",
          fileList[0].originFileObj,
          fileList[0].name
        );
      }
    }

    mudateAdd(data, {
      onSuccess: (res) => {
        if (res.success) {
          notification.success({
            message: "Success",
            description: "Successfully submitted.",
          });
          // history.push("/assessors");
          setProfileData(res.data);
        }
      },
    });
  };

  const { mutate: mudateAdd, isLoading: isLoadingAdd } = POST(
    "api/v1/add_assessor",
    "add_assessor"
  );

  const country = optionCountryCodes;
  const stateUS = optionStateCodesUnitedState();
  const stateCA = optionStateCodesCanada();

  const [stateLabel, setStateLabel] = useState("State");
  const [optionState, setOptionState] = useState(stateUS);
  const [zipLabel, setZipLabel] = useState("Zip Code");
  const [optionZip, setOptionZip] = useState(/(^\d{5}$)|(^\d{5}[ -]\d{4}$)/);

  const [countryOption, setCountryOption] = useState([]);
  const [citOption, setCityyOption] = useState([]);

  const { data: data_get_manager_state_area } = GET(
    "api/v1/get_manager_state_area2",
    "get_manager_state_area",
    (res) => {
      if (res.success) {
        // console.log("get_manager_state_area", res);
        setCountryOption(res.countries);
        // setOptionState(res.states);
      }
    }
  );

  useEffect(() => {
    if (match.params.id) {
      refetchAssessor();
    }
  }, [match]);

  const [isChangePasswordVisible, setIsChangePasswordVisible] = useState(false);
  const [profileData, setProfileData] = useState([]);
  const { refetch: refetchAssessor } = GETMANUAL(
    `api/v1/users/${match.params.id}`,
    "assessor",
    (res) => {
      if (res.success) {
        // console.log("update_profile", res.data);
        setProfileData(res.data);
        form.setFieldsValue({
          username: res.data.username,
          role: res.data.role,
          email: res.data.email,
          id: res.data.id,
        });
        // if (res.data.phone_number === null || res.data.phone_number === "") {
        //   form.resetFields(["phone_number"]);
        // }

        if (res.data.user_address.length > 0) {
          form.setFieldsValue({
            address1: res.data.user_address[0].address1,
            address2: res.data.user_address[0].address2,
            country: res.data.user_address[0].country,
            state: res.data.user_address[0].state,
            city: res.data.user_address[0].city,
            zip_code: res.data.user_address[0].zip_code,
          });

          if (
            res.data.user_address[0].address2 === null ||
            res.data.user_address[0].address2 === ""
          ) {
            form.resetFields(["address2"]);
          }

          if (res.data.user_address[0].country === "United States") {
            setStateLabel("State");
            setZipLabel("Zip Code");
            setOptionZip(/(^\d{5}$)|(^\d{5}[ -]\d{4}$)/);

            let option_states = [];
            data_get_manager_state_area &&
              data_get_manager_state_area.states.map((state, key) => {
                let find = stateUS.find((p) => p.value == state.value);
                if (find) {
                  option_states.push(find);
                }
              });
            setOptionState(option_states);

            let get_city =
              option_states &&
              option_states.filter(
                (item) => res.data.user_address[0].state === item.value
              );
            setCityyOption(get_city && get_city[0]["json"]);
          } else if (res.data.user_address[0].country === "Canada") {
            setStateLabel("County");
            setZipLabel("Postal Code");
            setOptionZip(/^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/);

            let option_states = [];
            data_get_manager_state_area &&
              data_get_manager_state_area.states.map((state, key) => {
                let find = stateCA.find((p) => p.value == state.value);
                if (find) {
                  option_states.push(find);
                }
              });
            setOptionState(option_states);

            let get_city = option_states.filter(
              (item) => res.data.user_address[0].state === item.value
            );
            setCityyOption(get_city && get_city[0]["json"]);
          }
        }

        let image_type = res.data.profile_image
          ? res.data.profile_image.split("/")
          : "";
        if (image_type[0] !== undefined) {
          image_type =
            image_type === "https:"
              ? res.data.profile_image
              : companyInfo().apiUrl + res.data.profile_image;

          setFileList([
            {
              uid: "-1",
              name: "image.png",
              status: "done",
              url: image_type,
            },
          ]);
        } else {
          setFileList([]);
          image_type = "";
        }
      }
    }
  );

  const handleCountry = (val, opt) => {
    if (val === "United States") {
      setStateLabel("State");
      setZipLabel("Zip Code");
      setOptionState(stateUS);
      setOptionZip(/(^\d{5}$)|(^\d{5}[ -]\d{4}$)/);
    } else if (val === "Canada") {
      setStateLabel("County");
      setZipLabel("Postal Code");
      setOptionState(stateCA);
      setOptionZip(/^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/);
    } else {
      setStateLabel("State");
      setZipLabel("Zip Code");
      setOptionState(stateUS);
      setOptionZip(/(^\d{5}$)|(^\d{5}[ -]\d{4}$)/);
    }

    form.resetFields(["state", "zip_code"]);
  };

  const onBlurInput = (value, field) => {
    // console.log("value", value);
    // console.log("profileData", field);
    // console.log("profileData", profileData);
    if (match.params.id) {
      if (profileData[field] !== value) {
        form.submit();
      }
    }
  };

  const onBlurInputAddress = (value, field) => {
    // console.log("value", value);
    // console.log("profileData", field);
    // console.log("profileData", profileData);
    if (match.params.id) {
      if (profileData && profileData.user_address.length > 0) {
        if (profileData.user_address[0][field] !== value) {
          form.submit();
        }
      } else {
        form.submit();
      }
    }
  };

  // const [isChangePasswordVisible, setIsChangePasswordVisible] = useState(false);

  return (
    <>
      <ComponentHeader
        title={permission}
        sub_title="ADD / EDIT"
        icon={faUserFriends}
      />

      {/* <ModalProfileChangePassword
        toggleModalPreview={isChangePasswordVisible}
        setToggleModalPreview={setIsChangePasswordVisible}
        profileData={profileData}
      /> */}
      <Card className="p-b-lg" id="PageAssessorAddEdit">
        <Form
          name="assessment-form"
          form={form}
          onFinish={onFinish}
          initialValues={{
            role: "Assessor",
          }}
          autoComplete="off"
          // loading={isLoadingUpdate || isLoadingGet}
        >
          <Row gutter={12}>
            <Col xs={24} sm={24} md={16}>
              <Collapse
                className="main-1-collapse"
                expandIcon={({ isActive }) =>
                  isActive ? (
                    <span
                      className="ant-menu-submenu-arrow"
                      style={{ color: "#FFF", transform: "rotate(270deg)" }}
                    ></span>
                  ) : (
                    <span
                      className="ant-menu-submenu-arrow"
                      style={{ color: "#FFF", transform: "rotate(90deg)" }}
                    ></span>
                  )
                }
                defaultActiveKey={["1"]}
                expandIconPosition="start"
              >
                <Panel
                  header="ASSESSOR'S INFORMATION"
                  key="1"
                  className="accordion bg-darkgray-form"
                >
                  <Row gutter={24}>
                    <Col xs={24} sm={24} md={24}>
                      <Form.Item
                        name="id"
                        hasFeedback
                        style={{ display: "none" }}
                      >
                        <FloatInput label="id" placeholder="id" />
                      </Form.Item>
                      <Form.Item
                        name="username"
                        rules={[validator.require]}
                        hasFeedback
                      >
                        <FloatInput
                          label="Username"
                          placeholder="Username"
                          onBlurInput={(e) => onBlurInput(e, "username")}
                        />
                      </Form.Item>
                    </Col>

                    <Col xs={24} sm={24} md={24}>
                      <Form.Item
                        name="email"
                        rules={[validator.require]}
                        hasFeedback
                      >
                        <FloatInput
                          label="Email"
                          placeholder="Email"
                          onBlurInput={(e) => onBlurInput(e, "email")}
                          // disabled={profileData ? true : false}
                        />
                      </Form.Item>
                    </Col>

                    <Col xs={24} sm={24} md={24}>
                      <Button
                        type="link"
                        className="btn-link-main-3 btn-p-0"
                        onClick={(e) => setIsChangePasswordVisible(true)}
                      >
                        Change Password
                      </Button>
                    </Col>
                  </Row>

                  {/* {!profileData && ( */}
                  {/* <Row gutter={24}>
                    <Col xs={24} sm={24} md={24}>
                      <Form.Item
                        name="password"
                        rules={[validator.require]}
                        hasFeedback
                      >
                        <FloatInputPasswordStrength
                          label="Password"
                          placeholder="Password"
                          onBlurInput={(e) => onBlurInput(e, "password")}
                        />
                      </Form.Item>
                    </Col>
                  </Row> */}
                  {/* )} */}
                </Panel>
              </Collapse>

              <br />
              <Collapse
                className="main-1-collapse"
                expandIcon={({ isActive }) =>
                  isActive ? (
                    <span
                      className="ant-menu-submenu-arrow"
                      style={{ color: "#FFF", transform: "rotate(270deg)" }}
                    ></span>
                  ) : (
                    <span
                      className="ant-menu-submenu-arrow"
                      style={{ color: "#FFF", transform: "rotate(90deg)" }}
                    ></span>
                  )
                }
                defaultActiveKey={["1"]}
                expandIconPosition="start"
              >
                <Panel
                  header="ASSESSOR'S ADDRESS"
                  key="1"
                  className="accordion bg-darkgray-form"
                >
                  <Row gutter={24}>
                    <Col xs={24} sm={24} md={12}>
                      <Form.Item
                        name="role"
                        rules={[validator.require]}
                        hasFeedback
                      >
                        <FloatInput
                          label="Role"
                          placeholder="Role"
                          disabled={true}
                        />
                      </Form.Item>
                    </Col>

                    <Col xs={24} sm={24} md={12}>
                      <Form.Item
                        name="country"
                        className="form-select-error"
                        rules={[validator.require]}
                        hasFeedback
                      >
                        <FloatSelect
                          label="Country"
                          placeholder="Country"
                          options={countryOption}
                          onChange={handleCountry}
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row gutter={24}>
                    <Col xs={24} sm={24} md={24}>
                      <Form.Item
                        name="address1"
                        rules={[validator.require]}
                        hasFeedback
                      >
                        <FloatInput
                          label="Address 1"
                          placeholder="Address 1"
                          onBlurInput={(e) => onBlurInputAddress(e, "address1")}
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row gutter={24}>
                    <Col xs={24} sm={24} md={24}>
                      <Form.Item name="address2" hasFeedback>
                        <FloatInput
                          label="Address 2"
                          placeholder="Address 2"
                          onBlurInput={(e) => onBlurInputAddress(e, "address2")}
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row gutter={24}>
                    <Col xs={24} sm={24} md={8}>
                      <Form.Item
                        className="form-select-error"
                        name="state"
                        rules={[validator.require]}
                        hasFeedback
                      >
                        <FloatSelect
                          label={stateLabel}
                          placeholder={stateLabel}
                          options={optionState}
                          onBlurInput={(e) => onBlurInputAddress(e, "state")}
                        />
                      </Form.Item>
                    </Col>

                    <Col xs={24} sm={24} md={8}>
                      <Form.Item
                        className="form-select-error"
                        name="city"
                        rules={[validator.require]}
                        hasFeedback
                      >
                        <FloatSelect
                          label="City"
                          placeholder="City"
                          options={citOption}
                          onBlurInput={(e) => onBlurInputAddress(e, "city")}
                        />
                      </Form.Item>
                    </Col>

                    <Col xs={24} sm={24} md={8}>
                      <Form.Item
                        name="zip_code"
                        rules={[
                          {
                            required: true,
                            message: "This field field is required.",
                          },
                          {
                            pattern: optionZip,
                            message: "Invalid " + zipLabel,
                          },
                        ]}
                        hasFeedback
                      >
                        <FloatInput
                          label={zipLabel}
                          placeholder={zipLabel}
                          onBlurInput={(e) => onBlurInputAddress(e, "zip_code")}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Panel>
              </Collapse>
            </Col>

            <Col xs={24} sm={24} md={8}>
              <Collapse
                className="main-1-collapse"
                expandIcon={({ isActive }) =>
                  isActive ? (
                    <span
                      className="ant-menu-submenu-arrow"
                      style={{ color: "#FFF", transform: "rotate(270deg)" }}
                    ></span>
                  ) : (
                    <span
                      className="ant-menu-submenu-arrow"
                      style={{ color: "#FFF", transform: "rotate(90deg)" }}
                    ></span>
                  )
                }
                defaultActiveKey={["1"]}
                expandIconPosition="start"
              >
                <Panel
                  // header="USER'S INFORMATION"
                  header={
                    match.url === "/assessors/edit"
                      ? "ASSESSOR'S PHOTO"
                      : "USER'S PHOTO"
                  }
                  key="1"
                  className="accordion bg-darkgray-form"
                >
                  <label className="font-red">
                    <b>
                      Photo upload & cropping: select your image orientation
                    </b>
                  </label>
                  <br />
                  <Radio.Group onChange={handleResize} value={radioData}>
                    <Radio value={1}>Square</Radio>
                    <Radio value={2}>Rectangle</Radio>
                    <Radio value={3}>Portrait</Radio>
                  </Radio.Group>
                  <Divider />
                  <div style={{ display: "flex" }}>
                    <ImgCrop rotate aspect={imageCrop.width / imageCrop.height}>
                      <Upload
                        // action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                        listType="picture-card"
                        style={{ width: "200px" }}
                        maxCount={1}
                        action={false}
                        customRequest={false}
                        fileList={fileList}
                        onChange={onChange}
                        onPreview={onPreview}
                        beforeUpload={beforeUpload}
                        showUploadList={{
                          showPreviewIcon: false,
                        }}
                        className="profilePhoto"
                      >
                        {fileList.length < 1 && "+ Upload"}
                      </Upload>
                    </ImgCrop>
                  </div>
                </Panel>
              </Collapse>
            </Col>
          </Row>

          {match.params.id ? (
            <></>
          ) : (
            <>
              <Row gutter={12}>
                <Col xs={24} sm={24} md={8}>
                  <br />
                  <Button
                    htmlType="submit"
                    className="btn-main-outline-2"
                    size="large"
                    loading={isLoadingAdd}
                  >
                    <FontAwesomeIcon icon={faSave} /> &nbsp; SAVE ALL CHANGES
                  </Button>
                </Col>
              </Row>
            </>
          )}
        </Form>
      </Card>

      <ModalProfileChangePasswordAssessor
        toggleModalPreview={isChangePasswordVisible}
        setToggleModalPreview={setIsChangePasswordVisible}
        profileData={profileData}
      />
    </>
  );
}
