import React from "react";
import { Link } from "react-router-dom";
import { Menu, Typography } from "antd";
import {
  faAlarmClock,
  faMapMarkerPlus,
  faSearch,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const menuLeft = (
  <>
    <div className="ant-space-icon">
      <Link to="/user/search-athletes">
        <FontAwesomeIcon icon={faSearch} />
        <Typography.Text className="ant-typography-text">
          Search <br /> Users
        </Typography.Text>
      </Link>
    </div>

    <div className="ant-space-icon">
      <Link to="/pending-assessments">
        <FontAwesomeIcon icon={faAlarmClock} />
        <Typography.Text className="ant-typography-text">
          Pending <br /> Assessments
        </Typography.Text>
      </Link>
    </div>

    <div className="ant-space-icon">
      <Link to="/organization/add-new">
        <FontAwesomeIcon icon={faMapMarkerPlus} />
        <Typography.Text className="ant-typography-text">
          Add New <br /> Organization
        </Typography.Text>
      </Link>
    </div>
  </>
);

export const dropDownMenuLeft = (
  <Menu>
    <Menu.Item
      key="/user/search-athletes"
      icon={<FontAwesomeIcon icon={faSearch} />}
    >
      <Link to="/user/search-athletes">Search Athletes</Link>
    </Menu.Item>
    <Menu.Item
      key="/pending-assessments"
      icon={<FontAwesomeIcon icon={faAlarmClock} />}
    >
      <Link to="/pending-assessments">Pending Assessments</Link>
    </Menu.Item>
    <Menu.Item
      key="/organization/add-new"
      icon={<FontAwesomeIcon icon={faMapMarkerPlus} />}
    >
      <Link to="/organization/add-new">Add New Organization</Link>
    </Menu.Item>
  </Menu>
);
