import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Table,
  Space,
  Button,
  Modal,
  Form,
  notification,
  // Divider,
  Popconfirm,
} from "antd";
// import ComponentFaqs from "../Components/ComponentFaqs";
import {
  PlusOutlined,
  EditOutlined,
  DeleteOutlined,
  // DragOutlined,
} from "@ant-design/icons";
import $ from "jquery";
// import FloatInputSearch from "../../../../../providers/FloatInputSearch";
import { GET, POST, DELETE } from "../../../../../providers/useAxiosQuery";
// import { useHistory } from "react-router-dom";
import FloatInput from "../../../../../providers/FloatInput";
import FloatTextArea from "../../../../../providers/FloatTextArea";
import FloatInputRate from "../../../../../providers/FloatInputRate";
import FloatSelect from "../../../../../providers/FloatSelect";
// import dragula from "dragula";
// import "dragula/dist/dragula.css";

// import { useDrag } from "react-dnd";
// import { HTML5Backend } from "react-dnd-html5-backend";
// import update from "immutability-helper";

import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from "react-sortable-hoc";
import { MenuOutlined } from "@ant-design/icons";
import { arrayMoveImmutable } from "array-move";
import {
  TableGlobalSearch,
  TablePageSize,
  TablePagination,
  TableShowingEntries,
} from "../../../Components/ComponentTableFilter";
// import { log } from "@antv/g2plot/lib/utils";

const DragHandle = SortableHandle(() => (
  <MenuOutlined style={{ cursor: "grab", color: "#999" }} />
));

const SortableItem = SortableElement((props) => <tr {...props} />);
const SortableBody = SortableContainer((props) => <tbody {...props} />);

export default function TableAccountTypesPlan({ id }) {
  const [form] = Form.useForm();
  const [tableTotal, setTableTotal] = useState(0);
  const [dataTableInfo, setDataTableInfo] = useState({
    filter_value: "",
    state: "",
    page: 1,
    page_size: "50",
    column: "index",
    order: "asc",
    account_type_id: id,
  });
  const [data, setData] = useState([]);
  const {
    isLoading: isLoadingGetProfiles,
    isFetching: isFetchingTable,
    refetch: refetchGetProfiles,
  } = GET(
    `api/v1/account_plan?${$.param(dataTableInfo)}`,
    "account_plan_lists",
    (res) => {
      if (res.success) {
        let arr = [];
        res.data.data.map((row, key) => {
          arr.push({
            key: row.id,
            index: key,
            account_type_id: row.account_type_id,
            amount: row.amount,
            description: row.description,
            id: row.id,
            plan: row.plan,
            type: row.type,
            up_to: row.up_to,
            updated_at: row.updated_at,
            stripe_price_id: row.stripe_price_id,
          });
          return "";
        });
        setData(arr);
        setTableTotal(res.data.total);
        return "";
      }
    }
  );

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setDataTableInfo({
        ...dataTableInfo,
        account_type_id: id,
      });
    });
    return () => {
      clearTimeout(timeoutId);
    };
  }, [id]);

  const handleTableChange = (pagination, filters, sorter) => {
    setDataTableInfo({
      ...dataTableInfo,
      column: sorter.columnKey,
      order: sorter.order ? sorter.order.replace("end", "") : null,
      // page: 1,
      // page_size: "50",
    });
  };

  const [searchText, setSearchText] = useState("");
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setDataTableInfo({
        ...dataTableInfo,
        filter_value: searchText,
        page: 1,
      });
    }, 1500);
    return () => {
      clearTimeout(timeoutId);
    };
  }, [searchText]);

  useEffect(() => {
    refetchGetProfiles();
    return () => {};
  }, [dataTableInfo]);

  const validator = {
    require: {
      required: true,
      message: "Required",
    },
  };

  const handleEdit = (record) => {
    form.setFieldsValue({
      id: record.id,
      plan: record.plan,
      description: record.description,
      amount: record.amount,
      up_to: record.up_to,
      type: record.type,
      stripe_price_id: record.stripe_price_id,
    });
    setModal(true);
  };

  const handleDelete = (record) => {
    let data = {
      id: record.id,
    };

    mutateDelete(data, {
      onSuccess: (res) => {
        if (res.success) {
          refetchGetProfiles();
          notification.success({
            message: "Success",
            description: "Succesfully Deleted",
          });
        }
      },
    });
  };

  const { mutate: mutateDelete, isLoading: isLoadingDelete } = DELETE(
    "api/v1/account_plan",
    "account_plan_delete"
  );

  const [modal, setModal] = useState(false);
  const onFinish = (value) => {
    let data = {
      ...value,
      account_type_id: id,
    };
    console.log("onFinish", data);
    mutateAccountType(data, {
      onSuccess: (res) => {
        if (res.success) {
          refetchGetProfiles();
          notification.success({
            message: "Success",
            description: "Succesfully Submitted",
          });
          form.resetFields();
          setModal(false);
        }
      },
    });
  };

  const { mutate: mutateAccountType, isLoading: isLoadingAccountType } = POST(
    "api/v1/account_plan",
    "account_plan_create"
  );

  const onSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      const newData = arrayMoveImmutable(
        [].concat(data),
        oldIndex,
        newIndex
      ).filter((el) => !!el);
      console.log("Sorted items: ", newData);
      let data_sort = { sorted_data: JSON.stringify(newData) };
      mutateSort(data_sort, {
        onSuccess: (res) => {
          if (res.success) {
            console.log(res);
            notification.success({
              message: "Success",
              description: "Succesfully Save",
            });
          }
        },
      });
      setData(newData);
    }
  };

  const { mutate: mutateSort } = POST("api/v1/plan_sort", "plan_sort");

  const DraggableContainer = (props) => (
    <SortableBody
      useDragHandle
      disableAutoscroll
      helperClass="row-dragging"
      onSortEnd={onSortEnd}
      {...props}
    />
  );

  const DraggableBodyRow = ({ className, style, ...restProps }) => {
    const index = data.findIndex((x) => x.index === restProps["data-row-key"]);
    return <SortableItem index={index} {...restProps} />;
  };

  const columns = [
    {
      title: "Sort",
      dataIndex: "sort",
      width: 30,
      className: "drag-visible",
      render: () => <DragHandle />,
    },
    {
      title: "Plan",
      dataIndex: "plan",
      key: "plan",
      className: "drag-visible",
      sorter: true,
      width: "200px",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      className: "drag-visible",
      sorter: true,
      width: "250px",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      className: "drag-visible",
      sorter: true,
      width: "150px",
    },
    // {
    //   title: "Up To",
    //   dataIndex: "up_to",
    //   key: "up_to",
    //   className: "drag-visible",
    //   sorter: true,
    //   width: "150px",
    // },
    // {
    //   title: "Type",
    //   dataIndex: "type",
    //   key: "type",
    //   className: "drag-visible",
    //   sorter: true,
    //   width: "150px",
    // },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      className: "drag-visible",
      width: "150px",
      render: (text, record) => {
        return (
          <Space>
            <Button
              size="small"
              className="btn-warning-outline"
              icon={<EditOutlined />}
              onClick={(e) => handleEdit(record)}
            >
              Edit
            </Button>
            <Popconfirm
              title="Are you sure to delete this Plan?"
              onConfirm={(e) => handleDelete(record)}
              // onCancel={cancel}
              okText="Yes"
              cancelText="No"
            >
              <Button
                size="small"
                className="btn-danger-outline"
                icon={<DeleteOutlined />}
                loading={isLoadingDelete}
                // onClick={(e) => handleDelete(record)}
              >
                Delete
              </Button>
            </Popconfirm>
          </Space>
        );
      },
    },
  ];

  return (
    <>
      <Button
        className="btn-main-outline-2 m-t-sm"
        icon={<PlusOutlined />}
        onClick={(e) => {
          setModal(true);
        }}
      >
        Add Plan
      </Button>
      <br />
      <br />
      <Row gutter={24} className="search-table" align="bottom">
        <Col xs={24} sm={24} md={16} lg={16} xl={18} className="m-b-sm">
          <Space>
            <TablePageSize
              paginationFilter={dataTableInfo}
              setPaginationFilter={setDataTableInfo}
            />
            <br />
          </Space>
        </Col>
        <Col xs={24} sm={24} md={8} lg={8} xl={6}>
          <TableGlobalSearch
            paginationFilter={searchText}
            setPaginationFilter={setSearchText}
          />
          <br />
        </Col>
      </Row>
      <Row gutter={24}>
        <Col xs={24} sm={24} md={24} className="m-t-sm m-b-sm">
          <div className="table-responsive">
            <Table
              size="small"
              // rowKey={(record) => record.id}
              rowKey="index"
              className="ant-table-default ant-table-striped"
              loading={isLoadingGetProfiles || isFetchingTable}
              dataSource={data}
              columns={columns}
              pagination={false}
              onChange={handleTableChange}
              components={{
                body: {
                  wrapper: DraggableContainer,
                  row: DraggableBodyRow,
                },
              }}
              scroll={{ x: "max-content" }}
            />
          </div>
        </Col>
      </Row>
      <Row gutter={24} className="m-t-sm m-b-sm">
        <Col xs={24} sm={24} md={24}>
          <Space className="ant-space-flex-space-between table1">
            <TableShowingEntries />

            <TablePagination
              paginationFilter={dataTableInfo}
              setPaginationFilter={setDataTableInfo}
              setPaginationTotal={tableTotal}
              showLessItems={true}
              showSizeChanger={false}
              parentClass="table1"
            />
          </Space>
        </Col>
      </Row>

      <Modal
        title="Plan Form"
        className="modal-login"
        visible={modal}
        width="700px"
        onCancel={(e) => {
          setModal(false);
          form.resetFields();
        }}
        footer={
          <Space>
            <Button
              onClick={(e) => {
                setModal(false);
                form.resetFields();
              }}
            >
              Cancel
            </Button>
            <Button
              className="btn-main-outline-2"
              onClick={(e) => form.submit()}
              loading={isLoadingAccountType}
            >
              Submit
            </Button>
          </Space>
        }
      >
        <Form layout="horizontal" form={form} onFinish={onFinish}>
          <Row gutter={24}>
            <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
              <Form.Item name="id" style={{ display: "none" }}>
                <FloatInput label="id" placeholder="id" />
              </Form.Item>
              <Form.Item name="plan" rules={[validator.require]}>
                <FloatInput label="Plan" placeholder="Plan" required />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
              <Form.Item name="amount" rules={[validator.require]}>
                <FloatInputRate label="Amount" placeholder="Amount" required />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
              <Form.Item name="description" rules={[validator.require]}>
                <FloatTextArea
                  label="Description"
                  placeholder="Description"
                  required
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
              <Form.Item name="up_to" rules={[validator.require]}>
                <FloatInput label="Up To" placeholder="Up To" required />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
              <Form.Item
                name="type"
                rules={[validator.require]}
                className="form-select-error"
              >
                <FloatSelect
                  label="Type"
                  placeholder="Type"
                  options={[
                    {
                      label: "Monthly",
                      value: "Monthly",
                    },
                    {
                      label: "Yearly",
                      value: "Yearly",
                    },
                  ]}
                  required
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
              <Form.Item name="stripe_price_id" rules={[validator.require]}>
                <FloatInput
                  label="Stripe Price Id"
                  placeholder="Stripe Price Id"
                  required
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
}
