import React, { useEffect, useState } from "react";
import { Card, Col, Form, Layout, notification, Row, Collapse } from "antd";
import FloatInput from "../../../../providers/FloatInput";
import FloatSelect from "../../../../providers/FloatSelect";
// import optionStateCodes from "../../../../providers/optionStateCodes";
import ComponentHeader from "../../Components/ComponentHeader";
import { faSchool } from "@fortawesome/pro-regular-svg-icons";
import { GET, UPDATE } from "../../../../providers/useAxiosQuery";
// import { useHistory } from "react-router-dom";

import optionCountryCodes from "../../../../providers/optionCountryCodes";
import optionStateCodesUnitedState from "../../../../providers/optionStateCodesUnitedState";
import optionStateCodesCanada from "../../../../providers/optionStateCodesCanada";
import optionOrganizationType from "../../../../providers/optionOrganizationType";
import companyInfo from "../../../../providers/companyInfo";

const userdata = companyInfo().userData;

export default function PageOrganizationEdit({ match, permission }) {
  const { Panel } = Collapse;
  const [form] = Form.useForm();
  // const history = useHistory();
  const id = match.params.id;
  const onFinish = (values) => {
    let data = {
      ...values,
      id: id,
    };
    mudateAdd(data, {
      onSuccess: (res) => {
        if (res.success) {
          notification.success({
            message: "Success",
            description: "Successfully updated",
          });
        }
      },
    });
  };

  const { mutate: mudateAdd } = UPDATE("api/v1/organization", "organization");

  const [country] = useState(optionCountryCodes);
  const stateUS = optionStateCodesUnitedState();
  const stateCA = optionStateCodesCanada();

  const [stateLabel, setStateLabel] = useState("State");
  const [optionState, setOptionState] = useState(stateUS);
  const [zipLabel, setZipLabel] = useState("Zip Code");
  const [optionZip, setOptionZip] = useState(/(^\d{5}$)|(^\d{5}[ -]\d{4}$)/);

  const handleCountry = (val, opt) => {
    if (val === "United States") {
      setStateLabel("State");
      setZipLabel("Zip Code");
      setOptionState(stateUS);
      setOptionZip(/(^\d{5}$)|(^\d{5}[ -]\d{4}$)/);
    } else if (val === "Canada") {
      setStateLabel("County");
      setZipLabel("Postal Code");
      setOptionState(stateCA);
      setOptionZip(/^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/);
    }
    setCItyOption([]);
    form.resetFields(["state", "zip", "city"]);
  };

  const [title, setTitle] = useState("");
  const [cItyOption, setCItyOption] = useState([]);
  const [formData, setFormData] = useState();
  GET(`api/v1/organization/${id}`, "organization", (res) => {
    if (res.success) {
      form.setFieldsValue({
        name: res.data.name,
        country: res.data.country,
        address1: res.data.address1,
        address2: res.data.address2,
        organization_type: res.data.organization_type,
        state: res.data.state,
        status: res.data.status,
        city: res.data.city,
        zip: res.data.zip,
      });
      setFormData(res.data);
      setTitle(res.data.name);

      if (res.data.country === "United States") {
        setStateLabel("State");
        setZipLabel("Zip Code");
        setOptionState(stateUS);
        setOptionZip(/(^\d{5}$)|(^\d{5}[ -]\d{4}$)/);

        let get_city = stateUS.filter((item) => res.data.state === item.value);
        setCItyOption(get_city[0]["json"]);
      } else if (res.data.country === "Canada") {
        setStateLabel("County");
        setZipLabel("Postal Code");
        setOptionState(stateCA);
        setOptionZip(/^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/);

        let get_city = stateCA.filter((item) => res.data.state === item.value);
        setCItyOption(get_city[0]["json"]);
      }
    }
  });

  const handleBlur = (value, field) => {
    let col = formData[field] ? formData[field] : null;
    let val = value ? value : null;
    if (col !== val) {
      form.submit();
    }
  };

  return (
    <>
      <ComponentHeader title="Organization" sub_title="EDIT" icon={faSchool} />

      <Card className="p-b-lg" id="PageOrganizationEdit">
        <Row gutter={12}>
          <Col xs={24} sm={24} md={16} className="ant-card-form-list">
            <Form
              layout="vertical"
              onFinish={onFinish}
              autoComplete="off"
              initialValues={{ organizations: [""] }}
              form={form}
            >
              <Collapse
                className="main-1-collapse"
                expandIcon={({ isActive }) =>
                  isActive ? (
                    <span
                      className="ant-menu-submenu-arrow"
                      style={{
                        color: "#FFF",
                        transform: "rotate(270deg)",
                      }}
                    ></span>
                  ) : (
                    <span
                      className="ant-menu-submenu-arrow"
                      style={{
                        color: "#FFF",
                        transform: "rotate(90deg)",
                      }}
                    ></span>
                  )
                }
                defaultActiveKey={["1"]}
                expandIconPosition="start"
              >
                <Panel
                  header={`ORGANIZATION - ${title}`}
                  key="1"
                  className="accordion bg-darkgray-form"
                >
                  <Row gutter={24}>
                    <Col xs={24} sm={24} md={8}>
                      <Form.Item
                        name="country"
                        className="form-select-error"
                        rules={[
                          {
                            required: true,
                            message: "This field field is required.",
                          },
                        ]}
                      >
                        <FloatSelect
                          label="Country"
                          placeholder="Country"
                          options={country}
                          onChange={(val, opt) => {
                            handleCountry(val, opt);
                          }}
                          onBlurInput={(val) => {
                            handleBlur(val, "country");
                          }}
                          required
                        />
                      </Form.Item>
                    </Col>

                    <Col xs={24} sm={24} md={8}>
                      <Form.Item
                        name="organization_type"
                        className="form-select-error"
                        rules={[
                          {
                            required: true,
                            message: "This field field is required.",
                          },
                        ]}
                      >
                        <FloatSelect
                          label="Type"
                          placeholder="Type"
                          options={optionOrganizationType}
                          onBlurInput={(val) => {
                            handleBlur(val, "organization_type");
                          }}
                          required
                        />
                      </Form.Item>
                    </Col>

                    <Col xs={24} sm={24} md={8}>
                      <Form.Item
                        name="state"
                        className="form-select-error"
                        rules={[
                          {
                            required: true,
                            message: "This field field is required.",
                          },
                        ]}
                      >
                        <FloatSelect
                          label={stateLabel}
                          placeholder={stateLabel}
                          options={optionState}
                          onChange={(val, opt) => {
                            form.resetFields(["city"]);
                            setCItyOption(opt["data-json"]);
                          }}
                          onBlurInput={(val) => {
                            handleBlur(val, "state");
                          }}
                          required
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row gutter={24}>
                    <Col xs={24} sm={24} md={24}>
                      <Form.Item
                        name="name"
                        rules={[
                          {
                            required: true,
                            message: "This field field is required.",
                          },
                        ]}
                      >
                        <FloatInput
                          label="Organization Name"
                          placeholder="Organization Name"
                          onBlurInput={(val) => handleBlur(val, "name")}
                          required
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row gutter={24}>
                    <Col xs={24} sm={24} md={24}>
                      <Form.Item
                        name="address1"
                        rules={[
                          {
                            required: true,
                            message: "This field field is required.",
                          },
                        ]}
                      >
                        <FloatInput
                          label="Address 1"
                          placeholder="Address 1"
                          onBlurInput={(val) => handleBlur(val, "address1")}
                          required
                        />
                      </Form.Item>
                    </Col>

                    <Col xs={24} sm={24} md={24}>
                      <Form.Item name="address2">
                        <FloatInput
                          label="Address 2"
                          placeholder="Address 2"
                          onBlurInput={(val) => handleBlur(val, "address2")}
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row gutter={24}>
                    <Col xs={24} sm={24} md={8}>
                      <Form.Item
                        name="city"
                        rules={[
                          {
                            required: true,
                            message: "This field field is required.",
                          },
                        ]}
                        className="form-select-error"
                      >
                        <FloatSelect
                          label="City"
                          placeholder="City"
                          onBlurInput={(val, opt) => handleBlur(val, "city")}
                          options={cItyOption}
                          required
                        />
                      </Form.Item>
                    </Col>

                    <Col xs={24} sm={24} md={8}>
                      <Form.Item
                        name={"zip"}
                        rules={[
                          {
                            required: true,
                            message: "This field field is required.",
                          },
                          {
                            pattern: optionZip,
                            message: "Invalid " + zipLabel,
                          },
                        ]}
                      >
                        <FloatInput
                          label={zipLabel}
                          placeholder={zipLabel}
                          onBlurInput={(val) => handleBlur(val, "zip")}
                          required
                        />
                      </Form.Item>
                    </Col>

                    {userdata.role === "Admin" && (
                      <Col xs={24} sm={24} md={8}>
                        <Form.Item
                          name={"status"}
                          rules={[
                            {
                              required: true,
                              message: "This field field is required.",
                            },
                          ]}
                        >
                          <FloatSelect
                            label={"Status"}
                            placeholder={"Status"}
                            options={[
                              {
                                value: "Pending",
                                label: "Pending",
                              },
                              {
                                value: "Approved",
                                label: "Approved",
                              },
                              {
                                value: "Deleted",
                                label: "Deleted",
                              },
                            ]}
                            onBlurInput={(val) => handleBlur(val, "status")}
                            required
                          />
                        </Form.Item>
                      </Col>
                    )}
                  </Row>
                </Panel>
              </Collapse>
            </Form>
          </Col>
        </Row>
        <br />

        {/* <Button
          type="primary"
          htmlType="submit"
          loading={isLoaddingAdd}
          className="btn-main-outline-2"
          size="large"
          onClick={(e) => form.submit()}
        >
          SAVE ALL CHANGES
        </Button> */}
      </Card>
    </>
  );
}
