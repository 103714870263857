import {
  Col,
  // Collapse,
  // notification,
  Row,
  Space,
  Table,
} from "antd";
import React, { useState, useEffect } from "react";
// import { faHandsUsd } from "@fortawesome/pro-regular-svg-icons";
// import ComponentHeader from "../../../Components/ComponentHeader";
import {
  TableGlobalSearch,
  TablePageSize,
  TablePagination,
  TableShowingEntries,
} from "../../../Components/ComponentTableFilter";
import $ from "jquery";
// import { Link, useHistory } from "react-router-dom";
import { GET } from "../../../../../providers/useAxiosQuery";
// import companyInfo from "../../../../../providers/companyInfo";

export default function PageFundsPending({ match, permission }) {
  // const history = useHistory();
  // const { Panel } = Collapse;
  // const userdata = companyInfo().userData;
  const [tableFilter, setTableFilter] = useState({
    page: 1,
    page_size: 50,
    search: "",
    sort_field: "strdate",
    sort_order: "desc",
    admin_funds_status: "Pending",
  });

  const [tableTotal, setTableTotal] = useState(0);
  const [dataSource, setDataSource] = useState([]);
  const {
    refetch: refetchTable,
    isLoading: isLoadingTable,
    isFetching: isFetchingTable,
  } = GET(`api/v1/funds?${$.param(tableFilter)}`, "funds_pending", (res) => {
    if (res.success) {
      console.log("funds", res);
      setDataSource(res.data && res.data.data);
      setTableTotal(res.data.total);
    }
  });

  const handleTableChange = (pagination, filters, sorter) => {
    setTableFilter({
      ...tableFilter,
      sort_field: sorter.columnKey,
      sort_order: sorter.order ? sorter.order.replace("end", "") : null,
      // page: 1,
    });
  };

  const [searchText, setSearchText] = useState("");
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setTableFilter({
        ...tableFilter,
        search: searchText,
        page: 1,
      });
    }, 1500);
    return () => {
      clearTimeout(timeoutId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText]);

  useEffect(() => {
    refetchTable();
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableFilter]);

  // const handleDelete = (id) => {
  //   let data = {
  //     status: 1,
  //     id: id,
  //   };

  //   mutateDelete(data, {
  //     onSuccess: (res) => {
  //       if (res.success) {
  //         notification.success({
  //           message: "Success",
  //           description: "Successfully deleted.",
  //         });
  //       }
  //     },
  //   });
  // };

  // const { mutate: mutateDelete, isLoading: isLoadingDelete } = UPDATE(
  //   "api/v1/search_athletes",
  //   "search_athletes"
  // );

  // const [toggleModalPreview, setToggleModalPreview] = useState({
  //   show: false,
  //   data: null,
  //   type: "Starter",
  // });

  return (
    <>
      <Row gutter={12}>
        <Col xs={24} sm={24} md={16} lg={16} xl={18} className="m-b-sm">
          <Space>
            <TablePageSize
              paginationFilter={tableFilter}
              setPaginationFilter={setTableFilter}
            />
          </Space>
        </Col>
        <Col xs={24} sm={24} md={8} lg={8} xl={6}>
          <TableGlobalSearch
            paginationFilter={searchText}
            setPaginationFilter={setSearchText}
          />
        </Col>
      </Row>
      <Row gutter={24}>
        <Col xs={24} sm={24} md={24} className="m-t-sm m-b-sm">
          <Table
            dataSource={dataSource && dataSource}
            rowKey={(record) => record.id}
            loading={isLoadingTable || isFetchingTable}
            pagination={false}
            bordered={false}
            onChange={handleTableChange}
            className="ant-table-default ant-table-striped"
            scroll={{ x: "max-content" }}
          >
            <Table.Column
              title="Date"
              key="strdate"
              dataIndex="strdate"
              sorter={true}
              width={"150px"}
              defaultSortOrder="descend"
            />
            <Table.Column
              title="First Name"
              key="firstname"
              dataIndex="firstname"
              sorter={true}
              // render={(text, record) => {
              //   return <Link to={"#"}>{record.firstname}</Link>;
              // }}
              width={"200px"}
            />
            <Table.Column
              title="Last Name"
              key="lastname"
              dataIndex="lastname"
              sorter={true}
              // render={(text, record) => {
              //   return <Link to={"#"}>{record.lastname}</Link>;
              // }}
              width={"200px"}
            />
            <Table.Column
              title="Sport"
              key="sport"
              dataIndex="sport"
              sorter={true}
              width={"150px"}
            />
            <Table.Column
              title="Amount"
              key="amount"
              dataIndex="amount"
              sorter={true}
              width={"150px"}
              render={(text, record) => {
                if (text.indexOf(".") === -1) {
                  return text;
                } else {
                  let amount = text.split(".");
                  if (amount[1]) {
                    amount =
                      amount[0] + "." + amount[1].toString().substr(0, 2);
                  } else {
                    amount = amount[0];
                  }
                  return amount;
                }
              }}
            />
            <Table.Column
              title="Paid"
              key="admin_funds_status"
              dataIndex="admin_funds_status"
              sorter={true}
              width={"150px"}
            />
          </Table>
        </Col>
        <Col xs={24} sm={24} md={24}>
          <Space className="ant-space-flex-space-between table-pending">
            <TableShowingEntries />

            <TablePagination
              paginationFilter={tableFilter}
              setPaginationFilter={setTableFilter}
              setPaginationTotal={tableTotal}
              showLessItems={true}
              showSizeChanger={false}
              parentClass="table-pending"
            />
          </Space>
        </Col>
      </Row>
    </>
  );
}
