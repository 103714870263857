import React from "react";

import { Card, Col, Row } from "antd";
import ComponentHeader from "../Components/ComponentHeader";
import { faLock } from "@fortawesome/pro-solid-svg-icons";

export default function PagePolicy({ match, permission }) {
  return (
    <>
      <ComponentHeader title={permission} sub_title="PRIVACY" icon={faLock} />

      <Card id="PagePolicy">
        <Row gutter={12}>
          <Col md={24}>
            <h1>
              <b>WEB SITE PRIVACY POLICY</b>
            </h1>
            <span>
              Your privacy is very important to us. Accordingly, we have
              developed this Policy in order for you to understand how we
              collect, use, communicate and disclose and make use of personal
              information. The following outlines our privacy policy.
            </span>

            {/*  */}
            <br />
            <br />
            <span>
              1. Before or at the time of collecting personal information, we
              will identify the purposes for which information is being
              collected.
            </span>

            <br />
            <span>
              2. We will collect and use this personal information solely with
              the objective of fulfilling those purposes specified by us and for
              other compatible purposes, unless we obtain the consent of the
              individual concerned or as required by law.
            </span>

            <br />
            <span>
              3. We will only retain personal information as long as necessary
              for the fulfillment of those purposes.
            </span>

            <br />
            <span>
              4. We will collect personal information by lawful and fair means
              and, where appropriate, with the knowledge or consent of the
              individual concerned.
            </span>

            <br />
            <span>
              5. Personal data should be relevant to the purposes for which it
              is to be used, and, to the extent necessary for those purposes,
              should be accurate, complete, and up-to-date.
            </span>

            <br />
            <span>
              6. We will protect personal information by reasonable security
              safeguards against loss or theft, as well as unauthorized access,
              disclosure, copying, use or modification.
            </span>

            <br />
            <span>
              7. We will make readily available to customers information about
              our policies and practices relating to the management of personal
              information.
            </span>

            <br />
            <br />
            <br />
            <span>
              We are committed to conducting our business in accordance with
              these principles in order to ensure that the confidentiality of
              personal information is protected and maintained.
            </span>
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
          </Col>
        </Row>
      </Card>
    </>
  );
}
