import React from "react";
import { Button, Card, Col, Form, notification, Row } from "antd";
import ComponentHeader from "../Components/ComponentHeader";
import { faTicketAlt } from "@fortawesome/pro-solid-svg-icons";
import FloatSelect from "../../../providers/FloatSelect";
import FloatInput from "../../../providers/FloatInput";
import FloatTextArea from "../../../providers/FloatTextArea";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave } from "@fortawesome/pro-regular-svg-icons";
import { POST } from "../../../providers/useAxiosQuery";
import { useHistory } from "react-router-dom";
import companyInfo from "../../../providers/companyInfo";

const role = companyInfo().role;

export default function PageTiketingCreate({ match, permission }) {
  const history = useHistory();
  const [form] = Form.useForm();

  const onFinish = (values) => {
    mutateAdd(values, {
      onSuccess: (res) => {
        if (res.success) {
          notification.success({
            message: "Success",
            description: "Successfully created",
          });
          form.resetFields();

          if (role === "Athlete") {
            history.push("/support/ticketing");
          } else {
            history.push("/ticketing");
          }
        }
      },
    });
  };

  const { mutate: mutateAdd, isLoading: isLoadingAdd } = POST(
    "api/v1/ticket",
    "ticket"
  );

  return (
    <>
      <ComponentHeader title="Ticket" sub_title="CREATE" icon={faTicketAlt} />

      <Card id="PageTicketingCreate">
        <Card title="TICKETING INFORMATION" className="card-dark-head">
          <Form layout="vertical" form={form} onFinish={onFinish}>
            <Row gutter={12}>
              <Col xs={24} sm={24} md={14}>
                <Form.Item
                  name="priority"
                  className="form-select-error"
                  rules={[
                    {
                      required: true,
                      message: "This field field is required.",
                    },
                  ]}
                >
                  <FloatSelect
                    label="Priority"
                    placeholder="Priority"
                    options={[
                      {
                        label: "Low",
                        value: "Low",
                      },
                      {
                        label: "Medium",
                        value: "Medium",
                      },
                      {
                        label: "High",
                        value: "High",
                      },
                      {
                        label: "Urgent",
                        value: "Urgent",
                      },
                    ]}
                    required
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={12}>
              <Col xs={24} sm={24} md={14}>
                <Form.Item
                  name="subject"
                  rules={[
                    {
                      required: true,
                      message: "This field field is required.",
                    },
                  ]}
                >
                  <FloatInput label="Subject" placeholder="Subject" required />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={12}>
              <Col xs={24} sm={24} md={14}>
                <Form.Item
                  name="comments"
                  rules={[
                    {
                      required: true,
                      message: "This field field is required.",
                    },
                  ]}
                >
                  <FloatTextArea
                    label="Description"
                    placeholder="Description"
                    required
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={12}>
              <Col xs={24} sm={24} md={14}>
                <Button
                  htmlType="submit"
                  className="btn-main-outline-2"
                  size="large"
                  loading={isLoadingAdd}
                >
                  <FontAwesomeIcon icon={faSave} /> &nbsp; CREATE
                </Button>
              </Col>
            </Row>
          </Form>
        </Card>
      </Card>
    </>
  );
}
