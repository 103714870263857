import { Checkbox, Collapse, Form, notification } from "antd";
import { useEffect } from "react";
import { POST } from "../../../../../providers/useAxiosQuery";

export default function FormPdfStarter(props) {
  const { assessment_id, dataSource } = props;

  const [form] = Form.useForm();
  const initialValues = {
    p3_cmj_rsi_no: true,
    p3_es_height: true,
    p3_sj_height: true,
    p3_sprint_time_tem_meter: true,
    p3_isometric_pull: true,
    p3_age_avg_cmj_rsi_no_sum: true,
    p3_average_1: true,
  };

  const {
    mutate: mutatePdfDisplayData,
    // isLoading: isLoadingUpdateAthleteUser,
  } = POST("api/v1/athlete_pdf_display_data", "athlete_pdf_display_data_elite");

  const handleForm = (values) => {
    console.log("handleForm", values);

    let data = [];

    Object.keys(values).map(function (key) {
      if (values[key] !== undefined) {
        if (values[key]) {
          let objKeyVal = {};
          objKeyVal[key] = "1";
          data.push(objKeyVal);
        } else {
          let objKeyVal = {};
          objKeyVal[key] = "0";
          data.push(objKeyVal);
        }
      } else {
        let objKeyVal = {};
        objKeyVal[key] = "1";
        data.push(objKeyVal);
      }

      return "";
    });

    console.log("data", data);

    mutatePdfDisplayData(
      { assessment_id, data },
      {
        onSuccess: (res) => {
          if (res.success) {
            notification.success({
              message: res.message,
              description: res.description,
            });
          } else {
            notification.error({
              message: "Error",
              description: "Data not updated!",
            });
          }
        },
        onError: (err) => {
          notification.error({
            message: "Athlete PDF Display Data",
            description: err,
          });
        },
      }
    );
  };

  const handleChangeCheckbox = () => {
    form.submit();
  };

  useEffect(() => {
    // console.log("dataSource", dataSource);

    if (dataSource) {
      dataSource.map((item) => {
        form.setFieldsValue({ [item.name]: item.status ? true : false });
        // console.log(item.name, item.status ? true : false);
        return "";
      });
    }

    return () => {};
  }, [form, dataSource]);

  return (
    <Form form={form} onFinish={handleForm} initialValues={initialValues}>
      <Collapse
        className="main-1-collapse border-none"
        expandIcon={({ isActive }) =>
          isActive ? (
            <span
              className="ant-menu-submenu-arrow"
              style={{ color: "#FFF", transform: "rotate(270deg)" }}
            ></span>
          ) : (
            <span
              className="ant-menu-submenu-arrow"
              style={{ color: "#FFF", transform: "rotate(90deg)" }}
            ></span>
          )
        }
        defaultActiveKey={["1"]}
        expandIconPosition="start"
      >
        <Collapse.Panel
          header="PAGE 3"
          key="1"
          className="accordion bg-darkgray-form m-b-md border"
        >
          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th>BFS TESTING: PERFORMANCE TESTS</th>
                  <th>REBOUND JUMP (RSI)</th>
                  <th>COUNTERMOVEMENT JUMP (IN)</th>
                  <th>SQUAT JUMP (IN)</th>
                  <th>10YD SPRINT (SECS)</th>
                  <th>ISOMETRIC MID-THIGH PULL (LBS)</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th>YOU</th>
                  <td>
                    <Form.Item valuePropName="checked" name="p3_cmj_rsi_no">
                      <Checkbox onChange={handleChangeCheckbox} />
                    </Form.Item>
                  </td>
                  <td>
                    <Form.Item valuePropName="checked" name="p3_es_height">
                      <Checkbox onChange={handleChangeCheckbox} />
                    </Form.Item>
                  </td>
                  <td>
                    <Form.Item valuePropName="checked" name="p3_sj_height">
                      <Checkbox onChange={handleChangeCheckbox} />
                    </Form.Item>
                  </td>
                  <td>
                    <Form.Item
                      valuePropName="checked"
                      name="p3_sprint_time_tem_meter"
                    >
                      <Checkbox onChange={handleChangeCheckbox} />
                    </Form.Item>
                  </td>
                  <td>
                    <Form.Item valuePropName="checked" name="p3_isometric_pull">
                      <Checkbox onChange={handleChangeCheckbox} />
                    </Form.Item>
                  </td>
                </tr>
                <tr>
                  <th>Performers Avg (10 yrs old)</th>
                  <td>
                    <Form.Item
                      valuePropName="checked"
                      name="p3_age_avg_cmj_rsi_no_sum"
                    >
                      <Checkbox onChange={handleChangeCheckbox} />
                    </Form.Item>
                  </td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <th>Your Benchmark</th>
                  <td>
                    <Form.Item valuePropName="checked" name="p3_average_1">
                      <Checkbox onChange={handleChangeCheckbox} />
                    </Form.Item>
                  </td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
              </tbody>
            </table>
          </div>
        </Collapse.Panel>
      </Collapse>
    </Form>
  );
}
