import {
  Button,
  Card,
  Col,
  Collapse,
  Form,
  notification,
  Popconfirm,
  Row,
} from "antd";
import { faTrashAlt, faUserFriends } from "@fortawesome/pro-regular-svg-icons";

import ComponentHeader from "../../Components/ComponentHeader";
import FloatInput from "../../../../providers/FloatInput";
import { PlusOutlined } from "@ant-design/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DELETE, GET, POST } from "../../../../providers/useAxiosQuery";
import companyInfo from "../../../../providers/companyInfo";
import { useState } from "react";

const userData = companyInfo().userData;

export default function PageProfileParentGuardian() {
  const [form] = Form.useForm();

  const [selectedData, setSelectedData] = useState();
  const [listData, setListData] = useState([]);

  GET(
    `api/v1/user_athlete_info?id=${userData.id}`,
    "user_athlete_parent_guardian_create",
    (res) => {
      // console.log("user_athlete_parent_guardian_create res", res.data);
      if (res.data) {
        let data = res.data;

        setSelectedData(data);

        form.setFieldsValue({
          athlete_guardian:
            data.athlete_guardian && data.athlete_guardian.length > 0
              ? data.athlete_guardian
              : [""],
        });

        setListData(data.athlete_guardian);
      }
    }
  );

  const {
    mutate: mutateAthleteUserParentGuardian,
    // isLoading: isLoadingAthleteUserParentGuardian,
  } = POST(
    "api/v1/athlete_guardian_create_update",
    "user_athlete_parent_guardian_create"
  );

  const {
    mutate: mutateDeleteAthleteUserParentGuardian,
    isLoading: isLoadingDeleteAthleteUserParentGuardian,
  } = DELETE("api/v1/athlete_guardian", "user_athlete_parent_guardian_delete");

  const onFinish = (values) => {
    // console.log("onFinish", values);
    let data = {
      user_id: userData.id,
      athlete_guardian: values.athlete_guardian.map((item) => {
        return { ...item, id: item.id !== undefined ? item.id : "" };
      }),
    };

    // console.log("onFinish", data);

    mutateAthleteUserParentGuardian(data, {
      onSuccess: (res) => {
        // console.log("mutateAthleteUserParentGuardian", res);
        if (res.success) {
          notification.success({
            message: "Success",
            description: "Successfully Updated",
          });
          form.setFieldsValue({
            athlete_guardian: res.data,
          });
          setSelectedData(res.data);
          setListData(res.athlete_guardian);
        } else {
          notification.success({
            message: "Error",
            description: "Data not updated!",
          });
        }
      },
      onError: (err) => {
        // notification.success({
        // 	message: "Error",
        // 	description: err,
        // });
      },
    });
  };

  const handleRemoveGuardian = (index) => {
    let tempDataGuardian = [...selectedData.athlete_guardian];
    // console.log("tempDataGuardian", tempDataGuardian);

    let tempDataGuardianFiltered = tempDataGuardian[index];
    // console.log("tempDataGuardianFiltered", tempDataGuardianFiltered);

    if (tempDataGuardianFiltered) {
      mutateDeleteAthleteUserParentGuardian(tempDataGuardianFiltered, {
        onSuccess: (res) => {
          if (res.success) {
            notification.success({
              message: "Athlete Parent Guardian",
              description: "Data Successfully Deleted!",
            });

            tempDataGuardian.splice(index, 1);

            let tempSelectedData = {
              ...selectedData,
              athlete_guardian: tempDataGuardian,
            };

            // console.log("tempSelectedData", tempSelectedData);

            setSelectedData(tempSelectedData);
          } else {
            notification.error({
              message: "Athlete Parent Guardian",
              description: "Data Not Deleted!",
            });
          }
        },
        onError: (err) => {},
      });
    }
  };

  const onBlurInput = (value, index, field) => {
    let col =
      listData[index] && listData[index][field] ? listData[index][field] : null;
    let val = value ? value : null;
    console.log("onBlurInput", col);
    console.log("onBlurInput", val);
    if (col !== val) {
      form.submit();
    }
    // console.log("index", index);
    // console.log("field", field);
  };

  const checkAccount = (value, type, key, field) => {
    let data = {
      value: value,
      type: type,
    };

    mutateCheckAccount(data, {
      onSuccess: (res) => {
        if (res.success) {
        } else {
          let data = [...listData];
          let col =
            listData[key] && listData[key][field] ? listData[key][field] : null;
          let val = value ? value : null;

          if (col !== val) {
            notification.error({
              message: "Warning",
              description: res.message,
            });
            if (type === "guardian_email") {
              data[key] = {
                ...data[key],
                email: null,
              };
              console.log(key, data);
              form.setFieldsValue({
                athlete_guardian: data,
              });
            } else if (type === "guardian_username") {
              data[key] = {
                ...data[key],
                username: null,
              };
              form.setFieldsValue({
                athlete_guardian: data,
              });
            }
          }
        }
      },
    });
  };

  const { mutate: mutateCheckAccount } = POST(
    "api/v1/check_account",
    "check_account"
  );

  return (
    <>
      <ComponentHeader
        title="Parent / Guardian"
        sub_title="EDIT"
        icon={faUserFriends}
      />

      <Card>
        <Form form={form} onFinish={onFinish}>
          <Row>
            <Col xs={24} sm={24} md={24}>
              <Form.List name="athlete_guardian">
                {(fields, { add, remove }) => (
                  <>
                    {fields.map(({ key, name, ...restField }, index) => (
                      <Collapse
                        className="main-1-collapse border-none"
                        expandIcon={({ isActive }) =>
                          isActive ? (
                            <span
                              className="ant-menu-submenu-arrow"
                              style={{
                                color: "#FFF",
                                transform: "rotate(270deg)",
                              }}
                            ></span>
                          ) : (
                            <span
                              className="ant-menu-submenu-arrow"
                              style={{
                                color: "#FFF",
                                transform: "rotate(90deg)",
                              }}
                            ></span>
                          )
                        }
                        defaultActiveKey={["0"]}
                        expandIconPosition="start"
                        key={`${index}`}
                      >
                        <Collapse.Panel
                          header="PARENT/GUARDIAN"
                          className="accordion bg-darkgray-form m-b-md border"
                          extra={
                            fields.length > 1 ? (
                              <Popconfirm
                                title="Are you sure to delete this Parent / Guardian?"
                                onConfirm={() => {
                                  if (
                                    !isLoadingDeleteAthleteUserParentGuardian
                                  ) {
                                    remove(name);
                                    handleRemoveGuardian(index);
                                  }
                                }}
                                okText="Confirm"
                                cancelText="Cancel"
                              >
                                <FontAwesomeIcon
                                  icon={faTrashAlt}
                                  className="cursor fa-lg"
                                />
                              </Popconfirm>
                            ) : null
                          }
                          key="0"
                        >
                          <Form.Item
                            {...restField}
                            name={[name, "id"]}
                            className="hide"
                          >
                            <FloatInput label="ss" placeholder="ss" />
                          </Form.Item>

                          <Row gutter={12}>
                            <Col xs={24} sm={24} md={12}>
                              <Form.Item
                                {...restField}
                                name={[name, "email"]}
                                rules={[
                                  {
                                    required: true,
                                    message: "This field field is required.",
                                  },
                                ]}
                              >
                                <FloatInput
                                  label="Email"
                                  placeholder="Email"
                                  onBlurInput={(e) => {
                                    checkAccount(
                                      e,
                                      "guardian_email",
                                      name,
                                      "email"
                                    );
                                    onBlurInput(e, name, "email");
                                  }}
                                  required
                                />
                              </Form.Item>
                            </Col>

                            <Col xs={24} sm={24} md={12}>
                              <Form.Item
                                {...restField}
                                name={[name, "username"]}
                                rules={[
                                  {
                                    required: true,
                                    message: "This field field is required.",
                                  },
                                ]}
                              >
                                <FloatInput
                                  label="User Name"
                                  placeholder="User Name"
                                  onBlurInput={(e) => {
                                    checkAccount(
                                      e,
                                      "guardian_username",
                                      name,
                                      "username"
                                    );
                                    onBlurInput(e, name, "username");
                                  }}
                                  required
                                />
                              </Form.Item>
                            </Col>

                            <Col xs={24} sm={24} md={12}>
                              <Form.Item
                                {...restField}
                                name={[name, "firstname"]}
                                rules={[
                                  {
                                    required: true,
                                    message: "This field field is required.",
                                  },
                                ]}
                              >
                                <FloatInput
                                  label="First Name"
                                  placeholder="First Name"
                                  onBlurInput={(e) =>
                                    onBlurInput(e, name, "firstname")
                                  }
                                  required
                                />
                              </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={12}>
                              <Form.Item
                                {...restField}
                                name={[name, "middlename"]}
                              >
                                <FloatInput
                                  label="Middle Name"
                                  placeholder="Middle Name"
                                  onBlurInput={(e) =>
                                    onBlurInput(e, name, "middlename")
                                  }
                                />
                              </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={12}>
                              <Form.Item
                                {...restField}
                                name={[name, "lastname"]}
                                rules={[
                                  {
                                    required: true,
                                    message: "This field field is required.",
                                  },
                                ]}
                              >
                                <FloatInput
                                  label="Last Name"
                                  placeholder="Last Name"
                                  onBlurInput={(e) =>
                                    onBlurInput(e, name, "lastname")
                                  }
                                  required
                                />
                              </Form.Item>
                            </Col>

                            <Col xs={24} sm={24} md={12}>
                              <Form.Item
                                {...restField}
                                name={[name, "name_ext"]}
                              >
                                <FloatInput
                                  label="Name Ext"
                                  placeholder="Name Ext"
                                  onBlurInput={(e) =>
                                    onBlurInput(e, name, "name_ext")
                                  }
                                />
                              </Form.Item>
                            </Col>
                          </Row>
                        </Collapse.Panel>
                      </Collapse>
                    ))}

                    <Form.Item>
                      <Button
                        type="link"
                        onClick={() => {
                          add();
                        }}
                        icon={<PlusOutlined />}
                        className="companyColor3 p-n"
                      >
                        Add Another Parent / Guardian
                      </Button>
                    </Form.Item>
                  </>
                )}
              </Form.List>
            </Col>
          </Row>
        </Form>
      </Card>
    </>
  );
}
