import React, { useState, useEffect } from "react";
// import { Link } from "react-router-dom";
import {
  Card,
  Form,
  Button,
  Row,
  Col,
  Radio,
  Divider,
  message,
  Collapse,
  Input,
  notification,
  Typography,
  Alert,
  Popconfirm,
  Modal,
  Space,
} from "antd";
import ImgCrop from "antd-img-crop";
import { Upload } from "antd";
import { faEdit } from "@fortawesome/pro-regular-svg-icons";
import { PlusOutlined } from "@ant-design/icons";

import ComponentHeader from "../../Components/ComponentHeader";
import ModalProfileChangePassword from "../../Components/ModalProfileChangePassword";

import FloatInput from "../../../../providers/FloatInput";
import FloatSelect from "../../../../providers/FloatSelect";
import optionOrganizationType from "../../../../providers/optionOrganizationType";
import optionCountryCodes from "../../../../providers/optionCountryCodes";
import optionStateCodesUnitedState from "../../../../providers/optionStateCodesUnitedState";
import optionStateCodesCanada from "../../../../providers/optionStateCodesCanada";
import FloatInputMask from "../../../../providers/FloatInputMask";
// import FloatAutoComplete from "../../../../providers/FloatAutoComplete";
import {
  DELETE,
  GET,
  GETMANUAL,
  POST,
} from "../../../../providers/useAxiosQuery";

import companyInfo from "../../../../providers/companyInfo";
import FloatDatePicker from "../../../../providers/FloatDatePicker";

import $ from "jquery";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/pro-solid-svg-icons";
import moment from "moment";
import { FaTimes } from "react-icons/fa";
import CoachFormOrganization from "./CoachFormOrganization";
import FloatInputMaskNoFeedback from "../../../../providers/FloatInputMaskNoFeedback";

const userData = companyInfo().userData;
const apiUrl = companyInfo().apiUrl;
const encryptor = companyInfo().encryptor;

export default function PageProfileCoach({ props, permission }) {
  const [form] = Form.useForm();
  const { Panel } = Collapse;

  const validator = {
    require: {
      required: true,
      message: "This field is required.",
    },
  };

  const [isChangePasswordVisible, setIsChangePasswordVisible] = useState(false);

  const [country] = useState(optionCountryCodes);
  const stateUS = optionStateCodesUnitedState();
  const stateCA = optionStateCodesCanada();

  const [stateLabel1, setStateLabel1] = useState("State");
  const [optionState1, setOptionState1] = useState(stateUS);

  const [fileList, setFileList] = useState([]);
  const [radioData, setRadioData] = useState(1);
  const [imageCrop, setImageCrop] = useState({
    width: 1,
    height: 1,
  });

  const [selectedData, setSelectedData] = useState([]);
  const [getData, setGetData] = useState();
  // const [dataOptionOganizationType, setDataOptionOganizationType] = useState([
  //   [],
  // ]);
  const [optionOrganization, setOptionOrganization] = useState([[]]);

  const [school, setSchool] = useState([]);
  const [club, setClub] = useState([]);
  const [university, setUniversity] = useState([]);
  const [dataOrgList, setDataOrgList] = useState([
    {
      org_name: [],
      org_none: 0,
      org_country: country,
      org_state: [],
      org_zip: "",
      org_state_label: "",
      org_zip_label: "",
      create: 0,
      create_status: "Pending",
      status: "",
    },
  ]);

  const [cityOption, setCityOption] = useState([]);

  GET("api/v1/athlete_organization", "coach_organization", (res) => {
    if (res.success) {
      // console.log("coach_organization", res);
      setClub(res.club);
      setSchool(res.school);
      setUniversity(res.university);
    }
  });

  useEffect(() => {
    refetchPage();
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [school, club, university]);

  const { refetch: refetchPage } = GETMANUAL(
    `api/v1/user_coach_info?id=${userData.id}`,
    "user_coach_info",
    (res) => {
      if (res.data) {
        let data = res.data;
        console.log("user_coach_info", data);
        setSelectedData(data);

        let organizations = [];
        let tempOptionoptionOrganization = [];

        if (Number(data.photo_crop) === 1) {
          setImageCrop({
            width: 1,
            height: 1,
          });
          setRadioData(1);
        } else if (Number(data.photo_crop) === 2) {
          setImageCrop({
            width: 3.9,
            height: 2.6,
          });
          setRadioData(2);
        } else if (Number(data.photo_crop) === 3) {
          setImageCrop({
            width: 1,
            height: 1.5,
          });
          setRadioData(3);
        } else {
          setImageCrop({
            width: 1,
            height: 1,
          });
          setRadioData(1);
        }

        if (
          data.user_address &&
          data.user_address[0].country === "United States"
        ) {
          let get_city = stateUS.filter(
            (item) => data.user_address[0].state === item.value
          );
          setCityOption(get_city[0]["json"]);
        } else if (
          data.user_address &&
          data.user_address[0].country === "Canada"
        ) {
          let get_city = stateCA.filter(
            (item) => data.user_address[0].state === item.value
          );
          setCityOption(get_city[0]["json"]);
        }

        form.setFieldsValue({
          username: data.username,
          email: data.email,
          firstname: data.firstname,
          lastname: data.lastname,
          phone_number: data.phone_number ? data.phone_number : undefined,
          gender:
            data.coach_infos && data.coach_infos[0].athlete_gender
              ? data.coach_infos[0].athlete_gender
              : undefined,
        });

        if (data.user_address && data.user_address.length > 0) {
          form.setFieldsValue({
            country: data.user_address[0].country,
            state: data.user_address[0].state,
            city: data.user_address[0].city,
          });
        }

        let get_data = {
          phone_number: data.phone_number ? data.phone_number : "",
          gender: data.gender ? data.gender : "",
          country:
            data.user_address && data.user_address[0].country
              ? data.user_address[0].country
              : "",
          state:
            data.user_address && data.user_address[0].state
              ? data.user_address[0].state
              : "",
          city:
            data.user_address && data.user_address[0].city
              ? data.user_address[0].city
              : "",
          organizations: organizations.length > 0 ? organizations : [""],
        };
        setGetData(get_data);

        let image_type = data.profile_image
          ? data.profile_image.split("/")
          : "";

        if (image_type[0] !== undefined) {
          image_type =
            image_type[0] === "https:"
              ? data.profile_image
              : companyInfo().apiUrl + data.profile_image;

          setFileList([
            {
              uid: "-1",
              name: "image.png",
              status: "done",
              url: image_type,
            },
          ]);
        } else {
          setFileList([]);
          image_type = "";
        }
      }
    }
  );

  const {
    mutate: mutateUpdateUserCoach,
    // isLoading: isLoadingUpdateCoachUserAthlete,
  } = POST("api/v1/update_user_coach_profile", "user_coach_info");

  const { mutate: mutateDeleteOrganization } = DELETE(
    "api/v1/coach_organization",
    "delete_user_coach_organization"
  );

  const handleResize = (val) => {
    console.log("val", val.target.value);
    setRadioData(val.target.value);
    if (val.target.value === 1) {
      setImageCrop({
        width: 1,
        height: 1,
      });
    } else if (val.target.value === 2) {
      setImageCrop({
        width: 4.2,
        height: 2,
      });
    } else if (val.target.value === 3) {
      setImageCrop({
        width: 1,
        height: 1.5,
      });
    }
  };

  const onChange = ({ fileList: newFileList }) => {
    var _file = newFileList;
    console.log(_file);
    if (_file.length !== 0) {
      _file[0].status = "done";
      setFileList(_file);
      form.submit();
    } else {
      setFileList([]);
    }
  };

  const onPreview = async (file) => {
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow.document.write(image.outerHTML);
  };

  const beforeUpload = (file) => {
    const isJpgOrPng =
      file.type === "image/jpeg" ||
      file.type === "image/png" ||
      file.type === "image/gif" ||
      file.type === "image/jpg";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG, PNG, GIF, JPEG file!");
      setFileList([]);
      return;
    }
    const isLt2M = file.size / 102400 / 102400 < 10;
    if (!isLt2M) {
      message.error("Image must smaller than 10MB!");
      setFileList([]);
      return;
    }
    return isJpgOrPng && isLt2M;
  };

  const onFinish = (values) => {
    console.log("onFinish", values);
    // console.log("onFinishInfomation", values);

    const data = new FormData();

    data.append("id", userData.id);
    data.append("photo_crop", radioData);

    Object.keys(values).map(function (key) {
      // console.log("key, values[key]", key, values[key]);
      if (key === "organizations") {
        // console.log("organizations", values[key]);
        data.append(
          "organizations",
          values[key] ? JSON.stringify(values[key]) : JSON.stringify([])
        );
      } else {
        data.append(key, values[key] ? values[key] : "");
      }
      return "";
    });

    if (fileList.length !== 0) {
      if (fileList[0].uid !== "-1") {
        data.append(
          "profile_photo",
          fileList[0].originFileObj,
          fileList[0].name
        );
      }
    }

    console.log("onFinishInfomation", values);

    mutateUpdateUserCoach(data, {
      onSuccess: (res) => {
        console.log("mutateUpdateCoachUser", res);
        if (res.success) {
          notification.success({
            message: "Coach Profile",
            description: "Successfully Updated",
          });

          setSelectedData(res.data);

          // let organizations = [];
          // let tempOptionoptionOrganization = [];

          // res.data.coach_organization.map((item, index) => {
          //   organizations.push({
          //     id: item.id,
          //     organization_type: item.organization.organization_type,
          //     organization_id: item.organization_id,
          //     sport: item.sport,
          //     title: item.title,
          //     year: item.year,
          //   });

          //   tempOptionoptionOrganization.push([
          //     {
          //       value: item.organization.id,
          //       label: item.organization.name,
          //     },
          //   ]);

          //   return "";
          // });
          // setOptionOrganization(tempOptionoptionOrganization);

          localStorage.userdata = encryptor.encrypt({
            ...res.data,
          });

          $(".ant-menu-submenu-profile").attr(
            "src",
            apiUrl + res.data.profile_image
          );
          $(".ant-typography-profile-details-image").attr(
            "src",
            apiUrl + res.data.profile_image
          );
          $(".ant-typography-profile-details-name-info").html(
            res.data.firstname + " " + res.data.lastname
          );

          // form.setFieldsValue({
          //   organizations: organizations.length > 0 ? organizations : [""],
          // });
        } else {
          notification.error({
            message: "Coach Profile",
            description: "Data not updated!",
          });
        }
      },
      onError: (err) => {},
    });
  };

  const handleCountry1 = (val, opt) => {
    if (val === "United States") {
      setStateLabel1("State");
      setOptionState1(stateUS);
    } else if (val === "Canada") {
      setStateLabel1("County");
      setOptionState1(stateCA);
    } else {
      setStateLabel1("State");
      setOptionState1(stateUS);
    }

    form.resetFields(["state"]);
  };

  const handleBlurSave = (value, field) => {
    // console.log("field", field);
    console.log("dataForm", value);
    console.log("dataForm", getData[field]);

    // console.log("dataForm", dataForm);
    if (getData[field] !== value) {
      form.submit();
    }
  };

  return (
    <>
      <ComponentHeader title={permission} sub_title="EDIT" icon={faEdit} />
      <ModalProfileChangePassword
        toggleModalPreview={isChangePasswordVisible}
        setToggleModalPreview={setIsChangePasswordVisible}
        profileData={userData}
      />
      <Card className="p-b-lg">
        <Row gutter={12}>
          <Col xs={24} sm={24} md={16}>
            <Form name="assessment-form" form={form} onFinish={onFinish}>
              <Collapse
                className="main-1-collapse m-b-md"
                expandIcon={({ isActive }) =>
                  isActive ? (
                    <span
                      className="ant-menu-submenu-arrow"
                      style={{ color: "#FFF", transform: "rotate(270deg)" }}
                    ></span>
                  ) : (
                    <span
                      className="ant-menu-submenu-arrow"
                      style={{ color: "#FFF", transform: "rotate(90deg)" }}
                    ></span>
                  )
                }
                defaultActiveKey={["1"]}
                expandIconPosition="start"
              >
                <Panel
                  header="LOGIN INFORMATION"
                  key="1"
                  className="accordion bg-darkgray-form"
                >
                  <Row gutter={24}>
                    <Col xs={24} sm={24} md={24}>
                      <Form.Item name="username">
                        <FloatInput
                          label="Username"
                          placeholder="Username"
                          disabled={true}
                          required
                        />
                      </Form.Item>
                    </Col>

                    <Col xs={24} sm={24} md={24}>
                      <Form.Item name="email">
                        <FloatInput
                          label="Email"
                          placeholder="Email"
                          disabled={true}
                          required
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row gutter={24}>
                    <Col xs={24} sm={24} md={24}>
                      <Button
                        type="link"
                        className="btn-link-main-3 btn-p-0"
                        onClick={(e) => setIsChangePasswordVisible(true)}
                      >
                        Change Password
                      </Button>
                    </Col>
                  </Row>
                </Panel>
              </Collapse>

              <Collapse
                className="main-1-collapse m-b-md"
                expandIcon={({ isActive }) =>
                  isActive ? (
                    <span
                      className="ant-menu-submenu-arrow"
                      style={{ color: "#FFF", transform: "rotate(270deg)" }}
                    ></span>
                  ) : (
                    <span
                      className="ant-menu-submenu-arrow"
                      style={{ color: "#FFF", transform: "rotate(90deg)" }}
                    ></span>
                  )
                }
                defaultActiveKey={["1"]}
                expandIconPosition="start"
              >
                <Panel
                  header="COACHES INFORMATION"
                  key="1"
                  className="accordion bg-darkgray-form"
                >
                  <Row gutter={24}>
                    <Col xs={24} sm={24} md={24}>
                      <Form.Item name="firstname">
                        <FloatInput
                          label="First Name"
                          placeholder="First Name"
                          disabled={true}
                          required
                        />
                      </Form.Item>
                    </Col>

                    <Col xs={24} sm={24} md={24}>
                      <Form.Item name="lastname">
                        <FloatInput
                          label="Last Name"
                          placeholder="Last Name"
                          disabled={true}
                          required
                        />
                      </Form.Item>
                    </Col>

                    <Col xs={24} sm={24} md={24}>
                      <Form.Item
                        name="phone_number"
                        rules={[
                          {
                            pattern:
                              /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/,
                            message: "Invalid Phone Number",
                          },
                        ]}
                      >
                        <FloatInputMaskNoFeedback
                          label="Phone Number"
                          placeholder="Phone Number"
                          maskLabel="phone_number"
                          maskType="999-999-9999"
                          onBlurInput={(e) => handleBlurSave(e, "phone_number")}
                          //   onChange={handleInput}
                        />
                      </Form.Item>
                    </Col>

                    <Col xs={24} sm={24} md={24}>
                      <Form.Item
                        name="country"
                        rules={[validator.require]}
                        className="form-select-error"
                      >
                        <FloatSelect
                          label="Country"
                          placeholder="Country"
                          options={country}
                          onChange={handleCountry1}
                          onBlurInput={(e) => handleBlurSave(e, "country")}
                          required
                        />
                      </Form.Item>
                    </Col>

                    <Col xs={24} sm={24} md={24}>
                      <Form.Item
                        name="state"
                        rules={[validator.require]}
                        className="form-select-error"
                      >
                        <FloatSelect
                          label={stateLabel1}
                          placeholder={stateLabel1}
                          options={optionState1}
                          onBlurInput={(e) => handleBlurSave(e, "state")}
                          required
                        />
                      </Form.Item>
                    </Col>

                    <Col xs={24} sm={24} md={24}>
                      <Form.Item
                        name="city"
                        rules={[validator.require]}
                        className="form-select-error"
                      >
                        <FloatSelect
                          label="City"
                          placeholder="City"
                          onBlurInput={(e) => handleBlurSave(e, "city")}
                          options={cityOption}
                          required
                        />
                      </Form.Item>
                    </Col>

                    <Col xs={24} sm={24} md={24}>
                      <Form.Item
                        name="gender"
                        rules={[validator.require]}
                        className="form-select-error"
                      >
                        <FloatSelect
                          label="Sex"
                          placeholder="Sex"
                          options={[
                            // { label: "Both", value: "Both" },
                            {
                              label: "Male",
                              value: "Male",
                            },
                            {
                              label: "Female",
                              value: "Female",
                            },
                          ]}
                          onBlurInput={handleBlurSave}
                          required
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Panel>
              </Collapse>
            </Form>

            <CoachFormOrganization
              user_id={userData.id}
              selectedData={selectedData}
              refetchPage={refetchPage}
            />
          </Col>

          <Col xs={24} sm={24} md={8}>
            <Collapse
              className="main-1-collapse"
              expandIcon={({ isActive }) =>
                isActive ? (
                  <span
                    className="ant-menu-submenu-arrow"
                    style={{ color: "#FFF", transform: "rotate(270deg)" }}
                  ></span>
                ) : (
                  <span
                    className="ant-menu-submenu-arrow"
                    style={{ color: "#FFF", transform: "rotate(90deg)" }}
                  ></span>
                )
              }
              defaultActiveKey={["1"]}
              expandIconPosition="start"
            >
              <Panel
                header="MY PROFILE PHOTO"
                key="1"
                className="accordion bg-darkgray-form"
              >
                <Row gutter={12} justify="center">
                  <Col xs={24} sm={24} md={24}>
                    <label className="font-red">
                      <b>
                        Photo upload & cropping: select your image orientation
                      </b>
                    </label>
                  </Col>
                  <Col xs={24} sm={24} md={24}>
                    <Radio.Group onChange={handleResize} value={radioData}>
                      <Radio value={1}>Square</Radio>
                      <Radio value={2}>Rectangle</Radio>
                      <Radio value={3}>Portrait</Radio>
                    </Radio.Group>
                  </Col>
                  <Divider />
                  <Col xs={24} sm={24} md={24}>
                    <div style={{ display: "flex" }}>
                      <ImgCrop
                        rotate
                        aspect={imageCrop.width / imageCrop.height}
                      >
                        <Upload
                          // action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                          listType="picture-card"
                          style={{ width: "200px" }}
                          maxCount={1}
                          action={false}
                          customRequest={false}
                          fileList={fileList}
                          onChange={onChange}
                          onPreview={onPreview}
                          beforeUpload={beforeUpload}
                          showUploadList={{
                            showPreviewIcon: false,
                          }}
                          className="profilePhoto"
                        >
                          {fileList.length < 1 && "+ Upload"}
                        </Upload>
                      </ImgCrop>
                    </div>
                  </Col>
                </Row>
              </Panel>
            </Collapse>
          </Col>
        </Row>
      </Card>
    </>
  );
}
