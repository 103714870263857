import { Card, Col, Row, Space, Table } from "antd";
import React, { useState, useEffect } from "react";
// import { faAlarmClock } from "@fortawesome/pro-regular-svg-icons";
import ComponentHeader from "../../Components/ComponentHeader";
import {
  TableGlobalSearch,
  TablePageSize,
  TablePagination,
  TableShowingEntries,
} from "../../Components/ComponentTableFilter";
import $ from "jquery";
import { useHistory } from "react-router-dom";
import { GET } from "../../../../providers/useAxiosQuery";
import companyInfo from "../../../../providers/companyInfo";
import { faFilePdf, faCogs } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function PageAthletePdfDisplayData() {
  const history = useHistory();
  const userdata = companyInfo().userData;
  const [tableFilter, setTableFilter] = useState({
    page: 1,
    page_size: 50,
    search: "",
    sort_field: "strdate",
    sort_order: "desc",
    from: "",
    manager_id: userdata.id,
  });

  const [tableTotal, setTableTotal] = useState(0);
  const [dataSource, setDataSource] = useState([]);
  const {
    refetch: refetchTable,
    isLoading: isLoadingTable,
    isFetching: isFetchingTable,
  } = GET(
    `api/v1/athlete_assessment?${$.param(tableFilter)}`,
    "athlete_assessment",
    (res) => {
      if (res.success) {
        console.log("dataTable", res);
        setDataSource(res.data && res.data.data);
        setTableTotal(res.data.total);
      }
    }
  );

  const handleTableChange = (pagination, filters, sorter) => {
    setTableFilter({
      ...tableFilter,
      sort_field: sorter.columnKey,
      sort_order: sorter.order ? sorter.order.replace("end", "") : null,
      // page: 1,
    });
  };

  const [searchText, setSearchText] = useState("");
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setTableFilter({
        ...tableFilter,
        search: searchText,
        page: 1,
      });
    }, 1500);
    return () => {
      clearTimeout(timeoutId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText]);

  useEffect(() => {
    refetchTable();
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableFilter]);

  // const handleDelete = (id) => {
  //   let data = {
  //     status: 1,
  //     id: id,
  //   };

  //   mutateDelete(data, {
  //     onSuccess: (res) => {
  //       if (res.success) {
  //         notification.success({
  //           message: "Success",
  //           description: "Successfully deleted.",
  //         });
  //       }
  //     },
  //   });
  // };

  // const { mutate: mutateDelete, isLoading: isLoadingDelete } = UPDATE(
  //   "api/v1/athlete_assessment",
  //   "athlete_assessment"
  // );

  // const [toggleModalPreview, setToggleModalPreview] = useState({
  //   show: false,
  //   data: null,
  //   type: "Starter",
  // });

  return (
    <>
      <ComponentHeader
        title="Display Data"
        sub_title="ATHLETE PDF"
        icon={faFilePdf}
      />

      <Card className="card-min-height" id="PageAthletePdfDisplayData">
        <Row gutter={12}>
          <Col xs={24} sm={24} md={16} lg={16} xl={18} className="m-b-sm">
            <Space>
              <TablePageSize
                paginationFilter={tableFilter}
                setPaginationFilter={setTableFilter}
              />
            </Space>
          </Col>
          <Col xs={24} sm={24} md={8} lg={8} xl={6}>
            <TableGlobalSearch
              paginationFilter={searchText}
              setPaginationFilter={setSearchText}
            />
          </Col>
        </Row>
        <Row gutter={12}>
          <Col xs={24} sm={24} md={24} className="m-t-sm m-b-sm">
            <Table
              className="ant-table-default ant-table-striped"
              dataSource={dataSource && dataSource}
              rowKey={(record) => record.id}
              loading={isLoadingTable || isFetchingTable}
              pagination={false}
              bordered={false}
              onChange={handleTableChange}
              scroll={{ x: "max-content" }}
            >
              <Table.Column
                title="Date"
                key="strdate"
                dataIndex="strdate"
                defaultSortOrder="descend"
                sorter={true}
                width={"150px"}
              />
              <Table.Column
                title="Name"
                key="name"
                dataIndex="name"
                sorter={true}
                width={"200px"}
              />
              <Table.Column
                title="Type"
                key="type"
                dataIndex="type"
                sorter={true}
                width={"100px"}
              />
              <Table.Column
                title="Organization"
                key="organization"
                dataIndex="organization"
                sorter={true}
                width={"250px"}
              />
              <Table.Column
                title="Status"
                key="status"
                dataIndex="status"
                sorter={true}
                width={"150px"}
              />
              {/* <Table.Column
								title="State"
								key="state"
								dataIndex="state"
								sorter={true}
								width={"100px"}
							/>
							<Table.Column
								title="City"
								key="city"
								dataIndex="city"
								sorter={true}
								width={"150px"}
							/> */}
              <Table.Column
                title="Action"
                key="action"
                align="center"
                width={"100px"}
                render={(_, record) => {
                  // console.log("action record", record);
                  return (
                    <FontAwesomeIcon
                      icon={faCogs}
                      className="cursor companyColor3"
                      onClick={() =>
                        history.push({
                          pathname: "/athlete-pdf-display-data/form",
                          state: record,
                        })
                      }
                    />
                  );
                }}
              />
            </Table>
          </Col>
          <Col xs={24} sm={24} md={24}>
            <Space className="ant-space-flex-space-between">
              <TableShowingEntries />

              <TablePagination
                paginationFilter={tableFilter}
                setPaginationFilter={setTableFilter}
                setPaginationTotal={tableTotal}
                showLessItems={true}
                showSizeChanger={false}
              />
            </Space>
          </Col>
          <Col xs={24} sm={24} md={12}></Col>
        </Row>

        <br />
      </Card>
    </>
  );
}
