import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Layout,
  Breadcrumb,
  Button,
  // Space
} from "antd";
import $ from "jquery";
import { SpinnerDotted } from "spinners-react";

// import { SpinnerDotted } from "spinners-react";

import companyInfo from "../../providers/companyInfo";
import Footer from "./Footer";

import { RightOutlined } from "@ant-design/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome } from "@fortawesome/pro-regular-svg-icons";
import SideMenu from "./SideMenu";
import Header from "./Header";

import { POST } from "../../providers/useAxiosQuery";
import { ClearCacheProvider, useClearCache } from "react-clear-cache";
import { GiftOutlined } from "@ant-design/icons";
import { H } from "highlight.run";

const name = companyInfo().name;
const role = companyInfo().role;
const userdata = companyInfo().userData;
const encryptor = companyInfo().encryptor;
const apiUrl = companyInfo().apiUrl;

if (apiUrl === "https://live-api.bfssathlete.com/") {
  H.init("kgr57jme", {
    serviceName: "frontend-app",
    tracingOrigins: true,
    networkRecording: {
      enabled: true,
      recordHeadersAndBody: true,
      urlBlocklist: [
        "https://www.googleapis.com/identitytoolkit",
        "https://securetoken.googleapis.com",
      ],
    },
  });
}

export default function Private(props) {
  const { title, permission, children, breadcrumb, path } = props;

  const history = useHistory();
  const [sideMenuCollapse, setSideMenuCollapse] = useState(
    $(window).width() <= 768 ? true : false
  );
  const [width, setWidth] = useState($(window).width());

  useEffect(() => {
    if (title) {
      document.title = title + " | " + name;
    } else if (permission) {
      document.title = permission + " | " + name;
    }

    function handleResize() {
      setWidth($(window).width());
      if ($(window).width() <= 768) {
        setSideMenuCollapse(true);
      } else {
        setSideMenuCollapse(false);
      }
    }
    window.addEventListener("resize", handleResize);

    $(".ant-btn-quick-link-svg-512-448").attr("viewBox", "0 0 512 448");

    return () => window.removeEventListener("resize", handleResize);
  }, [title, permission]);

  const { mutate: mutateGenerateToken, isLoading: isLoadingtickets } = POST(
    "api/v1/generate/token/viewas",
    "viewas_mutate"
  );

  const handleBackToSuperAdmin = () => {
    let userdata_admin = encryptor.decrypt(localStorage.userdata_admin);
    viewAsBack(userdata_admin.id, true);
  };

  const viewAsBack = (id, backtoadmin = false) => {
    mutateGenerateToken(
      { id: id, viewas: localStorage.viewas },
      {
        onSuccess: (res) => {
          if (res.success) {
            console.log(res);
            localStorage.token = res.token;
            localStorage.userdata = encryptor.encrypt(res.data);
            if (backtoadmin) {
              localStorage.removeItem("viewas");
              localStorage.removeItem("userdata_admin");
            }

            var url = window.location.origin + "/viewas";
            window.location.href = url;
          }
        },
      }
    );
  };

  const { isLatestVersion, emptyCacheStorage } = useClearCache();
  useEffect(() => {
    console.log("isLatestVersion", isLatestVersion);

    return () => {};
  }, []);
  return (
    <ClearCacheProvider>
      {!isLatestVersion && (
        <div className="updateAvailableDiv">
          <div className="div1">
            <GiftOutlined />
          </div>
          <div className="div2">
            <h3>Updates available</h3>
            <p>
              A new and better version of the app is installed <br />
              and ready. Reload the page to get all the changes.
            </p>
            <Button
              onClick={(e) => {
                e.preventDefault();
                emptyCacheStorage();
              }}
              type="primary"
            >
              Reload
            </Button>
          </div>
        </div>
      )}
      <div className="globalLoading hide">
        <SpinnerDotted
          thickness="100"
          color="ff8303"
          className="loading-color"
          enabled={true}
        />
      </div>

      <Layout hasSider className="private-layout">
        <SideMenu
          history={history}
          sideMenuCollapse={sideMenuCollapse}
          setSideMenuCollapse={setSideMenuCollapse}
          role={role}
        />

        <Layout
          className={
            "site-layout-main " +
            (sideMenuCollapse ? "site-layout-main-collapse" : "")
          }
          onClick={(e) => {
            // console.log("asdas");
            if ($(window).width() <= 768) {
              if (!sideMenuCollapse) {
                setSideMenuCollapse(!sideMenuCollapse);
              }
            }
          }}
        >
          <Header
            sideMenuCollapse={sideMenuCollapse}
            setSideMenuCollapse={setSideMenuCollapse}
            role={role}
            width={width}
            path={path}
          />

          <Breadcrumb
            separator={
              <span
                className="ant-menu-submenu-arrow companyColor1"
                style={{
                  transform: "rotate(270deg)",
                  position: "relative",
                  left: "-4px",
                  top: "9px",
                }}
              ></span>
            }
            className="first-breadcrumb"
          >
            <Breadcrumb.Item key="/home">
              <a href="/">
                <FontAwesomeIcon icon={faHome} />
              </a>
            </Breadcrumb.Item>

            {breadcrumb &&
              breadcrumb.map((item, index) => {
                return (
                  <Breadcrumb.Item
                    key={index}
                    onClick={(e) => history.push(item.link)}
                    style={{ cursor: "pointer", color: "black" }}
                  >
                    {item.name}
                  </Breadcrumb.Item>
                );
              })}
          </Breadcrumb>
          {localStorage.viewas == "true" && (
            <>
              {" "}
              <div>
                <div
                  style={{
                    position: "fixed",
                    bottom: 0,
                    left: "50%",
                    bottom: "4%",
                    transform: "translate(-50%, 0)",
                    padding: 10,
                    fontWeight: 900,
                    background: "black",
                    color: "white",
                    zIndex: 2,
                    textAlign: "center",
                  }}
                >
                  Viewing As: {userdata.firstname + " " + userdata.lastname}
                  <br></br>
                  <Button
                    className="btn-main-outline-2"
                    style={{ marginTop: "10px" }}
                    onClick={handleBackToSuperAdmin}
                  >
                    Back to Super Admin View
                  </Button>
                </div>
              </div>
              <div className="viewAsBoxTop"></div>
              <div className="viewAsBoxRight"></div>
              <div className="viewAsBoxLeft"></div>
              <div className="viewAsBoxBottom"></div>
            </>
          )}
          {children}

          <Footer />
        </Layout>
      </Layout>
    </ClearCacheProvider>
  );
}
