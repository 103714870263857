import { useEffect, useState } from "react";
import {
  Alert,
  Button,
  Card,
  Col,
  Collapse,
  Form,
  Modal,
  notification,
  Radio,
  Row,
  Space,
  Steps,
  Tabs,
  Typography,
} from "antd";
import FloatSelect from "../../../../providers/FloatSelect";
// import FloatSelect2 from "../../../../providers/FloatSelect2";
import ComponentHeader from "../../Components/ComponentHeader";
import FloatInput from "../../../../providers/FloatInput";
// import FloatInput2 from "../../../../providers/FloatInput2";
import FloatInputMask from "../../../../providers/FloatInputMask";
import optionCountryCodes from "../../../../providers/optionCountryCodes";
import groupArrayOfObjects from "../../../../providers/groupArrayOfObjects";
import { faCalendarAlt } from "@fortawesome/pro-regular-svg-icons";
import moment from "moment";
import { Link, useHistory } from "react-router-dom";
import { POST, GET, GETMANUAL } from "../../../../providers/useAxiosQuery";
import optionStateCodesUnitedState from "../../../../providers/optionStateCodesUnitedState";
import optionStateCodesCanada from "../../../../providers/optionStateCodesCanada";
import companyInfo from "../../../../providers/companyInfo";
// import FloatInputNumber from "../../../../providers/FloatInputNumber";
import NumberFormat from "react-number-format";
import { LoadingOutlined } from "@ant-design/icons";
import BookComponentStep3 from "./BookComponent/BookComponentStep3";
import FloatInputPhone from "../../../../providers/FloatInputPhone";
import FloatInputMaskNoFeedback from "../../../../providers/FloatInputMaskNoFeedback";

const userData = companyInfo().userData;

export default function PageCalendarBookAssessments(props) {
  const { location } = props;
  const history = useHistory();
  // console.log("props", props);

  const [form2] = Form.useForm();
  const [form3] = Form.useForm();

  let typeAssessment =
    location.state && location.state.type ? location.state.type : "";

  const [warningModal, setWarningModal] = useState(false);
  useEffect(() => {
    if (userData.status === "Cancelled") {
      setWarningModal(true);
    }
  }, [userData]);

  const stateUS = optionStateCodesUnitedState();
  const stateCA = optionStateCodesCanada();
  const [optionStateLabel, setOptionStateLabel] = useState("State");
  const [optionState, setOptionState] = useState([]);
  const [zipLabel, setZipLabel] = useState("Zip Code");
  const [optionBillingZip, setOptionBillingZip] = useState();
  const dataAssessment = typeAssessment === "group" ? location.state.data : [];
  const [dateSchedule, setDateSchedule] = useState([]);
  const [accountPlanOption, setAccountPlanOption] = useState([]);
  const [current, setCurrent] = useState(0);
  const [stepData, setStepData] = useState([
    {
      step: "process",
      data: null,
    },
    {
      step: "wait",
      data: {
        date: null,
        event_schedule_id: null,
        time_start: null,
        time_end: null,
      },
    },
    {
      step: "wait",
      data: {
        firstname: userData.firstname,
        lastname: userData.lastname,
        contact_number: userData.phone_number,
        email: userData.email,
      },
    },
    {
      step: "wait",
      data: {
        card_name: null,
        card_number: null,
        card_expiry: null,
        card_cvv: null,
        billing_address1: null,
        billing_address2: null,
        billing_city: null,
        billing_state: null,
        billing_zip: null,
        billing_country: null,
      },
    },
    {
      step: "wait",
      data: null,
    },
    {
      step: "wait",
      data: null,
    },
  ]);

  const [step3Time, setStep3Time] = useState("");

  useEffect(() => {
    let locationstatedata = location.state ? location.state.data : [];
    if (typeAssessment === "group") {
      let dateScheduleData = [];
      if (locationstatedata) {
        locationstatedata.schedule.map((item) => {
          let curDay = moment().format("YYYY-MM-DD");
          let diff = moment(item.date).diff(curDay, "days");
          if (diff > 0) {
            dateScheduleData.push(item);
          }
          return "";
        });
        setDateSchedule(dateScheduleData);
      }
    }
    if (typeAssessment !== "group") {
      refetchSchedule();
    }

    return () => {};
  }, [typeAssessment, location.state, form2]);

  const { data: dataSchedule, refetch: refetchSchedule } = GETMANUAL(
    `api/v1/event_schedule_private_list?user_athlete_id=${userData.id}`,
    "event_schedule_private_list",
    (res) => {
      if (res.data) {
        // let dateScheduleData = [];
        // res.data.map((item) => {
        //   let curDay = moment().format("YYYY-MM-DD");
        //   let diff = moment(item.date).diff(curDay, "days");
        //   if (diff > 0) {
        //     dateScheduleData.push(item);
        //   }
        // });
        // setDateSchedule(dateScheduleData);
        if (res && res.data.length === 1) {
          handleGetSchedule(res.data[0]);
        }
        console.log("dateScheduleData", res);
        // console.log("dateScheduleData", dateScheduleData);
      }
    }
  );

  const [amountValue, setAmountValue] = useState({
    service_amount: 0,
    sub_amount: 0,
    total_amount: 0,
    coupon_status: false,
    coupon_type: "",
    discount_label: "",
    discount_value: 0,
  });

  GET(
    `api/v1/assessment_type?role=${userData.role}&user_id=${userData.id}`,
    `at_role_athlete_data_plan`,
    (res) => {
      if (res.success) {
        console.log("at_role_athlete_data_plan", res);
        if (
          res.data &&
          res.data.dataAccountType &&
          res.data.dataAccountType.length > 0
        ) {
          let arrAccountType = [];

          res.data.dataAccountType[0].account_plan.map((item) => {
            arrAccountType.push({
              value: item.id,
              label: item.plan + " $" + item.amount,
              json: item,
            });

            return "";
          });
          setAccountPlanOption(arrAccountType);

          if (res.data.dataUserPlan) {
            let stepDataTemp = [...stepData];
            stepDataTemp[0] = {
              ...stepDataTemp[0],
              data: {
                account_plan_id: res.data.dataUserPlan.account_plan_id,
                program: res.data.dataUserPlan.account_plan
                  ? res.data.dataUserPlan.account_plan.plan
                  : "",
              },
            };

            setAmountValue({
              ...amountValue,
              sub_amount: res.data.dataUserPlan.account_plan.amount,
              total_amount: res.data.dataUserPlan.account_plan.amount,
            });

            setStepData(stepDataTemp);

            console.log("res.data", res.data.credit_info);
            setPaymentCharge(res.data.credit_info);
          }
        }
      }
    }
  );

  const { mutate: mutateBooking, isLoading: isLoadingBooking } = POST(
    "api/v1/book_assessment",
    "book_assessment_mutate"
  );

  const { mutate: mutateBookingCoupon, isLoading: isLoadingBookingCoupon } =
    POST("api/v1/coupons", "coupons");

  const { mutate: mutateCheckEventSchedulePrivate } = POST(
    "api/v1/check_event_schedule_private",
    "check_event_schedule_private"
  );

  // const [btnSubmit, setBtnSubmit] = useState(false);
  const handleSubmit = () => {
    let data = {
      event_id: dataAssessment && dataAssessment.id ? dataAssessment.id : "",
      eventType: typeAssessment === "group" ? "Group" : "Private",
      type: stepData[0].data,
      datetime: stepData[1].data,
      ...stepData[2].data,
      ...stepData[3].data,
      coupon_code: stepData[0].data.coupon_code,
      user_id: userData.id,
      link_origin: window.location.origin,
    };

    console.log("handleSubmit", data);

    mutateBooking(data, {
      onSuccess: (res) => {
        if (res.success) {
          let stepDataTemp = [...stepData];
          stepDataTemp[4] = {
            ...stepDataTemp[4],
            step: "finish",
          };
          stepDataTemp[5] = {
            ...stepDataTemp[5],
            step: "finish",
          };
          setStepData(stepDataTemp);
          setCurrent(current + 1);
          notification.success({
            message: res.message,
            description: res.description,
          });

          mutateBookingCoupon(
            {
              coupon_code: stepData[0].data.coupon_code,
              user_id: userData.id,
            },
            {
              onSuccess: (res) => {
                if (res.success) {
                  console.log("mutateAddCoupon", "yes");
                }
              },
            }
          );
        } else {
          notification.warning({
            message: res.message,
            description: res.description,
          });
        }
      },
      onError: (err) => {
        notification.error({
          message: "Assessment",
          description: err.response.data.message,
        });
      },
    });
  };

  const [freeCreditAmount, setFreeCreditAmount] = useState();
  GET("api/v1/get_free_credit", "get_free_credit", (res) => {
    if (res.success) {
      console.log("get_free_credit", res);
      setFreeCreditAmount(res);
    }
  });

  const [paymentCharge, setPaymentCharge] = useState();
  const handleSelectService = (e, opt) => {
    console.log("handleSelectService", opt["data-json"]);
    if (e !== "") {
      let program = accountPlanOption.filter(
        (itemFilter) => itemFilter.value === e
      );
      program = program[0].label;
      let stepDataTemp = [...stepData];
      stepDataTemp[0] = {
        data: { account_plan_id: e, program },
      };
      setStepData(stepDataTemp);

      if (freeCreditAmount) {
        let total = 0;
        let credit_balance;
        //   opt["data-json"]["amount"] -
        // freeCreditAmount.data.registration_credit_amount;
        let credit = parseFloat(
          freeCreditAmount.data?.registration_credit_amount
        );
        let amount = parseFloat(opt["data-json"]["amount"]);

        // console.log("credit_balance", credit);
        // console.log("credit_balance", amount);
        if (credit > amount) {
          total = 0;
          credit_balance =
            freeCreditAmount.data?.registration_credit_amount -
            opt["data-json"]["amount"];

          console.log("credit_balance 1");
        } else {
          total =
            opt["data-json"]["amount"] -
            freeCreditAmount.data.registration_credit_amount;

          credit_balance = 0;
          console.log("credit_balance 2");

          // console.log("credit_balance", credit_balance);
          // console.log(
          //   "credit_balance",
          //   freeCreditAmount.data?.registration_credit_amount
          // );
          // console.log("credit_balance", opt["data-json"]["amount"]);
        }

        setAmountValue({
          ...amountValue,
          service_amount: opt["data-json"]["amount"],
          sub_amount: total.toFixed(2),
          total_amount: total.toFixed(2),

          coupon_status: false,
          coupon_type: "",
          discount_label: "",
          discount_value: 0,
          credit_bal: parseFloat(credit_balance).toFixed(2),
        });
      } else {
        setAmountValue({
          ...amountValue,
          service_amount: opt["data-json"]["amount"],
          sub_amount: opt["data-json"]["amount"],
          total_amount: opt["data-json"]["amount"],

          coupon_status: false,
          coupon_type: "",
          discount_label: "",
          discount_value: 0,
          credit_bal: 0,
        });
      }
      setCouponValue(null);
    }
  };

  // const handleMutateCheckPayment = () => {};

  const handleForm2 = (values) => {
    // console.log("handleForm2", values);

    let stepDataTemp = [...stepData];
    stepDataTemp[2] = {
      ...stepDataTemp[2],
      step: "finish",
      data: { ...stepDataTemp[2].data, contact_number: values.contact_number },
    };
    stepDataTemp[3] = {
      ...stepDataTemp[3],
      step: "process",
    };
    setStepData(stepDataTemp);
    setCurrent(current + 1);
  };

  const handleForm3 = (values) => {
    console.log("handleForm3", values);
    let stepDataTemp = [...stepData];
    stepDataTemp[3] = {
      ...stepDataTemp[3],
      step: "finish",
      data: { ...values },
    };
    stepDataTemp[4] = {
      ...stepDataTemp[4],
      step: "process",
    };
    setStepData(stepDataTemp);
    setCurrent(current + 1);
  };

  const handleDateSchedule = (e, item) => {
    // console.log("handleDateSchedule item", e, item, dateSchedule);
    if (typeAssessment === "group") {
      let tempSetstep3Time = dateSchedule
        .filter((filterItem) => filterItem.id === e.target.value)
        .map((item2, index2) => {
          // console.log("item2", item2);
          return {
            time_start: item2.time_start,
            time_end: item2.time_end,
          };
        });

      // console.log("tempSetstep3Time", dateSchedule);

      let stepDataTemp = [...stepData];
      stepDataTemp[1].data = {
        date: item,
        event_schedule_id: e.target.value,
        time_start: tempSetstep3Time[0].time_start,
        time_end: tempSetstep3Time[0].time_end,
      };
      setStepData(stepDataTemp);

      setStep3Time(
        tempSetstep3Time[0].time_start + " to " + tempSetstep3Time[0].time_end
      );
    } else {
      mutateCheckEventSchedulePrivate(
        { id: e.target.value },
        {
          onSuccess: (res) => {
            if (res.data) {
              if (res.data.count === 0) {
                let tempSetstep3Time = dateSchedule
                  .filter((filterItem) => filterItem.id === e.target.value)
                  .map((item2, index2) => {
                    // console.log("item2", item2);
                    return {
                      time_start: item2.time_start,
                      time_end: item2.time_end,
                    };
                  });

                let stepDataTemp = [...stepData];
                stepDataTemp[1].data = {
                  date: item,
                  event_schedule_id: e.target.value,
                  time_start: tempSetstep3Time[0].time_start,
                  time_end: tempSetstep3Time[0].time_end,
                };
                setStepData(stepDataTemp);
                console.log("handleDateSchedule", stepDataTemp);

                setStep3Time(
                  tempSetstep3Time[0].time_start +
                    " to " +
                    tempSetstep3Time[0].time_end
                );
              } else {
                notification.warning({
                  message: "Private Assessment",
                  description: "The time you selected is already booked!",
                });
              }
            }
          },
          onError: (err) => {
            notification.error({
              message: "Assessment",
              description: err.response.data.message,
            });
          },
        }
      );
    }
  };

  const [selectedManager, setSelectedManager] = useState();
  const handleGetSchedule = (result) => {
    let dateScheduleData = [];
    result?.private_schedule?.map((item) => {
      // let curDay = moment().format("YYYY-MM-DD");
      // let diff = moment(item.date).diff(curDay, "days");
      // if (diff > 0) {
      //   dateScheduleData.push(item);
      // }

      let curDay = moment();
      if (moment(item.date) > curDay) {
        dateScheduleData.push(item);
      }
    });
    setDateSchedule(dateScheduleData);
    console.log("dateScheduleData result", dateScheduleData);
  };

  const [WarningSchedule, setWarningSchedule] = useState(false);
  const handleRenderStep1 = () => {
    if (dateSchedule) {
      if (typeAssessment === "group") {
        return Object.keys(groupArrayOfObjects(dateSchedule, "date")).map(
          (item, index) => {
            // console.log("groupArrayOfObjects item, index", item, index);
            return (
              <Space
                direction="vertical"
                key={index}
                style={{ width: "200px" }}
              >
                <Typography.Title
                  level={5}
                  className="companyBGColor3 white p-xxs text-center"
                >
                  {moment(item).format("ddd, MMM DD")}
                </Typography.Title>

                <Radio.Group
                  buttonStyle="solid"
                  onChange={(e) => handleDateSchedule(e, item)}
                  value={
                    stepData[1].data.event_schedule_id
                      ? stepData[1].data.event_schedule_id
                      : ""
                  }
                >
                  <Space direction="vertical">
                    {dateSchedule
                      .filter(
                        (dateScheduleFitler) => dateScheduleFitler.date === item
                      )
                      .map((item2, index2) => {
                        // console.log("item2", item2);
                        return (
                          <Radio key={index2} value={item2.id}>
                            {item2.time_start} - {item2.time_end}
                          </Radio>
                        );
                      })}
                  </Space>
                </Radio.Group>
              </Space>
            );
          }
        );
      } else {
        if (dataSchedule && dataSchedule.data.length > 0) {
          let manager_option = dataSchedule.data.map((item) => {
            return {
              label: `${item.firstname} ${item.lastname}`,
              value: item.id,
              json: item,
            };
          });

          let newdateSchedule = dateSchedule.filter(
            (itemFitler) => itemFitler.count === 0
          );

          return (
            <div className="w-100">
              {dataSchedule.data.length !== 1 && (
                <Row
                  gutter={24}
                  className="w-100 m-t-sm"
                  style={{ height: "60px" }}
                >
                  <Col xs={24} sm={24} md={6}>
                    <FloatSelect
                      label="Manager"
                      placeholder="Manager"
                      options={manager_option}
                      value={selectedManager}
                      onChange={(val, opt) => {
                        if (val) {
                          setSelectedManager(val);
                          handleGetSchedule(opt["data-json"]);
                        }
                      }}
                    />
                  </Col>
                </Row>
              )}

              {newdateSchedule.length > 0 ? (
                <Row
                  gutter={24}
                  className="w-100 m-t-sm"
                  style={{ minHeight: "60px" }}
                >
                  <Col span={24}>
                    {Object.keys(
                      groupArrayOfObjects(newdateSchedule, "date")
                    ).map((item, index) => {
                      // console.log("groupArrayOfObjects item, index", item, index);
                      return (
                        <Space
                          direction="vertical"
                          key={index}
                          style={{
                            width: "200px",
                            marginRight: 5,
                          }}
                        >
                          <Typography.Title
                            level={5}
                            className="companyBGColor3 white p-xxs text-center"
                          >
                            {moment(item).format("ddd, MMM DD")}
                          </Typography.Title>

                          <Radio.Group
                            buttonStyle="solid"
                            onChange={(e) => handleDateSchedule(e, item)}
                            value={
                              stepData[1].data.event_schedule_id
                                ? stepData[1].data.event_schedule_id
                                : ""
                            }
                          >
                            <Space direction="vertical">
                              {newdateSchedule
                                .filter(
                                  (dateScheduleFitler) =>
                                    dateScheduleFitler.date === item
                                )
                                .map((item2, index2) => {
                                  // console.log("item2", item2);
                                  if (item2.count === 0) {
                                    return (
                                      <Radio key={index2} value={item2.id}>
                                        {item2.time_start} - {item2.time_end}
                                      </Radio>
                                    );
                                  } else {
                                    return "";
                                  }
                                })}
                            </Space>
                          </Radio.Group>
                        </Space>
                      );
                    })}
                  </Col>
                </Row>
              ) : (
                <Alert
                  // message="Warning"
                  description="No Available Schedule Slots"
                  type="warning"
                  showIcon
                  style={{ marginBottom: "20px" }}
                />
              )}
            </div>
          );
        } else {
          return (
            <Alert
              // message="Warning"
              description="No Available Schedule Slots"
              type="warning"
              showIcon
              style={{ marginBottom: "20px" }}
            />
          );
        }
      }
    } else {
      return (
        <Alert
          // message="Warning"
          description="No Available Schedule Slots"
          type="warning"
          showIcon
          style={{ marginBottom: "20px" }}
        />
      );
    }
  };

  // const [cityOption, setCityOption] = useState([]);
  // const handleStateOption = (val, opt) => {
  //   if (val) {
  //     let option = opt["data-json"];
  //     console.log("val", opt);
  //     setCityOption(option);
  //   } else {
  //     setCityOption([]);
  //   }
  //   // console.log("getFieldsValue", form3.getFieldsValue());
  //   form3.resetFields(["billing_city"]);
  // };

  const { mutate: mutateApplyCoupon, isLoading: isLoadingApplyCoupon } = POST(
    "api/v1/apply_coupon_stripe_system",
    "apply_coupon_stripe_system"
  );

  const [couponValue, setCouponValue] = useState(null);
  const [couponCode, setCouponCode] = useState();
  const handleApplyCoupon = (e) => {
    var coupon = couponValue;

    let stepDataTemp = [...stepData];
    let selected = stepDataTemp[0].data?.account_plan_id;
    let plan = accountPlanOption.filter(
      (itemFilter) => itemFilter.value === selected
    );

    let plan_total = plan[0]["json"]["amount"];
    if (freeCreditAmount) {
      plan_total =
        plan[0]["json"]["amount"] -
        freeCreditAmount.data.registration_credit_amount;
    }

    if (coupon) {
      mutateApplyCoupon(
        { code: coupon, role: userData.role, user_id: userData.id },
        {
          onSuccess: (res) => {
            if (res.success) {
              console.log("@res", res);
              if (res.data) {
                if (parseInt(res.data.max) > parseInt(res.data.redemption)) {
                  // setCouponError(true);
                  // setDataCounponSelected(res.data);
                  notification.success({
                    message: "Success",
                    description: " Code Successfully applied",
                  });
                  if (res.data.type == "fixed") {
                    let discountData = res.data.off;
                    let postTotal = plan_total - discountData;
                    postTotal = postTotal.toFixed(6);
                    postTotal = postTotal.split(".");
                    postTotal = `${postTotal[0]}.${postTotal[1].substr(0, 2)}`;
                    setAmountValue({
                      ...amountValue,
                      coupon_status: true,
                      coupon_type: "fixed",
                      discount_label: `$${res.data.off} off`,
                      sub_amount: plan_total,
                      total_amount: postTotal < 0 ? 0 : postTotal,
                    });
                  }
                  if (res.data.type == "percent") {
                    let discountData = res.data.off / 100;
                    discountData = discountData * plan_total;
                    let total = plan_total - discountData;
                    total = total.toFixed(6);
                    total = total.split(".");
                    total = `${total[0]}.${total[1].substr(0, 2)}`;
                    setAmountValue({
                      ...amountValue,
                      coupon_status: true,
                      coupon_type: "percent",
                      discount_label: `${res.data.off}% off`,
                      sub_amount: plan_total,
                      total_amount: total,
                    });
                  }
                  if (res.data.type == "offer") {
                    let discountData = res.data.off;
                    let postTotal = plan_total - discountData;
                    postTotal = postTotal.toFixed(6);
                    postTotal = postTotal.split(".");
                    postTotal = `${postTotal[0]}.${postTotal[1].substr(0, 2)}`;
                    setAmountValue({
                      ...amountValue,
                      coupon_status: true,
                      coupon_type: "offer",
                      discount_label: `$${res.data.off} off`,
                      sub_amount: plan_total,
                      total_amount: postTotal < 0 ? 0 : postTotal,
                    });
                  }
                  setCouponCode(coupon);
                } else {
                  notification.error({
                    message: "Coupon Denied",
                    description: "Coupon usage limit has been reached",
                  });
                  setCouponValue(null);
                  setAmountValue({
                    ...amountValue,
                    coupon_status: false,
                    coupon_type: "",
                    discount_label: "",
                    sub_amount: plan_total,
                    total_amount: plan_total,
                  });
                }
              }
            } else {
              setCouponValue(null);
              setAmountValue({
                ...amountValue,
                coupon_status: false,
                coupon_type: "",
                discount_label: "",
                sub_amount: plan_total,
                total_amount: plan_total,
              });
              notification.error({
                message: "Coupon Denied",
                description: res.error,
              });
            }
          },
          onError: (err) => {
            console.log("err", err);
            notification.error({
              message: "Coupon Denied",
              description: err.error,
            });
            setCouponValue(null);
            setAmountValue({
              ...amountValue,
              coupon_status: false,
              coupon_type: "",
              discount_label: "",
              sub_amount: plan_total,
              total_amount: plan_total,
            });
          },
        }
      );
    } else {
      setCouponValue(null);
      setAmountValue({
        ...amountValue,
        coupon_status: false,
        coupon_type: "",
        discount_label: "",
        sub_amount: plan_total,
        total_amount: plan_total,
      });
      notification.error({
        message: "Coupon Denied",
        description: "Coupon code is not available",
      });
    }
  };

  GET(
    `api/v1/user_athlete_info?id=${userData.id}`,
    "athletic_user_athlete_info",
    (res) => {
      if (res.data) {
        let data = res.data;
        form2.setFieldsValue({
          firstname: data.firstname,
          lastname: data.lastname,
          contact_number: data.phone_number ? data.phone_number : undefined,
          email: data.email,
        });
      }
    }
  );

  const steps = [
    {
      title: "ASSESSMENT TYPE",
      status: stepData[0].step,
      content: (
        <Row>
          <Col span={24}>
            <Typography.Text strong>Please select service:</Typography.Text>
          </Col>
          <Col span={24} className="m-t-sm">
            <Typography.Text className="companycolor1">
              Assessment Type
            </Typography.Text>
          </Col>
          <Col span={24}>
            <FloatSelect
              label="Select Service"
              placeholder="Select Service"
              options={accountPlanOption}
              onChange={handleSelectService}
              value={stepData[0].data?.account_plan_id}
              disabled={userData.status === "Cancelled" ? true : false}
              required
            />
          </Col>

          <Col xs={24} sm={12} md={8} className="m-t-lg">
            <>
              <FloatInput
                label="Coupon"
                value={couponValue}
                placeholder="Coupon"
                onChange={(e) => setCouponValue(e)}
                addonAfter={
                  <Button
                    className="btn-primary-default"
                    loading={isLoadingApplyCoupon}
                    style={{
                      height: "45px",
                      marginTop: "-1px",
                      borderRadius: "0px",
                    }}
                    onClick={(e) => handleApplyCoupon(e)}
                    disabled={userData.status === "Cancelled" ? true : false}
                  >
                    APPLY
                  </Button>
                }
                disabled={userData.status === "Cancelled" ? true : false}
              />
              <br />
            </>

            <div className="book-assessment-payment-total-info">
              <table>
                {freeCreditAmount && (
                  <thead>
                    <tr>
                      <th className="p-b-md text-left">Credit Balance:</th>
                      <th className="p-b-md"></th>
                      <th className="p-b-md text-left">
                        <NumberFormat
                          value={
                            freeCreditAmount &&
                            freeCreditAmount.data.registration_credit_amount
                            // amountValue?.credit_bal
                          }
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"$"}
                        />
                      </th>
                    </tr>
                  </thead>
                )}
                <tbody>
                  <tr>
                    <td className="font-bold">Assessment:</td>
                    <td></td>
                    <td className="font-bold">
                      <NumberFormat
                        value={amountValue.service_amount}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={"$"}
                      />
                    </td>
                  </tr>

                  {freeCreditAmount && (
                    <>
                      <tr>
                        <td className="font-bold">Credit Amount:</td>
                        <td className="font-bold">-</td>
                        <td className="font-bold">
                          <NumberFormat
                            value={
                              freeCreditAmount &&
                              freeCreditAmount.data.registration_credit_amount
                            }
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$"}
                          />
                        </td>
                      </tr>
                      {amountValue?.credit_bal && (
                        <tr>
                          <td className="font-bold">Credit Remaining:</td>
                          <td className="font-bold"></td>
                          <td className="font-bold">
                            <NumberFormat
                              value={amountValue?.credit_bal}
                              displayType={"text"}
                              thousandSeparator={true}
                              prefix={"$"}
                            />
                          </td>
                        </tr>
                      )}
                    </>
                  )}

                  {amountValue.discount_label && (
                    <tr>
                      <td className="font-bold">Discount:</td>
                      <td></td>
                      <td className="font-bold">
                        {amountValue.discount_label}
                      </td>
                    </tr>
                  )}
                  <tr>
                    <td className="font-bold">Total:</td>
                    <td></td>
                    <td className="font-bold">
                      <NumberFormat
                        value={amountValue.total_amount}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={"$"}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </Col>

          <Col span={24}>
            <Space
              className="m-t-lg"
              style={{ display: "flex", flexDirection: "row-reverse" }}
            >
              {userData.status === "Cancelled" ? (
                <Button className="btn-main-outline-2" disabled={true}>
                  Next
                </Button>
              ) : (
                <Button
                  className="btn-main-outline-2"
                  onClick={() => {
                    let stepDataTemp = [...stepData];
                    let couponData = {
                      ...stepDataTemp[0].data,
                      sub_amount: amountValue.sub_amount,
                      coupon_type: amountValue.coupon_type,
                      coupon_status: amountValue.coupon_status,
                      total_amount: amountValue.total_amount,
                      discount_label: amountValue.discount_label,
                      credit_bal: amountValue?.credit_bal,
                    };

                    if (couponCode) {
                      couponData = {
                        ...couponData,
                        coupon_code: couponCode,
                      };
                    }
                    if (stepDataTemp[0].data) {
                      stepDataTemp[0] = {
                        ...stepDataTemp[0],
                        data: couponData,
                        step: "finish",
                      };
                      stepDataTemp[1] = {
                        ...stepDataTemp[1],
                        step: "process",
                      };
                      setStepData(stepDataTemp);
                      setCurrent(current + 1);
                      console.log("handleApplyCoupon", stepDataTemp);
                    } else {
                      notification.warning({
                        message: "Book",
                        description: "Please select assessment type!",
                      });
                    }
                  }}
                >
                  Next
                </Button>
              )}
            </Space>
          </Col>
        </Row>
      ),
    },
    {
      title: "TIME",
      status: stepData[1].step,
      content: (
        <Row className="ant-row-step2-content">
          <Col span={24}>
            <Typography.Text strong>
              Below is a list of available time slots and days for your
              assessments. Click on a time slot to proceed with booking.
            </Typography.Text>
          </Col>
          <Col span={24} className="m-t-md">
            <div
              style={{
                display: "flex",
                alignItems: "flex-start",
                // width: "max-content",
                // minWidth: "100%",
                gap: "8px",
                // overflow: "scroll hidden",
                overflowX: "scroll",
                overflowY: "hidden",
                whiteSpace: "nowrap",
              }}
              className="scrollbar-2 schedule-time"
            >
              {handleRenderStep1()}
            </div>
          </Col>
          <Col span={24}>
            <Space
              className="m-t-lg"
              style={{ display: "flex", flexDirection: "row-reverse" }}
            >
              <Button
                className="btn-main-outline-2 "
                onClick={() => {
                  let stepDataTemp = [...stepData];

                  let stepDataTempIf =
                    stepDataTemp[1].data.time_start !== null &&
                    stepDataTemp[1].data.time_end !== null &&
                    stepDataTemp[1].data.date !== null &&
                    stepDataTemp[1].data.event_schedule_id !== null;

                  if (stepDataTempIf) {
                    stepDataTemp[1] = {
                      ...stepDataTemp[1],
                      step: "finish",
                    };
                    stepDataTemp[2] = {
                      ...stepDataTemp[2],
                      step: "process",
                    };
                    setStepData(stepDataTemp);
                    setCurrent(current + 1);
                  } else {
                    notification.warning({
                      message: "Book",
                      description: "Please select schedule!",
                    });
                  }
                }}
              >
                Next
              </Button>
              <Button
                className="btn-main-outline-2"
                onClick={() => {
                  let stepDataTemp = [...stepData];
                  stepDataTemp[0] = {
                    ...stepDataTemp[0],
                    step: "process",
                  };
                  stepDataTemp[1] = {
                    ...stepDataTemp[1],
                    step: "wait",
                  };
                  setStepData(stepDataTemp);
                  setCurrent(current - 1);
                }}
              >
                Back
              </Button>
            </Space>
          </Col>
        </Row>
      ),
    },
    {
      title: "DETAILS",
      status: stepData[2].step,
      content: (
        <Form name="dettails" form={form2} onFinish={handleForm2}>
          <Typography.Text strong>
            You selected a private assessment at {step3Time} on{" "}
            {stepData[1].data.date
              ? moment(stepData[1].data.date).format("MMMM DD, YYYY")
              : "-"}
            . Please make sure your details below are correct to proceed with
            booking.
          </Typography.Text>
          <br />
          <br />
          <Row gutter={12}>
            <Col span={24}></Col>
            <Col xs={24} sm={24} md={12}>
              <Form.Item name="firstname">
                <FloatInput
                  label="First Name"
                  placeholder="First Name"
                  disabled={true}
                  required
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12}>
              <Form.Item name="lastname">
                <FloatInput
                  label="Last Name"
                  placeholder="Last Name"
                  disabled={true}
                  required
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12}>
              <Form.Item
                name="contact_number"
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: "This field is required.",
                  },
                  {
                    pattern:
                      /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/,
                    message: "Invalid Phone Number",
                  },
                ]}
              >
                <FloatInputMaskNoFeedback
                  label="Contact Number"
                  placeholder="Contact Number"
                  maskLabel="contact_number"
                  maskType="999-999-9999"
                  required
                />
                {/* <FloatInputPhone
                  label="Contact Number"
                  placeholder="Contact Number"
                  maxLength={10}
                /> */}
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12}>
              <Form.Item name="email">
                <FloatInput
                  label="Email"
                  placeholder="Email"
                  disabled={true}
                  required
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Space
                className="m-t-lg"
                style={{ display: "flex", flexDirection: "row-reverse" }}
              >
                <Button className="btn-main-outline-2" htmlType="submit">
                  Next
                </Button>

                <Button
                  className="btn-main-outline-2"
                  onClick={() => {
                    let stepDataTemp = [...stepData];
                    stepDataTemp[1] = {
                      ...stepDataTemp[1],
                      step: "process",
                    };
                    stepDataTemp[2] = {
                      ...stepDataTemp[2],
                      step: "wait",
                    };
                    setStepData(stepDataTemp);
                    setCurrent(current - 1);
                  }}
                >
                  Back
                </Button>
              </Space>
            </Col>
          </Row>
        </Form>
      ),
    },
    {
      title: "PAYMENT",
      status: stepData[3].step,
      content: (
        <>
          <BookComponentStep3
            freeCreditAmount={freeCreditAmount}
            amountValue={amountValue}
            optionCountryCodes={optionCountryCodes}
            setOptionState={setOptionState}
            stateUS={stateUS}
            setOptionBillingZip={setOptionBillingZip}
            setOptionStateLabel={setOptionStateLabel}
            setZipLabel={setZipLabel}
            stateCA={stateCA}
            optionStateLabel={optionStateLabel}
            optionState={optionState}
            optionBillingZip={optionBillingZip}
            zipLabel={zipLabel}
            stepData={stepData}
            setStepData={setStepData}
            setCurrent={setCurrent}
            current={current}
            handleForm3={handleForm3}
          />
        </>
      ),
    },
    {
      title: "CONFIRMATION",
      status: stepData[4].step,
      content: (
        <Row>
          <Col span={24} className=" m-t-lg">
            <Typography.Text>
              Please review the information. Click the <b>Go Back</b> button
              below if you need to make changes.
              <br />
              Otherwise, click the{" "}
              <b>Submit button to complete your registration.</b>
            </Typography.Text>

            {paymentCharge && paymentCharge.credit_bal == 0 ? (
              <Collapse
                className="main-1-collapse m-t-md"
                expandIcon={({ isActive }) =>
                  isActive ? (
                    <span
                      className="ant-menu-submenu-arrow"
                      style={{ color: "#FFF", transform: "rotate(270deg)" }}
                    />
                  ) : (
                    <span
                      className="ant-menu-submenu-arrow"
                      style={{ color: "#FFF", transform: "rotate(90deg)" }}
                    />
                  )
                }
                expandIconPosition="right"
                defaultActiveKey={["0"]}
              >
                <Collapse.Panel
                  header="Payment Information"
                  key="0"
                  className="accordion bg-darkgray-form"
                >
                  <table>
                    <thead>
                      <tr>
                        <th className="p-b-md text-left">Credit Balance:</th>
                        <th className="p-b-md"></th>
                        <th className="p-b-md text-left">
                          <NumberFormat
                            value={paymentCharge.credit_bal}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$"}
                          />
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="font-bold">Assessment:</td>
                        <td></td>
                        <td className="font-bold">
                          <NumberFormat
                            value={paymentCharge.assessment}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$"}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className="font-bold">Credit Balance:</td>
                        <td className="font-bold">-</td>
                        <td className="font-bold">
                          <NumberFormat
                            value={paymentCharge.credit_bal}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$"}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className="font-bold">Total:</td>
                        <td></td>
                        <td className="font-bold">
                          <NumberFormat
                            value={paymentCharge.total}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$"}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className="font-bold">Balance:</td>
                        <td></td>
                        <td className="font-bold">
                          <NumberFormat
                            value={paymentCharge.balance_credit}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$"}
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </Collapse.Panel>
              </Collapse>
            ) : (
              ""
            )}

            <Collapse
              className="main-1-collapse m-t-md"
              expandIcon={({ isActive }) =>
                isActive ? (
                  <span
                    className="ant-menu-submenu-arrow"
                    style={{ color: "#FFF", transform: "rotate(270deg)" }}
                  />
                ) : (
                  <span
                    className="ant-menu-submenu-arrow"
                    style={{ color: "#FFF", transform: "rotate(90deg)" }}
                  />
                )
              }
              expandIconPosition="right"
              defaultActiveKey={["0"]}
            >
              <Collapse.Panel
                header="Assessment Information"
                key="0"
                className="accordion bg-darkgray-form"
              >
                <Typography.Title level={5} className="companyColor3">
                  {dataAssessment && dataAssessment.title
                    ? dataAssessment.title
                    : "Private"}
                </Typography.Title>

                <Typography.Title level={5} className="m-t-xs">
                  When: {moment(stepData[1].data.date).format("MMMM DD, YYYY")}{" "}
                  {moment(stepData[1].data.time_start, "hh:mm").format("LT")}
                  {" - "}
                  {moment(stepData[1].data.time_end, "hh:mm").format("LT")}
                </Typography.Title>
                <Typography.Title level={5} className="m-t-xs">
                  Type: {stepData[0].data?.program}
                </Typography.Title>

                {freeCreditAmount && (
                  <Typography.Title level={5} className="m-t-xs">
                    Credit Balance: $
                    {freeCreditAmount &&
                      freeCreditAmount.data.registration_credit_amount}
                  </Typography.Title>
                )}

                <Typography.Title level={5} className="m-t-xs">
                  Assessment: ${amountValue.service_amount}
                </Typography.Title>

                {freeCreditAmount && (
                  <>
                    <Typography.Title level={5} className="m-t-xs">
                      Credit Amount: $
                      {freeCreditAmount &&
                        freeCreditAmount.data.registration_credit_amount}
                    </Typography.Title>
                    {amountValue?.credit_bal && (
                      <Typography.Title level={5} className="m-t-xs">
                        Credit Remaining: ${amountValue?.credit_bal}
                      </Typography.Title>
                    )}
                  </>
                )}

                {amountValue.discount_label && (
                  <Typography.Title level={5} className="m-t-xs">
                    Discount: {amountValue.discount_label}
                  </Typography.Title>
                )}

                <Typography.Title level={5} className="m-t-xs">
                  Total: ${amountValue.total_amount}
                </Typography.Title>
              </Collapse.Panel>
            </Collapse>

            <Collapse
              className="main-1-collapse m-t-md"
              expandIcon={({ isActive }) =>
                isActive ? (
                  <span
                    className="ant-menu-submenu-arrow"
                    style={{ color: "#FFF", transform: "rotate(270deg)" }}
                  />
                ) : (
                  <span
                    className="ant-menu-submenu-arrow"
                    style={{ color: "#FFF", transform: "rotate(90deg)" }}
                  />
                )
              }
              expandIconPosition="right"
              defaultActiveKey={["0"]}
            >
              <Collapse.Panel
                header="Participant's Information"
                key="0"
                className="accordion bg-darkgray-form"
              >
                <Typography.Title level={5} className="m-t-xs">
                  First Name:{" "}
                  <span style={{ fontWeight: "normal" }}>
                    {stepData[2].data.firstname}
                  </span>
                </Typography.Title>
                <Typography.Title level={5} className="m-t-xs">
                  Last Name:{" "}
                  <span style={{ fontWeight: "normal" }}>
                    {stepData[2].data.lastname}
                  </span>
                </Typography.Title>
                <Typography.Title level={5} className="m-t-xs">
                  Email:{" "}
                  <span style={{ fontWeight: "normal" }}>
                    {stepData[2].data.email}
                  </span>
                </Typography.Title>
                <Typography.Title level={5} className="m-t-xs">
                  Phone:{" "}
                  <span style={{ fontWeight: "normal" }}>
                    {stepData[2].data.contact_number}
                  </span>
                </Typography.Title>
              </Collapse.Panel>
            </Collapse>

            <Collapse
              className="main-1-collapse m-t-md"
              expandIcon={({ isActive }) =>
                isActive ? (
                  <span
                    className="ant-menu-submenu-arrow"
                    style={{ color: "#FFF", transform: "rotate(270deg)" }}
                  />
                ) : (
                  <span
                    className="ant-menu-submenu-arrow"
                    style={{ color: "#FFF", transform: "rotate(90deg)" }}
                  />
                )
              }
              expandIconPosition="right"
              defaultActiveKey={["0"]}
            >
              <Collapse.Panel
                header="Billing Information"
                key="0"
                className="accordion bg-darkgray-form"
              >
                <Typography.Title level={5} className="m-t-xs">
                  Billing Name:{" "}
                  <span style={{ fontWeight: "normal" }}>
                    {stepData[3].data.card_name}
                  </span>
                </Typography.Title>
                <Typography.Title level={5} className="m-t-xs">
                  Card Number:{" "}
                  <span style={{ fontWeight: "normal" }}>
                    {stepData[3].data.card_number}
                  </span>
                </Typography.Title>
                <Typography.Title level={5} className="m-t-xs">
                  Card Exp.:{" "}
                  <span style={{ fontWeight: "normal" }}>
                    {stepData[3].data.card_expiry}
                  </span>
                </Typography.Title>
                <Typography.Title level={5} className="m-t-xs">
                  Security Code:{" "}
                  <span style={{ fontWeight: "normal" }}>
                    {stepData[3].data.card_cvv}
                  </span>
                </Typography.Title>
                <Typography.Title level={5} className="m-t-xs">
                  Address:{" "}
                  <span style={{ fontWeight: "normal" }}>
                    {stepData[3].data.billing_address1}
                  </span>
                </Typography.Title>
                <Typography.Title level={5} className="m-t-xs">
                  City, State, Zip:{" "}
                  <span style={{ fontWeight: "normal" }}>
                    {stepData[3].data.billing_city}
                    {", "}
                    {stepData[3].data.billing_state}
                    {", "}
                    {stepData[3].data.billing_zip}
                  </span>
                </Typography.Title>
              </Collapse.Panel>
            </Collapse>
          </Col>
          <Col span={24}>
            <Space
              className="m-t-lg"
              style={{ display: "flex", flexDirection: "row-reverse" }}
            >
              <Button
                className="btn-main-outline-2"
                onClick={handleSubmit}
                // disabled={btnSubmit}
                loading={isLoadingBooking}
              >
                SUBMIT
              </Button>
              <Button
                className="btn-main-outline-2"
                onClick={() => {
                  let stepDataTemp = [...stepData];
                  stepDataTemp[3] = {
                    ...stepDataTemp[3],
                    step: "process",
                  };
                  stepDataTemp[4] = {
                    ...stepDataTemp[4],
                    step: "wait",
                  };
                  setStepData(stepDataTemp);
                  setCurrent(current - 1);

                  form3.setFieldsValue({ ...stepDataTemp[3].data });
                }}
              >
                Back
              </Button>
            </Space>
          </Col>
        </Row>
      ),
    },
    {
      title: "SUCCESS",
      status: stepData[5].step,
      content: (
        <Row>
          <Col span={24} className="text-center">
            <Typography.Text strong>
              Thank you for booking your {typeAssessment} assessment with BFSS.
              An email with the details of your booking has been sent to you.
            </Typography.Text>
          </Col>
          <Col span={24} className="text-center m-t-lg">
            <Link to="/calendar/scheduled-assessments">
              Go to Upcoming Group Assessment
            </Link>
          </Col>
        </Row>
      ),
    },
  ];

  const onChange = (current) => {
    // console.log("onChange:", current);
    // setCurrent(current);
    // if (steps[0] &&) {
    // }
  };

  useEffect(() => {
    // console.log("stepData", stepData);

    return () => {};
  }, [stepData]);

  return (
    <>
      <ComponentHeader
        title={
          typeAssessment !== "group" ? "Private Assessment" : "Group Assessment"
        }
        sub_title="BOOK A"
        icon={faCalendarAlt}
      />

      <Modal
        title={"Warning"}
        visible={warningModal}
        // closable={false}
        onCancel={(e) => history.goBack()}
        footer={false}
      >
        You can't book a assessment due to Account Cancelation
      </Modal>

      <Card
        className="page-book-assessment card-min-height"
        id="PageCalendarBookAssessments"
      >
        {/* {freeCreditAmount && (
          <Alert
            description={
              <>
                <strong>Welcome!</strong> Just a reminder that you have a $
                {freeCreditAmount.data.registration_credit_amount} credit you
                can use towards your first assessment.
              </>
            }
            type="success"
            showIcon
            className="m-b-sm"
          />
        )} */}
        <Row gutter={12} justify="center">
          <Col xs={24} sm={24} md={24} className="m-b-md">
            <Typography.Title level={4} className="book-title">
              Schedule Your {typeAssessment === "group" ? "Group" : "Private"}{" "}
              Assessment
            </Typography.Title>
          </Col>

          {current !== 5 && typeAssessment === "group" && dataAssessment && (
            <Col xs={24} sm={24} md={24} className="m-b-md">
              <Card className="m-b-md">
                <Typography.Title level={3} className="text-center">
                  {dataAssessment.title}
                </Typography.Title>
                {dataAssessment.schedule &&
                  dataAssessment.schedule.length > 0 && (
                    <Typography.Title level={4} className="text-center m-t-n">
                      {moment(dataAssessment.schedule[0].date).format(
                        "MMMM DD, YYYY"
                      )}{" "}
                      {moment(
                        dataAssessment.schedule[0].time_start,
                        "hh:mm"
                      ).format("LT")}{" "}
                      -{" "}
                      {moment(
                        dataAssessment.schedule[0].time_end,
                        "hh:mm"
                      ).format("LT")}
                      {dataAssessment.schedule.length > 1 ? (
                        <>
                          {" - "}
                          {moment(
                            dataAssessment.schedule[
                              dataAssessment.schedule.length - 1
                            ].date
                          ).format("MMMM DD, YYYY")}{" "}
                          {moment(
                            dataAssessment.schedule[
                              dataAssessment.schedule.length - 1
                            ].time_start,
                            "hh:mm"
                          ).format("LT")}
                        </>
                      ) : null}
                    </Typography.Title>
                  )}
              </Card>
            </Col>
          )}

          <Col xs={24} sm={24} md={24} className="m-b-md">
            <Steps
              current={current}
              onChange={onChange}
              size="small"
              className="ant-steps-site-navigation-calendar-bpa"
            >
              {steps.map((item) => (
                <Steps.Step
                  status={item.status}
                  key={item.title}
                  title={item.title}
                />
              ))}
            </Steps>

            <br />
            <div className="ant-steps-content-calendar-bpa">
              {steps[current].content}
            </div>
          </Col>
        </Row>
        <br />
      </Card>
    </>
  );
}
