import { Link } from "react-router-dom";
import { Menu, Typography } from "antd";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faAlarmClock,
	faMapMarkerPlus,
	faSearch,
} from "@fortawesome/pro-regular-svg-icons";

export const menuLeft = (
	<>
		<div className="ant-space-icon">
			<Link to="/user/search-athletes">
				<span className="anticon">
					<FontAwesomeIcon icon={faSearch} />
				</span>
				<Typography.Text>
					Search <br /> Users
				</Typography.Text>
			</Link>
		</div>

		<div className="ant-space-icon">
			<Link to="/assessment/pending-assessment">
				<span className="anticon">
					<FontAwesomeIcon icon={faAlarmClock} />
				</span>
				<Typography.Text>
					Pending <br /> Assessments
				</Typography.Text>
			</Link>
		</div>

		<div className="ant-space-icon">
			<Link to="/organization/add-new">
				<span className="anticon">
					<FontAwesomeIcon icon={faMapMarkerPlus} />
				</span>
				<Typography.Text>
					Add New <br /> Organization
				</Typography.Text>
			</Link>
		</div>
	</>
);

export const dropDownMenuLeft = (
	<Menu>
		<Menu.Item
			key="/user/search-athletes"
			icon={<FontAwesomeIcon icon={faSearch} />}
		>
			<Link to="/user/search-athletes">Search Users</Link>
		</Menu.Item>
		<Menu.Item
			key="/assessment/pending-assessment"
			icon={<FontAwesomeIcon icon={faAlarmClock} />}
		>
			<Link to="/assessment/pending-assessment">Pending Assessments</Link>
		</Menu.Item>
		<Menu.Item
			key="/organization/add-new"
			icon={<FontAwesomeIcon icon={faMapMarkerPlus} />}
		>
			<Link to="/organization/add-new">Add New Organization</Link>
		</Menu.Item>
	</Menu>
);
