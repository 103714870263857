import React from "react";
// import { useHistory } from "react-router-dom";
import { Button, Col, Form, Modal, notification, Row, Space } from "antd";
import { FaTimes } from "react-icons/fa";
// import companyInfo from "../../../providers/companyInfo";
import FloatInputPasswordStrength from "../../../providers/FloatInputPasswordStrength";
import { POST } from "../../../providers/useAxiosQuery";
import FloatInputPassword from "../../../providers/FloatInputPassword";

// const apiUrl = companyInfo().apiUrl;

export default function ModalProfileChangePassword(props) {
  const { toggleModalPreview, setToggleModalPreview, profileData, clear } =
    props;
  console.log("props", props);
  // let location = useHistory();
  // const userdata = companyInfo().userData;

  // useEffect(() => {
  //   console.log("toggleModalPreview", toggleModalPreview);

  //   return () => {};
  // }, [toggleModalPreview]);

  const [form] = Form.useForm();

  const onFinishForm = (values) => {
    let data = {
      ...values,
      id: profileData.id,
    };
    console.log("onFinishForm", data);

    mutatePassword(data, {
      onSuccess: (res) => {
        if (res.success) {
          // console.log(res)
          notification.success({
            message: "Success",
            description: "Successfully updated",
          });
          setToggleModalPreview(false);
          // if (userdata.role !== "Admin" && userdata.role !== "Manager") {
          if (!clear) {
            localStorage.removeItem("userdata");
            localStorage.removeItem("token");
            localStorage.removeItem("userdata_admin");
            localStorage.removeItem("viewas");
            window.location.replace("/");
          }
        }
      },
    });
  };

  const { mutate: mutatePassword } = POST(
    "api/v1/profile_change_password",
    "profile_change_password"
  );

  const validator = {
    require: {
      required: true,
      message: "Required",
    },
    password: {
      min: 8,
      message: "Password must be minimum 8 characters.",
    },
  };

  return (
    <>
      <Modal
        visible={toggleModalPreview}
        width={400}
        onCancel={(e) => setToggleModalPreview(!toggleModalPreview)}
        zIndex={3}
        closeIcon={<FaTimes />}
        title="CHANGE PASSWORD"
        className="assessment-modal"
        bodyStyle={{ padding: "15px" }}
        footer={[
          <Space>
            <Button
              className="btn-main-outline-2"
              onClick={(e) => {
                form
                  .validateFields()
                  .then((values) => {
                    onFinishForm(values);
                  })
                  .catch((info) => {
                    console.log("Validate Failed:", info);
                  });
              }}
            >
              UPDATE
            </Button>
          </Space>,
        ]}
      >
        <Form name="change-password" layout="vertical" form={form}>
          <Row gutter={24}>
            <Col xs={24} sm={24} md={24}>
              <Form.Item
                name="password_1"
                rules={[
                  validator.require,
                  {
                    pattern:
                      /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,64}$/,
                    message: "Invalid Password",
                  },
                ]}
              >
                {/* FloatInputPassword */}
                <FloatInputPasswordStrength
                  label="New Password"
                  placeholder="New Password"
                  required
                />
              </Form.Item>
            </Col>

            <Col xs={24} sm={24} md={24}>
              <Form.Item
                name="password_2"
                dependencies={["password_1"]}
                rules={[
                  validator.require,
                  {
                    pattern:
                      /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,64}$/,
                    message: "Invalid Password",
                  },

                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue("password_1") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error(
                          "The two passwords that you entered do not match!"
                        )
                      );
                    },
                  }),
                ]}
              >
                <FloatInputPasswordStrength
                  label="Confirm New Password"
                  placeholder="Confirm New Password"
                  required
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
}
