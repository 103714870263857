import React from "react";
import { Switch, Route } from "react-router-dom";
import companyInfo from "../../providers/companyInfo";

/** template */
import PrivateRoute from "../PrivateRoute";

import Error404 from "../../views/errors/Error404";
import Error500 from "../../views/errors/Error500";

import PageDashboard from "../../views/private/PageAthlete/PageDashboard/PageDashboard";
import PageCalendarUpcomingGroupAssessment from "../../views/private/PageAthlete/PageCalendar/PageCalendarUpcomingGroupAssessments";
import PageCalendarBookAssessments from "../../views/private/PageAthlete/PageCalendar/PageCalendarBookAssessments";
import PageCalendarScheduledAssessments from "../../views/private/PageAthlete/PageCalendar/PageCalendarScheduledAssessments";
import PageAssessmentStats from "../../views/private/PageAthlete/PageAssessmentStats/PageAssessmentStats";
import PageAthleticProfileEdit from "../../views/private/PageAthlete/PageAthleticProfile/PageAthleticProfileEdit";
import PageAthleticProfileViewWebpage from "../../views/private/PageAthlete/PageAthleticProfile/PageAthleticProfileViewWebpage";
import PageProfileEdit from "../../views/private/PageAthlete/PageProfile/PageProfileEdit";
import PageProfileManager from "../../views/private/PageAthlete/PageProfile/PageProfileManager";
import PageProfileParentGuardian from "../../views/private/PageAthlete/PageProfile/PageProfileParentGuardian";
import PageProfilePaymentReceipt from "../../views/private/PageAthlete/PageProfile/PageProfilePaymentReceipt";
import PageProfilePaymentReceiptView from "../../views/private/PageAthlete/PageProfile/PageProfilePaymentReceiptView";
import PageMessages from "../../views/private/PageMessages/PageMessages";
// import PageAssessmentStatsPDF from "../../views/private/PageAthlete/PageAssessmentStats/PageAssessmentStatsPDF";

const role = companyInfo().role;

export default function RouteAthlete() {
  return (
    <Switch>
      {/* member */}

      <PrivateRoute
        exact
        path="/dashboard"
        permission="Dashboard"
        component={PageDashboard}
        breadcrumb={[
          {
            name: "Dashboard",
            link: "/dashboard",
          },
        ]}
      />

      <PrivateRoute
        exact
        path="/calendar/all-assessments"
        component={PageCalendarUpcomingGroupAssessment}
        title="All Assessments"
        permission="Calendar - All Assessments"
        breadcrumb={[
          {
            name: "Calendar - View & Book",
            link: "",
          },
          {
            name: "All Assessments",
            link: "/calendar/all-assessments",
          },
        ]}
      />

      <PrivateRoute
        exact
        path="/calendar/book-assessment"
        component={PageCalendarBookAssessments}
        title="Book a Private Assessments"
        permission="Calendar - Book a Private Assessments"
        breadcrumb={[
          {
            name: "Calendar - View & Book",
            link: "",
          },
          {
            name: "Book a Private Assessments",
            link: "/calendar/book-assessment",
          },
        ]}
      />

      <PrivateRoute
        exact
        path="/book-assessment"
        component={PageCalendarBookAssessments}
        title="Book a Group Assessments"
        permission="Calendar - Book a Group Assessments"
        breadcrumb={[
          {
            name: "Calendar - View & Book",
            link: "",
          },
          {
            name: "Book a Group Assessments",
            link: "/book-assessment",
          },
        ]}
      />

      <PrivateRoute
        exact
        path="/calendar/scheduled-assessments"
        component={PageCalendarScheduledAssessments}
        title="My Scheduled Assessments"
        permission="Calendar - Scheduled Assessments"
        breadcrumb={[
          {
            name: "Calendar - View & Book",
            link: "",
          },
          {
            name: "My Scheduled Assessments",
            link: "/calendar/scheduled-assessments",
          },
        ]}
      />

      <PrivateRoute
        exact
        path="/assessment-stats"
        component={PageAssessmentStats}
        title="My Assessments"
        permission="My Assessment Stats"
        breadcrumb={[
          {
            name: "My Assessments",
            link: "",
          },
        ]}
      />

      <PrivateRoute
        exact
        path="/athletic-profile/edit"
        component={PageAthleticProfileEdit}
        title="Athletic Profile"
        permission="Athletic Profile - Edit"
        breadcrumb={[
          {
            name: "Dashboard",
            link: null,
          },
          {
            name: "My Athletic Profile",
            link: null,
          },
          {
            name: "Edit Athletic Profile",
            link: "/athletic-profile/edit",
          },
        ]}
      />

      {/* {(role === "Athlete" || role === "Athlete Guardian") && (
				<Route
					exact
					path="/athletic/:name"
					component={PageAthleticProfileViewWebpage}
					title="Athletic Profile"
				/>
			)} */}

      {(role === "Athlete" || role === "Athlete Guardian") && (
        <Route
          exact
          path="/athletic-profile/view-webpage"
          component={PageAthleticProfileViewWebpage}
          title=" Athletic Profile"
        />
      )}

      <PrivateRoute
        exact
        path="/profile/profile-edit"
        component={PageProfileEdit}
        title="Edit Profile"
        permission="Athletic Profile - Edit Profile"
        breadcrumb={[
          {
            name: "Dashboard",
            link: "",
          },
          {
            name: "Edit Profile",
            link: "/profile/profile-edit",
          },
        ]}
      />

      <PrivateRoute
        exact
        path="/profile/manager"
        component={PageProfileManager}
        title="Manager"
        permission="Athletic Profile - Manager"
        breadcrumb={[
          {
            name: "Dashboard",
            link: "",
          },
          {
            name: "BFSS Manager",
            link: "/profile/manager",
          },
        ]}
      />

      <PrivateRoute
        exact
        path="/profile/parent-guardian"
        component={PageProfileParentGuardian}
        title="Parent Guardian"
        permission="Athletic Profile - Parent Guardian"
        breadcrumb={[
          {
            name: "Dashboard",
            link: "",
          },
          {
            name: "Edit Parent/Guardian",
            link: "/profile/parent-guardian",
          },
        ]}
      />

      <PrivateRoute
        exact
        path="/profile/payments-receipts"
        component={PageProfilePaymentReceipt}
        title="Payment Receipts"
        permission="Athletic Profile - Payment Receipts"
        breadcrumb={[
          {
            name: "Dashboard",
            link: "",
          },
          {
            name: "Payment Receipts",
            link: "/profile/payments-receipts",
          },
        ]}
      />

      <PrivateRoute
        exact
        path="/profile/payments-receipts/view"
        component={PageProfilePaymentReceiptView}
        title="Payment Receipts"
        permission="Athletic Profile - Payment Receipts"
        breadcrumb={[
          {
            name: "My Athletic",
            link: "",
          },
          {
            name: "Payment Receipts",
            link: "/profile/payments-receipts/view",
          },
        ]}
      />

      <PrivateRoute
        exact
        path="/messages"
        component={PageMessages}
        title="Messages"
        permission="Messages"
        breadcrumb={[
          {
            name: "Messages",
            link: "/message",
          },
        ]}
      />

      <Route exact path="/500" component={Error500} />
      <Route exact path="/*" component={Error404} />
    </Switch>
  );
}
