import { useState } from "react";
import { Card, Col, Layout, Row, Select, Typography } from "antd";
import ComponentHeader from "../../Components/ComponentHeader";
import optionUserType from "../../../../providers/optionUserType";
import ComponentFAQAdd from "./Component/ComponentFAQAdd";
import { faLightbulbOn } from "@fortawesome/pro-regular-svg-icons";

export default function PageFAQ() {
  const [selectedUserType, setSelectedUserType] = useState();

  return (
    <Layout className="site-layout-background" id="PageFAQ">
      <ComponentHeader
        title="Questions"
        sub_title="FREQUENTLY ASKED"
        icon={faLightbulbOn}
      />

      <Card className="p-b-lg">
        <Row gutter={12}>
          {!selectedUserType && (
            <Col span={8}>
              <Typography.Text>
                Please select the FAQ account you would like to edit
              </Typography.Text>
              <Select
                className="w-100 m-t-sm"
                placeholder="Select"
                value={selectedUserType}
                onChange={(e) => setSelectedUserType(e)}
              >
                {optionUserType.map((itemUT, indexUT) => (
                  <Select.Option key={indexUT} value={itemUT.value}>
                    {itemUT.label}
                  </Select.Option>
                ))}
              </Select>
            </Col>
          )}

          {selectedUserType && (
            <Col span={24}>
              <ComponentFAQAdd />
            </Col>
          )}
        </Row>
      </Card>
    </Layout>
  );
}
