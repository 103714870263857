import { Card, Col, Row, Space, Table, Typography } from "antd";
import { faCreditCard, faDownload } from "@fortawesome/pro-regular-svg-icons";
import ComponentHeader from "../../Components/ComponentHeader";
import {
  TableGlobalSearch,
  TablePageSize,
  TablePagination,
  TableShowingEntries,
} from "../../Components/ComponentTableFilter";
import { useEffect, useState } from "react";
import { GET } from "../../../../providers/useAxiosQuery";
import $ from "jquery";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { Link } from "react-router-dom";
// import moment from "moment";

export default function PageProfilePaymentReceipt() {
  const [tableFilter, setTableFilter] = useState({
    page: 1,
    page_size: 50,
    search: "",
    sort_field: "date_paid",
    sort_order: "desc",
  });

  const [dataSource, setDataSource] = useState([]);
  const {
    data: dataTable,
    refetch: refetchTable,
    isLoading: isLoadingTable,
    isFetching: isFetchingTable,
  } = GET(
    `api/v1/user_payment?${$.param(tableFilter)}`,
    "athlete_user_payment",
    (res) => {
      if (res.data) {
        console.log("dataTable", res);
        setDataSource(res.data.data);
        // setTableTotal(res.data.total);
      }
    }
  );

  const [searchText, setSearchText] = useState("");
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setTableFilter({
        ...tableFilter,
        search: searchText,
        page: 1,
      });
    }, 1500);
    return () => {
      clearTimeout(timeoutId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText]);

  const handleTableChange = (pagination, filters, sorter) => {
    console.log(sorter.order ? sorter.order.replace("end", "") : "");
    setTableFilter({
      ...tableFilter,
      sort_field: sorter.columnKey,
      sort_order: sorter.order ? sorter.order.replace("end", "") : null,
      // page: 1,
    });
  };

  useEffect(() => {
    refetchTable();
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableFilter]);

  return (
    <>
      <ComponentHeader
        title="Payments & Receipts"
        sub_title="VIEW"
        icon={faCreditCard}
      />

      <Card className="card-min-height">
        <Row gutter={12}>
          <Col xs={24} sm={24} md={18} className="m-b-sm">
            <Space>
              <TablePageSize
                paginationFilter={tableFilter}
                setPaginationFilter={setTableFilter}
              />
            </Space>
          </Col>
          <Col xs={24} sm={24} md={6}>
            <TableGlobalSearch
              paginationFilter={searchText}
              setPaginationFilter={setSearchText}
            />
          </Col>
          <Col xs={24} sm={24} md={24} className="m-t-sm m-b-sm">
            <Table
              className="ant-table-default ant-table-striped"
              dataSource={dataSource && dataSource}
              rowKey={(record) => record.id}
              loading={isLoadingTable || isFetchingTable}
              pagination={false}
              bordered={false}
              onChange={handleTableChange}
              scroll={{ x: "max-content" }}
            >
              <Table.Column
                title="Receipt Pdf"
                key="receipt_url"
                dataIndex="receipt_url"
                render={(text, record) => {
                  return record.description !== "Free Registration" && text ? (
                    <Typography.Link
                      href={text}
                      target="new"
                      className="companyColor3"
                    >
                      <FontAwesomeIcon icon={faDownload} className="m-r-xs" />
                      {" Download"}
                    </Typography.Link>
                  ) : (
                    ""
                  );
                }}
              />
              <Table.Column
                title="Paid Date"
                key="date_paid"
                dataIndex="date_paid"
                defaultSortOrder={"descend"}
                // render={(text, record) => moment(text).format("MMMM DD, YYYY")}
                sorter={true}
                align="center"
              />
              <Table.Column
                title="Description"
                key="description"
                dataIndex="description"
                sorter={true}
              />
              <Table.Column
                title="Amount"
                key="amount_paid"
                dataIndex="amount_paid"
                // render={(text, record) => "$ " + (text !== null ? text : 0)}
                sorter={true}
                align="center"
              />
              <Table.Column
                title="Assessment Date"
                key="date_assessment"
                dataIndex="date_assessment"
                align="center"
                // render={(text, record) =>
                //   text ? moment(text).format("MMMM DD, YYYY") : ""
                // }
                sorter={true}
              />
            </Table>
          </Col>
          <Col xs={24} sm={24} md={24}>
            <Space className="ant-space-flex-space-between">
              <TableShowingEntries />
              <TablePagination
                paginationFilter={tableFilter}
                setPaginationFilter={setTableFilter}
                setPaginationTotal={
                  dataTable && dataTable.data && dataTable.data.total
                    ? dataTable.data.total
                    : 0
                }
                showLessItems={true}
                showSizeChanger={false}
              />
            </Space>
          </Col>
          <Col xs={24} sm={24} md={12}></Col>
        </Row>
        <br />
      </Card>
    </>
  );
}
