import { Col, Row, Space, Table } from "antd";
import React, { useState, useEffect } from "react";
import {
  TableGlobalSearch,
  TablePageSize,
  TablePagination,
  TableShowingEntries,
} from "../../../Components/ComponentTableFilter";
import $ from "jquery";
import { Link } from "react-router-dom";
import { GET } from "../../../../../providers/useAxiosQuery";
import ModalStarterProfessionalAssessment from "../Modals/ModalStarterProfessionalAssessment";
import ModalEliteAssessment from "../Modals/ModalEliteAssessment";

export default function TableGroupAssessment({ eventID, scheduleID }) {
  // const history = useHistory();
  const [tableFilter, setTableFilter] = useState({
    page: 1,
    page_size: 50,
    search: "",
    sort_field: "strdate",
    sort_order: "asc",
    event_id: eventID,
    // schedule_id: scheduleID,
  });

  const [tableTotal, setTableTotal] = useState(0);
  const [dataSource, setDataSource] = useState([]);
  const {
    refetch: refetchTable,
    isLoading: isLoadingTable,
    isFetching: isFetchingTable,
  } = GET(
    `api/v1/assessment_events_attendees?${$.param(tableFilter)}`,
    "assessment_events_attendees",
    (res) => {
      if (res.success) {
        console.log("assessment_table", res);
        setDataSource(res.data && res.data.data);
        setTableTotal(res.data.total);
      }
    }
  );

  const handleTableChange = (pagination, filters, sorter) => {
    setTableFilter({
      ...tableFilter,
      sort_field: sorter.columnKey,
      sort_order: sorter.order ? sorter.order.replace("end", "") : null,
      // page: 1,
    });
  };

  const [searchText, setSearchText] = useState("");
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setTableFilter({
        ...tableFilter,
        search: searchText,
        page: 1,
      });
    }, 1500);
    return () => {
      clearTimeout(timeoutId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText]);

  useEffect(() => {
    refetchTable();
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableFilter]);

  // const handleDelete = (id) => {
  //   let data = {
  //     status: 1,
  //     id: id,
  //   };

  //   mutateDelete(data, {
  //     onSuccess: (res) => {
  //       if (res.success) {
  //         notification.success({
  //           message: "Success",
  //           description: "Successfully deleted.",
  //         });
  //       }
  //     },
  //   });
  // };

  // const { mutate: mutateDelete, isLoading: isLoadingDelete } = UPDATE(
  //   "api/v1/athlete_assessment",
  //   "assessment_events_attendees"
  // );

  const [toggleModalPreview, setToggleModalPreview] = useState({
    show: false,
    data: null,
    type: "Starter",
  });

  return (
    <>
      <Row gutter={12}>
        <Col xs={24} sm={24} md={16} lg={16} xl={18} className="m-b-sm">
          <Space>
            <TablePageSize
              paginationFilter={tableFilter}
              setPaginationFilter={setTableFilter}
            />
          </Space>
        </Col>
        <Col xs={24} sm={24} md={8} lg={8} xl={6}>
          <TableGlobalSearch
            paginationFilter={searchText}
            setPaginationFilter={setSearchText}
          />
        </Col>
      </Row>
      <Row gutter={24}>
        <Col xs={24} sm={24} md={24} className="m-t-sm m-b-sm">
          <Table
            className="ant-table-default ant-table-striped"
            loading={isLoadingTable || isFetchingTable}
            dataSource={dataSource && dataSource}
            rowKey={(record) => record.id}
            pagination={false}
            bordered={false}
            onChange={handleTableChange}
            scroll={{ x: "max-content" }}
          >
            <Table.Column
              title="Date"
              key="strdate"
              dataIndex="strdate"
              sorter={true}
              defaultSortOrder="descend"
              width={"150px"}
            />
            <Table.Column
              title="Time"
              key="strtime"
              dataIndex="strtime"
              sorter={true}
              width={"130px"}
            />
            <Table.Column
              title="Name"
              key="name"
              dataIndex="name"
              sorter={true}
              defaultSortOrder="descend"
              render={(text, record) => {
                return record.status !== "Approved" ? (
                  <Link
                    to="#"
                    className="companycolor1 cursor"
                    onClick={(e) => {
                      setToggleModalPreview({
                        show: true,
                        data: record,
                        type: record.type,
                      });
                    }}
                  >
                    {text}
                  </Link>
                ) : (
                  text
                );
              }}
              width={"300px"}
            />
            <Table.Column
              title="Type"
              key="type"
              dataIndex="type"
              sorter={true}
              width={"150px"}
            />
            <Table.Column
              title="Sport"
              key="sport"
              dataIndex="sport"
              sorter={true}
              width={"180px"}
            />
            <Table.Column
              title="Status"
              key="status"
              dataIndex="status"
              sorter={true}
              width={"150px"}
            />
          </Table>
        </Col>
        <Col xs={24} sm={24} md={24}>
          <Space className="ant-space-flex-space-between">
            <TableShowingEntries />

            <TablePagination
              paginationFilter={tableFilter}
              setPaginationFilter={setTableFilter}
              setPaginationTotal={tableTotal}
              showLessItems={true}
              showSizeChanger={false}
            />
          </Space>
        </Col>

        {toggleModalPreview.type === "Starter" ||
        toggleModalPreview.type === "Professional" ? (
          <>
            <ModalStarterProfessionalAssessment
              toggleModalPreview={toggleModalPreview}
              setToggleModalPreview={setToggleModalPreview}
              keyName="assessment_events_attendees"
            />
          </>
        ) : (
          <>
            <ModalEliteAssessment
              toggleModalPreview={toggleModalPreview}
              setToggleModalPreview={setToggleModalPreview}
              keyName="assessment_events_attendees"
            />
          </>
        )}
      </Row>
    </>
  );
}
