import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Image,
  notification,
  Row,
  Space,
  Table,
  Typography,
} from "antd";
import { RiDoubleQuotesR, RiSingleQuotesR } from "react-icons/ri";
import ComponentHeader from "../../Components/ComponentHeader";

import coinImg from "../../../../assets/img/coin.png";
import runImg from "../../../../assets/img/run.png";
import builderImg from "../../../../assets/img/builder.png";
import arrowsDataImg from "../../../../assets/img/arrows_data.png";
import jumpImg from "../../../../assets/img/jump.png";

import { faChartBar } from "@fortawesome/pro-regular-svg-icons";
import { GET, GETMANUAL } from "../../../../providers/useAxiosQuery";
import companyInfo from "../../../../providers/companyInfo";
import {
  TableGlobalSearch,
  TablePageSize,
  TablePagination,
  TableShowingEntries,
} from "../../Components/ComponentTableFilter";
import ModalPreviewAssessmentStats from "./ModalPreviewAssessmentStats";

import pdf2base64 from "pdf-to-base64";

import pdfElite from "../../../../providers/pdf/pdfElite";
import pdfStarter from "../../../../providers/pdf/pdfStarter";
import pdfPro from "../../../../providers/pdf/pdfPro";

// import pdfFileElite from "../../../../assets/pdf/2022-June-BFS-Elite-New-Report-BLANK.pdf";
// import pdfFileStarter from "../../../../assets/pdf/2022-June-BFS-New-Starter-Report-BLANK.pdf";
// import pdfFileProfessional from "../../../../assets/pdf/2022-June-BFS-New-Professional-Report-BLANK.pdf";

import average from "../../../../providers/pdf/average";
import newtonPound from "../../../../providers/pdf/newtonPound";
// import averageText from "../../../../providers/pdf/averageText";
// import averagePercentage from "../../../../providers/pdf/averagePercentage";
import truncateDecimalNumber from "../../../../providers/pdf/truncateDecimalNumber";
import $ from "jquery";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf } from "@fortawesome/pro-solid-svg-icons";
// import moment from "moment";

const userData = companyInfo().userData;

export default function PageAssessmentStats() {
  const [pdfUrlElite, setPdfUrlElite] = useState();
  const [pdfUrlStarter, setPdfUrlStarter] = useState();
  const [pdfUrlPro, setPdfUrlPro] = useState();

  useEffect(() => {
    let elite =
      process.env.REACT_APP_URL +
      "pdf/2022-June-BFS-Elite-New-Report-BLANK.pdf";
    let starter =
      process.env.REACT_APP_URL +
      "pdf/2022-June-BFS-New-Starter-Report-BLANK.pdf";
    let pro =
      process.env.REACT_APP_URL +
      "pdf/2022-June-BFS-New-Professional-Report-BLANK.pdf";
    // elite
    pdf2base64(elite)
      .then((response) => {
        setPdfUrlElite(response);
      })
      .catch((error) => {
        console.log(error); //Exepection error....
      });
    // starter
    pdf2base64(starter)
      .then((response) => {
        // console.log("response", response); //cGF0aC90by9maWxlLmpwZw==
        setPdfUrlStarter(response);
      })
      .catch((error) => {
        console.log(error); //Exepection error....
      });
    // pro
    pdf2base64(pro)
      .then((response) => {
        // console.log("response", response); //cGF0aC90by9maWxlLmpwZw==
        setPdfUrlPro(response);
      })
      .catch((error) => {
        console.log(error); //Exepection error....
      });
  }, []);

  // console.log("userData", userData);

  const [toggleModalPreviewAssessment, setToggleModalPreviewAssessment] =
    useState({
      toggle: false,
      data: null,
    });

  const [tableFilter, setTableFilter] = useState({
    page: 1,
    page_size: 50,
    sort_field: "date_assessment",
    sort_order: "desc",
    user_id: userData.id,
    // from: "Athlete Stats",
  });

  const [latestAssessmentSnapshot, setLatestAssessmentSnapshot] = useState();

  GET(
    `api/v1/athlete_assessment_stats_approved`,
    "athlete_assessment_list_approved",
    (res) => {
      console.log("athlete_assessment_list_approved", res.data);
      setLatestAssessmentSnapshot(res.data);
      // if (res.data && res.data.data && res.data.data.length > 0) {
      // }
    }
  );

  const handleTableChange = (pagination, filters, sorter) => {
    setTableFilter({
      ...tableFilter,
      sort_field: sorter.columnKey,
      sort_order: sorter.order ? sorter.order.replace("end", "") : null,
      // page: 1,
    });
  };

  const {
    data: dataUserAthleteAssessment,
    isLoading: isLoadingUserAthleteAssessment,
    refetch: refetchUserAthleteAssessment,
    isFetching: isFetchingUserAthleteAssessment,
  } = GETMANUAL(
    `api/v1/athlete_assessment_stats?${new URLSearchParams(
      tableFilter
    ).toString()}`,
    "athlete_assessment_list",
    (res) => {
      console.log("athlete_assessment_list", res);
    }
  );

  const [searchText, setSearchText] = useState("");
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setTableFilter({
        ...tableFilter,
        search: searchText,
        page: 1,
      });
    }, 1500);
    return () => {
      clearTimeout(timeoutId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText]);

  useEffect(() => {
    refetchUserAthleteAssessment();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableFilter]);
  // useEffect(() => {
  //   if (pdfUrlElite && pdfUrlStarter && pdfUrlPro) {
  //     refetchUserAthleteAssessment();
  //   }
  // }, [tableFilter, pdfUrlElite, pdfUrlStarter, pdfUrlPro]);

  const [pdfDownload, setPdfDownload] = useState({
    type: "",
    data: [],
    permission: [],
  });
  const [pdfData, setPdfData] = useState();
  const handlePdf = (record) => {
    let data = record.pdf_data ? JSON.parse(record.pdf_data) : "";
    $(".globalLoading").removeClass("hide");
    setPdfData(record);
    setPdfDownload({
      type: record.type,
      data: data,
      permission: record.athlete_pdf_display_data,
    });
  };

  useEffect(() => {
    if (pdfData) {
      const timeoutId = setTimeout(() => {
        if (
          pdfDownload.type === "Starter" ||
          pdfDownload.type === "Starter $29.99"
        ) {
          pdfStarter(pdfUrlStarter, pdfDownload.data, pdfDownload.permission);
        } else if (
          pdfDownload.type === "Professional" ||
          pdfDownload.type === "Professional $69.99"
        ) {
          pdfPro(pdfUrlPro, pdfDownload.data, pdfDownload.permission);
        } else if (
          pdfDownload.type === "Elite" ||
          pdfDownload.type === "Elite $199.99"
        ) {
          pdfElite(pdfUrlElite, pdfDownload.data, pdfDownload.permission);
        }
        console.log(pdfDownload);
        setPdfDownload({
          type: "",
          data: [],
          permission: [],
        });
        setPdfData("");
        $(".globalLoading").addClass("hide");
      }, 2000);

      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [pdfDownload]);

  return (
    <>
      <ComponentHeader
        title="Stats"
        sub_title="My Assessment"
        icon={faChartBar}
        iconClassName="ant-btn-quick-link-svg-512-448 fs40px"
      />

      <Card
        className="ant-card-my-assessment-stats"
        id="PageAssessmentStats-bu"
      >
        <Card
          className="card-dark-head"
          title="ASSESSMENT SNAPSHOT"
          bordered={false}
        >
          <Row gutter={12}>
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={24}
              className="ant-col-my-reactive-strength"
            >
              <Space className="space">
                <Space className="space-body">
                  <Typography.Title level={1} className="snap-data">
                    {latestAssessmentSnapshot &&
                    latestAssessmentSnapshot.cmj_rsi_no
                      ? latestAssessmentSnapshot.cmj_rsi_no
                      : "N/A"}{" "}
                    {/* <RiSingleQuotesR /> */}
                  </Typography.Title>

                  <Space direction="vertical">
                    <Typography.Title level={2}>
                      <span>REACTIVE</span> <span>STRENGTH (RSI)</span>
                    </Typography.Title>

                    <Typography.Title level={4}>
                      Multi-Rebound Jump Test (RSI)
                    </Typography.Title>
                  </Space>
                </Space>

                <Image src={coinImg} preview={false} />
              </Space>
            </Col>
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={24}
              xl={12}
              className="ant-col-speed-acceleration m-t-sm"
            >
              <Space size={12} className="space">
                <Space direction="vertical" className="space-body">
                  <Typography.Title level={1} className="snap-data">
                    {latestAssessmentSnapshot &&
                    latestAssessmentSnapshot.sprint_time_four_meter
                      ? latestAssessmentSnapshot.sprint_time_four_meter
                      : "N/A"}
                    {"/"}
                    {latestAssessmentSnapshot &&
                    latestAssessmentSnapshot.sprint_time_tem_meter
                      ? latestAssessmentSnapshot.sprint_time_tem_meter
                      : "N/A"}
                  </Typography.Title>

                  <Typography.Title level={4}>
                    <span>SPEED / ACCELERATION</span>{" "}
                    <span>40YD/10YD (SECS)</span>
                  </Typography.Title>
                </Space>

                <Image src={runImg} preview={false} />
              </Space>
            </Col>
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={24}
              xl={12}
              className="ant-col-maximal-strength m-t-sm"
            >
              <Space size={12} className="space">
                <Space size={20} className="space-body">
                  <Typography.Title level={1} className="snap-data">
                    {latestAssessmentSnapshot &&
                    latestAssessmentSnapshot.ms_peak_force
                      ? newtonPound(
                          parseFloat(latestAssessmentSnapshot.ms_peak_force)
                        )
                      : "N/A"}
                    {/* {latestAssessmentSnapshot &&
                    latestAssessmentSnapshot.ms_peak_force &&
                    latestAssessmentSnapshot.ms_rfd
                      ? truncateDecimalNumber(
                          average(
                            parseFloat(
                              newtonPound(
                                parseFloat(
                                  latestAssessmentSnapshot.ms_peak_force
                                )
                              )
                            ),
                            parseFloat(
                              newtonPound(latestAssessmentSnapshot.ms_rfd)
                            )
                          )
                        )
                      : "N/A"} */}
                  </Typography.Title>

                  <Space direction="vertical">
                    <Typography.Title level={4}>
                      <span>MAXIMAL</span> <span>STRENGTH (LBS)</span>
                    </Typography.Title>

                    <Typography.Title level={5}>
                      <span>Isometric</span> <span>Mid-Thigh Pull</span>
                    </Typography.Title>
                  </Space>
                </Space>

                <Image src={builderImg} preview={false} />
              </Space>
            </Col>
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={24}
              xl={12}
              className="ant-col-ballistic-strength m-t-sm"
            >
              <Space size={12} className="space">
                <Space className="space-body">
                  <Typography.Title level={1} className="snap-data">
                    {latestAssessmentSnapshot &&
                    latestAssessmentSnapshot.sj_height
                      ? latestAssessmentSnapshot.sj_height
                      : "N/A"}{" "}
                    <RiDoubleQuotesR />
                  </Typography.Title>

                  <Space direction="vertical">
                    <Typography.Title level={4}>
                      BALLISTIC <br /> STRENGTH (IN)
                    </Typography.Title>

                    <Typography.Title level={5}>Squat Jump</Typography.Title>
                  </Space>
                </Space>

                <Image src={arrowsDataImg} preview={false} />
              </Space>
            </Col>
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={24}
              xl={12}
              className="ant-col-elastic-strength m-t-sm m-b-md"
            >
              <Space size={12} className="space">
                <Space className="space-body">
                  <Typography.Title level={1} className="snap-data">
                    {latestAssessmentSnapshot &&
                    latestAssessmentSnapshot.es_height
                      ? latestAssessmentSnapshot.es_height
                      : "N/A"}
                    <RiDoubleQuotesR />
                  </Typography.Title>

                  <Space>
                    <Typography.Title level={4}>
                      <span>ELASTIC</span> <span>STRENGTH (IN)</span>
                    </Typography.Title>

                    <Typography.Title level={5}>
                      Countermovement Jump
                    </Typography.Title>
                  </Space>
                </Space>

                <Image src={jumpImg} preview={false} />
              </Space>
            </Col>
            <Col xs={24} sm={24} md={16} lg={16} xl={18} className="m-b-sm">
              <Space>
                <TablePageSize
                  paginationFilter={tableFilter}
                  setPaginationFilter={setTableFilter}
                />
              </Space>
            </Col>
            <Col xs={24} sm={24} md={8} lg={8} xl={6}>
              <TableGlobalSearch
                paginationFilter={searchText}
                setPaginationFilter={setSearchText}
              />
            </Col>
            <Col span={24} className="table-responsive m-t-sm m-b-sm">
              <Table
                className="ant-table-default ant-table-striped"
                dataSource={
                  dataUserAthleteAssessment &&
                  dataUserAthleteAssessment.data.data
                }
                rowKey={(record) => record.id}
                pagination={false}
                bordered={false}
                loading={
                  isLoadingUserAthleteAssessment ||
                  isFetchingUserAthleteAssessment
                }
                onChange={handleTableChange}
                scroll={{ x: "max-content" }}
              >
                <Table.Column
                  title="Date"
                  key="date_assessment"
                  dataIndex="date_assessment"
                  defaultSortOrder={"descend"}
                  sorter={true}
                  width={"150px"}
                />
                <Table.Column
                  title="Event Title"
                  key="event_title"
                  dataIndex="event_title"
                  sorter={true}
                  width={"200px"}
                />
                <Table.Column
                  title="Report (PDFs)"
                  key="type"
                  dataIndex="type"
                  sorter={true}
                  // render={(_, record) => {
                  //   if (record.status === "Approved") {
                  //     let disabled = true;
                  //     if (record.type == "Starter") {
                  //     }
                  //     return (
                  //       <Button
                  //         type="link"
                  //         style={{ padding: "0px" }}
                  //         className={"companyColor3"}
                  //         onClick={() => handlePdf(record)}
                  //         disabled={
                  //           record.type == "Starter"
                  //             ? pdfUrlStarter
                  //               ? false
                  //               : record.type == "Professional"
                  //               ? pdfUrlPro
                  //                 ? false
                  //                 : record.type == "Elite"
                  //                 ? pdfUrlElite
                  //                   ? false
                  //                   : true
                  //                 : true
                  //               : true
                  //             : true
                  //         }
                  //         loading={
                  //           record.type == "Starter"
                  //             ? pdfUrlStarter
                  //               ? false
                  //               : record.type == "Professional"
                  //               ? pdfUrlPro
                  //                 ? false
                  //                 : record.type == "Elite"
                  //                 ? pdfUrlElite
                  //                   ? false
                  //                   : true
                  //                 : true
                  //               : true
                  //             : true
                  //         }
                  //       >
                  //         {record.type}
                  //       </Button>
                  //     );
                  //   } else {
                  //     return record.type;
                  //   }
                  // }}
                  render={(_, record) => {
                    if (record.status === "Approved") {
                      if (record.type == "Starter") {
                        return (
                          <Button
                            className="companyColor3"
                            style={{ padding: "0px" }}
                            type="link"
                            size="small"
                            onClick={() => handlePdf(record)}
                            disabled={pdfUrlStarter ? false : true}
                            loading={pdfUrlStarter ? false : true}
                          >
                            {record.type}
                          </Button>
                        );
                      } else if (record.type == "Professional") {
                        return (
                          <Button
                            className="companyColor3"
                            style={{ padding: "0px" }}
                            type="link"
                            size="small"
                            onClick={() => handlePdf(record)}
                            disabled={pdfUrlPro ? false : true}
                            loading={pdfUrlPro ? false : true}
                          >
                            {record.type}
                          </Button>
                        );
                      } else if (record.type == "Elite") {
                        return (
                          <Button
                            className="companyColor3"
                            style={{ padding: "0px" }}
                            type="link"
                            size="small"
                            onClick={() => handlePdf(record)}
                            disabled={pdfUrlElite ? false : true}
                            loading={pdfUrlElite ? false : true}
                          >
                            {record.type}
                          </Button>
                        );
                      }
                    } else {
                      return record.type;
                    }
                  }}
                  width={"350px"}
                />
                <Table.Column
                  title="Location"
                  key="location"
                  dataIndex="location"
                  sorter={true}
                  width={"150px"}
                />
                <Table.Column
                  title="Status"
                  key="status"
                  dataIndex="status"
                  sorter={true}
                  width={"150px"}
                />
              </Table>
            </Col>
            <Col xs={24} sm={24} md={24}>
              <Space className="ant-space-flex-space-between">
                <TableShowingEntries />

                <TablePagination
                  paginationFilter={tableFilter}
                  setPaginationFilter={setTableFilter}
                  setPaginationTotal={
                    dataUserAthleteAssessment &&
                    dataUserAthleteAssessment.data.total
                  }
                  showLessItems={true}
                  showSizeChanger={false}
                />
              </Space>
            </Col>
            <Col xs={24} sm={24} md={12}></Col>
          </Row>
          <br />
        </Card>
      </Card>

      <ModalPreviewAssessmentStats
        toggleModalPreviewAssessment={toggleModalPreviewAssessment}
        setToggleModalPreviewAssessment={setToggleModalPreviewAssessment}
      />
    </>
  );
}
