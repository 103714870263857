import React, { Fragment, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Button, Col, Image, Modal, notification, Row, Typography } from "antd";
import moment from "moment";
import { FaTimes } from "react-icons/fa";
import companyInfo from "../../../providers/companyInfo";
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  EmailShareButton,
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
  EmailIcon,
  FacebookShareCount,
} from "react-share";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCommentDots, faTicketAlt } from "@fortawesome/pro-regular-svg-icons";
import { POST } from "../../../providers/useAxiosQuery";

// import { ShareButton } from "react-custom-share";
// import { css } from "emotion";

const apiUrl = companyInfo().apiUrl;
const userdata = companyInfo().userData;
const logo = companyInfo().logo;

export default function ModalAssessmentPreview(props) {
  const { toggleModalPreview, setToggleModalPreview, hideTicketingButton } =
    props;
  let location = useHistory();

  // console.log("props", props);

  // console.log("toggleModalPreview", toggleModalPreview.data);

  const { mutate: mutateStartNewConvo } = POST(
    "api/v1/message",
    `message_${userdata.id}`
  );

  const { mutate: mutateNewMessageConvo } = POST("api/v1/message_convo");

  const handleMakeNewConvo = (user_id) => {
    // console.log("eventData", eventData);
    let data = {
      to_id: user_id,
      from_id: userdata.id,
    };
    // console.log(data);
    mutateStartNewConvo(data, {
      onSuccess: (res) => {
        // console.log(res);
        if (res.success) {
          handleNewMessageConvo(res.data.id, user_id);
        } else {
          notification.error({ message: res.data });
        }
      },
    });
  };

  const handleNewMessageConvo = (message_id, user_id) => {
    let messageText = `Hello! I want to inquire about the Assessment on ${toggleModalPreview.data.title}`;
    let data = {
      from_id: userdata.id,
      to_id: user_id,
      message_id: message_id,
      message: messageText,
    };
    // console.log(data);
    mutateNewMessageConvo(data, {
      onSuccess: (res) => {
        // console.log(res);
        location.push("/messages?message_id=" + message_id);
      },
    });
  };

  useEffect(() => {
    console.log("toggleModalPreview", toggleModalPreview);

    return () => {};
  }, [toggleModalPreview]);

  function stripHtml(html) {
    let tmp = document.createElement("DIV");
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || "";
  }

  return (
    <>
      <Modal
        visible={toggleModalPreview.show}
        width={600}
        footer={null}
        onCancel={(e) =>
          setToggleModalPreview({ ...toggleModalPreview, show: false })
        }
        zIndex={3}
        closeIcon={<FaTimes />}
        className="assessment-modal"
      >
        <Row gutter={0}>
          <Col xs={24} sm={24} md={14} style={{ background: "#e4e4e4" }}>
            <Image
              src={
                toggleModalPreview.data && toggleModalPreview.data.upload
                  ? apiUrl + toggleModalPreview.data.upload
                  : logo
              }
              preview={false}
              //   style={{ width: "100%" }}
            />
          </Col>
          <Col xs={24} sm={24} md={10} className="assessment-modal-title">
            <div className="container-padding book-assessment-modal-basic-info">
              <Typography.Title level={5} className="dddd">
                {toggleModalPreview.data && toggleModalPreview.data.day_modal}
              </Typography.Title>
              <Typography.Title level={5} className="mmmmdo">
                {toggleModalPreview.data && toggleModalPreview.data.month_modal}
              </Typography.Title>
              <Typography.Title level={5} className="title-label">
                {toggleModalPreview.data && toggleModalPreview.data.title}
              </Typography.Title>
              {userdata.role === "Athlete" ||
              userdata.role === "Athlete Guardian" ||
              userdata.role === "Coach" ? (
                <div className="btn-chat-with-container">
                  <Button
                    tyle="link"
                    // className="btn-primary"
                    className="btn-chat-with"
                    icon={<FontAwesomeIcon icon={faCommentDots} />}
                    onClick={(e) =>
                      handleMakeNewConvo(toggleModalPreview.data.user_id)
                    }
                  >
                    Chat with BFSS Manager
                  </Button>
                </div>
              ) : (
                ""
              )}
            </div>
          </Col>
        </Row>
        <Row
          gutter={0}
          style={{
            borderBottom: "1px solid #ff8303",
            height: "41px",
            // marginBottom: "40px",
          }}
        >
          <Col xs={24} sm={24} md={14} lg={14}>
            <div className="section-share">
              <p className="section-share-text">SHARE</p>
              <span className="section-share-button-icon">
                <FacebookShareButton
                  url={
                    apiUrl +
                    "share-media-content/" +
                    (toggleModalPreview.data && toggleModalPreview.data.id)
                  }
                  quote={
                    toggleModalPreview.data && toggleModalPreview.data.title
                  }
                  // hashtag={`#${toggleModalPreview.data.title}`}
                  description={stripHtml(
                    toggleModalPreview.data &&
                      toggleModalPreview.data.description
                  )}
                  className="Demo__some-network__share-button"
                >
                  <FacebookIcon
                    size={25}
                    style={{ marginBottom: "-5px", marginLeft: 5 }}
                    round
                  />
                </FacebookShareButton>
              </span>
              <span className="section-share-button-icon">
                <TwitterShareButton
                  url={
                    apiUrl +
                    "share-media-content/" +
                    (toggleModalPreview.data && toggleModalPreview.data.id)
                  }
                  // title={
                  //   toggleModalPreview.data && toggleModalPreview.data.title
                  // }
                  title={
                    `Title ${
                      toggleModalPreview.data && toggleModalPreview.data.title
                    } \nDescription: ${stripHtml(
                      toggleModalPreview.data &&
                        toggleModalPreview.data.description
                    )}\n`.substring(0, 135) + "..."
                  }
                  className="Demo__some-network__share-button"
                >
                  <TwitterIcon
                    size={25}
                    style={{ marginBottom: "-5px", marginLeft: 5 }}
                    round
                  />
                </TwitterShareButton>
              </span>
              <span className="section-share-button-icon">
                <LinkedinShareButton
                  url={
                    apiUrl +
                    "share-media-content/" +
                    (toggleModalPreview.data && toggleModalPreview.data.id)
                  }
                  className="Demo__some-network__share-button"
                >
                  <LinkedinIcon
                    size={25}
                    style={{ marginBottom: "-5px", marginLeft: 5 }}
                    round
                  />
                </LinkedinShareButton>
              </span>
              <span className="section-share-button-icon">
                <EmailShareButton
                  url={
                    apiUrl +
                    "share-media-content/" +
                    (toggleModalPreview.data && toggleModalPreview.data.id)
                  }
                  subject={
                    toggleModalPreview.data && toggleModalPreview.data.title
                  }
                  body={
                    (toggleModalPreview.data && toggleModalPreview.data.title) +
                    "\n" +
                    "When: " +
                    moment(
                      toggleModalPreview.data &&
                        toggleModalPreview.data.schedule[0].date
                    ).format("DD MMM, YYYY") +
                    "-" +
                    moment(
                      toggleModalPreview.data &&
                        toggleModalPreview.data.schedule[
                          toggleModalPreview.data.schedule.length - 1
                        ].date
                    ).format("DD MMM, YYYY") +
                    "\n" +
                    "Description: " +
                    stripHtml(
                      toggleModalPreview.data &&
                        toggleModalPreview.data.description
                    ) +
                    "\n" +
                    "Please copy the link below to see the content" +
                    "\n"
                  }
                  className="Demo__some-network__share-button"
                  openShareDialogOnClick
                >
                  <EmailIcon
                    size={25}
                    style={{ marginBottom: "-5px", marginLeft: 5 }}
                    round
                  />
                </EmailShareButton>
              </span>
            </div>
          </Col>
          <Col xs={24} sm={24} md={10} lg={10}>
            {!hideTicketingButton ? (
              <>
                {(userdata.role === "Athlete" ||
                  userdata.role === "Athlete Guardian") && (
                  <>
                    {toggleModalPreview.from !== "my_assessments" &&
                    toggleModalPreview.from !== "athlete_my_assessments" ? (
                      <>
                        {toggleModalPreview.data &&
                        toggleModalPreview.data.assessments &&
                        toggleModalPreview.data.assessments.length > 0 ? (
                          ""
                        ) : (
                          <>
                            {userdata.status === "Cancelled" ? (
                              <Button
                                className="btn-main-outline-2 border-bottom-none"
                                icon={
                                  <FontAwesomeIcon
                                    icon={faTicketAlt}
                                    className="m-r-xs rotate-315"
                                  />
                                }
                                size="large"
                                style={{
                                  borderRadius: "0px",
                                  marginBottom: "50px",
                                }}
                                block
                                disabled={
                                  userdata.status === "Cancelled" ? true : false
                                }
                                onClick={() =>
                                  location.push({
                                    pathname: "/book-assessment",
                                    state: {
                                      data: toggleModalPreview.data,
                                      type: "group",
                                    },
                                  })
                                }
                              >
                                Book Now
                              </Button>
                            ) : (
                              <Button
                                className="btn-main-outline-2 border-bottom-none"
                                icon={
                                  <FontAwesomeIcon
                                    icon={faTicketAlt}
                                    className="m-r-xs rotate-315"
                                  />
                                }
                                size="large"
                                style={{
                                  borderRadius: "0px",
                                  marginBottom: "50px",
                                }}
                                block
                                onClick={() =>
                                  location.push({
                                    pathname: "/book-assessment",
                                    state: {
                                      data: toggleModalPreview.data,
                                      type: "group",
                                    },
                                  })
                                }
                              >
                                Book Now
                              </Button>
                            )}
                          </>
                        )}
                      </>
                    ) : null}
                  </>
                )}
              </>
            ) : null}
          </Col>
        </Row>
        {/* {!hideTicketingButton ? (
          <>
            {(userdata.role === "Athlete" ||
              userdata.role === "Athlete Guardian") && (
              <>
                {toggleModalPreview.from !== "my_assessments" &&
                toggleModalPreview.from !== "athlete_my_assessments" ? (
                  <>
                    <br />
                    <br />
                  </>
                ) : null}
              </>
            )}
          </>
        ) : null} */}

        <Row gutter={0} className="mobile-content-card">
          <Col xs={24} sm={24} md={14} lg={14}>
            <div className="container-padding book-assessment-modal-info-description m-t-md">
              <div
                className="scrollbar-2"
                style={{ minHeight: "500px", overflowY: "auto" }}
              >
                <div style={{ textTransform: "uppercase" }}>
                  <b>description</b>
                </div>
                <div
                // className="modal-desc scrollbar-2"
                >
                  <span
                    dangerouslySetInnerHTML={{
                      __html:
                        toggleModalPreview.data &&
                        toggleModalPreview.data.description,
                    }}
                  />
                </div>
              </div>
            </div>
          </Col>
          <Col xs={24} sm={24} md={10} lg={10}>
            <div className="container-padding">
              <div className="m-t-md">
                <div style={{ textTransform: "uppercase" }}>
                  <b>DATE AND TIME</b>
                </div>
                {toggleModalPreview.data &&
                  toggleModalPreview.data.schedule.map((item, key) => {
                    return (
                      <Fragment key={key}>
                        <span>
                          {moment(item.date).format("MMM. DD YYYY")} -{" "}
                          {item.time_start}
                          {" - "}
                          {item.time_end}
                        </span>
                        <br />
                      </Fragment>
                    );
                  })}
              </div>

              <div className="m-t-md">
                <div style={{ textTransform: "uppercase" }}>
                  <b>categories</b>
                </div>
                {toggleModalPreview.data &&
                  toggleModalPreview.data.category.map((item, key) => {
                    return (
                      <Fragment key={key}>
                        <span key={key}>{item.category}</span>
                        <br />
                      </Fragment>
                    );
                  })}
              </div>

              <div className="m-t-md">
                <div style={{ textTransform: "uppercase" }}>
                  <b>tags</b>
                </div>
                {toggleModalPreview.data &&
                  toggleModalPreview.data.tag.map((item, key) => {
                    console.log("item-tags", item);
                    return (
                      <Fragment key={key}>
                        <span key={key}>{item.tag}</span>
                        <br />
                      </Fragment>
                    );
                  })}
              </div>

              <div className="m-t-md">
                <div style={{ textTransform: "uppercase" }}>
                  <b>location</b>
                </div>
                <span>
                  {toggleModalPreview.data && toggleModalPreview.data.location}
                </span>
                <br />
                <span>
                  {toggleModalPreview.data && toggleModalPreview.data.address_1}
                </span>
                <br />
                <span>
                  {toggleModalPreview.data && toggleModalPreview.data.city}
                  {", "}
                  {toggleModalPreview.data && toggleModalPreview.data.state}
                  {", "}
                  {toggleModalPreview.data && toggleModalPreview.data.zip}
                </span>
                <br />
                <span>
                  {toggleModalPreview.data &&
                    toggleModalPreview.data.locations.country}
                </span>
              </div>

              <div className="m-t-md">
                <div style={{ textTransform: "uppercase" }}>
                  <b>ASSESSMENT TYPE</b>
                </div>
                <span>
                  {toggleModalPreview.data && toggleModalPreview.data.type}
                </span>
              </div>
            </div>
          </Col>
        </Row>
        <br />
      </Modal>
    </>
  );
}
