import React, { useState, useEffect } from "react";
import {
  Layout,
  Row,
  Col,
  Table,
  Space,
  Button,
  Modal,
  Form,
  notification,
  // Divider,
  Popconfirm,
} from "antd";
// import ComponentFaqs from "../Components/ComponentFaqs";
import { PlusOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";
import $ from "jquery";
import { DELETE, GET, POST } from "../../../../../providers/useAxiosQuery";
// import { useHistory } from "react-router-dom";
import FloatInput from "../../../../../providers/FloatInput";

import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from "react-sortable-hoc";

import { MenuOutlined } from "@ant-design/icons";
import { arrayMoveImmutable } from "array-move";

import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import ImageResize from "quill-image-resize-module-react";
import {
  TableGlobalSearch,
  TablePageSize,
  TablePagination,
  TableShowingEntries,
} from "../../../Components/ComponentTableFilter";
Quill.register("modules/imageResize", ImageResize);

const DragHandle = SortableHandle(() => (
  <MenuOutlined style={{ cursor: "grab", color: "#999" }} />
));

const SortableItem = SortableElement((props) => <tr {...props} />);
const SortableBody = SortableContainer((props) => <tbody {...props} />);

export default function TableFaqs({ id }) {
  // const sub_title = "View";
  // const history = useHistory();
  const [form] = Form.useForm();

  const modulesToolBar = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["image", "video"],
      ["clean"],
    ],
    imageResize: {
      modules: ["Resize", "DisplaySize"],
    },
  };

  const formats = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "video",
  ];
  const [responseData, setResponseData] = useState({
    response: "",
  });
  const [tableTotal, setTableTotal] = useState(0);
  const [dataTableInfo, setDataTableInfo] = useState({
    search: "",
    state: "",
    page: 1,
    page_size: "50",
    sort_field: "index",
    sort_order: "asc",
    account_type_id: id,
  });
  const [data, setData] = useState([]);
  const {
    // data: dataGetProfiles,
    isLoading: isLoadingGetProfiles,
    isFetching: isFetchingTable,
    refetch: refetchGetProfiles,
  } = GET(`api/v1/faq?${$.param(dataTableInfo)}`, "faq", (res) => {
    if (res.success) {
      console.log("faq", res);
      let arr = [];
      res.data.data.map((row, key) => {
        arr.push({
          key: row.id,
          index: key,
          account_type_id: row.account_type_id,
          title: row.title,
          description: row.description,
          id: row.id,
        });
        return "";
      });
      setTableTotal(res.data.total);
      setData(arr);
    }
  });

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setDataTableInfo({
        ...dataTableInfo,
        account_type_id: id,
      });
    });
    return () => {
      clearTimeout(timeoutId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const handleTableChange = (pagination, filters, sorter) => {
    setDataTableInfo({
      ...dataTableInfo,
      sort_field: sorter.columnKey,
      sort_order: sorter.order ? sorter.order.replace("end", "") : null,
      // page: 1,
      // page_size: "50",
    });
  };

  const [searchText, setSearchText] = useState("");
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setDataTableInfo({
        ...dataTableInfo,
        search: searchText,
        page: 1,
      });
    }, 1500);
    return () => {
      clearTimeout(timeoutId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText]);

  useEffect(() => {
    refetchGetProfiles();
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataTableInfo]);

  const validator = {
    require: {
      required: true,
      message: "Required",
    },
  };

  const handleEdit = (record) => {
    // console.log("handleEdit", record);
    form.setFieldsValue({
      id: record.id,
      title: record.title,
      description: record.description,
    });
    setResponseData({ ...responseData, response: record.description });
    setModal(true);
  };

  const handleDelete = (record) => {
    let data = {
      id: record.id,
    };

    mutateDelete(data, {
      onSuccess: (res) => {
        if (res.success) {
          refetchGetProfiles();
          notification.success({
            message: "Success",
            description: "Succesfully Deleted",
          });
        }
      },
    });
  };

  const { mutate: mutateDelete, isLoading: isLoadingDelete } = DELETE(
    "api/v1/faq",
    "faq"
  );

  const [modal, setModal] = useState(false);
  const onFinish = (value) => {
    let data = {
      ...value,
      account_type_id: id,
    };
    console.log("onFinish", data);
    mutateAccountType(data, {
      onSuccess: (res) => {
        if (res.success) {
          refetchGetProfiles();
          notification.success({
            message: "Success",
            description: "Succesfully Submitted",
          });
          form.resetFields();
          setModal(false);
        }
      },
    });
  };

  const { mutate: mutateAccountType, isLoading: isLoadingAccountType } = POST(
    "api/v1/faq",
    "faq"
  );

  const {
    mutate: mutateSort,
    // isLoading: isLoadingSort
  } = POST("api/v1/faq_sort", "faq_sort");

  const onSortEnd = ({ oldIndex, newIndex }) => {
    // const { dataSource } = data;
    if (oldIndex !== newIndex) {
      const newData = arrayMoveImmutable(
        [].concat(data),
        oldIndex,
        newIndex
      ).filter((el) => !!el);
      console.log("Sorted items: ", newData);
      let data_sort = { sorted_data: JSON.stringify(newData) };
      mutateSort(data_sort, {
        onSuccess: (res) => {
          if (res.success) {
            console.log(res);
            notification.success({
              message: "Success",
              description: "Succesfully Save",
            });
          }
        },
      });
      setData(newData);
    }
  };

  const DraggableContainer = (props) => (
    <SortableBody
      useDragHandle
      disableAutoscroll
      helperClass="row-dragging"
      onSortEnd={onSortEnd}
      {...props}
    />
  );

  const DraggableBodyRow = ({ className, style, ...restProps }) => {
    const index = data.findIndex((x) => x.index === restProps["data-row-key"]);
    return <SortableItem index={index} {...restProps} />;
  };

  const columns = [
    {
      title: "Sort",
      dataIndex: "sort",
      width: 30,
      className: "drag-visible",
      render: () => <DragHandle />,
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      className: "drag-visible",
      sorter: true,
      width: "200px",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      className: "drag-visible",
      sorter: true,
      render: (text, record) => {
        return (
          <div
            dangerouslySetInnerHTML={{
              __html: text,
            }}
          ></div>
        );
      },
      width: "350px",
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      align: "center",
      width: "150px",
      className: "drag-visible",
      render: (text, record) => {
        return (
          <Space>
            <Button
              size="small"
              className="btn-warning-outline"
              icon={<EditOutlined />}
              onClick={(e) => handleEdit(record)}
            >
              Edit
            </Button>
            <Popconfirm
              title="Are you sure to delete this FAQ?"
              onConfirm={(e) => handleDelete(record)}
              // onCancel={cancel}
              okText="Yes"
              cancelText="No"
            >
              <Button
                size="small"
                className="btn-danger-outline"
                icon={<DeleteOutlined />}
                loading={isLoadingDelete}
                // onClick={(e) => handleDelete(record)}
              >
                Delete
              </Button>
            </Popconfirm>
          </Space>
        );
      },
    },
  ];

  return (
    <Layout.Content style={{ paddingTop: "0px" }}>
      <Button
        className="btn-main-outline-2 m-t-sm"
        icon={<PlusOutlined />}
        onClick={(e) => {
          setModal(true);
          setResponseData({
            response: "",
          });
        }}
      >
        Add FAQ's
      </Button>
      <br />
      <br />
      <Row gutter={24} className="search-table" align="bottom">
        <Col xs={24} sm={24} md={16} lg={16} xl={18} className="m-b-sm">
          <Space>
            <TablePageSize
              paginationFilter={dataTableInfo}
              setPaginationFilter={setDataTableInfo}
            />
            <br />
          </Space>
        </Col>
        <Col xs={24} sm={24} md={8} lg={8} xl={6}>
          <TableGlobalSearch
            paginationFilter={searchText}
            setPaginationFilter={setSearchText}
          />
          <br />
        </Col>
      </Row>

      <Row gutter={24}>
        <Col xs={24} sm={24} md={24} className="m-t-sm m-b-sm">
          <div className="table-responsive">
            <Table
              size="small"
              rowKey="index"
              className="ant-table-default ant-table-striped"
              loading={isLoadingGetProfiles || isFetchingTable}
              dataSource={data}
              columns={columns}
              pagination={false}
              onChange={handleTableChange}
              components={{
                body: {
                  wrapper: DraggableContainer,
                  row: DraggableBodyRow,
                },
              }}
              scroll={{ x: "max-content" }}
            ></Table>
          </div>
        </Col>
        <Col xs={24} sm={24} md={24}>
          <Space className="ant-space-flex-space-between table2">
            <TableShowingEntries />

            <TablePagination
              paginationFilter={dataTableInfo}
              setPaginationFilter={setDataTableInfo}
              setPaginationTotal={tableTotal}
              showLessItems={true}
              showSizeChanger={false}
              parentClass="table2"
            />
          </Space>
        </Col>
      </Row>

      <Modal
        title="FAQ Form"
        className="modal-login"
        visible={modal}
        width="700px"
        onCancel={(e) => {
          setModal(false);
          form.resetFields();
        }}
        footer={
          <Space>
            <Button
              onClick={(e) => {
                setModal(false);
                form.resetFields();
              }}
            >
              Cancel
            </Button>
            <Button
              className="btn-main-outline-2"
              onClick={(e) => form.submit()}
              loading={isLoadingAccountType}
            >
              Submit
            </Button>
          </Space>
        }
      >
        <Form
          layout="horizontal"
          form={form}
          onFinish={onFinish}
          initialValues={{
            description: "",
          }}
        >
          <Row gutter={24}>
            <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
              <Form.Item name="id" style={{ display: "none" }}>
                <FloatInput label="id" placeholder="id" />
              </Form.Item>
              <Form.Item name="title" rules={[validator.require]}>
                <FloatInput label="Title" placeholder="Title" required />
              </Form.Item>
            </Col>

            <Col
              xs={24}
              sm={24}
              md={24}
              lg={24}
              xl={24}
              xxl={24}
              className="quill-input"
            >
              <Form.Item name="description" rules={[validator.require]}>
                <ReactQuill
                  theme="snow"
                  style={{ height: 200 }}
                  modules={modulesToolBar}
                  formats={formats}
                  required
                />
              </Form.Item>

              <br />
              <br />
            </Col>
          </Row>
        </Form>
      </Modal>
    </Layout.Content>
  );
}
