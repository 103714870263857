import React from "react";
import { Switch, Route } from "react-router-dom";
/** template */
import PrivateRoute from "../PrivateRoute";

import Error404 from "../../views/errors/Error404";
import Error500 from "../../views/errors/Error500";

import PageDashboard from "../../views/private/PageAssessor/PageDashboard/PageDashboard";
import PageProfileEdit from "../../views/private/PageAssessor/PageProfile/PageProfileEdit";

import PageAssessment from "../../views/private/PageAssessor/PageDashboard/PageAssessment";
import PageMessages from "../../views/private/PageMessages/PageMessages";

export default function RouteAssessor() {
  return (
    <Switch>
      <PrivateRoute
        exact
        path="/dashboard"
        permission="Dashboard"
        component={PageDashboard}
        breadcrumb={[
          {
            name: "Dashboard",
            link: "/dashboard",
          },
        ]}
      />
      <PrivateRoute
        exact
        path="/profile"
        permission="Profile"
        component={PageProfileEdit}
        breadcrumb={[
          {
            name: "Dashboard",
            link: null,
          },
          {
            name: "Profile",
            link: "/profile",
          },
        ]}
      />
      <PrivateRoute
        exact
        path="/dashboard/event/:id/:event_name"
        permission="Event"
        component={PageAssessment}
        breadcrumb={[
          {
            name: "Dashboard",
            link: "/dashboard",
          },
          {
            name: "Assessment Event",
            link: "#",
          },
        ]}
      />

      <PrivateRoute
        exact
        path="/messages"
        component={PageMessages}
        title="Messages"
        permission="Messages"
        breadcrumb={[
          {
            name: "Messages",
            link: "/messages",
          },
        ]}
      />

      <Route exact path="/500" component={Error500} />
      <Route exact path="/*" component={Error404} />
    </Switch>
  );
}
