import { Card, Col, Row, Space, Table } from "antd";
import React, { useState, useEffect } from "react";
import $ from "jquery";
import {
  GET,
  // UPDATE
} from "../../../../providers/useAxiosQuery";
// import { useHistory } from "react-router-dom";

import { faCalendarAlt } from "@fortawesome/pro-regular-svg-icons";
import ComponentHeader from "../../Components/ComponentHeader";
import {
  TableGlobalSearch,
  TablePageSize,
  TablePagination,
  TableShowingEntries,
} from "../../Components/ComponentTableFilter";
import ModalPendingAssessmentPreview from "../../PageManager/PagePendingAssessments/ModalPendingAssessmentPreview";
import ModalPendingAssessmentPreviewElite from "../../PageManager/PagePendingAssessments/ModalPendingAssessmentPreviewElite";
import companyInfo from "../../../../providers/companyInfo";
import { Link } from "react-router-dom";

// import ModalStarterProfessionalAssessment from "../Modals/ModalStarterProfessionalAssessment";
// import ModalEliteAssessment from "../Modals/ModalEliteAssessment";

export default function PageEventCalendarPrivateAssessment({ permission }) {
  // const { Panel } = Collapse;
  // const history = useHistory();
  const userdata = companyInfo().userData;
  const [tableFilter, setTableFilter] = useState({
    page: 1,
    page_size: 50,
    search: "",
    sort_field: "id",
    sort_order: "desc",
    event_type: "Private",
    // manager_id: userdata.id,
  });

  const [tableTotal, setTableTotal] = useState(0);
  const [dataSource, setDataSource] = useState([]);
  const {
    refetch: refetchTable,
    isLoading: isLoadingTable,
    isFetching: isFetchingTable,
  } = GET(
    `api/v1/assessment_events_private?${$.param(tableFilter)}`,
    "assessment_events_private",
    (res) => {
      if (res.success) {
        console.log("TablePrivate", res);
        setDataSource(res.data && res.data.data);
        setTableTotal(res.data.total);
      }
    }
  );

  const handleTableChange = (pagination, filters, sorter) => {
    setTableFilter({
      ...tableFilter,
      sort_field: sorter.columnKey,
      sort_order: sorter.order ? sorter.order.replace("end", "") : null,
      page: 1,
    });
  };

  const [searchText, setSearchText] = useState("");
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setTableFilter({
        ...tableFilter,
        search: searchText,
        page: 1,
      });
    }, 1500);
    return () => {
      clearTimeout(timeoutId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText]);

  useEffect(() => {
    refetchTable();
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableFilter]);

  const [toggleModalPreview, setToggleModalPreview] = useState({
    show: false,
    data: null,
    type: "Starter",
  });

  return (
    <>
      <ComponentHeader
        title={permission}
        sub_title="PRIVATE"
        icon={faCalendarAlt}
      />
      <Card
        className="card-min-height"
        id="PageEventsCalendarPrivateAssessments"
      >
        <Row gutter={12}>
          <Col xs={24} sm={24} md={16} lg={16} xl={18} className="m-b-sm">
            <Space>
              <TablePageSize
                paginationFilter={tableFilter}
                setPaginationFilter={setTableFilter}
              />
            </Space>
          </Col>
          <Col xs={24} sm={24} md={8} lg={8} xl={6}>
            <TableGlobalSearch
              paginationFilter={searchText}
              setPaginationFilter={setSearchText}
            />
          </Col>
        </Row>
        <Row gutter={12}>
          <Col xs={24} sm={24} md={24} className="m-t-sm m-b-sm">
            <Table
              className="ant-table-default ant-table-striped"
              dataSource={dataSource && dataSource}
              rowKey={(record) => record.id}
              loading={isLoadingTable || isFetchingTable}
              pagination={false}
              bordered={false}
              onChange={handleTableChange}
              scroll={{ x: "max-content" }}
            >
              <Table.Column
                title="Date"
                key="strdate"
                dataIndex="strdate"
                defaultSortOrder="descend"
                sorter={true}
                width={"150px"}
              />
              <Table.Column
                title="Time"
                key="strtime"
                dataIndex="strtime"
                sorter={true}
                width={"150px"}
              />
              <Table.Column
                title="Participant"
                key="name"
                dataIndex="name"
                sorter={true}
                render={(text, record) => {
                  if (userdata.role !== "Admin") {
                    if (record.status !== "Approved") {
                      return (
                        <Link
                          to="#"
                          className="companycolor1 cursor"
                          onClick={() =>
                            setToggleModalPreview({
                              show: true,
                              data: record,
                              type: record.type,
                            })
                          }
                        >
                          {record.name}
                        </Link>
                      );
                    } else {
                      return text;
                    }
                  } else {
                    return (
                      <Link
                        to="#"
                        className="companycolor1 cursor"
                        onClick={() =>
                          setToggleModalPreview({
                            show: true,
                            data: record,
                            type: record.type,
                          })
                        }
                      >
                        {record.name}
                      </Link>
                    );
                  }
                }}
                width={"200px"}
              />
              <Table.Column
                title="Type"
                key="type"
                dataIndex="type"
                sorter={true}
                width={"150px"}
              />
              <Table.Column
                title="State"
                key="state"
                dataIndex="state"
                sorter={true}
                width={"100px"}
              />
              <Table.Column
                title="City"
                key="city"
                dataIndex="city"
                sorter={true}
                width={"150px"}
              />
              <Table.Column
                title="Status"
                key="status"
                dataIndex="status"
                sorter={true}
                width={"150px"}
              />
            </Table>
          </Col>
          <Col xs={24} sm={24} md={24}>
            <Space className="ant-space-flex-space-between">
              <TableShowingEntries />

              <TablePagination
                paginationFilter={tableFilter}
                setPaginationFilter={setTableFilter}
                setPaginationTotal={tableTotal}
                showLessItems={true}
                showSizeChanger={false}
              />
            </Space>
          </Col>
        </Row>

        {toggleModalPreview.type === "Starter" ||
        toggleModalPreview.type === "Professional" ? (
          <>
            {/* <ModalPendingAssessmentPreview */}
            <ModalPendingAssessmentPreview
              toggleModalPreview={toggleModalPreview}
              setToggleModalPreview={setToggleModalPreview}
            />
          </>
        ) : (
          <>
            {/* <ModalPendingAssessmentPreviewElite */}
            <ModalPendingAssessmentPreviewElite
              toggleModalPreview={toggleModalPreview}
              setToggleModalPreview={setToggleModalPreview}
            />
          </>
        )}
        <br />
        <br />
      </Card>
    </>
  );
}
