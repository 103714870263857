import { useState, useEffect } from "react";
import { Button, Col, Image, Layout, Row, Space, Typography } from "antd";
import moment from "moment";
import { useHistory, Link } from "react-router-dom";
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  EmailShareButton,
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
  EmailIcon,
} from "react-share";

// import HelmetMetaData from "./HelmetMetaData";

import { GET } from "../../../providers/useAxiosQuery";
import companyInfo from "../../../providers/companyInfo";
import ComponentDashboardPublic from "../../private/Components/ComponentDashboardPublic";
import $ from "jquery";

export default function PageCalendar(props) {
  const apiUrl = companyInfo().apiUrl;
  const sidelogo = companyInfo().sidelogo;
  const userData = companyInfo().userData;
  const { match } = props;
  const history = useHistory();
  const [eventData, setEventData] = useState();

  const [dataFilter, setDataFilter] = useState({
    date: moment().format("MM/DD/YYYY"),
    type: "Group",
    search: "",
    category: "",
    tag: "",
    country: "",
    state: "",
    city: "",
    user_id: userData.id,
    from: "athlete_dashboard",
    // col_lg: ,
  });

  useEffect(() => {
    $("head").prepend(`
      <style>
        .splash-centered {
          top: 15% !important;
        }

        body {
          background: white !important;
        }
      </style>
    `);

    console.log("public___________");
  }, []);

  return (
    <>
      <Layout>
        {/* <Layout.Header style={{ height: 90, padding: "0 0px " }}>
          <Row gutter={12} className="event">
            <Col
              xs={24}
              sm={24}
              md={12}
              lg={6}
              className=" public-event-logo"
              style={{ paddingLeft: "0px!important" }}
            >
              <Image src={sidelogo} preview={false} />
            </Col>
          </Row>
        </Layout.Header> */}

        <Layout.Content
        // style={{
        //   paddingLeft: "0px",
        //   paddingRight: "0px",
        // }}
        >
          <ComponentDashboardPublic
            dataFilter={dataFilter}
            setDataFilter={setDataFilter}
            column={12}
            column_lg={6}
            column_xl={6}
          />
        </Layout.Content>

        {/* <Layout.Footer style={{ textAlign: "center" }}>
          © Copyright {process.env.REACT_APP_APP_NAME}{" "}
          {new Date().getFullYear()} Alright Reserve.
        </Layout.Footer> */}
      </Layout>
    </>
  );
}
