import React from "react";
import { Card, Col, Row, Collapse } from "antd";
import ComponentHeader from "../../Components/ComponentHeader";
import { faHome } from "@fortawesome/pro-regular-svg-icons";
import TableGroupAssessment from "./Tables/TableGroupAssessment";

export default function PageAssessment(props) {
  const { match, permission } = props;
  const { Panel } = Collapse;
  const event_id = match.params.id;
  const event_name = match.params.event_name;

  console.log("match", match);
  return (
    <>
      <ComponentHeader
        title={permission}
        sub_title="ASSESSMENT"
        icon={faHome}
      />

      <Card className="card-min-height" id="PageAssessment">
        <Row gutter={12}>
          <Col xs={24} sm={24} md={24} lg={24}>
            <Collapse
              className="main-1-collapse"
              expandIcon={({ isActive }) =>
                isActive ? (
                  <span
                    className="ant-menu-submenu-arrow"
                    style={{ color: "#FFF", transform: "rotate(270deg)" }}
                  ></span>
                ) : (
                  <span
                    className="ant-menu-submenu-arrow"
                    style={{ color: "#FFF", transform: "rotate(90deg)" }}
                  ></span>
                )
              }
              defaultActiveKey={["1"]}
              expandIconPosition="start"
            >
              <Panel
                header={`Athletic Profile Assessments - ${event_name}`}
                key="1"
                className="accordion bg-darkgray-form"
              >
                <TableGroupAssessment
                  eventID={event_id}
                  // scheduleID={schedule_id}
                />
              </Panel>
            </Collapse>
          </Col>
        </Row>
        <br />
      </Card>
    </>
  );
}
