import {
  Button,
  Card,
  Col,
  Collapse,
  notification,
  Row,
  Space,
  Table,
  Form,
  Divider,
  Typography,
} from "antd";
import React, { useState, useEffect } from "react";
// import { useHistory } from "react-router-dom";
// import $ from "jquery";

import { faFilePdf } from "@fortawesome/free-solid-svg-icons";
import FloatInput from "../../../../../providers/FloatInput";
import FloatSelect from "../../../../../providers/FloatSelect";
import { GETMANUAL, POST } from "../../../../../providers/useAxiosQuery";

export default function PageStarter({ pdfData, pdfID }) {
  // const history = useHistory();
  // const userdata = companyInfo().userData;

  //   const pdf_data = JSON.parse(pdfData.pdf_data);
  const [form] = Form.useForm();

  useEffect(() => {
    if (pdfData) {
      refetchData();
    }
  }, [pdfID]);

  const [PDFData, setPDFData] = useState();
  const [typeData, setTypeData] = useState("");
  const { refetch: refetchData } = GETMANUAL(
    `api/v1/get_pdf_data_list?id=${pdfID}`,
    "get_data",
    (res) => {
      if (res.success) {
        let pdf_data = JSON.parse(res.data.pdf_data);
        console.log("pdf_data", pdf_data);
        setPDFData(pdf_data);
        setTypeData(res.data);

        form.setFieldsValue({
          p_3_rebound_jump:
            pdf_data.p_3_rebound_jump === 0
              ? Number(pdf_data.p_3_rebound_jump).toFixed()
              : pdf_data.p_3_rebound_jump,
          p_3_countermovement_jump:
            pdf_data.p_3_countermovement_jump === 0
              ? Number(pdf_data.p_3_countermovement_jump).toFixed()
              : pdf_data.p_3_countermovement_jump,
          p_3_squat_jump:
            pdf_data.p_3_squat_jump === 0
              ? Number(pdf_data.p_3_squat_jump).toFixed()
              : pdf_data.p_3_squat_jump,
          p_3_ten_yard_sprint_jump:
            pdf_data.p_3_ten_yard_sprint_jump === 0
              ? Number(pdf_data.p_3_ten_yard_sprint_jump).toFixed()
              : pdf_data.p_3_ten_yard_sprint_jump,
          p_3_isometric_mid_thigh_pull:
            pdf_data.p_3_isometric_mid_thigh_pull === 0
              ? Number(pdf_data.p_3_isometric_mid_thigh_pull).toFixed()
              : pdf_data.p_3_isometric_mid_thigh_pull,
          p_3_rebound_jump_average:
            pdf_data.p_3_rebound_jump_average === 0
              ? Number(pdf_data.p_3_rebound_jump_average).toFixed()
              : pdf_data.p_3_rebound_jump_average,
          p_3_rebound_jump_benchmark:
            pdf_data.p_3_rebound_jump_benchmark === 0
              ? Number(pdf_data.p_3_rebound_jump_benchmark).toFixed()
              : pdf_data.p_3_rebound_jump_benchmark,
        });
      }
    }
  );

  const onFinish = (values) => {
    let data = {
      ...PDFData,
      p_3_rebound_jump: values.p_3_rebound_jump,
      p_3_countermovement_jump: values.p_3_countermovement_jump,
      p_3_squat_jump: values.p_3_squat_jump,
      p_3_ten_yard_sprint_jump: values.p_3_ten_yard_sprint_jump,
      p_3_isometric_mid_thigh_pull: values.p_3_isometric_mid_thigh_pull,
      p_3_rebound_jump_average: values.p_3_rebound_jump_average,
      p_3_rebound_jump_benchmark: values.p_3_rebound_jump_benchmark,
    };

    console.log(data);

    let result = { id: pdfID, pdf_data: JSON.stringify(data) };

    mutateUpdate(result, {
      onSuccess: (res) => {
        if (res.success) {
          console.log("pdf_data", res);
          notification.success({
            message: "Success",
            description: "Successfully Updated!",
          });
        }
      },
    });
  };

  const { mutate: mutateUpdate, isLoading: isLoadingUpdate } = POST(
    "api/v1/edit_pdf_start",
    "get_data"
  );

  return (
    <>
      <Form form={form} onFinish={onFinish}>
        <Row gutter={12}>
          <Col xs={24} sm={24} md={12}>
            <Collapse
              className="main-1-collapse border-none"
              expandIcon={({ isActive }) =>
                isActive ? (
                  <span
                    className="ant-menu-submenu-arrow"
                    style={{ color: "#FFF", transform: "rotate(270deg)" }}
                  ></span>
                ) : (
                  <span
                    className="ant-menu-submenu-arrow"
                    style={{ color: "#FFF", transform: "rotate(90deg)" }}
                  ></span>
                )
              }
              defaultActiveKey={["0"]}
              expandIconPosition="start"
            >
              {PDFData && (
                <Collapse.Panel
                  header="Athlete Information"
                  key="0"
                  className="accordion bg-darkgray-form m-b-md border"
                >
                  <Typography.Text>
                    <strong>Assessment Type</strong>:{" "}
                    {typeData && typeData.type}
                  </Typography.Text>
                  <br />

                  <Typography.Text>
                    <strong>Name</strong>: {PDFData.name}
                  </Typography.Text>
                  <br />

                  <Typography.Text>
                    <strong>Age</strong>: {PDFData.p_3_age}
                  </Typography.Text>
                  <br />

                  <Typography.Text>
                    <strong>Sport</strong>: {PDFData.p_3_sport}
                  </Typography.Text>
                  <br />

                  <Typography.Text>
                    <strong>Weight</strong>: {PDFData.p_3_weight}
                  </Typography.Text>
                  <br />

                  <Typography.Text>
                    <strong>Sex</strong>: {PDFData.p_3_gender}
                  </Typography.Text>
                  <br />
                </Collapse.Panel>
              )}
            </Collapse>
          </Col>
        </Row>

        <Collapse
          className="main-1-collapse border-none"
          expandIcon={({ isActive }) =>
            isActive ? (
              <span
                className="ant-menu-submenu-arrow"
                style={{ color: "#FFF", transform: "rotate(270deg)" }}
              ></span>
            ) : (
              <span
                className="ant-menu-submenu-arrow"
                style={{ color: "#FFF", transform: "rotate(90deg)" }}
              ></span>
            )
          }
          defaultActiveKey={["1"]}
          expandIconPosition="start"
        >
          <Collapse.Panel
            header="Page 3 - BFS Science Assessment"
            key="1"
            className="accordion bg-darkgray-form m-b-md border"
          >
            <Divider orientation="left" plain style={{ marginTop: "0px" }}>
              <strong>You</strong>
            </Divider>
            <Row gutter={12}>
              <Col xs={24} sm={24} md={8}>
                <Form.Item name="p_3_rebound_jump">
                  <FloatInput
                    label="Reactive Strength (IN)"
                    placeholder="Reactive Strength (IN)"
                  />
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} md={8}>
                <Form.Item name="p_3_countermovement_jump">
                  <FloatInput
                    label="Elastic Strength (IN)"
                    placeholder="Elastic Strength (IN)"
                  />
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} md={8}>
                <Form.Item name="p_3_squat_jump">
                  <FloatInput
                    label="Ballastic Strength (IN)"
                    placeholder="Ballastic Strength (IN)"
                  />
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} md={8}>
                <Form.Item name="p_3_ten_yard_sprint_jump">
                  <FloatInput
                    label="Acceleration/Speed (SEC)"
                    placeholder="Acceleration/Speed (SEC)"
                  />
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} md={8}>
                <Form.Item name="p_3_isometric_mid_thigh_pull">
                  <FloatInput
                    label="Maximal Strength (LBS)"
                    placeholder="Maximal Strength (LBS)"
                  />
                </Form.Item>
              </Col>
            </Row>

            <Divider orientation="left" plain style={{ marginTop: "0px" }}>
              <strong>Performaers AVG</strong>
            </Divider>
            <Row gutter={12}>
              <Col xs={24} sm={24} md={8}>
                <Form.Item name="p_3_rebound_jump_average">
                  <FloatInput
                    label="Maximal Strength (LBS)"
                    placeholder="Maximal Strength (LBS)"
                  />
                </Form.Item>
              </Col>
            </Row>

            <Divider orientation="left" plain style={{ marginTop: "0px" }}>
              <strong>Your Benchmark</strong>
            </Divider>
            <Row gutter={12}>
              <Col xs={24} sm={24} md={8}>
                <Form.Item name="p_3_rebound_jump_benchmark">
                  <FloatInput
                    label="Reactive Strength (IN)"
                    placeholder="Reactive Strength (IN)"
                  />
                </Form.Item>
              </Col>
            </Row>
          </Collapse.Panel>
        </Collapse>

        <Row gutter={12}>
          <Col xs={24} sm={8} md={8}>
            <Button
              size="large"
              className="btn-main-outline-2"
              htmlType="submit"
              loading={isLoadingUpdate}
              block
            >
              SUBMIT
            </Button>
          </Col>
        </Row>
      </Form>
    </>
  );
}
