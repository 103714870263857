import React from "react";
import { Switch, Route } from "react-router-dom";
/** template */
import PrivateRoute from "../PrivateRoute";

import Error404 from "../../views/errors/Error404";
import Error500 from "../../views/errors/Error500";

import PageCoachDashboard from "../../views/private/PageCoach/PageDashboard/PageDashboard";
import PageCoachSearch from "../../views/private/PageCoach/PageSearch/PageSearch";
import PageCoachSearchAthleteProfile from "../../views/private/PageCoach/PageSearch/PageSearchAthleteProfile";
import PageCoachFundsFunding from "../../views/private/PageCoach/PageFunds/PageFundsFunding/PageFundsFunding";
import PageCoachFundsTermsAndCondition from "../../views/private/PageCoach/PageFunds/PageFundsTermsAndCondition/PageFundsTermsAndCondition";

import PageAthleticProfileEdit from "../../views/private/PageCoach/PageAthleticProfile/PageAthleticProfileEdit";
import PageMyAthlete from "../../views/private/PageCoach/PageAthleticProfile/PageMyAthlete";

import PageProfileCoach from "../../views/private/PageCoach/PageProfileCoach/PageProfileCoach";

import companyInfo from "../../providers/companyInfo";

// import PageUserSearchAthletes from "../../views/private/PageManager/PageUser/PageUserSearchAthletes";
import PageAthleticProfileViewWebpage from "../../views/private/PageAthlete/PageAthleticProfile/PageAthleticProfileViewWebpage";

const role = companyInfo().role;

export default function RouteCoach() {
  return (
    <Switch>
      {/* member */}

      <PrivateRoute
        exact
        path="/dashboard"
        permission="Dashboard"
        component={PageCoachDashboard}
        breadcrumb={[
          {
            name: "Dashboard",
            link: "/dashboard",
          },
        ]}
      />

      <PrivateRoute
        exact
        path="/search"
        title="Search Athletes"
        permission="Athletes"
        component={PageCoachSearch}
        breadcrumb={[
          {
            name: "Users",
            link: "#",
          },
          {
            name: "Search Athletes",
            link: "/search",
          },
        ]}
      />

      <Route
        exact
        path="/search/preview/:id"
        title="Athlete"
        permission="Athlete"
        component={PageAthleticProfileViewWebpage}
        breadcrumb={[
          {
            name: "Athlete",
            link: "",
          },
        ]}
      />

      {role === "Coach" && (
        <Route
          exact
          path="/athlete-profile/:id"
          component={PageCoachSearchAthleteProfile}
          title="Athlete Profile"
          permission="Athlete Profile"
          breadcrumb={[
            {
              name: "Athlete Profile",
              link: "/athlete-profile/:id",
            },
          ]}
        />
      )}

      <PrivateRoute
        exact
        path="/funds/funding"
        component={PageCoachFundsFunding}
        title="Funding"
        permission="Funding"
        breadcrumb={[
          {
            name: "Funds",
            link: "#",
          },
          {
            name: "Funding",
            link: "/funds/funding",
          },
        ]}
      />
      <PrivateRoute
        exact
        path="/funds/terms-conditions-of-funds"
        component={PageCoachFundsTermsAndCondition}
        title="Terms & Conditions of Funds"
        permission="TERMS & CONDITION OF"
        breadcrumb={[
          {
            name: "Funds",
            link: "#",
          },
          {
            name: "Terms & Conditions of Funds",
            link: "/funds/terms-conditions-of-funds",
          },
        ]}
      />
      <PrivateRoute
        exact
        path="/athlete/follow"
        component={PageAthleticProfileEdit}
        title="Athletes I Follow"
        permission="Athletes I Follow"
        breadcrumb={[
          {
            name: "Athlete",
            link: "#",
          },
          {
            name: "Athletes I Follow",
            link: "#",
          },
        ]}
      />
      <PrivateRoute
        exact
        path="/athlete/my-athletes"
        component={PageMyAthlete}
        title="My Athletes"
        permission="My Athletes"
        breadcrumb={[
          {
            name: "Athlete",
            link: "#",
          },
          {
            name: "My Athletes",
            link: "#",
          },
        ]}
      />

      <PrivateRoute
        exact
        path="/profile"
        component={PageProfileCoach}
        title="Edit Profile"
        permission="Profile"
        breadcrumb={[
          {
            name: "Edit Profile",
            link: "#",
          },
        ]}
      />

      <Route exact path="/500" component={Error500} />
      <Route exact path="/*" component={Error404} />
    </Switch>
  );
}
