import React, { useState, useEffect } from "react";
import {
  Card,
  Col,
  Row,
  Collapse,
  Form,
  Upload,
  message,
  Radio,
  Divider,
  Button,
  notification,
  Alert,
} from "antd";
import ImgCrop from "antd-img-crop";
import ComponentHeader from "../../../Components/ComponentHeader";
import { faCalendarAlt, faTrash } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FloatInput from "../../../../../providers/FloatInput";
import FloatSelect from "../../../../../providers/FloatSelect";
import optionCountryCodes from "../../../../../providers/optionCountryCodes";
import optionStateCodesUnitedState from "../../../../../providers/optionStateCodesUnitedState";
import optionStateCodesCanada from "../../../../../providers/optionStateCodesCanada";
import optionAssessmentCategory from "../../../../../providers/optionAssessmentCategory";

import csc from "countries-states-cities";
import moment from "moment";
import companyInfo from "../../../../../providers/companyInfo";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import ImageResize from "quill-image-resize-module-react";
import FloatDatePicker from "../../../../../providers/FloatDatePicker";
import FloatTimePicker from "../../../../../providers/FloatTimePicker";
import { GET, GETMANUAL, POST } from "../../../../../providers/useAxiosQuery";
import { Link, useHistory } from "react-router-dom";
Quill.register("modules/imageResize", ImageResize);

export default function PageEventCalendarCreate({ match, permission }) {
  const history = useHistory();
  const id = match.params.id;
  const { Panel } = Collapse;
  const [form] = Form.useForm();
  const [subTitle, setSubTitle] = useState("ADD EVENT");

  const [country] = useState(optionCountryCodes);
  const categories = optionAssessmentCategory;
  // const tags = companyInfo().sport;
  const stateUnitedStates = optionStateCodesUnitedState();
  const stateCanada = optionStateCodesCanada();

  const [stateUS, setStateUS] = useState(optionStateCodesUnitedState());
  const [stateCA, setStateCA] = useState(optionStateCodesCanada());
  const [defaultState, setDefaultState] = useState(stateUS);
  const [stateUSLocation, setStateUSLocation] = useState([]);
  const [stateCALocation, setStateCALocation] = useState([]);

  const [stateLabel, setStateLabel] = useState("State");
  const [zipLabel, setZipLabel] = useState("Zip Code");
  const [optionState, setOptionState] = useState([]);
  const [optionZip, setOptionZip] = useState(/(^\d{5}$)|(^\d{5}[ -]\d{4}$)/);

  const handleCountry = (val, opt) => {
    console.log(val);
    if (val === "United States") {
      setStateLabel("State");
      setZipLabel("Zip Code");
      // console.log("stateUS", stateUS);
      let option_states = [];
      data_get_manager_state_area.states.map((state, key) => {
        let find = stateUS.find((p) => p.value == state.value);
        if (find) {
          option_states.push({ ...state, label: find.label });
        }
      });
      setOptionState(option_states);
      console.log("onFinish", option_states);
      setOptionZip(/(^\d{5}$)|(^\d{5}[ -]\d{4}$)/);
    } else if (val === "Canada") {
      setStateLabel("County");
      setZipLabel("Postal Code");
      let option_states = [];
      data_get_manager_state_area.states.map((state, key) => {
        let find = stateCA.find((p) => p.value == state.value);
        if (find) {
          option_states.push({ ...state, label: find.label });
        }
      });
      setOptionState(option_states);
      setOptionZip(/^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/);
    }
    setCityOption([]);
    form.resetFields(["state", "zip", "city"]);
  };

  useEffect(() => {
    console.log("asdasd", optionZip);
  }, [optionZip]);

  const validator = {
    require: {
      required: true,
      message: "This field is required",
    },
    email: {
      type: "email",
      message: "Please enter a valid email",
    },
  };

  const modulesToolBar = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "image", "video"],
      ["clean"],
    ],
    imageResize: {
      modules: ["Resize", "DisplaySize"],
    },
  };

  const formats = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "video",
  ];

  const [fileList, setFileList] = useState([]);
  const [radioData, setRadioData] = useState(1);
  const [imageCrop, setImageCrop] = useState({
    width: 1,
    height: 1,
  });

  const handleResize = (val) => {
    console.log("val", val.target.value);
    setRadioData(val.target.value);
    if (val.target.value === 1) {
      setImageCrop({
        width: 1,
        height: 1,
      });
    } else if (val.target.value === 2) {
      setImageCrop({
        width: 3.9,
        height: 2.6,
      });
    } else if (val.target.value === 3) {
      setImageCrop({
        width: 1,
        height: 1.5,
      });
    }
  };

  const onChange = ({ fileList: newFileList }) => {
    var _file = newFileList;
    console.log(_file);
    if (_file.length != 0) {
      _file[0].status = "done";
      setFileList(_file);
    } else {
      setFileList([]);
    }
  };

  const onPreview = async (file) => {
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow.document.write(image.outerHTML);
  };

  const beforeUpload = (file) => {
    const isJpgOrPng =
      file.type === "image/jpeg" ||
      file.type === "image/png" ||
      file.type === "image/gif" ||
      file.type === "image/jpg";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG, PNG, GIF, JPEG file!");
      setFileList([]);
      return;
    }
    const isLt2M = file.size / 102400 / 102400 < 10;
    if (!isLt2M) {
      message.error("Image must smaller than 10MB!");
      setFileList([]);
      return;
    }
    return isJpgOrPng && isLt2M;
  };

  const key = `open${Date.now()}`;
  const close = () => {
    history.push("/profile");
  };

  const btn = (
    <Button
      type="primary"
      size="small"
      onClick={() => {
        close();
        notification.close(key);
      }}
    >
      Click Here
    </Button>
  );

  const [noBankDetails, setNoBankDetails] = useState(false);
  GET("api/v1/get_bank_details", "get_bank_details", (res) => {
    console.log("get_bank_details", res);
    if (res.success) {
      setNoBankDetails(res.success);
    } else {
      setNoBankDetails(res.success);
    }
  });

  const onFinish = (values) => {
    console.log("onFinish", values);

    // if (!noBankDetails) {
    //   notification.open({
    //     type: "warning",
    //     message: "Warning",
    //     description: "Please Edit Your Bank Details on Profile Page",
    //     btn,
    //     key,
    //     // onClose: close,
    //   });
    //   return true;
    // }

    let assessment = {
      title: values.title,
      type: "Group",
      description: values.description,
      location: values.location,
      country: values.country,
      address_1: values.address_1,
      address_2: values.address_2,
      city: values.city,
      state: values.state,
      zip: values.zip,
      image_orientation: radioData,
    };

    let location = {
      country: values.country_location,
      state: values.state_location,
      city: values.city_location,
    };

    let schedule = values.schedule;
    schedule.map((time, key) => {
      schedule[key]["id"] = time.id ? time.id : "";
      schedule[key]["date"] = moment(time.date).format("YYYY-MM-DD");
      schedule[key]["time_start"] = time.time_start.format("HH:mm");
      schedule[key]["time_end"] = time.time_end.format("HH:mm");
    });

    const data = new FormData();
    if (id) {
      data.append("id", id);
    }

    data.append("assessment", JSON.stringify(assessment));
    data.append("schedule", JSON.stringify(schedule));
    data.append("categories", JSON.stringify(values.categories));
    data.append("location", JSON.stringify(location));
    data.append("tags", JSON.stringify(values.tags));

    if (fileList.length !== 0) {
      if (fileList[0].uid != "-1") {
        data.append("upload", fileList[0].originFileObj, fileList[0].name);
      }
    }

    if (fileList.length !== 0) {
      mutateCreate(data, {
        onSuccess: (res) => {
          if (res.success) {
            if (id) {
              notification.success({
                message: "Success",
                description: "Successfully updated",
              });
            } else {
              notification.success({
                message: "Success",
                description: "Successfully submitted",
              });
            }
            console.log(res);
            form.resetFields();
            setFileList([]);
            history.push("/event-calendar/edit-assessments");
          }
        },
      });
    } else {
      notification.error({
        message: "Error",
        description: "Please select Event Image!",
      });
    }
  };

  const { mutate: mutateCreate, isLoading: isLoadingCreate } = POST(
    "api/v1/assessment",
    "assessment"
  );

  const { data: data_get_manager_state_area } = GET(
    "api/v1/get_manager_state_area2",
    "get_manager_state_area",
    (res) => {
      if (res.success) {
        console.log("get_manager_state_area", res);
        // if (res.data) {
        //   refetchFind();
        // }
      }
    }
  );

  useEffect(() => {
    if (id) {
      console.log("edit", "asdasd");
      setSubTitle("EDIT EVENT");
      refetchFind();
    } else {
      form.resetFields();
      setFileList([]);
      form.setFieldsValue({
        schedule: [
          {
            date: null,
            time: null,
            duration: null,
          },
        ],
      });
    }
  }, [id]);

  const [cityOption, setCityOption] = useState([]);
  const { refetch: refetchFind } = GETMANUAL(
    `api/v1/assessment/${id}`,
    "assessment_manual",
    (res) => {
      if (res.success) {
        console.log("manual", res);
        let _schedule = [];

        res.data.schedule.map((item) => {
          let obj = {
            id: item.id,
            date: moment(item.date),
            time_start: moment(item.time_start, "HH:mm"),
            time_end: moment(item.time_end, "HH:mm"),
          };
          _schedule.push(obj);
        });

        let _categories = [];
        res.data.category.map((item) => {
          _categories.push(item.category);
        });

        let _tags = [];
        res.data.tag.map((item) => {
          _tags.push(item.tag);
        });

        // console.log("_tags", _tags);

        let _city_location = [];
        let _data_city_location = JSON.parse(res.data.locations.city);
        _data_city_location.map((item) => {
          _city_location.push(item);
        });

        setRadioData(res.data.image_orientation);
        if (res.data.image_orientation === 1) {
          setImageCrop({
            width: 1,
            height: 1,
          });
        } else if (res.data.image_orientation === 2) {
          setImageCrop({
            width: 3.9,
            height: 2.6,
          });
        } else if (res.data.image_orientation === 3) {
          setImageCrop({
            width: 1,
            height: 1.5,
          });
        } else {
          setImageCrop({
            width: 1,
            height: 1,
          });
        }
        form.setFieldsValue({
          title: res.data.title,
          type: res.data.type,
          description: res.data.description,
          location: res.data.location,
          country: res.data.country,
          address_1: res.data.address_1,
          address_2: res.data.address_2,
          city: res.data.city,
          city: res.data.city,
          state: res.data.state,
          zip: res.data.zip,
          schedule: _schedule,
          categories: _categories,
          country_location: res.data.locations.country,
          state_location: res.data.locations.state,
          // city_location: res.data.locations.city,
          city_location: _city_location,
          tags: _tags,
        });

        setFileList([
          {
            uid: "-1",
            name: "image.png",
            status: "done",
            url: companyInfo().apiUrl + res.data.upload,
          },
        ]);

        if (res.data.country === "United States") {
          setStateLabel("State");
          setZipLabel("Zip Code");
          setOptionZip(/(^\d{5}$)|(^\d{5}[ -]\d{4}$)/);
          let option_states = [];
          data_get_manager_state_area &&
            data_get_manager_state_area.states.map((state, key) => {
              let find = stateUS.find((p) => p.value == state.value);
              if (find) {
                option_states.push({ ...state, label: find.label });
              }
            });
          setOptionState(option_states);

          let get_city = option_states.filter(
            (item) => res.data.state === item.value
          );

          if (get_city.length > 0) {
            console.log("get_city", get_city);
            setCityOption(get_city[0]["json"]);
          }

          // console.log("option_states", option_states, res.data.state);
        } else if (res.data.country === "Canada") {
          setStateLabel("County");
          setZipLabel("Postal Code");
          setOptionZip(/^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/);
          let option_states = [];
          data_get_manager_state_area &&
            data_get_manager_state_area.states.map((state, key) => {
              let find = stateCA.find((p) => p.value == state.value);
              if (find) {
                option_states.push({ ...state, label: find.label });
              }
            });
          setOptionState(option_states);

          let get_city = option_states.filter(
            (item) => res.data.state === item.value
          );

          if (get_city.length > 0) {
            console.log("get_city", get_city);
            setCityOption(get_city[0]["json"]);
          }
        }

        if (res.data.locations.country === "United States") {
          setStateLabelLocation("State");
          setZipLabelLocation("Zip Code");
          let option_states = [];
          data_get_manager_state_area &&
            data_get_manager_state_area.states.map((state, key) => {
              let find = stateUS.find((p) => p.value == state.value);
              if (find) {
                option_states.push({ ...state, label: find.label });
              }
            });
          setOptionStateLocation(option_states);
          let _city = option_states.filter(
            (item) => item.value === res.data.locations.state
          );
          console.log("_city", _city);
          if (_city && _city.length > 0) {
            setOptionCityLocation(_city && _city[0]?.json);
          }
        } else if (res.data.locations.country === "Canada") {
          setStateLabelLocation("County");
          setZipLabelLocation("Postal Code");
          let option_states = [];
          data_get_manager_state_area &&
            data_get_manager_state_area.states.map((state, key) => {
              let find = stateCA.find((p) => p.value == state.value);
              if (find) {
                option_states.push({ ...state, label: find.label });
              }
            });
          setOptionStateLocation(option_states);

          let _city = option_states.filter(
            (item) => item.value === res.data.locations.state
          );
          if (_city && _city.length > 0) {
            setOptionCityLocation(_city && _city[0]?.json);
          }
        } else {
          setStateLabelLocation("State");
          setZipLabelLocation("Zip Code");
          setOptionStateLocation(stateUS);
        }
      }
    }
  );

  const [stateLabelLocation, setStateLabelLocation] = useState("State");
  const [optionStateLocation, setOptionStateLocation] = useState([]);
  const [optionCityLocation, setOptionCityLocation] = useState([]);
  const [zipLabelLocation, setZipLabelLocation] = useState("Zip Code");

  const handleCountryLocation = (val, opt) => {
    if (val === "United States") {
      setStateLabelLocation("State");
      setZipLabelLocation("Zip Code");
      // console.log("stateUS", stateUS);
      let option_states = [];
      data_get_manager_state_area.states.map((state, key) => {
        let find = stateUS.find((p) => p.value == state.value);
        if (find) {
          option_states.push({ ...state, label: find.label });
        }
      });
      setOptionStateLocation(option_states);
    } else if (val === "Canada") {
      setStateLabelLocation("County");
      setZipLabelLocation("Postal Code");
      let option_states = [];
      data_get_manager_state_area.states.map((state, key) => {
        let find = stateCA.find((p) => p.value == state.value);
        if (find) {
          option_states.push({ ...state, label: find.label });
        }
      });
      setOptionStateLocation(option_states);
    }
    form.resetFields(["state_location", "city_location"]);

    console.log("handleBlurSelect", val);
  };

  const handleStateLocation = (val, opt) => {
    form.resetFields(["city_location"]);
    setOptionCityLocation(opt["data-json"]);
  };

  const handleState = (val, opt) => {
    if (val) {
      let city = opt["data-json"];
      console.log("onFinish", city);
      setCityOption(city);
    }
  };

  const handleDateCheckStart = (e, index) => {
    let row = form.getFieldValue().schedule;
    let selected = row[index];
    // console.log("handleDateCheckStart row", row);
    // console.log("handleDateCheckStart selected", row[index]);

    if (selected && selected.time_start) {
      row.map((item, key) => {
        if (
          item &&
          item.date &&
          selected.date &&
          item.time_start &&
          item.time_end
        ) {
          if (key !== index) {
            let item_date = item.date.format("L");
            let value_date = selected.date.format("L");

            let time_start = item.time_start.format("HHmm");
            let time_start_selected = selected.time_start.format("HHmm");
            if (item_date === value_date) {
              if (time_start === time_start_selected) {
                let rowResult = [...row];
                rowResult[index] = {
                  ...rowResult[index],
                  time_start: null,
                };
                form.setFieldsValue({
                  schedule: rowResult,
                });
                notification.warning({
                  message: "Warning",
                  description: "Start time is already used!",
                });
                return true;
              } else {
                if (item.time_end) {
                  let time_end = item.time_end.format("HHmm");
                  if (
                    Number(time_start) <= Number(time_start_selected) &&
                    Number(time_end) >= Number(time_start_selected)
                  ) {
                    notification.warning({
                      message: "Warning",
                      description: "Invalid time schedule!",
                    });
                    let rowResult = [...row];
                    rowResult[index] = {
                      ...rowResult[index],
                      time_start: null,
                    };
                    form.setFieldsValue({
                      schedule: rowResult,
                    });
                  }
                }
              }
            }
          }
        }
      });
    }
  };

  const handleDateCheckEnd = (e, index) => {
    let row = form.getFieldValue().schedule;
    let selected = row[index];

    let arrMax = [];
    let arrMin = [];
    row.map((i, k) => {
      if (k !== index) {
        if (i && i.date && i.time_end && selected && selected.date) {
          let item_date = i.date.format("L");
          let value_date = selected.date.format("L");
          if (value_date === item_date) {
            if (i.time_end) {
              arrMax.push(Number(i.time_end.format("HHmm")));
            }
            if (i.time_start) {
              arrMin.push(Number(i.time_start.format("HHmm")));
            }
          }
        }
      }
    });
    let minData = arrMin.length > 0 ? Math.min.apply(null, arrMin) : 0;
    let maxData = arrMax.length > 0 ? Math.min.apply(null, arrMax) : 0;
    console.log("handleDateCheckEnd", maxData);

    if (selected && selected.time_start && selected.time_end) {
      let time_start = selected.time_start;
      let time_end = e;

      if (time_start && time_end) {
        time_start = time_start.format("HHmm");
        time_end = time_end.format("HHmm");
        if (time_start >= time_end) {
          notification.warning({
            message: "Warning",
            description: "Time schedule is invalid!",
          });

          let rowResult = [...row];
          rowResult[index] = {
            ...rowResult[index],
            time_end: null,
          };
          form.setFieldsValue({
            schedule: rowResult,
          });
          return true;
        } else {
          row.map((item, key) => {
            if (
              item &&
              item.date &&
              selected.date &&
              item.time_start &&
              item.time_end
            ) {
              if (key !== index) {
                let item_date = item.date.format("L");
                let value_date = selected.date.format("L");

                let time_end = item.time_end.format("HHmm");
                let time_end_selected = selected.time_end.format("HHmm");
                if (item_date === value_date) {
                  if (time_end === time_end_selected) {
                    let rowResult = [...row];
                    rowResult[index] = {
                      ...rowResult[index],
                      time_end: null,
                    };
                    form.setFieldsValue({
                      schedule: rowResult,
                    });
                    notification.warning({
                      message: "Warning",
                      description: "End time is already used!",
                    });
                    return true;
                  } else {
                    if (item.time_start) {
                      let time_start_item = item.time_start.format("HHmm");
                      console.log(
                        "handleDateCheckEnd",
                        Number(time_end),
                        maxData
                      );
                      if (
                        Number(time_start_item) <= Number(time_end_selected) &&
                        Number(time_end) >= Number(time_end_selected)
                      ) {
                        notification.warning({
                          message: "Warning",
                          description: "Invalid time schedule!",
                        });

                        let rowResult = [...row];
                        rowResult[index] = {
                          ...rowResult[index],
                          time_end: null,
                        };
                        form.setFieldsValue({
                          schedule: rowResult,
                        });
                        return true;
                      } else if (
                        maxData &&
                        Number(time_end_selected) >= maxData &&
                        Number(time_start) <= minData
                      ) {
                        notification.warning({
                          message: "Warning",
                          description: "Invalid time schedule!",
                        });

                        let rowResult = [...row];
                        rowResult[index] = {
                          ...rowResult[index],
                          time_end: null,
                        };
                        form.setFieldsValue({
                          schedule: rowResult,
                        });
                        return true;
                      }
                    }
                  }
                }
              }
            }
          });
        }
      }
    }
  };

  const [sportList, setSportList] = useState([]);
  GET("api/v1/get_all_sport", "get_all_sport", (res) => {
    if (res.success) {
      console.log("get_all_sport", res);
      let row = [];
      res.data &&
        res.data.map((item, key) => {
          row.push({
            value: item.sport,
            label: item.sport,
          });
        });
      setSportList(row);
    }
  });

  return (
    <>
      <ComponentHeader
        title={permission}
        sub_title={subTitle}
        icon={faCalendarAlt}
      />

      <Card className="page-manager-event" id="PageEventCalendarCreate">
        {!noBankDetails && (
          <Alert
            type="error"
            message=""
            description={
              <>
                Please Edit Your Bank Details on Profile Page.{" "}
                <Link to="/profile">Click Here</Link>
              </>
            }
            className="m-b-md"
          />
        )}

        <Form
          name="assessment-form"
          form={form}
          // onFinish={onFinish}
          initialValues={{ description: "", type: "Group" }}
        >
          <Row gutter={12}>
            <Col xs={24} sm={24} md={24} lg={24}>
              <Collapse
                className="main-1-collapse"
                expandIcon={({ isActive }) =>
                  isActive ? (
                    <span
                      className="ant-menu-submenu-arrow"
                      style={{ color: "#FFF", transform: "rotate(270deg)" }}
                    ></span>
                  ) : (
                    <span
                      className="ant-menu-submenu-arrow"
                      style={{ color: "#FFF", transform: "rotate(90deg)" }}
                    ></span>
                  )
                }
                defaultActiveKey={["1"]}
                expandIconPosition="start"
              >
                <Panel
                  header="ASSESSMENT INFORMATION"
                  key="1"
                  className="accordion bg-darkgray-form"
                >
                  <Row gutter={24}>
                    <Col xs={24} sm={24} md={12} lg={12}>
                      <Form.Item name="title" rules={[validator.require]}>
                        <FloatInput
                          label="Assessment Title"
                          placeholder="Assessment Title"
                          required
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row gutter={24}>
                    <Col xs={24} sm={24} md={24} lg={24}>
                      <label>
                        Assessment Description{" "}
                        <span className="text-danger">*</span>
                      </label>
                      <Form.Item name="description">
                        <ReactQuill
                          className="ticket-quill"
                          theme="snow"
                          modules={modulesToolBar}
                          formats={formats}
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row gutter={24}>
                    <Col xs={24} sm={24} md={12} lg={12}>
                      <Form.Item name="location" rules={[validator.require]}>
                        <FloatInput
                          label="Location Name"
                          placeholder="Location Name"
                          required
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12}>
                      <Form.Item
                        name="country"
                        rules={[validator.require]}
                        className="form-select-error"
                      >
                        <FloatSelect
                          label="Country"
                          placeholder="Country"
                          options={
                            data_get_manager_state_area
                              ? data_get_manager_state_area.countries
                              : []
                          }
                          onChange={handleCountry}
                          required
                          // disabled={true}
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row gutter={24}>
                    <Col xs={24} sm={24} md={24} lg={24}>
                      <Form.Item name="address_1" rules={[validator.require]}>
                        <FloatInput
                          label="Address 1"
                          placeholder="Address 1"
                          required
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={24}>
                      <Form.Item name="address_2">
                        <FloatInput label="Address 2" placeholder="Address 2" />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row gutter={24}>
                    <Col xs={24} sm={24} md={8} lg={8}>
                      <Form.Item
                        name="state"
                        rules={[validator.require]}
                        className="form-select-error"
                      >
                        <FloatSelect
                          label={stateLabel}
                          placeholder={stateLabel}
                          options={optionState}
                          onChange={handleState}
                          required
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={8} lg={8}>
                      <Form.Item
                        name="city"
                        className="form-select-error"
                        rules={[validator.require]}
                      >
                        <FloatSelect
                          label="City"
                          placeholder="City"
                          options={cityOption}
                          required
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={8} lg={8}>
                      <Form.Item name="zip">
                        <Form.Item
                          name="zip"
                          rules={[
                            validator.require,
                            {
                              pattern: optionZip,
                              message: "Invalid " + zipLabel,
                            },
                          ]}
                        >
                          <FloatInput
                            label={zipLabel}
                            placeholder={zipLabel}
                            required
                          />
                        </Form.Item>
                      </Form.Item>
                    </Col>
                  </Row>
                </Panel>
              </Collapse>
              <br />
            </Col>
          </Row>

          <Row gutter={24}>
            <Col xs={24} sm={24} md={24} lg={24}>
              <Collapse
                className="main-1-collapse"
                expandIcon={({ isActive }) =>
                  isActive ? (
                    <span
                      className="ant-menu-submenu-arrow"
                      style={{ color: "#FFF", transform: "rotate(270deg)" }}
                    ></span>
                  ) : (
                    <span
                      className="ant-menu-submenu-arrow"
                      style={{ color: "#FFF", transform: "rotate(90deg)" }}
                    ></span>
                  )
                }
                defaultActiveKey={["1"]}
                expandIconPosition="start"
              >
                <Panel
                  header="EVENT IMAGE"
                  key="1"
                  className="accordion bg-darkgray-form manager-event-image-panel"
                >
                  <Row gutter={24}>
                    <Col xs={24} sm={24} md={24} lg={24}>
                      <div className="font-red image-upload-label">
                        <b>
                          Photo upload & cropping: select your image orientation
                        </b>
                      </div>
                      <br />
                      <Radio.Group onChange={handleResize} value={radioData}>
                        <Radio value={1}>Square</Radio>
                        <Radio value={2}>Rectangle</Radio>
                        <Radio value={3}>Portrait</Radio>
                      </Radio.Group>
                      <Divider />
                      <ImgCrop
                        rotate
                        aspect={imageCrop.width / imageCrop.height}
                      >
                        <Upload
                          // action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                          listType="picture-card"
                          style={{ width: "200px" }}
                          maxCount={1}
                          action={false}
                          customRequest={false}
                          fileList={fileList}
                          onChange={onChange}
                          onPreview={onPreview}
                          beforeUpload={beforeUpload}
                          showUploadList={{
                            showPreviewIcon: false,
                          }}
                        >
                          {fileList.length < 1 && "+ Upload"}
                        </Upload>
                      </ImgCrop>
                    </Col>
                  </Row>
                </Panel>
              </Collapse>
              <br />
            </Col>
          </Row>

          <Row gutter={24}>
            <Col xs={24} sm={24} md={24} lg={24}>
              <Collapse
                className="main-1-collapse"
                expandIcon={({ isActive }) =>
                  isActive ? (
                    <span
                      className="ant-menu-submenu-arrow"
                      style={{ color: "#FFF", transform: "rotate(270deg)" }}
                    ></span>
                  ) : (
                    <span
                      className="ant-menu-submenu-arrow"
                      style={{ color: "#FFF", transform: "rotate(90deg)" }}
                    ></span>
                  )
                }
                defaultActiveKey={["1"]}
                expandIconPosition="start"
              >
                <Panel
                  header="SCHEDULE"
                  key="1"
                  className="accordion bg-darkgray-form"
                >
                  <Row gutter={24}>
                    <Col xs={24} sm={24} md={24} lg={24}>
                      <Form.List name="schedule">
                        {(fields, { add, remove }) => (
                          <>
                            {fields.map(({ key, name, ...restField }) => (
                              <div key={key}>
                                <Row gutter={24}>
                                  <Col xs={24} sm={24} md={6} lg={6}>
                                    <Form.Item
                                      {...restField}
                                      name={[name, "id"]}
                                      style={{ display: "none" }}
                                    >
                                      <FloatInput label="ID" placeholder="ID" />
                                    </Form.Item>
                                    <Form.Item
                                      {...restField}
                                      name={[name, "date"]}
                                      rules={[validator.require]}
                                    >
                                      <FloatDatePicker
                                        label="Date"
                                        placeholder="Date"
                                        format={"MM/DD/YYYY"}
                                        onChange={(e) => {
                                          // handleDateCheckStart(e, name)

                                          let row =
                                            form.getFieldValue().schedule;
                                          let rowResult = [...row];
                                          rowResult[name] = {
                                            ...rowResult[name],
                                            time_start: null,
                                            time_end: null,
                                          };
                                          form.setFieldsValue({
                                            schedule: rowResult,
                                          });
                                        }}
                                        required
                                      />
                                    </Form.Item>
                                  </Col>

                                  <Col xs={24} sm={24} md={6} lg={6}>
                                    <Form.Item
                                      {...restField}
                                      name={[name, "time_start"]}
                                      rules={[validator.require]}
                                    >
                                      <FloatTimePicker
                                        label="Start Time"
                                        placeholder="Start Time"
                                        mode
                                        onChange={(e) =>
                                          handleDateCheckStart(e, name)
                                        }
                                        required
                                      />
                                    </Form.Item>
                                  </Col>

                                  <Col xs={24} sm={24} md={6} lg={6}>
                                    <Form.Item
                                      {...restField}
                                      name={[name, "time_end"]}
                                      rules={[validator.require]}
                                    >
                                      <FloatTimePicker
                                        label="End Time"
                                        placeholder="End Time"
                                        mode
                                        onChange={(e) =>
                                          handleDateCheckEnd(e, name)
                                        }
                                        required
                                      />
                                    </Form.Item>
                                  </Col>

                                  {restField.fieldKey !== 0 && (
                                    <Col xs={24} sm={24} md={6} lg={6}>
                                      <Button
                                        onClick={(e) => remove(name)}
                                        style={{ height: "45px" }}
                                        className="btn-danger m-b-md"
                                      >
                                        <FontAwesomeIcon
                                          icon={faTrash}
                                          size="large"
                                        />
                                      </Button>
                                    </Col>
                                  )}
                                </Row>
                              </div>
                            ))}
                            <Row gutter={24}>
                              <Col xs={24} sm={24} md={6} lg={6}>
                                <Form.Item>
                                  <Button
                                    type="dashed"
                                    onClick={() => add()}
                                    block
                                    // icon={<PlusOutlined />}
                                  >
                                    Add field
                                  </Button>
                                </Form.Item>
                              </Col>
                            </Row>
                          </>
                        )}
                      </Form.List>
                    </Col>
                  </Row>
                </Panel>
              </Collapse>
              <br />
            </Col>
          </Row>

          <Row gutter={24}>
            <Col xs={24} sm={24} md={24} lg={24}>
              <Collapse
                className="main-1-collapse"
                expandIcon={({ isActive }) =>
                  isActive ? (
                    <span
                      className="ant-menu-submenu-arrow"
                      style={{ color: "#FFF", transform: "rotate(270deg)" }}
                    ></span>
                  ) : (
                    <span
                      className="ant-menu-submenu-arrow"
                      style={{ color: "#FFF", transform: "rotate(90deg)" }}
                    ></span>
                  )
                }
                defaultActiveKey={["1"]}
                expandIconPosition="start"
              >
                <Panel
                  header="CATEGORIES"
                  key="1"
                  className="accordion bg-darkgray-form"
                >
                  <Row gutter={24}>
                    <Col xs={24} sm={24} md={12} lg={12}>
                      <Form.Item
                        name="categories"
                        rules={[validator.require]}
                        className="form-select-error-multi"
                      >
                        <FloatSelect
                          label="Categories"
                          placeholder="Categories"
                          options={categories}
                          multi="multiple"
                          required
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Panel>
              </Collapse>
              <br />
            </Col>
          </Row>

          <Row gutter={24}>
            <Col xs={24} sm={24} md={24} lg={24}>
              <Collapse
                className="main-1-collapse"
                expandIcon={({ isActive }) =>
                  isActive ? (
                    <span
                      className="ant-menu-submenu-arrow"
                      style={{ color: "#FFF", transform: "rotate(270deg)" }}
                    ></span>
                  ) : (
                    <span
                      className="ant-menu-submenu-arrow"
                      style={{ color: "#FFF", transform: "rotate(90deg)" }}
                    ></span>
                  )
                }
                defaultActiveKey={["1"]}
                expandIconPosition="start"
              >
                <Panel
                  header="LOCATION (CITIES)"
                  key="1"
                  className="accordion bg-darkgray-form"
                >
                  <Row gutter={24}>
                    <Col xs={24} sm={24} md={6} lg={6}>
                      <Form.Item
                        name="country_location"
                        rules={[validator.require]}
                        className="form-select-error"
                      >
                        <FloatSelect
                          label="Country"
                          placeholder="Country"
                          options={
                            data_get_manager_state_area
                              ? data_get_manager_state_area.countries
                              : []
                          }
                          // onChange={handleCountry}
                          onChange={handleCountryLocation}
                          // disabled={true}
                          required
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={6} lg={6}>
                      <Form.Item
                        name="state_location"
                        rules={[validator.require]}
                        className="form-select-error"
                      >
                        <FloatSelect
                          label={stateLabelLocation}
                          placeholder={stateLabelLocation}
                          options={optionStateLocation}
                          onChange={handleStateLocation}
                          required
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12}>
                      <Form.Item
                        name="city_location"
                        rules={[validator.require]}
                        className="form-select-error-multi"
                      >
                        {/* <FloatInput label="City" placeholder="City" /> */}
                        <FloatSelect
                          multi="multiple"
                          label="City"
                          placeholder="City"
                          options={optionCityLocation}
                          required
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Panel>
              </Collapse>
              <br />
            </Col>
          </Row>

          <Row gutter={24}>
            <Col xs={24} sm={24} md={24} lg={24}>
              <Collapse
                className="main-1-collapse"
                expandIcon={({ isActive }) =>
                  isActive ? (
                    <span
                      className="ant-menu-submenu-arrow"
                      style={{ color: "#FFF", transform: "rotate(270deg)" }}
                    ></span>
                  ) : (
                    <span
                      className="ant-menu-submenu-arrow"
                      style={{ color: "#FFF", transform: "rotate(90deg)" }}
                    ></span>
                  )
                }
                defaultActiveKey={["1"]}
                expandIconPosition="start"
              >
                <Panel
                  header="TAGS"
                  key="1"
                  className="accordion bg-darkgray-form"
                >
                  <Row gutter={24}>
                    <Col xs={24} sm={24} md={12} lg={12}>
                      <Form.Item
                        name="tags"
                        rules={[validator.require]}
                        className="form-select-error-multi"
                      >
                        <FloatSelect
                          label="Tags"
                          placeholder="Tags"
                          options={sportList}
                          // options={[]}
                          multi="multiple"
                          required
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Panel>
              </Collapse>
              <br />
            </Col>
          </Row>

          <br />
          <Row gutter={24}>
            <Col xs={24} sm={24} md={10} lg={10} xl={6}>
              <Button
                size="large"
                className="btn-main-outline-2"
                // htmlType="submit"
                loading={isLoadingCreate}
                onClick={(e) => {
                  form
                    .validateFields()
                    .then((values) => {
                      onFinish(values);
                    })
                    .catch((info) => {
                      notification.warning({
                        message: "Warning",
                        description: "Please fill-up required fields!",
                      });
                    });
                }}
                block
              >
                {id ? "Update" : "Submit"} Assessment Event
              </Button>
            </Col>
          </Row>
          <br />
        </Form>
      </Card>
    </>
  );
}
