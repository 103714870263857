import React from "react";
import { Avatar, Menu, Typography } from "antd";
import { GoPrimitiveDot } from "react-icons/go";
import { Link, useHistory } from "react-router-dom";
import companyInfo from "../../../providers/companyInfo";

const userData = companyInfo().userData;

const MessagesAlert = ({ messages, refetch }) => {
  let history = useHistory();
  // const { mutate: mutateRead, isLoading: isLoadingRead } = POST(
  // 	"api/v1/read",
  // 	"get_message_convo"
  // );

  const messageList = () => {
    if (messages && messages.length > 0) {
      return messages.map((item, index) => {
        let user;

        if (userData.id === item.to_id) {
          user = item.from;
        }
        if (userData.id === item.from_id) {
          user = item.to;
        }

        let image = user?.profile_image;
        if (image) {
          image = image.includes("gravatar")
            ? image
            : `${process.env.REACT_APP_API_URL}${image}`;
        } else {
          image = `${process.env.REACT_APP_API_URL}images/default.png`;
        }
        console.log("gravatar", image);

        return (
          <Menu.Item
            key={index}
            onClick={() =>
              history.push("/messages?message_id=" + item.message_id)
            }
            icon={<Avatar src={image} style={{ width: 40, height: 40 }} />}
            style={{ height: "60px" }}
            ellipsis
          >
            <Link to="#" style={{ paddingTop: "5px" }} ellipsis>
              <Typography.Text strong ellipsis>
                {user.firstname} {user.lastname}
              </Typography.Text>
              <Typography.Paragraph ellipsis={{ rows: 2 }}>
                {item.message}
              </Typography.Paragraph>
            </Link>

            {item.unread === 1 ? (
              <span className="ant-status-container">
                <GoPrimitiveDot />
              </span>
            ) : null}
          </Menu.Item>
        );
      });
    } else {
      return (
        <Menu.Item key="#" className="text-center ant-message">
          <Link to="#">No messages</Link>
        </Menu.Item>
      );
    }
  };

  return (
    <Menu>
      <Menu.Item key="#messages" className="title">
        <Link to="#">Messages</Link>
      </Menu.Item>
      <Menu.Divider key="#messagedivider" />
      {messageList()}
    </Menu>
  );
};

export default MessagesAlert;
