import { faBell, faTag, faTags } from "@fortawesome/free-solid-svg-icons";
import {
  faBaseball,
  faCalendarAlt,
  faAlarmClock,
  faCommentDots,
  faHandHoldingUsd,
  faHome,
  // faLightbulbOn,
  faPaperPlane,
  faSchool,
  faTicketAlt,
  faUsers,
  faUserCog,
  faFilePdf,
  faEye,
  faMoneyBill,
  faCog,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const AdminSideMenu = [
  {
    title: "Dashboard",
    path: "/dashboard",
    icon: <FontAwesomeIcon icon={faHome} style={{ marginTop: "-5px" }} />,
    permission: "Dashboard",
  },
  {
    title: "Account Type",
    path: "/account-type",
    icon: <FontAwesomeIcon icon={faUserCog} style={{ marginTop: "-5px" }} />,
    children: [
      {
        title: "Athlete",
        path: "/account-type/athlete",
        permission: "Account Type - Athlete",
      },
      {
        title: "Athlete Guardian",
        path: "/account-type/athlete-guardian",
        permission: "Account Type - Athlete Guardian",
      },
      {
        title: "Coach",
        path: "/account-type/coach",
        permission: "Account Type - Coach",
      },
    ],
  },
  {
    title: "Users",
    path: "/user",
    icon: <FontAwesomeIcon icon={faUsers} style={{ marginTop: "-5px" }} />,
    children: [
      {
        title: "View / Edit Active",
        path: "/user/view-edit-active",
        permission: "User View / Edit Active",
      },
      {
        title: "View / Edit Deactivated",
        path: "/user/view-edit-deactivated",
        permission: "User View / Edit Deactivated",
      },
      {
        title: "Create Account",
        path: "/user/create-account",
        permission: "User Create Account",
      },
      {
        title: "Search Athletes",
        path: "/user/search-athletes",
        permission: "User Search Athletes",
      },
      {
        title: "Create Athlete",
        path: "/user/create-account-athlete",
        permission: "User Create Athlete",
      },
      // {
      // 	title: "User Type Permissions",
      // 	path: "/user/user-type-permission",
      // 	permission: "User Type Permissions",
      // },
    ],
  },
  {
    title: "Organizations",
    path: "/organization",
    icon: <FontAwesomeIcon icon={faSchool} style={{ marginTop: "-5px" }} />,
    children: [
      {
        title: "View / Edit",
        path: "/organization/view-edit",
        permission: "Organization View / Edit",
      },
      {
        title: "Add New",
        path: "/organization/add-new",
        permission: "Organization Add New",
      },
      {
        title: "Pending Approval",
        path: "/organization/pending-approval",
        permission: "Organization Pending Approval",
      },
    ],
  },
  {
    title: "Sport",
    path: "/sport",
    icon: <FontAwesomeIcon icon={faBaseball} style={{ marginTop: "-5px" }} />,
    permission: "Sport",
  },
  {
    title: "Coupon",
    path: "/coupon",
    icon: <FontAwesomeIcon icon={faTag} style={{ marginTop: "-5px" }} />,
    permission: "Coupon",
  },
  //
  {
    title: "Notification",
    path: "/notification",
    icon: <FontAwesomeIcon icon={faBell} style={{ marginTop: "-5px" }} />,
    permission: "Notification",
  },
  {
    title: "My Events Calendar",
    path: "/events-calendar",
    icon: (
      <FontAwesomeIcon icon={faCalendarAlt} style={{ marginTop: "-5px" }} />
    ),
    children: [
      {
        title: "Group Assessments",
        path: "/events-calendar/group-assessment",
        permission: "Event Calendar Group Assessments",
      },
      {
        title: "Private Assessments",
        path: "/events-calendar/private-assessment",
        permission: "Event Calendar Private Assessments",
      },
    ],
  },
  {
    title: "Assessments",
    path: "/assessment",
    icon: <FontAwesomeIcon icon={faAlarmClock} style={{ marginTop: "-5px" }} />,
    children: [
      {
        title: "Pending Assessments",
        path: "/assessment/pending-assessment",
        permission: "Pending Assessments",
      },
    ],
  },
  {
    title: "Athlete PDF Display Data",
    path: "/athlete-pdf-display-data",
    icon: <FontAwesomeIcon icon={faFilePdf} style={{ marginTop: "-5px" }} />,
    permission: "Athlete PDF Display Data",
  },
  {
    title: "Assessment PDF",
    path: "/assessment-pdf",
    icon: <FontAwesomeIcon icon={faFilePdf} style={{ marginTop: "-5px" }} />,
    permission: "Assessment PDF",
  },
  {
    title: "Editable Templates",
    path: "/editable-template",
    icon: <FontAwesomeIcon icon={faPaperPlane} style={{ marginTop: "-5px" }} />,
    permission: "Editable Templates",
  },
  {
    title: "Revenue / Manager",
    path: "/revenue-manager",
    icon: (
      <FontAwesomeIcon icon={faHandHoldingUsd} style={{ marginTop: "-5px" }} />
    ),
    children: [
      {
        title: "Pending Payment",
        path: "/revenue-manager/pending-payment",
        permission: "Revenue Managers Pending Payment",
      },
      {
        title: "Paid",
        path: "/revenue-manager/paid",
        permission: "Revenue Managers Paid",
      },
    ],
  },
  {
    title: "Revenue / Organization",
    path: "/revenue-organization",
    icon: (
      <FontAwesomeIcon icon={faHandHoldingUsd} style={{ marginTop: "-5px" }} />
    ),
    children: [
      {
        title: "Pending Payment",
        path: "/revenue-organization/pending-payment",
        permission: "Revenue Organizations Pending Payment",
      },
      {
        title: "Paid",
        path: "/revenue-organization/paid",
        permission: "Revenue Organizations Paid",
      },
    ],
  },
  // {
  // 	title: "FAQ'S",
  // 	path: "/faq",
  // 	icon: <FontAwesomeIcon icon={faLightbulbOn}  style={{ marginTop: "-5px" }}/>,
  // 	permission: "FAQ",
  // },
  {
    title: "Ticketing",
    path: "/ticketing",
    icon: <FontAwesomeIcon icon={faTicketAlt} style={{ marginTop: "-5px" }} />,
    permission: "Ticketing",
  },
  {
    title: "Messages",
    path: "/messages",
    icon: (
      <FontAwesomeIcon icon={faCommentDots} style={{ marginTop: "-5px" }} />
    ),
    // permission: "Ticketing",
  },
  {
    title: "Fee Setup",
    path: "/fee-setup",
    icon: <FontAwesomeIcon icon={faMoneyBill} style={{ marginTop: "-5px" }} />,
    // permission: "Ticketing",
  },
  {
    title: "Maintenance",
    path: "/maintenance-configuration",
    icon: <FontAwesomeIcon icon={faCog} style={{ marginTop: "-5px" }} />,
    // permission: "Ticketing",
  },
  {
    title: "View As",
    path: "/viewas",
    icon: <FontAwesomeIcon icon={faEye} style={{ marginTop: "-5px" }} />,
    // permission: "Ticketing",
  },
];

export default AdminSideMenu;
