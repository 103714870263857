import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Button, Card, Col, Collapse, Form, notification, Row } from "antd";
import { DeleteFilled, PlusOutlined } from "@ant-design/icons";
import { faUser } from "@fortawesome/pro-solid-svg-icons";

import ComponentHeader from "../../Components/ComponentHeader";

import FloatInput from "../../../../providers/FloatInput";
import FloatInputMask from "../../../../providers/FloatInputMask";
import FloatSelect from "../../../../providers/FloatSelect";
import FloatSelectMulti from "../../../../providers/FloatSelectMulti";
import optionCountryCodes from "../../../../providers/optionCountryCodes";
import optionStateCodesUnitedState from "../../../../providers/optionStateCodesUnitedState";
import optionStateCodesCanada from "../../../../providers/optionStateCodesCanada";
// import optionStateCodes from "../../../../providers/optionStateCodes";
import { GET, POST } from "../../../../providers/useAxiosQuery";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave } from "@fortawesome/free-solid-svg-icons";
import FloatInputMaskNoFeedback from "../../../../providers/FloatInputMaskNoFeedback";

export default function PageUserEditAccount(props) {
  const history = useHistory();
  const { match } = props;
  const { Panel } = Collapse;
  const [form] = Form.useForm();
  const manager_id = match.params.id;

  const country = optionCountryCodes;
  const stateUS = optionStateCodesUnitedState();
  const stateCA = optionStateCodesCanada();

  const [stateLabel, setStateLabel] = useState("State");
  const [optionState, setOptionState] = useState(stateUS);
  const [zipLabel, setZipLabel] = useState("Zip Code");
  const [optionZip, setOptionZip] = useState(/(^\d{5}$)|(^\d{5}[ -]\d{4}$)/);

  const [listCoverage, setListCoverage] = useState([
    {
      id: 0,
      stateLabel: "State",
      optionState: [],
      optionCity: [],
    },
  ]);

  const [cityOption, setCityOption] = useState([]);
  const [cityOptionData, setCityOptionData] = useState([]);
  GET(`api/v1/get_manager_data?id=${manager_id}`, "get_manager_data", (res) => {
    if (res.success) {
      console.log("get_manager_data", res);

      let coverage = [];
      let coverage_array_option = [];

      res.coverage.length > 0 &&
        res.coverage.map((row, index) => {
          // console.log("row.city", row.city);
          let check_city = row.city;

          coverage.push({
            country: row.country,
            state: row.state,
            city: JSON.parse(row.city),
          });

          let cities = [];
          // console.log("row.state row.state", row.state);
          if (row.country === "United States") {
            let newState = stateUS.filter(
              (itemFilter) => itemFilter.value === row.state
            );
            // console.log(newState);
            if (newState.length > 0) {
              cities = newState[0].json;
            }
          } else {
            let newState = stateCA.filter(
              (itemFilter) => itemFilter.value === row.state
            );
            // console.log(newState);
            if (newState.length > 0) {
              cities = newState[0].json;
            }
          }

          coverage_array_option.push({
            id: index,
            stateLabel: row.country === "United States" ? "State" : "County",
            optionState: row.country === "United States" ? stateUS : stateCA,
            optionCity: cities
              ? [
                  {
                    label: "Select All",
                    value: "All",
                  },
                  ...cities,
                ]
              : [],
          });
          return "";
        });

      // console.log("coverage_array_option", coverage_array_option);

      if (res.coverage.length > 0) {
        setListCoverage(coverage_array_option);
      }

      form.setFieldsValue({
        username: res.data.username,
        email: res.data.email,
        firstname: res.data.firstname,
        lastname: res.data.lastname,
        phone_number: res.data.phone_number,
        coverage_zone_name: res.data.coverage_zone_name
          ? res.data.coverage_zone_name
          : undefined,
        country:
          res.data.user_address.length > 0
            ? res.data.user_address[0].country
            : "",
        address1:
          res.data.user_address.length > 0
            ? res.data.user_address[0].address1
            : "",
        address2:
          res.data.user_address.length > 0
            ? res.data.user_address[0].address2
              ? res.data.user_address[0].address2
              : undefined
            : undefined,
        city:
          res.data.user_address.length > 0 ? res.data.user_address[0].city : "",
        state:
          res.data.user_address.length > 0
            ? res.data.user_address[0].state
            : "",
        zip_code:
          res.data.user_address.length > 0
            ? res.data.user_address[0].zip_code
            : "",

        assessor_username: res.assessor ? res.assessor.username : undefined,
        assessor_email: res.assessor ? res.assessor.email : undefined,
        managers_coverage_area: res.coverage.length > 0 ? coverage : [""],
      });
      setCityOptionData(res.coverage.length > 0 ? coverage : [""]);

      if (res.data.user_address.length > 0) {
        // res.data.user_address[0].state
        if (res.data.user_address[0].country === "United States") {
          let rows = stateUS.filter(
            (val) => val.value === res.data.user_address[0].state
          );
          setCityOption(rows[0]["json"]);
        } else if (res.data.user_address[0].country === "Canada") {
          let rows = stateCA.filter(
            (val) => val.value === res.data.user_address[0].state
          );
          setCityOption(rows[0]["json"]);
        }
      }

      if (res.data.user_address[0].country === "United States") {
        setStateLabel("State");
        setZipLabel("Zip Code");
        setOptionState(stateUS);
        setOptionZip(/(^\d{5}$)|(^\d{5}[ -]\d{4}$)/);
      } else if (res.data.user_address[0].country === "Canada") {
        setStateLabel("County");
        setZipLabel("Postal Code");
        setOptionState(stateCA);
        setOptionZip(/^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/);
      }
    }
  });

  const onFinish = (values) => {
    let data = {
      ...values,
      id: manager_id,
      link_origin: window.location.origin,
    };

    // console.log("asdas", data);

    mutateAdd(data, {
      onSuccess: (res) => {
        if (res.success) {
          form.resetFields();
          notification.success({
            message: "Success",
            description: "Successfully updated!",
          });
          history.goBack();
        } else {
          notification.error({
            message: "Warning",
            description: res.message,
          });
        }
      },
    });
  };

  const { mutate: mutateAdd, isLoading: isLoadingAdd } = POST(
    "api/v1/update_manager_data",
    "update_manager_data"
  );

  const handleCountry = (val, opt) => {
    if (val === "United States") {
      setStateLabel("State");
      setZipLabel("Zip Code");
      setOptionState(stateUS);
      setOptionZip(/(^\d{5}$)|(^\d{5}[ -]\d{4}$)/);
    } else if (val === "Canada") {
      setStateLabel("County");
      setZipLabel("Postal Code");
      setOptionState(stateCA);
      setOptionZip(/^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/);
    }

    form.resetFields(["state", "zip_code", "city"]);
  };

  const handleCoverageCountry = (val, opt, index) => {
    let row = [...listCoverage];

    if (val === "United States") {
      row[index] = {
        ...row[index],
        stateLabel: "State",
        optionState: stateUS,
        optionCity: [],
      };
    } else if (val === "Canada") {
      row[index] = {
        ...row[index],
        stateLabel: "County",
        optionState: stateCA,
        optionCity: [],
      };
    }
    setListCoverage(row);
  };

  const removeItemOrg = (index) => {
    listCoverage.splice(index, 1);
    setListCoverage(listCoverage);
  };

  const { mutate: mutateCheckCoverageState } = POST(
    "api/v1/check_coverage_state",
    "check_coverage_state"
  );

  const { mutate: mutateCheckCoverageStateAll } = POST(
    "api/v1/check_coverage_state_all",
    "check_coverage_state"
  );

  const [submitButtonDisabled, setSubmitButtonDisabled] = useState(false);
  const handleCoverageState = (val, opt, name) => {
    if (val) {
      let formData = form.getFieldsValue().managers_coverage_area;
      let state_value = formData[name]["state"];
      let city_value = formData[name]["city"];
      // let city_value = val.slice(-1).pop();

      let data = { state: state_value, city: val };
      if (val === "All") {
        let data = {
          state: state_value,
          city: listCoverage[name].optionCity,
          manager_id: manager_id,
        };
        console.log("handleCoverageState", data);

        mutateCheckCoverageStateAll(data, {
          onSuccess: (res) => {
            if (res.success) {
              console.log("handleCoverageState-All", res);
              let result = res.successCities.filter((e) => e !== "All");
              let row = [...formData];
              row[name] = {
                ...row[name],
                city: result,
              };
              form.setFieldsValue({
                managers_coverage_area: row,
              });

              if (res.errorCities.length > 0) {
                notification.warning({
                  message: "Warning",
                  description:
                    "This cities had aready been selected, (" +
                    res.errorCities.toString() +
                    ")",
                });
              }
            } else {
              // console.log("handleCoverageState-All", res);

              let result = res.successCities.filter((e) => e !== "All");
              let row = [...formData];
              row[name] = {
                ...row[name],
                city: result,
              };
              form.setFieldsValue({
                managers_coverage_area: row,
              });
            }
          },
        });
      } else {
        mutateCheckCoverageState(data, {
          onSuccess: (res) => {
            if (res.success) {
              let result = res.data;
              if (cityOptionData[name]) {
                let defaultCityData = cityOptionData[name]["city"];
                if (defaultCityData.length > 0) {
                  let defaultCityDataCheck = defaultCityData.filter(
                    (item) => item === val
                  );
                  if (defaultCityDataCheck.length > 0) {
                    // console.log("handleCoverageState", defaultCityDataCheck);
                  } else {
                    let check = res.data.filter((item) => item === val);
                    if (check.length > 0) {
                      let getValueArray = formData[name]["city"];
                      let newRowValue = getValueArray.filter((item) => {
                        if (item !== val) {
                          return item;
                        }
                      });
                      let row = [...formData];
                      row[name] = {
                        ...row[name],
                        city: newRowValue,
                      };

                      notification.warning({
                        message: "Warning",
                        description: "This city is already been taken",
                      });
                      form.setFieldsValue({
                        managers_coverage_area: row,
                      });
                    } else {
                      // console.log("handleCoverageState available", data);
                    }
                  }
                } else {
                  // console.log("handleCoverageState naa nas lain", data);
                  // let check = res.data.filter((item) => item === val);
                }
              } else {
                let check = res.data.filter((item) => item === val);
                if (check.length > 0) {
                  let getValueArray = formData[name]["city"];
                  let newRowValue = getValueArray.filter((item) => {
                    if (item !== val) {
                      return item;
                    }
                  });

                  let row = [...formData];
                  row[name] = {
                    ...row[name],
                    city: newRowValue,
                  };

                  notification.warning({
                    message: "Warning",
                    description: "This city is already been taken",
                  });
                  form.setFieldsValue({
                    managers_coverage_area: row,
                  });
                } else {
                  // console.log("handleCoverageState available", data);
                }
              }
            }
          },
        });
      }
    }
  };

  useEffect(() => {
    // console.log("listCoverage", listCoverage);
  }, [listCoverage]);

  return (
    <>
      <ComponentHeader
        // sub_title="EDIT MANAGER / ASSESSOR"
        sub_title="VIEW / EDIT"
        title={"User"}
        icon={faUser}
      />

      <Card id="PageUserEditAccount">
        <Form
          layout="vertical"
          onFinish={onFinish}
          autoComplete="off"
          form={form}
          initialValues={{ managers_coverage_area: [""] }}
          scrollToFirstError
        >
          <Collapse
            className="ant-collapse-primary"
            expandIcon={({ isActive }) =>
              isActive ? (
                <span
                  className="ant-menu-submenu-arrow"
                  style={{ color: "#FFF", transform: "rotate(270deg)" }}
                ></span>
              ) : (
                <span
                  className="ant-menu-submenu-arrow"
                  style={{ color: "#FFF", transform: "rotate(90deg)" }}
                ></span>
              )
            }
            expandIconPosition="right"
            defaultActiveKey={["1", "2", "3"]}
          >
            <Panel
              header="MANAGER'S INFORMATION"
              key="1"
              className="accordion bg-darkgray-form"
            >
              <Row gutter={12}>
                <Col xs={24} sm={24} md={12}>
                  <Form.Item name="username">
                    <FloatInput
                      label="Username"
                      placeholder="Username"
                      disabled={true}
                      onBlurInput={(e) => {
                        let username = form.getFieldsValue().username;
                        let usernameObject1 = `${username}_assessor`;
                        // console.log("email", usernameObject1);

                        form.setFieldsValue({
                          assessor_username: usernameObject1,
                        });
                      }}
                      required
                    />
                  </Form.Item>
                </Col>

                <Col xs={24} sm={24} md={12}>
                  <Form.Item name="email">
                    <FloatInput
                      label="Email"
                      placeholder="Email"
                      disabled={true}
                      onBlurInput={(e) => {
                        let email = form.getFieldsValue().email.split("@");
                        let emailObject1 = `${email[0]}+assessor`;
                        let finalEmail = `${emailObject1}@${email[1]}`;
                        // console.log("email", finalEmail);

                        form.setFieldsValue({
                          assessor_email: finalEmail,
                        });
                      }}
                      required
                    />
                  </Form.Item>
                </Col>

                <Col xs={24} sm={24} md={12}>
                  <Form.Item
                    name="firstname"
                    rules={[
                      {
                        required: true,
                        message: "This field is required.",
                      },
                    ]}
                  >
                    <FloatInput
                      label="First Name"
                      placeholder="First Name"
                      required
                    />
                  </Form.Item>
                </Col>

                <Col xs={24} sm={24} md={12}>
                  <Form.Item
                    name="lastname"
                    rules={[
                      {
                        required: true,
                        message: "This field is required.",
                      },
                    ]}
                  >
                    <FloatInput
                      label="Last Name"
                      placeholder="Last Name"
                      required
                    />
                  </Form.Item>
                </Col>

                <Col xs={24} sm={24} md={12}>
                  <Form.Item
                    name="phone_number"
                    rules={[
                      {
                        required: true,
                        message: "This field is required.",
                      },
                      {
                        pattern:
                          /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/,
                        message: "Invalid Phone Number",
                      },
                    ]}
                  >
                    <FloatInputMaskNoFeedback
                      label="Phone Number"
                      placeholder="Phone Number"
                      maskLabel="phone_number"
                      maskType="999-999-9999"
                      required
                    />
                  </Form.Item>
                </Col>

                <Col xs={24} sm={24} md={12}>
                  <Form.Item
                    name="country"
                    className="form-select-error"
                    rules={[
                      {
                        required: true,
                        message: "This field is required.",
                      },
                    ]}
                  >
                    <FloatSelect
                      label="Country"
                      placeholder="Country"
                      options={country}
                      onChange={handleCountry}
                      required
                    />
                  </Form.Item>
                </Col>

                <Col xs={24} sm={24} md={24}>
                  <Form.Item
                    name="address1"
                    rules={[
                      {
                        required: true,
                        message: "This field is required.",
                      },
                    ]}
                  >
                    <FloatInput
                      label="Address 1"
                      placeholder="Address 1"
                      required
                    />
                  </Form.Item>
                </Col>

                <Col xs={24} sm={24} md={24}>
                  <Form.Item name="address2">
                    <FloatInput label="Address 2" placeholder="Address 2" />
                  </Form.Item>
                </Col>

                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="state"
                    className="form-select-error"
                    rules={[
                      {
                        required: true,
                        message: "This field is required.",
                      },
                    ]}
                  >
                    <FloatSelect
                      label={stateLabel}
                      placeholder={stateLabel}
                      options={optionState}
                      onChange={(val, opt) => {
                        let rows = opt["data-json"];
                        setCityOption(rows);

                        form.resetFields(["city"]);
                      }}
                      required
                    />
                  </Form.Item>
                </Col>

                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="city"
                    className="form-select-error"
                    rules={[
                      {
                        required: true,
                        message: "This field is required.",
                      },
                    ]}
                  >
                    <FloatSelect
                      label="City"
                      placeholder="City"
                      options={cityOption}
                      required
                    />
                  </Form.Item>
                </Col>

                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="zip_code"
                    rules={[
                      {
                        required: true,
                        message: "This field is required.",
                      },
                      {
                        pattern: optionZip,
                        message: "Invalid " + zipLabel,
                      },
                    ]}
                  >
                    <FloatInput
                      label={zipLabel}
                      placeholder={zipLabel}
                      required
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Panel>

            <Panel
              header="MANAGER'S COVERAGE AREA"
              key="2"
              className="accordion bg-darkgray-form  m-t-lg"
            >
              <Col xs={24} sm={24} md={24}>
                <Form.Item
                  name="coverage_zone_name"
                  rules={[
                    {
                      required: true,
                      message: "This field is required.",
                    },
                  ]}
                >
                  <FloatInput
                    label="Coverage Zone Name"
                    placeholder="Coverage Zone Name"
                    required
                  />
                </Form.Item>
              </Col>

              <Col span={24} className="ant-form-list-nested-item">
                <Form.List name="managers_coverage_area">
                  {(fields, { add, remove }, { errors }) => (
                    <>
                      {fields.map(({ key, name, ...restField }) => (
                        <div key={key}>
                          <Form.Item
                            {...restField}
                            name={[name, "id"]}
                            style={{ display: "none" }}
                          >
                            <FloatInput label="id" placeholder="id" />
                          </Form.Item>
                          <Row gutter={24}>
                            <Col xs={24} sm={24} md={8}>
                              <Form.Item
                                {...restField}
                                name={[name, "country"]}
                                className="form-select-error"
                                rules={[
                                  {
                                    required: true,
                                    message: "This field is required.",
                                  },
                                ]}
                              >
                                <FloatSelect
                                  label="Country"
                                  placeholder="Country"
                                  options={country}
                                  onChange={(val, opt) => {
                                    {
                                      handleCoverageCountry(val, opt, name);
                                      let getStates =
                                        form.getFieldValue()
                                          .managers_coverage_area;
                                      let row = [...getStates];
                                      row[name] = {
                                        ...row[name],
                                        state: undefined,
                                        city: undefined,
                                      };
                                      form.setFieldsValue({
                                        managers_coverage_area: row,
                                      });
                                    }
                                  }}
                                  required
                                />
                              </Form.Item>
                            </Col>

                            <Col xs={24} sm={24} md={8}>
                              <Form.Item
                                {...restField}
                                name={[name, "state"]}
                                className="form-select-error"
                                rules={[
                                  {
                                    required: true,
                                    message: "This field is required.",
                                  },
                                ]}
                                // hasFeedback
                              >
                                <FloatSelect
                                  label={listCoverage[name].stateLabel}
                                  placeholder={listCoverage[name].stateLabel}
                                  options={listCoverage[name].optionState}
                                  required
                                  // onChange={handleCoverageState}
                                  onChange={(val, opt) => {
                                    // handleCoverageState(val, opt, name);
                                    if (val) {
                                      // setSubmitButtonDisabled(false);
                                      let getStates =
                                        form.getFieldValue()
                                          .managers_coverage_area;

                                      console.log("getStates", getStates);

                                      let filterState = getStates.filter(
                                        (item) => item.state === val
                                      );

                                      if (filterState.length >= 2) {
                                        let row = [...getStates];
                                        row[name] = {
                                          ...row[name],
                                          state: "",
                                          city: [],
                                        };

                                        form.setFieldsValue({
                                          managers_coverage_area: row,
                                        });

                                        notification.warning({
                                          message: "Warning",
                                          description:
                                            "You already selected this state!",
                                        });

                                        let rowC = [...listCoverage];
                                        rowC[name] = {
                                          ...rowC[name],
                                          optionCity: [],
                                        };

                                        console.log("setListCoverage", rowC);
                                        setListCoverage(rowC);
                                      } else {
                                        let rowC = [...listCoverage];
                                        rowC[name] = {
                                          ...rowC[name],
                                          optionCity: [
                                            {
                                              label: "Select All",
                                              value: "All",
                                            },
                                            ...opt["data-json"],
                                          ],
                                        };
                                        console.log("setListCoverage", rowC);
                                        setListCoverage(rowC);

                                        let row = [...getStates];
                                        row[name] = {
                                          ...row[name],
                                          city: [],
                                        };

                                        form.setFieldsValue({
                                          managers_coverage_area: row,
                                        });
                                      }
                                    }
                                  }}
                                />
                              </Form.Item>
                            </Col>

                            <Col
                              xs={24}
                              sm={24}
                              md={restField.fieldKey !== 0 ? 6 : 8}
                            >
                              <Form.Item
                                {...restField}
                                name={[name, "city"]}
                                rules={[
                                  {
                                    required: true,
                                    message: "This field is required.",
                                  },
                                ]}
                                // hasFeedback
                                // className="form-select-error-multi"
                              >
                                <FloatSelectMulti
                                  multi="multiple"
                                  label="City"
                                  required
                                  placeholder="City"
                                  options={listCoverage[name].optionCity}
                                  // options={[
                                  //   { label: "Select All", value: "All" },
                                  //   ...listCoverage[name].optionCity,
                                  // ]}
                                  onBlurInput={(val, opt) => {
                                    handleCoverageState(val, opt, name);
                                  }}
                                  allowClear={true}
                                />
                              </Form.Item>
                            </Col>

                            <Col
                              xs={24}
                              sm={24}
                              md={restField.fieldKey !== 0 ? 2 : 2}
                            >
                              {restField.fieldKey !== 0 ? (
                                <Button
                                  onClick={() => {
                                    remove(name);
                                    removeItemOrg(name);
                                  }}
                                  className="m-b-md p-l-n color-9 m-t-n-sm"
                                  type="link"
                                >
                                  <DeleteFilled />
                                </Button>
                              ) : null}
                            </Col>
                          </Row>
                        </div>
                      ))}
                      <Form.Item>
                        <Button
                          onClick={() => {
                            add();
                            setListCoverage([
                              ...listCoverage,
                              {
                                id: listCoverage.length,
                                stateLabel: "State",
                                optionState: [],
                                optionCity: [],
                              },
                            ]);
                          }}
                          type="link"
                          className="btn-link-main-3 p-l-none"
                          icon={<PlusOutlined />}
                        >
                          Add Another
                        </Button>
                        <Form.ErrorList errors={errors} />
                      </Form.Item>
                    </>
                  )}
                </Form.List>
              </Col>
            </Panel>

            <Panel
              header="ASSOCIATED ASSESSOR'S ACCOUNT"
              key="3"
              className="accordion bg-darkgray-form  m-t-lg"
            >
              <Row gutter={12}>
                <Col span={24}>
                  <Form.Item name="assessor_username">
                    <FloatInput
                      label="Username"
                      placeholder="Username"
                      disabled={true}
                      required
                    />
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <Form.Item name="assessor_email">
                    <FloatInput
                      label="Email"
                      placeholder="Email"
                      disabled={true}
                      required
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Panel>
          </Collapse>
          <br />

          <Button
            type="primary"
            htmlType="submit"
            loading={isLoadingAdd}
            className="btn-main-outline-2 float-right"
            disabled={submitButtonDisabled}
            size="large"
          >
            <FontAwesomeIcon icon={faSave} className="m-r-xs" /> SUBMIT
          </Button>
          <br />
          <br />
        </Form>
      </Card>
    </>
  );
}
