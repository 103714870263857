import React, { useState } from "react";
import {
  Card,
  Col,
  Image,
  Row,
  Typography,
  Form,
  Divider,
  Button,
  Radio,
  message,
  Collapse,
  Modal,
  notification,
} from "antd";
import ComponentHeader from "../../Components/ComponentHeader";
import FloatInput from "../../../../providers/FloatInput";
import { faLaptop } from "@fortawesome/pro-regular-svg-icons";
import companyInfo from "../../../../providers/companyInfo";
import moment from "moment";
import ImgCrop from "antd-img-crop";
import { Upload } from "antd";
import FloatInputPassword from "../../../../providers/FloatInputPassword";
import { GET, POST } from "../../../../providers/useAxiosQuery";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import $ from "jquery";
import FloatInputMask from "../../../../providers/FloatInputMask";
import FloatInputPasswordStrength from "../../../../providers/FloatInputPasswordStrength";
import FloatInputMaskNoFeedback from "../../../../providers/FloatInputMaskNoFeedback";

const userData = companyInfo().userData;
const apiUrl = companyInfo().apiUrl;
const encryptor = companyInfo().encryptor;

export default function PageProfile() {
  const [form] = Form.useForm();
  const [modalFormChangePassword] = Form.useForm();

  const [modalChangePasswordVisible, setModalChangePasswordVisible] =
    useState(false);
  const [fileList, setFileList] = useState([]);
  const [radioData, setRadioData] = useState(1);
  const [imageCrop, setImageCrop] = useState({
    width: 1,
    height: 1,
  });
  const [dataSource, setDataSource] = useState([]);

  GET(`api/v1/user_athlete_info?id=${userData.id}`, "update_profile", (res) => {
    console.log("athletic_user_athlete_info", res.data);
    if (res.data) {
      let data = res.data;
      setDataSource(data);

      form.setFieldsValue({
        username: data.username,
        firstname: data.firstname,
        lastname: data.lastname,
        email: data.email,
        phone_number: data.phone_number ? data.phone_number : undefined,
      });

      let image_type = data.profile_image ? data.profile_image.split("/") : "";

      if (Number(res.data.photo_crop) === 1) {
        setImageCrop({
          width: 1,
          height: 1,
        });
        setRadioData(1);
      } else if (Number(res.data.photo_crop) === 2) {
        setImageCrop({
          width: 3.9,
          height: 2.6,
        });
        setRadioData(2);
      } else if (Number(res.data.photo_crop) === 3) {
        setImageCrop({
          width: 1,
          height: 1.5,
        });
        setRadioData(3);
      } else {
        setImageCrop({
          width: 1,
          height: 1,
        });
        setRadioData(1);
      }

      console.log("image_type", image_type);
      if (image_type[0] !== undefined) {
        image_type =
          image_type === "https:"
            ? data.profile_image
            : companyInfo().apiUrl + data.profile_image;

        setFileList([
          {
            uid: "-1",
            name: "image.png",
            status: "done",
            url: image_type,
          },
        ]);
      } else {
        setFileList([]);
        image_type = "";
      }
    }
  });

  const {
    mutate: mutateUpdateAthleteUserPhoto,
    // isLoading: isLoadingUpdateAthleteUser,
  } = POST("api/v1/update_user_athlete_profile_photo", "update_profile");

  const {
    mutate: mutateUpdateAthleteUser,
    // isLoading: isLoadingUpdateAthleteUser,
  } = POST("api/v1/update_user_athlete_profile", "update_profile");

  const {
    mutate: mutateUpdateUserPassword,
    isLoading: isLoadingUpdateUserPassword,
  } = POST("api/v1/profile_change_password", "profile_change_password_athlete");

  const handleResize = (val) => {
    console.log("val", val.target.value);
    setRadioData(val.target.value);
    if (val.target.value === 1) {
      setImageCrop({
        width: 1,
        height: 1,
      });
    } else if (val.target.value === 2) {
      setImageCrop({
        width: 3.9,
        height: 2.6,
      });
    } else if (val.target.value === 3) {
      setImageCrop({
        width: 1,
        height: 1.5,
      });
    }
  };

  const onChangeUpload = ({ fileList: newFileList }) => {
    var _file = newFileList;
    console.log(_file);
    if (_file.length !== 0) {
      _file[0].status = "done";
      setFileList(_file);
      // form.submit();

      console.log(_file);
      const data = new FormData();
      data.append("id", userData.id);
      data.append("photo_crop", radioData);

      if (_file.length !== 0) {
        if (_file[0].uid !== "-1") {
          data.append("profile_image", _file[0].originFileObj, _file[0].nameww);

          mutateUpdateAthleteUserPhoto(data, {
            onSuccess: (res) => {
              console.log("res", res);
              if (res.success) {
                setDataSource(res.data);
                notification.success({
                  message: "Success",
                  description: "Successfully Updated",
                });

                // localStorage.userdata = encryptor.encrypt({
                //   ...res.data,
                // });

                // $(".ant-menu-submenu-profile").attr(
                //   "src",
                //   apiUrl + res.data.profile_image
                // );
                // $(".ant-typography-profile-details-image").attr(
                //   "src",
                //   apiUrl + res.data.profile_image
                // );
                // $(".ant-typography-profile-details-name-info").html(
                //   res.data.firstname + " " + res.data.lastname
                // );
              } else {
                notification.success({
                  message: "Error",
                  description: "Data not updated!",
                });
              }
            },
          });
        }
      }
    } else {
      setFileList([]);
    }
  };

  const onPreviewUpload = async (file) => {
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow.document.write(image.outerHTML);
  };

  const beforeUpload = (file) => {
    const isJpgOrPng =
      file.type === "image/jpeg" ||
      file.type === "image/png" ||
      file.type === "image/gif" ||
      file.type === "image/jpg";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG, PNG, GIF, JPEG file!");

      return;
    }
    const isLt2M = file.size / 102400 / 102400 < 10;
    if (!isLt2M) {
      message.error("Image must smaller than 10MB!");

      return;
    }

    return isJpgOrPng && isLt2M;
  };

  const onFinish = (values) => {
    console.log("onFinishInfomation", values);

    const data = new FormData();

    data.append("id", userData.id);

    Object.keys(values).map(function (key) {
      // console.log("key, values[key]", key, values[key]);
      data.append(key, values[key] ? values[key] : "");
      return "";
    });

    // if (fileList.length !== 0) {
    //   if (fileList[0].uid !== "-1") {
    //     data.append(
    //       "profile_image",
    //       fileList[0].originFileObj,
    //       fileList[0].name
    //     );
    //   }
    // }

    mutateUpdateAthleteUser(data, {
      onSuccess: (res) => {
        console.log("res", res);
        if (res.success) {
          setDataSource(res.data);
          notification.success({
            message: "Success",
            description: "Successfully Updated",
          });

          localStorage.userdata = encryptor.encrypt({
            ...res.data,
          });

          $(".ant-menu-submenu-profile").attr(
            "src",
            apiUrl + res.data.profile_image
          );
          $(".ant-typography-profile-details-image").attr(
            "src",
            apiUrl + res.data.profile_image
          );
          $(".ant-typography-profile-details-name-info").html(
            res.data.firstname + " " + res.data.lastname
          );
        } else {
          notification.success({
            message: "Error",
            description: "Data not updated!",
          });
        }
      },
    });
  };

  const handleOnFinishChangePassword = (values) => {
    // console.log("handleOnFinishChangePassword", values);
    let data = {
      ...values,
      id: userData.id,
    };
    mutateUpdateUserPassword(data, {
      onSuccess: (res) => {
        if (res.success) {
          notification.success({
            message: "Success",
            description: "Successfully Updated",
          });
          setModalChangePasswordVisible(false);
          localStorage.removeItem("userdata");
          localStorage.removeItem("token");
          window.location.replace("/");
        } else {
          notification.success({
            message: "Error",
            description: "Data not updated!",
          });
        }
      },
      onError: (err) => {
        // notification.success({
        // 	message: "Error",
        // 	description: err,
        // });
      },
    });
  };

  const onBlurInput = (value, field) => {
    // console.log("dataSource", dataSource);
    // console.log("onBlurInput value, field", field, value);
    // console.log("dataSource[field]", dataSource[field]);
    if (dataSource[field] !== value) {
      console.log("ssssssssss");
      form.submit();
    }
  };

  return (
    <>
      <ComponentHeader title="Profile" sub_title="EDIT" icon={faLaptop} />

      <Card>
        <Form form={form} onFinish={onFinish}>
          <Row gutter={12}>
            <Col xs={24} sm={24} md={14}>
              <Collapse
                className="main-1-collapse border-none"
                expandIcon={({ isActive }) =>
                  isActive ? (
                    <span
                      className="ant-menu-submenu-arrow"
                      style={{ color: "#FFF", transform: "rotate(270deg)" }}
                    ></span>
                  ) : (
                    <span
                      className="ant-menu-submenu-arrow"
                      style={{ color: "#FFF", transform: "rotate(90deg)" }}
                    ></span>
                  )
                }
                defaultActiveKey={["1", "2"]}
                expandIconPosition="start"
              >
                <Collapse.Panel
                  header="LOGIN INFORMATION"
                  key="1"
                  className="accordion bg-darkgray-form m-b-md border"
                >
                  <Row gutter={12}>
                    <Col xs={24} sm={24} md={24} lg={24}>
                      <Form.Item name="username">
                        <FloatInput
                          label="User Name"
                          placeholder="User Name"
                          disabled={true}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={24}>
                      <Form.Item name="email">
                        <FloatInput
                          label="Email"
                          placeholder="Email"
                          disabled
                          onBlurInput={(e) => onBlurInput(e, "username")}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={24}>
                      <Typography.Link
                        className="companyColor3"
                        onClick={() => setModalChangePasswordVisible(true)}
                      >
                        Change Password
                      </Typography.Link>
                    </Col>
                  </Row>
                </Collapse.Panel>

                <Collapse.Panel
                  header="ADMIN'S INFORMATION"
                  key="2"
                  className="accordion bg-darkgray-form m-b-md border"
                >
                  <Row gutter={12}>
                    <Col xs={24} sm={24} md={24} lg={12}>
                      <Form.Item name="firstname">
                        <FloatInput
                          label="First Name"
                          placeholder="First Name"
                          onBlurInput={(e) => onBlurInput(e, "firstname")}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={12}>
                      <Form.Item name="lastname">
                        <FloatInput
                          label="Last Name"
                          placeholder="Last Name"
                          onBlurInput={(e) => onBlurInput(e, "lastname")}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={12}>
                      <Form.Item
                        name="phone_number"
                        rules={[
                          {
                            pattern:
                              /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/,
                            message: "Invalid Phone Number",
                          },
                        ]}
                      >
                        <FloatInputMaskNoFeedback
                          label="Phone Number"
                          placeholder="Phone Number"
                          maskLabel="phone_number"
                          maskType="999-999-9999"
                          onBlurInput={(e) => onBlurInput(e, "phone_number")}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={12}>
                      <FloatInput
                        label="Date Joined"
                        placeholder="Date Joined"
                        value={moment(userData.created_at).format("MM-DD-YYYY")}
                        disabled={true}
                      />
                    </Col>
                  </Row>
                </Collapse.Panel>
              </Collapse>
            </Col>
            <Col xs={24} sm={24} md={10}>
              <Collapse
                className="main-1-collapse border-none"
                expandIcon={({ isActive }) =>
                  isActive ? (
                    <span
                      className="ant-menu-submenu-arrow"
                      style={{ color: "#FFF", transform: "rotate(270deg)" }}
                    ></span>
                  ) : (
                    <span
                      className="ant-menu-submenu-arrow"
                      style={{ color: "#FFF", transform: "rotate(90deg)" }}
                    ></span>
                  )
                }
                defaultActiveKey={["1", "2"]}
                expandIconPosition="start"
              >
                <Collapse.Panel
                  header="PROFILE PHOTO"
                  key="1"
                  className="accordion bg-darkgray-form m-b-md border"
                >
                  <Row gutter={12}>
                    <Col xs={24} sm={24} md={24}>
                      <label className="font-red">
                        <b>
                          Photo upload & cropping: select your image orientation
                        </b>
                      </label>
                      <br />
                      <Radio.Group onChange={handleResize} value={radioData}>
                        <Radio value={1}>Square</Radio>
                        <Radio value={2}>Rectangle</Radio>
                        <Radio value={3}>Portrait</Radio>
                      </Radio.Group>
                    </Col>
                    <Divider />
                    <Col xs={24} sm={24} md={24}>
                      <div style={{ display: "flex" }}>
                        <ImgCrop
                          rotate
                          aspect={imageCrop.width / imageCrop.height}
                        >
                          <Upload
                            // action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                            listType="picture-card"
                            style={{ width: "200px" }}
                            maxCount={1}
                            action={false}
                            customRequest={false}
                            fileList={fileList}
                            onChange={onChangeUpload}
                            onPreview={onPreviewUpload}
                            beforeUpload={beforeUpload}
                            className="profilePhoto"
                          >
                            {fileList.length < 1 && "+ Upload"}
                          </Upload>
                        </ImgCrop>
                      </div>
                    </Col>
                    <Divider />
                    <Col xs={24} sm={24} md={24}>
                      {userData.role === "Athlete" ? (
                        <Typography.Text strong>
                          No longer need your account and want to deactivate it?
                          You can request deactivating you account via our
                          ticketing system.
                        </Typography.Text>
                      ) : (
                        <Typography.Text strong>
                          No longer need your Parent / Guardian on your account
                          and want to remove them? You can request Parent /
                          Guardian removal from your account via our ticketing
                          system.
                        </Typography.Text>
                      )}
                    </Col>
                  </Row>
                </Collapse.Panel>
              </Collapse>
            </Col>

            {/* <Col xs={24} sm={24} md={6}>
							<Button
								htmlType="submit"
								className="btn-primary-default invert"
								size="large"
								block
								icon={<FontAwesomeIcon icon={faSave} className="m-r-xs" />}
								loading={isLoadingUpdateAthleteUser}
							>
								Save
							</Button>
						</Col> */}
          </Row>
        </Form>
        <br />
      </Card>

      <Modal
        visible={modalChangePasswordVisible}
        title="CHANGE PASSWORD"
        centered
        onCancel={() => setModalChangePasswordVisible(false)}
        footer={
          <>
            <Button
              type="primary"
              htmlType="submit"
              loading={isLoadingUpdateUserPassword}
              className="btn-main-outline-2"
              size="large"
              style={{ width: "33%" }}
              onClick={() => modalFormChangePassword.submit()}
            >
              UPDATE
            </Button>
          </>
        }
      >
        <Form
          form={modalFormChangePassword}
          onFinish={handleOnFinishChangePassword}
          autoComplete="off"
        >
          <Form.Item
            name="password_1"
            rules={[
              {
                required: true,
                message: "Please input your password!",
              },
              {
                pattern:
                  /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,64}$/,
                message: "Invalid Password",
              },
            ]}
            hasFeedback
          >
            <FloatInputPasswordStrength
              label="New Password"
              placeholder="New Password"
            />
          </Form.Item>

          <Form.Item
            name="password_2"
            hasFeedback
            dependencies={["password_1"]}
            rules={[
              {
                required: true,
                message: "Please confirm your password!",
              },
              {
                pattern:
                  /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,64}$/,
                message: "Invalid Password",
              },

              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("password_1") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error(
                      "The two passwords that you entered do not match!"
                    )
                  );
                },
              }),
            ]}
          >
            <FloatInputPasswordStrength
              label="Confirm New Password"
              placeholder="Confirm New Password"
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}
