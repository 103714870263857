import {
  Button,
  Card,
  Col,
  notification,
  Popconfirm,
  Row,
  Space,
  Table,
} from "antd";
import React, { useState, useEffect } from "react";
import { PlusOutlined } from "@ant-design/icons";
import { faBaseball } from "@fortawesome/pro-regular-svg-icons";
// import { useHistory } from "react-router-dom";
// import $ from "jquery";

import ComponentHeader from "../../Components/ComponentHeader";
import {
  TableGlobalSearch,
  TablePageSize,
  TablePagination,
  TableShowingEntries,
} from "../../Components/ComponentTableFilter";
import { DELETE, GET, POST } from "../../../../providers/useAxiosQuery";
// import companyInfo from "../../../../providers/companyInfo";
import ModalSport from "./ModalSport";

export default function PageSport({ match, permission }) {
  // const history = useHistory();
  // const userdata = companyInfo().userData;
  const [tableFilter, setTableFilter] = useState({
    page: 1,
    page_size: 50,
    search: "",
    sort_field: "sport",
    sort_order: "desc",
    total: 0,
  });

  const [tableTotal, setTableTotal] = useState(0);
  const [dataSource, setDataSource] = useState([]);
  // const { refetch: refetchTable } = GET(
  //   `api/v1/athlete_assessment?${$.param(tableFilter)}`,
  //   "athlete_assessment",
  //   (res) => {
  //     if (res.success) {
  //       console.log("dataTable", res);
  //       setDataSource(res.data && res.data.data);
  //       setTableTotal(res.data.total);
  //     }
  //   }
  // );
  const {
    refetch: refetchTable,
    isLoading: isLoadingTable,
    isFetching: isFetchingTable,
  } = GET(
    `api/v1/sport?${new URLSearchParams(tableFilter)}`,
    "sport_create_update",
    (res) => {
      if (res.data) {
        console.log("res", res);
        setDataSource(res.data && res.data.data);
        setTableTotal(res.data.total);
      }
    }
  );

  const handleTableChange = (pagination, filters, sorter) => {
    setTableFilter({
      ...tableFilter,
      sort_field: sorter.columnKey,
      sort_order: sorter.order ? sorter.order.replace("end", "") : null,
      // page: 1,
    });
  };

  const [searchText, setSearchText] = useState("");
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setTableFilter({
        ...tableFilter,
        search: searchText,
        page: 1,
      });
    }, 1500);
    return () => {
      clearTimeout(timeoutId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText]);

  useEffect(() => {
    refetchTable();
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableFilter]);

  const [toggleModalForm, setToggleModalForm] = useState({
    show: false,
    data: null,
  });

  const { mutate: mutateCreateSport, isLoading: isLoadingCreateSport } = POST(
    "api/v1/sport",
    "sport_create_update"
  );

  const handleOnFinish = (values) => {
    let id =
      toggleModalForm && toggleModalForm.data ? toggleModalForm.data.id : "";

    let data = {
      ...values,
      id,
    };

    mutateCreateSport(data, {
      onSuccess: (res) => {
        if (res.success) {
          notification.success({
            message: "Success",
            description:
              "Data Successfully " + (id === "" ? "Submitted" : "Updated"),
          });

          setToggleModalForm({ show: false, data: null });
        } else {
          notification.warning({
            message: "Warning",
            description: "This sport is already been added!",
          });
        }
      },
    });
  };

  const handleDelete = (id) => {
    mutateDeleteSport(
      { id: id },
      {
        onSuccess: (res) => {
          if (res.success) {
            notification.success({
              message: "Success",
              description: "Successfully deleted!",
            });
          }
        },
      }
    );
  };

  const { mutate: mutateDeleteSport, isLoading: isLoadingDeleteSport } = DELETE(
    "api/v1/sport",
    "sport_create_update"
  );

  return (
    <>
      <ComponentHeader title={permission} sub_title="VIEW" icon={faBaseball} />

      <Card className="card-min-height" id="PageSport">
        <Row gutter={12}>
          <Col span={24} className="m-b-md m-t-sm">
            <Button
              className="btn-main-outline-2"
              icon={<PlusOutlined />}
              onClick={(e) => {
                setToggleModalForm({ ...toggleModalForm, show: true });
              }}
            >
              Add Sport
            </Button>
          </Col>
          <Col
            xs={24}
            sm={24}
            md={16}
            lg={16}
            xl={18}
            className="m-t-sm  m-b-sm"
          >
            <Space>
              <TablePageSize
                paginationFilter={tableFilter}
                setPaginationFilter={setTableFilter}
              />
            </Space>
          </Col>
          <Col xs={24} sm={24} md={8} lg={8} xl={6}>
            <TableGlobalSearch
              paginationFilter={searchText}
              setPaginationFilter={setSearchText}
            />
          </Col>
        </Row>
        <Row gutter={12}>
          <Col xs={24} sm={24} md={24} className="m-t-sm m-b-sm">
            <Table
              className="ant-table-default ant-table-striped"
              dataSource={dataSource && dataSource}
              loading={isLoadingTable || isFetchingTable}
              rowKey={(record) => record.id}
              pagination={false}
              bordered={false}
              onChange={handleTableChange}
              scroll={{ x: "max-content" }}
            >
              <Table.Column
                title="Sport"
                key="sport"
                dataIndex="sport"
                defaultSortOrder="descend"
                sorter={true}
                width="250px"
              />
              <Table.Column
                title="Action"
                key="action"
                dataIndex="action"
                align="center"
                sorter={false}
                width="100px"
                render={(_, record) => {
                  return (
                    <Space>
                      <Button
                        // loading={isLoadingCreateSport}
                        className="btn-main-outline-7"
                        size="small"
                        onClick={() =>
                          setToggleModalForm({ data: record, show: true })
                        }
                      >
                        Edit
                      </Button>

                      <Popconfirm
                        placement="top"
                        title="Are you sure you want to delete this data?"
                        onConfirm={() => handleDelete(record.id)}
                        okText="Yes"
                        cancelText="No"
                      >
                        <Button
                          loading={isLoadingDeleteSport}
                          className="btn-main-outline-9"
                          size="small"
                        >
                          Delete
                        </Button>
                      </Popconfirm>
                    </Space>
                  );
                }}
              />
            </Table>
          </Col>
          <Col xs={24} sm={24} md={24}>
            <Space className="ant-space-flex-space-between">
              <TableShowingEntries />

              <TablePagination
                paginationFilter={tableFilter}
                setPaginationFilter={setTableFilter}
                setPaginationTotal={tableTotal}
                showLessItems={true}
                showSizeChanger={false}
              />
            </Space>
          </Col>
          <Col xs={24} sm={24} md={12}></Col>
        </Row>
        <br />
      </Card>

      <ModalSport
        setToggleModalForm={setToggleModalForm}
        toggleModalForm={toggleModalForm}
        handleOnFinish={handleOnFinish}
        isLoadingCreateSport={isLoadingCreateSport}
      />
    </>
  );
}
