import { useEffect, useState } from "react";
import {
  Alert,
  Button,
  Card,
  Col,
  Form,
  Modal,
  notification,
  Row,
  Typography,
} from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { faInfo, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { GET, POST } from "../../../../../providers/useAxiosQuery";
import companyInfo from "../../../../../providers/companyInfo";
import FloatDatePicker from "../../../../../providers/FloatDatePicker";
import FloatInput from "../../../../../providers/FloatInput";
import FloatSelect from "../../../../../providers/FloatSelect";
import optionOrganizationType from "../../../../../providers/optionOrganizationType";
import FloatInputMask from "../../../../../providers/FloatInputMask";
import $ from "jquery";

import optionCountryCodes from "../../../../../providers/optionCountryCodes";
import optionStateCodesUnitedState from "../../../../../providers/optionStateCodesUnitedState";
import optionStateCodesCanada from "../../../../../providers/optionStateCodesCanada";
import FloatInputMaskNoFeedback from "../../../../../providers/FloatInputMaskNoFeedback";

const firstname = companyInfo().userData.firstname;
const userData = companyInfo().userData;
const encryptor = companyInfo().encryptor;

export default function ModalFormWelcome() {
  const [modalForm] = Form.useForm();

  const [openModal, setOpenModal] = useState(
    userData.one_time_modal === 0 ? true : false
  );

  const stateUS = optionStateCodesUnitedState();
  const stateCA = optionStateCodesCanada();
  const [optionState, setOptionState] = useState([]);
  const [stateLabel, setStateLabel] = useState("State");

  const [dataOptionOganizationType, setDataOptionOganizationType] = useState([
    [],
  ]);
  const [optionOrganization, setOptionOrganization] = useState([[]]);
  const [optionOrganizationState, setOptionOrganizationState] = useState([[]]);
  const [enableAddOrganization, setEnableAddOrganization] = useState([
    { status: 0 },
  ]);
  const [optionSport, setOptionSport] = useState([]);
  const [cityOpt, setCityOpt] = useState([]);
  // const [checkOrganizationState, setCheckOrganizationState] = useState("");

  GET(
    `api/v1/user_coach_info?id=${userData.id}`,
    "modal_user_coach_info",
    (res) => {
      if (res.data) {
        let data = res.data;

        console.log("modal_user_coach_info", data);

        modalForm.setFieldsValue({
          firstname: data.firstname,
          lastname: data.lastname,
          phone_number: data.phone_number,
          gender: data.gender,
          country:
            data.user_address && data.user_address.length > 0
              ? data.user_address[0].country
              : undefined,
          state:
            data.user_address && data.user_address.length > 0
              ? data.user_address[0].state
              : undefined,
          // state: "AL",
          city:
            data.user_address && data.user_address.length > 0
              ? data.user_address[0].city
              : undefined,
          athlete_gender:
            data.coach_infos && data.coach_infos.length > 0
              ? data.coach_infos[0].athlete_gender
              : undefined,
          instagram_acc:
            data.coach_infos && data.coach_infos.length > 0
              ? data.coach_infos[0].instagram_acc
              : undefined,
          youtube_vimeo_channel_acc:
            data.coach_infos && data.coach_infos.length > 0
              ? data.coach_infos[0].youtube_vimeo_channel_acc
              : undefined,
        });

        if (data.user_address && data.user_address.length > 0) {
          if (data.user_address[0].country === "United States") {
            setStateLabel("State");
            setOptionState(stateUS);

            let city_list = stateUS.filter(
              (item) => item.value === data.user_address[0].state
            );
            setCityOpt(city_list[0]["json"]);
          } else if (data.user_address[0].country === "Canada") {
            setStateLabel("County");
            setOptionState(stateCA);

            let city_list = stateCA.filter(
              (item) => item.value === data.user_address[0].state
            );
            setCityOpt(city_list[0]["json"]);
          }
        } else {
          setStateLabel("State");
          setOptionState(stateUS);
        }
      }
    }
  );

  GET("api/v1/organization?status=Approved", "organization_select", (res) => {
    console.log("res", res);
    if (res.data) {
      let dataOrganizationType = [];
      optionOrganizationType.map((item) => {
        dataOrganizationType.push({
          ...item,
          organization: res.data
            .filter((itemFilter) => itemFilter.organization_type === item.value)
            .map((item2) => {
              // if (item2.status === "Approved") {
              // }
              // console.log("item2", item2);
              return {
                value: item2.id,
                label: item2.name,
                state: item2.state,
              };
            }),
        });
        return "";
      });
      console.log("dataOrganizationType", dataOrganizationType);

      setDataOptionOganizationType(dataOrganizationType);
      // let data = [];
      // res.data.map((item) => {
      // 	data.push({
      // 		value: item.id,
      // 		label: item.lastname,
      // 	});
      // 	return "";
      // });
      // setOptionOrganization(data);
      console.log("userData", userData);
      modalForm.setFieldsValue({
        ...userData,
        organizations: [{ positions: [""] }],
      });
    }
  });

  GET("api/v1/sport", "sport_select", (res) => {
    if (res.data) {
      console.log("sports", res.data);
      let data = [];
      res.data.map((item) => {
        data.push({
          value: item.id,
          label: item.sport,
        });
        return "";
      });
      setOptionSport(data);
    }
  });

  // const { mutate: mutateCheckOrganization } = POST(
  // 	"api/v1/check_manager_state",
  // 	"check_organization"
  // );

  const { mutate: mutateUserCoach, isLoading: isLoadingUserCoach } = POST(
    "api/v1/modal_welcome_update_user_coach",
    "modal_welcome_update_user_coach"
  );

  const handleOnFinish = (values) => {
    console.log("handleOnFinish", values);
    console.log("optionSport", optionSport);

    let organizations = values.organizations.map((item) => {
      let sportval = optionSport.find((p) => p.value === item.sport);

      return {
        ...item,
        sport: sportval.label,
      };
    });

    let data = {
      ...values,
      id: userData.id,
      organizations,
    };

    console.log("data", data);

    mutateUserCoach(data, {
      onSuccess: (res) => {
        if (res.success) {
          localStorage.userdata = encryptor.encrypt({
            ...userData,
            one_time_modal: 1,
            firstname: values.firstname,
            lastname: values.lastname,
          });
          notification.success({
            message: "Success",
            description: "Data Successfully Submited",
          });
          setOpenModal(false);
        } else {
          notification.success({
            message: "Error",
            description: "Data not updated!",
          });
        }
      },
    });
  };

  const handleCountry = (e) => {
    if (e === "United States") {
      setOptionState(stateUS);
      setStateLabel("State");
    } else if (e === "Canada") {
      setOptionState(stateCA);
      setStateLabel("County");
    }
    setCityOpt([]);

    modalForm.resetFields(["state", "city"]);
  };

  const [orgSettings, setOrgSettings] = useState([
    {
      registered: true,
      state_label: "State",
      state: [],
      zip_label: "Zip Code",
      zip: /(^\d{5}$)|(^\d{5}[ -]\d{4}$)/,
      city: [],
    },
  ]);

  const handleChangeOrgReg = (index, type) => {
    let row = [...orgSettings];
    row[index] = {
      ...row[index],
      registered: type,
    };
    setOrgSettings(row);

    let tempOrganization = modalForm.getFieldsValue().organizations;
    tempOrganization[index] = {
      ...tempOrganization[index],
      organization_id: undefined,
    };

    modalForm.setFieldsValue({
      organizations: tempOrganization,
    });
  };

  const handleChangeCountryOrg = (val, opt, index) => {
    let data = modalForm.getFieldsValue().organizations;
    if (val) {
      let rowData = [...data];
      let row = [...orgSettings];
      if (val === "United States") {
        row[index] = {
          ...row[index],
          state_label: "State",
          state: stateUS,
          zip_label: "Zip Code",
          zip: /(^\d{5}$)|(^\d{5}[ -]\d{4}$)/,
          city: [],
        };
      } else if (val === "Canada") {
        row[index] = {
          ...row[index],
          state_label: "County",
          state: stateCA,
          zip_label: "Postal Code",
          zip: /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/,
          city: [],
        };
      }
      setOrgSettings(row);

      rowData[index] = {
        ...rowData[index],
        state: null,
        city: null,
        zip: null,
      };

      console.log("handleChangeCountryOrg", rowData);
      // modalForm.setFieldsValue({
      //   organizations: rowData,
      // });

      modalForm.setFieldsValue({
        organizations: rowData,
      });

      // $(`.state-feedback-${index} .ant-form-item-feedback-icon`).hide();
      // $(`.city-feedback-${index} .ant-form-item-feedback-icon`).hide();
      // $(`.zip-feedback-${index} .ant-form-item-feedback-icon`).hide();
    } else {
      // modalForm.resetFields(["state", "city", "zip"]);
    }
  };

  const handleCheckOrgCoach = (val, opt, index) => {
    let form = modalForm.getFieldsValue().organizations;
    let row = [...form];

    if (val) {
      let check_org = form.filter((item, key) => {
        if (item.organization_id) {
          if (item.organization_id === val && index !== key) {
            return item;
          }
        }
      });
      if (check_org.length > 0) {
        notification.warning({
          message: "Warning",
          description: "You already selected this organization.",
        });

        row[index] = {
          ...row[index],
          organization_id: null,
        };

        modalForm.setFieldsValue({
          organizations: row,
        });
      } else {
        mutateCheck(
          { organization_id: val, user_id: userData.id },
          {
            onSuccess: (res) => {
              if (res.success) {
                notification.warning({
                  message: "Warning",
                  description: "You already selected this organization.",
                });

                row[index] = {
                  ...row[index],
                  organization_id: undefined,
                };

                modalForm.setFieldsValue({
                  organizations: row,
                });
              }
            },
          }
        );
      }
    }
  };

  const { mutate: mutateCheck } = POST(
    "api/v1/check_organization_coach",
    "check_organization_coach"
  );

  useEffect(() => {
    console.log("orgSettings", orgSettings);
    console.log("enableAddOrganization", enableAddOrganization);
    console.log("optionOrganizationState", optionOrganizationState);
  }, [orgSettings]);

  return (
    <Modal
      // visible={true}
      width={1000}
      visible={openModal}
      title={
        <Alert
          style={{ marginTop: 10 }}
          message={`Welcome ${firstname}`}
          description="To continue you must complete all the required fields below"
          type="success"
          closable={false}
        />
      }
      style={{ top: 40 }}
      className="modal-dashboard-welcome"
      closable={false}
      footer={
        <Row
          gutter={24}
          style={{
            marginLeft: "-5px",
            marginRight: "-5px",
          }}
        >
          <Col xs={24} sm={24} md={10}>
            <Button
              type="primary"
              htmlType="submit"
              loading={isLoadingUserCoach}
              className="btn-main-outline-2"
              size="large"
              block
              onClick={() =>
                modalForm
                  .validateFields()
                  .then((values) => {
                    handleOnFinish(values);
                  })
                  .catch((info) => {
                    console.log("Validate Failed:", info);
                  })
              }
            >
              SUBMIT
            </Button>
          </Col>
        </Row>
      }
    >
      <Form form={modalForm} onFinish={handleOnFinish} autoComplete="off">
        <Row gutter={12}>
          <Col xs={24} sm={24} md={16}>
            <Card
              title="COACH INFORMATION"
              className="card-dark-head"
              extra={<FontAwesomeIcon icon={faInfo} />}
            >
              <Row gutter={12}>
                <Col xs={24} sm={24} md={12}>
                  <Form.Item
                    name="firstname"
                    rules={[
                      {
                        required: true,
                        message: "This field is required.",
                      },
                    ]}
                  >
                    <FloatInput
                      label="First Name"
                      placeholder="First Name"
                      required
                    />
                  </Form.Item>
                </Col>

                <Col xs={24} sm={24} md={12}>
                  <Form.Item
                    name="lastname"
                    rules={[
                      {
                        required: true,
                        message: "This field is required.",
                      },
                    ]}
                  >
                    <FloatInput
                      label="Last Name"
                      placeholder="Last Name"
                      required
                    />
                  </Form.Item>
                </Col>

                <Col xs={24} sm={24} md={12}>
                  <Form.Item
                    name={"country"}
                    rules={[
                      {
                        required: true,
                        message: "This field is required.",
                      },
                    ]}
                    className="form-select-error"
                  >
                    <FloatSelect
                      label="Country"
                      placeholder="Country"
                      options={optionCountryCodes}
                      dropdownClassName="select-dropdown-in-modal"
                      onChange={handleCountry}
                      required
                    />
                  </Form.Item>
                </Col>

                <Col xs={24} sm={24} md={12}>
                  <Form.Item
                    name="state"
                    rules={[
                      {
                        required: true,
                        message: "This field is required.",
                      },
                    ]}
                    className="form-select-error"
                  >
                    <FloatSelect
                      label={stateLabel}
                      placeholder={stateLabel}
                      options={optionState}
                      onChange={(val, opt) => {
                        if (val) {
                          setCityOpt(opt["data-json"]);
                        }
                      }}
                      required
                    />
                  </Form.Item>
                </Col>

                <Col xs={24} sm={24} md={12}>
                  <Form.Item
                    name="city"
                    rules={[
                      {
                        required: true,
                        message: "This field is required.",
                      },
                    ]}
                  >
                    <FloatSelect
                      label="City"
                      placeholder="City"
                      options={cityOpt}
                      required
                    />
                  </Form.Item>
                </Col>

                <Col xs={24} sm={24} md={12}>
                  <Form.Item
                    name="phone_number"
                    rules={[
                      {
                        pattern:
                          /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/,
                        message: "Invalid Phone Number",
                      },
                    ]}
                  >
                    <FloatInputMaskNoFeedback
                      label="Phone Number"
                      placeholder="Phone Number"
                      maskLabel="phone_number"
                      maskType="999-999-9999"
                    />
                  </Form.Item>
                </Col>

                <Col xs={24} sm={24} md={12}>
                  <Form.Item
                    name="athlete_gender"
                    rules={[
                      {
                        required: true,
                        message: "This field is required.",
                      },
                    ]}
                    className="form-select-error"
                  >
                    <FloatSelect
                      label="Sex"
                      placeholder="Sex"
                      options={[
                        { label: "Male", value: "Male" },
                        { label: "Female", value: "Female" },
                      ]}
                      dropdownClassName="select-dropdown-in-modal"
                      required
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Card>

            <Form.List name="organizations">
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name, ...restField }, index) => (
                    <Card
                      title="SCHOOL / CLUB / UNIVERSITY"
                      className="card-dark-head m-t-md"
                      extra={
                        fields.length > 1 ? (
                          <FontAwesomeIcon
                            icon={faTrashAlt}
                            onClick={() => {
                              remove(name);
                              let tempOptionOrganization = [
                                ...optionOrganization,
                              ];
                              tempOptionOrganization.splice(index, 1);
                              setOptionOrganization(tempOptionOrganization);
                              let tempEnableAddOrganization = [
                                ...enableAddOrganization,
                              ];
                              tempEnableAddOrganization.splice(index, 1);
                              setEnableAddOrganization(
                                tempEnableAddOrganization
                              );

                              let tempOrgSett = [...orgSettings];
                              tempOrgSett.splice(index, 1);
                              setOrgSettings(tempOrgSett);
                            }}
                            className="cursor companyColor3"
                          />
                        ) : null
                      }
                      key={key}
                    >
                      <Row gutter={12}>
                        <Col xs={24} sm={24} md={24}>
                          <Form.Item
                            {...restField}
                            name={[name, "organization_type"]}
                            className="form-select-error"
                            rules={[
                              {
                                required: true,
                                message: "This field field is required.",
                              },
                            ]}
                          >
                            <FloatSelect
                              label="Organization Type"
                              placeholder="Organization Type"
                              options={optionOrganizationType}
                              onChange={(e) => {
                                let tempState =
                                  modalForm.getFieldsValue().state;

                                let organizations =
                                  dataOptionOganizationType.filter(
                                    (item) => item.value === e
                                  );

                                if (organizations.length > 0) {
                                  let tempOptionOrganization = [
                                    ...optionOrganization,
                                  ];

                                  tempOptionOrganization[index] =
                                    organizations[0].organization.filter(
                                      (item) => item.state === tempState
                                    );

                                  setOptionOrganization(tempOptionOrganization);
                                }

                                let tempOrganization =
                                  modalForm.getFieldsValue().organizations;
                                tempOrganization[index] = {
                                  ...tempOrganization[index],
                                  organization_id: undefined,
                                };

                                modalForm.setFieldsValue({
                                  organizations: tempOrganization,
                                });
                              }}
                              required
                            />
                          </Form.Item>
                        </Col>

                        <Col xs={24} sm={24} md={24}>
                          {orgSettings[index].registered ? (
                            <Form.Item
                              {...restField}
                              name={[name, "organization_id"]}
                              className={`form-select-error feedback-id-${name}`}
                              rules={[
                                {
                                  required: true,
                                  message: "This field is required.",
                                },
                              ]}
                            >
                              <FloatSelect
                                label="Organization Name"
                                placeholder="Organization Name"
                                options={
                                  optionOrganization &&
                                  optionOrganization.length > 0
                                    ? optionOrganization[index]
                                    : []
                                }
                                onChange={(val, opt) => {
                                  if (val) {
                                    handleCheckOrgCoach(val, opt, index);
                                  }
                                }}
                                required
                              />
                            </Form.Item>
                          ) : (
                            <Form.Item
                              {...restField}
                              className={`feedback-name-${name}`}
                              name={[name, "organization_name"]}
                              rules={[
                                {
                                  required: true,
                                  message: "This field is required.",
                                },
                              ]}
                            >
                              <FloatInput
                                label="Organization Name"
                                placeholder="Organization Name"
                                required
                              />
                            </Form.Item>
                          )}
                        </Col>

                        <Col xs={24} sm={24} md={24} className="m-t-n-sm">
                          {orgSettings[index].registered ? (
                            <Typography.Text>
                              If organization not listed,{" "}
                              <Button
                                type="link"
                                className="companyColor3 p-l-none"
                                onClick={(e) => {
                                  handleChangeOrgReg(name, false);
                                }}
                              >
                                click here.
                              </Button>
                            </Typography.Text>
                          ) : (
                            <Typography.Text>
                              If organization listed,{" "}
                              <Button
                                type="link"
                                className="companyColor3 p-l-none"
                                onClick={(e) => {
                                  handleChangeOrgReg(name, true);
                                }}
                              >
                                click here.
                              </Button>
                            </Typography.Text>
                          )}
                        </Col>

                        {orgSettings[index].registered === false && (
                          <>
                            <Col xs={24} sm={24} md={24}>
                              <Form.Item
                                {...restField}
                                name={[name, "country"]}
                                className="form-select-error"
                                rules={[
                                  {
                                    required: true,
                                    message: "This field is required.",
                                  },
                                ]}
                              >
                                <FloatSelect
                                  label="Country"
                                  placeholder="Country"
                                  options={optionCountryCodes}
                                  onChange={(val, opt) =>
                                    handleChangeCountryOrg(val, opt, index)
                                  }
                                  required
                                />
                              </Form.Item>
                            </Col>

                            <Col xs={24} sm={24} md={24}>
                              <Form.Item
                                {...restField}
                                name={[name, "state"]}
                                className={`form-select-error state-feedback-${index}`}
                                rules={[
                                  {
                                    required: true,
                                    message: "This field is required.",
                                  },
                                ]}
                              >
                                <FloatSelect
                                  label={orgSettings[index].state_label}
                                  placeholder={orgSettings[index].state_label}
                                  options={orgSettings[index].state}
                                  onChange={(val, opt) => {
                                    if (val) {
                                      $(
                                        `.state-feedback-${index} .ant-form-item-feedback-icon`
                                      ).show();

                                      // console.log(opt["data-json"]);

                                      let row = [...orgSettings];
                                      row[index] = {
                                        ...row[index],
                                        city: opt["data-json"],
                                      };
                                      setOrgSettings(row);
                                    }
                                  }}
                                  required
                                />
                              </Form.Item>
                            </Col>

                            <Col xs={24} sm={24} md={24}>
                              <Form.Item
                                {...restField}
                                name={[name, "address1"]}
                                rules={[
                                  {
                                    required: true,
                                    message: "This field is required.",
                                  },
                                ]}
                              >
                                <FloatInput
                                  label="Address 1"
                                  placeholder="Address 1"
                                  required
                                />
                              </Form.Item>
                            </Col>

                            <Col xs={24} sm={24} md={24}>
                              <Form.Item
                                {...restField}
                                name={[name, "address2"]}
                              >
                                <FloatInput
                                  label="Address 2"
                                  placeholder="Address 2"
                                  options={optionSport}
                                />
                              </Form.Item>
                            </Col>

                            <Col xs={24} sm={24} md={24}>
                              <Form.Item
                                {...restField}
                                name={[name, "city"]}
                                className={`form-select-error city-feedback-${index}`}
                                rules={[
                                  {
                                    required: true,
                                    message: "This field is required.",
                                  },
                                ]}
                              >
                                <FloatSelect
                                  label="City"
                                  placeholder="City"
                                  options={orgSettings[index].city}
                                  required
                                />
                              </Form.Item>
                            </Col>

                            <Col xs={24} sm={24} md={24}>
                              <Form.Item
                                {...restField}
                                name={[name, "zip"]}
                                className={`zip-feedback-${index}`}
                                rules={[
                                  {
                                    required: true,
                                    message: "This field is required.",
                                  },
                                  {
                                    pattern: orgSettings[index].zip,
                                    message:
                                      "Invalid " + orgSettings[index].zip_label,
                                  },
                                ]}
                              >
                                <FloatInput
                                  label={orgSettings[index].zip_label}
                                  placeholder={orgSettings[index].zip_label}
                                  onChange={(e) => {
                                    $(
                                      `.zip-feedback-${index} .ant-form-item-feedback-icon`
                                    ).show();
                                  }}
                                  required
                                />
                              </Form.Item>
                            </Col>
                          </>
                        )}

                        <Col xs={24} sm={24} md={24}>
                          <Form.Item
                            {...restField}
                            name={[name, "sport"]}
                            className="form-select-error"
                            rules={[
                              {
                                required: true,
                                message: "This field is required.",
                              },
                            ]}
                          >
                            <FloatSelect
                              label="Sport"
                              placeholder="Sport"
                              options={optionSport}
                              required
                            />
                          </Form.Item>
                        </Col>

                        <Col xs={24} sm={24} md={24}>
                          <Form.Item
                            {...restField}
                            name={[name, "gender"]}
                            className="form-select-error"
                            rules={[
                              {
                                required: true,
                                message: "This field is required.",
                              },
                            ]}
                          >
                            <FloatSelect
                              label="Gender"
                              placeholder="Gender"
                              options={[
                                {
                                  label: "Male",
                                  value: "Male",
                                },
                                {
                                  label: "Female",
                                  value: "Female",
                                },
                              ]}
                              required
                            />
                          </Form.Item>
                        </Col>

                        <Col xs={24} sm={24} md={24}>
                          <Form.Item
                            {...restField}
                            name={[name, "title"]}
                            rules={[
                              {
                                required: true,
                                message: "This field is required.",
                              },
                            ]}
                          >
                            <FloatInput
                              label="Coach Title"
                              placeholder="Coach Title"
                              required
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Card>
                  ))}

                  <Form.Item>
                    <Button
                      type="link"
                      onClick={() => {
                        let tempOptionOrganization = [...optionOrganization];
                        tempOptionOrganization.push([]);
                        setOptionOrganization(tempOptionOrganization);

                        let tempEnableAddOrganization = [
                          ...enableAddOrganization,
                        ];
                        tempEnableAddOrganization.push({ status: 0 });

                        setEnableAddOrganization(tempEnableAddOrganization);

                        let tempOptionOrganizationState = [
                          ...optionOrganizationState,
                        ];
                        tempOptionOrganizationState.push(stateUS);
                        setOptionOrganizationState(tempOptionOrganizationState);

                        let arrOrg = [
                          ...orgSettings,
                          {
                            registered: true,
                            state_label: "State",
                            state: [],
                            zip_label: "Zip Code",
                            zip: /(^\d{5}$)|(^\d{5}[ -]\d{4}$)/,
                            city: [],
                          },
                        ];
                        setOrgSettings(arrOrg);

                        add();
                      }}
                      icon={<PlusOutlined />}
                      className="companyColor3 p-n"
                    >
                      Add Another Organization
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>
          </Col>

          <Col xs={24} sm={24} md={8}>
            <Card
              title="SCHOOL / TEAM SOCIAL MEDIA"
              className="card-dark-head"
              extra={<FontAwesomeIcon icon={faInfo} />}
            >
              <Row gutter={12}>
                <Col xs={24} sm={24} md={24}>
                  <Form.Item name="instagram_acc" className="m-b-sm">
                    <FloatInput
                      label="Instagram (Optional)"
                      placeholder="Instagram (Optional)"
                    />
                  </Form.Item>
                </Col>

                <Col xs={24} sm={24} md={24}>
                  <Form.Item
                    name="youtube_vimeo_channel_acc"
                    className="m-b-sm"
                  >
                    <FloatInput
                      label="Youtube (Optional)"
                      placeholder="Youtube (Optional)"
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}
