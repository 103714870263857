import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Divider,
  Form,
  Modal,
  Row,
  Space,
  Typography,
  Collapse,
  notification,
} from "antd";
import FloatInput from "../../../../providers/FloatInput";
import FloatSelect from "../../../../providers/FloatSelect";
import { POST } from "../../../../providers/useAxiosQuery";
import companyInfo from "../../../../providers/companyInfo";

const userdata = companyInfo().userData;

export default function ModalPendingAssessmentPreviewElite(props) {
  const {
    toggleModalPreview,
    setToggleModalPreview,
    refetchTable = () => {},
  } = props;
  const [modalForm] = Form.useForm();
  const { Panel } = Collapse;
  const [updateValue, setUpdateValue] = useState("");

  // useEffect(() => {
  //   console.log("data", toggleModalPreview.data);
  // }, [toggleModalPreview]);

  const onFinishForm = (values, status_value) => {
    let status_val;
    if (
      values.cmj_weight !== "" &&
      values.cmj_weight !== null &&
      values.cmj_weight !== undefined &&
      values.cmj_jump_height !== "" &&
      values.cmj_jump_height !== null &&
      values.cmj_jump_height !== undefined &&
      values.cmj_rsi_no !== "" &&
      values.cmj_rsi_no !== null &&
      values.cmj_rsi_no !== undefined &&
      values.ground_contact_time !== "" &&
      values.ground_contact_time !== null &&
      values.ground_contact_time !== undefined &&
      values.es_height !== "" &&
      values.es_height !== null &&
      values.es_height !== undefined &&
      values.es_peak_propulsive_force !== "" &&
      values.es_peak_propulsive_force !== null &&
      values.es_peak_propulsive_force !== undefined &&
      values.es_peak_power !== "" &&
      values.es_peak_power !== null &&
      values.es_peak_power !== undefined &&
      values.sj_height !== "" &&
      values.sj_height !== null &&
      values.sj_height !== undefined &&
      // values.sj_peak_propulsive_force !== "" &&
      // values.sj_peak_propulsive_force !== null &&
      // values.sj_peak_propulsive_force !== undefined &&
      values.sj_peak_power !== "" &&
      values.sj_peak_power !== null &&
      values.sj_peak_power !== undefined &&
      values.sprint_time_tem_meter !== "" &&
      values.sprint_time_tem_meter !== null &&
      values.sprint_time_tem_meter !== undefined &&
      values.sprint_time_four_meter !== "" &&
      values.sprint_time_four_meter !== null &&
      values.sprint_time_four_meter !== undefined &&
      values.ms_peak_force !== "" &&
      values.ms_peak_force !== null &&
      values.ms_peak_force !== undefined &&
      values.ms_rfd !== "" &&
      values.ms_rfd !== null &&
      values.ms_rfd !== undefined &&
      // values.ms_force !== "" &&
      // values.ms_force !== null &&
      // values.ms_force !== undefined &&
      // values.ssm_power !== "" &&
      // values.ssm_power !== null &&
      // values.ssm_power !== undefined &&
      values.spm_power !== "" &&
      values.spm_power !== null &&
      values.spm_power !== undefined &&
      values.rm_power !== "" &&
      values.rm_power !== null &&
      values.rm_power !== undefined &&
      values.se_pull_ups !== "" &&
      values.se_pull_ups !== "" &&
      values.se_pull_ups !== null &&
      values.se_chin_ups !== null &&
      values.se_chin_ups !== undefined &&
      values.se_chin_ups !== undefined &&
      values.cod_power !== "" &&
      values.cod_power !== null &&
      values.cod_power !== undefined

      // values.page_eight_first_paragraph_benchmark !== "" &&
      // values.page_eight_first_paragraph_benchmark !== null &&
      // values.page_eight_first_paragraph_benchmark !== undefined &&
      // values.page_eight_first_paragraph_epm !== "" &&
      // values.page_eight_first_paragraph_epm !== null &&
      // values.page_eight_first_paragraph_epm !== undefined &&
      // values.page_six_first_paragraph_epm !== "" &&
      // values.page_six_first_paragraph_epm !== null &&
      // values.page_six_first_paragraph_epm !== undefined &&
      // values.page_six_second_paragraph_benchmark !== "" &&
      // values.page_six_second_paragraph_benchmark !== null &&
      // values.page_six_second_paragraph_benchmark !== undefined &&
      // values.page_six_second_paragraph_epm !== "" &&
      // values.page_six_second_paragraph_epm !== null &&
      // values.page_six_second_paragraph_epm !== undefined &&
      // values.page_six_second_paragraph_type !== "" &&
      // values.page_six_second_paragraph_type !== null &&
      // values.page_six_second_paragraph_type !== undefined &&
    ) {
      status_val = "Complete";
    } else {
      status_val = "Incomplete";
    }
    let data = {
      id: toggleModalPreview.data.id,
      cmj_jump_height: values.cmj_jump_height ? values.cmj_jump_height : "",
      cmj_rsi_no: values.cmj_rsi_no ? values.cmj_rsi_no : "",
      ground_contact_time: values.ground_contact_time
        ? values.ground_contact_time
        : "",
      cmj_weight: values.cmj_weight ? values.cmj_weight : "",
      cod_power: values.cod_power ? values.cod_power : "",
      es_height: values.es_height ? values.es_height : "",
      es_peak_power: values.es_peak_power ? values.es_peak_power : "",
      es_peak_propulsive_force: values.es_peak_propulsive_force
        ? values.es_peak_propulsive_force
        : "",
      ms_peak_force: values.ms_peak_force ? values.ms_peak_force : "",
      ms_rfd: values.ms_rfd ? values.ms_rfd : "",
      // ms_force: values.ms_force ? values.ms_force : "",
      page_eight_first_paragraph_benchmark:
        values.page_eight_first_paragraph_benchmark
          ? values.page_eight_first_paragraph_benchmark
          : "",
      page_eight_first_paragraph_epm: values.page_eight_first_paragraph_epm
        ? values.page_eight_first_paragraph_epm
        : "",
      page_six_first_paragraph_epm: values.page_six_first_paragraph_epm
        ? values.page_six_first_paragraph_epm
        : "",
      page_six_second_paragraph_benchmark:
        values.page_six_second_paragraph_benchmark
          ? values.page_six_second_paragraph_benchmark
          : "",
      page_six_second_paragraph_epm: values.page_six_second_paragraph_epm
        ? values.page_six_second_paragraph_epm
        : "",
      page_six_second_paragraph_type: values.page_six_second_paragraph_type
        ? values.page_six_second_paragraph_type
        : "",
      rm_power: values.rm_power ? values.rm_power : "",
      se_pull_ups: values.se_pull_ups ? values.se_pull_ups : "",
      se_chin_ups: values.se_chin_ups ? values.se_chin_ups : "",
      sj_height: values.sj_height ? values.sj_height : "",
      sj_peak_power: values.sj_peak_power ? values.sj_peak_power : "",
      // sj_peak_propulsive_force: values.sj_peak_propulsive_force
      //   ? values.sj_peak_propulsive_force
      //   : "",
      spm_power: values.spm_power ? values.spm_power : "",
      sprint_time_four_meter: values.sprint_time_four_meter
        ? values.sprint_time_four_meter
        : "",
      sprint_time_tem_meter: values.sprint_time_tem_meter
        ? values.sprint_time_tem_meter
        : "",
      // ssm_power: values.ssm_power ? values.ssm_power : "",
      status: status_value ? status_value : status_val,
      manager_id: userdata.id,
      // manager_name: userdata.firstname + " " + userdata.lastname,
      // update_label: values.update_label ? values.update_label : "",
      update_value: values.update_value ? values.update_value : "",
    };
    console.log("onFinishForm", status_val);
    mutateApprove(data, {
      onSuccess: (res) => {
        if (res.success) {
          notification.success({
            message: "Success",
            description: status_value
              ? "Successfully Approved."
              : "Successfully Updated.",
          });
          if (status_value) {
            setToggleModalPreview({ show: false, data: null });
            modalForm.resetFields();
          }
          refetchTable();
        }
      },
    });
  };

  const { mutate: mutateApprove, isloading: isLoadingApprove } = POST(
    "api/v1/approve_assessment",
    "athlete_assessment"
  );

  useEffect(() => {
    // console.log("toggleModalPreview", toggleModalPreview);
    if (toggleModalPreview.data) {
      getData();
    }
  }, [toggleModalPreview.data]);

  const getData = (val) => {
    mutateGet(
      { id: toggleModalPreview.data.id },
      {
        onSuccess: (res) => {
          if (res.success) {
            modalForm.setFieldsValue({
              cmj_weight: res.data[0].cmj_weight,
              cmj_jump_height: res.data[0].cmj_jump_height,
              cmj_rsi_no: res.data[0].cmj_rsi_no,
              ground_contact_time: res.data[0].ground_contact_time,
              es_height: res.data[0].es_height,
              es_peak_propulsive_force: res.data[0].es_peak_propulsive_force,
              es_peak_power: res.data[0].es_peak_power,
              sj_height: res.data[0].sj_height,
              // sj_peak_propulsive_force: res.data[0].sj_peak_propulsive_force,
              sj_peak_power: res.data[0].sj_peak_power,
              sprint_time_tem_meter: res.data[0].sprint_time_tem_meter,
              sprint_time_four_meter: res.data[0].sprint_time_four_meter,
              ms_peak_force: res.data[0].ms_peak_force,
              ms_rfd: res.data[0].ms_rfd,
              // ms_force: res.data[0].ms_force,
              // ssm_power: res.data[0].ssm_power,
              spm_power: res.data[0].spm_power,
              rm_power: res.data[0].rm_power,
              se_pull_ups: res.data[0].se_pull_ups,
              se_chin_ups: res.data[0].se_chin_ups,
              cod_power: res.data[0].cod_power,

              page_six_first_paragraph_epm:
                res.data[0].page_six_first_paragraph_epm,
              page_six_second_paragraph_type:
                res.data[0].page_six_second_paragraph_type,
              page_six_second_paragraph_benchmark:
                res.data[0].page_six_second_paragraph_benchmark,
              page_six_second_paragraph_epm:
                res.data[0].page_six_second_paragraph_epm,
              page_eight_first_paragraph_benchmark:
                res.data[0].page_eight_first_paragraph_benchmark,
              page_eight_first_paragraph_epm:
                res.data[0].page_eight_first_paragraph_epm,
            });
          }
        },
      }
    );
  };

  const { mutate: mutateGet } = POST(
    "api/v1/get_modal_attendess_data",
    "assessment_events_attendeess"
  );

  const [dataForm, setDataForm] = useState("");

  const handleInput = (val) => {
    console.log("handleInputBlur", val);
    if (val) {
      setDataForm(val);
    } else {
      setDataForm("Empty");
    }
  };

  const handleInputBlur = (e, input) => {
    if (dataForm) {
      // modalForm.submit();
      modalForm
        .validateFields()
        .then((values) => {
          let newvalues = {
            ...values,
            update_value: `${input}: updated value to "${dataForm}" by ${userdata.role}: ${userdata.lastname}`,
          };
          console.log("newvalues", newvalues);
          onFinishForm(newvalues);
          setDataForm("");
        })
        .catch((info) => {
          console.log("Validate Failed:", info);
        });
    }
  };

  const validate = {
    pattern: {
      pattern: new RegExp(
        /(^[E][F]$)|(^[A][I]$)|(^[A][R][T]$)|^-?(0|[1-9][0-9]*)(\.[0-9]*)?$/
      ),
      message: "Invalid value",
    },
  };

  return (
    <Modal
      title={
        <>
          <Typography.Title level={3} className="m-b-none color-3" ellipsis>
            {`${
              toggleModalPreview.data &&
              toggleModalPreview.data.athlete?.firstname
            } ${
              toggleModalPreview.data &&
              toggleModalPreview.data.athlete?.lastname
            }`}
          </Typography.Title>

          <Space
            split={
              <Divider
                type="vertical"
                style={{ borderLeft: "1px solid #31302f" }}
              />
            }
            size={0}
          >
            {toggleModalPreview.data &&
              toggleModalPreview.data.athlete_sport && (
                <span className="color-1 text-capitalize">
                  {toggleModalPreview.data &&
                    toggleModalPreview.data.athlete_sport}
                </span>
              )}
            {toggleModalPreview.data && toggleModalPreview.data.position && (
              <span className="color-1 text-capitalize">
                {JSON.parse(toggleModalPreview.data.position).join(", ")}
              </span>
            )}
          </Space>
        </>
      }
      visible={toggleModalPreview.show}
      zIndex={3}
      width={700}
      footer={null}
      onCancel={() => setToggleModalPreview({ show: false, data: null })}
      className="modal-primary-default modal-manager-pending-assessment"
    >
      <Form form={modalForm} preserve={false}>
        <Row gutter={12}>
          <Col xs={24} sm={24} md={24}>
            <Collapse
              className="main-1-collapse"
              expandIcon={({ isActive }) =>
                isActive ? (
                  <span
                    className="ant-menu-submenu-arrow"
                    style={{ color: "#FFF", transform: "rotate(270deg)" }}
                  ></span>
                ) : (
                  <span
                    className="ant-menu-submenu-arrow"
                    style={{ color: "#FFF", transform: "rotate(90deg)" }}
                  ></span>
                )
              }
              defaultActiveKey={["1"]}
              expandIconPosition="start"
            >
              <Panel
                header="ELITE ASSESSMENT"
                key="1"
                className="accordion bg-darkgray-form"
              >
                <Row gutter={12}>
                  <Col xs={24} sm={24} md={24}>
                    <Space
                      split={<Divider type="vertical" />}
                      size={0}
                      className="w-100 assessment-space"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography.Title
                        level={5}
                        className="m-b-none fs-15 ta-center color-3"
                      >
                        EF - Equipment Failure
                      </Typography.Title>
                      <Typography.Title
                        level={5}
                        className="m-b-none fs-15 ta-center color-3"
                      >
                        AI - Athlete Injured
                      </Typography.Title>
                      <Typography.Title
                        level={5}
                        className="m-b-none fs-15 ta-center color-3"
                      >
                        ART - Athlete Refused Test
                      </Typography.Title>
                    </Space>

                    <Divider className="m-t-sm m-b-sm" />
                  </Col>

                  <Col xs={24} sm={24} md={12}>
                    <Typography.Text strong level={5}>
                      Reactive Strength <br /> (Multi-Rebound Jump)
                    </Typography.Text>

                    <Form.Item name="cmj_weight" rules={[validate.pattern]}>
                      <FloatInput
                        label="Weight (N) Calculated into lbs"
                        placeholder="Weight (N) Calculated into lbs"
                        onChange={handleInput}
                        onBlurInput={(e) =>
                          handleInputBlur(
                            e,
                            "Reactive Strength (CMJ Rebound Jump) Weight (N) Calculated into lbs"
                          )
                        }
                      />
                    </Form.Item>

                    <Form.Item
                      name="cmj_jump_height"
                      rules={[validate.pattern]}
                    >
                      <FloatInput
                        label="Peak Jump Height"
                        placeholder="Peak Jump Height"
                        onChange={handleInput}
                        onBlurInput={(e) =>
                          handleInputBlur(e, "Peak Jump Height")
                        }
                      />
                    </Form.Item>

                    <Form.Item name="cmj_rsi_no" rules={[validate.pattern]}>
                      <FloatInput
                        label="RSI Top 3 Jumps Peak RSI"
                        placeholder="RSI Top 3 Jumps Peak RSI"
                        onChange={handleInput}
                        onBlurInput={(e) =>
                          handleInputBlur(e, "RSI Top 3 Jumps Peak RSI")
                        }
                      />
                    </Form.Item>

                    <Form.Item
                      name="ground_contact_time"
                      rules={[validate.pattern]}
                    >
                      <FloatInput
                        label="Ground Contact Time"
                        placeholder="Ground Contact Time"
                        onChange={handleInput}
                        onBlurInput={(e) =>
                          handleInputBlur(
                            e,
                            "ground_contact_time",
                            "Ground Contact Time"
                          )
                        }
                      />
                    </Form.Item>
                  </Col>

                  <Col xs={24} sm={24} md={12}>
                    <Typography.Text strong level={5}>
                      Elastic Strength <br /> (Countermovement Jump)
                    </Typography.Text>

                    <Form.Item name="es_height" rules={[validate.pattern]}>
                      <FloatInput
                        label="Jump Height (In)"
                        placeholder="Jump Height (In)"
                        onChange={handleInput}
                        onBlurInput={(e) =>
                          handleInputBlur(
                            e,
                            "Elastic Strength (Countermovement Jump) - Jump Height (In)"
                          )
                        }
                      />
                    </Form.Item>

                    <Form.Item
                      name="es_peak_propulsive_force"
                      rules={[validate.pattern]}
                    >
                      <FloatInput
                        label="Peak Propulsive Force (N)"
                        placeholder="Peak Propulsive Force (N)"
                        onChange={handleInput}
                        onBlurInput={(e) =>
                          handleInputBlur(e, "Peak Propulsive Force (N)")
                        }
                      />
                    </Form.Item>

                    <Form.Item
                      name="es_peak_power"
                      className="letter-space"
                      rules={[validate.pattern]}
                    >
                      <FloatInput
                        label="Relative Average Propulsive Power (W/kg)"
                        placeholder="Relative Average Propulsive Power (W/kg)"
                        onChange={handleInput}
                        onBlurInput={(e) =>
                          handleInputBlur(
                            e,
                            "Relative Average Propulsive Power (W/kg)"
                          )
                        }
                      />
                    </Form.Item>
                  </Col>

                  <Col xs={24} sm={24} md={12}>
                    <Typography.Text strong level={5}>
                      Ballistic Strength (Squat Jump)
                    </Typography.Text>

                    <Form.Item name="sj_height" rules={[validate.pattern]}>
                      <FloatInput
                        label="Jump Height (In)"
                        placeholder="Jump Height (In)"
                        onChange={handleInput}
                        onBlurInput={(e) =>
                          handleInputBlur(
                            e,
                            "Ballistic Strength (Squat Jump) - Jump Height (In)"
                          )
                        }
                      />
                    </Form.Item>

                    {/* <Form.Item
                      name="sj_peak_propulsive_force"
                      rules={[validate.pattern]}
                    >
                      <FloatInput
                        label="Peak Propulsive Force (N)"
                        placeholder="Peak Propulsive Force (N)"
                        onChange={handleInput}
                        onBlurInput={(e) =>
                          handleInputBlur(e, "Peak Propulsive Force (N)")
                        }
                      />
                    </Form.Item> */}

                    <Form.Item name="sj_peak_power" rules={[validate.pattern]}>
                      <FloatInput
                        label="Relative Peak Propulsive Power (W/kg)"
                        placeholder="Relative Peak Propulsive Power (W/kg)"
                        onChange={handleInput}
                        onBlurInput={(e) =>
                          handleInputBlur(
                            e,
                            "Relative Peak Propulsive Power (W/kg)"
                          )
                        }
                      />
                    </Form.Item>
                  </Col>

                  <Col xs={24} sm={24} md={12}>
                    <Typography.Text strong level={5}>
                      10YD/40YD Sprint
                    </Typography.Text>

                    <Form.Item
                      name="sprint_time_tem_meter"
                      rules={[validate.pattern]}
                    >
                      <FloatInput
                        label="10YD Time (sec)"
                        placeholder="10YD Time (sec)"
                        onChange={handleInput}
                        onBlurInput={(e) =>
                          handleInputBlur(e, "10YD/40YD - 10YD Time (sec)")
                        }
                      />
                    </Form.Item>

                    <Form.Item
                      name="sprint_time_four_meter"
                      rules={[validate.pattern]}
                    >
                      <FloatInput
                        label="40YD Time (sec)"
                        placeholder="40YD Time (sec)"
                        onChange={handleInput}
                        onBlurInput={(e) =>
                          handleInputBlur(e, "10YD/40YD - 40YD Time (sec)")
                        }
                      />
                    </Form.Item>
                  </Col>

                  <Col xs={24} sm={24} md={12}>
                    <Typography.Text strong level={5}>
                      Maximal Strength <br />
                      (Isometric Mid-Thigh Pull)
                    </Typography.Text>

                    <Form.Item name="ms_peak_force" rules={[validate.pattern]}>
                      <FloatInput
                        label="Peak Force (N)"
                        placeholder="Peak Force (N)"
                        onChange={handleInput}
                        onBlurInput={(e) =>
                          handleInputBlur(
                            e,
                            "Maximal Strength Peak (Isometric Mid-Thigh Pull) - Force (N)"
                          )
                        }
                      />
                    </Form.Item>

                    <Form.Item name="ms_rfd" rules={[validate.pattern]}>
                      <FloatInput
                        label="Force at 100m/s (N)"
                        placeholder="Force at 100m/s (N)"
                        onChange={handleInput}
                        onBlurInput={(e) =>
                          handleInputBlur(e, "Force at 100m/s (N)")
                        }
                      />
                    </Form.Item>

                    {/* <Form.Item rules={[validate.pattern]} name="ms_force">
                      <FloatInput
                        label="0-100ms RFD % of Peak Force"
                        placeholder="0-100ms RFD % of Peak Force"
                        onChange={handleInput}
                        onBlurInput={(e) =>
                          handleInputBlur(
                            e,
                            "ms_force",
                            "0-100ms RFD % of Peak Force"
                          )
                        }
                      />
                    </Form.Item> */}
                  </Col>

                  {/* <Col xs={24} sm={24} md={12}>
                    <Typography.Text strong level={5}>
                      UE <br />
                      (SSM Ball Throw)
                    </Typography.Text>

                    <Form.Item name="ssm_power" rules={[validate.pattern]}>
                      <FloatInput
                        label="Power (W)"
                        placeholder="Power (W)"
                        onChange={handleInput}
                        onBlurInput={(e) =>
                          handleInputBlur(e, "UE (SSM Ball Throw) - Power (W)")
                        }
                      />
                    </Form.Item>
                  </Col> */}

                  <Col xs={24} sm={24} md={12}>
                    <Typography.Text strong level={5}>
                      UE <br />
                      (Plyometric Chest Throw)
                    </Typography.Text>

                    <Form.Item name="spm_power" rules={[validate.pattern]}>
                      <FloatInput
                        label="Peak Propulsive Power (W)"
                        placeholder="Peak Propulsive Power (W)"
                        onChange={handleInput}
                        onBlurInput={(e) =>
                          handleInputBlur(
                            e,
                            "UE (Plyometric Chest Throw) - Peak Propulsive Power (W)"
                          )
                        }
                      />
                    </Form.Item>
                  </Col>

                  <Col xs={24} sm={24} md={12}>
                    <Typography.Text strong level={5}>
                      MB <br />
                      Rotational Throw
                    </Typography.Text>

                    <Form.Item name="rm_power" rules={[validate.pattern]}>
                      <FloatInput
                        label="Peak Propulsive Power (W)"
                        placeholder="Peak Propulsive Power (W)"
                        onChange={handleInput}
                        onBlurInput={(e) =>
                          handleInputBlur(
                            e,
                            "MB (Rotational Throw) - Peak Propulsive Power (W)"
                          )
                        }
                      />
                    </Form.Item>
                  </Col>

                  <Col xs={24} sm={24} md={12}>
                    <Typography.Text strong level={5}>
                      Strength Endurance <br /> (Push Ups)
                    </Typography.Text>

                    <Form.Item name="se_pull_ups" rules={[validate.pattern]}>
                      <FloatInput
                        label="Push-ups (#)"
                        placeholder="Push-ups (#)"
                        onChange={handleInput}
                        onBlurInput={(e) =>
                          handleInputBlur(
                            e,
                            "Strength Endurance - Push-ups (#)"
                          )
                        }
                      />
                    </Form.Item>
                  </Col>

                  <Col xs={24} sm={24} md={12}>
                    <Typography.Text strong level={5}>
                      Strength Endurance <br /> (Chin Ups)
                    </Typography.Text>

                    <Form.Item name="se_chin_ups" rules={[validate.pattern]}>
                      <FloatInput
                        label="Chin Ups (#)"
                        placeholder="Chin Ups (#)"
                        onChange={handleInput}
                        onBlurInput={(e) =>
                          handleInputBlur(
                            e,
                            "Strength Endurance - Chin Ups (#)"
                          )
                        }
                      />
                    </Form.Item>
                  </Col>

                  <Col xs={24} sm={24} md={12}>
                    <Typography.Text strong level={5}>
                      Change of Direction <br />
                      (5-10-5 Pro Agility Test)
                    </Typography.Text>

                    <Form.Item name="cod_power" rules={[validate.pattern]}>
                      <FloatInput
                        label="Time (secs)"
                        placeholder="Time (secs)"
                        onChange={handleInput}
                        onBlurInput={(e) =>
                          handleInputBlur(
                            e,
                            "Change of Direction (5-10-5 Pro Agility Test) - Time (secs)"
                          )
                        }
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Panel>
            </Collapse>
            <br />

            {/* <Collapse
              className="main-1-collapse"
              expandIcon={({ isActive }) =>
                isActive ? (
                  <span
                    className="ant-menu-submenu-arrow"
                    style={{ color: "#FFF", transform: "rotate(270deg)" }}
                  ></span>
                ) : (
                  <span
                    className="ant-menu-submenu-arrow"
                    style={{ color: "#FFF", transform: "rotate(90deg)" }}
                  ></span>
                )
              }
              defaultActiveKey={["1"]}
              expandIconPosition="start"
            >
              <Panel
                header="PDF FIELDS"
                key="1"
                className="accordion bg-darkgray-form"
              >
                <Row gutter={12}>
                  <Col xs={24} sm={24} md={12}>
                    <Typography.Text
                      strong
                      level={3}
                      className="companycolor1 c-21572c"
                    >
                      PAGE 6
                    </Typography.Text>

                    <br />

                    <Typography.Text strong level={5}>
                      1st Paragraph
                    </Typography.Text>

                    <Form.Item name="page_six_first_paragraph_epm">
                      <FloatInput
                        label="Excellent Performers Measure (In)"
                        placeholder="Excellent Performers Measure (In)"
                        onBlurInput={handleInputBlur}
                      />
                    </Form.Item>
                  </Col>

                  <Col xs={24} sm={24} md={24}>
                    <Row gutter={12}>
                      <Col xs={24} sm={24} md={24}>
                        <Typography.Text strong level={5}>
                          2nd Paragraph
                        </Typography.Text>
                      </Col>
                      <Col xs={24} sm={24} md={12}>
                        <Form.Item
                          name="page_six_second_paragraph_type"
                          className="m-b-sm form-select-error"
                        >
                          <FloatSelect
                            label="Type"
                            placeholder="Type"
                            options={[
                              {
                                label: "Low",
                                value: "Low",
                              },
                              {
                                label: "High",
                                value: "High",
                              },
                            ]}
                            onBlurInput={handleInputBlur}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={12}>
                        <Form.Item name="page_six_second_paragraph_benchmark">
                          <FloatInput
                            label="Benchmark %"
                            placeholder="Benchmark %"
                            onBlurInput={handleInputBlur}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={12}>
                        <Form.Item name="page_six_second_paragraph_epm">
                          <FloatInput
                            label="Excellent Performers Measure (In)"
                            placeholder="Excellent Performers Measure (In)"
                            onBlurInput={handleInputBlur}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>

                  <Col xs={24} sm={24} md={24}>
                    <Row gutter={12}>
                      <Col xs={24} sm={24} md={24}>
                        <Typography.Text
                          strong
                          level={3}
                          className="companycolor1 c-21572c"
                        >
                          PAGE 8
                        </Typography.Text>

                        <br />

                        <Typography.Text strong level={5}>
                          1st Paragraph
                        </Typography.Text>
                      </Col>
                      <Col xs={24} sm={24} md={12}>
                        <Form.Item name="page_eight_first_paragraph_benchmark">
                          <FloatInput
                            label="Benchmark %"
                            placeholder="Benchmark %"
                            onBlurInput={handleInputBlur}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={12}>
                        <Form.Item name="page_eight_first_paragraph_epm">
                          <FloatInput
                            label="Excellent Performers Measure (In)"
                            placeholder="Excellent Performers Measure (In)"
                            onBlurInput={handleInputBlur}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Panel>
            </Collapse>
            <br /> */}
          </Col>

          <Col xs={24} sm={24} md={24}>
            <Button
              type="primary"
              //   htmlType="submit"
              // loading={isLoadingButtonLogin}
              className="btn-main-outline-2"
              size="large"
              isloading={isLoadingApprove}
              onClick={(e) => {
                modalForm
                  .validateFields()
                  .then((values) => {
                    let newvalues = {
                      ...values,
                      update_value: `Successfully Approved by ${userdata.role}: ${userdata.lastname}`,
                    };
                    console.log("newvalues", newvalues);
                    onFinishForm(newvalues, "Approved");
                  })
                  .catch((info) => {
                    console.log("Validate Failed:", info);
                  });
              }}
            >
              {userdata.role === "Admin" ? "ADMIN" : "MANAGER"} APPROVED
            </Button>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}
