import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PageHeader, Space, Typography } from "antd";

const ComponentHeader = ({ title, sub_title, icon }) => {
	return (
		<PageHeader
			title={
				<Space>
					<span className="anticon">
						<FontAwesomeIcon icon={icon} />
					</span>
					<span className="page-header-title">
						<Typography.Text>{sub_title}</Typography.Text>
						<Typography.Title level={2}>{title}</Typography.Title>
					</span>
				</Space>
			}
		/>
	);
};

export default ComponentHeader;
