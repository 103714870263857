import { Button, Card, Col, notification, Row, Space, Table } from "antd";
import React, { useState, useEffect } from "react";
import { PlusOutlined } from "@ant-design/icons";
import { faSchool } from "@fortawesome/pro-regular-svg-icons";
// import { useHistory } from "react-router-dom";
// import $ from "jquery";

import ComponentHeader from "../../Components/ComponentHeader";
import {
  TableGlobalSearch,
  TablePageSize,
  TablePagination,
  TableShowingEntries,
} from "../../Components/ComponentTableFilter";
import { GET, POST } from "../../../../providers/useAxiosQuery";
// import companyInfo from "../../../../providers/companyInfo";
import ModalSchool from "./ModalSchool";

export default function PageSchool({ match, permission }) {
  // const history = useHistory();
  // const userdata = companyInfo().userData;
  const [tableFilter, setTableFilter] = useState({
    page: 1,
    page_size: 10,
    search: "",
    sort_field: "school",
    sort_order: "desc",
    total: 0,
  });

  const [tableTotal, setTableTotal] = useState(0);
  const [dataSource, setDataSource] = useState([]);
  // const { refetch: refetchTable } = GET(
  //   `api/v1/athlete_assessment?${$.param(tableFilter)}`,
  //   "athlete_assessment",
  //   (res) => {
  //     if (res.success) {
  //       console.log("dataTable", res);
  //       setDataSource(res.data && res.data.data);
  //       setTableTotal(res.data.total);
  //     }
  //   }
  // );
  const {
    isLoading: isLoadingTable,
    isFetching: isFetchingTable,
    refetch: refetchTable,
  } = GET(
    `api/v1/school?${new URLSearchParams(tableFilter)}`,
    "school_list",
    (res) => {
      if (res.data) {
        console.log("res", res);
        setDataSource(res.data && res.data.data);
        setTableTotal(res.data.total);
      }
    }
  );

  const handleTableChange = (pagination, filters, sorter) => {
    setTableFilter({
      ...tableFilter,
      sort_field: sorter.columnKey,
      sort_order: sorter.order ? sorter.order.replace("end", "") : null,
      page: 1,
    });
  };

  const [searchText, setSearchText] = useState("");
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setTableFilter({
        ...tableFilter,
        search: searchText,
        page: 1,
      });
    }, 1500);
    return () => {
      clearTimeout(timeoutId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText]);

  useEffect(() => {
    refetchTable();
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableFilter]);

  const [toggleModalForm, setToggleModalForm] = useState({
    show: false,
    data: null,
  });

  const { mutate: mutateCreateSchool, isLoading: isLoadingCreateSchool } = POST(
    "api/v1/school",
    "school_list"
  );

  const handleOnFinish = (values) => {
    console.log("handleOnFinish", values);

    let id =
      toggleModalForm && toggleModalForm.data ? toggleModalForm.data.id : "";

    let data = {
      ...values,
      id,
    };
    console.log("handleOnFinish data", data);

    mutateCreateSchool(data, {
      onSuccess: (res) => {
        if (res.success) {
          notification.success({
            message: "Success",
            description:
              "Data Successfully " + (id === "" ? "Submitted" : "Updated"),
          });

          setToggleModalForm({ show: false, data: null });
        } else {
          notification.success({
            message: "Error",
            description: "Data not " + (id === "" ? "Saved!" : "Updated!"),
          });
        }
      },
    });
  };

  return (
    <>
      <ComponentHeader title={permission} sub_title="VIEW" icon={faSchool} />

      <Card className="card-min-height" id="PageSchool">
        <Row gutter={12}>
          <Col span={24} className="m-b-md">
            <Button
              className="btn-main-outline-2"
              icon={<PlusOutlined />}
              onClick={(e) => {
                setToggleModalForm({ ...toggleModalForm, show: true });
              }}
            >
              Add School
            </Button>
          </Col>
          <Col xs={24} sm={24} md={18} className="m-b-sm">
            <Space>
              <TablePageSize
                paginationFilter={tableFilter}
                setPaginationFilter={setTableFilter}
              />
            </Space>
          </Col>
          <Col xs={24} sm={24} md={6}>
            <TableGlobalSearch
              paginationFilter={searchText}
              setPaginationFilter={setSearchText}
            />
          </Col>
        </Row>
        <Row gutter={12}>
          <Col xs={24} sm={24} md={24} className="m-t-sm m-b-sm">
            <Table
              className="ant-table-default ant-table-striped"
              dataSource={dataSource && dataSource}
              rowKey={(record) => record.id}
              loading={isLoadingTable || isFetchingTable}
              pagination={false}
              bordered={false}
              onChange={handleTableChange}
              scroll={{ x: "max-content" }}
            >
              <Table.Column
                title="School"
                key="school"
                dataIndex="school"
                sorter={true}
                width="250px"
              />
              <Table.Column
                title="Action"
                key="action"
                dataIndex="action"
                align="center"
                sorter={false}
                width="100px"
                render={(_, record) => {
                  return (
                    <Space>
                      <Button
                        // loading={isLoadingCreateSport}
                        className="btn-main-outline-7"
                        size="small"
                        onClick={() =>
                          setToggleModalForm({ data: record, show: true })
                        }
                      >
                        Edit
                      </Button>

                      {/* <Popconfirm
                        placement="top"
                        title="Are you sure you want to delete this data?"
                        onConfirm={() => handleDelete(record)}
                        okText="Yes"
                        cancelText="No"
                      >
                        <Button
                          loading={isLoadingDeleteSport}
                          className="btn-main-outline-9"
                          size="small"
                        >
                          Delete
                        </Button>
                      </Popconfirm> */}
                    </Space>
                  );
                }}
              />
            </Table>
          </Col>
          <Col xs={24} sm={24} md={24}>
            <Space className="ant-space-flex-space-between">
              <TableShowingEntries />

              <TablePagination
                paginationFilter={tableFilter}
                setPaginationFilter={setTableFilter}
                setPaginationTotal={tableTotal}
                showLessItems={true}
                showSizeChanger={false}
              />
            </Space>
          </Col>
          <Col xs={24} sm={24} md={12}></Col>
        </Row>

        <br />
      </Card>

      <ModalSchool
        setToggleModalForm={setToggleModalForm}
        toggleModalForm={toggleModalForm}
        handleOnFinish={handleOnFinish}
        isLoadingCreateSport={isLoadingCreateSchool}
      />
    </>
  );
}
