import { faClipboardListCheck } from "@fortawesome/pro-regular-svg-icons";
import { Card, Col, Row, Select, Space, Table } from "antd";
import { useEffect, useState } from "react";
import { GET } from "../../../../providers/useAxiosQuery";
import ComponentHeader from "../../Components/ComponentHeader";
import {
  TableGlobalSearch,
  TablePageSize,
  TablePagination,
  TableShowingEntries,
} from "../../Components/ComponentTableFilter";
import moment from "moment";
import FloatSelect from "../../../../providers/FloatSelect";

export default function PageAssessmentLogs() {
  const [tableFilter, setTableFilter] = useState({
    page: 1,
    page_size: 50,
    search: "",
    search_field: "date",
    sort_field: "date",
    sort_order: "desc",
  });
  const [tableTotal, setTableTotal] = useState(0);
  const [dataSource, setDataSource] = useState([]);

  const {
    refetch: refetchSource,
    isLoading: isLoadingSource,
    isFetching: isFetchingSource,
  } = GET(
    `api/v1/assessment_log?${new URLSearchParams(tableFilter)}`,
    "assessment_logs_table",
    (res) => {
      if (res.data) {
        console.log("res", res);
        // console.log({ ...tableFilter, total: res.data.data.total });
        setDataSource(res.data && res.data.data);
        setTableTotal(res.data.total);
      }
    }
  );

  const handleTableChange = (pagination, filters, sorter) => {
    setTableFilter({
      ...tableFilter,
      sort_field: sorter.columnKey,
      sort_order: sorter.order ? sorter.order.replace("end", "") : null,
      // page: 1,
    });
  };

  const [searchText, setSearchText] = useState("");
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setTableFilter({
        ...tableFilter,
        search: searchText,
        page: 1,
      });
    }, 1500);
    return () => {
      clearTimeout(timeoutId);
    };
  }, [searchText]);

  useEffect(() => {
    refetchSource();
    return () => {};
  }, [tableFilter]);

  return (
    <>
      <ComponentHeader
        title="Assessment"
        sub_title="LOGS"
        icon={faClipboardListCheck}
      />

      <Card className="card-min-height" id="PageAssessmentLogs">
        <Row gutter={(12, 12)}>
          <Col xs={24} sm={24} md={10} lg={12} xl={14} className="m-b-sm">
            <Space>
              <TablePageSize
                paginationFilter={tableFilter}
                setPaginationFilter={setTableFilter}
              />
            </Space>
          </Col>
          <Col xs={24} sm={24} md={14} lg={12} xl={10}>
            <Row gutter={12}>
              <Col xs={24} sm={24} md={12}>
                <Select
                  defaultValue={"date"}
                  size="large"
                  style={{ width: "100%" }}
                  onChange={(e) =>
                    setTableFilter({ ...tableFilter, search_field: e })
                  }
                  className="m-b-sm"
                >
                  <Select.Option value="date">Date Assessed</Select.Option>
                  <Select.Option value="athlete_name">
                    Athlete's Name
                  </Select.Option>
                  <Select.Option value="assessment_type">
                    Assessment Type
                  </Select.Option>
                  <Select.Option value="title">Title</Select.Option>
                  <Select.Option value="decsciption">Description</Select.Option>
                  <Select.Option value="status">Status</Select.Option>
                </Select>
              </Col>
              <Col xs={24} sm={24} md={12}>
                <TableGlobalSearch
                  paginationFilter={searchText}
                  setPaginationFilter={setSearchText}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col xs={24} sm={24} md={24} className="m-t-sm m-b-sm">
            <Table
              className="ant-table-default ant-table-striped"
              dataSource={dataSource && dataSource}
              rowKey={(record) => record.id}
              loading={isLoadingSource || isFetchingSource}
              pagination={false}
              bordered={false}
              onChange={handleTableChange}
              scroll={{ x: "max-content" }}
            >
              <Table.Column
                title="Date Assessed"
                key="date"
                dataIndex="date"
                // render={(_, record) =>
                //   `${moment(record.created_at).format("MMM. DD YYYY")} ${moment(
                //     record.created_at,
                //     "hh:mm"
                //   ).format("LT")}`
                // }
                defaultSortOrder="descend"
                sorter={true}
                width={"180px"}
              />
              <Table.Column
                title="Athlete's Name"
                key="athlete_name"
                dataIndex="athlete_name"
                sorter={true}
                width={"150px"}
              />
              <Table.Column
                key="assessment_type"
                title="Assessment Type"
                dataIndex="assessment_type"
                sorter={true}
                width={"170px"}
              />
              <Table.Column
                title="Title"
                key="title"
                dataIndex="title"
                sorter={true}
                width={"150px"}
              />
              <Table.Column
                title="Description"
                key="decsciption"
                dataIndex="decsciption"
                sorter={true}
                width={"350px"}
              />
              <Table.Column
                title="Status"
                key="status"
                dataIndex="status"
                sorter={true}
                width={"100px"}
              />
            </Table>
          </Col>
          <Col xs={24} sm={24} md={24}>
            <Space className="ant-space-flex-space-between">
              <TableShowingEntries />

              <TablePagination
                paginationFilter={tableFilter}
                setPaginationFilter={setTableFilter}
                setPaginationTotal={tableTotal}
                showLessItems={true}
                showSizeChanger={false}
              />
            </Space>
          </Col>
        </Row>
        <br />
      </Card>
    </>
  );
}
