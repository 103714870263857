import {
  // degrees,
  PDFDocument,
  rgb,
  StandardFonts,
} from "pdf-lib";
import download from "downloadjs";
import moment from "moment";
// import truncateDecimalNumber from "./truncateDecimalNumber";

const pdfElite = async (pdfUrl, data, display_data) => {
  // let averageText = "Average";
  // let dash = "-";
  // let na = "N/A";
  // console.log("display_data", display_data);
  // let new_display_data = display_data.map((item) => {
  // 	let itemFi = item.name.split("_")[0].substring(1);
  // 	console.log("itemFi", itemFi);
  // });

  let name = data.name === null ? "NA" : data.name;
  let p_3_age = data.p_3_age === null ? "NA" : data.p_3_age;
  let p_3_sport = data.p_3_sport === null ? "NA" : data.p_3_sport;
  let p_3_weight = data.p_3_weight === null ? "NA" : data.p_3_weight;
  let p_3_gender = data.p_3_gender === null ? "NA" : data.p_3_gender;

  let dataRender = [
    [
      [
        {
          value: `${name}`,
          x: 185,
          y: ["+", 240],
          fontSize: 46,
          font: StandardFonts.HelveticaBold,
          color: rgb(1, 1, 1),
          posxx: true,
        },
      ],
    ], // page 1
    [], // page 2
    [
      [
        {
          value: `${p_3_age}`,
          x: 85,
          y: ["+", 309],
          fontSize: 18,
          color: rgb(0, 0, 0),
          posxx: true,
        },
        {
          value: `${p_3_sport}`,
          x: 185,
          y: ["+", 309],
          fontSize: 18,
          color: rgb(0, 0, 0),
          posxx: true,
        },
        {
          value: `${p_3_weight}`,
          x: 828,
          y: ["+", 310],
          fontSize: 18,
          color: rgb(0, 0, 0),
          posxx: true,
        },
        {
          value: `${p_3_gender}`,
          x: 913,
          y: ["+", 310],
          fontSize: 18,
          color: rgb(0, 0, 0),
          posxx: true,
        },
      ],
      [
        {
          value: `${
            data.p_3_rebound_jump === null ||
            data.p_3_rebound_jump === 0 ||
            data.p_3_rebound_jump === "EF" ||
            data.p_3_rebound_jump === "AI" ||
            data.p_3_rebound_jump === "ART"
              ? "NA"
              : `${data.p_3_rebound_jump}`
          }`,
          x: 280,
          y: ["+", 135],
          fontSize: 18,
          color: rgb(0, 0, 0),
          name: "p3_cmj_rsi_no",
        },
        {
          value: `${
            data.p_3_countermovement_jump === null ||
            data.p_3_countermovement_jump === 0 ||
            data.p_3_countermovement_jump === "EF" ||
            data.p_3_countermovement_jump === "AI" ||
            data.p_3_countermovement_jump === "ART"
              ? "NA"
              : `${data.p_3_countermovement_jump}"`
          }`,
          x: 430,
          y: ["+", 135],
          fontSize: 18,
          color: rgb(0, 0, 0),
          name: "p3_es_height",
        },
        {
          value: `${
            data.p_3_squat_jump === null ||
            data.p_3_squat_jump === 0 ||
            data.p_3_squat_jump === "EF" ||
            data.p_3_squat_jump === "AI" ||
            data.p_3_squat_jump === "ART"
              ? "NA"
              : `${data.p_3_squat_jump}"`
          }`,
          x: 586,
          y: ["+", 135],
          fontSize: 18,
          color: rgb(0, 0, 0),
          name: "p3_sj_height",
        },
        {
          value: `${
            data.p_3_ten_yard_sprint_jump === null ||
            data.p_3_ten_yard_sprint_jump === 0 ||
            data.p_3_ten_yard_sprint_jump === "EF" ||
            data.p_3_ten_yard_sprint_jump === "AI" ||
            data.p_3_ten_yard_sprint_jump === "ART"
              ? "NA"
              : `${data.p_3_ten_yard_sprint_jump}s`
          }`,
          x: 738,
          y: ["+", 135],
          fontSize: 18,
          color: rgb(0, 0, 0),
          name: "p3_sprint_time_tem_meter",
        },
        {
          value: `${
            data.p_3_isometric_mid_thigh_pull === null ||
            data.p_3_isometric_mid_thigh_pull === 0 ||
            data.p_3_isometric_mid_thigh_pull === "EF" ||
            data.p_3_isometric_mid_thigh_pull === "AI" ||
            data.p_3_isometric_mid_thigh_pull === "ART"
              ? "NA"
              : `${data.p_3_isometric_mid_thigh_pull}lbs`
          }`,
          x: 890,
          y: ["+", 135],
          fontSize: 18,
          color: rgb(0, 0, 0),
          name: "p3_isometric_pull",
        },
      ],
      [
        {
          value: `${
            data.p_3_age === null ||
            data.p_3_age === 0 ||
            data.p_3_age === "EF" ||
            data.p_3_age === "AI" ||
            data.p_3_age === "ART"
              ? "NA"
              : `${data.p_3_age}`
          }`,
          x: 110,
          y: ["+", 52],
          fontSize: 20,
          color: rgb(1, 1, 1),
          name: "p3_p_3_age1",
        },
        {
          value: `${
            data.p_3_rebound_jump === null ||
            data.p_3_rebound_jump === 0 ||
            data.p_3_rebound_jump === "EF" ||
            data.p_3_rebound_jump === "AI" ||
            data.p_3_rebound_jump === "ART"
              ? "NA"
              : `${data.p_3_rebound_jump_average}`
          }`,
          x: 280,
          y: ["+", 60],
          fontSize: 18,
          color: rgb(0, 0, 0),
          name: "p3_age_avg_cmj_rsi_no_sum",
        },
        {
          value: `${
            data.p_3_countermovement_jump === null ||
            data.p_3_countermovement_jump === 0 ||
            data.p_3_countermovement_jump === "EF" ||
            data.p_3_countermovement_jump === "AI" ||
            data.p_3_countermovement_jump === "ART"
              ? "NA"
              : `${data.p_3_countermovement_jump_average}"`
          }`,
          x: 430,
          y: ["+", 60],
          fontSize: 18,
          color: rgb(0, 0, 0),
          name: "p3_age_avg_es_height_sum",
        },
        {
          value: `${
            data.p_3_squat_jump === null ||
            data.p_3_squat_jump === 0 ||
            data.p_3_squat_jump === "EF" ||
            data.p_3_squat_jump === "AI" ||
            data.p_3_squat_jump === "ART"
              ? "NA"
              : `${data.p_3_squat_jump_average}"`
          }`,
          x: 586,
          y: ["+", 60],
          fontSize: 18,
          color: rgb(0, 0, 0),
          name: "p3_age_avg_sj_height_sum",
        },
        {
          value: `${
            data.p_3_ten_yard_sprint_jump === null ||
            data.p_3_ten_yard_sprint_jump === 0 ||
            data.p_3_ten_yard_sprint_jump === "EF" ||
            data.p_3_ten_yard_sprint_jump === "AI" ||
            data.p_3_ten_yard_sprint_jump === "ART"
              ? "NA"
              : `${data.p_3_ten_yard_sprint_jump_average}s`
          }`,
          x: 738,
          y: ["+", 60],
          fontSize: 18,
          color: rgb(0, 0, 0),
          name: "p3_age_avg_sprint_time_tem_meter_sum",
        },
        {
          value: `${
            data.p_3_isometric_mid_thigh_pull === null ||
            data.p_3_isometric_mid_thigh_pull === 0 ||
            data.p_3_isometric_mid_thigh_pull === "EF" ||
            data.p_3_isometric_mid_thigh_pull === "AI" ||
            data.p_3_isometric_mid_thigh_pull === "ART"
              ? "NA"
              : `${data.p_3_isometric_mid_thigh_pull_average}lbs`
          }`,
          x: 890,
          y: ["+", 60],
          fontSize: 18,
          color: rgb(0, 0, 0),
          name: "p3_age_avg_isometric_pull",
        },
      ],
      [
        {
          value: `${
            data.p_3_rebound_jump === null ||
            data.p_3_rebound_jump === 0 ||
            data.p_3_rebound_jump === "EF" ||
            data.p_3_rebound_jump === "AI" ||
            data.p_3_rebound_jump === "ART"
              ? ""
              : `${data.p_3_rebound_jump_benchmark}`
          }`,
          x: 280,
          y: ["-", 2],
          fontSize: 18,
          color: rgb(0, 0, 0),
          name: "p3_average_1",
        },
        {
          value: `${
            data.p_3_countermovement_jump === null ||
            data.p_3_countermovement_jump === 0 ||
            data.p_3_countermovement_jump === "EF" ||
            data.p_3_countermovement_jump === "AI" ||
            data.p_3_countermovement_jump === "ART"
              ? ""
              : `${data.p_3_countermovement_jump_benchmark}`
          }`,
          x: 430,
          y: ["-", 2],
          fontSize: 18,
          color: rgb(0, 0, 0),
          name: "p3_average_2",
        },
        {
          value: `${
            data.p_3_squat_jump === null ||
            data.p_3_squat_jump === 0 ||
            data.p_3_squat_jump === "EF" ||
            data.p_3_squat_jump === "AI" ||
            data.p_3_squat_jump === "ART"
              ? ""
              : `${data.p_3_squat_jump_benchmark}`
          }`,
          x: 586,
          y: ["-", 2],
          fontSize: 18,
          color: rgb(0, 0, 0),
          name: "p3_average_3",
        },
        {
          value: `${
            data.p_3_ten_yard_sprint_jump === null ||
            data.p_3_ten_yard_sprint_jump === 0 ||
            data.p_3_ten_yard_sprint_jump === "EF" ||
            data.p_3_ten_yard_sprint_jump === "AI" ||
            data.p_3_ten_yard_sprint_jump === "ART"
              ? ""
              : `${data.p_3_ten_yard_sprint_jump_benchmark}`
          }`,
          x: 738,
          y: ["-", 2],
          fontSize: 18,
          color: rgb(0, 0, 0),
          name: "p3_average_4",
        },
        {
          value: `${
            data.p_3_isometric_mid_thigh_pull === null ||
            data.p_3_isometric_mid_thigh_pull === 0 ||
            data.p_3_isometric_mid_thigh_pull === "EF" ||
            data.p_3_isometric_mid_thigh_pull === "AI" ||
            data.p_3_isometric_mid_thigh_pull === "ART"
              ? ""
              : `${data.p_3_isometric_mid_thigh_pull_benchmark}`
          }`,
          x: 890,
          y: ["-", 2],
          fontSize: 18,
          color: rgb(0, 0, 0),
          name: "p3_average_5",
        },
      ],
      [
        {
          value: `${
            data.p_3_rebound_jump === null ||
            data.p_3_rebound_jump === 0 ||
            data.p_3_rebound_jump === "EF" ||
            data.p_3_rebound_jump === "AI" ||
            data.p_3_rebound_jump === "ART"
              ? ""
              : `PERCENTILE`
          }`,
          x: 280,
          y: ["-", 25],
          fontSize: 18,
          color: rgb(0, 0, 0),
          name: "p3_average_1",
        },
        {
          value: `${
            data.p_3_countermovement_jump === null ||
            data.p_3_countermovement_jump === 0 ||
            data.p_3_countermovement_jump === "EF" ||
            data.p_3_countermovement_jump === "AI" ||
            data.p_3_countermovement_jump === "ART"
              ? ""
              : `PERCENTILE`
          }`,
          x: 430,
          y: ["-", 25],
          fontSize: 18,
          color: rgb(0, 0, 0),
          name: "p3_average_2",
        },
        {
          value: `${
            data.p_3_squat_jump === null ||
            data.p_3_squat_jump === 0 ||
            data.p_3_squat_jump === "EF" ||
            data.p_3_squat_jump === "AI" ||
            data.p_3_squat_jump === "ART"
              ? ""
              : `PERCENTILE`
          }`,
          x: 586,
          y: ["-", 25],
          fontSize: 18,
          color: rgb(0, 0, 0),
          name: "p3_average_3",
        },
        {
          value: `${
            data.p_3_ten_yard_sprint_jump === null ||
            data.p_3_ten_yard_sprint_jump === 0 ||
            data.p_3_ten_yard_sprint_jump === "EF" ||
            data.p_3_ten_yard_sprint_jump === "AI" ||
            data.p_3_ten_yard_sprint_jump === "ART"
              ? ""
              : `PERCENTILE`
          }`,
          x: 738,
          y: ["-", 25],
          fontSize: 18,
          color: rgb(0, 0, 0),
          name: "p3_average_4",
        },
        {
          value: `${
            data.p_3_isometric_mid_thigh_pull === null ||
            data.p_3_isometric_mid_thigh_pull === 0 ||
            data.p_3_isometric_mid_thigh_pull === "EF" ||
            data.p_3_isometric_mid_thigh_pull === "AI" ||
            data.p_3_isometric_mid_thigh_pull === "ART"
              ? ""
              : `PERCENTILE`
          }`,
          x: 890,
          y: ["-", 25],
          fontSize: 18,
          color: rgb(0, 0, 0),
          name: "p3_average_5",
        },
      ],
      [
        {
          value: `${
            data.p_3_rebound_jump === null ||
            data.p_3_rebound_jump === 0 ||
            data.p_3_rebound_jump === "EF" ||
            data.p_3_rebound_jump === "AI" ||
            data.p_3_rebound_jump === "ART"
              ? "NA"
              : ``
          }`,
          x: 280,
          y: ["-", 15],
          fontSize: 18,
          color: rgb(0, 0, 0),
          name: "p3_average_1",
        },
        {
          value: `${
            data.p_3_countermovement_jump === null ||
            data.p_3_countermovement_jump === 0 ||
            data.p_3_countermovement_jump === "EF" ||
            data.p_3_countermovement_jump === "AI" ||
            data.p_3_countermovement_jump === "ART"
              ? "NA"
              : ``
          }`,
          x: 430,
          y: ["-", 15],
          fontSize: 18,
          color: rgb(0, 0, 0),
          name: "p3_average_2",
        },
        {
          value: `${
            data.p_3_squat_jump === null ||
            data.p_3_squat_jump === 0 ||
            data.p_3_squat_jump === "EF" ||
            data.p_3_squat_jump === "AI" ||
            data.p_3_squat_jump === "ART"
              ? "NA"
              : ``
          }`,
          x: 586,
          y: ["-", 15],
          fontSize: 18,
          color: rgb(0, 0, 0),
          name: "p3_average_3",
        },
        {
          value: `${
            data.p_3_ten_yard_sprint_jump === null ||
            data.p_3_ten_yard_sprint_jump === 0 ||
            data.p_3_ten_yard_sprint_jump === "EF" ||
            data.p_3_ten_yard_sprint_jump === "AI" ||
            data.p_3_ten_yard_sprint_jump === "ART"
              ? "NA"
              : ``
          }`,
          x: 738,
          y: ["-", 15],
          fontSize: 18,
          color: rgb(0, 0, 0),
          name: "p3_average_4",
        },
        {
          value: `${
            data.p_3_isometric_mid_thigh_pull === null ||
            data.p_3_isometric_mid_thigh_pull === 0 ||
            data.p_3_isometric_mid_thigh_pull === "EF" ||
            data.p_3_isometric_mid_thigh_pull === "AI" ||
            data.p_3_isometric_mid_thigh_pull === "ART"
              ? "NA"
              : ``
          }`,
          x: 890,
          y: ["-", 15],
          fontSize: 18,
          color: rgb(0, 0, 0),
          name: "p3_average_5",
        },
      ],
    ], // page 3
    [
      [
        {
          value: `${
            data.p_4_static_med_ball_throw === null ||
            data.p_4_static_med_ball_throw === 0 ||
            data.p_4_static_med_ball_throw === "EF" ||
            data.p_4_static_med_ball_throw === "AI" ||
            data.p_4_static_med_ball_throw === "ART"
              ? "NA"
              : `${data.p_4_static_med_ball_throw}W`
          }`,
          x: 280,
          y: ["+", 90],
          fontSize: 18,
          color: rgb(0, 0, 0),
          name: "p4_spm_power",
        },
        {
          value: `${
            data.p_4_rational_med_throw === null ||
            data.p_4_rational_med_throw === 0 ||
            data.p_4_rational_med_throw === "EF" ||
            data.p_4_rational_med_throw === "AI" ||
            data.p_4_rational_med_throw === "ART"
              ? "NA"
              : `${data.p_4_rational_med_throw}W`
          }`,
          x: 430,
          y: ["+", 90],
          fontSize: 18,
          color: rgb(0, 0, 0),
          name: "p4_rm_power",
        },
        {
          value: `${
            data.p_4_push_ups === null ||
            // data.p_4_push_ups === 0 ||
            data.p_4_push_ups === "EF" ||
            data.p_4_push_ups === "AI" ||
            data.p_4_push_ups === "ART"
              ? "NA"
              : `${data.p_4_push_ups}`
          }`,
          x: 586,
          y: ["+", 90],
          fontSize: 18,
          color: rgb(0, 0, 0),
          name: "p4_se_pull_ups",
        },
        {
          value: `${
            data.p_4_chin_ups === null ||
            // data.p_4_chin_ups === 0 ||
            data.p_4_chin_ups === "EF" ||
            data.p_4_chin_ups === "AI" ||
            data.p_4_chin_ups === "ART"
              ? "NA"
              : `${data.p_4_chin_ups}`
          }`,
          x: 738,
          y: ["+", 90],
          fontSize: 18,
          color: rgb(0, 0, 0),
          name: "p4_se_chin_ups",
        },
        {
          value: `${
            data.p_4_5_10_5_agility_test === null ||
            data.p_4_5_10_5_agility_test === 0 ||
            data.p_4_5_10_5_agility_test === "EF" ||
            data.p_4_5_10_5_agility_test === "AI" ||
            data.p_4_5_10_5_agility_test === "ART"
              ? "NA"
              : `${data.p_4_5_10_5_agility_test}s`
          }`,
          x: 890,
          y: ["+", 90],
          fontSize: 18,
          color: rgb(0, 0, 0),
          name: "p4_cod_power",
        },
      ],
      [
        {
          value: `${
            data.p_3_age === null ||
            data.p_3_age === 0 ||
            data.p_3_age === "EF" ||
            data.p_3_age === "AI" ||
            data.p_3_age === "ART"
              ? "NA"
              : `${data.p_3_age}`
          }`,
          x: 110,
          y: ["-", 17],
          fontSize: 21,
          color: rgb(1, 1, 1),
          name: "p3_age_avg_cmj_rsi_no_sum",
        },
        {
          value: `${
            data.p_4_static_med_ball_throw === null ||
            data.p_4_static_med_ball_throw === 0 ||
            data.p_4_static_med_ball_throw === "EF" ||
            data.p_4_static_med_ball_throw === "AI" ||
            data.p_4_static_med_ball_throw === "ART"
              ? "NA"
              : `${data.p_4_static_med_ball_throw_average}W`
          }`,
          x: 280,
          y: ["-", 10],
          fontSize: 18,
          color: rgb(0, 0, 0),
          name: "p4_age_avg_spm_power_sum",
        },
        {
          value: `${
            data.p_4_rational_med_throw === null ||
            data.p_4_rational_med_throw === 0 ||
            data.p_4_rational_med_throw === "EF" ||
            data.p_4_rational_med_throw === "AI" ||
            data.p_4_rational_med_throw === "ART"
              ? "NA"
              : `${data.p_4_rational_med_throw_average}W`
          }`,
          x: 430,
          y: ["-", 10],
          fontSize: 18,
          color: rgb(0, 0, 0),
          name: "p4_age_avg_rm_power_sum",
        },
        {
          value: `${
            data.p_4_push_ups === null ||
            // data.p_4_push_ups === 0 ||
            data.p_4_push_ups === "EF" ||
            data.p_4_push_ups === "AI" ||
            data.p_4_push_ups === "ART"
              ? "NA"
              : `${data.p_4_push_ups_average}`
          }`,
          x: 586,
          y: ["-", 10],
          fontSize: 18,
          color: rgb(0, 0, 0),
          name: "p4_age_avg_se_pull_ups_sum",
        },
        {
          value: `${
            data.p_4_chin_ups === null ||
            // data.p_4_chin_ups === 0 ||
            data.p_4_chin_ups === "EF" ||
            data.p_4_chin_ups === "AI" ||
            data.p_4_chin_ups === "ART"
              ? "NA"
              : `${data.p_4_chin_ups_average}`
          }`,
          x: 738,
          y: ["-", 10],
          fontSize: 18,
          color: rgb(0, 0, 0),
          name: "p4_age_avg_se_chin_ups_sum",
        },
        {
          value: `${
            data.p_4_5_10_5_agility_test === null ||
            data.p_4_5_10_5_agility_test === 0 ||
            data.p_4_5_10_5_agility_test === "EF" ||
            data.p_4_5_10_5_agility_test === "AI" ||
            data.p_4_5_10_5_agility_test === "ART"
              ? "NA"
              : `${data.p_4_5_10_5_agility_test_average}s`
          }`,
          x: 890,
          y: ["-", 10],
          fontSize: 18,
          color: rgb(0, 0, 0),
          name: "p4_age_avg_cod_power_sum",
        },
      ],
      [
        {
          value: `${
            data.p_4_static_med_ball_throw === null ||
            data.p_4_static_med_ball_throw === 0 ||
            data.p_4_static_med_ball_throw === "EF" ||
            data.p_4_static_med_ball_throw === "AI" ||
            data.p_4_static_med_ball_throw === "ART"
              ? ""
              : `${data.p_4_static_med_ball_throw_benchmark}`
          }`,
          x: 280,
          y: ["-", 100],
          fontSize: 18,
          color: rgb(0, 0, 0),
          name: "p4_average_1",
        },
        {
          value: `${
            data.p_4_rational_med_throw === null ||
            data.p_4_rational_med_throw === 0 ||
            data.p_4_rational_med_throw === "EF" ||
            data.p_4_rational_med_throw === "AI" ||
            data.p_4_rational_med_throw === "ART"
              ? ""
              : `${data.p_4_rational_med_throw_benchmark}`
          }`,
          x: 430,
          y: ["-", 100],
          fontSize: 18,
          color: rgb(0, 0, 0),
          name: "p4_average_2",
        },
        {
          value: `${
            data.p_4_push_ups === null ||
            // data.p_4_push_ups === 0 ||
            data.p_4_push_ups === "EF" ||
            data.p_4_push_ups === "AI" ||
            data.p_4_push_ups === "ART"
              ? ""
              : `${data.p_4_push_ups_benchmark}`
          }`,
          x: 586,
          y: ["-", 100],
          fontSize: 18,
          color: rgb(0, 0, 0),
          name: "p4_average_3",
        },
        {
          value: `${
            data.p_4_chin_ups === null ||
            // data.p_4_chin_ups === 0 ||
            data.p_4_chin_ups === "EF" ||
            data.p_4_chin_ups === "AI" ||
            data.p_4_chin_ups === "ART"
              ? ""
              : `${data.p_4_chin_ups_benchmark}`
          }`,
          x: 738,
          y: ["-", 100],
          fontSize: 18,
          color: rgb(0, 0, 0),
          name: "p4_average_4",
        },
        {
          value: `${
            data.p_4_5_10_5_agility_test === null ||
            data.p_4_5_10_5_agility_test === 0 ||
            data.p_4_5_10_5_agility_test === "EF" ||
            data.p_4_5_10_5_agility_test === "AI" ||
            data.p_4_5_10_5_agility_test === "ART"
              ? ""
              : `${data.p_4_5_10_5_agility_test_benchmark}`
          }`,
          x: 890,
          y: ["-", 100],
          fontSize: 18,
          color: rgb(0, 0, 0),
          name: "p4_average_5",
        },
      ],
      [
        {
          value: `${
            data.p_4_static_med_ball_throw === null ||
            data.p_4_static_med_ball_throw === 0 ||
            data.p_4_static_med_ball_throw === "EF" ||
            data.p_4_static_med_ball_throw === "AI" ||
            data.p_4_static_med_ball_throw === "ART"
              ? ""
              : `PERCENTILE`
          }`,
          x: 280,
          y: ["-", 123],
          fontSize: 18,
          color: rgb(0, 0, 0),
          name: "p4_average_1",
        },
        {
          value: `${
            data.p_4_rational_med_throw === null ||
            data.p_4_rational_med_throw === 0 ||
            data.p_4_rational_med_throw === "EF" ||
            data.p_4_rational_med_throw === "AI" ||
            data.p_4_rational_med_throw === "ART"
              ? ""
              : `PERCENTILE`
          }`,
          x: 430,
          y: ["-", 123],
          fontSize: 18,
          color: rgb(0, 0, 0),
          name: "p4_average_2",
        },
        {
          value: `${
            data.p_4_push_ups === null ||
            data.p_4_push_ups === 0 ||
            data.p_4_push_ups === "EF" ||
            data.p_4_push_ups === "AI" ||
            data.p_4_push_ups === "ART"
              ? ""
              : `PERCENTILE`
          }`,
          x: 586,
          y: ["-", 123],
          fontSize: 18,
          color: rgb(0, 0, 0),
          name: "p4_average_3",
        },
        {
          value: `${
            data.p_4_chin_ups === null ||
            data.p_4_chin_ups === 0 ||
            data.p_4_chin_ups === "EF" ||
            data.p_4_chin_ups === "AI" ||
            data.p_4_chin_ups === "ART"
              ? ""
              : `PERCENTILE`
          }`,
          x: 738,
          y: ["-", 123],
          fontSize: 18,
          color: rgb(0, 0, 0),
          name: "p4_average_4",
        },
        {
          value: `${
            data.p_4_5_10_5_agility_test === null ||
            data.p_4_5_10_5_agility_test === 0 ||
            data.p_4_5_10_5_agility_test === "EF" ||
            data.p_4_5_10_5_agility_test === "AI" ||
            data.p_4_5_10_5_agility_test === "ART"
              ? ""
              : `PERCENTILE`
          }`,
          x: 890,
          y: ["-", 123],
          fontSize: 18,
          color: rgb(0, 0, 0),
          name: "p4_average_5",
        },
      ],
      [
        {
          value: `${
            data.p_4_static_med_ball_throw === null ||
            data.p_4_static_med_ball_throw === 0 ||
            data.p_4_static_med_ball_throw === "EF" ||
            data.p_4_static_med_ball_throw === "AI" ||
            data.p_4_static_med_ball_throw === "ART"
              ? "NA"
              : ``
          }`,
          x: 280,
          y: ["-", 111],
          fontSize: 18,
          color: rgb(0, 0, 0),
          name: "p4_average_1",
        },
        {
          value: `${
            data.p_4_rational_med_throw === null ||
            data.p_4_rational_med_throw === 0 ||
            data.p_4_rational_med_throw === "EF" ||
            data.p_4_rational_med_throw === "AI" ||
            data.p_4_rational_med_throw === "ART"
              ? "NA"
              : ``
          }`,
          x: 430,
          y: ["-", 111],
          fontSize: 18,
          color: rgb(0, 0, 0),
          name: "p4_average_2",
        },
        {
          value: `${
            data.p_4_push_ups === null ||
            data.p_4_push_ups === 0 ||
            data.p_4_push_ups === "EF" ||
            data.p_4_push_ups === "AI" ||
            data.p_4_push_ups === "ART"
              ? "NA"
              : ``
          }`,
          x: 586,
          y: ["-", 111],
          fontSize: 18,
          color: rgb(0, 0, 0),
          name: "p4_average_3",
        },
        {
          value: `${
            data.p_4_chin_ups === null ||
            data.p_4_chin_ups === 0 ||
            data.p_4_chin_ups === "EF" ||
            data.p_4_chin_ups === "AI" ||
            data.p_4_chin_ups === "ART"
              ? "NA"
              : ``
          }`,
          x: 738,
          y: ["-", 111],
          fontSize: 18,
          color: rgb(0, 0, 0),
          name: "p4_average_4",
        },
        {
          value: `${
            data.p_4_5_10_5_agility_test === null ||
            data.p_4_5_10_5_agility_test === 0 ||
            data.p_4_5_10_5_agility_test === "EF" ||
            data.p_4_5_10_5_agility_test === "AI" ||
            data.p_4_5_10_5_agility_test === "ART"
              ? "NA"
              : ``
          }`,
          x: 890,
          y: ["-", 111],
          fontSize: 18,
          color: rgb(0, 0, 0),
          name: "p4_average_5",
        },
      ],
    ], // page 4
    [], // page 5
    [
      [
        {
          value: `${
            data.p_6_rsi_score === null ||
            data.p_6_rsi_score === 0 ||
            data.p_6_rsi_score === "EF" ||
            data.p_6_rsi_score === "AI" ||
            data.p_6_rsi_score === "ART"
              ? "NA"
              : `${data.p_6_rsi_score}`
          }`,
          x: 605,
          y: ["+", 215],
          fontSize: 12,
          color: rgb(0, 0, 0),
          name: "p6_cmj_rsi_no",
        },
        {
          value: `${
            data.p_6_jump_height === null ||
            data.p_6_jump_height === 0 ||
            data.p_6_jump_height === "EF" ||
            data.p_6_jump_height === "AI" ||
            data.p_6_jump_height === "ART"
              ? "NA"
              : `${data.p_6_jump_height}`
          }`,
          x: 730,
          y: ["+", 215],
          fontSize: 12,
          color: rgb(0, 0, 0),
          name: "p6_es_height",
        },
        {
          value: `${
            data.p_6_ground_contact_time === null ||
            data.p_6_ground_contact_time === 0 ||
            data.p_6_ground_contact_time === "EF" ||
            data.p_6_ground_contact_time === "AI" ||
            data.p_6_ground_contact_time === "ART"
              ? "NA"
              : `${data.p_6_ground_contact_time}`
          }`,
          x: 890,
          y: ["+", 215],
          fontSize: 12,
          color: rgb(0, 0, 0),
          name: "p6_ground_contact_time",
        },
        {
          value: `${
            data.p_6_rsi_score === null ||
            data.p_6_rsi_score === 0 ||
            data.p_6_rsi_score === "EF" ||
            data.p_6_rsi_score === "AI" ||
            data.p_6_rsi_score === "ART"
              ? "NA"
              : `${data.p_6_rank}`
          }`,
          x: 140,
          y: ["+", 42],
          fontSize: 16,
          color: rgb(0, 0, 0),
          name: "p6_rank",
          posxx: true,
        },
      ],
    ], // page 6
    [], // page 7
    [
      [
        {
          value: `${
            data.p_8_cmj_height === null ||
            data.p_8_cmj_height === 0 ||
            data.p_8_cmj_height === "EF" ||
            data.p_8_cmj_height === "AI" ||
            data.p_8_cmj_height === "ART"
              ? "NA"
              : `${data.p_8_cmj_height}`
          }`,
          x: 765,
          y: ["+", 212],
          fontSize: 12,
          color: rgb(0, 0, 0),
          name: "p8_cmj_jump_height",
        },
        {
          value: `${
            data.p_8_cmj_height === null ||
            data.p_8_cmj_height === 0 ||
            data.p_8_cmj_height === "EF" ||
            data.p_8_cmj_height === "AI" ||
            data.p_8_cmj_height === "ART"
              ? "NA"
              : `${data.p_8_cmj_height_average}`
          }`,
          x: 900,
          y: ["+", 212],
          fontSize: 12,
          color: rgb(0, 0, 0),
          name: "p8_avg_cmj_jump_height",
        },
      ],
      [
        {
          value: `${
            data.p_8_relative_avg_power === null ||
            data.p_8_relative_avg_power === 0 ||
            data.p_8_relative_avg_power === "EF" ||
            data.p_8_relative_avg_power === "AI" ||
            data.p_8_relative_avg_power === "ART"
              ? "NA"
              : `${data.p_8_relative_avg_power}`
          }`,
          x: 765,
          y: ["+", 190],
          fontSize: 12,
          color: rgb(0, 0, 0),
          name: "p8_es_peak_power",
        },
        {
          value: `${
            data.p_8_relative_avg_power === null ||
            data.p_8_relative_avg_power === 0 ||
            data.p_8_relative_avg_power === "EF" ||
            data.p_8_relative_avg_power === "AI" ||
            data.p_8_relative_avg_power === "ART"
              ? "NA"
              : `${data.p_8_relative_avg_power_average}`
          }`,
          x: 900,
          y: ["+", 190],
          fontSize: 12,
          color: rgb(0, 0, 0),
          name: "p8_cmj_relative_avg_power",
        },
        {
          value: `${
            data.p_8_cmj_height === null ||
            data.p_8_cmj_height === 0 ||
            data.p_8_cmj_height === "EF" ||
            data.p_8_cmj_height === "AI" ||
            data.p_8_cmj_height === "ART"
              ? "NA"
              : `${data.p_8_rank}`
          }`,
          x: 140,
          y: ["+", 60],
          fontSize: 16,
          color: rgb(0, 0, 0),
          name: "p8_rank",
          posxx: true,
        },
      ],
    ], // page 8
    [], // page 9
    [
      [
        {
          value: `${
            data.p_10_j_height === null ||
            data.p_10_j_height === 0 ||
            data.p_10_j_height === "EF" ||
            data.p_10_j_height === "AI" ||
            data.p_10_j_height === "ART"
              ? "NA"
              : `${data.p_10_j_height}`
          }`,
          x: 765,
          y: ["+", 197],
          fontSize: 12,
          color: rgb(0, 0, 0),
          name: "p10_sj_height",
        },
        {
          value: `${
            data.p_10_j_height === null ||
            data.p_10_j_height === 0 ||
            data.p_10_j_height === "EF" ||
            data.p_10_j_height === "AI" ||
            data.p_10_j_height === "ART"
              ? "NA"
              : `${data.p_10_j_height_average}`
          }`,
          x: 910,
          y: ["+", 197],
          fontSize: 12,
          color: rgb(0, 0, 0),
          name: "p10_age_avg_sj_height_sum",
        },
      ],
      [
        {
          value: `${
            data.p_10_realative_peak_power === null ||
            data.p_10_realative_peak_power === 0 ||
            data.p_10_realative_peak_power === "EF" ||
            data.p_10_realative_peak_power === "AI" ||
            data.p_10_realative_peak_power === "ART"
              ? "NA"
              : `${data.p_10_realative_peak_power}`
          }`,
          x: 765,
          y: ["+", 165],
          fontSize: 12,
          color: rgb(0, 0, 0),
          name: "p10_sj_peak_power",
        },
        {
          value: `${
            data.p_10_realative_peak_power === null ||
            data.p_10_realative_peak_power === 0 ||
            data.p_10_realative_peak_power === "EF" ||
            data.p_10_realative_peak_power === "AI" ||
            data.p_10_realative_peak_power === "ART"
              ? "NA"
              : `${data.p_10_realative_peak_power_average}`
          }`,
          x: 910,
          y: ["+", 165],
          fontSize: 12,
          color: rgb(0, 0, 0),
          name: "p10_relative_sj_peak_power",
        },
        {
          value: `${
            data.p_10_j_height === null ||
            data.p_10_j_height === 0 ||
            data.p_10_j_height === "EF" ||
            data.p_10_j_height === "AI" ||
            data.p_10_j_height === "ART"
              ? "NA"
              : `${data.p_10_rank}`
          }`,
          x: 140,
          y: ["+", 66],
          fontSize: 16,
          color: rgb(0, 0, 0),
          name: "p10_rank",
          posxx: true,
        },
      ],
    ], // page 10
    [
      [
        {
          value: `${
            data.p_10_j_height === null ||
            data.p_10_j_height === 0 ||
            data.p_10_j_height === "EF" ||
            data.p_10_j_height === "AI" ||
            data.p_10_j_height === "ART"
              ? "NA"
              : `${data.p_11_your_eur_score}`
          }`,
          x: 208,
          y: ["+", -136],
          fontSize: 16,
          color: rgb(0, 0, 0),
          name: "p11_your_eur_score",
          posxx: true,
        },
      ],
    ], // page 11
    [], // page 12
    [
      [
        {
          value: `${
            data.p_13_10_yard_sprint === null ||
            data.p_13_10_yard_sprint === 0 ||
            data.p_13_10_yard_sprint === "EF" ||
            data.p_13_10_yard_sprint === "AI" ||
            data.p_13_10_yard_sprint === "ART"
              ? "NA"
              : `${data.p_13_10_yard_sprint}`
          }`,
          x: 718,
          y: ["+", 185],
          fontSize: 14,
          color: rgb(0, 0, 0),
          name: "p13_sprint_time_tem_meter",
        },
        {
          value: `${
            data.p_13_10_yard_sprint === null ||
            data.p_13_10_yard_sprint === 0 ||
            data.p_13_10_yard_sprint === "EF" ||
            data.p_13_10_yard_sprint === "AI" ||
            data.p_13_10_yard_sprint === "ART"
              ? "NA"
              : `${data.p_13_10_yard_sprint_average}`
          }`,
          x: 815,
          y: ["+", 185],
          fontSize: 14,
          color: rgb(0, 0, 0),
          name: "p13_age_avg_sprint_time_tem_meter_sum",
        },
      ],
      [
        {
          value: `${
            data.p_13_40_yard_sprint === null ||
            data.p_13_40_yard_sprint === 0 ||
            data.p_13_40_yard_sprint === "EF" ||
            data.p_13_40_yard_sprint === "AI" ||
            data.p_13_40_yard_sprint === "ART"
              ? "NA"
              : `${data.p_13_40_yard_sprint}`
          }`,
          x: 718,
          y: ["+", 110],
          fontSize: 14,
          color: rgb(0, 0, 0),
          name: "p13_sprint_time_four_meter",
        },
        {
          value: `${
            data.p_13_40_yard_sprint === null ||
            data.p_13_40_yard_sprint === 0 ||
            data.p_13_40_yard_sprint === "EF" ||
            data.p_13_40_yard_sprint === "AI" ||
            data.p_13_40_yard_sprint === "ART"
              ? "NA"
              : `${data.p_13_40_yard_sprint_average}`
          }`,
          x: 815,
          y: ["+", 110],
          fontSize: 14,
          color: rgb(0, 0, 0),
          name: "p13_age_avg_sprint_time_four_meter_sum",
        },
        {
          value: `${
            data.p_13_40_yard_sprint === null ||
            data.p_13_40_yard_sprint === 0 ||
            data.p_13_40_yard_sprint === "EF" ||
            data.p_13_40_yard_sprint === "AI" ||
            data.p_13_40_yard_sprint === "ART"
              ? "NA"
              : `${data.p_13_rank}`
          }`,
          x: 150,
          y: ["+", 50],
          fontSize: 16,
          color: rgb(0, 0, 0),
          name: "p13_rank",
          posxx: true,
        },
      ],
    ], // page 13
    [], // page 14
    [
      [
        {
          value: `${data && data.p_15_weight}`,
          x: 775,
          y: ["+", 225],
          fontSize: 12,
          color: rgb(0, 0, 0),
          name: "p15_weight",
        },
      ],
      [
        {
          value: `${
            data.p_15_absolute_strenght === null ||
            data.p_15_absolute_strenght === 0 ||
            data.p_15_absolute_strenght === "EF" ||
            data.p_15_absolute_strenght === "AI" ||
            data.p_15_absolute_strenght === "ART"
              ? "NA"
              : `${data.p_15_absolute_strenght}`
          }`,
          x: 700,
          y: ["+", 135],
          fontSize: 12,
          color: rgb(0, 0, 0),
          name: "p15_ms_peak_force",
        },
        {
          value: `${
            data.p_15_absolute_strenght === null ||
            data.p_15_absolute_strenght === 0 ||
            data.p_15_absolute_strenght === "EF" ||
            data.p_15_absolute_strenght === "AI" ||
            data.p_15_absolute_strenght === "ART"
              ? "NA"
              : `${data.p_15_absolute_strenght_pounds}`
          }`,
          x: 775,
          y: ["+", 135],
          fontSize: 12,
          color: rgb(0, 0, 0),
          name: "p15_ms_peak_force_lbs",
        },
        {
          value: `${
            data.p_15_absolute_strenght === null ||
            data.p_15_absolute_strenght === 0 ||
            data.p_15_absolute_strenght === "EF" ||
            data.p_15_absolute_strenght === "AI" ||
            data.p_15_absolute_strenght === "ART"
              ? "NA"
              : `${data.p_15_absolute_strenght_average_1}`
          }`,
          x: 855,
          y: ["+", 135],
          fontSize: 12,
          color: rgb(0, 0, 0),
          name: "p15_age_avg_isometric_pull_n",
        },
        {
          value: `${
            data.p_15_absolute_strenght === null ||
            data.p_15_absolute_strenght === 0 ||
            data.p_15_absolute_strenght === "EF" ||
            data.p_15_absolute_strenght === "AI" ||
            data.p_15_absolute_strenght === "ART"
              ? "NA"
              : `${data.p_15_absolute_strenght_average_2}`
          }`,
          x: 930,
          y: ["+", 135],
          fontSize: 12,
          color: rgb(0, 0, 0),
          name: "p15_age_avg_isometric_pull",
        },
      ],
      [
        {
          value: `${
            data.p_15_absolute_strenght === null ||
            data.p_15_absolute_strenght === 0 ||
            data.p_15_absolute_strenght === "EF" ||
            data.p_15_absolute_strenght === "AI" ||
            data.p_15_absolute_strenght === "ART"
              ? "NA"
              : `${data.p_15_relative_strength_peak_force_trength}x`
          }`,
          x: 780,
          y: ["+", 40],
          fontSize: 12,
          color: rgb(0, 0, 0),
          name: "p15_relative_stregth_pf_bw",
        },
        {
          value: `${
            data.p_15_absolute_strenght === null ||
            data.p_15_absolute_strenght === 0 ||
            data.p_15_absolute_strenght === "EF" ||
            data.p_15_absolute_strenght === "AI" ||
            data.p_15_absolute_strenght === "ART"
              ? "NA"
              : `${data.p_15_relative_strength_peak_force_trength_average}x`
          }`,
          x: 910,
          y: ["+", 40],
          fontSize: 12,
          color: rgb(0, 0, 0),
          name: "p15_relative_stregth_pf_bw_avg_per",
        },
        {
          value: `${
            data.p_15_absolute_strenght === null ||
            data.p_15_absolute_strenght === 0 ||
            data.p_15_absolute_strenght === "EF" ||
            data.p_15_absolute_strenght === "AI" ||
            data.p_15_absolute_strenght === "ART"
              ? "NA"
              : `${data.p_15_rank}`
          }`,
          x: 155,
          y: ["+", 40],
          fontSize: 16,
          color: rgb(0, 0, 0),
          name: "p_15_rank",
          posxx: true,
        },
      ],
    ], // page 15
    [
      [
        {
          value: `${
            data.p_16_peak_force_pounds === null ||
            data.p_16_peak_force_pounds === 0 ||
            data.p_16_peak_force_pounds === "EF" ||
            data.p_16_peak_force_pounds === "AI" ||
            data.p_16_peak_force_pounds === "ART"
              ? "NA"
              : `${data.p_16_peak_force_pounds}`
          }`,
          x: 565,
          y: ["+", 216],
          fontSize: 12,
          color: rgb(0, 0, 0),
          name: "p16_ms_peak_force_lbs",
        },
        {
          value: `${data && data.p_16_weight}`,
          x: 660,
          y: ["+", 216],
          fontSize: 12,
          color: rgb(0, 0, 0),
          name: "p16_weight",
        },
        {
          value: `${
            data.p_16_peak_force_body_weight === null ||
            data.p_16_peak_force_body_weight === 0 ||
            data.p_16_peak_force_body_weight === "EF" ||
            data.p_16_peak_force_body_weight === "AI" ||
            data.p_16_peak_force_body_weight === "ART"
              ? "NA"
              : `${data.p_16_peak_force_body_weight}x`
          }`,
          x: 760,
          y: ["+", 216],
          fontSize: 12,
          color: rgb(0, 0, 0),
          name: "p16_relative_stregth_pf_bw_lbs",
        },
        {
          value: `${
            data.p_16_force_0_100_ms === null ||
            data.p_16_force_0_100_ms === 0 ||
            data.p_16_force_0_100_ms === "EF" ||
            data.p_16_force_0_100_ms === "AI" ||
            data.p_16_force_0_100_ms === "ART"
              ? "NA"
              : `${data.p_16_force_0_100_ms}`
          }`,
          x: 855,
          y: ["+", 216],
          fontSize: 12,
          color: rgb(0, 0, 0),
          name: "p16_ms_rfd_lbs",
        },
        {
          value: `${
            data.p_16_0_100_ms_rfd === null ||
            data.p_16_0_100_ms_rfd === 0 ||
            data.p_16_0_100_ms_rfd === "EF" ||
            data.p_16_0_100_ms_rfd === "AI" ||
            data.p_16_0_100_ms_rfd === "ART"
              ? "NA"
              : `${data.p_16_0_100_ms_rfd}`
          }`,
          x: 955,
          y: ["+", 216],
          fontSize: 12,
          color: rgb(0, 0, 0),
          name: "p16_RFD_percent_of_peak_force_lbs",
        },
      ],
      [
        {
          value: `${data && data.p_16_peak_force_low}`,
          x: 600,
          y: ["-", 52],
          fontSize: 12,
          color: rgb(0, 0, 0),
          name: "p16_2_peak_force_low",
        },
        {
          value: `${data && data.p_16_peak_force_average}`,
          x: 600,
          y: ["-", 75],
          fontSize: 12,
          color: rgb(0, 0, 0),
          name: "p16_2_peak_force_average",
        },
        {
          value: `${data && data.p_16_peak_force_high}`,
          x: 600,
          y: ["-", 98],
          fontSize: 12,
          color: rgb(0, 0, 0),
          name: "p16_2_peak_force_high",
        },
      ],
    ], // page 16
    [
      [
        {
          value: `${
            data.p_17_imtp_peak_force === null ||
            data.p_17_imtp_peak_force === 0 ||
            data.p_17_imtp_peak_force === "EF" ||
            data.p_17_imtp_peak_force === "AI" ||
            data.p_17_imtp_peak_force === "ART"
              ? "NA"
              : `${data.p_17_imtp_peak_force}`
          }`,
          x: 140,
          y: ["-", 138],
          fontSize: 12,
          color: rgb(0, 0, 0),
          name: "p17_ms_peak_force",
        },
        {
          value: `${
            data.p_17_cmj_peak_force === null ||
            data.p_17_cmj_peak_force === 0 ||
            data.p_17_cmj_peak_force === "EF" ||
            data.p_17_cmj_peak_force === "AI" ||
            data.p_17_cmj_peak_force === "ART"
              ? "NA"
              : `${data.p_17_cmj_peak_force}`
          }`,
          x: 270,
          y: ["-", 138],
          fontSize: 12,
          color: rgb(0, 0, 0),
          name: "p17_es_peak_propulsive_force",
        },
        {
          value: `${
            data.p_17_dsi_score === null ||
            data.p_17_dsi_score === 0 ||
            data.p_17_dsi_score === "EF" ||
            data.p_17_dsi_score === "AI" ||
            data.p_17_dsi_score === "ART"
              ? "NA"
              : `${data.p_17_dsi_score}`
          }`,
          x: 400,
          y: ["-", 138],
          fontSize: 15,
          color: rgb(0, 0, 0),
          name: "p17_dsi_score",
        },
      ],
    ], // page 17
    [], // page 18
    [
      [
        {
          value: `${
            data.p_19_peak_propulsive_power === null ||
            data.p_19_peak_propulsive_power === 0 ||
            data.p_19_peak_propulsive_power === "EF" ||
            data.p_19_peak_propulsive_power === "AI" ||
            data.p_19_peak_propulsive_power === "ART"
              ? "NA"
              : `${data.p_19_peak_propulsive_power}W`
          }`,
          x: 770,
          y: ["+", 212],
          fontSize: 12,
          color: rgb(0, 0, 0),
          name: "p19_es_peak_propulsive_force",
        },
        {
          value: `${
            data.p_19_peak_propulsive_power === null ||
            data.p_19_peak_propulsive_power === 0 ||
            data.p_19_peak_propulsive_power === "EF" ||
            data.p_19_peak_propulsive_power === "AI" ||
            data.p_19_peak_propulsive_power === "ART"
              ? "NA"
              : `${data.p_19_peak_propulsive_power_average}W`
          }`,
          x: 905,
          y: ["+", 212],
          fontSize: 12,
          color: rgb(0, 0, 0),
          name: "p19_es_peak_propulsive_force_sum_avg",
        },
      ],
      [
        {
          value: `${
            data.p_19_relative_peak_power === null ||
            data.p_19_relative_peak_power === 0 ||
            data.p_19_relative_peak_power === "EF" ||
            data.p_19_relative_peak_power === "AI" ||
            data.p_19_relative_peak_power === "ART"
              ? "NA"
              : `${data.p_19_relative_peak_power}W/lb`
          }`,
          x: 770,
          y: ["+", 190],
          fontSize: 12,
          color: rgb(0, 0, 0),
          name: "p19_relative_peak_power",
        },
        {
          value: `${
            data.p_19_relative_peak_power === null ||
            data.p_19_relative_peak_power === 0 ||
            data.p_19_relative_peak_power === "EF" ||
            data.p_19_relative_peak_power === "AI" ||
            data.p_19_relative_peak_power === "ART"
              ? "NA"
              : `${data.p_19_relative_peak_power_average}W/lb`
          }`,
          x: 905,
          y: ["+", 190],
          fontSize: 12,
          color: rgb(0, 0, 0),
          name: "p19_relative_peak_power_avg",
        },
        {
          value: `${
            data.p_19_peak_propulsive_power === null ||
            data.p_19_peak_propulsive_power === 0 ||
            data.p_19_peak_propulsive_power === "EF" ||
            data.p_19_peak_propulsive_power === "AI" ||
            data.p_19_peak_propulsive_power === "ART"
              ? "NA"
              : `${data.p_19_rank}`
          }`,
          x: 140,
          y: ["+", 60],
          fontSize: 16,
          color: rgb(0, 0, 0),
          name: "p_19_rank",
          posxx: true,
        },
      ],
    ], // page 19
    [], // page 20
    [
      [
        {
          value: `${
            data.p_21_peak_rotational_power === null ||
            data.p_21_peak_rotational_power === 0 ||
            data.p_21_peak_rotational_power === "EF" ||
            data.p_21_peak_rotational_power === "AI" ||
            data.p_21_peak_rotational_power === "ART"
              ? "NA"
              : `${data.p_21_peak_rotational_power}`
          }`,
          x: 715,
          y: ["+", 205],
          fontSize: 12,
          color: rgb(0, 0, 0),
          name: "p21_rm_power",
        },
        {
          value: `${
            data.p_21_peak_rotational_power === null ||
            data.p_21_peak_rotational_power === 0 ||
            data.p_21_peak_rotational_power === "EF" ||
            data.p_21_peak_rotational_power === "AI" ||
            data.p_21_peak_rotational_power === "ART"
              ? "NA"
              : `${data.p_21_peak_rotational_power_average}`
          }`,
          x: 820,
          y: ["+", 205],
          fontSize: 12,
          color: rgb(0, 0, 0),
          name: "p21_age_avg_rm_power_sum",
        },
        {
          value: `${
            data.p_21_peak_rotational_power === null ||
            data.p_21_peak_rotational_power === 0 ||
            data.p_21_peak_rotational_power === "EF" ||
            data.p_21_peak_rotational_power === "AI" ||
            data.p_21_peak_rotational_power === "ART"
              ? "NA"
              : `${data.p_21_rank}`
          }`,
          x: 150,
          y: ["+", 50],
          fontSize: 16,
          color: rgb(0, 0, 0),
          name: "p_21_rank",
          posxx: true,
        },
      ],
    ], // page 21
    [], // page 22
    [
      [
        {
          value: `${
            data.p_23_chin_ups === null ||
            // data.p_23_chin_ups === 0 ||
            data.p_23_chin_ups === "EF" ||
            data.p_23_chin_ups === "AI" ||
            data.p_23_chin_ups === "ART"
              ? "NA"
              : `${data.p_23_chin_ups}`
          }`,
          x: 500,
          y: ["+", 165],
          fontSize: 18,
          color: rgb(0, 0, 0),
          name: "p23_se_chin_ups",
        },
        {
          value: `${
            data.p_23_chin_ups === null ||
            // data.p_23_chin_ups === 0 ||
            data.p_23_chin_ups === "EF" ||
            data.p_23_chin_ups === "AI" ||
            data.p_23_chin_ups === "ART"
              ? "NA"
              : `${data.p_23_chin_ups_average}`
          }`,
          x: 700,
          y: ["+", 165],
          fontSize: 18,
          color: rgb(0, 0, 0),
          name: "p23_age_avg_se_chin_ups_sum",
        },
      ],
      [
        {
          value: `${
            data.p_23_push_ups === null ||
            // data.p_23_push_ups === 0 ||
            data.p_23_push_ups === "EF" ||
            data.p_23_push_ups === "AI" ||
            data.p_23_push_ups === "ART"
              ? "NA"
              : `${data.p_23_push_ups}`
          }`,
          x: 500,
          y: ["+", 80],
          fontSize: 18,
          color: rgb(0, 0, 0),
          name: "p23_se_pull_ups",
        },
        {
          value: `${
            data.p_23_push_ups === null ||
            // data.p_23_push_ups === 0 ||
            data.p_23_push_ups === "EF" ||
            data.p_23_push_ups === "AI" ||
            data.p_23_push_ups === "ART"
              ? "NA"
              : `${data.p_23_push_ups_average}`
          }`,
          x: 700,
          y: ["+", 80],
          fontSize: 18,
          color: rgb(0, 0, 0),
          name: "p23_age_avg_se_pull_ups_sum",
        },
      ],
    ], // page 23
    [], // page 24
    [
      [
        {
          value: `${
            data.p_25_5_10_5_agility === null ||
            data.p_25_5_10_5_agility === 0 ||
            data.p_25_5_10_5_agility === "EF" ||
            data.p_25_5_10_5_agility === "AI" ||
            data.p_25_5_10_5_agility === "ART"
              ? "NA"
              : `${data.p_25_5_10_5_agility}`
          }`,
          x: 715,
          y: ["+", 180],
          fontSize: 12,
          color: rgb(0, 0, 0),
          name: "p25_cod_power",
        },
        {
          value: `${
            data.p_25_5_10_5_agility === null ||
            data.p_25_5_10_5_agility === 0 ||
            data.p_25_5_10_5_agility === "EF" ||
            data.p_25_5_10_5_agility === "AI" ||
            data.p_25_5_10_5_agility === "ART"
              ? "NA"
              : `${data.p_25_5_10_5_agility_average}`
          }`,
          x: 815,
          y: ["+", 180],
          fontSize: 12,
          color: rgb(0, 0, 0),
          name: "p25_age_avg_cod_power_sum",
        },
        {
          value: `${
            data.p_25_5_10_5_agility === null ||
            data.p_25_5_10_5_agility === 0 ||
            data.p_25_5_10_5_agility === "EF" ||
            data.p_25_5_10_5_agility === "AI" ||
            data.p_25_5_10_5_agility === "ART"
              ? "NA"
              : `${data.p_25_rank}`
          }`,
          x: 145,
          y: ["+", 43],
          fontSize: 16,
          color: rgb(0, 0, 0),
          name: "p_25_rank",
          posxx: true,
        },
      ],
    ], // page 25
    [
      [
        {
          value: `${
            data.p_26_reactive_strength_index === null ||
            data.p_26_reactive_strength_index === 0 ||
            data.p_26_reactive_strength_index === "EF" ||
            data.p_26_reactive_strength_index === "AI" ||
            data.p_26_reactive_strength_index === "ART"
              ? "NA"
              : `${data.p_26_reactive_strength_index}`
          }`,
          x: 325,
          y: ["+", 67],
          fontSize: 18,
          color: rgb(0, 0, 0),
          name: "p26_cmj_rsi_no",
        },
        {
          value: `${data && data.p_26_dynamic_strength_index}`,
          x: 510,
          y: ["+", 67],
          fontSize: 18,
          color: rgb(0, 0, 0),
          name: "p26_dsi_score",
        },
        {
          value: `${
            data.p_26_eccentric_strength_index === null ||
            data.p_26_eccentric_strength_index === 0 ||
            data.p_26_eccentric_strength_index === "EF" ||
            data.p_26_eccentric_strength_index === "AI" ||
            data.p_26_eccentric_strength_index === "ART"
              ? "NA"
              : `${data.p_26_eccentric_strength_index}`
          }`,
          x: 700,
          y: ["+", 67],
          fontSize: 18,
          color: rgb(0, 0, 0),
          name: "p26_eur",
        },
        {
          value: `${
            data.p_26_relative_strength_index === null ||
            data.p_26_relative_strength_index === 0 ||
            data.p_26_relative_strength_index === "EF" ||
            data.p_26_relative_strength_index === "AI" ||
            data.p_26_relative_strength_index === "ART"
              ? "NA"
              : `${data.p_26_relative_strength_index}x`
          }`,
          x: 890,
          y: ["+", 67],
          fontSize: 18,
          color: rgb(0, 0, 0),
          name: "p26_ms_peak_force",
        },
      ],
      [
        {
          value: `${
            data.p_26_reactive_strength_index === null ||
            data.p_26_reactive_strength_index === 0 ||
            data.p_26_reactive_strength_index === "EF" ||
            data.p_26_reactive_strength_index === "AI" ||
            data.p_26_reactive_strength_index === "ART"
              ? "NA"
              : `${data.p_26_reactive_strength_index_average}`
          }`,
          x: 325,
          y: ["-", 40],
          fontSize: 18,
          color: rgb(0, 0, 0),
          name: "p26_age_avg_cmj_rsi_no_sum",
        },
        {
          value: `${data && data.p_26_dynamic_strength_index_average}`,
          x: 510,
          y: ["-", 40],
          fontSize: 18,
          color: rgb(0, 0, 0),
          name: "p26_dsi_score_avg_tot",
        },
        {
          value: `${data && data.p_26_eccentric_strength_index_average}`,
          x: 700,
          y: ["-", 40],
          fontSize: 18,
          color: rgb(0, 0, 0),
          name: "p26_avg_eccentric_utilization_ratio",
        },
        {
          value: `${
            data.p_26_relative_strength_index === null ||
            data.p_26_relative_strength_index === 0 ||
            data.p_26_relative_strength_index === "EF" ||
            data.p_26_relative_strength_index === "AI" ||
            data.p_26_relative_strength_index === "ART"
              ? "NA"
              : `${data.p_26_relative_strength_index_average}x`
          }`,
          x: 890,
          y: ["-", 40],
          fontSize: 18,
          color: rgb(0, 0, 0),
          name: "p26_avg_relative_strength",
        },
      ],
      [
        {
          value: `${
            data.p_26_reactive_strength_index === null ||
            data.p_26_reactive_strength_index === 0 ||
            data.p_26_reactive_strength_index === "EF" ||
            data.p_26_reactive_strength_index === "AI" ||
            data.p_26_reactive_strength_index === "ART"
              ? ""
              : `${data.p_26_reactive_strength_index_benchmark}`
          }`,
          x: 325,
          y: ["-", 138],
          fontSize: 18,
          color: rgb(0, 0, 0),
          name: "p26_average_1",
        },
        {
          value: `${data && data.p_26_dynamic_strength_index_benchmark}`,
          x: 510,
          y: ["-", 155],
          fontSize: 18,
          color: rgb(0, 0, 0),
          name: "p26_average_2",
        },
        {
          value: `${data && data.p_26_eccentric_strength_index_benchmark}`,
          x: 700,
          y: ["-", 155],
          fontSize: 18,
          color: rgb(0, 0, 0),
          name: "p26_average_3",
        },
        {
          value: `${
            data.p_26_relative_strength_index === null ||
            data.p_26_relative_strength_index === 0 ||
            data.p_26_relative_strength_index === "EF" ||
            data.p_26_relative_strength_index === "AI" ||
            data.p_26_relative_strength_index === "ART"
              ? ""
              : `${data.p_26_relative_strength_index_benchmark}`
          }`,
          x: 890,
          y: ["-", 138],
          fontSize: 18,
          color: rgb(0, 0, 0),
          name: "p26_average_4",
        },
      ],
      [
        {
          value: `${
            data.p_26_reactive_strength_index === null ||
            data.p_26_reactive_strength_index === 0 ||
            data.p_26_reactive_strength_index === "EF" ||
            data.p_26_reactive_strength_index === "AI" ||
            data.p_26_reactive_strength_index === "ART"
              ? ""
              : `PERCENTILE`
          }`,
          x: 325,
          y: ["-", 160],
          fontSize: 18,
          color: rgb(0, 0, 0),
          name: "p26_average_1",
        },
        {
          value: `${
            data.p_26_relative_strength_index === null ||
            data.p_26_relative_strength_index === 0 ||
            data.p_26_relative_strength_index === "EF" ||
            data.p_26_relative_strength_index === "AI" ||
            data.p_26_relative_strength_index === "ART"
              ? ""
              : `PERCENTILE`
          }`,
          x: 890,
          y: ["-", 160],
          fontSize: 18,
          color: rgb(0, 0, 0),
          name: "p26_average_4",
        },
      ],
      [
        {
          value: `${
            data.p_26_reactive_strength_index === null ||
            data.p_26_reactive_strength_index === 0 ||
            data.p_26_reactive_strength_index === "EF" ||
            data.p_26_reactive_strength_index === "AI" ||
            data.p_26_reactive_strength_index === "ART"
              ? "NA"
              : ``
          }`,
          x: 325,
          y: ["-", 148],
          fontSize: 18,
          color: rgb(0, 0, 0),
          name: "p26_average_1",
        },
        {
          value: `${
            data.p_26_relative_strength_index === null ||
            data.p_26_relative_strength_index === 0 ||
            data.p_26_relative_strength_index === "EF" ||
            data.p_26_relative_strength_index === "AI" ||
            data.p_26_relative_strength_index === "ART"
              ? "NA"
              : ``
          }`,
          x: 890,
          y: ["-", 148],
          fontSize: 18,
          color: rgb(0, 0, 0),
          name: "p26_average_4",
        },
      ],
    ], // page 26
    [], // page 27
  ];

  // Load a PDFDocument from the existing PDF bytes
  const pdfDoc = await PDFDocument.load(pdfUrl);
  // console.log("pdfDoc", pdfDoc);

  // Embed the HelveticaBold font
  const helveticaFont = await pdfDoc.embedFont(StandardFonts.HelveticaBold);

  // Get the first page of the document
  const pages = pdfDoc.getPages();
  // console.log("pages", pages);

  for (let p = 0; p < pages.length; p++) {
    if (dataRender && dataRender[p]?.length > 0) {
      const {
        // width,
        height,
      } = pages[p].getSize();
      for (let x = 0; x < dataRender[p].length; x++) {
        let drpx = dataRender[p][x];

        for (let y = 0; y < drpx.length; y++) {
          let drpxy = drpx[y];
          let yVal = 0;

          if (drpxy.y[0] === "+") {
            yVal = height / 2 + drpxy.y[1];
          } else {
            yVal = height / 2 - drpxy.y[1];
          }

          //   const HelveticaBold = await pdfDoc.embedFont(StandardFonts.HelveticaBold);
          const textWidth = helveticaFont.widthOfTextAtSize(
            drpxy.value,
            drpxy.fontSize
          );

          let subtractXPos = textWidth / 2;

          if (display_data && display_data.length > 0) {
            let show_data = display_data.filter(
              (itemFilter) => itemFilter.name === drpxy.name
            );

            let show = 1;
            if (show_data && show_data.length > 0) {
              show = show_data[0].status;
            }

            if (show) {
              pages[p].drawText(`${drpxy.value}`, {
                x: drpxy.posxx ? drpxy.x : drpxy.x - subtractXPos,
                y: yVal,
                size: drpxy.fontSize,
                font: helveticaFont,
                color: drpxy.color,
                // rotate: drpxy.degrees(drpxy.degrees),
              });
            }
          } else {
            pages[p].drawText(`${drpxy.value}`, {
              x: drpxy.posxx ? drpxy.x : drpxy.x - subtractXPos,
              y: yVal,
              size: drpxy.fontSize,
              font: helveticaFont,
              color: drpxy.color,
              // rotate: drpxy.degrees(drpxy.degrees),
            });
          }
        }
      }
    }
  }

  // Serialize the PDFDocument to bytes (a Uint8Array)
  const pdfBytes = await pdfDoc.save();
  download(
    pdfBytes,
    // "2022-June-BFS-Elite-New-Report-BLANK.pdf",
    `${moment().format("YYYY")}-BFS-New-Elite-Report-${data.name}.pdf`,
    "application/pdf"
  );
};

export default pdfElite;
