import { Col, Modal, Row } from "antd";

export default function ModalPreviewAssessmentStats(props) {
	const { toggleModalPreviewAssessment, setToggleModalPreviewAssessment } =
		props;

	return (
		<Modal
			title="Preview"
			visible={toggleModalPreviewAssessment.toggle}
			onCancel={() =>
				setToggleModalPreviewAssessment({ toggle: false, data: null })
			}
			okButtonProps={{ style: { display: "none" } }}
		>
			<Row>
				<Col xs={24} sm={24} md={12}></Col>
			</Row>
		</Modal>
	);
}
