import { useState } from "react";
import { Card, Col, notification, Row, Switch } from "antd";
import { faUserTie } from "@fortawesome/pro-regular-svg-icons";
import ComponentHeader from "../../Components/ComponentHeader";
import { GET, POST } from "../../../../providers/useAxiosQuery";
import companyInfo from "../../../../providers/companyInfo";

const userData = companyInfo().userData;

export default function PageProfileManager() {
	const [editPermission, setEditPermission] = useState(0);
	GET(
		`api/v1/user_athlete_info?id=${userData.id}`,
		"athletic_user_athlete_info",
		(res) => {
			console.log("user_athlete_info res", res.data);
			if (res.data) {
				let data = res.data;

				setEditPermission(data.edit_permission === "Yes" ? true : false);
			}
		}
	);

	const {
		mutate: mutateUserAthleteEditPermission,
		// isLoading: isLoadingUserAthleteEditPermission,
	} = POST("api/v1/edit_permission", "user_athlete_edit_permission");

	const onChange = (checked) => {
		console.log(`switch to ${checked}`);

		let data = {
			id: userData.id,
			edit_permission: checked ? "Yes" : "No",
		};

		mutateUserAthleteEditPermission(data, {
			onSuccess: (res) => {
				if (res.success) {
					notification.success({
						message: res.message,
						description: res.description,
					});
					setEditPermission(checked);
				} else {
					notification.success({
						message: res.message,
						description: res.description,
					});
				}
			},
		});
	};

	return (
		<>
			<ComponentHeader title="Profile" sub_title="EDIT" icon={faUserTie} />

			<Card>
				<Col xs={24} sm={24} md={14}>
					<Card className="card-dark-head" title="ATHLETE'S INFORMATION">
						<Row gutter={12}>
							<Col xs={24} sm={24} md={24} lg={16} className="m-t-sm">
								Enable or disabled to allow BFSS Manager access to your account
							</Col>
							<Col xs={24} sm={24} md={24} lg={8} className="m-t-sm text-right">
								<Switch checked={editPermission} onChange={onChange} />
							</Col>
						</Row>
					</Card>
				</Col>
			</Card>
		</>
	);
}
