import React from "react";

import { Card, Col, Row } from "antd";
import ComponentHeader from "../Components/ComponentHeader";
import { faLock } from "@fortawesome/pro-solid-svg-icons";

export default function PageTermAndCondition({ match, permission }) {
  return (
    <>
      <ComponentHeader
        title="Policy"
        sub_title="TERMS AND CONDITIONS"
        icon={faLock}
      />

      <Card id="PageTermAndCondition">
        <Row gutter={12}>
          <Col md={24}>
            <span>
              <b>1. Terms</b>
            </span>
            <br />
            <span>
              By accessing this web site, you are agreeing to be bound by these
              web site Terms and Conditions of Use, all applicable laws and
              regulations, and agree that you are responsible for compliance
              with any applicable local laws. If you do not agree with any of
              these terms, you are prohibited from using or accessing this site.
              The materials contained in this web site are protected by
              applicable copyright and trade mark law.
            </span>

            <br />
            <br />
            <span>
              <b>2. Use License</b>
            </span>
            <br />
            <span>
              Permission is granted to temporarily download one copy of the
              materials (information or software) on Better Faster Stronger
              Science's (BFSS's) web site for personal, non-commercial
              transitory viewing only. This is the grant of a license, not a
              transfer of title, and under this license you may not:
            </span>
            <br />
            <p className="m-l-sm p-t-sm">
              1. remove any copyright or other proprietary notations from the
              materials; or
              <br />
              2. attempt to decompile or reverse engineer any software contained
              on BFSS's web site;
              <br />
              3. use the materials for any commercial purpose, or for any public
              display (commercial or non-commercial);
              <br />
              4. modify or copy the materials;
              <br />
              5. transfer the materials to another person or “mirror” the
              materials on any other server.
              <br />
              6. This license shall automatically terminate if you violate any
              of these restrictions and may be terminated by BFSS at any time.
              Upon terminating your viewing of these materials or upon the
              termination of this license, you must destroy any downloaded
              materials in your possession whether in electronic or printed
              format.
            </p>

            <span>
              <b>3. Disclaimer</b>
            </span>
            <br />
            <span>
              The materials on BFSS web site are provided “as is”. BFSS makes no
              warranties, expressed or implied, and hereby disclaims and negates
              all other warranties, including without limitation, implied
              warranties or conditions of merchantability, fitness for a
              particular purpose, or non-infringement of intellectual property
              or other violation of rights. Further, BFSS does not warrant or
              make any representations concerning the accuracy, likely results,
              or reliability of the use of the materials on its Internet web
              site or otherwise relating to such materials or on any sites
              linked to this site.
            </span>

            <br />
            <br />
            <span>
              <b>4. Limitations</b>
            </span>
            <br />
            <span>
              In no event shall BFSS or its suppliers be liable for any damages
              (including, without limitation, damages for loss of data or
              profit, or due to business interruption,) arising out of the use
              or inability to use the materials on Internet site, even BFSS if
              BFSS or a BFSS authorized representative has been notified orally
              or in writing of the possibility of such damage. Because some
              jurisdictions do not allow limitations on implied warranties, or
              limitations of liability for consequential or incidental damages,
              these limitations may not apply to you.
            </span>

            <br />
            <br />
            <span>
              <b>5. Revisions and Errata</b>
            </span>
            <br />
            <span>
              The materials appearing on BFSS's web site could include
              technical, typographical, or photographic errors. BFSS does not
              warrant that any of the materials on its web site are accurate,
              complete, or current. BFSS may make changes to the materials
              contained on its web site at any time without notice. BFSS does
              not, however, make any commitment to update the materials.
            </span>

            <br />
            <br />
            <span>
              <b>6. Links</b>
            </span>
            <br />
            <span>
              BFSS has not reviewed all of the sites linked to its Internet web
              site and is not responsible for the contents of any such linked
              site. The inclusion of any link does not imply endorsement by BFSS
              of the site. Use of any such linked web site is at the user’s own
              risk.
            </span>

            <br />
            <br />
            <span>
              <b>7. Site Terms of Use Modifications</b>
            </span>
            <br />
            <span>
              BFSS may revise these terms of use for its web site at any time
              without notice. By using this web site you are agreeing to be
              bound by the then current version of these Terms and Conditions of
              Use.
            </span>

            <br />
            <br />
            <span>
              <b>8. Governing Law</b>
            </span>
            <br />
            <span>
              Any claim relating to BFSS web site shall be governed by the laws
              of the State of Arizona without regard to its conflict of law
              provisions.
            </span>

            <br />
            <br />
            <br />
            <br />
            <br />
          </Col>
        </Row>
      </Card>
    </>
  );
}
