import {
  Button,
  Card,
  Col,
  Collapse,
  notification,
  Row,
  Space,
  Table,
  Form,
  Divider,
  Typography,
} from "antd";
import React, { useState, useEffect } from "react";
// import { useHistory } from "react-router-dom";
// import $ from "jquery";

import { faFilePdf } from "@fortawesome/free-solid-svg-icons";
import FloatInput from "../../../../../providers/FloatInput";
import FloatSelect from "../../../../../providers/FloatSelect";
import { GETMANUAL, POST } from "../../../../../providers/useAxiosQuery";

export default function PageElite({ pdfData, pdfID }) {
  // const history = useHistory();
  // const userdata = companyInfo().userData;

  //   const pdf_data = JSON.parse(pdfData.pdf_data);
  const [form] = Form.useForm();

  useEffect(() => {
    if (pdfData) {
      refetchData();
    }
  }, [pdfID]);

  const [PDFData, setPDFData] = useState();
  const [typeData, setTypeData] = useState("");
  const { refetch: refetchData } = GETMANUAL(
    `api/v1/get_pdf_data_list?id=${pdfID}`,
    "get_data",
    (res) => {
      if (res.success) {
        console.log("pdf_data", res);
        let pdf_data = JSON.parse(res.data.pdf_data);
        setPDFData(pdf_data);
        setTypeData(res.data);

        form.setFieldsValue({
          p_3_rebound_jump:
            pdf_data.p_3_rebound_jump === 0
              ? Number(pdf_data.p_3_rebound_jump).toFixed()
              : pdf_data.p_3_rebound_jump,
          p_3_countermovement_jump:
            pdf_data.p_3_countermovement_jump === 0
              ? Number(pdf_data.p_3_countermovement_jump).toFixed()
              : pdf_data.p_3_countermovement_jump,
          p_3_squat_jump:
            pdf_data.p_3_squat_jump === 0
              ? Number(pdf_data.p_3_squat_jump).toFixed()
              : pdf_data.p_3_squat_jump,
          p_3_ten_yard_sprint_jump:
            pdf_data.p_3_ten_yard_sprint_jump === 0
              ? Number(pdf_data.p_3_ten_yard_sprint_jump).toFixed()
              : pdf_data.p_3_ten_yard_sprint_jump,
          p_3_isometric_mid_thigh_pull:
            pdf_data.p_3_isometric_mid_thigh_pull === 0
              ? Number(pdf_data.p_3_isometric_mid_thigh_pull).toFixed()
              : pdf_data.p_3_isometric_mid_thigh_pull,

          p_3_rebound_jump_average:
            pdf_data.p_3_rebound_jump_average === 0
              ? Number(pdf_data.p_3_rebound_jump_average).toFixed()
              : pdf_data.p_3_rebound_jump_average,
          p_3_countermovement_jump_average:
            pdf_data.p_3_countermovement_jump_average === 0
              ? Number(pdf_data.p_3_countermovement_jump_average).toFixed()
              : pdf_data.p_3_countermovement_jump_average,
          p_3_squat_jump_average:
            pdf_data.p_3_squat_jump_average === 0
              ? Number(pdf_data.p_3_squat_jump_average).toFixed()
              : pdf_data.p_3_squat_jump_average,
          p_3_ten_yard_sprint_jump_average:
            pdf_data.p_3_ten_yard_sprint_jump_average === 0
              ? Number(pdf_data.p_3_ten_yard_sprint_jump_average).toFixed()
              : pdf_data.p_3_ten_yard_sprint_jump_average,
          p_3_isometric_mid_thigh_pull_average:
            pdf_data.p_3_isometric_mid_thigh_pull_average === 0
              ? Number(pdf_data.p_3_isometric_mid_thigh_pull_average).toFixed()
              : pdf_data.p_3_isometric_mid_thigh_pull_average,

          p_3_rebound_jump_benchmark:
            pdf_data.p_3_rebound_jump_benchmark === 0
              ? Number(pdf_data.p_3_rebound_jump_benchmark).toFixed()
              : pdf_data.p_3_rebound_jump_benchmark,
          p_3_countermovement_jump_benchmark:
            pdf_data.p_3_countermovement_jump_benchmark === 0
              ? Number(pdf_data.p_3_countermovement_jump_benchmark).toFixed()
              : pdf_data.p_3_countermovement_jump_benchmark,
          p_3_squat_jump_benchmark:
            pdf_data.p_3_squat_jump_benchmark === 0
              ? Number(pdf_data.p_3_squat_jump_benchmark).toFixed()
              : pdf_data.p_3_squat_jump_benchmark,
          p_3_ten_yard_sprint_jump_benchmark:
            pdf_data.p_3_ten_yard_sprint_jump_benchmark === 0
              ? Number(pdf_data.p_3_ten_yard_sprint_jump_benchmark).toFixed()
              : pdf_data.p_3_ten_yard_sprint_jump_benchmark,
          p_3_isometric_mid_thigh_pull_benchmark:
            pdf_data.p_3_isometric_mid_thigh_pull_benchmark === 0
              ? Number(
                  pdf_data.p_3_isometric_mid_thigh_pull_benchmark
                ).toFixed()
              : pdf_data.p_3_isometric_mid_thigh_pull_benchmark,

          p_4_static_med_ball_throw:
            pdf_data.p_4_static_med_ball_throw === 0
              ? Number(pdf_data.p_4_static_med_ball_throw).toFixed()
              : pdf_data.p_4_static_med_ball_throw,
          p_4_rational_med_throw:
            pdf_data.p_4_rational_med_throw === 0
              ? Number(pdf_data.p_4_rational_med_throw).toFixed()
              : pdf_data.p_4_rational_med_throw,
          p_4_push_ups:
            pdf_data.p_4_push_ups === 0
              ? Number(pdf_data.p_4_push_ups).toFixed()
              : pdf_data.p_4_push_ups,
          p_4_chin_ups:
            pdf_data.p_4_chin_ups === 0
              ? Number(pdf_data.p_4_chin_ups).toFixed()
              : pdf_data.p_4_chin_ups,
          p_4_5_10_5_agility_test:
            pdf_data.p_4_5_10_5_agility_test === 0
              ? Number(pdf_data.p_4_5_10_5_agility_test).toFixed()
              : pdf_data.p_4_5_10_5_agility_test,

          p_4_static_med_ball_throw_average:
            pdf_data.p_4_static_med_ball_throw_average === 0
              ? Number(pdf_data.p_4_static_med_ball_throw_average).toFixed()
              : pdf_data.p_4_static_med_ball_throw_average,
          p_4_rational_med_throw_average:
            pdf_data.p_4_rational_med_throw_average === 0
              ? Number(pdf_data.p_4_rational_med_throw_average).toFixed()
              : pdf_data.p_4_rational_med_throw_average,
          p_4_push_ups_average:
            pdf_data.p_4_push_ups_average === 0
              ? Number(pdf_data.p_4_push_ups_average).toFixed()
              : pdf_data.p_4_push_ups_average,
          p_4_chin_ups_average:
            pdf_data.p_4_chin_ups_average === 0
              ? Number(pdf_data.p_4_chin_ups_average).toFixed()
              : pdf_data.p_4_chin_ups_average,
          p_4_5_10_5_agility_test_average:
            pdf_data.p_4_5_10_5_agility_test_average === 0
              ? Number(pdf_data.p_4_5_10_5_agility_test_average).toFixed()
              : pdf_data.p_4_5_10_5_agility_test_average,

          p_4_static_med_ball_throw_benchmark:
            pdf_data.p_4_static_med_ball_throw_benchmark === 0
              ? Number(pdf_data.p_4_static_med_ball_throw_benchmark).toFixed()
              : pdf_data.p_4_static_med_ball_throw_benchmark,
          p_4_rational_med_throw_benchmark:
            pdf_data.p_4_rational_med_throw_benchmark === 0
              ? Number(pdf_data.p_4_rational_med_throw_benchmark).toFixed()
              : pdf_data.p_4_rational_med_throw_benchmark,
          p_4_push_ups_benchmark:
            pdf_data.p_4_push_ups_benchmark === 0
              ? Number(pdf_data.p_4_push_ups_benchmark).toFixed()
              : pdf_data.p_4_push_ups_benchmark,
          p_4_chin_ups_benchmark:
            pdf_data.p_4_chin_ups_benchmark === 0
              ? Number(pdf_data.p_4_chin_ups_benchmark).toFixed()
              : pdf_data.p_4_chin_ups_benchmark,
          p_4_5_10_5_agility_test_benchmark:
            pdf_data.p_4_5_10_5_agility_test_benchmark === 0
              ? Number(pdf_data.p_4_5_10_5_agility_test_benchmark).toFixed()
              : pdf_data.p_4_5_10_5_agility_test_benchmark,

          p_6_rsi_score:
            pdf_data.p_6_rsi_score === 0
              ? Number(pdf_data.p_6_rsi_score).toFixed()
              : pdf_data.p_6_rsi_score,
          p_6_jump_height:
            pdf_data.p_6_jump_height === 0
              ? Number(pdf_data.p_6_jump_height).toFixed()
              : pdf_data.p_6_jump_height,
          p_6_ground_contact_time:
            pdf_data.p_6_ground_contact_time === 0
              ? Number(pdf_data.p_6_ground_contact_time).toFixed()
              : pdf_data.p_6_ground_contact_time,
          p_6_rank:
            pdf_data.p_6_rank === 0
              ? Number(pdf_data.p_6_rank).toFixed()
              : pdf_data.p_6_rank,

          p_8_cmj_height:
            pdf_data.p_8_cmj_height === 0
              ? Number(pdf_data.p_8_cmj_height).toFixed()
              : pdf_data.p_8_cmj_height,
          p_8_cmj_height_average:
            pdf_data.p_8_cmj_height_average === 0
              ? Number(pdf_data.p_8_cmj_height_average).toFixed()
              : pdf_data.p_8_cmj_height_average,
          p_8_relative_avg_power:
            pdf_data.p_8_relative_avg_power === 0
              ? Number(pdf_data.p_8_relative_avg_power).toFixed()
              : pdf_data.p_8_relative_avg_power,
          p_8_relative_avg_power_average:
            pdf_data.p_8_relative_avg_power_average === 0
              ? Number(pdf_data.p_8_relative_avg_power_average).toFixed()
              : pdf_data.p_8_relative_avg_power_average,
          p_8_rank:
            pdf_data.p_8_rank === 0
              ? Number(pdf_data.p_8_rank).toFixed()
              : pdf_data.p_8_rank,

          p_10_j_height:
            pdf_data.p_10_j_height === 0
              ? Number(pdf_data.p_10_j_height).toFixed()
              : pdf_data.p_10_j_height,
          p_10_j_height_average:
            pdf_data.p_10_j_height_average === 0
              ? Number(pdf_data.p_10_j_height_average).toFixed()
              : pdf_data.p_10_j_height_average,
          p_10_realative_peak_power:
            pdf_data.p_10_realative_peak_power === 0
              ? Number(pdf_data.p_10_realative_peak_power).toFixed()
              : pdf_data.p_10_realative_peak_power,
          p_10_realative_peak_power_average:
            pdf_data.p_10_realative_peak_power_average === 0
              ? Number(pdf_data.p_10_realative_peak_power_average).toFixed()
              : pdf_data.p_10_realative_peak_power_average,
          p_10_rank:
            pdf_data.p_10_rank === 0
              ? Number(pdf_data.p_10_rank).toFixed()
              : pdf_data.p_10_rank,

          p_11_your_eur_score:
            pdf_data.p_11_your_eur_score === 0
              ? Number(pdf_data.p_11_your_eur_score).toFixed()
              : pdf_data.p_11_your_eur_score,

          p_13_10_yard_sprint:
            pdf_data.p_13_10_yard_sprint === 0
              ? Number(pdf_data.p_13_10_yard_sprint).toFixed()
              : pdf_data.p_13_10_yard_sprint,
          p_13_10_yard_sprint_average:
            pdf_data.p_13_10_yard_sprint_average === 0
              ? Number(pdf_data.p_13_10_yard_sprint_average).toFixed()
              : pdf_data.p_13_10_yard_sprint_average,
          p_13_40_yard_sprint:
            pdf_data.p_13_40_yard_sprint === 0
              ? Number(pdf_data.p_13_40_yard_sprint).toFixed()
              : pdf_data.p_13_40_yard_sprint,
          p_13_40_yard_sprint_average:
            pdf_data.p_13_40_yard_sprint_average === 0
              ? Number(pdf_data.p_13_40_yard_sprint_average).toFixed()
              : pdf_data.p_13_40_yard_sprint_average,
          p_13_rank:
            pdf_data.p_13_rank === 0
              ? Number(pdf_data.p_13_rank).toFixed()
              : pdf_data.p_13_rank,

          p_15_absolute_strenght:
            pdf_data.p_15_absolute_strenght === 0
              ? Number(pdf_data.p_15_absolute_strenght).toFixed()
              : pdf_data.p_15_absolute_strenght,
          p_15_absolute_strenght_pounds:
            pdf_data.p_15_absolute_strenght_pounds === 0
              ? Number(pdf_data.p_15_absolute_strenght_pounds).toFixed()
              : pdf_data.p_15_absolute_strenght_pounds,
          p_15_absolute_strenght_average_1:
            pdf_data.p_15_absolute_strenght_average_1 === 0
              ? Number(pdf_data.p_15_absolute_strenght_average_1).toFixed()
              : pdf_data.p_15_absolute_strenght_average_1,
          p_15_absolute_strenght_average_2:
            pdf_data.p_15_absolute_strenght_average_2 === 0
              ? Number(pdf_data.p_15_absolute_strenght_average_2).toFixed()
              : pdf_data.p_15_absolute_strenght_average_2,

          p_15_relative_strength_peak_force_trength:
            pdf_data.p_15_relative_strength_peak_force_trength === 0
              ? Number(
                  pdf_data.p_15_relative_strength_peak_force_trength
                ).toFixed()
              : pdf_data.p_15_relative_strength_peak_force_trength,
          p_15_relative_strength_peak_force_trength_average:
            pdf_data.p_15_relative_strength_peak_force_trength_average === 0
              ? Number(
                  pdf_data.p_15_relative_strength_peak_force_trength_average
                ).toFixed()
              : pdf_data.p_15_relative_strength_peak_force_trength_average,
          p_15_rank:
            pdf_data.p_15_rank === 0
              ? Number(pdf_data.p_15_rank).toFixed()
              : pdf_data.p_15_rank,

          p_16_peak_force_pounds:
            pdf_data.p_16_peak_force_pounds === 0
              ? Number(pdf_data.p_16_peak_force_pounds).toFixed()
              : pdf_data.p_16_peak_force_pounds,
          p_16_weight:
            pdf_data.p_16_weight === 0
              ? Number(pdf_data.p_16_weight).toFixed()
              : pdf_data.p_16_weight,
          p_16_peak_force_body_weight:
            pdf_data.p_16_peak_force_body_weight === 0
              ? Number(pdf_data.p_16_peak_force_body_weight).toFixed()
              : pdf_data.p_16_peak_force_body_weight,
          p_16_force_0_100_ms:
            pdf_data.p_16_force_0_100_ms === 0
              ? Number(pdf_data.p_16_force_0_100_ms).toFixed()
              : pdf_data.p_16_force_0_100_ms,
          p_16_0_100_ms_rfd:
            pdf_data.p_16_0_100_ms_rfd === 0
              ? Number(pdf_data.p_16_0_100_ms_rfd).toFixed()
              : pdf_data.p_16_0_100_ms_rfd,

          p_17_imtp_peak_force:
            pdf_data.p_17_imtp_peak_force === 0
              ? Number(pdf_data.p_17_imtp_peak_force).toFixed()
              : pdf_data.p_17_imtp_peak_force,
          p_17_cmj_peak_force:
            pdf_data.p_17_cmj_peak_force === 0
              ? Number(pdf_data.p_17_cmj_peak_force).toFixed()
              : pdf_data.p_17_cmj_peak_force,
          p_17_dsi_score:
            pdf_data.p_17_dsi_score === 0
              ? Number(pdf_data.p_17_dsi_score).toFixed()
              : pdf_data.p_17_dsi_score,

          p_19_peak_propulsive_power:
            pdf_data.p_19_peak_propulsive_power === 0
              ? Number(pdf_data.p_19_peak_propulsive_power).toFixed()
              : pdf_data.p_19_peak_propulsive_power,
          p_19_peak_propulsive_power_average:
            pdf_data.p_19_peak_propulsive_power_average === 0
              ? Number(pdf_data.p_19_peak_propulsive_power_average).toFixed()
              : pdf_data.p_19_peak_propulsive_power_average,
          p_19_relative_peak_power:
            pdf_data.p_19_relative_peak_power === 0
              ? Number(pdf_data.p_19_relative_peak_power).toFixed()
              : pdf_data.p_19_relative_peak_power,
          p_19_relative_peak_power_average:
            pdf_data.p_19_relative_peak_power_average === 0
              ? Number(pdf_data.p_19_relative_peak_power_average).toFixed()
              : pdf_data.p_19_relative_peak_power_average,
          p_19_rank:
            pdf_data.p_19_rank === 0
              ? Number(pdf_data.p_19_rank).toFixed()
              : pdf_data.p_19_rank,

          p_21_peak_rotational_power:
            pdf_data.p_21_peak_rotational_power === 0
              ? Number(pdf_data.p_21_peak_rotational_power).toFixed()
              : pdf_data.p_21_peak_rotational_power,
          p_21_peak_rotational_power_average:
            pdf_data.p_21_peak_rotational_power_average === 0
              ? Number(pdf_data.p_21_peak_rotational_power_average).toFixed()
              : pdf_data.p_21_peak_rotational_power_average,
          p_21_rank:
            pdf_data.p_21_rank === 0
              ? Number(pdf_data.p_21_rank).toFixed()
              : pdf_data.p_21_rank,

          p_23_chin_ups:
            pdf_data.p_23_chin_ups === 0
              ? Number(pdf_data.p_23_chin_ups).toFixed()
              : pdf_data.p_23_chin_ups,
          p_23_chin_ups_average:
            pdf_data.p_23_chin_ups_average === 0
              ? Number(pdf_data.p_23_chin_ups_average).toFixed()
              : pdf_data.p_23_chin_ups_average,
          p_23_push_ups:
            pdf_data.p_23_push_ups === 0
              ? Number(pdf_data.p_23_push_ups).toFixed()
              : pdf_data.p_23_push_ups,
          p_23_push_ups_average:
            pdf_data.p_23_push_ups_average === 0
              ? Number(pdf_data.p_23_push_ups_average).toFixed()
              : pdf_data.p_23_push_ups_average,

          p_25_5_10_5_agility:
            pdf_data.p_25_5_10_5_agility === 0
              ? Number(pdf_data.p_25_5_10_5_agility).toFixed()
              : pdf_data.p_25_5_10_5_agility,
          p_25_5_10_5_agility_average:
            pdf_data.p_25_5_10_5_agility_average === 0
              ? Number(pdf_data.p_25_5_10_5_agility_average).toFixed()
              : pdf_data.p_25_5_10_5_agility_average,
          p_25_rank:
            pdf_data.p_25_rank === 0
              ? Number(pdf_data.p_25_rank).toFixed()
              : pdf_data.p_25_rank,

          //
          p_26_reactive_strength_index:
            pdf_data.p_26_reactive_strength_index === 0
              ? Number(pdf_data.p_26_reactive_strength_index).toFixed()
              : pdf_data.p_26_reactive_strength_index,
          p_26_dynamic_strength_index:
            pdf_data.p_26_dynamic_strength_index === 0
              ? Number(pdf_data.p_26_dynamic_strength_index).toFixed()
              : pdf_data.p_26_dynamic_strength_index,
          p_26_eccentric_strength_index:
            pdf_data.p_26_eccentric_strength_index === 0
              ? Number(pdf_data.p_26_eccentric_strength_index).toFixed()
              : pdf_data.p_26_eccentric_strength_index,
          p_26_relative_strength_index:
            pdf_data.p_26_relative_strength_index === 0
              ? Number(pdf_data.p_26_relative_strength_index).toFixed()
              : pdf_data.p_26_relative_strength_index,

          p_26_reactive_strength_index_average:
            pdf_data.p_26_reactive_strength_index_average === 0
              ? Number(pdf_data.p_26_reactive_strength_index_average).toFixed()
              : pdf_data.p_26_reactive_strength_index_average,
          p_26_dynamic_strength_index_average:
            pdf_data.p_26_dynamic_strength_index_average === 0
              ? Number(pdf_data.p_26_dynamic_strength_index_average).toFixed()
              : pdf_data.p_26_dynamic_strength_index_average,
          p_26_eccentric_strength_index_average:
            pdf_data.p_26_eccentric_strength_index_average === 0
              ? Number(pdf_data.p_26_eccentric_strength_index_average).toFixed()
              : pdf_data.p_26_eccentric_strength_index_average,
          p_26_relative_strength_index_average:
            pdf_data.p_26_relative_strength_index_average === 0
              ? Number(pdf_data.p_26_relative_strength_index_average).toFixed()
              : pdf_data.p_26_relative_strength_index_average,

          p_26_reactive_strength_index_benchmark:
            pdf_data.p_26_reactive_strength_index_benchmark === 0
              ? Number(
                  pdf_data.p_26_reactive_strength_index_benchmark
                ).toFixed()
              : pdf_data.p_26_reactive_strength_index_benchmark,
          p_26_dynamic_strength_index_benchmark:
            pdf_data.p_26_dynamic_strength_index_benchmark === 0
              ? Number(pdf_data.p_26_dynamic_strength_index_benchmark).toFixed()
              : pdf_data.p_26_dynamic_strength_index_benchmark,
          p_26_eccentric_strength_index_benchmark:
            pdf_data.p_26_eccentric_strength_index_benchmark === 0
              ? Number(
                  pdf_data.p_26_eccentric_strength_index_benchmark
                ).toFixed()
              : pdf_data.p_26_eccentric_strength_index_benchmark,
          p_26_relative_strength_index_benchmark:
            pdf_data.p_26_relative_strength_index_benchmark === 0
              ? Number(
                  pdf_data.p_26_relative_strength_index_benchmark
                ).toFixed()
              : pdf_data.p_26_relative_strength_index_benchmark,
        });
      }
    }
  );

  const onFinish = (values) => {
    let data = {
      ...PDFData,
      p_3_countermovement_jump: values.p_3_countermovement_jump,
      p_3_isometric_mid_thigh_pull: values.p_3_isometric_mid_thigh_pull,
      p_3_rebound_jump: values.p_3_rebound_jump,
      p_3_rebound_jump_average: values.p_3_rebound_jump_average,
      p_3_rebound_jump_benchmark: values.p_3_rebound_jump_benchmark,

      p_3_rebound_jump_average: values.p_3_rebound_jump_average,
      p_3_countermovement_jump_average: values.p_3_countermovement_jump_average,
      p_3_squat_jump_average: values.p_3_squat_jump_average,
      p_3_ten_yard_sprint_jump_average: values.p_3_ten_yard_sprint_jump_average,
      p_3_isometric_mid_thigh_pull_average:
        values.p_3_isometric_mid_thigh_pull_average,

      p_3_rebound_jump_benchmark: values.p_3_rebound_jump_benchmark,
      p_3_countermovement_jump_benchmark:
        values.p_3_countermovement_jump_benchmark,
      p_3_squat_jump_benchmark: values.p_3_squat_jump_benchmark,
      p_3_ten_yard_sprint_jump_benchmark:
        values.p_3_ten_yard_sprint_jump_benchmark,
      p_3_isometric_mid_thigh_pull_benchmark:
        values.p_3_isometric_mid_thigh_pull_benchmark,

      p_4_static_med_ball_throw: values.p_4_static_med_ball_throw,
      p_4_rational_med_throw: values.p_4_rational_med_throw,
      p_4_push_ups: values.p_4_push_ups,
      p_4_chin_ups: values.p_4_chin_ups,
      p_4_5_10_5_agility_test: values.p_4_5_10_5_agility_test,

      p_4_static_med_ball_throw_average:
        values.p_4_static_med_ball_throw_average,
      p_4_rational_med_throw_average: values.p_4_rational_med_throw_average,
      p_4_push_ups_average: values.p_4_push_ups_average,
      p_4_chin_ups_average: values.p_4_chin_ups_average,
      p_4_5_10_5_agility_test_average: values.p_4_5_10_5_agility_test_average,

      p_4_static_med_ball_throw_benchmark:
        values.p_4_static_med_ball_throw_benchmark,
      p_4_rational_med_throw_benchmark: values.p_4_rational_med_throw_benchmark,
      p_4_push_ups_benchmark: values.p_4_push_ups_benchmark,
      p_4_chin_ups_benchmark: values.p_4_chin_ups_benchmark,
      p_4_5_10_5_agility_test_benchmark:
        values.p_4_5_10_5_agility_test_benchmark,

      p_6_rsi_score: values.p_6_rsi_score,
      p_6_jump_height: values.p_6_jump_height,
      p_6_ground_contact_time: values.p_6_ground_contact_time,
      p_6_rank: values.p_6_rank,

      p_8_cmj_height: values.p_8_cmj_height,
      p_8_cmj_height_average: values.p_8_cmj_height_average,
      p_8_relative_avg_power: values.p_8_relative_avg_power,
      p_8_relative_avg_power_average: values.p_8_relative_avg_power_average,
      p_8_rank: values.p_8_rank,

      p_10_j_height: values.p_10_j_height,
      p_10_j_height_average: values.p_10_j_height_average,
      p_10_realative_peak_power: values.p_10_realative_peak_power,
      p_10_realative_peak_power_average:
        values.p_10_realative_peak_power_average,
      p_10_rank: values.p_10_rank,

      p_11_your_eur_score: values.p_11_your_eur_score,

      p_13_10_yard_sprint: values.p_13_10_yard_sprint,
      p_13_10_yard_sprint_average: values.p_13_10_yard_sprint_average,
      p_13_40_yard_sprint: values.p_13_40_yard_sprint,
      p_13_40_yard_sprint_average: values.p_13_40_yard_sprint_average,
      p_13_rank: values.p_13_rank,

      p_15_absolute_strenght: values.p_15_absolute_strenght,
      p_15_absolute_strenght_pounds: values.p_15_absolute_strenght_pounds,
      p_15_absolute_strenght_average_1: values.p_15_absolute_strenght_average_1,
      p_15_absolute_strenght_average_2: values.p_15_absolute_strenght_average_2,

      p_15_relative_strength_peak_force_trength:
        values.p_15_relative_strength_peak_force_trength,
      p_15_relative_strength_peak_force_trength_average:
        values.p_15_relative_strength_peak_force_trength_average,
      p_15_rank: values.p_15_rank,

      p_16_peak_force_pounds: values.p_16_peak_force_pounds,
      p_16_weight: values.p_16_weight,
      p_16_peak_force_body_weight: values.p_16_peak_force_body_weight,
      p_16_force_0_100_ms: values.p_16_force_0_100_ms,
      p_16_0_100_ms_rfd: values.p_16_0_100_ms_rfd,

      p_17_imtp_peak_force: values.p_17_imtp_peak_force,
      p_17_cmj_peak_force: values.p_17_cmj_peak_force,
      p_17_dsi_score: values.p_17_dsi_score,

      p_19_peak_propulsive_power: values.p_19_peak_propulsive_power,
      p_19_peak_propulsive_power_average:
        values.p_19_peak_propulsive_power_average,
      p_19_relative_peak_power: values.p_19_relative_peak_power,
      p_19_relative_peak_power_average: values.p_19_relative_peak_power_average,
      p_19_rank: values.p_19_rank,

      p_21_peak_rotational_power: values.p_21_peak_rotational_power,
      p_21_peak_rotational_power_average:
        values.p_21_peak_rotational_power_average,
      p_21_rank: values.p_21_rank,

      p_23_chin_ups: values.p_23_chin_ups,
      p_23_chin_ups_average: values.p_23_chin_ups_average,
      p_23_push_ups: values.p_23_push_ups,
      p_23_push_ups_average: values.p_23_push_ups_average,

      p_25_5_10_5_agility: values.p_25_5_10_5_agility,
      p_25_5_10_5_agility_average: values.p_25_5_10_5_agility_average,
      p_25_rank: values.p_25_rank,

      //
      p_26_reactive_strength_index: values.p_26_reactive_strength_index,
      p_26_dynamic_strength_index: values.p_26_dynamic_strength_index,
      p_26_eccentric_strength_index: values.p_26_eccentric_strength_index,
      p_26_relative_strength_index: values.p_26_relative_strength_index,

      p_26_reactive_strength_index_average:
        values.p_26_reactive_strength_index_average,
      p_26_dynamic_strength_index_average:
        values.p_26_dynamic_strength_index_average,
      p_26_eccentric_strength_index_average:
        values.p_26_eccentric_strength_index_average,
      p_26_relative_strength_index_average:
        values.p_26_relative_strength_index_average,

      p_26_reactive_strength_index_benchmark:
        values.p_26_reactive_strength_index_benchmark,
      p_26_dynamic_strength_index_benchmark:
        values.p_26_dynamic_strength_index_benchmark,
      p_26_eccentric_strength_index_benchmark:
        values.p_26_eccentric_strength_index_benchmark,
      p_26_relative_strength_index_benchmark:
        values.p_26_relative_strength_index_benchmark,
    };

    let result = { id: pdfID, pdf_data: JSON.stringify(data) };

    mutateUpdate(result, {
      onSuccess: (res) => {
        if (res.success) {
          console.log("pdf_data", res);
          notification.success({
            message: "Success",
            description: "Successfully Updated!",
          });
        }
      },
    });
  };

  const { mutate: mutateUpdate, isLoading: isLoadingUpdate } = POST(
    "api/v1/edit_pdf_start",
    "get_data"
  );

  return (
    <>
      <Form form={form} onFinish={onFinish}>
        <Row gutter={12}>
          <Col xs={24} sm={24} md={12}>
            <Collapse
              className="main-1-collapse border-none"
              expandIcon={({ isActive }) =>
                isActive ? (
                  <span
                    className="ant-menu-submenu-arrow"
                    style={{ color: "#FFF", transform: "rotate(270deg)" }}
                  ></span>
                ) : (
                  <span
                    className="ant-menu-submenu-arrow"
                    style={{ color: "#FFF", transform: "rotate(90deg)" }}
                  ></span>
                )
              }
              defaultActiveKey={["0"]}
              expandIconPosition="start"
            >
              {PDFData && (
                <Collapse.Panel
                  header="Athlete Information"
                  key="0"
                  className="accordion bg-darkgray-form m-b-md border"
                >
                  <Typography.Text>
                    <strong>Assessment Type</strong>:{" "}
                    {typeData && typeData.type}
                  </Typography.Text>
                  <br />

                  <Typography.Text>
                    <strong>Name</strong>: {PDFData.name}
                  </Typography.Text>
                  <br />

                  <Typography.Text>
                    <strong>Age</strong>: {PDFData.p_3_age}
                  </Typography.Text>
                  <br />

                  <Typography.Text>
                    <strong>Sport</strong>: {PDFData.p_3_sport}
                  </Typography.Text>
                  <br />

                  <Typography.Text>
                    <strong>Weight</strong>: {PDFData.p_3_weight}
                  </Typography.Text>
                  <br />

                  <Typography.Text>
                    <strong>Sex</strong>: {PDFData.p_3_gender}
                  </Typography.Text>
                  <br />
                </Collapse.Panel>
              )}
            </Collapse>
          </Col>
        </Row>

        <Collapse
          className="main-1-collapse border-none"
          expandIcon={({ isActive }) =>
            isActive ? (
              <span
                className="ant-menu-submenu-arrow"
                style={{ color: "#FFF", transform: "rotate(270deg)" }}
              ></span>
            ) : (
              <span
                className="ant-menu-submenu-arrow"
                style={{ color: "#FFF", transform: "rotate(90deg)" }}
              ></span>
            )
          }
          defaultActiveKey={[
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10",
            "11",
            "12",
            "13",
            "14",
            "15",
          ]}
          expandIconPosition="start"
        >
          <Collapse.Panel
            header="Page 3 - BFS Science Assessment"
            key="1"
            className="accordion bg-darkgray-form m-b-md border"
          >
            <Divider orientation="left" plain style={{ marginTop: "0px" }}>
              <strong>You</strong>
            </Divider>
            <Row gutter={12}>
              <Col xs={24} sm={24} md={8}>
                <Form.Item name="p_3_rebound_jump">
                  <FloatInput
                    label="Rebound Jump (RSI)"
                    placeholder="Rebound Jump (RSI)"
                  />
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} md={8}>
                <Form.Item name="p_3_countermovement_jump">
                  <FloatInput
                    label="Countermovement jump (IN)"
                    placeholder="Countermovement jump (IN)"
                  />
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} md={8}>
                <Form.Item name="p_3_squat_jump">
                  <FloatInput
                    label="Squat jump (IN)"
                    placeholder="Squat jump (IN)"
                  />
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} md={8}>
                <Form.Item name="p_3_ten_yard_sprint_jump">
                  <FloatInput
                    label="10yd Sprint (SECS)"
                    placeholder="10yd Sprint (SECS)"
                  />
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} md={8}>
                <Form.Item name="p_3_isometric_mid_thigh_pull">
                  <FloatInput
                    label="Isometric Mid-thigh pull (LBS)"
                    placeholder="Isometric Mid-thigh pull (LBS)"
                  />
                </Form.Item>
              </Col>
            </Row>

            <Divider orientation="left" plain style={{ marginTop: "0px" }}>
              <strong>Performaers AVG</strong>
            </Divider>
            <Row gutter={12}>
              <Col xs={24} sm={24} md={8}>
                <Form.Item name="p_3_rebound_jump_average">
                  <FloatInput
                    label="Rebound Jump (RSI)"
                    placeholder="Rebound Jump (RSI)"
                  />
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} md={8}>
                <Form.Item name="p_3_countermovement_jump_average">
                  <FloatInput
                    label="Countermovement jump (IN)"
                    placeholder="Countermovement jump (IN)"
                  />
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} md={8}>
                <Form.Item name="p_3_squat_jump_average">
                  <FloatInput
                    label="Squat jump (IN)"
                    placeholder="Squat jump (IN)"
                  />
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} md={8}>
                <Form.Item name="p_3_ten_yard_sprint_jump_average">
                  <FloatInput
                    label="10yd Sprint (SECS)"
                    placeholder="10yd Sprint (SECS)"
                  />
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} md={8}>
                <Form.Item name="p_3_isometric_mid_thigh_pull_average">
                  <FloatInput
                    label="Isometric Mid-thigh pull (LBS)"
                    placeholder="Isometric Mid-thigh pull (LBS)"
                  />
                </Form.Item>
              </Col>
            </Row>

            <Divider orientation="left" plain style={{ marginTop: "0px" }}>
              <strong>Your Benchmark</strong>
            </Divider>
            <Row gutter={12}>
              <Col xs={24} sm={24} md={8}>
                <Form.Item name="p_3_rebound_jump_benchmark">
                  <FloatInput
                    label="Rebound Jump (RSI)"
                    placeholder="Rebound Jump (RSI)"
                  />
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} md={8}>
                <Form.Item name="p_3_countermovement_jump_benchmark">
                  <FloatInput
                    label="Countermovement jump (IN)"
                    placeholder="Countermovement jump (IN)"
                  />
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} md={8}>
                <Form.Item name="p_3_squat_jump_benchmark">
                  <FloatInput
                    label="Squat jump (IN)"
                    placeholder="Squat jump (IN)"
                  />
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} md={8}>
                <Form.Item name="p_3_ten_yard_sprint_jump_benchmark">
                  <FloatInput
                    label="10yd Sprint (SECS)"
                    placeholder="10yd Sprint (SECS)"
                  />
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} md={8}>
                <Form.Item name="p_3_isometric_mid_thigh_pull_benchmark">
                  <FloatInput
                    label="Isometric Mid-thigh pull (LBS)"
                    placeholder="Isometric Mid-thigh pull (LBS)"
                  />
                </Form.Item>
              </Col>
            </Row>
          </Collapse.Panel>

          <Collapse.Panel
            header="Page 4 - BFS Science Assessment"
            key="2"
            className="accordion bg-darkgray-form m-b-md border"
          >
            <Divider orientation="left" plain style={{ marginTop: "0px" }}>
              <strong>You</strong>
            </Divider>
            <Row gutter={12}>
              <Col xs={24} sm={24} md={8}>
                <Form.Item name="p_4_static_med_ball_throw">
                  <FloatInput
                    label="Plyometric MED BALL THROW (W)"
                    placeholder="Plyometric MED BALL THROW (W)"
                  />
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} md={8}>
                <Form.Item name="p_4_rational_med_throw">
                  <FloatInput
                    label="ROTATIONAL MED BALL THROW (W)"
                    placeholder="ROTATIONAL MED BALL THROW (W)"
                  />
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} md={8}>
                <Form.Item name="p_4_push_ups">
                  <FloatInput label="PUSH-UPS (#)" placeholder="PUSH-UPS (#)" />
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} md={8}>
                <Form.Item name="p_4_chin_ups">
                  <FloatInput label="Chin Ups (#)" placeholder="Chin Ups (#)" />
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} md={8}>
                <Form.Item name="p_4_5_10_5_agility_test">
                  <FloatInput
                    label="5-10-5 Agility Test(Secs)"
                    placeholder="5-10-5 Agility Test(Secs)"
                  />
                </Form.Item>
              </Col>
            </Row>

            <Divider orientation="left" plain style={{ marginTop: "0px" }}>
              <strong>Performaers AVG</strong>
            </Divider>
            <Row gutter={12}>
              <Col xs={24} sm={24} md={8}>
                <Form.Item name="p_4_static_med_ball_throw_average">
                  <FloatInput
                    label="Plyometric MED BALL THROW (W)"
                    placeholder="Plyometric MED BALL THROW (W)"
                  />
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} md={8}>
                <Form.Item name="p_4_rational_med_throw_average">
                  <FloatInput
                    label="ROTATIONAL MED BALL THROW (W)"
                    placeholder="ROTATIONAL MED BALL THROW (W)"
                  />
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} md={8}>
                <Form.Item name="p_4_push_ups_average">
                  <FloatInput label="PUSH-UPS (#)" placeholder="PUSH-UPS (#)" />
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} md={8}>
                <Form.Item name="p_4_chin_ups_average">
                  <FloatInput label="Chin Ups (#)" placeholder="Chin Ups (#)" />
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} md={8}>
                <Form.Item name="p_4_5_10_5_agility_test_average">
                  <FloatInput
                    label="5-10-5 Agility Test(Secs)"
                    placeholder="5-10-5 Agility Test(Secs)"
                  />
                </Form.Item>
              </Col>
            </Row>

            <Divider orientation="left" plain style={{ marginTop: "0px" }}>
              <strong>Your Benchmark</strong>
            </Divider>
            <Row gutter={12}>
              <Col xs={24} sm={24} md={8}>
                <Form.Item name="p_4_static_med_ball_throw_benchmark">
                  <FloatInput
                    label="Plyometric MED BALL THROW (W)"
                    placeholder="Plyometric MED BALL THROW (W)"
                  />
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} md={8}>
                <Form.Item name="p_4_rational_med_throw_benchmark">
                  <FloatInput
                    label="ROTATIONAL MED BALL THROW (W)"
                    placeholder="ROTATIONAL MED BALL THROW (W)"
                  />
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} md={8}>
                <Form.Item name="p_4_push_ups_benchmark">
                  <FloatInput label="PUSH-UPS (#)" placeholder="PUSH-UPS (#)" />
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} md={8}>
                <Form.Item name="p_4_chin_ups_benchmark">
                  <FloatInput label="Chin Ups (#)" placeholder="Chin Ups (#)" />
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} md={8}>
                <Form.Item name="p_4_5_10_5_agility_test_benchmark">
                  <FloatInput
                    label="5-10-5 Agility Test(Secs)"
                    placeholder="5-10-5 Agility Test(Secs)"
                  />
                </Form.Item>
              </Col>
            </Row>
          </Collapse.Panel>

          <Collapse.Panel
            header="Page 6 - Rebound Jump Test"
            key="3"
            className="accordion bg-darkgray-form m-b-md border"
          >
            <Divider orientation="left" plain style={{ marginTop: "0px" }}>
              <strong>Assessment</strong>
            </Divider>
            <Row gutter={12}>
              <Col xs={24} sm={24} md={8}>
                <Form.Item name="p_6_rsi_score">
                  <FloatInput
                    label="Your RSI Score"
                    placeholder="Your RSI Score"
                  />
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} md={8}>
                <Form.Item name="p_6_jump_height">
                  <FloatInput
                    label="Jump Height (IN)"
                    placeholder="Jump Height (IN)"
                  />
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} md={8}>
                <Form.Item name="p_6_ground_contact_time">
                  <FloatInput
                    label="Ground Contact Time (MS)"
                    placeholder="Ground Contact Time (MS)"
                  />
                </Form.Item>
              </Col>
            </Row>

            <Divider orientation="left" plain style={{ marginTop: "0px" }}>
              <strong>Your Rank is:</strong>
            </Divider>
            <Row gutter={12}>
              <Col xs={24} sm={24} md={8}>
                <Form.Item name="p_6_rank">
                  <FloatInput label="Rank" placeholder="Rank" />
                </Form.Item>
              </Col>
            </Row>
          </Collapse.Panel>

          <Collapse.Panel
            header="Page 8 - Counter-Movement Jump Test"
            key="4"
            className="accordion bg-darkgray-form m-b-md border"
          >
            <Divider orientation="left" plain style={{ marginTop: "0px" }}>
              <strong>CMJ Height (IN)</strong>
            </Divider>
            <Row gutter={12}>
              <Col xs={24} sm={24} md={8}>
                <Form.Item name="p_8_cmj_height">
                  <FloatInput label="You" placeholder="You" />
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} md={8}>
                <Form.Item name="p_8_cmj_height_average">
                  <FloatInput
                    label="Elite Performers"
                    placeholder="Elite Performers"
                  />
                </Form.Item>
              </Col>
            </Row>

            <Divider orientation="left" plain style={{ marginTop: "0px" }}>
              <strong>CMJ Peak Force (N)</strong>
            </Divider>
            <Row gutter={12}>
              <Col xs={24} sm={24} md={8}>
                <Form.Item name="p_8_relative_avg_power">
                  <FloatInput label="You" placeholder="You" />
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} md={8}>
                <Form.Item name="p_8_relative_avg_power_average">
                  <FloatInput
                    label="Elite Performers"
                    placeholder="Elite Performers"
                  />
                </Form.Item>
              </Col>
            </Row>

            <Divider orientation="left" plain style={{ marginTop: "0px" }}>
              <strong>Your Rank is:</strong>
            </Divider>
            <Row gutter={12}>
              <Col xs={24} sm={24} md={8}>
                <Form.Item name="p_8_rank">
                  <FloatInput label="Rank" placeholder="Rank" />
                </Form.Item>
              </Col>
            </Row>
          </Collapse.Panel>

          <Collapse.Panel
            header="Page 10 - Squat Jump Test"
            key="5"
            className="accordion bg-darkgray-form m-b-md border"
          >
            <Divider orientation="left" plain style={{ marginTop: "0px" }}>
              <strong>Jump Height (IN)</strong>
            </Divider>
            <Row gutter={12}>
              <Col xs={24} sm={24} md={8}>
                <Form.Item name="p_10_j_height">
                  <FloatInput label="You" placeholder="You" />
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} md={8}>
                <Form.Item name="p_10_j_height_average">
                  <FloatInput
                    label="Elite Performers"
                    placeholder="Elite Performers"
                  />
                </Form.Item>
              </Col>
            </Row>

            <Divider orientation="left" plain style={{ marginTop: "0px" }}>
              <strong>Peak Force Produced (N)</strong>
            </Divider>
            <Row gutter={12}>
              <Col xs={24} sm={24} md={8}>
                <Form.Item name="p_10_realative_peak_power">
                  <FloatInput label="You" placeholder="You" />
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} md={8}>
                <Form.Item name="p_10_realative_peak_power_average">
                  <FloatInput
                    label="Elite Performers"
                    placeholder="Elite Performers"
                  />
                </Form.Item>
              </Col>
            </Row>

            <Divider orientation="left" plain style={{ marginTop: "0px" }}>
              <strong>Your Rank is:</strong>
            </Divider>
            <Row gutter={12}>
              <Col xs={24} sm={24} md={8}>
                <Form.Item name="p_10_rank">
                  <FloatInput label="Rank" placeholder="Rank" />
                </Form.Item>
              </Col>
            </Row>
          </Collapse.Panel>

          <Collapse.Panel
            header="Page 11 - Eccentric Utilization Ratio (EUR)"
            key="6"
            className="accordion bg-darkgray-form m-b-md border"
          >
            <Divider orientation="left" plain style={{ marginTop: "0px" }}>
              <strong>Jump Height (IN)</strong>
            </Divider>
            <Row gutter={12}>
              <Col xs={24} sm={24} md={8}>
                <Form.Item name="p_11_your_eur_score">
                  <FloatInput label="Score" placeholder="Score" />
                </Form.Item>
              </Col>
            </Row>
          </Collapse.Panel>

          <Collapse.Panel
            header="Page 13 - 10YD/40YD sprint Test"
            key="7"
            className="accordion bg-darkgray-form m-b-md border"
          >
            <Divider orientation="left" plain style={{ marginTop: "0px" }}>
              <strong>10 YD Sprint (SEC)</strong>
            </Divider>
            <Row gutter={12}>
              <Col xs={24} sm={24} md={8}>
                <Form.Item name="p_13_10_yard_sprint">
                  <FloatInput label="You" placeholder="You" />
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} md={8}>
                <Form.Item name="p_13_10_yard_sprint_average">
                  <FloatInput
                    label="Elite Performers"
                    placeholder="Elite Performers"
                  />
                </Form.Item>
              </Col>
            </Row>

            <Divider orientation="left" plain style={{ marginTop: "0px" }}>
              <strong>40 YD Sprint (SEC)</strong>
            </Divider>
            <Row gutter={12}>
              <Col xs={24} sm={24} md={8}>
                <Form.Item name="p_13_40_yard_sprint">
                  <FloatInput label="You" placeholder="You" />
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} md={8}>
                <Form.Item name="p_13_40_yard_sprint_average">
                  <FloatInput
                    label="Elite Performers"
                    placeholder="Elite Performers"
                  />
                </Form.Item>
              </Col>
            </Row>

            <Divider orientation="left" plain style={{ marginTop: "0px" }}>
              <strong>Your Rank is:</strong>
            </Divider>
            <Row gutter={12}>
              <Col xs={24} sm={24} md={8}>
                <Form.Item name="p_13_rank">
                  <FloatInput label="Rank" placeholder="Rank" />
                </Form.Item>
              </Col>
            </Row>
          </Collapse.Panel>

          <Collapse.Panel
            header="Page 15 - Isometric Mid-thigh Pull Test (IMTP)"
            key="8"
            className="accordion bg-darkgray-form m-b-md border"
          >
            <Divider orientation="left" plain style={{ marginTop: "0px" }}>
              <strong>Absolute Strength</strong>
            </Divider>
            <Row gutter={12}>
              <Col xs={24} sm={24} md={8}>
                <Form.Item name="p_15_absolute_strenght">
                  <FloatInput label="You (N)" placeholder="You (N)" />
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} md={8}>
                <Form.Item name="p_15_absolute_strenght_pounds">
                  <FloatInput label="You (LBS)" placeholder="You (LBS)" />
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} md={8}>
                <Form.Item name="p_15_absolute_strenght_average_1">
                  <FloatInput
                    label="Average Performers (N)"
                    placeholder="Average Performers (N)"
                  />
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} md={8}>
                <Form.Item name="p_15_absolute_strenght_average_2">
                  <FloatInput
                    label="Average Performers (LBS)"
                    placeholder="Average Performers (LBS)"
                  />
                </Form.Item>
              </Col>
            </Row>

            <Divider orientation="left" plain style={{ marginTop: "0px" }}>
              <strong>Relative Strength Peak Force/Body Weight</strong>
            </Divider>
            <Row gutter={12}>
              <Col xs={24} sm={24} md={8}>
                <Form.Item name="p_15_relative_strength_peak_force_trength">
                  <FloatInput label="You" placeholder="You" />
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} md={8}>
                <Form.Item name="p_15_relative_strength_peak_force_trength_average">
                  <FloatInput
                    label="Elite Performers"
                    placeholder="Elite Performers"
                  />
                </Form.Item>
              </Col>
            </Row>

            <Divider orientation="left" plain style={{ marginTop: "0px" }}>
              <strong>Your Rank is:</strong>
            </Divider>
            <Row gutter={12}>
              <Col xs={24} sm={24} md={8}>
                <Form.Item name="p_15_rank">
                  <FloatInput label="Rank" placeholder="Rank" />
                </Form.Item>
              </Col>
            </Row>
          </Collapse.Panel>

          <Collapse.Panel
            header="Page 16 - Rate of Force Development (RFD)"
            key="9"
            className="accordion bg-darkgray-form m-b-md border"
          >
            <Divider orientation="left" plain style={{ marginTop: "0px" }}>
              <strong>Assessment</strong>
            </Divider>
            <Row gutter={12}>
              <Col xs={24} sm={24} md={8}>
                <Form.Item name="p_16_peak_force_pounds">
                  <FloatInput
                    label="Peak Force (LBS)"
                    placeholder="Peak Force (LBS)"
                  />
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} md={8}>
                <Form.Item name="p_16_weight">
                  <FloatInput
                    label="Your Weight (LBS)"
                    placeholder="Your Weight (LBS)"
                  />
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} md={8}>
                <Form.Item name="p_16_peak_force_body_weight">
                  <FloatInput
                    label="Peak Force/Body Weight"
                    placeholder="Peak Force/Body Weight"
                  />
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} md={8}>
                <Form.Item name="p_16_force_0_100_ms">
                  <FloatInput
                    label="Force (lbs) @0-100ms"
                    placeholder="Force (lbs) @ 0-100ms"
                  />
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} md={8}>
                <Form.Item name="p_16_0_100_ms_rfd">
                  <FloatInput
                    label="0-100ms RFD % of Peak Force"
                    placeholder="0-100ms RFD % of Peak Force"
                  />
                </Form.Item>
              </Col>
            </Row>
          </Collapse.Panel>

          <Collapse.Panel
            header="Page 17 - Dynamic Strength Index (DSI)"
            key="10"
            className="accordion bg-darkgray-form m-b-md border"
          >
            <Divider orientation="left" plain style={{ marginTop: "0px" }}>
              <strong>Dynamic Strength Index</strong>
            </Divider>
            <Row gutter={12}>
              <Col xs={24} sm={24} md={8}>
                <Form.Item name="p_17_imtp_peak_force">
                  <FloatInput
                    label="IMTP Peak Force"
                    placeholder="IMTP Peak Force"
                  />
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} md={8}>
                <Form.Item name="p_17_cmj_peak_force">
                  <FloatInput
                    label="CMJ Peak Force"
                    placeholder="CMJ Peak Force"
                  />
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} md={8}>
                <Form.Item name="p_17_dsi_score">
                  <FloatInput
                    label="Your DSI Score"
                    placeholder="Your DSI Score"
                  />
                </Form.Item>
              </Col>
            </Row>
          </Collapse.Panel>

          <Collapse.Panel
            header="Page 19 - Supine Med Ball Throw test"
            key="11"
            className="accordion bg-darkgray-form m-b-md border"
          >
            <Divider orientation="left" plain style={{ marginTop: "0px" }}>
              <strong>Peak Propulsive Power</strong>
            </Divider>
            <Row gutter={12}>
              <Col xs={24} sm={24} md={8}>
                <Form.Item name="p_19_peak_propulsive_power">
                  <FloatInput label="You" placeholder="You" />
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} md={8}>
                <Form.Item name="p_19_peak_propulsive_power_average">
                  <FloatInput
                    label="Average Performers"
                    placeholder="Average Performers"
                  />
                </Form.Item>
              </Col>
            </Row>

            <Divider orientation="left" plain style={{ marginTop: "0px" }}>
              <strong>Relative Peak Power (W/lb)</strong>
            </Divider>
            <Row gutter={12}>
              <Col xs={24} sm={24} md={8}>
                <Form.Item name="p_19_relative_peak_power">
                  <FloatInput label="You" placeholder="You" />
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} md={8}>
                <Form.Item name="p_19_relative_peak_power_average">
                  <FloatInput
                    label="Average Performers"
                    placeholder="Average Performers"
                  />
                </Form.Item>
              </Col>
            </Row>

            <Divider orientation="left" plain style={{ marginTop: "0px" }}>
              <strong>Your Rank is:</strong>
            </Divider>
            <Row gutter={12}>
              <Col xs={24} sm={24} md={8}>
                <Form.Item name="p_19_rank">
                  <FloatInput label="Rank" placeholder="Rank" />
                </Form.Item>
              </Col>
            </Row>
          </Collapse.Panel>

          <Collapse.Panel
            header="Page 21 - Rotational med Ball Throw Test"
            key="12"
            className="accordion bg-darkgray-form m-b-md border"
          >
            <Divider orientation="left" plain style={{ marginTop: "0px" }}>
              <strong>Peak Rotational Power (W)</strong>
            </Divider>
            <Row gutter={12}>
              <Col xs={24} sm={24} md={8}>
                <Form.Item name="p_21_peak_rotational_power">
                  <FloatInput label="You" placeholder="You" />
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} md={8}>
                <Form.Item name="p_21_peak_rotational_power_average">
                  <FloatInput
                    label="Average Performers"
                    placeholder="Average Performers"
                  />
                </Form.Item>
              </Col>
            </Row>

            <Divider orientation="left" plain style={{ marginTop: "0px" }}>
              <strong>Your Rank is:</strong>
            </Divider>
            <Row gutter={12}>
              <Col xs={24} sm={24} md={8}>
                <Form.Item name="p_21_rank">
                  <FloatInput label="Rank" placeholder="Rank" />
                </Form.Item>
              </Col>
            </Row>
          </Collapse.Panel>

          <Collapse.Panel
            header="Page 23 - Strength Endurance Testing"
            key="13"
            className="accordion bg-darkgray-form m-b-md border"
          >
            <Divider orientation="left" plain style={{ marginTop: "0px" }}>
              <strong>Chin Ups</strong>
            </Divider>
            <Row gutter={12}>
              <Col xs={24} sm={24} md={8}>
                <Form.Item name="p_23_chin_ups">
                  <FloatInput
                    label="Your Repetitions"
                    placeholder="Your Repetitions"
                  />
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} md={8}>
                <Form.Item name="p_23_chin_ups_average">
                  <FloatInput
                    label="Average Performers Repetitions"
                    placeholder="Average Performers Repetitions"
                  />
                </Form.Item>
              </Col>
            </Row>

            <Divider orientation="left" plain style={{ marginTop: "0px" }}>
              <strong>PUSH-UPS</strong>
            </Divider>
            <Row gutter={12}>
              <Col xs={24} sm={24} md={8}>
                <Form.Item name="p_23_push_ups">
                  <FloatInput
                    label="Your Repetitions"
                    placeholder="Your Repetitions"
                  />
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} md={8}>
                <Form.Item name="p_23_push_ups_average">
                  <FloatInput
                    label="Average Performers Repetitions"
                    placeholder="Average Performers Repetitions"
                  />
                </Form.Item>
              </Col>
            </Row>
          </Collapse.Panel>

          <Collapse.Panel
            header="Page 25 - 5-10-5 Agility Test"
            key="14"
            className="accordion bg-darkgray-form m-b-md border"
          >
            <Divider orientation="left" plain style={{ marginTop: "0px" }}>
              <strong>5-10-5 Agility</strong>
            </Divider>
            <Row gutter={12}>
              <Col xs={24} sm={24} md={8}>
                <Form.Item name="p_25_5_10_5_agility">
                  <FloatInput label="You" placeholder="You" />
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} md={8}>
                <Form.Item name="p_25_5_10_5_agility_average">
                  <FloatInput
                    label="Elite Performers"
                    placeholder="Elite Performers"
                  />
                </Form.Item>
              </Col>
            </Row>

            <Divider orientation="left" plain style={{ marginTop: "0px" }}>
              <strong>Your Rank is:</strong>
            </Divider>
            <Row gutter={12}>
              <Col xs={24} sm={24} md={8}>
                <Form.Item name="p_25_rank">
                  <FloatInput label="Rank" placeholder="Rank" />
                </Form.Item>
              </Col>
            </Row>
          </Collapse.Panel>

          <Collapse.Panel
            header="Page 26 - Summary of Findings"
            key="15"
            className="accordion bg-darkgray-form m-b-md border"
          >
            <Divider orientation="left" plain style={{ marginTop: "0px" }}>
              <strong>You</strong>
            </Divider>
            <Row gutter={12}>
              <Col xs={24} sm={24} md={8}>
                <Form.Item name="p_26_reactive_strength_index">
                  <FloatInput
                    label="Reactive Strength Index"
                    placeholder="Reactive Strength Index"
                  />
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} md={8}>
                <Form.Item name="p_26_dynamic_strength_index">
                  <FloatInput
                    label="Dynamic Strength Index"
                    placeholder="Dynamic Strength Index"
                  />
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} md={8}>
                <Form.Item name="p_26_eccentric_strength_index">
                  <FloatInput
                    label="Eccentric Utilization Ratio"
                    placeholder="Eccentric Utilization Ratio"
                  />
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} md={8}>
                <Form.Item name="p_26_relative_strength_index">
                  <FloatInput
                    label="Relative Strength"
                    placeholder="Relative Strength"
                  />
                </Form.Item>
              </Col>
            </Row>

            <Divider orientation="left" plain style={{ marginTop: "0px" }}>
              <strong>Average Performers</strong>
            </Divider>
            <Row gutter={12}>
              <Col xs={24} sm={24} md={8}>
                <Form.Item name="p_26_reactive_strength_index_average">
                  <FloatInput
                    label="Reactive Strength Index"
                    placeholder="Reactive Strength Index"
                  />
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} md={8}>
                <Form.Item name="p_26_dynamic_strength_index_average">
                  <FloatInput
                    label="Dynamic Strength Index"
                    placeholder="Dynamic Strength Index"
                  />
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} md={8}>
                <Form.Item name="p_26_eccentric_strength_index_average">
                  <FloatInput
                    label="Eccentric Utilization Ratio"
                    placeholder="Eccentric Utilization Ratio"
                  />
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} md={8}>
                <Form.Item name="p_26_relative_strength_index_average">
                  <FloatInput
                    label="Relative Strength"
                    placeholder="Relative Strength"
                  />
                </Form.Item>
              </Col>
            </Row>

            <Divider orientation="left" plain style={{ marginTop: "0px" }}>
              <strong>Benchmark</strong>
            </Divider>
            <Row gutter={12}>
              <Col xs={24} sm={24} md={8}>
                <Form.Item name="p_26_reactive_strength_index_benchmark">
                  <FloatInput
                    label="Reactive Strength Index"
                    placeholder="Reactive Strength Index"
                  />
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} md={8}>
                <Form.Item name="p_26_dynamic_strength_index_benchmark">
                  <FloatInput
                    label="Dynamic Strength Index"
                    placeholder="Dynamic Strength Index"
                    disabled={true}
                  />
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} md={8}>
                <Form.Item name="p_26_eccentric_strength_index_benchmark">
                  <FloatInput
                    label="Eccentric Utilization Ratio"
                    placeholder="Eccentric Utilization Ratio"
                    disabled={true}
                  />
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} md={8}>
                <Form.Item name="p_26_relative_strength_index_benchmark">
                  <FloatInput
                    label="Relative Strength"
                    placeholder="Relative Strength"
                  />
                </Form.Item>
              </Col>
            </Row>
          </Collapse.Panel>
        </Collapse>

        <Row gutter={12}>
          <Col xs={24} sm={8} md={8}>
            <Button
              size="large"
              className="btn-main-outline-2"
              htmlType="submit"
              loading={isLoadingUpdate}
              block
            >
              SUBMIT
            </Button>
          </Col>
        </Row>
      </Form>
    </>
  );
}
