import { Card, Col, Layout, Row, Space, Table } from "antd";
import React, { useState, useEffect } from "react";
import { faSearch } from "@fortawesome/pro-regular-svg-icons";
import ComponentHeader from "../../Components/ComponentHeader";
import {
  TableGlobalSearch,
  TablePageSize,
  TablePagination,
  TableShowingEntries,
} from "../../Components/ComponentTableFilter";
import $ from "jquery";
import { Link } from "react-router-dom";
import { GET } from "../../../../providers/useAxiosQuery";
// import companyInfo from "../../../../providers/companyInfo";

export default function PageSearch({ match, permission }) {
  // const history = useHistory();
  // const userdata = companyInfo().userData;
  const [tableFilter, setTableFilter] = useState({
    page: 1,
    page_size: 50,
    search: "",
    sort_field: "name",
    sort_order: "desc",
    from: "coach_search",
  });

  const [tableTotal, setTableTotal] = useState(0);
  const [dataSource, setDataSource] = useState([]);
  const {
    refetch: refetchTable,
    isLoading: isLoadingTable,
    isFetching: isFetchingTable,
  } = GET(
    `api/v1/get_athlete?${$.param(tableFilter)}`,
    "search_athletes",
    (res) => {
      if (res.success) {
        // console.log("dataTable", res);
        setDataSource(res.data && res.data.data);
        setTableTotal(res.data.total);
      }
    }
  );

  const handleTableChange = (pagination, filters, sorter) => {
    setTableFilter({
      ...tableFilter,
      sort_field: sorter.columnKey,
      sort_order: sorter.order ? sorter.order.replace("end", "") : null,
      // page: 1,
    });
  };

  const [searchText, setSearchText] = useState("");
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setTableFilter({
        ...tableFilter,
        search: searchText,
        page: 1,
      });
    }, 1500);
    return () => {
      clearTimeout(timeoutId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText]);

  useEffect(() => {
    refetchTable();
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableFilter]);

  // const handleDelete = (id) => {
  //   let data = {
  //     status: 1,
  //     id: id,
  //   };

  //   mutateDelete(data, {
  //     onSuccess: (res) => {
  //       if (res.success) {
  //         notification.success({
  //           message: "Success",
  //           description: "Successfully deleted.",
  //         });
  //       }
  //     },
  //   });
  // };

  // const { mutate: mutateDelete, isLoading: isLoadingDelete } = UPDATE(
  //   "api/v1/search_athletes",
  //   "search_athletes"
  // );

  // const [toggleModalPreview, setToggleModalPreview] = useState({
  //   show: false,
  //   data: null,
  //   type: "Starter",
  // });

  return (
    <>
      <ComponentHeader title={permission} sub_title="SEARCH" icon={faSearch} />

      <Card className="card-min-height">
        <Row gutter={12}>
          <Col xs={24} sm={24} md={16} lg={16} xl={18} className="m-b-sm">
            <Space>
              <TablePageSize
                paginationFilter={tableFilter}
                setPaginationFilter={setTableFilter}
              />
            </Space>
          </Col>
          <Col xs={24} sm={24} md={8} lg={8} xl={6}>
            <TableGlobalSearch
              paginationFilter={searchText}
              setPaginationFilter={setSearchText}
            />
          </Col>
        </Row>
        <Row gutter={24}>
          <Col xs={24} sm={24} md={24} className="m-t-sm m-b-sm">
            <Table
              dataSource={dataSource && dataSource}
              rowKey={(record) => record.id}
              pagination={false}
              loading={isLoadingTable || isFetchingTable}
              bordered={false}
              onChange={handleTableChange}
              className="ant-table-default ant-table-striped"
              scroll={{ x: "max-content" }}
            >
              <Table.Column
                title="Name"
                key="name"
                dataIndex="name"
                sorter={true}
                defaultSortOrder="descend"
                render={(text, record) => {
                  let defaultWebsite = record.athlete_website.filter(
                    (itemFilter) => itemFilter.is_default === 1
                  );
                  // console.log("defaultWebsite", defaultWebsite);
                  return defaultWebsite ? (
                    <>
                      {defaultWebsite.length > 0 ? (
                        <Link
                          to={`/athletic/${
                            defaultWebsite.length > 0 &&
                            defaultWebsite[0].name &&
                            defaultWebsite[0].name
                          }`}
                          target="_blank"
                        >
                          {text}
                        </Link>
                      ) : (
                        text
                      )}
                    </>
                  ) : (
                    text
                  );
                }}
                width={"250px"}
              />
              <Table.Column
                title="Organization"
                key="organization"
                dataIndex="organization"
                sorter={true}
                width={"250px"}
              />
              <Table.Column
                title="State"
                key="state"
                dataIndex="state"
                sorter={true}
                width={"150px"}
              />
              <Table.Column
                title="City"
                key="city"
                dataIndex="city"
                sorter={true}
                width={"150px"}
              />
            </Table>
          </Col>
          <Col xs={24} sm={24} md={24}>
            <Space className="ant-space-flex-space-between">
              <TableShowingEntries />

              <TablePagination
                paginationFilter={tableFilter}
                setPaginationFilter={setTableFilter}
                setPaginationTotal={tableTotal}
                showLessItems={true}
                showSizeChanger={false}
              />
            </Space>
          </Col>
        </Row>
        <br />
      </Card>
    </>
  );
}
