import React from "react";
import { Switch, Route } from "react-router-dom";
/** template */
import PrivateRoute from "../PrivateRoute";

import Error404 from "../../views/errors/Error404";
import Error500 from "../../views/errors/Error500";

import PageDashboard from "../../views/private/PageManager/PageDashboard/PageDashboard";
import PagePendingAssessments from "../../views/private/PageManager/PagePendingAssessments/PagePendingAssessments";
import PageUserViewEditActive from "../../views/private/PageManager/PageUser/PageUserViewEditActive";
import PageUserViewEditDeactivated from "../../views/private/PageManager/PageUser/PageUserViewEditDeactivated";
import PageUserSearchAthletes from "../../views/private/PageManager/PageUser/PageUserSearchAthletes";
import PageUserEdit from "../../views/private/PageManager/PageUser/PageUserEdit";

import PageOrganizationViewEdit from "../../views/private/PageManager/PageOrganization/PageOrganizationViewEdit";
import PageOrganizationAddNew from "../../views/private/PageManager/PageOrganization/PageOrganizationAddNew";
import PageOrganizationEdit from "../../views/private/PageManager/PageOrganization/PageOrganizationEdit";
import PageOrganizationPendingApproval from "../../views/private/PageManager/PageOrganization/PageOrganizationPendingApproval";
import PageOrganizationUpdateNew from "../../views/private/PageManager/PageOrganization/PageOrganizationUpdateNew";

import PageEventCalendarCreate from "../../views/private/PageManager/PageEventCalendar/PageEventCalendarCreate/PageEventCalendarCreate";
import PageEventCalendarViewAll from "../../views/private/PageManager/PageEventCalendar/PageEventCalendarViewAll/PageEventCalendarViewAll";
import PageEventCalendarGroupAssessment from "../../views/private/PageManager/PageEventCalendar/PageEventCalendarGroupAssessment/PageEventCalendarGroupAssessment";
import PageEventCalendarPrivateAssessment from "../../views/private/PageManager/PageEventCalendar/PageEventCalendarPrivateAssessment/PageEventCalendarPrivateAssessment";
import PageEventPrivateCalendar from "../../views/private/PageManager/PageEventCalendar/PageEventPrivateCalendar/PageEventPrivateCalendar";

import PageProfileManager from "../../views/private/PageManager/PageProfileManager/PageProfileManager";

import PageAssessors from "../../views/private/PageManager/PageAssessors/PageAssessors";
import PageAssessorAddEdit from "../../views/private/PageManager/PageAssessors/PageAssessorAddEdit";
import PageMessages from "../../views/private/PageMessages/PageMessages";

import PageAssessmentLogs from "../../views/private/PageManager/PageAssessmentLogs/PageAssessmentLogs";
import PageProfileManagerBankDetails from "../../views/private/PageManager/PageProfileManager/PageProfileManagerBankDetails";

export default function RouteManager() {
  return (
    <Switch>
      {/* member */}

      <PrivateRoute
        exact
        path="/dashboard"
        permission="Dashboard"
        component={PageDashboard}
        breadcrumb={[
          {
            name: "Dashboard",
            link: "/dashboard",
          },
        ]}
      />

      <PrivateRoute
        exact
        path="/pending-assessments"
        title="Pending Assessments"
        permission="Assessments"
        component={PagePendingAssessments}
        breadcrumb={[
          {
            name: "Pending Assessments",
            link: "/pending-assessments",
          },
        ]}
      />

      <PrivateRoute
        exact
        path="/user/view-edit-active"
        title="View / Edit Active"
        permission="Active"
        component={PageUserViewEditActive}
        breadcrumb={[
          {
            name: "Users",
            link: "",
          },
          {
            name: "View / Edit Active",
            link: "/user/view-edit-active",
          },
        ]}
      />

      <PrivateRoute
        exact
        path="/user/view-edit-deactivated"
        title="View / Edit Deactivated"
        permission="Deactivated"
        component={PageUserViewEditDeactivated}
        breadcrumb={[
          {
            name: "Users",
            link: "",
          },
          {
            name: "View / Edit Deactivated",
            link: "/user/view-edit-deactivated",
          },
        ]}
      />

      <PrivateRoute
        exact
        path="/user/edit/:id"
        title="Edit User"
        permission="User"
        component={PageUserEdit}
        breadcrumb={[
          {
            name: "Users",
            link: "#",
          },
          {
            name: "Edit User",
            link: "#",
          },
        ]}
      />

      <PrivateRoute
        exact
        path="/user/search-athletes"
        title="Search Athletes"
        permission="Athletes"
        component={PageUserSearchAthletes}
        breadcrumb={[
          {
            name: "Users",
            link: "",
          },
          {
            name: "Search Athletes",
            link: "/user/search-athletes",
          },
        ]}
      />
      <PrivateRoute
        exact
        path="/user/view-edit-active"
        title="View / Edit Active"
        permission="User View / Edit Active"
        component={PageUserViewEditActive}
        breadcrumb={[
          {
            name: "Users",
            link: "",
          },
          {
            name: "View / Edit Active",
            link: "/user/view-edit-active",
          },
        ]}
      />
      <PrivateRoute
        exact
        path="/user/view-edit-deactivated"
        title="View / Edit Deactivated"
        permission="User View / Edit Deactivated"
        component={PageUserViewEditDeactivated}
        breadcrumb={[
          {
            name: "Users",
            link: "",
          },
          {
            name: "View / Edit Deactivated",
            link: "/user/view-edit-deactivated",
          },
        ]}
      />
      <PrivateRoute
        exact
        path="/user/search-athletes"
        title="Search Athletes"
        permission="Athlete"
        component={PageUserSearchAthletes}
        breadcrumb={[
          {
            name: "Users",
            link: "",
          },
          {
            name: "Search Athletes",
            link: "/user/search-athletes",
          },
        ]}
      />

      <PrivateRoute
        exact
        path="/organization/view-edit"
        title="View Edit"
        permission="Organizations"
        component={PageOrganizationViewEdit}
        breadcrumb={[
          {
            name: "Organizations",
            link: "/organization/view-edit",
          },
          {
            name: "View Edit",
            link: "/organization/view-edit",
          },
        ]}
      />

      <PrivateRoute
        exact
        path="/organization/edit/:id"
        title="View / Edit"
        permission="Organizations"
        component={PageOrganizationEdit}
        breadcrumb={[
          {
            name: "Organizations",
            link: "/organization/view-edit",
          },
          {
            name: "View / Edit",
            link: "/organization/edit/:id",
          },
        ]}
      />

      <PrivateRoute
        exact
        path="/organization/add-new"
        title="Add New Organization"
        permission="Organization"
        component={PageOrganizationAddNew}
        breadcrumb={[
          {
            name: "Organizations",
            link: "",
          },
          {
            name: "Add New Organization",
            link: "/organization/add-new",
          },
        ]}
      />

      <PrivateRoute
        exact
        // path="/organization/pending-approval"
        path="/organization/pending-approval/:state/:city"
        title="Pending Approval"
        permission="Organization Pending Approval"
        component={PageOrganizationUpdateNew}
        breadcrumb={[
          {
            name: "Organizations",
            link: "",
          },
          {
            name: "Pending Approval",
            link: "/organization/pending-approval",
          },
        ]}
      />

      <PrivateRoute
        exact
        path="/organization/pending-approval"
        title="Pending Approval"
        permission="Approval"
        component={PageOrganizationPendingApproval}
        breadcrumb={[
          {
            name: "Organizations",
            link: "",
          },
          {
            name: "Pending Approval",
            link: "/organization/pending-approval",
          },
        ]}
      />

      <PrivateRoute
        exact
        path="/event-calendar/add-assessment"
        title="Create Event"
        permission="Assessment"
        component={PageEventCalendarCreate}
        breadcrumb={[
          {
            name: "Event Calendar",
            link: "",
          },
          {
            name: "Add Assessment",
            link: "/event-calendar/add-assessment",
          },
        ]}
      />

      <PrivateRoute
        exact
        path="/event-calendar/edit-assessments"
        title="View All Event"
        permission="Assessment"
        component={PageEventCalendarViewAll}
        breadcrumb={[
          {
            name: "Event Calendar",
            link: "",
          },
          {
            name: "Edit Assessment",
            link: "/event-calendar/edit-assessments",
          },
        ]}
      />

      <PrivateRoute
        exact
        path="/event-calendar/edit-assessments/:id"
        title="View All Event"
        permission="Assessment"
        component={PageEventCalendarCreate}
        breadcrumb={[
          {
            name: "Event Calendar",
            link: "",
          },
          {
            name: "View Assessment",
            link: "/event-calendar/edit-assessments",
          },
          {
            name: "Edit Assessment",
            link: "/event-calendar/edit-assessments/view",
          },
        ]}
      />

      <PrivateRoute
        exact
        path="/event-calendar/group-assessment"
        title="Group Assessments"
        permission="Assessments"
        component={PageEventCalendarGroupAssessment}
        breadcrumb={[
          {
            name: "Event Calendar",
            link: "",
          },
          {
            name: "Group Assessments",
            link: "/event-calendar/group-assessment",
          },
        ]}
      />

      <PrivateRoute
        exact
        path="/event-calendar/private-assessment"
        title="Private Assessments"
        permission="Assessments"
        component={PageEventCalendarPrivateAssessment}
        breadcrumb={[
          {
            name: "Event Calendar",
            link: "",
          },
          {
            name: "Private Assessments",
            link: "/event-calendar/private-assessment",
          },
        ]}
      />

      <PrivateRoute
        exact
        path="/event-calendar/add-edit-schedule"
        title="Add / Edit Schedule"
        permission="Schedule"
        component={PageEventPrivateCalendar}
        breadcrumb={[
          {
            name: "Event Calendar",
            link: "",
          },
          {
            name: "Add / Edit Schedule",
            link: "/event-calendar/add-edit-schedule",
          },
        ]}
      />

      <PrivateRoute
        exact
        path="/profile"
        component={PageProfileManager}
        title="Profile"
        permission="Profile"
        breadcrumb={[
          {
            name: "Dashboard",
            link: "",
          },
          {
            name: "Profile",
            link: "/profile",
          },
        ]}
      />
      <PrivateRoute
        exact
        path="/profile/bank-details"
        component={PageProfileManagerBankDetails}
        title="Profile Bank Details"
        permission="Profile Bank Details"
        breadcrumb={[
          {
            name: "Dashboard",
            link: "",
          },
          {
            name: "Profile",
            link: "/profile",
          },
          {
            name: "Profile Bank Details",
            link: "/profile/bank-details",
          },
        ]}
      />

      <PrivateRoute
        exact
        path="/assessors"
        component={PageAssessors}
        title="Assessor"
        permission="Assessor"
        breadcrumb={[
          {
            name: "Dashboard",
            link: "/",
          },
          {
            name: "Assessor",
            link: "#",
          },
        ]}
      />

      <PrivateRoute
        exact
        path="/assessors/edit"
        title="Add Assessor"
        permission="Assessor"
        component={PageAssessorAddEdit}
        breadcrumb={[
          {
            name: "Dashboard",
            link: "/",
          },
          {
            name: "Assessor",
            link: "/assessors",
          },
          {
            name: "Add Assessor",
            link: "#",
          },
        ]}
      />

      <PrivateRoute
        exact
        path="/assessors/edit/:id"
        title="Edit Assessor"
        permission="Assessor"
        component={PageAssessorAddEdit}
        breadcrumb={[
          {
            name: "Dashboard",
            link: "/",
          },
          {
            name: "Assessors",
            link: "/assessors",
          },
          {
            name: "Edit Assessor",
            link: "#",
          },
        ]}
      />

      <PrivateRoute
        exact
        path="/messages"
        component={PageMessages}
        title="Messages"
        permission="Messages"
        breadcrumb={[
          {
            name: "Messages",
            link: "/messages",
          },
        ]}
      />

      <PrivateRoute
        exact
        path="/assessment-logs"
        component={PageAssessmentLogs}
        title="Assessment Logs"
        permission="Assessment Logs"
        breadcrumb={[
          {
            name: "Assessment Logs",
            link: "/assessment-logs",
          },
        ]}
      />

      <Route exact path="/500" component={Error500} />
      <Route exact path="/*" component={Error404} />
    </Switch>
  );
}
