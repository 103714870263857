import { useState, Fragment, useEffect } from "react";
import { Button, Col, Image, Layout, Row, Space, Typography } from "antd";
import moment from "moment";
import { useHistory, Link } from "react-router-dom";
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  EmailShareButton,
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
  EmailIcon,
} from "react-share";
import $ from "jquery";

// import HelmetMetaData from "./HelmetMetaData";

import { GET } from "../../../providers/useAxiosQuery";

import companyInfo from "../../../providers/companyInfo";

export default function PageEvent(props) {
  const apiUrl = companyInfo().apiUrl;
  const sidelogo = companyInfo().sidelogo;
  const userData = companyInfo().userData;
  const { match } = props;
  const history = useHistory();
  const [eventData, setEventData] = useState();

  GET(`api/v1/get_public_event/${match.params.id}`, "event_public", (res) => {
    if (res.success) {
      console.log("res", res);
      setEventData(res.data);
    }
  });

  const booking = () => {
    if (localStorage.userdata) {
      if (userData.role == "Athlete") {
        history.push({
          pathname: "/book-assessment",
          state: { data: eventData, type: "group" },
        });
      } else {
        // console.log("not a member");
        history.push("/register");
      }
    } else {
      history.push("/register");
      // console.log("/register");
    }
  };
  function stripHtml(html) {
    let tmp = document.createElement("DIV");
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || "";
  }

  useEffect(() => {
    if (eventData) {
      let schedule =
        moment(eventData.schedule[0].date).format("dddd") +
        " " +
        moment(eventData.schedule[0].date).format("MMMM Do") +
        " - " +
        eventData.description;

      console.log("eventData", eventData);
      $("head").prepend(`
        <meta http-equiv="Pragma" content="no-cache">
        <title>${eventData.title}</title>
        <meta property="fb:app_id" content="969675650345598" />
        <meta property="type" content="website" />
        <meta property="url" content="${apiUrl}/event/${eventData.id}" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="${apiUrl}/event/${eventData.id}" />
        <meta property="og:title" content="${eventData.title}" />
        <meta content="image/*" property="og:image:type" />
        <meta property="og:image" content="${
          eventData.upload ? `${apiUrl}${eventData.upload}` : ""
        }" />
        <meta property="og:image:width" content="400" />
        <meta property="og:image:height" content="400" />
        <meta name="msapplication-TileColor" content="#ffffff" />
        <meta name="msapplication-TileImage" content="/ms-icon-144x144.png" />
        <meta name="theme-color" content="#ffffff" />
        <meta name="_token" content="" />
        <meta name="robots" content="noodp" />
        <meta property="title" content="${eventData.title}" />
        <meta property="quote" content="${schedule}" />
        <meta name="description" content="${schedule}" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:hashtag" content="#assessment" />
        <meta property="og:site_name" content="Better Faster Stronger Science" />
      `);
    }
  }, [eventData]);

  return (
    <>
      <Layout className="private-layout-athlete-view-webpage">
        <Layout.Header style={{ height: 90, padding: "0 0px " }}>
          <Row gutter={12} className="event">
            <Col
              xs={24}
              sm={24}
              md={12}
              lg={6}
              className=" public-event-logo"
              style={{ paddingLeft: "0px!important" }}
            >
              <Image src={sidelogo} preview={false} />
            </Col>
          </Row>
        </Layout.Header>

        <Layout.Content
          style={{
            paddingLeft: "0px",
            paddingRight: "0px",
          }}
        >
          {eventData && (
            <Row gutter={12}>
              <Col
                xs={24}
                sm={24}
                md={24}
                lg={24}
                xl={24}
                xxl={24}
                className="col-image-public-event"
              >
                <Row gutter={[2, 2]}>
                  <Col
                    xs={24}
                    sm={24}
                    md={14}
                    lg={14}
                    style={{ paddingRight: "0px !important" }}
                  >
                    <img
                      style={{ width: "100%" }}
                      src={apiUrl + eventData.upload}
                      preview={false}
                    />
                  </Col>

                  <Col
                    xs={24}
                    sm={24}
                    md={10}
                    lg={10}
                    className="col-basic-info"
                    // style={{ paddingLeft: "0px !important" }}
                  >
                    <Space direction="vertical" size={0}>
                      <Typography.Title level={4}>
                        {moment(eventData.schedule[0].date).format("dddd")}
                      </Typography.Title>
                      <Typography.Title level={1}>
                        {moment(eventData.schedule[0].date).format("MMMM Do")}
                      </Typography.Title>
                      <Typography.Title level={3}>
                        {eventData.title}
                      </Typography.Title>

                      <Typography.Paragraph className="typography-description">
                        <span
                          dangerouslySetInnerHTML={{
                            __html: eventData.description,
                          }}
                        />
                      </Typography.Paragraph>
                    </Space>
                  </Col>
                </Row>
              </Col>

              <Col
                xs={24}
                sm={24}
                md={24}
                lg={24}
                xl={24}
                xxl={24}
                className="col-social-media"
              >
                <Row gutter={[2, 2]}>
                  <Col xs={24} sm={24} md={14} lg={14}>
                    <Space className="p-l-md" style={{ paddingTop: "4px" }}>
                      <Typography.Text>SHARE: </Typography.Text>

                      <FacebookShareButton
                        url={
                          apiUrl +
                          "share-media-content/" +
                          (eventData && eventData.id)
                        }
                        quote={eventData && eventData.title}
                        // hashtag={`#${eventData.title}`}
                        description={stripHtml(
                          eventData && eventData.description
                        )}
                        className="Demo__some-network__share-button"
                      >
                        <FacebookIcon
                          size={30}
                          style={{ marginBottom: "-5px", marginLeft: 5 }}
                          round
                        />
                      </FacebookShareButton>

                      <TwitterShareButton
                        url={
                          apiUrl +
                          "share-media-content/" +
                          (eventData && eventData.id)
                        }
                        // title={eventData && eventData.title}
                        title={
                          `Title ${
                            eventData && eventData.title
                          } \nDescription: ${stripHtml(
                            eventData && eventData.description
                          )}\n`.substring(0, 135) + "..."
                        }
                        className="Demo__some-network__share-button"
                      >
                        <TwitterIcon
                          size={30}
                          style={{ marginBottom: "-5px", marginLeft: 5 }}
                          round
                        />
                      </TwitterShareButton>

                      <LinkedinShareButton
                        url={
                          apiUrl +
                          "share-media-content/" +
                          (eventData && eventData.id)
                        }
                        className="Demo__some-network__share-button"
                      >
                        <LinkedinIcon
                          size={30}
                          style={{ marginBottom: "-5px", marginLeft: 5 }}
                          round
                        />
                      </LinkedinShareButton>

                      <EmailShareButton
                        url={
                          apiUrl +
                          "share-media-content/" +
                          (eventData && eventData.id)
                        }
                        subject={eventData && eventData.title}
                        body={
                          (eventData && eventData.title) +
                          "\n" +
                          "When: " +
                          moment(
                            eventData && eventData.schedule[0].date
                          ).format("DD MMM, YYYY") +
                          "-" +
                          moment(
                            eventData &&
                              eventData.schedule[eventData.schedule.length - 1]
                                .date
                          ).format("DD MMM, YYYY") +
                          "\n" +
                          "Description: " +
                          stripHtml(eventData && eventData.description) +
                          "\n"
                        }
                        className="Demo__some-network__share-button"
                      >
                        <EmailIcon
                          size={30}
                          style={{ marginBottom: "-5px", marginLeft: 5 }}
                          round
                        />
                      </EmailShareButton>
                    </Space>
                  </Col>

                  <Col
                    xs={24}
                    sm={24}
                    md={10}
                    lg={10}
                    style={{ paddingLeft: "0px !important" }}
                  >
                    <Button className="btn-book-now" onClick={booking}>
                      BOOK NOW
                    </Button>
                  </Col>
                </Row>
              </Col>

              <Col
                xs={24}
                sm={24}
                md={24}
                lg={24}
                xl={24}
                xxl={24}
                className="col-other-info"
              >
                <Row gutter={24}>
                  <Col xs={24} sm={24} md={8} lg={6}>
                    <Typography.Title level={4} className="m-b-none">
                      DATE & TIME
                    </Typography.Title>

                    <Space direction="vertical">
                      {eventData.schedule.map((item, key) => {
                        return (
                          <Fragment key={key}>
                            <span>
                              {moment(item.date).format("MMM. DD YYYY")} -{" "}
                              {item.time_start}
                              {" - "}
                              {item.time_end}
                            </span>
                          </Fragment>
                        );
                      })}
                    </Space>
                  </Col>

                  <Col xs={24} sm={24} md={8} lg={6} className="m-b-md">
                    <Typography.Title level={4} className="m-b-none">
                      CATEGORIES
                    </Typography.Title>

                    <Space direction="vertical">
                      {eventData.category.map((item, key) => {
                        return (
                          <Fragment key={key}>
                            <span key={key}>{item.category}</span>
                          </Fragment>
                        );
                      })}
                    </Space>
                  </Col>

                  <Col xs={24} sm={24} md={8} lg={6} className="m-b-md">
                    <Space direction="vertical" className="space-date-time">
                      <Typography.Title level={4} className="m-b-none">
                        LOCATION
                      </Typography.Title>

                      <span>{eventData && eventData.location}</span>

                      <span>{eventData && eventData.address_1}</span>

                      <span>
                        {eventData && eventData.city}
                        {", "}
                        {eventData && eventData.state}
                        {", "}
                        {eventData && eventData.zip}
                      </span>

                      <span>{eventData && eventData.locations.country}</span>
                    </Space>
                  </Col>

                  <Col xs={24} sm={24} md={8} lg={6} className="m-b-md">
                    <Typography.Title level={4} className="m-b-none">
                      TAGS
                    </Typography.Title>
                    <Space direction="vertical">
                      {eventData.tag.map((item, key) => {
                        return (
                          <Fragment key={key}>
                            <span key={key}>{item.tag}</span>
                          </Fragment>
                        );
                      })}
                    </Space>
                  </Col>
                </Row>
                <br />
                <br />
                <br />
              </Col>
            </Row>
          )}
        </Layout.Content>

        <Layout.Footer style={{ textAlign: "center" }}>
          © Copyright {process.env.REACT_APP_APP_NAME}{" "}
          {new Date().getFullYear()} Alright Reserve.
        </Layout.Footer>
      </Layout>
    </>
  );
}
