import {
  Button,
  Card,
  Col,
  Form,
  Layout,
  Modal,
  notification,
  Popconfirm,
  Row,
  Space,
  Table,
  Tooltip,
  Typography,
} from "antd";
import React, { useState, useEffect } from "react";
import { faHandHoldingUsd } from "@fortawesome/pro-regular-svg-icons";
import {
  TableGlobalSearch,
  TablePageSize,
  TablePagination,
  TableShowingEntries,
} from "../../Components/ComponentTableFilter";
import $ from "jquery";
// import { useHistory } from "react-router-dom";
import { GET, POST } from "../../../../providers/useAxiosQuery";
// import companyInfo from "../../../../providers/companyInfo";
import ComponentHeader from "../../Components/ComponentHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FaTimes } from "react-icons/fa";
import FloatInputRate from "../../../../providers/FloatInputRate";
import validateRules from "../../../../providers/validateRules";

export default function PageRevenueManagersPendingPayment({
  match,
  permission,
}) {
  // const history = useHistory();
  // const { Panel } = Collapse;
  // const userdata = companyInfo().userData;
  const [tableFilter, setTableFilter] = useState({
    page: 1,
    page_size: 50,
    search: "",
    sort_field: "strcreated",
    sort_order: "desc",
    admin_manager_funds_status: "Pending",
  });

  const [tableTotal, setTableTotal] = useState(0);
  const [dataSource, setDataSource] = useState([]);
  const {
    refetch: refetchTable,
    isLoading: isLoadingTable,
    isFetching: isFetchingTable,
  } = GET(
    `api/v1/admin_dashboard_revenue?${$.param(tableFilter)}`,
    "revenue_manager",
    (res) => {
      if (res.success) {
        console.log("revenue_manager", res);
        setDataSource(res.data && res.data.data);
        setTableTotal(res.data.total);
      }
    }
  );

  const handleTableChange = (pagination, filters, sorter) => {
    setTableFilter({
      ...tableFilter,
      sort_field: sorter.columnKey,
      sort_order: sorter.order ? sorter.order.replace("end", "") : null,
      // page: 1,
    });
  };

  const [searchText, setSearchText] = useState("");
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setTableFilter({
        ...tableFilter,
        search: searchText,
        page: 1,
      });
    }, 1500);
    return () => {
      clearTimeout(timeoutId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText]);

  useEffect(() => {
    refetchTable();
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableFilter]);

  // const [toggleModalPreview, setToggleModalPreview] = useState({
  //   show: false,
  //   data: null,
  //   type: "Starter",
  // });

  const [selectionType, setSelectionType] = useState([]);
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      // console.log(
      //   `selectedRowKeys: ${selectedRowKeys}`,
      //   "selectedRows: ",
      //   selectedRows
      // );

      setSelectionType(selectedRowKeys);
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User",
      // Column configuration not to be checked
      name: record.name,
    }),
  };

  const handleDeactivate = () => {
    let data = {
      selected: selectionType,
    };
    console.log("handleDeactivate", data);

    mutateDeactivate(data, {
      onSuccess: (res) => {
        if (res.success) {
          console.log(res);
          notification.success({
            message: "Success",
            description: "Successfully Made Payment",
          });
        }
      },
    });
  };

  const { mutate: mutateDeactivate, isLoading: isLoadingDeactivate } = POST(
    "api/v1/manager_funds",
    "revenue_manager"
  );

  const [modalTransfer, setModalTransfer] = useState({
    show: false,
    data: null,
  });
  const [form] = Form.useForm();

  const onFinishForm = (values) => {
    values = {
      ...values,
      assessment_id: modalTransfer.data.id,
      manager_id: modalTransfer.data.manager_id,
      charge_id: modalTransfer.data.charge_id,
    };

    console.log("onFinishForm", values);
    mutateSend(values, {
      onSuccess: (res) => {
        if (res.success) {
          console.log("onFinishForm", res);
          notification.success({
            message: "Success",
            description: "Successfully transfered funds.",
          });
          setModalTransfer({
            show: false,
            data: null,
          });
        } else {
          notification.error({
            message: "Error",
            description: "Something went wrong.",
          });
        }
      },
    });
  };

  const { mutate: mutateSend, isLoading: isLoadingSend } = POST(
    "api/v1/tranfer_charge_amount",
    "revenue_manager"
  );

  useEffect(() => {
    if (!modalTransfer.show) {
      setModalTransfer({
        show: false,
        data: null,
      });
      form.resetFields();
    }
  }, [modalTransfer.show]);

  return (
    <>
      <ComponentHeader
        title="Payment"
        sub_title="MANAGERS PENDING"
        icon={faHandHoldingUsd}
      />

      <Card
        className="p-b-lg card-min-height"
        id="PageRevenueManagersPendingPayment"
      >
        <Row gutter={12}>
          <Col xs={24} sm={24} md={16} lg={16} xl={18} className="m-b-sm">
            <Space>
              <TablePageSize
                paginationFilter={tableFilter}
                setPaginationFilter={setTableFilter}
              />
              {selectionType.length > 0 && (
                <Popconfirm
                  title="Click confirm to pay this manager?"
                  onConfirm={handleDeactivate}
                  okText="Confirm"
                  cancelText="Cancel"
                >
                  <Button
                    type="primary"
                    className="btn-main-outline-2"
                    loading={isLoadingDeactivate}
                  >
                    <FontAwesomeIcon icon={faTimes} /> &nbsp; PAYMENT MADE
                  </Button>
                </Popconfirm>
              )}
            </Space>
          </Col>
          <Col xs={24} sm={24} md={8} lg={8} xl={6}>
            <TableGlobalSearch
              paginationFilter={searchText}
              setPaginationFilter={setSearchText}
            />
          </Col>
        </Row>
        <Row gutter={24}>
          <Col xs={24} sm={24} md={24} className="m-t-sm m-b-sm">
            <Table
              dataSource={dataSource && dataSource}
              rowKey={(record) => record.id}
              loading={isLoadingTable || isFetchingTable}
              pagination={false}
              bordered={false}
              onChange={handleTableChange}
              className="ant-table-default ant-table-striped"
              scroll={{ x: "max-content" }}
              rowSelection={{
                type: selectionType,
                ...rowSelection,
              }}
            >
              <Table.Column
                title="Purchase Date"
                key="strcreated"
                dataIndex="strcreated"
                sorter={true}
                defaultSortOrder={"descend"}
                width={"130px"}
              />
              <Table.Column
                title="Customer Name"
                key="name"
                dataIndex="name"
                sorter={true}
                // render={(text, record) => {
                //   return <Link to={"#"}>{record.firstname}</Link>;
                // }}
                width={"150px"}
              />
              <Table.Column
                title="City"
                key="city"
                dataIndex="city"
                sorter={true}
                width={"100px"}
              />
              <Table.Column
                title="State"
                key="state"
                dataIndex="state"
                sorter={true}
                width={"100px"}
              />
              <Table.Column
                title="Program"
                key="type"
                dataIndex="type"
                sorter={true}
                width={"150px"}
              />
              <Table.Column
                title="Amount"
                key="amount"
                dataIndex="amount"
                sorter={true}
                width={"150px"}
                render={(text, record) => {
                  let total = 0;
                  if (text === "0") {
                    total = record.amount_value;
                  } else {
                    let amount = record.amount_value.split(".");
                    if (amount[1]) {
                      text =
                        amount[0] + "." + amount[1].toString().substr(0, 2);
                    } else {
                      text = amount[0];
                    }
                  }

                  if (record.role === "Manager") {
                    if (record.bank_id) {
                      if (record.transfer === "") {
                        return (
                          <Typography.Text
                            className={
                              text > 0
                                ? "companyColor3 cursor-pointer"
                                : "cursor-pointer"
                            }
                            onClick={() => {
                              setModalTransfer({
                                show: true,
                                data: record,
                              });
                              form.setFieldsValue({
                                amount: text,
                              });
                            }}
                          >{`$${text}`}</Typography.Text>
                        );
                      } else {
                        return `$${text}`;
                      }
                    } else {
                      return (
                        <Tooltip title={"Manager no bank details"}>
                          <Typography.Text className="companyColor9">{`$${text}`}</Typography.Text>
                        </Tooltip>
                      );
                    }
                  } else {
                    return `$${text}`;
                  }
                }}
              />
              <Table.Column
                title="BFSS Manager"
                key="manager_name"
                dataIndex="manager_name"
                sorter={true}
                width={"130px"}
                render={(text, record) => {
                  return (
                    <Tooltip title={`${text} - ${record.role}`}>
                      <span>{text}</span>
                    </Tooltip>
                  );
                }}
              />
            </Table>
          </Col>
          <Col xs={24} sm={24} md={24}>
            <Space className="ant-space-flex-space-between table-paid">
              <TableShowingEntries />

              <TablePagination
                paginationFilter={tableFilter}
                setPaginationFilter={setTableFilter}
                setPaginationTotal={tableTotal}
                showLessItems={true}
                showSizeChanger={false}
                parentClass="table-paid"
              />
            </Space>
          </Col>
        </Row>

        <Modal
          title="FORM TRANSFER"
          visible={modalTransfer.show}
          width={400}
          onCancel={(e) =>
            setModalTransfer({
              show: false,
              data: null,
            })
          }
          maskStyle={{ zIndex: 2 }}
          zIndex={2}
          closeIcon={<FaTimes />}
          className="assessment-modal"
          bodyStyle={{ padding: "22px 15px 0px 15px" }}
          footer={[
            <Space>
              <Button
                className="btn-main-outline-2"
                loading={isLoadingSend}
                onClick={(e) => {
                  form
                    .validateFields()
                    .then((values) => {
                      onFinishForm(values);
                    })
                    .catch((info) => {
                      console.log("Validate Failed:", info);
                    });
                }}
              >
                SUBMIT
              </Button>
            </Space>,
          ]}
        >
          <Form form={form}>
            <Form.Item name="amount" rules={[validateRules.required]}>
              <FloatInputRate label="Amount" placeholder="Amount" required />
            </Form.Item>
          </Form>
        </Modal>

        <br />
      </Card>
    </>
  );
}
