import {
  //  degrees,
  PDFDocument,
  rgb,
  StandardFonts,
} from "pdf-lib";
import download from "downloadjs";
import moment from "moment";
// import newtonPound from "./newtonPound";
// import truncateDecimalNumber from "./truncateDecimalNumber";

const pdfStarter = async (pdfUrl, data, display_data) => {
  let dataRender = [
    [
      [
        {
          value: `${data.name}`,
          x: 185,
          y: ["+", 240],
          fontSize: 46,
          fontWeight: "Bold",
          color: rgb(1, 1, 1),
          posxx: true,
          name: "p26_dsi_score",
        },
      ],
    ], // page 1
    [], // page 2
    [
      [
        {
          value: `${
            data.p_3_age === null ||
            data.p_3_age === 0 ||
            data.p_3_age === "EF" ||
            data.p_3_age === "AI" ||
            data.p_3_age === "ART"
              ? "NA"
              : `${data.p_3_age}`
          }`,
          x: 85,
          y: ["+", 309],
          fontSize: 18,
          color: rgb(0, 0, 0),
          posxx: true,
        },
        {
          value: `${
            data.p_3_sport === null ||
            data.p_3_sport === 0 ||
            data.p_3_sport === "EF" ||
            data.p_3_sport === "AI" ||
            data.p_3_sport === "ART"
              ? "NA"
              : `${data.p_3_sport}`
          }`,
          x: 185,
          y: ["+", 309],
          fontSize: 18,
          color: rgb(0, 0, 0),
          posxx: true,
        },
        {
          value: `${
            data.p_3_weight === null ||
            data.p_3_weight === 0 ||
            data.p_3_weight === "EF" ||
            data.p_3_weight === "AI" ||
            data.p_3_weight === "ART"
              ? "NA"
              : `${data.p_3_weight}`
          }`,
          x: 828,
          y: ["+", 310],
          fontSize: 18,
          color: rgb(0, 0, 0),
          posxx: true,
        },
        {
          value: `${
            data.p_3_gender === null ||
            data.p_3_gender === 0 ||
            data.p_3_gender === "EF" ||
            data.p_3_gender === "AI" ||
            data.p_3_gender === "ART"
              ? "NA"
              : `${data.p_3_gender}`
          }`,
          x: 913,
          y: ["+", 309],
          fontSize: 18,
          color: rgb(0, 0, 0),
          posxx: true,
        },
      ],
      [
        {
          value: `${
            data.p_3_rebound_jump === null ||
            data.p_3_rebound_jump === 0 ||
            data.p_3_rebound_jump === "EF" ||
            data.p_3_rebound_jump === "AI" ||
            data.p_3_rebound_jump === "ART"
              ? "NA"
              : `${data.p_3_rebound_jump}`
          }`,
          x: 285,
          y: ["+", 135],
          fontSize: 18,
          color: rgb(0, 0, 0),
          name: "p3_cmj_rsi_no",
        },
        {
          value: `${
            data.p_3_countermovement_jump === null ||
            data.p_3_countermovement_jump === 0 ||
            data.p_3_countermovement_jump === "EF" ||
            data.p_3_countermovement_jump === "AI" ||
            data.p_3_countermovement_jump === "ART"
              ? "NA"
              : `${data.p_3_countermovement_jump}"`
          }`,
          x: 432,
          y: ["+", 135],
          fontSize: 18,
          color: rgb(0, 0, 0),
          name: "p3_es_height",
        },
        {
          value: `${
            data.p_3_squat_jump === null ||
            data.p_3_squat_jump === 0 ||
            data.p_3_squat_jump === "EF" ||
            data.p_3_squat_jump === "AI" ||
            data.p_3_squat_jump === "ART"
              ? "NA"
              : `${data.p_3_squat_jump}"`
          }`,
          x: 584,
          y: ["+", 135],
          fontSize: 18,
          color: rgb(0, 0, 0),
          name: "p3_sj_height",
        },
        {
          value: `${
            data.p_3_ten_yard_sprint_jump === null ||
            data.p_3_ten_yard_sprint_jump === 0 ||
            data.p_3_ten_yard_sprint_jump === "EF" ||
            data.p_3_ten_yard_sprint_jump === "AI" ||
            data.p_3_ten_yard_sprint_jump === "ART"
              ? "NA"
              : `${data.p_3_ten_yard_sprint_jump}s`
          }`,
          x: 736,
          y: ["+", 135],
          fontSize: 18,
          color: rgb(0, 0, 0),
          name: "p3_sprint_time_tem_meter",
        },
        {
          value: `${
            data.p_3_isometric_mid_thigh_pull === null ||
            data.p_3_isometric_mid_thigh_pull === 0 ||
            data.p_3_isometric_mid_thigh_pull === "EF" ||
            data.p_3_isometric_mid_thigh_pull === "AI" ||
            data.p_3_isometric_mid_thigh_pull === "ART"
              ? "NA"
              : `${data.p_3_isometric_mid_thigh_pull}lbs`
          }`,
          x: 890,
          y: ["+", 135],
          fontSize: 18,
          color: rgb(0, 0, 0),
          name: "p3_isometric_pull",
        },
      ],
      [
        {
          value: `${
            data.p_3_age === null ||
            data.p_3_age === 0 ||
            data.p_3_age === "EF" ||
            data.p_3_age === "AI" ||
            data.p_3_age === "ART"
              ? "NA"
              : `${data.p_3_age}`
          }`,
          x: 118,
          y: ["+", 52],
          fontSize: 20,
          color: rgb(0, 0, 0),
          name: "p3_p_3_age1",
        },
        {
          value: `${
            data.p_3_rebound_jump === null ||
            data.p_3_rebound_jump === 0 ||
            data.p_3_rebound_jump === "EF" ||
            data.p_3_rebound_jump === "AI" ||
            data.p_3_rebound_jump === "ART"
              ? "NA"
              : `${data.p_3_rebound_jump_average}`
          }`,
          x: 285,
          y: ["+", 60],
          fontSize: 18,
          color: rgb(0, 0, 0),
          name: "p3_age_avg_cmj_rsi_no_sum",
        },
      ],
      [
        {
          value: `${
            data.p_3_rebound_jump === null ||
            data.p_3_rebound_jump === 0 ||
            data.p_3_rebound_jump === "EF" ||
            data.p_3_rebound_jump === "AI" ||
            data.p_3_rebound_jump === "ART"
              ? ""
              : `${data.p_3_rebound_jump_benchmark}`
          }`,
          x: 280,
          y: ["-", 2],
          fontSize: 18,
          color: rgb(0, 0, 0),
          name: "p3_average_1",
        },
      ],
      [
        {
          value: `${
            data.p_3_rebound_jump === null ||
            data.p_3_rebound_jump === 0 ||
            data.p_3_rebound_jump === "EF" ||
            data.p_3_rebound_jump === "AI" ||
            data.p_3_rebound_jump === "ART"
              ? ""
              : `PERCENTILE`
          }`,
          x: 280,
          y: ["-", 25],
          fontSize: 18,
          color: rgb(0, 0, 0),
          name: "p3_average_1",
        },
      ],
      [
        {
          value: `${
            data.p_3_rebound_jump === null ||
            data.p_3_rebound_jump === 0 ||
            data.p_3_rebound_jump === "EF" ||
            data.p_3_rebound_jump === "AI" ||
            data.p_3_rebound_jump === "ART"
              ? "NA"
              : ``
          }`,
          x: 280,
          y: ["-", 15],
          fontSize: 18,
          color: rgb(0, 0, 0),
          name: "p3_average_1",
        },
      ],
    ], // page 3
    [], // page 4
    [], // page 5
    [], // page 6
    [], // page 7
    [], // page 8
    [], // page 9
  ];

  // Load a PDFDocument from the existing PDF bytes
  const pdfDoc = await PDFDocument.load(pdfUrl);
  // console.log("pdfDoc", pdfDoc);

  // Embed the HelveticaBold font
  const helveticaFont = await pdfDoc.embedFont(StandardFonts.HelveticaBold);

  // Get the first page of the document
  const pages = pdfDoc.getPages();
  // console.log("pages", pages);

  for (let p = 0; p < pages.length; p++) {
    if (dataRender && dataRender[p]?.length > 0) {
      const {
        // width,
        height,
      } = pages[p].getSize();
      for (let x = 0; x < dataRender[p].length; x++) {
        let drpx = dataRender[p][x];
        for (let y = 0; y < drpx.length; y++) {
          let drpxy = drpx[y];
          let yVal = 0;

          if (drpxy.y[0] === "+") {
            yVal = height / 2 + drpxy.y[1];
          } else {
            yVal = height / 2 - drpxy.y[1];
          }

          const textWidth = helveticaFont.widthOfTextAtSize(
            drpxy.value,
            drpxy.fontSize
          );

          let subtractXPos = textWidth / 2;

          if (display_data && display_data.length > 0) {
            let show_data = display_data.filter(
              (itemFilter) => itemFilter.name === drpxy.name
            );

            let show = 1;
            if (show_data && show_data.length > 0) {
              show = show_data[0].status;
            }

            if (show) {
              pages[p].drawText(`${drpxy.value}`, {
                x: drpxy.posxx ? drpxy.x : drpxy.x - subtractXPos,
                y: yVal,
                size: drpxy.fontSize,
                font: helveticaFont,
                color: drpxy.color,
                // rotate: drpxy.degrees(drpxy.degrees),
              });
            }
          } else {
            pages[p].drawText(`${drpxy.value}`, {
              x: drpxy.posxx ? drpxy.x : drpxy.x - subtractXPos,
              y: yVal,
              size: drpxy.fontSize,
              font: helveticaFont,
              color: drpxy.color,
              // rotate: drpxy.degrees(drpxy.degrees),
            });
          }
        }
      }
    }
  }

  // Serialize the PDFDocument to bytes (a Uint8Array)
  const pdfBytes = await pdfDoc.save();
  download(
    pdfBytes,
    `${moment().format("YYYY")}-BFS-New-Starter-Report-${data.name}.pdf`,
    "application/pdf"
  );
};

export default pdfStarter;
