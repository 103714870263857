import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  List,
  Row,
  Typography,
  Collapse,
  Space,
  notification,
  Modal,
  Form,
  Table,
  Popconfirm,
} from "antd";

import {
  faAlarmClock,
  faEye,
  faMapMarkerPlus,
  faRunning,
  faSchool,
  faSearch,
  faTicketAlt,
  faUser,
  faUsers,
} from "@fortawesome/pro-solid-svg-icons";
import { faHome } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { GET, POST, UPDATE } from "../../../../providers/useAxiosQuery";
import { useHistory } from "react-router-dom";
import moment from "moment";
import $ from "jquery";

import FloatInput from "../../../../providers/FloatInput";
import FloatSelect from "../../../../providers/FloatSelect";
import FloatAutoCompleteOrg from "../../../../providers/FloatAutoCompleteOrg";
import optionCountryCodes from "../../../../providers/optionCountryCodes";
import optionStateCodesUnitedState from "../../../../providers/optionStateCodesUnitedState";
import optionStateCodesCanada from "../../../../providers/optionStateCodesCanada";
import optionOrganizationType from "../../../../providers/optionOrganizationType";
import companyInfo from "../../../../providers/companyInfo";

import ComponentHeader from "../../Components/ComponentHeader";
import ComponentDashboard from "../../Components/ComponentDashboard";
import ComponentsPendingApproval from "../../PageAdmin/PageDashboard/Components/ComponentsPendingApproval";

export default function PageDashboard(props) {
  const { Panel } = Collapse;
  let history = useHistory();
  let userdata = companyInfo().userData;
  console.log("userdata", userdata);
  const { permission } = props;

  // useEffect(() => {
  //   console.log("dashboard_Filter", dataFilter);
  // }, [dataFilter]);

  const [organizationData, setOrganizationData] = useState([]);
  // GET(
  //   `api/v1/manager_dashboard_pending_list?user_id=${userdata.id}`,
  //   "manager_dashboard_pending_list",
  //   (res) => {
  //     if (res.success) {
  //       console.log("manager_dashboard_pending_list", res);
  //       setOrganizationData(res.data);
  //     }
  //   }
  // );

  const [isModalApprove, setIsModalApprove] = useState(false);
  const [isDataApprove, setIsDataApprove] = useState([]);

  const { mutate: mutateApprove, isLoading: isLoadingApprove } = UPDATE(
    "api/v1/organization",
    "manager_dashboard_pending_list"
  );

  const deleteOrg = () => {
    // console.log("isDataEdit", isDataEdit);
    mutateApprove(
      { id: isDataEdit.id, status: "Deleted" },
      {
        onSuccess: (res) => {
          if (res.success) {
            notification.success({
              message: "Success",
              description: "Successfully deleted",
            });
            setIsDataApprove([]);
            setIsModalEdit(false);
          }
        },
      }
    );
  };

  const [form] = Form.useForm();
  const [isModalEdit, setIsModalEdit] = useState(false);
  const [isDataEdit, setIsDataEdit] = useState([]);
  // const state = optionStateCodesUnitedState();

  const onFinishForm = (values) => {
    let data = {
      ...values,
      status: "Approved",
      id: isDataEdit.id,
    };
    console.log("onFinishForm", data);
    mutateApproveOrg(data, {
      onSuccess: (res) => {
        if (res.success) {
          notification.success({
            message: "Success",
            description: "Successfully Updated",
          });
          // console.log("res", res);
          setIsModalEdit(false);
          setIsModalApprove1(false);
          // setIsDataEdit([]);
          // setIsModalEdit(false);
        }
      },
    });
  };

  const { mutate: mutateApproveOrg, isLoading: isLoadingApproveOrg } = POST(
    "api/v1/approve_organization",
    "manager_dashboard_pending_list"
  );

  const stateUS = optionStateCodesUnitedState();
  const stateCA = optionStateCodesCanada();

  const [stateLabel, setStateLabel] = useState("State");
  const [zipLabel, setZipLabel] = useState("Zip Code");
  const [optionState, setOptionState] = useState(stateUS);
  const [optionZip, setOptionZip] = useState(/(^\d{5}$)|(^\d{5}[ -]\d{4}$)/);

  const handleCountry = (val, opt) => {
    if (val === "United States") {
      setStateLabel("State");
      setZipLabel("Zip Code");
      setOptionState(stateUS);
      setOptionZip(/(^\d{5}$)|(^\d{5}[ -]\d{4}$)/);
    } else if (val === "Canada") {
      setStateLabel("County");
      setZipLabel("Postal Code");
      setOptionState(stateCA);
      setOptionZip(/^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/);
    } else {
      setStateLabel("State");
      setZipLabel("Zip Code");
      setOptionState(stateUS);
      setOptionZip(/(^\d{5}$)|(^\d{5}[ -]\d{4}$)/);
    }
    setCityOption([]);
    form.resetFields(["state", "zip", "city"]);
  };

  // dashboard assessment view
  const [dataFilter, setDataFilter] = useState({
    date: moment().format("MM/DD/YYYY"),
    type: "Group",
    search: "",
    category: "",
    tag: "",
    country: "",
    state: "",
    city: "",
    manager_id: userdata.id,
  });

  const [stateLabel1, setStateLabel1] = useState("State");
  const [optionState1, setOptionState1] = useState([]);
  const [searchDataForm, setSearchDataForm] = useState("");

  const [tableFilter, setTableFilter] = useState({
    search: "",
    state: "",
    country: "",
  });

  const [formSearch] = Form.useForm();
  const [orgSearchData, setOrgSearchData] = useState([]);
  const { refetch: refetchSearchData } = GET(
    `api/v1/check_organization_dashboard?${$.param(tableFilter)}`,
    "check_organization_dashboard",
    (res) => {
      if (res.success) {
        console.log("data", res);
        let arr = [];
        res.data.map((item) => {
          arr.push({
            label: item.name,
            value: item.name,
            json: item,
          });
          return "";
        });
        setOrgSearchData(arr);
      }
    }
  );

  const onSearch = (values) => {
    console.log("onSearch", values);
    setTableFilter(values);
  };

  const handleSearchSelect = (val, opt) => {
    if (val) {
      setSearchDataForm(val);
    } else {
      setSearchDataForm("none");
    }
  };

  const [orgSelectedData, setOrgSelectedData] = useState([]);
  const handleSearchAuto = (val, opt) => {
    if (val) {
      setSearchDataForm(val);
      setOrgSelectedData([opt["data-json"]]);
    } else {
      setOrgSelectedData([]);
      setSearchDataForm("none");
    }
  };

  const [countryOption, setCountryOption] = useState([]);
  const [cityOption, setCityOption] = useState([]);
  const { data: data_get_manager_state_area } = GET(
    "api/v1/get_manager_state_area2",
    "get_manager_state_area",
    (res) => {
      if (res.success) {
        console.log("get_manager_state_area", res);
        setCountryOption(res.countries);
      }
    }
  );

  const handleCountry1 = (val, opt) => {
    if (val === "United States") {
      setStateLabel1("State");
      let option_states = [];
      data_get_manager_state_area.states.map((state, key) => {
        let find = stateUS.find((p) => p.value == state.value);
        if (find) {
          option_states.push(find);
        }
      });
      setOptionState1(option_states);
    } else if (val === "Canada") {
      setStateLabel1("County");
      let option_states = [];
      data_get_manager_state_area.states.map((state, key) => {
        let find = stateCA.find((p) => p.value == state.value);
        if (find) {
          option_states.push(find);
        }
      });
      setOptionState1(option_states);
    }
    // else {
    //   setStateLabel1("State");
    //   setOptionState1(stateUS);
    // }
    // console.log("searchDataForm", val);
    if (val) {
      setSearchDataForm(val);
    } else {
      setSearchDataForm("none");
    }
  };

  const handleUseOrg = () => {
    console.log("orgSelectedData", orgSelectedData[0]);
    form.setFieldsValue({
      selected_id: orgSelectedData[0].id,
      name: orgSelectedData[0].name,
      organization_type: orgSelectedData[0].organization_type,
      country: orgSelectedData[0].country,
      address1: orgSelectedData[0].address1,
      address2: orgSelectedData[0].address2,
      city: orgSelectedData[0].city,
      state: orgSelectedData[0].state,
      zip: orgSelectedData[0].zip,
    });
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (searchDataForm) {
        formSearch.submit();
        setSearchDataForm("");
      }
    }, 0);
    return () => {
      clearTimeout(timeoutId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchDataForm]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      // console.log("searchDataFormuseState", searchDataForm);
      refetchSearchData();
    }, 2000);
    return () => {
      clearTimeout(timeoutId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableFilter]);

  useEffect(() => {
    if (isModalEdit === false) {
      form.resetFields();
      formSearch.resetFields();
      setOrgMembers([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isModalEdit]);

  const { mutate: mutateGetOrgMember } = POST(
    "api/v1/get_organization_member",
    "get_organization_member"
  );

  const [orgMembers, setOrgMembers] = useState([]);
  const getOrgMember = (item) => {
    // console.log("getOrgMember", item);
    let data = {
      id: item.id,
    };

    mutateGetOrgMember(data, {
      onSuccess: (res) => {
        if (res.success) {
          console.log("getOrgMember", res);
          setOrgMembers(res.data);
        }
      },
    });
  };

  const [isModalApprove1, setIsModalApprove1] = useState(false);

  return (
    <>
      <ComponentHeader
        title={permission}
        sub_title="ALL FEATURES"
        icon={faHome}
      />

      <Card
        className="card-quick-links p-b-lg card-min-height"
        id="PageDashboard"
      >
        <h1>QUICK LINKS</h1>
        <Row gutter={24}>
          <Col xs={24} sm={24} md={24} lg={24} xl={16}>
            <Card
              // title="QUICK LINKS"
              className="card-transparent-head border-bottom-none no-side-border p-none"
              bodyStyle={{ padding: "0px" }}
            >
              <Row gutter={[12, 12]} className="ant-row-quick-link">
                <Col xs={12} sm={12} md={6}>
                  <Button
                    type="link"
                    className="ant-btn-quick-link"
                    onClick={(e) => history.push("/user/view-edit-active")}
                  >
                    <FontAwesomeIcon icon={faUser} />
                    <Typography.Text>
                      View / Edit
                      <br />
                      Users
                    </Typography.Text>
                  </Button>
                </Col>
                <Col xs={12} sm={12} md={6}>
                  <Button
                    type="link"
                    className="ant-btn-quick-link"
                    onClick={(e) => history.push("/pending-assessments")}
                  >
                    <FontAwesomeIcon icon={faAlarmClock} />
                    <Typography.Text>
                      Pending
                      <br />
                      Assessments
                    </Typography.Text>
                  </Button>
                </Col>
                <Col xs={12} sm={12} md={6}>
                  <Button
                    type="link"
                    className="ant-btn-quick-link"
                    onClick={(e) => history.push("/user/search-athletes")}
                  >
                    <FontAwesomeIcon icon={faSearch} />
                    <Typography.Text>
                      Search
                      <br />
                      Athletes
                    </Typography.Text>
                  </Button>
                </Col>
                <Col xs={12} sm={12} md={6}>
                  <Button
                    type="link"
                    className="ant-btn-quick-link"
                    onClick={(e) => history.push("/organization/add-new")}
                  >
                    <FontAwesomeIcon icon={faMapMarkerPlus} />
                    <Typography.Text>
                      Add New
                      <br />
                      Organization
                    </Typography.Text>
                  </Button>
                </Col>
                <Col xs={12} sm={12} md={6}>
                  <Button
                    type="link"
                    className="ant-btn-quick-link"
                    onClick={(e) => history.push("/organization/view-edit")}
                  >
                    <FontAwesomeIcon icon={faSchool} />
                    <Typography.Text>
                      View / Edit
                      <br />
                      Organization
                    </Typography.Text>
                  </Button>
                </Col>
                <Col xs={12} sm={12} md={6}>
                  <Button
                    type="link"
                    className="ant-btn-quick-link"
                    onClick={(e) =>
                      history.push("/event-calendar/private-assessment")
                    }
                  >
                    <FontAwesomeIcon icon={faRunning} />
                    <Typography.Text>
                      Private
                      <br />
                      Assessments
                    </Typography.Text>
                  </Button>
                </Col>
                <Col xs={12} sm={12} md={6}>
                  <Button
                    type="link"
                    className="ant-btn-quick-link"
                    onClick={(e) =>
                      history.push("/event-calendar/group-assessment")
                    }
                  >
                    <FontAwesomeIcon icon={faUsers} />
                    <Typography.Text>
                      Group
                      <br />
                      Assessments
                    </Typography.Text>
                  </Button>
                </Col>
                <Col xs={12} sm={12} md={6}>
                  <Button
                    type="link"
                    className="ant-btn-quick-link"
                    onClick={(e) => history.push("/ticketing")}
                  >
                    <FontAwesomeIcon icon={faTicketAlt} />
                    <Typography.Text>
                      Ticketing
                      <br />
                      System
                    </Typography.Text>
                  </Button>
                </Col>
              </Row>
            </Card>

            <ComponentDashboard
              dataFilter={dataFilter}
              setDataFilter={setDataFilter}
              column={12}
              column_lg={8}
            />
          </Col>

          <Col xs={24} sm={24} md={8} lg={24} xl={8}>
            <ComponentsPendingApproval />
          </Col>
        </Row>
      </Card>
    </>
  );
}
