import React, { useState, useEffect } from "react";
import {
  Card,
  Col,
  Row,
  Collapse,
  Form,
  Button,
  Modal,
  notification,
  message,
  Space,
} from "antd";
import ComponentHeader from "../../../Components/ComponentHeader";
import {
  faCalendarAlt,
  faTrashAlt,
  faSave,
  faTrash,
  faCopy,
} from "@fortawesome/pro-regular-svg-icons";

import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import moment from "moment";
import FloatInput from "../../../../../providers/FloatInput";
import FloatDatePicker from "../../../../../providers/FloatDatePicker";
import FloatTimePicker from "../../../../../providers/FloatTimePicker";
import { DELETE, GET, POST } from "../../../../../providers/useAxiosQuery";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useHistory } from "react-router-dom";
import companyInfo from "../../../../../providers/companyInfo";
const userData = companyInfo().userData;

export default function PageEventPrivateCalendar({ match, permission }) {
  const { Panel } = Collapse;
  const [form] = Form.useForm();
  const [formCreate] = Form.useForm();
  const subTitle = "ADD / EDIT ";
  const history = useHistory();

  const validator = {
    require: {
      required: true,
      message: "This field is required",
    },
    email: {
      type: "email",
      message: "Please enter a valid email",
    },
  };

  const [dataTable, setDataTable] = useState([]);
  const {} = GET("api/v1/get_all_schedule", "event_schedule_private", (res) => {
    if (res.success) {
      console.log(res);
      let arr = [];
      res.data.map((item, key) => {
        arr.push({
          id: item.id,
          title: "Schedule",
          start: item.start,
          end: item.end,
          data: item,
        });
      });
      setDataTable(arr);
    }
  });

  const [isModal, setIsModal] = useState(false);
  const [dataRemove, setDataRemove] = useState([]);
  const handleEventClick = (e) => {
    let row = e.event._def.extendedProps.data;
    console.log("onSelect", row.date);
    console.log("onSelect now", moment());

    if (moment(row.date) <= moment()) {
      message.error("Warning date is on the past or today.");
    } else {
      setDataRemove([row]);
      setIsModal(true);
      form.setFieldsValue({
        date: moment(row.date),
        time_start: moment(row.time_start, "HH:mm"),
        time_end: moment(row.time_end, "HH:mm"),
        id: row.id,
      });
    }
  };

  useEffect(() => {
    if (isModal === false) {
      form.resetFields();
      setDataRemove([]);
    }
  }, [isModal]);

  const key = `open${Date.now()}`;
  const close = () => {
    history.push("/profile");
  };
  const btn = (
    <Button
      type="primary"
      size="small"
      onClick={() => {
        close();
        notification.close(key);
      }}
    >
      Click Here
    </Button>
  );

  const [noBankDetails, setNoBankDetails] = useState(false);
  GET("api/v1/get_bank_details", "get_bank_details", (res) => {
    console.log("get_bank_details", res);
    if (res.success) {
      setNoBankDetails(res.success);
    } else {
      setNoBankDetails(res.success);
    }
  });

  const onFinishForm = (values) => {
    let data = {
      ...values,
      date: values.date.format("YYYY-MM-DD"),
      time_start: values.time_start.format("HH:mm"),
      time_end: values.time_end.format("HH:mm"),
    };

    console.log("onFinishForm", data);

    mutateAdd(data, {
      onSuccess: (res) => {
        if (res.success) {
          notification.success({
            message: "Success",
            description: "Successfully Submitted",
          });
          setIsModal(false);
        }
      },
    });
  };

  const { mutate: mutateAdd, isLoading: isLoadingAdd } = POST(
    "api/v1/event_schedule_private",
    "event_schedule_private"
  );

  const handleRemove = () => {
    let data = {
      id: dataRemove[0].id,
    };

    console.log("dataRemove", data);

    // if (!noBankDetails) {
    //   notification.open({
    //     type: "warning",
    //     message: "Warning",
    //     description: "Please Edit Your Bank Details on Profile Page",
    //     btn,
    //     key,
    //     // onClose: close,
    //   });
    //   return true;
    // }

    mustateDelete(data, {
      onSuccess: (res) => {
        notification.success({
          message: "Success",
          description: "Successfully deleted",
        });
        setIsModal(false);
      },
    });
  };

  const { mutate: mustateDelete, isLoading: isLoadingDelete } = DELETE(
    "api/v1/event_schedule_private",
    "event_schedule_private"
  );

  const [isModalAdd, setIsModalAdd] = useState(false);
  const handleDayClick = (val) => {
    console.log("onSelect", val);
    if (val.date <= moment()) {
      message.error("Warning date is on the past or today.");
    } else {
      handleGetDaySchedule(val.dateStr);

      formCreate.setFieldsValue({
        date: moment(val.dateStr),
        schedule: [
          {
            time_start: null,
            time_end: null,
          },
        ],
      });
      setIsModalAdd(true);
    }
  };

  const [dayScheduleList, setDayScheduleList] = useState([]);
  const handleGetDaySchedule = (date) => {
    let data = {
      date: date,
      user_id: userData.id,
    };
    mutateGetDaySchedule(data, {
      onSuccess: (res) => {
        if (res.success) {
          console.log("handleGetDaySchedule", res);
          let row = [];
          if (res.data && res.data.length > 0) {
            res.data.map((item, key) => {
              row.push({
                time_start: moment(date + " " + item.time_start),
                time_end: moment(date + " " + item.time_end),
              });
            });

            // formCreate.setFieldsValue({
            //   date: moment(date),
            //   schedule: row,
            // });
          }
          setDayScheduleList(row);
        }
      },
    });
  };

  const { mutate: mutateGetDaySchedule } = POST(
    "api/v1/get_data_schedule",
    "get_data_schedule"
  );

  const onFinishFormAdd = (values) => {
    let schedule = values.schedule;
    schedule.map((item, key) => {
      schedule[key]["a"] = item.time_start.format("HH:mm");
      schedule[key]["b"] = item.time_end.format("HH:mm");
    });

    let data = {
      date: moment(values.date).format("YYYY-MM-DD"),
      time: schedule,
    };

    console.log(data);

    mutateAddBulk(data, {
      onSuccess: (res) => {
        if (res.success) {
          notification.success({
            message: "Success",
            description: "Successfully Submitted",
          });
          // console.log(res);
          setIsModalAdd(false);
        }
      },
    });
  };

  const onFinishFormDuplicate = (values) => {
    let data = {
      date_from: values.date_from.format("YYYY-MM-DD"),
      date_to: values.date_to.format("YYYY-MM-DD"),
    };
    console.log(data);
    if (
      moment(values.date_from.format("YYYY-MM-DD"), "YYYY-MM-DD").isSameOrAfter(
        moment(values.date_to.format("YYYY-MM-DD"), "YYYY-MM-DD")
      )
    ) {
      notification.warning({
        message: "Warning",
        description: "Date is past or same date.",
      });
    } else {
      mutateDuplicate(data, {
        onSuccess: (res) => {
          if (res.success) {
            notification.success({
              message: res.message,
              description: res.description,
            });
            setIsModalDuplicate(false);
          } else {
            notification.warning({
              message: res.message,
              description: res.description,
            });
          }
        },
      });
    }
  };

  const { mutate: mutateDuplicate, isLoading: isLoadingDuplicate } = POST(
    "api/v1/duplicate_schedule",
    "event_schedule_private"
  );

  const { mutate: mutateAddBulk, isLoading: isLoadingAddBulk } = POST(
    "api/v1/bulk_insert",
    "event_schedule_private"
  );

  const [isModalDuplicate, setIsModalDuplicate] = useState(false);
  const [duplicateForm] = Form.useForm();

  const findSchedule = (data, start, end, index) => {
    let values = {
      date: data,
      time_start: start,
      time_end: end,
    };

    mutateSchedule(values, {
      onSuccess: (res) => {
        if (res.success) {
        } else {
          let row = formCreate.getFieldsValue();
          let item = [...row.schedule];
          item[index] = {
            ...item[index],
            time_start: null,
            time_end: null,
          };

          formCreate.setFieldsValue({
            schedule: item,
          });

          notification.warning({
            message: "Warning",
            description: "Time schedule is aleady been used!",
          });
        }
      },
    });
  };

  const { mutate: mutateSchedule, isLoading: isLoadingSchedule } = POST(
    "api/v1/find_schedule",
    "find_schedule"
  );

  const handleDateCheckStart = (e, index) => {
    let row = formCreate.getFieldsValue().schedule;
    let rowGet = formCreate.getFieldsValue().schedule;
    row = row.concat(dayScheduleList);
    let selected = row[index];
    console.log("handleGetDaySchedule", row);

    if (selected && selected.time_start) {
      console.log("handleDateCheckStart", row);
      row.map((item, key) => {
        if (item && item.time_start) {
          if (key !== index) {
            let time_start = item.time_start.format("HHmm");
            let time_start_selected = selected.time_start.format("HHmm");

            console.log(time_start, time_start_selected);

            if (time_start === time_start_selected) {
              let rowResult = [...rowGet];
              rowResult[index] = {
                ...rowResult[index],
                time_start: null,
                time_end: null,
              };

              formCreate.setFieldsValue({
                schedule: rowResult,
              });

              notification.warning({
                message: "Warning",
                description: "Start time is already used!",
              });
              return true;
            } else {
              let time_end = item.time_end.format("HHmm");
              if (
                Number(time_start) <= Number(time_start_selected) &&
                Number(time_end) >= Number(time_start_selected)
              ) {
                notification.warning({
                  message: "Warning",
                  description: "Invalid time schedule!",
                });
                let rowResult = [...rowGet];
                rowResult[index] = {
                  ...rowResult[index],
                  time_start: null,
                };
                formCreate.setFieldsValue({
                  schedule: rowResult,
                });
              }
            }
          }
        }
      });
    }
  };

  const handleDateCheckEnd = (e, index) => {
    let row = formCreate.getFieldValue().schedule;
    let rowGet = formCreate.getFieldsValue().schedule;
    row = row.concat(dayScheduleList);
    let selected = row[index];

    console.log("handleGetDaySchedule", row);

    let dataDate = formCreate.getFieldValue();
    let date = dataDate.date.format("YYYY-MM-DD");
    let time_start_data = row.schedule?.[index]["time_start"];
    let time_end_data = row.schedule?.[index]["time_end"];

    let arrMax = [];
    let arrMin = [];
    row.map((i, k) => {
      if (k !== index) {
        if (i && i.time_end && selected) {
          if (i.time_end) {
            arrMax.push(Number(i.time_end.format("HHmm")));
          }
          if (i.time_start) {
            arrMin.push(Number(i.time_start.format("HHmm")));
          }
        }
      }
    });
    let minData = arrMin.length > 0 ? Math.min.apply(null, arrMin) : 0;
    let maxData = arrMax.length > 0 ? Math.min.apply(null, arrMax) : 0;
    console.log("handleDateCheckEnd", minData, maxData);

    if (selected && selected.time_start && selected.time_end) {
      let time_start = selected.time_start;
      let time_end = e;

      if (time_start && time_end) {
        time_start = time_start.format("HHmm");
        time_end = time_end.format("HHmm");
        if (time_start >= time_end) {
          notification.warning({
            message: "Warning",
            description: "Time schedule is invalid!",
          });

          let rowResult = [...rowGet];
          rowResult[index] = {
            ...rowResult[index],
            time_end: null,
          };
          formCreate.setFieldsValue({
            schedule: rowResult,
          });
          return true;
        } else {
          row.map((item, key) => {
            if (item && item.time_start && item.time_end) {
              if (key !== index) {
                let time_end = item.time_end.format("HHmm");
                let time_end_selected = selected.time_end.format("HHmm");
                if (time_end === time_end_selected) {
                  let rowResult = [...rowGet];
                  rowResult[index] = {
                    ...rowResult[index],
                    time_end: null,
                  };
                  formCreate.setFieldsValue({
                    schedule: rowResult,
                  });
                  notification.warning({
                    message: "Warning",
                    description: "End time is already used!",
                  });
                  return true;
                } else {
                  if (item.time_start) {
                    let time_start_item = item.time_start.format("HHmm");
                    if (
                      Number(time_start_item) <= Number(time_end_selected) &&
                      Number(time_end) >= Number(time_end_selected)
                    ) {
                      notification.warning({
                        message: "Warning",
                        description: "Invalid time schedule! 2",
                      });

                      let rowResult = [...rowGet];
                      rowResult[index] = {
                        ...rowResult[index],
                        time_end: null,
                      };
                      formCreate.setFieldsValue({
                        schedule: rowResult,
                      });
                      return false;
                    } else if (
                      maxData &&
                      Number(time_end_selected) >= maxData &&
                      Number(time_start) <= minData
                    ) {
                      notification.warning({
                        message: "Warning",
                        description: "Invalid time schedule! 3",
                      });

                      let rowResult = [...rowGet];
                      rowResult[index] = {
                        ...rowResult[index],
                        time_end: null,
                      };
                      formCreate.setFieldsValue({
                        schedule: rowResult,
                      });
                      return true;
                    }
                    return true;
                  }
                }
              }
            }
          });
        }
      }
    }
  };

  return (
    <>
      <ComponentHeader
        title={permission}
        sub_title={subTitle}
        icon={faCalendarAlt}
      />

      <Card className="p-b-lg" key={"calendar"} id="PageEventPrivateCalendar">
        <Row gutter={12}>
          <Col xs={24} sm={24} md={24} lg={24}>
            <FullCalendar
              defaultView="dayGridMonth"
              headerToolbar={{
                left: "prev,next",
                center: "title",
                right: "dayGridMonth,timeGridWeek,timeGridDay",
              }}
              plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
              dateClick={handleDayClick}
              events={dataTable}
              eventClick={(e) => handleEventClick(e)}
              eventTimeFormat={{
                hour: "2-digit",
                minute: "2-digit",
                hour12: false,
              }}
            />
          </Col>
        </Row>
      </Card>

      <Modal
        title="PRIVATE SCHEDULE FORM"
        visible={isModal}
        onCancel={(e) => setIsModal(false)}
        okText="UPDATE"
        key={"keyt"}
        footer={[
          <Space key={"editrf"}>
            {dataRemove.length > 0 && (
              <Button
                className="btn-main-outline-9"
                // loading={isLoadingDuplicate}
                onClick={() => handleRemove()}
              >
                <FontAwesomeIcon icon={faTrashAlt} /> &nbsp; REMOVE
              </Button>
            )}
            {/* <Button
              htmlType="submit"
              className="btn-main-outline-5"
              // loading={isLoadingApprove}
              onClick={() => {
                form
                  .validateFields()
                  .then((values) => {
                    onFinishFormDuplicate(values);
                  })
                  .catch((info) => {
                    console.log("Validate Failed:", info);
                  });
              }}
            >
              <FontAwesomeIcon icon={faCopy} /> &nbsp; DUPLICATE
            </Button> */}
            <Button
              htmlType="submit"
              className="btn-main-outline-2"
              // loading={isLoadingApprove}
              onClick={() => {
                form
                  .validateFields()
                  .then((values) => {
                    onFinishForm(values);
                  })
                  .catch((info) => {
                    console.log("Validate Failed:", info);
                  });
              }}
            >
              <FontAwesomeIcon icon={faSave} /> &nbsp; SUBMIT
            </Button>
          </Space>,
        ]}
      >
        <Form form={form} name="edit-form" layout="vertical">
          <Row gutter={24}>
            <Col xs={24} sm={24} md={24}>
              <Form.Item name={"date"} rules={[validator.require]}>
                <FloatDatePicker
                  label="Date"
                  placeholder="Date"
                  format={"MM/DD/YYYY"}
                  required
                />
              </Form.Item>

              <Form.Item name={"time_start"} rules={[validator.require]}>
                <FloatTimePicker
                  label="Start Time"
                  placeholder="Start Time"
                  mode
                  required
                />
              </Form.Item>

              <Form.Item name={"time_end"} rules={[validator.require]}>
                <FloatTimePicker
                  label="End Time"
                  placeholder="End Time"
                  mode
                  onChange={(e) => {
                    let formData = form.getFieldsValue();
                    let time_start = formData.time_start;
                    let time_end = e;

                    if (time_start && time_end) {
                      time_start = formData.time_start.format("HHmm");
                      time_end = time_end.format("HHmm");
                      if (time_start >= time_end) {
                        notification.warning({
                          message: "Warning",
                          description: "Time schedule is invalid!",
                        });

                        form.setFieldsValue({
                          time_end: null,
                        });
                      } else {
                        // findSchedule(
                        //   date,
                        //   time_start.format("HH:mm"),
                        //   time_end.format("HH:mm"),
                        //   name
                        // );
                      }
                    }
                  }}
                  required
                />
              </Form.Item>

              <Form.Item name={"id"} style={{ display: "none" }}>
                <FloatInput label="id" placeholder="id" required />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>

      <Modal
        title="PRIVATE SCHEDULE FORM"
        visible={isModalAdd}
        onCancel={(e) => setIsModalAdd(false)}
        okText="UPDATE"
        key={"duplicate"}
        footer={[
          <Space key={"duplicateadd"}>
            <Button
              htmlType="submit"
              className="btn-main-outline-5"
              // loading={isLoadingApprove}
              // onClick={() => {
              //   form
              //     .validateFields()
              //     .then((values) => {
              //       onFinishFormDuplicate(values);
              //     })
              //     .catch((info) => {
              //       console.log("Validate Failed:", info);
              //     });
              // }}
              onClick={() => {
                setIsModalAdd(false);
                setIsModalDuplicate(true);

                let date = formCreate.getFieldValue().date;

                duplicateForm.setFieldsValue({
                  date_from: date,
                  date_to: undefined,
                });
              }}
            >
              <FontAwesomeIcon icon={faCopy} /> &nbsp; DUPLICATE
            </Button>

            <Button
              htmlType="submit"
              className="btn-main-outline-2"
              loading={isLoadingAddBulk}
              onClick={() => {
                formCreate
                  .validateFields()
                  .then((values) => {
                    onFinishFormAdd(values);
                  })
                  .catch((info) => {
                    console.log("Validate Failed:", info);
                  });
              }}
            >
              <FontAwesomeIcon icon={faSave} /> &nbsp; SUBMIT
            </Button>
          </Space>,
        ]}
      >
        <Form form={formCreate} name="edit-form" layout="vertical">
          <Row gutter={24}>
            <Col xs={24} sm={24} md={24}>
              <Form.Item name={"date"} rules={[validator.require]}>
                <FloatDatePicker
                  label="Date"
                  placeholder="Date"
                  format={"MM/DD/YYYY"}
                  required
                />
              </Form.Item>

              <Form.List name="schedule">
                {(fields, { add, remove }) => (
                  <>
                    {fields.map(({ key, name, ...restField }) => (
                      <div key={key}>
                        <Row gutter={24}>
                          <Col xs={24} sm={24} md={12} lg={12}>
                            <Form.Item
                              {...restField}
                              name={[name, "time_start"]}
                              rules={[validator.require]}
                            >
                              <FloatTimePicker
                                label="Start Time"
                                placeholder="Start Time"
                                mode
                                onChange={(e) => handleDateCheckStart(e, name)}
                                required
                              />
                            </Form.Item>
                          </Col>

                          <Col
                            xs={restField.fieldKey !== 0 ? 20 : 24}
                            sm={restField.fieldKey !== 0 ? 22 : 24}
                            md={restField.fieldKey !== 0 ? 10 : 12}
                            lg={restField.fieldKey !== 0 ? 10 : 12}
                          >
                            <Form.Item
                              {...restField}
                              name={[name, "time_end"]}
                              rules={[validator.require]}
                            >
                              <FloatTimePicker
                                label="End Time"
                                placeholder="End Time"
                                onChange={(e) => {
                                  handleDateCheckEnd(e, name);
                                }}
                                mode
                                required
                              />
                            </Form.Item>
                          </Col>

                          {restField.fieldKey !== 0 && (
                            <Col
                              xs={2}
                              sm={2}
                              md={2}
                              lg={2}
                              style={{ paddingTop: "10px" }}
                            >
                              <FontAwesomeIcon
                                onClick={(e) => remove(name)}
                                icon={faTrash}
                                className="color-9"
                              />
                            </Col>
                          )}
                        </Row>
                      </div>
                    ))}
                    <Row gutter={24}>
                      <Col xs={24} sm={24} md={6} lg={6}>
                        <Button
                          type="link"
                          className="btn-link-main-3 btn-p-0"
                          onClick={() => add()}
                          // icon={<PlusOutlined />}
                        >
                          + Add Time
                        </Button>
                      </Col>
                    </Row>
                  </>
                )}
              </Form.List>
            </Col>
          </Row>
        </Form>
      </Modal>

      <Modal
        title="DUPLICATE SCHEDULE FORM"
        visible={isModalDuplicate}
        onCancel={(e) => setIsModalDuplicate(false)}
        okText="UPDATE"
        key={"keyts"}
        footer={[
          <Space key={"add"}>
            <Button
              htmlType="submit"
              className="btn-main-outline-2"
              loading={isLoadingDuplicate}
              onClick={() => {
                duplicateForm
                  .validateFields()
                  .then((values) => {
                    onFinishFormDuplicate(values);
                  })
                  .catch((info) => {
                    console.log("Validate Failed:", info);
                  });
              }}
            >
              <FontAwesomeIcon icon={faSave} /> &nbsp; SUBMIT
            </Button>
          </Space>,
        ]}
      >
        <Form form={duplicateForm} name="edit-form" layout="vertical">
          <Row gutter={24}>
            <Col xs={24} sm={24} md={24}>
              <Form.Item name={"date_from"} rules={[validator.require]}>
                <FloatDatePicker
                  label="Date From"
                  placeholder="Date From"
                  format={"MM/DD/YYYY"}
                  disabled={true}
                  required
                />
              </Form.Item>
            </Col>

            <Col xs={24} sm={24} md={24}>
              <Form.Item name={"date_to"} rules={[validator.require]}>
                <FloatDatePicker
                  label="Date To"
                  placeholder="Date To"
                  format={"MM/DD/YYYY"}
                  required
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
}
