import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Layout,
  notification,
  Row,
  Collapse,
  Popconfirm,
} from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import FloatInput from "../../../../providers/FloatInput";
import FloatSelect from "../../../../providers/FloatSelect";
// import optionStateCodes from "../../../../providers/optionStateCodes";
import $ from "jquery";
import ComponentHeader from "../../Components/ComponentHeader";
import {
  faMapMarkerPlus,
  faTrashAlt,
} from "@fortawesome/pro-regular-svg-icons";
import { GET, POST } from "../../../../providers/useAxiosQuery";
import { useHistory } from "react-router-dom";

import optionCountryCodes from "../../../../providers/optionCountryCodes";
import optionStateCodesUnitedState from "../../../../providers/optionStateCodesUnitedState";
import optionStateCodesCanada from "../../../../providers/optionStateCodesCanada";
import optionOrganizationType from "../../../../providers/optionOrganizationType";
import FloatAutoCompleteOrg from "../../../../providers/FloatAutoCompleteOrg";

import companyInfo from "../../../../providers/companyInfo";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function PageOrganizationAddNew() {
  const { Panel } = Collapse;
  const [form] = Form.useForm();
  const history = useHistory();
  const userdata = companyInfo().userData;
  const state = optionStateCodesUnitedState();

  const [formSearch] = Form.useForm();
  const [searchOptionCountry, setSearchOptionCountry] = useState("");
  const [searchOption, setSearchOption] = useState("");

  const [country] = useState(optionCountryCodes);
  const stateUnitedStates = optionStateCodesUnitedState();
  const stateCanada = optionStateCodesCanada();

  const [stateUS, setStateUS] = useState(stateUnitedStates);
  const [stateCA, setStateCA] = useState(stateCanada);
  const [stateSearch, setStateSearch] = useState(stateUS);
  const [stateSearchLabel, setStateSearchLabel] = useState("State");
  const [defaultCountry, setDefaultCountry] = useState("United States");

  const onFinish = (values) => {
    console.log("onFinish", values);
    mudateAdd(values, {
      onSuccess: (res) => {
        if (res.success) {
          notification.success({
            message: "Success",
            description: "Successfully added/updated",
          });
          // history.push("/organization/view-edit");
          history.push("/organization/view-edit");
        }
      },
    });
  };

  const [orgState, setOrgState] = useState([
    {
      id: 1,
      stateLabel: "State",
      stateValue: stateUS,
      zipLabel: "Zip Code",
      zipPattern: /(^\d{5}$)|(^\d{5}[ -]\d{4}$)/,
      cityOpt: [],
    },
  ]);

  const { data: data_get_manager_state_area } = GET(
    "api/v1/get_manager_state_area2",
    "get_manager_state_area",
    (res) => {
      if (res.success) {
        console.log("get_manager_state_area", res);
      }
    }
  );

  const { mutate: mudateAdd, isLoadding: isLoaddingAdd } = POST(
    "api/v1/organization",
    "organization"
  );

  const stateChange = (val, opt) => {
    setSearchOption(val);
  };

  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (searchText || searchOption || searchOptionCountry) {
        formSearch.submit();
      } else {
        // form.setFieldsValue({
        //   organizations: [""],
        // });
      }
    }, 1500);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [searchText, searchOption, searchOptionCountry]);

  const removeItemOrg = (index) => {
    // let data = orgState.filter((item) => {
    //   if (item.id !== index + 1) {
    //     console.log(item);
    //     return item;
    //   }
    // });
    // setOrgState(data);

    orgState.splice(index, 1);
    setOrgState(orgState);
  };

  const [stateLabel1, setStateLabel1] = useState("State");
  const [optionState1, setOptionState1] = useState([]);

  const handleCountry1 = (val, opt, index) => {
    console.log(val);
    if (val) {
      if (userdata.role === "Admin") {
        if (val === "United States") {
          let row = [...orgState];
          row[index] = {
            ...row[index],
            stateLabel: "State",
            stateValue: stateUS,
            zipLabel: "Zip Code",
            zipPattern: /(^\d{5}$)|(^\d{5}[ -]\d{4}$)/,
            cityOpt: [],
          };
          setOrgState(row);
        } else if (val === "Canada") {
          let row = [...orgState];
          row[index] = {
            ...row[index],
            stateLabel: "County",
            zipLabel: "Postal Code",
            stateValue: stateCA,
            zipPattern: /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/,
            cityOpt: [],
          };
          setOrgState(row);
        }
      } else {
        if (val === "United States") {
          setStateLabel1("State");
          console.log("stateUS", stateUS);
          let option_states = [];
          data_get_manager_state_area.states.map((state, key) => {
            let find = stateUS.find((p) => p.value == state.value);
            if (find) {
              option_states.push({ ...state, label: find.label });
            }
          });
          console.log("option_states_1", option_states);
          setOptionState1(option_states);

          let row = [...orgState];
          row[index] = {
            ...row[index],
            stateLabel: "State",
            zipLabel: "Zip Code",
            zipPattern: /(^\d{5}$)|(^\d{5}[ -]\d{4}$)/,
            stateValue: option_states,
          };
          setOrgState(row);
        } else if (val === "Canada") {
          setStateLabel1("County");
          let option_states = [];
          data_get_manager_state_area.states.map((state, key) => {
            let find = stateCA.find((p) => p.value == state.value);
            if (find) {
              option_states.push({ ...state, label: find.label });
            }
          });
          setOptionState1(option_states);

          let row = [...orgState];
          row[index] = {
            ...row[index],
            stateLabel: "County",
            zipLabel: "Postal Code",
            stateValue: option_states,
            zipPattern: /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/,
          };
          setOrgState(row);
        }
      }
    } else {
      let row = [...orgState];
      row[index] = {
        ...row[index],
        stateLabel: "State",
        stateValue: stateUS,
        zipLabel: "Zip Code",
        zipPattern: /(^\d{5}$)|(^\d{5}[ -]\d{4}$)/,
        cityOpt: [],
      };
      setOrgState(row);
    }

    let data = form.getFieldsValue().organizations;

    let rows = [...data];
    rows[index] = {
      ...rows[index],
      state: undefined,
      city: undefined,
      zip: undefined,
    };

    form.setFieldsValue({
      organizations: rows,
    });
  };

  const handleCountry2 = (e, index) => {
    if (e === "United States") {
      setStateSearch(stateUS);
      setStateSearchLabel("State");
    } else if (e === "Canada") {
      setStateSearch(stateCA);
      setStateSearchLabel("County");
    } else {
    }
    formSearch.resetFields(["state"]);
  };

  const [stateLabelSearch, setStateLabelSearch] = useState("State");
  const [optionStateSearch, setOptionStateSearch] = useState([]);

  const handleCountrySearch = (val, opt, index) => {
    console.log(val);
    if (val) {
      if (userdata.role === "Admin") {
        if (val === "United States") {
          setStateLabelSearch("State");
          setOptionStateSearch(stateUS);
        } else if (val === "Canada") {
          setStateLabelSearch("County");
          setOptionStateSearch(stateCA);
        }
      } else {
        if (val === "United States") {
          let option_states = [];
          data_get_manager_state_area.states.map((state, key) => {
            let find = stateUS.find((p) => p.value == state.value);
            if (find) {
              option_states.push({ ...state, label: find.label });
            }
          });
          setStateLabelSearch("State");
          setOptionStateSearch(option_states);
        } else if (val === "Canada") {
          let option_states = [];
          data_get_manager_state_area.states.map((state, key) => {
            let find = stateCA.find((p) => p.value == state.value);
            if (find) {
              option_states.push({ ...state, label: find.label });
            }
          });
          setStateLabelSearch("County");
          setOptionStateSearch(option_states);
        }
      }
    }
    formSearch.resetFields(["state"]);
  };

  const [tableFilter, setTableFilter] = useState({
    search: "",
    state: "",
    country: "",
  });

  const [searchOrgResult, setSearchOrgResult] = useState([]);
  const onSearch = (values) => {
    mutateSearch(values, {
      onSuccess: (res) => {
        if (res.success) {
          console.log("res", res.data);
          let arr = [];
          res.data.map((item, key) => {
            arr.push({
              label: item.name,
              value: item.name,
              json: item,
            });
          });
          setSearchOrgResult(arr);
        }
      },
    });
  };

  const { refetch: refetchSearchData } = GET(
    `api/v1/check_organization_dashboard?${$.param(tableFilter)}`,
    "check_organization_dashboard",
    (res) => {
      if (res.success) {
        console.log("data", res);
        let arr = [];
        res.data.map((item) => {
          arr.push({
            label: item.name,
            value: item.name,
            json: item,
          });
        });
        setSearchOrgResult(arr);
      }
    }
  );

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      console.log("searchDataFormuseState", tableFilter);
      // refetchSearchData();
    }, 2000);
    return () => {
      clearTimeout(timeoutId);
    };
  }, [tableFilter]);

  const { mutate: mutateSearch } = POST(
    "api/v1/organization/search",
    "organization_search"
  );

  const handleOnchangeState = (val, opt, index) => {
    if (val) {
      let row = [...orgState];
      row[index] = {
        ...row[index],
        stateLabel: "State",
        zipLabel: "Zip Code",
        cityOpt: opt["data-json"],
      };
      setOrgState(row);
    } else {
      let row = [...orgState];
      row[index] = {
        ...row[index],
        stateLabel: "State",
        zipLabel: "Zip Code",
        cityOpt: [],
      };
      setOrgState(row);
    }

    let data = form.getFieldsValue().organizations;
    let rows = [...data];
    rows[index] = {
      ...rows[index],
      city: undefined,
    };

    form.setFieldsValue({
      organizations: rows,
    });
  };

  return (
    <>
      <ComponentHeader
        title="Organization"
        sub_title="ADD NEW"
        icon={faMapMarkerPlus}
      />

      <Card className="p-b-lg card-min-height" id="PageOrganizationAddNew">
        <Row gutter={12}>
          <Col xs={24} sm={24} md={16} className="ant-card-form-list">
            <Form
              layout="vertical"
              onFinish={onFinish}
              autoComplete="off"
              initialValues={{ organizations: [""] }}
              form={form}
            >
              <Form.List name="organizations">
                {(fields, { add, remove }) => (
                  <>
                    {fields.map(({ key, name, ...restField }, index) => (
                      <div key={index}>
                        <Collapse
                          className="main-1-collapse"
                          expandIcon={({ isActive }) =>
                            isActive ? (
                              <span
                                className="ant-menu-submenu-arrow"
                                style={{
                                  color: "#FFF",
                                  transform: "rotate(270deg)",
                                }}
                              ></span>
                            ) : (
                              <span
                                className="ant-menu-submenu-arrow"
                                style={{
                                  color: "#FFF",
                                  transform: "rotate(90deg)",
                                }}
                              ></span>
                            )
                          }
                          defaultActiveKey={["1"]}
                          expandIconPosition="start"
                        >
                          <Panel
                            header="ADD NEW ORGANIZATION"
                            key="1"
                            className="accordion bg-darkgray-form"
                            extra={
                              restField.fieldKey !== 0 ? (
                                // <MinusCircleOutlined
                                //   className="dynamic-delete-button"
                                //   onClick={() => {
                                //     removeItemOrg(name);
                                //     remove(name);
                                //   }}
                                // />\
                                <Popconfirm
                                  title="Are you sure to delete this organization?"
                                  onConfirm={() => {
                                    removeItemOrg(name);
                                    remove(name);
                                  }}
                                  okText="Confirm"
                                  cancelText="Cancel"
                                >
                                  {/* <DeleteOutlined className="dynamic-delete-button" /> */}
                                  <FontAwesomeIcon icon={faTrashAlt} />
                                </Popconfirm>
                              ) : null
                            }
                          >
                            <Row gutter={24}>
                              <Col xs={24} sm={24} md={8}>
                                <Form.Item
                                  {...restField}
                                  name={[name, "country"]}
                                  className="form-select-error"
                                  rules={[
                                    {
                                      required: true,
                                      message: "This field is required.",
                                    },
                                  ]}
                                >
                                  <FloatSelect
                                    label="Country"
                                    placeholder="Country"
                                    options={
                                      userdata.role === "Admin"
                                        ? country
                                        : data_get_manager_state_area
                                        ? data_get_manager_state_area.countries
                                        : []
                                    }
                                    onChange={(val, opt) => {
                                      handleCountry1(val, opt, index);
                                    }}
                                    allowClear={true}
                                    required
                                    // disabled={userdata.role === "Manager" ? true : false}
                                  />
                                </Form.Item>
                              </Col>

                              <Col xs={24} sm={24} md={8}>
                                <Form.Item
                                  {...restField}
                                  name={[name, "organization_type"]}
                                  className="form-select-error"
                                  rules={[
                                    {
                                      required: true,
                                      message: "This field is required.",
                                    },
                                  ]}
                                >
                                  <FloatSelect
                                    label="Type"
                                    placeholder="Type"
                                    options={optionOrganizationType}
                                    onChange={(e) => {
                                      // console.log(e);
                                      console.log(index);
                                    }}
                                    allowClear={true}
                                    required
                                  />
                                </Form.Item>
                              </Col>

                              <Col xs={24} sm={24} md={8}>
                                <Form.Item
                                  {...restField}
                                  name={[name, "state"]}
                                  className="form-select-error"
                                  rules={[
                                    {
                                      required: true,
                                      message: "This field is required.",
                                    },
                                  ]}
                                >
                                  <FloatSelect
                                    label={orgState[index].stateLabel}
                                    placeholder={orgState[index].stateLabel}
                                    options={
                                      orgState[index].stateValue
                                      // userdata.role === "Admin"
                                      //   ? orgState[index].stateValue
                                      //   : optionState1
                                    }
                                    onChange={(val, opt) =>
                                      handleOnchangeState(val, opt, index)
                                    }
                                    allowClear={true}
                                    required
                                  />
                                </Form.Item>
                              </Col>
                            </Row>

                            <Row gutter={24}>
                              <Col xs={24} sm={24} md={24}>
                                <Form.Item
                                  {...restField}
                                  name={[name, "name"]}
                                  rules={[
                                    {
                                      required: true,
                                      message: "This field is required.",
                                    },
                                  ]}
                                >
                                  <FloatInput
                                    label="Organization Name"
                                    placeholder="Organization Name"
                                    required
                                  />
                                </Form.Item>
                              </Col>
                            </Row>

                            <Row gutter={24}>
                              <Col xs={24} sm={24} md={24}>
                                <Form.Item
                                  {...restField}
                                  name={[name, "address1"]}
                                  rules={[
                                    {
                                      required: true,
                                      message: "This field is required.",
                                    },
                                  ]}
                                >
                                  <FloatInput
                                    label="Address 1"
                                    placeholder="Address 1"
                                    required
                                  />
                                </Form.Item>
                              </Col>

                              <Col xs={24} sm={24} md={24}>
                                <Form.Item
                                  {...restField}
                                  name={[name, "address2"]}
                                >
                                  <FloatInput
                                    label="Address 2"
                                    placeholder="Address 2"
                                  />
                                </Form.Item>
                              </Col>
                            </Row>

                            <Row gutter={24}>
                              <Col xs={24} sm={24} md={8}>
                                <Form.Item
                                  {...restField}
                                  name={[name, "city"]}
                                  className="form-select-error"
                                  rules={[
                                    {
                                      required: true,
                                      message: "This field is required.",
                                    },
                                  ]}
                                >
                                  <FloatSelect
                                    label="City"
                                    placeholder="City"
                                    options={orgState[index].cityOpt}
                                    allowClear={true}
                                    required
                                    // options={[]}
                                  />
                                </Form.Item>
                              </Col>

                              <Col xs={24} sm={24} md={8}>
                                <Form.Item
                                  {...restField}
                                  name={[name, "zip"]}
                                  rules={[
                                    {
                                      required: true,
                                      message: "This field is required.",
                                    },
                                    {
                                      pattern: orgState[index].zipPattern,
                                      message:
                                        "Invalid " + orgState[index].zipLabel,
                                    },
                                  ]}
                                >
                                  <FloatInput
                                    label={orgState[index].zipLabel}
                                    placeholder={orgState[index].zipLabel}
                                    required
                                  />
                                </Form.Item>
                              </Col>
                            </Row>

                            <Form.Item
                              {...restField}
                              name={[name, "id"]}
                              style={{ position: "absolute" }}
                            >
                              <Input style={{ display: "none" }} />
                            </Form.Item>
                          </Panel>
                        </Collapse>
                        <br />
                      </div>
                    ))}
                    <Form.Item>
                      <Button
                        className="btn-link-main-3 btn-p-0"
                        type="link"
                        icon={<PlusOutlined />}
                        onClick={() => {
                          setOrgState([
                            ...orgState,
                            {
                              id: orgState.length + 1,
                              stateLabel:
                                defaultCountry === "United States"
                                  ? "State"
                                  : "County",
                              stateValue:
                                defaultCountry === "United States"
                                  ? stateUS
                                  : stateCA,
                              zipLabel:
                                defaultCountry === "United States"
                                  ? "Zip Code"
                                  : "Postal Code",
                              zipPattern:
                                defaultCountry === "United States"
                                  ? /(^\d{5}$)|(^\d{5}[ -]\d{4}$)/
                                  : /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/,
                              cityOpt: [],
                            },
                          ]);

                          // form.setFieldsValue({
                          //   organizations: tempOrganization,
                          // });
                          add();

                          let tempOrganization =
                            form.getFieldsValue().organizations;
                          let formCount = tempOrganization.length - 1;
                          tempOrganization[formCount] = {
                            ...tempOrganization[formCount],
                            // country: defaultCountry,
                          };

                          // console.log(formCount);
                          // console.log(tempOrganization);
                        }}
                      >
                        Add Another Organization
                      </Button>
                    </Form.Item>
                  </>
                )}
              </Form.List>
            </Form>
          </Col>

          <Col xs={24} sm={24} md={8}>
            <Collapse
              className="main-1-collapse"
              expandIcon={({ isActive }) =>
                isActive ? (
                  <span
                    className="ant-menu-submenu-arrow"
                    style={{
                      color: "#FFF",
                      transform: "rotate(270deg)",
                    }}
                  ></span>
                ) : (
                  <span
                    className="ant-menu-submenu-arrow"
                    style={{
                      color: "#FFF",
                      transform: "rotate(90deg)",
                    }}
                  ></span>
                )
              }
              defaultActiveKey={["1"]}
              expandIconPosition="start"
            >
              <Panel
                header="ORGANIZATION SEARCH"
                key="1"
                className="accordion bg-darkgray-form"
              >
                <Form
                  layout="vertical"
                  onFinish={onSearch}
                  autoComplete="off"
                  initialValues={{ organizations: [""] }}
                  form={formSearch}
                >
                  <Row gutter={12}>
                    <Col span={24}>
                      <Form.Item
                        name="country"
                        className="m-b-md form-select-error"
                      >
                        <FloatSelect
                          label="Country"
                          placeholder="Country"
                          options={
                            userdata.role === "Admin"
                              ? country
                              : data_get_manager_state_area
                              ? data_get_manager_state_area.countries
                              : []
                          }
                          onChange={
                            handleCountrySearch
                            // userdata.role === "Admin"
                            //   ? handleCountry2
                            //   : handleCountry1
                          }
                        />
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item
                        name="state"
                        className="m-b-md form-select-error"
                      >
                        <FloatSelect
                          label={stateLabelSearch}
                          placeholder={stateLabelSearch}
                          options={optionStateSearch}
                          onChange={stateChange}
                        />
                      </Form.Item>
                    </Col>

                    <Col span={24}>
                      <Form.Item name="search" className="m-b-sm">
                        <FloatAutoCompleteOrg
                          label="Search"
                          placeholder="Search"
                          options={searchOrgResult}
                          // onChange={setSearchText}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </Panel>
            </Collapse>
            <br></br>
          </Col>
        </Row>

        <Button
          type="primary"
          htmlType="submit"
          loading={isLoaddingAdd}
          className="btn-main-outline-2"
          size="large"
          onClick={(e) => form.submit()}
        >
          SUBMIT
        </Button>
      </Card>
    </>
  );
}
