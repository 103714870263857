import React, { useEffect, useState } from "react";

import {
  Layout,
  Card,
  Form,
  Input,
  Button,
  Row,
  Col,
  Image,
  Divider,
  Collapse,
  Select,
  notification,
  Checkbox,
  Alert,
  Modal,
  Space,
  Switch,
} from "antd";
import {
  FormOutlined,
  PlusOutlined,
  MinusOutlined,
  EyeOutlined,
} from "@ant-design/icons";
import ComponentHeader from "../../Components/ComponentHeader";
import { faTicketAlt } from "@fortawesome/pro-solid-svg-icons";
import moment from "moment";
import { useHistory } from "react-router-dom";

import FloatInput from "../../../../providers/FloatInput";
import FloatSelect from "../../../../providers/FloatSelect";
import FloatInputPasswordStrength from "../../../../providers/FloatInputPasswordStrength";

import states from "states-us";

import { GET, POST } from "../../../../providers/useAxiosQuery";
import { faEye, faCog } from "@fortawesome/pro-regular-svg-icons";
import companyInfo from "../../../../providers/companyInfo";
export default function Maintenance({ showModalNew, permission }) {
  const encryptor = companyInfo().encryptor;
  const { Panel } = Collapse;
  const [form] = Form.useForm();
  const sub_title = "User";
  const [dataUsers, setDataUsers] = useState([]);
  const [change, setChange] = useState(false);
  const [data, setData] = useState({
    system_maintenance: 0,
  });

  GET("api/v1/maintenance", "maintenance", (res) => {
    if (res.success) {
      console.log("config", res);
      setData({
        system_maintenance: res.data.system_maintenance,
      });

      form.setFieldsValue({
        system_maintenance: res.data.system_maintenance,
      });
    }
  });

  const { mutate: mutateConfig, isLoading: isLoadingConfig } = POST(
    "api/v1/maintenance",
    "maintenance"
  );

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (change) {
        console.log("data", data);
        mutateConfig(data, {
          onSuccess: (res) => {
            if (res.success) {
              notification.success({
                message: "Success",
                description: "Successfully updated.",
              });
              setChange(false);
            }
          },
        });
      }
    }, 3000);
    return () => {
      clearTimeout(timeoutId);
    };
  }, [change]);
  return (
    <Layout
      className="site-layout-background"
      style={{
        padding: "0px 0px 20px 0px",
        background: "#fff",
      }}
      id="ViewAs"
    >
      <ComponentHeader
        title="Configuration"
        sub_title="View Maintenance"
        icon={faCog}
      />
      {/* <ComponentFaqs
      linkVid={"https://player.vimeo.com/video/644213846?autoplay=0&muted=1"}
    /> */}
      <br></br>
      <Layout.Content style={{ padding: "30px", paddingTop: "0px" }}>
        <Row gutter={24}>
          <Col xs={24} sm={24} md={16} lg={16} xl={16} xxl={16}>
            <Collapse
              className="ant-collapse-primary"
              expandIcon={({ isActive }) =>
                isActive ? (
                  <span
                    className="ant-menu-submenu-arrow"
                    style={{ color: "#FFF", transform: "rotate(270deg)" }}
                  ></span>
                ) : (
                  <span
                    className="ant-menu-submenu-arrow"
                    style={{ color: "#FFF", transform: "rotate(90deg)" }}
                  ></span>
                )
              }
              expandIconPosition="right"
              defaultActiveKey={["1", "2", "3"]}
            >
              <Panel
                header="Website Maintenance"
                key="1"
                className="accordion bg-darkgray-form"
              >
                <Switch
                  loading={isLoadingConfig ? true : false}
                  checked={data.system_maintenance === 1 ? true : false}
                  onChange={(e) => {
                    setData({ ...data, system_maintenance: e ? 1 : 0 });
                    setChange(true);
                  }}
                />
              </Panel>
            </Collapse>
          </Col>
        </Row>
      </Layout.Content>
    </Layout>
  );
}
