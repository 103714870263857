import { Checkbox, Collapse, Form, notification, Typography } from "antd";
import { useEffect } from "react";
import { POST } from "../../../../../providers/useAxiosQuery";

export default function FormPdfProfessional(props) {
  const { assessment_id, dataSource } = props;

  const [form] = Form.useForm();
  const initialValues = {
    p3_cmj_rsi_no: true,
    p3_es_height: true,
    p3_sj_height: true,
    p3_sprint_time_tem_meter: true,
    p3_isometric_pull: true,
    p3_age_avg_cmj_rsi_no_sum: true,
    p3_age_avg_es_height_sum: true,
    p3_age_avg_sj_height_sum: true,
    p3_age_avg_sprint_time_tem_meter_sum: true,
    p3_age_avg_isometric_pull: true,
    p3_average_1: true,
    p3_average_2: true,
    p3_average_3: true,
    p3_average_4: true,
    p3_average_5: true,
    p5_cmj_rsi_no: true,
    p5_es_height: true,
    p5_ground_contact_time: true,
    p5_rank: true,
    p7_cmj_jump_height: true,
    p7_avg_cmj_jump_height: true,
    p7_es_peak_power: true,
    p7_cmj_relative_avg_power: true,
    p7_rank: true,
    p9_sj_height: true,
    p9_age_avg_sj_height_sum: true,
    p9_sj_peak_power: true,
    p9_relative_sj_peak_power: true,
    p9_rank: true,
    p10_your_eur_score: true,
    p12_sprint_time_tem_meter: true,
    p12_age_avg_sprint_time_tem_meter_sum: true,
    p12_sprint_time_four_meter: true,
    p12_age_avg_sprint_time_four_meter_sum: true,
    p12_rank: true,
    p14_weight: true,
    p14_ms_peak_force: true,
    p14_ms_peak_force_lbs: true,
    p14_age_avg_isometric_pull_n: true,
    p14_age_avg_isometric_pull: true,
    p14_relative_stregth_pf_bw: true,
    p14_relative_stregth_pf_bw_avg_per: true,
    p_14_rank: true,
    p15_ms_peak_force_lbs: true,
    p15_weight: true,
    p15_relative_stregth_pf_bw_lbs: true,
    p15_ms_rfd_lbs: true,
    p15_RFD_percent_of_peak_force_lbs: true,
    p16_ms_peak_force: true,
    p16_es_peak_propulsive_force: true,
    p16_dsi_score: true,
    p17_cmj_rsi_no: true,
    p17_dsi_score: true,
    p17_eur: true,
    p17_ms_peak_force: true,
    p17_age_avg_cmj_rsi_no_sum: true,
    p17_dsi_score_avg_tot: true,
    p17_avg_eccentric_utilization_ratio: true,
    p17_avg_relative_strength: true,
    p17_average_1: true,
    p17_average_4: true,
  };

  const {
    mutate: mutatePdfDisplayData,
    // isLoading: isLoadingUpdateAthleteUser,
  } = POST("api/v1/athlete_pdf_display_data", "athlete_pdf_display_data_elite");

  const handleForm = (values) => {
    console.log("handleForm", values);
    let newValues = { ...values, p14_weight: 1 };

    let data = [];

    Object.keys(newValues).map(function (key) {
      if (newValues[key] !== undefined) {
        if (newValues[key]) {
          let objKeyVal = {};
          objKeyVal[key] = "1";
          data.push(objKeyVal);
        } else {
          let objKeyVal = {};
          objKeyVal[key] = "0";
          data.push(objKeyVal);
        }
      } else {
        let objKeyVal = {};
        objKeyVal[key] = "1";
        data.push(objKeyVal);
      }

      return "";
    });

    console.log("data", data);

    mutatePdfDisplayData(
      { assessment_id, data },
      {
        onSuccess: (res) => {
          if (res.success) {
            notification.success({
              message: res.message,
              description: res.description,
            });
          } else {
            notification.error({
              message: "Error",
              description: "Data not updated!",
            });
          }
        },
        onError: (err) => {
          notification.error({
            message: "Athlete PDF Display Data",
            description: err,
          });
        },
      }
    );
  };

  const handleChangeCheckbox = () => {
    form.submit();
  };

  useEffect(() => {
    // console.log("dataSource", dataSource);

    if (dataSource) {
      dataSource.map((item) => {
        form.setFieldsValue({ [item.name]: item.status ? true : false });
        // console.log(item.name, item.status ? true : false);
        return "";
      });
    }

    return () => {};
  }, [form, dataSource]);

  return (
    <Form form={form} onFinish={handleForm} initialValues={initialValues}>
      <Collapse
        className="main-1-collapse border-none"
        expandIcon={({ isActive }) =>
          isActive ? (
            <span
              className="ant-menu-submenu-arrow"
              style={{ color: "#FFF", transform: "rotate(270deg)" }}
            ></span>
          ) : (
            <span
              className="ant-menu-submenu-arrow"
              style={{ color: "#FFF", transform: "rotate(90deg)" }}
            ></span>
          )
        }
        defaultActiveKey={["1"]}
        expandIconPosition="start"
      >
        <Collapse.Panel
          header="PAGE 3"
          key="1"
          className="accordion bg-darkgray-form m-b-md border"
        >
          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th>BFS TESTING: PERFORMANCE TESTS</th>
                  <th>REBOUND JUMP (RSI)</th>
                  <th>COUNTERMOVEMENT JUMP (IN)</th>
                  <th>SQUAT JUMP (IN)</th>
                  <th>10YD SPRINT (SECS)</th>
                  <th>ISOMETRIC MID-THIGH PULL (LBS)</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th>YOU</th>
                  <td>
                    <Form.Item valuePropName="checked" name="p3_cmj_rsi_no">
                      <Checkbox onChange={handleChangeCheckbox} />
                    </Form.Item>
                  </td>
                  <td>
                    <Form.Item valuePropName="checked" name="p3_es_height">
                      <Checkbox onChange={handleChangeCheckbox} />
                    </Form.Item>
                  </td>
                  <td>
                    <Form.Item valuePropName="checked" name="p3_sj_height">
                      <Checkbox onChange={handleChangeCheckbox} />
                    </Form.Item>
                  </td>
                  <td>
                    <Form.Item
                      valuePropName="checked"
                      name="p3_sprint_time_tem_meter"
                    >
                      <Checkbox onChange={handleChangeCheckbox} />
                    </Form.Item>
                  </td>
                  <td>
                    <Form.Item valuePropName="checked" name="p3_isometric_pull">
                      <Checkbox onChange={handleChangeCheckbox} />
                    </Form.Item>
                  </td>
                </tr>
                <tr>
                  <th>Performers Avg (10 yrs old)</th>
                  <td>
                    <Form.Item
                      valuePropName="checked"
                      name="p3_age_avg_cmj_rsi_no_sum"
                    >
                      <Checkbox onChange={handleChangeCheckbox} />
                    </Form.Item>
                  </td>
                  <td>
                    <Form.Item
                      valuePropName="checked"
                      name="p3_age_avg_es_height_sum"
                    >
                      <Checkbox onChange={handleChangeCheckbox} />
                    </Form.Item>
                  </td>
                  <td>
                    <Form.Item
                      valuePropName="checked"
                      name="p3_age_avg_sj_height_sum"
                    >
                      <Checkbox onChange={handleChangeCheckbox} />
                    </Form.Item>
                  </td>
                  <td>
                    <Form.Item
                      valuePropName="checked"
                      name="p3_age_avg_sprint_time_tem_meter_sum"
                    >
                      <Checkbox onChange={handleChangeCheckbox} />
                    </Form.Item>
                  </td>
                  <td>
                    <Form.Item
                      valuePropName="checked"
                      name="p3_age_avg_isometric_pull"
                    >
                      <Checkbox onChange={handleChangeCheckbox} />
                    </Form.Item>
                  </td>
                </tr>
                <tr>
                  <th>Your Benchmark</th>
                  <td>
                    <Form.Item valuePropName="checked" name="p3_average_1">
                      <Checkbox onChange={handleChangeCheckbox} />
                    </Form.Item>
                  </td>
                  <td>
                    <Form.Item valuePropName="checked" name="p3_average_2">
                      <Checkbox onChange={handleChangeCheckbox} />
                    </Form.Item>
                  </td>
                  <td>
                    <Form.Item valuePropName="checked" name="p3_average_3">
                      <Checkbox onChange={handleChangeCheckbox} />
                    </Form.Item>
                  </td>
                  <td>
                    <Form.Item valuePropName="checked" name="p3_average_4">
                      <Checkbox onChange={handleChangeCheckbox} />
                    </Form.Item>
                  </td>
                  <td>
                    <Form.Item valuePropName="checked" name="p3_average_5">
                      <Checkbox onChange={handleChangeCheckbox} />
                    </Form.Item>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </Collapse.Panel>
      </Collapse>

      <Collapse
        className="main-1-collapse border-none"
        expandIcon={({ isActive }) =>
          isActive ? (
            <span
              className="ant-menu-submenu-arrow"
              style={{ color: "#FFF", transform: "rotate(270deg)" }}
            ></span>
          ) : (
            <span
              className="ant-menu-submenu-arrow"
              style={{ color: "#FFF", transform: "rotate(90deg)" }}
            ></span>
          )
        }
        defaultActiveKey={["1"]}
        expandIconPosition="start"
      >
        <Collapse.Panel
          header="PAGE 5"
          key="1"
          className="accordion bg-darkgray-form m-b-md border"
        >
          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th colSpan={3}>Results: Rebound Jump Test</th>
                </tr>
                <tr>
                  <th>Your RSI Score </th>
                  <th>Jump Height (in)</th>
                  <th>Ground Contact Time (ms)</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <Form.Item valuePropName="checked" name="p5_cmj_rsi_no">
                      <Checkbox onChange={handleChangeCheckbox} />
                    </Form.Item>
                  </td>
                  <td>
                    <Form.Item valuePropName="checked" name="p5_es_height">
                      <Checkbox onChange={handleChangeCheckbox} />
                    </Form.Item>
                  </td>
                  <td>
                    <Form.Item
                      valuePropName="checked"
                      name="p5_ground_contact_time"
                    >
                      <Checkbox onChange={handleChangeCheckbox} />
                    </Form.Item>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <br />

          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th>Your Rank </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <Form.Item valuePropName="checked" name="p5_rank">
                      <Checkbox onChange={handleChangeCheckbox} />
                    </Form.Item>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </Collapse.Panel>
      </Collapse>

      <Collapse
        className="main-1-collapse border-none"
        expandIcon={({ isActive }) =>
          isActive ? (
            <span
              className="ant-menu-submenu-arrow"
              style={{ color: "#FFF", transform: "rotate(270deg)" }}
            ></span>
          ) : (
            <span
              className="ant-menu-submenu-arrow"
              style={{ color: "#FFF", transform: "rotate(90deg)" }}
            ></span>
          )
        }
        defaultActiveKey={["1"]}
        expandIconPosition="start"
      >
        <Collapse.Panel
          header="PAGE 7"
          key="1"
          className="accordion bg-darkgray-form m-b-md border"
        >
          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th colSpan={3}>Results: Counter-Movement Jump Test</th>
                </tr>
                <tr>
                  <th>Measurement </th>
                  <th>You</th>
                  <th>Average Performers</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>CMJ Height (in)</td>
                  <td>
                    <Form.Item
                      valuePropName="checked"
                      name="p7_cmj_jump_height"
                    >
                      <Checkbox onChange={handleChangeCheckbox} />
                    </Form.Item>
                  </td>
                  <td>
                    <Form.Item
                      valuePropName="checked"
                      name="p7_avg_cmj_jump_height"
                    >
                      <Checkbox onChange={handleChangeCheckbox} />
                    </Form.Item>
                  </td>
                </tr>
                <tr>
                  <td>Relative Avg Power (W/kg)</td>
                  <td>
                    <Form.Item valuePropName="checked" name="p7_es_peak_power">
                      <Checkbox onChange={handleChangeCheckbox} />
                    </Form.Item>
                  </td>
                  <td>
                    <Form.Item
                      valuePropName="checked"
                      name="p7_cmj_relative_avg_power"
                    >
                      <Checkbox onChange={handleChangeCheckbox} />
                    </Form.Item>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <br />

          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th>Your Rank </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <Form.Item valuePropName="checked" name="p7_rank">
                      <Checkbox onChange={handleChangeCheckbox} />
                    </Form.Item>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </Collapse.Panel>
      </Collapse>

      <Collapse
        className="main-1-collapse border-none"
        expandIcon={({ isActive }) =>
          isActive ? (
            <span
              className="ant-menu-submenu-arrow"
              style={{ color: "#FFF", transform: "rotate(270deg)" }}
            ></span>
          ) : (
            <span
              className="ant-menu-submenu-arrow"
              style={{ color: "#FFF", transform: "rotate(90deg)" }}
            ></span>
          )
        }
        defaultActiveKey={["1"]}
        expandIconPosition="start"
      >
        <Collapse.Panel
          header="PAGE 9"
          key="1"
          className="accordion bg-darkgray-form m-b-md border"
        >
          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th colSpan={3}>Results: Squat Jump Test</th>
                </tr>
                <tr>
                  <th>Measurement </th>
                  <th>You</th>
                  <th>Average Performers</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Jump Height (in)</td>
                  <td>
                    <Form.Item valuePropName="checked" name="p9_sj_height">
                      <Checkbox onChange={handleChangeCheckbox} />
                    </Form.Item>
                  </td>
                  <td>
                    <Form.Item
                      valuePropName="checked"
                      name="p9_age_avg_sj_height_sum"
                    >
                      <Checkbox onChange={handleChangeCheckbox} />
                    </Form.Item>
                  </td>
                </tr>
                <tr>
                  <td>Relative Peak Power (W/kg)</td>
                  <td>
                    <Form.Item valuePropName="checked" name="p9_sj_peak_power">
                      <Checkbox onChange={handleChangeCheckbox} />
                    </Form.Item>
                  </td>
                  <td>
                    <Form.Item
                      valuePropName="checked"
                      name="p9_relative_sj_peak_power"
                    >
                      <Checkbox onChange={handleChangeCheckbox} />
                    </Form.Item>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <br />
          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th>Your Rank </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <Form.Item valuePropName="checked" name="p9_rank">
                      <Checkbox onChange={handleChangeCheckbox} />
                    </Form.Item>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </Collapse.Panel>
      </Collapse>

      <Collapse
        className="main-1-collapse border-none"
        expandIcon={({ isActive }) =>
          isActive ? (
            <span
              className="ant-menu-submenu-arrow"
              style={{ color: "#FFF", transform: "rotate(270deg)" }}
            ></span>
          ) : (
            <span
              className="ant-menu-submenu-arrow"
              style={{ color: "#FFF", transform: "rotate(90deg)" }}
            ></span>
          )
        }
        defaultActiveKey={["1"]}
        expandIconPosition="start"
      >
        <Collapse.Panel
          header="PAGE 10"
          key="1"
          className="accordion bg-darkgray-form m-b-md border"
        >
          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th>EUR Score </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <Form.Item
                      valuePropName="checked"
                      name="p10_your_eur_score"
                    >
                      <Checkbox onChange={handleChangeCheckbox} />
                    </Form.Item>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </Collapse.Panel>
      </Collapse>

      <Collapse
        className="main-1-collapse border-none"
        expandIcon={({ isActive }) =>
          isActive ? (
            <span
              className="ant-menu-submenu-arrow"
              style={{ color: "#FFF", transform: "rotate(270deg)" }}
            ></span>
          ) : (
            <span
              className="ant-menu-submenu-arrow"
              style={{ color: "#FFF", transform: "rotate(90deg)" }}
            ></span>
          )
        }
        defaultActiveKey={["1"]}
        expandIconPosition="start"
      >
        <Collapse.Panel
          header="PAGE 12"
          key="1"
          className="accordion bg-darkgray-form m-b-md border"
        >
          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th colSpan={4}>Results: 10YD/40YD sprint Test</th>
                </tr>
                <tr>
                  <th>Measurement </th>
                  <th>You</th>
                  <th>Average Performers</th>
                  <th>Training Recommendation</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>10YD Sprint (sec)</td>
                  <td>
                    <Form.Item
                      valuePropName="checked"
                      name="p12_sprint_time_tem_meter"
                    >
                      <Checkbox onChange={handleChangeCheckbox} />
                    </Form.Item>
                  </td>
                  <td>
                    <Form.Item
                      valuePropName="checked"
                      name="p12_age_avg_sprint_time_tem_meter_sum"
                    >
                      <Checkbox onChange={handleChangeCheckbox} />
                    </Form.Item>
                  </td>
                  <td>
                    Maximal strength, Horizontal force development and ankle
                    stiffness.
                  </td>
                </tr>
                <tr>
                  <td>40YD Sprint (sec)</td>
                  <td>
                    <Form.Item
                      valuePropName="checked"
                      name="p12_sprint_time_four_meter"
                    >
                      <Checkbox onChange={handleChangeCheckbox} />
                    </Form.Item>
                  </td>
                  <td>
                    <Form.Item
                      valuePropName="checked"
                      name="p12_age_avg_sprint_time_four_meter_sum"
                    >
                      <Checkbox onChange={handleChangeCheckbox} />
                    </Form.Item>
                  </td>
                  <td>
                    Comprehensive training including maximal strength, ballistic
                    strength and plyometrics
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <br />

          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th>Your Rank</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <Form.Item valuePropName="checked" name="p12_rank">
                      <Checkbox onChange={handleChangeCheckbox} />
                    </Form.Item>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </Collapse.Panel>
      </Collapse>

      <Collapse
        className="main-1-collapse border-none"
        expandIcon={({ isActive }) =>
          isActive ? (
            <span
              className="ant-menu-submenu-arrow"
              style={{ color: "#FFF", transform: "rotate(270deg)" }}
            ></span>
          ) : (
            <span
              className="ant-menu-submenu-arrow"
              style={{ color: "#FFF", transform: "rotate(90deg)" }}
            ></span>
          )
        }
        defaultActiveKey={["1"]}
        expandIconPosition="start"
      >
        <Collapse.Panel
          header="PAGE 14"
          key="1"
          className="accordion bg-darkgray-form m-b-md border"
        >
          <Typography.Title level={4} className="text-center">
            Results: Isometric Mid-thigh Pull Test (IMTP)
          </Typography.Title>
          {/* <table className="table m-t-md">
						<thead>
							<tr>
								<th>Your Weight</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>
									<Form.Item valuePropName="checked" name="p14_weight">
										<Checkbox onChange={handleChangeCheckbox} />
									</Form.Item>
								</td>
							</tr>
						</tbody>
					</table> */}
          <div className="table-responsive">
            <table className="table m-t-md">
              <thead>
                <tr>
                  <th>Measurement </th>
                  <th>You (N)</th>
                  <th>You (lbs)</th>
                  <th>Average Performers</th>
                  <th>Average Performers</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Absolute Strength</td>
                  <td>
                    <Form.Item valuePropName="checked" name="p14_ms_peak_force">
                      <Checkbox onChange={handleChangeCheckbox} />
                    </Form.Item>
                  </td>
                  <td>
                    <Form.Item
                      valuePropName="checked"
                      name="p14_ms_peak_force_lbs"
                    >
                      <Checkbox onChange={handleChangeCheckbox} />
                    </Form.Item>
                  </td>
                  <td>
                    <Form.Item
                      valuePropName="checked"
                      name="p14_age_avg_isometric_pull_n"
                    >
                      <Checkbox onChange={handleChangeCheckbox} />
                    </Form.Item>
                  </td>
                  <td>
                    <Form.Item
                      valuePropName="checked"
                      name="p14_age_avg_isometric_pull"
                    >
                      <Checkbox onChange={handleChangeCheckbox} />
                    </Form.Item>
                  </td>
                </tr>
              </tbody>
            </table>
            <table className="table m-t-md">
              <thead>
                <tr>
                  <th>Measurement </th>
                  <th>You</th>
                  <th>Average Performers</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Relative Strength Peak Force/Body Weight</td>
                  <td>
                    <Form.Item
                      valuePropName="checked"
                      name="p14_relative_stregth_pf_bw"
                    >
                      <Checkbox onChange={handleChangeCheckbox} />
                    </Form.Item>
                  </td>
                  <td>
                    <Form.Item
                      valuePropName="checked"
                      name="p14_relative_stregth_pf_bw_avg_per"
                    >
                      <Checkbox onChange={handleChangeCheckbox} />
                    </Form.Item>
                  </td>
                </tr>
              </tbody>
            </table>
            <table className="table m-t-md">
              <thead>
                <tr>
                  <th>Your Rank</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <Form.Item valuePropName="checked" name="p_14_rank">
                      <Checkbox onChange={handleChangeCheckbox} />
                    </Form.Item>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </Collapse.Panel>
      </Collapse>

      <Collapse
        className="main-1-collapse border-none"
        expandIcon={({ isActive }) =>
          isActive ? (
            <span
              className="ant-menu-submenu-arrow"
              style={{ color: "#FFF", transform: "rotate(270deg)" }}
            ></span>
          ) : (
            <span
              className="ant-menu-submenu-arrow"
              style={{ color: "#FFF", transform: "rotate(90deg)" }}
            ></span>
          )
        }
        defaultActiveKey={["1"]}
        expandIconPosition="start"
      >
        <Collapse.Panel
          header="PAGE 15"
          key="1"
          className="accordion bg-darkgray-form m-b-md border"
        >
          <Typography.Title level={4} className="text-center">
            Rate of Force Development (RFD)
          </Typography.Title>

          <div className="table-responsive">
            <table className="table m-t-md">
              <thead>
                <tr>
                  <th>Peak Force (lbs)</th>
                  <th>Your Weight (lbs)</th>
                  <th>Peak Force/Body Weight</th>
                  <th>Force (lbs) @ 0-100ms</th>
                  <th>0-100ms RFD % of Peak Force</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <Form.Item
                      valuePropName="checked"
                      name="p15_ms_peak_force_lbs"
                    >
                      <Checkbox onChange={handleChangeCheckbox} />
                    </Form.Item>
                  </td>
                  <td>
                    <Form.Item valuePropName="checked" name="p15_weight">
                      <Checkbox onChange={handleChangeCheckbox} />
                    </Form.Item>
                  </td>
                  <td>
                    <Form.Item
                      valuePropName="checked"
                      name="p15_relative_stregth_pf_bw_lbs"
                    >
                      <Checkbox onChange={handleChangeCheckbox} />
                    </Form.Item>
                  </td>
                  <td>
                    <Form.Item valuePropName="checked" name="p15_ms_rfd_lbs">
                      <Checkbox onChange={handleChangeCheckbox} />
                    </Form.Item>
                  </td>
                  <td>
                    <Form.Item
                      valuePropName="checked"
                      name="p15_RFD_percent_of_peak_force_lbs"
                    >
                      <Checkbox onChange={handleChangeCheckbox} />
                    </Form.Item>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          {/* <table className="table m-t-md">
						<thead>
							<tr>
								<th>Peak Force</th>
								<th>Rank</th>
								<th>Training Emphasis Recommendations</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>
									<Form.Item
										valuePropName="checked"
										name="p15_2_peak_force_low"
									>
										<Checkbox onChange={handleChangeCheckbox} />
									</Form.Item>
								</td>
								<td>
									<Form.Item
										valuePropName="checked"
										name="p15_2_peak_force_average"
									>
										<Checkbox onChange={handleChangeCheckbox} />
									</Form.Item>
								</td>
								<td>
									<Form.Item
										valuePropName="checked"
										name="p15_2_peak_force_high"
									>
										<Checkbox onChange={handleChangeCheckbox} />
									</Form.Item>
								</td>
							</tr>
						</tbody>
					</table> */}
        </Collapse.Panel>
      </Collapse>

      <Collapse
        className="main-1-collapse border-none"
        expandIcon={({ isActive }) =>
          isActive ? (
            <span
              className="ant-menu-submenu-arrow"
              style={{ color: "#FFF", transform: "rotate(270deg)" }}
            ></span>
          ) : (
            <span
              className="ant-menu-submenu-arrow"
              style={{ color: "#FFF", transform: "rotate(90deg)" }}
            ></span>
          )
        }
        defaultActiveKey={["1"]}
        expandIconPosition="start"
      >
        <Collapse.Panel
          header="PAGE 16"
          key="1"
          className="accordion bg-darkgray-form m-b-md border"
        >
          <Typography.Title level={4} className="text-center">
            Dynamic Strength Index (DSI)
          </Typography.Title>

          <div className="table-responsive">
            <table className="table m-t-md">
              <thead>
                <tr>
                  <th>IMTP Peak Force</th>
                  <th>CMJ Peak Force</th>
                  <th>Your DSI Score</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <Form.Item valuePropName="checked" name="p16_ms_peak_force">
                      <Checkbox onChange={handleChangeCheckbox} />
                    </Form.Item>
                  </td>
                  <td>
                    <Form.Item
                      valuePropName="checked"
                      name="p16_es_peak_propulsive_force"
                    >
                      <Checkbox onChange={handleChangeCheckbox} />
                    </Form.Item>
                  </td>
                  <td>
                    <Form.Item valuePropName="checked" name="p16_dsi_score">
                      <Checkbox onChange={handleChangeCheckbox} />
                    </Form.Item>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </Collapse.Panel>
      </Collapse>

      <Collapse
        className="main-1-collapse border-none"
        expandIcon={({ isActive }) =>
          isActive ? (
            <span
              className="ant-menu-submenu-arrow"
              style={{ color: "#FFF", transform: "rotate(270deg)" }}
            ></span>
          ) : (
            <span
              className="ant-menu-submenu-arrow"
              style={{ color: "#FFF", transform: "rotate(90deg)" }}
            ></span>
          )
        }
        defaultActiveKey={["1"]}
        expandIconPosition="start"
      >
        <Collapse.Panel
          header="PAGE 17"
          key="1"
          className="accordion bg-darkgray-form m-b-md border"
        >
          <Typography.Title level={4} className="text-center">
            Summary of Findings
          </Typography.Title>

          <div className="table-responsive">
            <table className="table m-t-md">
              <thead>
                <tr>
                  <th>BFS Testing: Performance Tests </th>
                  <th>Reactive Strength Index</th>
                  <th>Dynamic Strength Index</th>
                  <th>Eccentric Utilization Ratio</th>
                  <th>relative Strength</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>You </td>
                  <td>
                    <Form.Item valuePropName="checked" name="p17_cmj_rsi_no">
                      <Checkbox onChange={handleChangeCheckbox} />
                    </Form.Item>
                  </td>
                  <td>
                    <Form.Item valuePropName="checked" name="p17_dsi_score">
                      <Checkbox onChange={handleChangeCheckbox} />
                    </Form.Item>
                  </td>
                  <td>
                    <Form.Item valuePropName="checked" name="p17_eur">
                      <Checkbox onChange={handleChangeCheckbox} />
                    </Form.Item>
                  </td>
                  <td>
                    <Form.Item valuePropName="checked" name="p17_ms_peak_force">
                      <Checkbox onChange={handleChangeCheckbox} />
                    </Form.Item>
                  </td>
                </tr>
                <tr>
                  <td>Average Performers</td>
                  <td>
                    <Form.Item
                      valuePropName="checked"
                      name="p17_age_avg_cmj_rsi_no_sum"
                    >
                      <Checkbox onChange={handleChangeCheckbox} />
                    </Form.Item>
                  </td>
                  <td>
                    <Form.Item
                      valuePropName="checked"
                      name="p17_dsi_score_avg_tot"
                    >
                      <Checkbox onChange={handleChangeCheckbox} />
                    </Form.Item>
                  </td>
                  <td>
                    <Form.Item
                      valuePropName="checked"
                      name="p17_avg_eccentric_utilization_ratio"
                    >
                      <Checkbox onChange={handleChangeCheckbox} />
                    </Form.Item>
                  </td>
                  <td>
                    <Form.Item
                      valuePropName="checked"
                      name="p17_avg_relative_strength"
                    >
                      <Checkbox onChange={handleChangeCheckbox} />
                    </Form.Item>
                  </td>
                </tr>
                <tr>
                  <td>Benchmark</td>
                  <td>
                    <Form.Item valuePropName="checked" name="p17_average_1">
                      <Checkbox onChange={handleChangeCheckbox} />
                    </Form.Item>
                  </td>
                  <td>
                    {/* <Form.Item valuePropName="checked" name="p17_average_2">
                      <Checkbox onChange={handleChangeCheckbox} />
                    </Form.Item> */}
                  </td>
                  <td>
                    {/* <Form.Item valuePropName="checked" name="p17_average_3">
                      <Checkbox onChange={handleChangeCheckbox} />
                    </Form.Item> */}
                  </td>
                  <td>
                    <Form.Item valuePropName="checked" name="p17_average_4">
                      <Checkbox onChange={handleChangeCheckbox} />
                    </Form.Item>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </Collapse.Panel>
      </Collapse>
    </Form>
  );
}
