import React, { useEffect, useState } from "react";
import { Collapse, Space } from "antd";
import {
  GET,
  GETMANUAL,
  POST,
  UPDATE,
} from "../../../../../providers/useAxiosQuery";
import { List } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { Modal } from "antd";
import { Popconfirm } from "antd";
import { Button } from "antd";
import { Form } from "antd";
import { Row } from "antd";
import { Col } from "antd";
import FloatInput from "../../../../../providers/FloatInput";
import FloatSelect from "../../../../../providers/FloatSelect";
import optionStateCodesUnitedState from "../../../../../providers/optionStateCodesUnitedState";
import optionStateCodesCanada from "../../../../../providers/optionStateCodesCanada";
import optionCountryCodes from "../../../../../providers/optionCountryCodes";
import companyInfo from "../../../../../providers/companyInfo";
import optionOrganizationType from "../../../../../providers/optionOrganizationType";
import { Table } from "antd";
import FloatAutoCompleteOrg from "../../../../../providers/FloatAutoCompleteOrg";
import { notification } from "antd";
const userdata = companyInfo().userData;
export default function ComponentsPendingApproval() {
  const [organizationData, setOrganizationData] = useState([]);
  const { isLoading: isLoadingPendingOrganization } = GET(
    "api/v1/manager_dashboard_pending_list",
    "manager_dashboard_pending_list",
    (res) => {
      if (res.success) {
        let row = [];
        res.data.map((item, key) => {
          let member = item.athlete_organization.concat(
            item.coach_organization
          );

          // FOR FORM ORGANIZATION VALUE
          row.push({
            ...item,
            members: member,
          });
        });
        setOrganizationData(row);
        console.log("manager_dashboard_pending_list", row);
      }
    }
  );

  const [formEditOrganization] = Form.useForm();
  const [selectOrganizationModal, setSelectOrganizationModal] = useState(false);
  const [selectOrganizationEdit, setSelectOrganizationEdit] = useState();
  const [selectOrganizationEditReset, setSelectOrganizationEditReset] =
    useState();
  const handleOrganizationView = (record, key) => {
    setSelectOrganizationModal(true);
    setSelectOrganizationEdit(record);
    setSelectOrganizationEditReset(record);
    formEditOrganization.setFieldsValue(record);

    if (record.country === "United States") {
      setStateLabel("State");
      setZipLabel("Zip Code");
      setOptionState(stateUS);
      setOptionZip(/(^\d{5}$)|(^\d{5}[ -]\d{4}$)/);

      let get_city = stateUS.filter((item) => record.state === item.value);
      setOptionCity(get_city[0]["json"]);
    } else if (record.country === "Canada") {
      setStateLabel("County");
      setZipLabel("Postal Code");
      setOptionState(stateCA);
      setOptionZip(/^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/);

      let get_city = stateCA.filter((item) => record.state === item.value);
      setOptionCity(get_city[0]["json"]);
    }
  };

  useEffect(() => {
    if (!selectOrganizationModal) {
      formEditOrganization.resetFields();
      formSearch.resetFields();
      setSearchOrganizationList([]);
    }
  }, [selectOrganizationModal]);

  const [stateUS, setStateUS] = useState(optionStateCodesUnitedState());
  const [stateCA, setStateCA] = useState(optionStateCodesCanada());
  const [searchCountry, setSearchCountry] = useState([]);

  const { data: dataManagerArea, isLoading: isLoadingManagerArea } = GET(
    "api/v1/get_manager_state_area_data",
    "get_manager_state_area",
    (res) => {
      if (res.success) {
        console.log("get_manager_state_area", res);
        if (userdata.role === "Manager") {
          setSearchCountry(res.countries);
          if (res.statesUS.length > 0) {
            let option_states = [];
            res.statesUS.map((row, key) => {
              stateUS.find((search) => {
                if (search.value == row.value) {
                  option_states.push({
                    ...row,
                    label: search.label,
                  });
                }
              });
            });
            setStateUS(option_states);
          }
          if (res.statesCA.length > 0) {
            let option_states = [];
            res.statesCA.map((row, key) => {
              stateCA.find((search) => {
                if (search.value == row.value) {
                  option_states.push({
                    ...row,
                    label: search.label,
                  });
                }
              });
            });
            setStateCA(option_states);
          }
        } else {
          setSearchCountry(optionCountryCodes);
        }
      }
    }
  );

  const [stateLabel, setStateLabel] = useState("State");
  const [zipLabel, setZipLabel] = useState("Zip Code");
  const [optionState, setOptionState] = useState([]);
  const [optionCity, setOptionCity] = useState([]);
  const [optionZip, setOptionZip] = useState(/(^\d{5}$)|(^\d{5}[ -]\d{4}$)/);

  const handleCountry = (val, opt) => {
    if (val === "United States") {
      setStateLabel("State");
      setZipLabel("Zip Code");
      setOptionState(stateUS);

      setOptionZip(/(^\d{5}$)|(^\d{5}[ -]\d{4}$)/);
    } else if (val === "Canada") {
      setStateLabel("County");
      setZipLabel("Postal Code");
      setOptionState(stateCA);
      setOptionZip(/^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/);
    }
    setOptionCity([]);
    formEditOrganization.resetFields(["state", "zip", "city"]);
  };

  const [formSearch] = Form.useForm();
  const [searchOptionLabel, setSearchOptionLabel] = useState("State");
  const [searchOption, setSearchOption] = useState([]);
  const [searchOrganizationList, setSearchOrganizationList] = useState([]);
  const [searchFilterData, setSearchFilterData] = useState({
    country: "",
    state: "",
    search: "",
  });
  const handleSearchCountry = (val, opt) => {
    if (val === "United States") {
      setSearchOptionLabel("State");
      setSearchOption(stateUS);
    } else if (val === "Canada") {
      setSearchOptionLabel("County");
      setSearchOption(stateCA);
    }
    // setSearchFilterData({
    //   ...searchFilterData,
    //   country: val ? val : "",
    // });
    formSearch.resetFields(["state"]);
  };

  const [searchText, setSearchText] = useState("");
  const [orgSelected, setOrgSelected] = useState();
  const onChangeSearchText = (val, opt) => {
    if (val) {
      let selected_row = opt["data-json"];
      setOrgSelected(selected_row);
    } else {
      setOrgSelected();
      setSearchOrganizationList([]);
    }
    setSearchText(val);
  };

  const { refetch: refetchSearch, isLoading: isLoadingSearch } = GETMANUAL(
    `api/v1/organization/search/get?${new URLSearchParams(searchFilterData)}`,
    "organization/search/get",
    (res) => {
      if (res.success) {
        let arr = [];
        res.data.map((item, key) => {
          arr.push({
            label: item.name,
            value: item.name,
            json: item,
          });
        });
        setSearchOrganizationList(arr);
        console.log("onChangeSearchText", arr);
      }
    }
  );

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      let formData = formSearch.getFieldsValue();

      console.log("onChangeSearchText", formData);
      setSearchFilterData({
        ...searchFilterData,
        country: formData.country ? formData.country : "",
        state: formData.state ? formData.state : "",
        search: searchText,
      });
    }, 1500);
    return () => {
      clearTimeout(timeoutId);
    };
  }, [searchText]);

  useEffect(() => {
    if (searchFilterData.search) {
      refetchSearch();
    }
  }, [searchFilterData]);

  const handleUseOrganization = () => {
    let data = {
      ...selectOrganizationEdit,
      address1: orgSelected.address1,
      address2: orgSelected.address2,
      city: orgSelected.city,
      country: orgSelected.country,
      name: orgSelected.name,
      organization_type: orgSelected.organization_type,
      state: orgSelected.state,
      selected_id: orgSelected.id,
    };

    formEditOrganization.setFieldsValue(data);
    setSelectOrganizationEdit(data);
    console.log("orgSelected", data);
  };

  const approveOrganization = () => {
    let value = {
      ...selectOrganizationEdit,
      status: "Approved",
    };
    console.log("orgSelected", value);
    mutateApprove(value, {
      onSuccess: (res) => {
        if (res.success) {
          notification.success({
            message: "Success",
            description: "Successfully Approved",
          });
          setIsModalApprove1(false);
          setSelectOrganizationModal(false);
        }
      },
    });
  };

  const { mutate: mutateApprove, isLoading: isLoadingApprove } = POST(
    "api/v1/approve_organization",
    "manager_dashboard_pending_list"
  );

  const [isModalApprove1, setIsModalApprove1] = useState(false);
  const removeOrganization = (organization_id) => {
    let data = {
      id: organization_id,
      status: "Deleted",
    };
    console.log("removeOrganization", data);

    mutateDeleteOrganization(data, {
      onSuccess: (res) => {
        if (res.success) {
          notification.success({
            message: "Success",
            description: "Successfully Deleted",
          });
          setIsModalApprove1(false);
          setSelectOrganizationModal(false);
        }
      },
    });
  };

  const {
    mutate: mutateDeleteOrganization,
    isLoading: isLoadingDeleteOrganization,
  } = UPDATE("api/v1/organization", "manager_dashboard_pending_list");

  return (
    <>
      <Collapse
        className="main-1-collapse"
        expandIcon={({ isActive }) =>
          isActive ? (
            <span
              className="ant-menu-submenu-arrow"
              style={{ color: "#FFF", transform: "rotate(270deg)" }}
            ></span>
          ) : (
            <span
              className="ant-menu-submenu-arrow"
              style={{ color: "#FFF", transform: "rotate(90deg)" }}
            ></span>
          )
        }
        defaultActiveKey={["1"]}
        expandIconPosition="start"
      >
        <Collapse.Panel
          header="PENDING APPROVAL"
          key="1"
          className="accordion bg-darkgray-form dashbaord-collapse dash-org-collapse"
          style={{ padding: "0" }}
        >
          <List
            bordered
            size="large"
            className="scrollbar-2"
            loading={isLoadingPendingOrganization || isLoadingManagerArea}
            style={{ height: "250px", overflow: "auto" }}
            dataSource={organizationData}
            renderItem={(item, key) => (
              <List.Item key={key}>
                <div className="dash-org-list">
                  <div
                    className="dash-org-list-left"
                    style={{ overflowWrap: "break-word" }}
                  >
                    {item.name} {item.address1}, {item.state}{" "}
                  </div>
                  <Space>
                    <FontAwesomeIcon
                      icon={faEye}
                      className="color-3 cursor-pointer"
                      onClick={(e) => handleOrganizationView(item, key)}
                    />
                  </Space>
                </div>
              </List.Item>
            )}
          />
        </Collapse.Panel>
      </Collapse>

      <Modal
        title="EDIT ORGANIZATION"
        visible={selectOrganizationModal}
        onCancel={(e) => setSelectOrganizationModal(false)}
        okText="UPDATE"
        width={950}
        className="modal-pending-approval-manager"
        footer={[
          <Space key={"foot"}>
            <Popconfirm
              title="Are you sure to delete this organization?"
              okText="Yes"
              cancelText="No"
              onConfirm={() => {
                removeOrganization(selectOrganizationEdit.id);
              }}
            >
              <Button
                key="UPDATE"
                className="btn-main-outline-9"
                loading={isLoadingDeleteOrganization}
              >
                DELETE
              </Button>
            </Popconfirm>

            <Button
              key="APPROVE"
              className="btn-main-outline-7"
              onClick={(e) => {
                formEditOrganization
                  .validateFields()
                  .then((values) => {
                    let data = {
                      ...selectOrganizationEdit,
                      ...values,
                    };
                    setSelectOrganizationEdit(data);
                    setIsModalApprove1(true);
                    console.log("formEditOrganization", data);
                  })
                  .catch((info) => {
                    //   notification.warning({
                    //     message: "Warning",
                    //     description: "Please fill-up required fields!",
                    //   });
                  });
              }}
            >
              APPROVE
            </Button>
          </Space>,
        ]}
      >
        <Row gutter={12}>
          <Col xs={24} sm={24} md={16}>
            <Form
              form={formEditOrganization}
              name="edit-form"
              layout="vertical"
            >
              <Row gutter={12}>
                <Col xs={24} sm={24} md={24}>
                  <Form.Item
                    name={"name"}
                    rules={[
                      {
                        required: true,
                        message: "This field field is required.",
                      },
                    ]}
                  >
                    <FloatInput
                      label="Organization Name"
                      placeholder="Organization Name"
                      required
                    />
                  </Form.Item>
                </Col>

                <Col xs={24} sm={24} md={12}>
                  <Form.Item
                    name={"organization_type"}
                    className="form-select-error"
                    rules={[
                      {
                        required: true,
                        message: "This field field is required.",
                      },
                    ]}
                  >
                    <FloatSelect
                      label="Type"
                      placeholder="Type"
                      options={optionOrganizationType}
                      required
                    />
                  </Form.Item>
                </Col>

                <Col xs={24} sm={24} md={12}>
                  <Form.Item
                    name={"country"}
                    className="form-select-error"
                    rules={[
                      {
                        required: true,
                        message: "This field field is required.",
                      },
                    ]}
                  >
                    <FloatSelect
                      label="Country"
                      placeholder="Country"
                      options={searchCountry}
                      onChange={handleCountry}
                      required
                    />
                  </Form.Item>
                </Col>

                <Col xs={24} sm={24} md={24}>
                  <Form.Item
                    name={"address1"}
                    rules={[
                      {
                        required: true,
                        message: "This field field is required.",
                      },
                    ]}
                  >
                    <FloatInput
                      label="Address 1"
                      placeholder="Address 1"
                      required
                    />
                  </Form.Item>
                </Col>

                <Col xs={24} sm={24} md={24}>
                  <Form.Item name={"address2"}>
                    <FloatInput label="Address 2" placeholder="Address 2" />
                  </Form.Item>
                </Col>

                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name={"state"}
                    className="form-select-error"
                    rules={[
                      {
                        required: true,
                        message: "This field field is required.",
                      },
                    ]}
                  >
                    <FloatSelect
                      label={stateLabel}
                      placeholder={stateLabel}
                      options={optionState}
                      onChange={(val, opt) => {
                        if (val) {
                          setOptionCity(opt["data-json"]);
                          formEditOrganization.resetFields(["city"]);
                        }
                      }}
                      required
                    />
                  </Form.Item>
                </Col>

                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name={"city"}
                    className="form-select-error"
                    rules={[
                      {
                        required: true,
                        message: "This field field is required.",
                      },
                    ]}
                  >
                    <FloatSelect
                      label="City"
                      placeholder="City"
                      options={optionCity}
                      required
                    />
                  </Form.Item>
                </Col>

                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name={"zip"}
                    rules={[
                      {
                        required: true,
                        message: "This field field is required.",
                      },
                      {
                        pattern: optionZip,
                        message: "Invalid " + zipLabel,
                      },
                    ]}
                  >
                    <FloatInput
                      label={zipLabel}
                      placeholder={zipLabel}
                      required
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Col>

          <Col xs={24} sm={24} md={8}>
            <Form form={formSearch} name="search-form" layout="vertical">
              <Row gutter={12}>
                <Col xs={24} sm={24} md={24}>
                  <Form.Item name="country" className="form-select-error">
                    <FloatSelect
                      label={"Country"}
                      placeholder={"Country"}
                      options={searchCountry}
                      onChange={handleSearchCountry}
                      allowClear={true}
                    />
                  </Form.Item>
                </Col>

                <Col xs={24} sm={24} md={24}>
                  <Form.Item name="state" className="form-select-error">
                    <FloatSelect
                      label={searchOptionLabel}
                      placeholder={searchOptionLabel}
                      options={searchOption}
                      allowClear={true}
                    />
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <Form.Item name="search">
                    <FloatAutoCompleteOrg
                      label="Search"
                      placeholder="Search"
                      options={searchOrganizationList}
                      allowClear={true}
                      onChange={(val, opt) => onChangeSearchText(val, opt)}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Space>
                <Button
                  size="small"
                  className="btn-main-outline-9"
                  onClick={(e) => {
                    setSelectOrganizationEdit(selectOrganizationEditReset);
                    formEditOrganization.setFieldsValue(
                      selectOrganizationEditReset
                    );
                    formSearch.resetFields();
                  }}
                  // style={{ marginTop: "2px" }}
                >
                  RESET
                </Button>
                {orgSelected && (
                  <Button
                    size="small"
                    className="btn-main-outline-2"
                    // style={{ marginTop: "2px" }}
                    onClick={(e) => handleUseOrganization()}
                  >
                    USE
                  </Button>
                )}
              </Space>
            </Form>
          </Col>
        </Row>

        <Row gutter={12} className="m-t-md">
          <Col xs={24} sm={24} md={24}>
            <Table
              dataSource={
                selectOrganizationEdit && selectOrganizationEdit.members
              }
              bordered
              rowKey={(record) => record.name}
              scroll={{ x: "max-content" }}
              pagination={false}
            >
              <Table.Column
                title="Name"
                key="name"
                dataIndex="name"
                width={"150px"}
              />
              <Table.Column
                title="Role"
                key="role"
                dataIndex="role"
                width={"150px"}
              />
              <Table.Column
                title="State"
                key="state"
                dataIndex="state"
                width={"150px"}
              />
              <Table.Column
                title="City"
                key="city"
                dataIndex="city"
                width={"150px"}
              />
            </Table>
          </Col>
        </Row>
      </Modal>

      <Modal
        title="APPROVE ORGANIZATION"
        visible={isModalApprove1}
        onCancel={(e) => {
          setIsModalApprove1(false);
        }}
        footer={[
          <Space key={"yes"}>
            <Button
              key="btn"
              loading={isLoadingApprove}
              onClick={(e) => {
                approveOrganization();
              }}
              className="btn-main-outline-2"
            >
              YES, APPROVE
            </Button>
          </Space>,
        ]}
      >
        <p>
          You are about to approve this organization and add it to the system,
          which CAN NOT be undone. Are you sure you have checked the spelling
          and did a search for this organization in the system before
          continuing?
        </p>
      </Modal>
    </>
  );
}
