import {
  Button,
  Card,
  Col,
  Collapse,
  Form,
  notification,
  Row,
  Space,
  Table,
} from "antd";
import React, { useState, useEffect } from "react";
import { PlusOutlined } from "@ant-design/icons";
import { faSchool } from "@fortawesome/pro-regular-svg-icons";
// import { useHistory } from "react-router-dom";
// import $ from "jquery";

import ComponentHeader from "../../Components/ComponentHeader";
import {
  TableGlobalSearch,
  TablePageSize,
  TablePagination,
  TableShowingEntries,
} from "../../Components/ComponentTableFilter";
import { GET, POST } from "../../../../providers/useAxiosQuery";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
// import companyInfo from "../../../../providers/companyInfo";

import $ from "jquery";
import FloatInput from "../../../../providers/FloatInput";
import FloatTextArea from "../../../../providers/FloatTextArea";

export default function PageRegistrationFee({ match, permission }) {
  const [form] = Form.useForm();
  const [formRevenue] = Form.useForm();
  const [formSplit] = Form.useForm();

  const validate = {
    pattern: {
      // pattern: new RegExp(/(^[1-9][0-9]*)(\.[0-9]*)?$/),
      pattern: new RegExp(/^\d*\.?\d*$/),
      message: "Invalid value",
    },
  };

  const [dataID, setDataID] = useState("");
  GET("api/v1/setting_fee/1", "setting_fee", (res) => {
    if (res.success) {
      console.log("res", res);
      setDataID(res.data.id);
      form.setFieldsValue({
        amount: res.data.amount,
        message_dashboard_athlete: res.data.message_dashboard_athlete,
      });
      formRevenue.setFieldsValue({
        revenue_percent_amount: res.data.revenue_percent_amount
          ? res.data.revenue_percent_amount
          : undefined,
      });
      formSplit.setFieldsValue({
        manager_split_amount: res.data.manager_split_amount
          ? res.data.manager_split_amount
          : undefined,
      });
    }
  });

  const { mutate: mutateUpdate, isLoading: isLoadingUpdate } = POST(
    "api/v1/setting_fee",
    "setting_fee"
  );

  const onFinishForm = (values) => {
    console.log("onFinishForm", values);

    let data = {
      ...values,
      id: dataID ? dataID : undefined,
    };

    mutateUpdate(data, {
      onSuccess: (res) => {
        if (res.success) {
          notification.success({
            message: "Success",
            description: "Successfully Updated!",
          });
        }
      },
    });
  };

  const { mutate: mutateUpdateRevenu, isLoading: isLoadingUpdateRevenue } =
    POST("api/v1/store_update", "setting_fee");

  const onFinishFormRevenue = (values) => {
    let data = {
      ...values,
      id: dataID ? dataID : undefined,
    };
    console.log("onFinishForm", data);

    mutateUpdateRevenu(data, {
      onSuccess: (res) => {
        if (res.success) {
          notification.success({
            message: "Success",
            description: "Successfully Updated!",
          });
        }
      },
    });
  };

  const { mutate: mutateUpdateSplit, isLoading: isLoadingUpdateSplit } = POST(
    "api/v1/split_update",
    "setting_fee"
  );
  const onFinishFormSplit = (values) => {
    let data = {
      ...values,
      id: dataID ? dataID : undefined,
    };
    console.log("onFinishForm", data);

    mutateUpdateSplit(data, {
      onSuccess: (res) => {
        if (res.success) {
          notification.success({
            message: "Success",
            description: "Successfully Updated!",
          });
        }
      },
    });
  };

  return (
    <>
      <ComponentHeader title={permission} sub_title="VIEW" icon={faFilePdf} />

      <Card className="card-min-height" id="PageAssessmentPdf">
        <Row gutter={12}>
          <Col xs={24} sm={24} md={12}>
            <Form form={form} name="fee">
              <Collapse
                className="main-1-collapse border-none"
                expandIcon={({ isActive }) =>
                  isActive ? (
                    <span
                      className="ant-menu-submenu-arrow"
                      style={{ color: "#FFF", transform: "rotate(270deg)" }}
                    ></span>
                  ) : (
                    <span
                      className="ant-menu-submenu-arrow"
                      style={{ color: "#FFF", transform: "rotate(90deg)" }}
                    ></span>
                  )
                }
                defaultActiveKey={["1", "2"]}
                expandIconPosition="start"
              >
                <Collapse.Panel
                  header="Edit Registration Fee"
                  key="1"
                  className="accordion bg-darkgray-form m-b-md border"
                >
                  <Form.Item name="amount" rules={[validate.pattern]}>
                    <FloatInput label="Amount" placeholder="Amount" />
                  </Form.Item>
                  <Form.Item
                    name="message_dashboard_athlete"
                    // rules={[validate.pattern]}
                  >
                    <FloatTextArea
                      label="Credit Alert"
                      placeholder="Credit Alert"
                    />
                  </Form.Item>

                  <Row gutter={12}>
                    <Col xs={24} sm={24} md={12}>
                      <Button
                        block
                        size="large"
                        className="btn-main-outline-2"
                        loading={isLoadingUpdate}
                        onClick={(e) => {
                          form
                            .validateFields()
                            .then((values) => {
                              onFinishForm(values);
                            })
                            .catch((info) => {
                              console.log("Validate Failed:", info);
                            });
                        }}
                      >
                        UPDATE
                      </Button>
                    </Col>
                  </Row>
                </Collapse.Panel>
              </Collapse>
            </Form>
          </Col>

          <Col xs={24} sm={24} md={12}>
            <Form form={formRevenue} name="revenue">
              <Collapse
                className="main-1-collapse border-none"
                expandIcon={({ isActive }) =>
                  isActive ? (
                    <span
                      className="ant-menu-submenu-arrow"
                      style={{ color: "#FFF", transform: "rotate(270deg)" }}
                    ></span>
                  ) : (
                    <span
                      className="ant-menu-submenu-arrow"
                      style={{ color: "#FFF", transform: "rotate(90deg)" }}
                    ></span>
                  )
                }
                defaultActiveKey={["1", "2"]}
                expandIconPosition="start"
              >
                <Collapse.Panel
                  header="Edit BFSS license Fee"
                  key="1"
                  className="accordion bg-darkgray-form m-b-md border"
                >
                  <Form.Item
                    name="revenue_percent_amount"
                    rules={[validate.pattern]}
                  >
                    <FloatInput label="Amount (%)" placeholder="Amount (%)" />
                  </Form.Item>

                  <Row gutter={12}>
                    <Col xs={24} sm={24} md={12}>
                      <Button
                        block
                        size="large"
                        className="btn-main-outline-2"
                        loading={isLoadingUpdateRevenue}
                        onClick={(e) => {
                          formRevenue
                            .validateFields()
                            .then((values) => {
                              onFinishFormRevenue(values);
                            })
                            .catch((info) => {
                              console.log("Validate Failed:", info);
                            });
                        }}
                      >
                        UPDATE
                      </Button>
                    </Col>
                  </Row>
                </Collapse.Panel>
              </Collapse>
            </Form>
          </Col>
        </Row>
        <br />

        <Row gutter={12}>
          <Col xs={24} sm={24} md={12}>
            <Form form={formSplit} name="revenue">
              <Collapse
                className="main-1-collapse border-none"
                expandIcon={({ isActive }) =>
                  isActive ? (
                    <span
                      className="ant-menu-submenu-arrow"
                      style={{ color: "#FFF", transform: "rotate(270deg)" }}
                    ></span>
                  ) : (
                    <span
                      className="ant-menu-submenu-arrow"
                      style={{ color: "#FFF", transform: "rotate(90deg)" }}
                    ></span>
                  )
                }
                defaultActiveKey={["1", "2"]}
                expandIconPosition="start"
              >
                <Collapse.Panel
                  header="Edit BFSS Manager Split Amount"
                  key="1"
                  className="accordion bg-darkgray-form m-b-md border"
                >
                  <Form.Item
                    name="manager_split_amount"
                    rules={[validate.pattern]}
                  >
                    <FloatInput label="Amount (%)" placeholder="Amount (%)" />
                  </Form.Item>

                  <Row gutter={12}>
                    <Col xs={24} sm={24} md={12}>
                      <Button
                        block
                        size="large"
                        className="btn-main-outline-2"
                        loading={isLoadingUpdateSplit}
                        onClick={(e) => {
                          formSplit
                            .validateFields()
                            .then((values) => {
                              onFinishFormSplit(values);
                            })
                            .catch((info) => {
                              console.log("Validate Failed:", info);
                            });
                        }}
                      >
                        UPDATE
                      </Button>
                    </Col>
                  </Row>
                </Collapse.Panel>
              </Collapse>
            </Form>
          </Col>
        </Row>
      </Card>
    </>
  );
}
