import { Button, Form, Modal } from "antd";
import { useEffect } from "react";
import FloatInput from "../../../../providers/FloatInput";

export default function ModalSchool(props) {
  const {
    toggleModalForm,
    setToggleModalForm,
    handleOnFinish,
    isLoadingCreateSchool,
  } = props;

  const [form] = Form.useForm();

  useEffect(() => {
    if (toggleModalForm.data !== null) {
      form.setFieldsValue({ ...toggleModalForm.data });
    } else {
      form.resetFields();
    }

    return () => {};
  }, [toggleModalForm, form]);

  return (
    <Modal
      visible={toggleModalForm.show}
      width={600}
      title="School Form"
      onCancel={() => setToggleModalForm({ show: false, data: null })}
      footer={
        <>
          {/* <Button
            size="large"
            style={{ width: "33%" }}
            onClick={() => setToggleModalForm({ show: false, data: null })}
          >
            CLOSE
          </Button> */}

          <Button
            type="primary"
            loading={isLoadingCreateSchool}
            className="btn-main-outline-2"
            size="large"
            style={{ width: "33%" }}
            onClick={() => form.submit()}
          >
            SUBMIT
          </Button>
        </>
      }
      className="dashboard-modal-event-preview"
    >
      <Form form={form} onFinish={handleOnFinish} autoComplete="off">
        <Form.Item
          name="school"
          hasFeedback
          rules={[
            {
              required: true,
              message: "This field school is required.",
            },
          ]}
        >
          <FloatInput label="School" placeholder="School" />
        </Form.Item>
      </Form>
    </Modal>
  );
}
