import { useState } from "react";
import {
  Alert,
  Button,
  Card,
  Col,
  Form,
  Modal,
  notification,
  Row,
  Typography,
} from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { faInfo, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { GET, POST } from "../../../../../providers/useAxiosQuery";
import companyInfo from "../../../../../providers/companyInfo";
// import FloatDatePicker from "../../../../../providers/FloatDatePicker";
import FloatDatePickerForm from "../../../../../providers/FloatDatePickerForm";
import FloatInput from "../../../../../providers/FloatInput";
import FloatSelect from "../../../../../providers/FloatSelect";
import FloatSelectCoach from "../../../../../providers/FloatSelectCoach";
import FloatTextArea from "../../../../../providers/FloatTextArea";
import optionCountryCodes from "../../../../../providers/optionCountryCodes";
import optionStateCodesUnitedState from "../../../../../providers/optionStateCodesUnitedState";
import optionStateCodesCanada from "../../../../../providers/optionStateCodesCanada";
import optionOrganizationType from "../../../../../providers/optionOrganizationType";
import $ from "jquery";

const firstname = companyInfo().userData.firstname;
const userData = companyInfo().userData;
const encryptor = companyInfo().encryptor;

export default function ModalFormWelcome() {
  const [modalForm] = Form.useForm();

  const [openModal, setOpenModal] = useState(
    userData.one_time_modal === 0 ? true : false
  );

  const stateUS = optionStateCodesUnitedState();
  const stateCA = optionStateCodesCanada();
  const [optionState, setOptionState] = useState([]);
  const [stateLabel, setStateLabel] = useState("State");
  const [countryOpt, setCountryOpt] = useState([]);
  const [cityOpt, setCityOpt] = useState([]);

  const [optionSport, setOptionSport] = useState([[]]);
  const [enableAddOrganization, setEnableAddOrganization] = useState([
    { status: 0 },
    { status: 0 },
  ]);
  // const [optionCoach, setOptionCoach] = useState([]);
  // const [checkOrganizationState, setCheckOrganizationState] = useState("");

  GET(
    `api/v1/user_athlete_info?id=${userData.id}`,
    "athletic_user_athlete_info",
    (res) => {
      console.log("user_athlete_info res", res.data);
      if (res.data) {
        let data = res.data;

        modalForm.setFieldsValue({
          username: data.username,
          firstname: data.firstname,
          lastname: data.lastname,
          email: data.email,
          phone_number: data.phone_number,
          gender: data.gender ? data.gender : undefined,
          country:
            data.user_address && data.user_address.length > 0
              ? data.user_address[0].country
              : "",
          state:
            data.user_address && data.user_address.length > 0
              ? data.user_address[0].state
              : "",
        });

        if (data.user_address && data.user_address.length > 0) {
          if (data.user_address[0].country === "United States") {
            setStateLabel("State");
            setOptionState(stateUS);

            let city_list = stateUS.filter(
              (item) => item.value === data.user_address[0].state
            );
            setCityOpt(city_list[0]["json"]);
          } else if (data.user_address[0].country === "Canada") {
            setStateLabel("County");
            setOptionState(stateCA);
            let city_list = stateCA.filter(
              (item) => item.value === data.user_address[0].state
            );
            setCityOpt(city_list[0]["json"]);
          }
        }
      }
    }
  );

  GET("api/v1/organization?status=Approved", "organization_select", (res) => {
    console.log("res", res);
    if (res.data) {
      let dataOrganizationType = [];
      optionOrganizationType.map((item) => {
        dataOrganizationType.push({
          ...item,
          organization: res.data
            .filter((itemFilter) => itemFilter.organization_type === item.value)
            .map((item2) => {
              return {
                value: item2.id,
                label: item2.name,
              };
            }),
        });
        return "";
      });
      console.log("dataOrganizationType", dataOrganizationType);

      // setDataOptionOganizationType(dataOrganizationType);

      console.log("userData", userData);
      modalForm.setFieldsValue({
        // ...userData,
        organizations: [{ positions: [""] }],
      });
    }
  });

  // GET("api/v1/user_coach", "user_coach_select", (res) => {
  //   if (res.data) {
  //     let data = [];
  //     res.data.map((item) => {
  //       data.push({
  //         value: item.id,
  //         label: item.lastname,
  //       });
  //       return "";
  //     });
  //     setOptionCoach(data);
  //   }
  // });

  GET("api/v1/get_all_sport", "get_all_sport", (res) => {
    if (res.data) {
      console.log("get_all_sport", res);
      let arr = [];
      res.data.map((item) => {
        arr.push({
          value: item.sport,
          label: item.sport,
        });
        return "";
      });
      setOptionSport(arr);
    }
  });

  const [club, setClub] = useState([]);
  const [school, setSchool] = useState([]);
  const [university, setUniversity] = useState([]);
  const [selectOptionOrganization, setSelectOptionOrganization] = useState([]);

  GET("api/v1/athlete_organization", "athlete_organization", (res) => {
    if (res.success) {
      console.log("athlete_organization", res);
      setClub(res.club);
      setSchool(res.school);
      setUniversity(res.university);
    }
  });

  const handleOrganization = (val, opt, index) => {
    console.log("handleOrganization", val);
    if (val === "School") {
      setSelectOptionOrganization(school);
    } else if (val === "Club") {
      setSelectOptionOrganization(club);
    } else if (val === "University") {
      setSelectOptionOrganization(university);
    }

    let tempOrganization = modalForm.getFieldsValue().organizations;
    tempOrganization[index] = {
      ...tempOrganization[index],
      organization_id: undefined,
      coach_id: undefined,
    };

    console.log("tempOrganization", tempOrganization);
    modalForm.setFieldsValue({
      organizations: tempOrganization,
    });
  };

  const { mutate: mutateUserAthlete, isLoading: isLoadingUserAthlete } = POST(
    "api/v1/modal_welcome_update_user_athlete",
    "user_athlete"
  );

  const handleOnFinish = (values) => {
    // console.log("handleOnFinish", values);
    let data = {
      ...values,
      birthdate: values.date_of_birth.format("YYYY-MM-DD"),
      id: userData.id,
      from: "welcome_modal",
    };

    console.log("handleOnFinish", data);

    mutateUserAthlete(data, {
      onSuccess: (res) => {
        if (res.success) {
          localStorage.userdata = encryptor.encrypt({
            ...userData,
            one_time_modal: 1,
            firstname: values.firstname,
            lastname: values.lastname,
            gender: values.gender ? values.gender : undefined,
            birthdate: values.date_of_birth.format("YYYY-MM-DD"),
          });

          setOpenModal(false);

          notification.success({
            message: "Success",
            description: "Data Successfully Submited",
          });

          const timeoutId = setTimeout(() => {
            window.location.reload();
          }, 1500);
        } else {
          notification.success({
            message: "Error",
            description: "Data not updated!",
          });
        }
      },
    });
  };

  const handleCountry = (e) => {
    if (e === "United States") {
      setOptionState(stateUS);
      setStateLabel("State");
    } else if (e === "Canada") {
      setOptionState(stateCA);
      setStateLabel("County");
    } else {
      setOptionState(stateUS);
      setStateLabel("State");
    }

    modalForm.resetFields(["state", "city"]);
    setCityOpt([]);
  };

  // const [orgStateLab, setOrgStateLab] = useState("State");
  // const [orgStateOpt, setOrgStateOpt] = useState(stateUS);
  // const [orgCodeLab, setOrgCodeLab] = useState("Zip Code");
  // const [orgCodeOpt, setOrgCodeOpt] = useState(stateUS);
  const [orgArrayOption, setOrgArrayOption] = useState([
    {
      state_label: "State",
      state_options: stateUS,
      code_label: "Zip Code",
      code_pattern: /(^\d{5}$)|(^\d{5}[ -]\d{4}$)/,
      coach_option: [],
      city_option: [],
    },
  ]);

  // console.log("orgArrayOption", orgArrayOption);
  const handleOrgCountry = (val, opt, index) => {
    // console.log("handleOrgCountry", val);
    // let row = [...dataResource];
    // row[index] = {
    //   ...row[index],
    //   search_state: e,
    // };
    // setDataResource(row);

    if (val) {
      let row = [...orgArrayOption];
      if (val === "United States") {
        row[index] = {
          ...row[index],
          state_label: "State",
          state_options: stateUS,
          code_label: "Zip Code",
          code_pattern: /(^\d{5}$)|(^\d{5}[ -]\d{4}$)/,
          city_option: [],
        };
        setOrgArrayOption(row);
      } else if (val === "Canada") {
        row[index] = {
          ...row[index],
          state_label: "County",
          state_options: stateCA,
          code_label: "Postal Code",
          city_option: [],
          code_pattern: /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/,
        };
        setOrgArrayOption(row);
      }
    }

    let tempOrganization = modalForm.getFieldsValue().organizations;
    tempOrganization[index] = {
      ...tempOrganization[index],
      organization_state: undefined,
      organization_city: undefined,
      organization_zip: undefined,
    };

    console.log("tempOrganization", tempOrganization);
    modalForm.setFieldsValue({
      organizations: tempOrganization,
    });
    $(`.select-state-${index} .ant-form-item-feedback-icon`).hide();
    $(`.select-city-${index} .ant-form-item-feedback-icon`).hide();
    $(`.select-zip-${index} .ant-form-item-feedback-icon`).hide();

    // console.log("handleOrgCountry", val);
  };

  const handleOrganizationName = (val, opt, index) => {
    if (val) {
      let form = modalForm.getFieldsValue().organizations;

      let check_org = form.filter((item, key) => {
        if (item.organization_id === val && index !== key) {
          return item;
        }
      });

      if (check_org.length > 0) {
        notification.warning({
          message: "Warning",
          description: "You already selected this organization.",
        });
        let row = [...form];
        row[index] = {
          ...row[index],
          organization_id: null,
          organization_name: null,
        };

        modalForm.setFieldsValue({
          organizations: row,
        });

        $(
          `.input-org-name-${index} .ant-form-item-feedback-icon-success`
        ).hide();
        $(
          `.select-org-id-${index} .ant-form-item-feedback-icon-success`
        ).hide();
      } else {
        mutateCheckOrganization(
          { organization_id: val, user_id: userData.id },
          {
            onSuccess: (res) => {
              if (res.success) {
                notification.warning({
                  message: "Warning",
                  description: "You already selected this organization.",
                });
                let row = [...form];
                row[index] = {
                  ...row[index],
                  organization_id: undefined,
                  organization_name: undefined,
                };

                modalForm.setFieldsValue({
                  organizations: row,
                });
                console.log("athlete_organization", row);

                $(
                  `.input-org-name-${index} .ant-form-item-feedback-icon-success`
                ).hide();
                $(
                  `.select-org-id-${index} .ant-form-item-feedback-icon-success`
                ).hide();
              } else {
                let data = opt["data-json"];
                let row = [...orgArrayOption];

                let coachOpt = [];
                data.map((item) => {
                  coachOpt.push(item);
                });

                if (data.length > 0) {
                  row[index] = {
                    ...row[index],
                    coach_option: data,
                  };
                  console.log("row", row);
                  setOrgArrayOption(row);
                } else {
                  row[index] = {
                    ...row[index],
                    coach_option: [],
                  };
                  setOrgArrayOption(row);
                }
              }
            },
          }
        );
      }
    }
  };

  const { mutate: mutateCheckOrganization } = POST(
    "api/v1/check_organization_athlete",
    "check_organization_athlete"
  );

  return (
    <Modal
      // visible={true}
      visible={openModal}
      width={1000}
      title={
        <Alert
          style={{ marginTop: 10 }}
          message={`Welcome ${firstname}`}
          description="To continue you must complete all the required fields below"
          type="success"
          closable={false}
        />
      }
      className="modal-dashboard-welcome"
      closable={false}
      style={{ top: 40 }}
      footer={
        <Row
          gutter={24}
          style={{
            marginLeft: "-5px",
            marginRight: "-5px",
          }}
        >
          <Col xs={24} sm={24} md={10}>
            <Button
              type="primary"
              htmlType="submit"
              loading={isLoadingUserAthlete}
              className="btn-main-outline-2"
              size="large"
              // style={{ width: "33%" }}
              block
              onClick={() =>
                modalForm
                  .validateFields()
                  .then((values) => {
                    handleOnFinish(values);
                  })
                  .catch((info) => {
                    console.log("Validate Failed:", info);
                  })
              }
            >
              SUBMIT
            </Button>
          </Col>
        </Row>
      }
      getContainer={false}
    >
      <Form form={modalForm} onFinish={handleOnFinish} autoComplete="off">
        <Row gutter={12}>
          <Col xs={24} sm={24} md={24} lg={24} xl={16}>
            <Card
              title="ATHLETE'S INFORMATION"
              className="card-dark-head"
              extra={<FontAwesomeIcon icon={faInfo} />}
            >
              <Row gutter={12}>
                <Col xs={24} sm={24} md={12}>
                  <Form.Item
                    name="firstname"
                    rules={[
                      {
                        required: true,
                        message: "This field firstname is required.",
                      },
                    ]}
                  >
                    <FloatInput
                      label="First Name"
                      placeholder="First Name"
                      required
                    />
                  </Form.Item>
                </Col>

                <Col xs={24} sm={24} md={12}>
                  <Form.Item
                    name="lastname"
                    rules={[
                      {
                        required: true,
                        message: "This field lastname is required.",
                      },
                    ]}
                  >
                    <FloatInput
                      label="Last Name"
                      placeholder="Last Name"
                      required
                    />
                  </Form.Item>
                </Col>

                <Col xs={24} sm={24} md={12}>
                  <Form.Item
                    name="country"
                    rules={[
                      {
                        required: true,
                        message: "This field country is required.",
                      },
                    ]}
                    className="form-select-error"
                  >
                    <FloatSelect
                      label="Country"
                      placeholder="Country"
                      options={optionCountryCodes}
                      dropdownClassName="select-dropdown-in-modal"
                      onChange={handleCountry}
                      required
                    />
                  </Form.Item>
                </Col>

                <Col xs={24} sm={24} md={12}>
                  <Form.Item
                    name="state"
                    rules={[
                      {
                        required: true,
                        message: "This field state is required.",
                      },
                    ]}
                    className="form-select-error"
                  >
                    <FloatSelect
                      label={stateLabel}
                      placeholder={stateLabel}
                      options={optionState}
                      dropdownClassName="select-dropdown-in-modal"
                      onChange={(val, opt) => {
                        if (val) {
                          console.log(opt);
                          setCityOpt(opt["data-json"]);
                        }
                      }}
                      required
                    />
                  </Form.Item>
                </Col>

                <Col xs={24} sm={24} md={12}>
                  <Form.Item
                    name="city"
                    rules={[
                      {
                        required: true,
                        message: "This field state is required.",
                      },
                    ]}
                    className="form-select-error"
                  >
                    <FloatSelect
                      label={"City"}
                      placeholder={"City"}
                      options={cityOpt}
                      dropdownClassName="select-dropdown-in-modal"
                      required
                    />
                  </Form.Item>
                </Col>

                <Col xs={24} sm={24} md={12}>
                  <Form.Item
                    name="gender"
                    rules={[
                      {
                        required: true,
                        message: "This field field is required.",
                      },
                    ]}
                    className="form-select-error"
                  >
                    <FloatSelect
                      label="Birth Gender"
                      placeholder="Birth Gender"
                      options={[
                        // { label: "Both", value: "Both" },
                        { label: "Male", value: "Male" },
                        { label: "Female", value: "Female" },
                      ]}
                      dropdownClassName="select-dropdown-in-modal"
                      required
                    />
                  </Form.Item>
                </Col>

                <Col xs={24} sm={24} md={12}>
                  <Form.Item
                    name="date_of_birth"
                    rules={[
                      {
                        required: true,
                        message: "This field field is required.",
                      },
                    ]}
                  >
                    <FloatDatePickerForm
                      label="DOB: MM/DD/YYYY"
                      placeholder="DOB: MM/DD/YYYY"
                      dropdownClassName="date-picker-dropdown-in-modal"
                      format="MM/DD/YYYY"
                      required
                    />
                  </Form.Item>
                </Col>

                <Col xs={24} sm={24} md={12}>
                  <Form.Item
                    name="height_in_inches"
                    rules={[
                      {
                        required: true,
                        message: "This field field is required.",
                      },
                    ]}
                  >
                    <FloatInput
                      label="Height In Inches"
                      placeholder="Height In Inches"
                      required
                    />
                  </Form.Item>
                </Col>

                <Col xs={24} sm={24} md={12}>
                  <Form.Item
                    name="weight_in_pounds"
                    rules={[
                      {
                        required: true,
                        message: "This field field is required.",
                      },
                    ]}
                  >
                    <FloatInput
                      label="Weight In Pounds"
                      placeholder="Weight In Pounds"
                      required
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Card>

            <Form.List name="organizations">
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name, ...restField }, index) => (
                    <Card
                      title="SCHOOL / CLUB / UNIVERSITY"
                      className="card-dark-head m-t-md"
                      extra={
                        fields.length > 1 ? (
                          <FontAwesomeIcon
                            icon={faTrashAlt}
                            onClick={() => {
                              remove(name);
                              // let tempOptionOrganization = [
                              //   ...optionOrganization,
                              // ];
                              // tempOptionOrganization.splice(index, 1);
                              // setOptionOrganization(tempOptionOrganization);
                              // let tempEnableAddOrganization = [
                              //   ...enableAddOrganization,
                              // ];
                              // tempEnableAddOrganization.splice(index, 1);
                              // setEnableAddOrganization(
                              //   tempEnableAddOrganization
                              // );
                            }}
                            className="cursor companyColor3"
                          />
                        ) : null
                      }
                      key={key}
                    >
                      <Row gutter={12}>
                        <Col xs={24} sm={24} md={24}>
                          <Form.Item
                            {...restField}
                            name={[name, "organization_type"]}
                            className="form-select-error"
                            rules={[
                              {
                                required: true,
                                message: "This field field is required.",
                              },
                            ]}
                          >
                            <FloatSelect
                              label="Organization Type"
                              placeholder="Organization Type"
                              options={optionOrganizationType}
                              onChange={(val, opt) =>
                                handleOrganization(val, opt, name)
                              }
                              required
                            />
                          </Form.Item>
                        </Col>

                        {enableAddOrganization[name].status === 0 ? (
                          <>
                            <Col xs={24} sm={24} md={24}>
                              <Form.Item
                                {...restField}
                                name={[name, "organization_id"]}
                                className={`form-select-error m-b-xs select-org-id-${index}`}
                                rules={[
                                  {
                                    required: true,
                                    message: "This field field is required.",
                                  },
                                ]}
                                required
                              >
                                <FloatSelect
                                  label="Organization Name"
                                  placeholder="Organization Name"
                                  options={selectOptionOrganization}
                                  onChange={(val, opt) => {
                                    handleOrganizationName(val, opt, name);
                                    $(
                                      `.select-org-id-${index} .ant-form-item-feedback-icon-success`
                                    ).show();
                                  }}
                                />
                              </Form.Item>
                            </Col>

                            <Col xs={24} sm={24} md={24} className="m-b-md">
                              <Typography.Text>
                                If organization not listed,{" "}
                                <Typography.Link
                                  className="companyColor3"
                                  onClick={() => {
                                    let tempEnableAddOrganization = [
                                      ...enableAddOrganization,
                                    ];
                                    tempEnableAddOrganization[index] = {
                                      ...tempEnableAddOrganization[index],
                                      status: 1,
                                      // coach_option: [],
                                    };
                                    setEnableAddOrganization(
                                      tempEnableAddOrganization
                                    );

                                    let row = [...orgArrayOption];
                                    row[index] = {
                                      ...row[index],
                                      coach_option: [],
                                    };
                                    setOrgArrayOption(row);
                                  }}
                                >
                                  click here.
                                </Typography.Link>
                              </Typography.Text>
                            </Col>
                          </>
                        ) : (
                          <>
                            <Col xs={24} sm={24} md={24}>
                              <Form.Item
                                {...restField}
                                name={[name, "organization_name"]}
                                rules={[
                                  {
                                    required: true,
                                    message:
                                      "This field organization name is required.",
                                  },
                                ]}
                                className={`m-b-xs select-org-name-${index}`}
                              >
                                <FloatInput
                                  label="Organization Name"
                                  placeholder="Organization Name"
                                  onChange={(e) => {
                                    $(
                                      `.select-org-name-${index} .ant-form-item-feedback-icon-success`
                                    ).show();
                                  }}
                                  required
                                />
                              </Form.Item>
                            </Col>

                            <Col xs={24} sm={24} md={24} className="m-b-md ">
                              <Typography.Text>
                                If organization listed,{" "}
                                <Typography.Link
                                  className="companyColor3"
                                  onClick={() => {
                                    let tempEnableAddOrganization = [
                                      ...enableAddOrganization,
                                    ];
                                    tempEnableAddOrganization[index] = {
                                      ...tempEnableAddOrganization[index],
                                      status: 0,
                                    };
                                    setEnableAddOrganization(
                                      tempEnableAddOrganization
                                    );
                                    let tempOrganization =
                                      modalForm.getFieldsValue().organizations;
                                    tempOrganization[index] = {
                                      ...tempOrganization[index],
                                      organization_id: "",
                                      organization_address1: "",
                                      organization_address2: "",
                                      organization_city: "",
                                      organization_country: "",
                                      organization_name: "",
                                      organization_state: "",
                                      organization_zip: "",
                                    };
                                    modalForm.setFieldsValue({
                                      organizations: tempOrganization,
                                    });
                                  }}
                                >
                                  click here.
                                </Typography.Link>
                              </Typography.Text>
                            </Col>

                            <Col xs={24} sm={24} md={24}>
                              <Form.Item
                                {...restField}
                                name={[name, "organization_country"]}
                                className="form-select-error"
                                rules={[
                                  {
                                    required: true,
                                    message: "This field field is required.",
                                  },
                                ]}
                              >
                                <FloatSelect
                                  label="Country"
                                  placeholder="Country"
                                  options={optionCountryCodes}
                                  onChange={(val, opt) =>
                                    handleOrgCountry(val, opt, name)
                                  }
                                  required
                                  // value={optionOrganizationState[index]}sa
                                />
                              </Form.Item>
                            </Col>

                            <Col xs={24} sm={24} md={24}>
                              <Form.Item
                                {...restField}
                                name={[name, "organization_state"]}
                                className={
                                  "m-b-sm form-select-error select-state-" +
                                  name
                                }
                                rules={[
                                  {
                                    required: true,
                                    message: "This field field is required.",
                                  },
                                ]}
                              >
                                <FloatSelect
                                  label={
                                    orgArrayOption[name] &&
                                    orgArrayOption[name].state_label
                                  }
                                  placeholder={
                                    orgArrayOption[name] &&
                                    orgArrayOption[name].state_label
                                  }
                                  options={
                                    orgArrayOption[name] &&
                                    orgArrayOption[name].state_options
                                  }
                                  dropdownClassName="select-dropdown-in-modal"
                                  onChange={(val, opt) => {
                                    if (val) {
                                      $(
                                        `.select-state-${index} .ant-form-item-feedback-icon`
                                      ).show();

                                      let row = [...orgArrayOption];
                                      row[index] = {
                                        ...row[index],
                                        city_option: opt["data-json"],
                                      };

                                      setOrgArrayOption(row);
                                    }
                                  }}
                                  required
                                />
                              </Form.Item>
                            </Col>

                            <Col xs={24} sm={24} md={24} className="m-t-md">
                              <Form.Item
                                {...restField}
                                name={[name, "organization_address1"]}
                                className="m-b-xs"
                                rules={[
                                  {
                                    required: true,
                                    message: "This field field is required.",
                                  },
                                ]}
                              >
                                <FloatInput
                                  label="Address 1"
                                  placeholder="Address 1"
                                  required
                                />
                              </Form.Item>
                            </Col>

                            <Col xs={24} sm={24} md={24} className="m-t-md">
                              <Form.Item
                                {...restField}
                                name={[name, "organization_address2"]}
                                className="m-b-xs"
                              >
                                <FloatInput
                                  label="Address 2"
                                  placeholder="Address 2"
                                />
                              </Form.Item>
                            </Col>

                            <Col xs={24} sm={24} md={24} className="m-t-md">
                              <Form.Item
                                {...restField}
                                name={[name, "organization_city"]}
                                className={
                                  "m-b-xs form-select-error select-city-" + name
                                }
                                rules={[
                                  {
                                    required: true,
                                    message: "This field city is required.",
                                  },
                                ]}
                              >
                                <FloatSelect
                                  label="City"
                                  placeholder="City"
                                  options={
                                    orgArrayOption[name] &&
                                    orgArrayOption[name].city_option
                                  }
                                  onChange={(val) => {
                                    if (val) {
                                      $(
                                        `.select-city-${index} .ant-form-item-feedback-icon`
                                      ).show();
                                    } else {
                                      $(
                                        `.select-city-${index} .ant-form-item-feedback-icon`
                                      ).hide();
                                    }
                                  }}
                                  required
                                />
                              </Form.Item>
                            </Col>

                            <Col xs={24} sm={24} md={24} className="m-t-md">
                              <Form.Item
                                {...restField}
                                name={[name, "organization_zip"]}
                                rules={[
                                  {
                                    required: true,
                                    message: "This field zip is required.",
                                  },
                                  {
                                    pattern: orgArrayOption[index].code_pattern,
                                    message:
                                      "Invalid " +
                                      orgArrayOption[index].code_label,
                                  },
                                ]}
                                className={"select-zip-" + name}
                              >
                                <FloatInput
                                  label={orgArrayOption[index].code_label}
                                  placeholder={orgArrayOption[index].code_label}
                                  onChange={(val) => {
                                    if (val) {
                                      $(
                                        `.select-zip-${index} .ant-form-item-feedback-icon`
                                      ).show();
                                    } else {
                                      $(
                                        `.select-zip-${index} .ant-form-item-feedback-icon`
                                      ).hide();
                                    }
                                  }}
                                  required
                                />
                              </Form.Item>
                            </Col>
                          </>
                        )}

                        <Col xs={24} sm={24} md={24}>
                          <Form.Item
                            {...restField}
                            name={[name, "coach_id"]}
                            className="form-select-error"
                          >
                            <FloatSelectCoach
                              label="Coach's Name (Optional)"
                              placeholder="Coach's Name (Optional)"
                              options={
                                orgArrayOption[name] &&
                                orgArrayOption[name].coach_option
                              }
                              allowClear={true}
                            />
                          </Form.Item>
                        </Col>

                        <Col xs={24} sm={24} md={24}>
                          <Form.Item
                            {...restField}
                            name={[name, "sport"]}
                            className="form-select-error"
                            rules={[
                              {
                                required: true,
                                message: "This field field is required.",
                              },
                            ]}
                          >
                            <FloatSelect
                              label="Sport"
                              placeholder="Sport"
                              options={optionSport}
                              required
                            />
                          </Form.Item>
                        </Col>

                        <Col
                          xs={24}
                          sm={24}
                          md={24}
                          className="modal-welcome-form-list"
                        >
                          <Form.List
                            {...restField}
                            name={[name, "positions"]}
                            rules={[
                              {
                                validator: async (_, names2) => {
                                  if (!names2 || names2.length < 1) {
                                    return Promise.reject(
                                      new Error("At least 1 position")
                                    );
                                  }
                                },
                              },
                            ]}
                          >
                            {(fields2, { add, remove }, { errors2 }) => (
                              <>
                                {fields2.map((field2, index2) => (
                                  <Form.Item
                                    required={false}
                                    key={field2.key}
                                    className={
                                      fields2.length !== index2 + 1
                                        ? "m-b-md"
                                        : ""
                                    }
                                  >
                                    <Form.Item
                                      {...field2}
                                      validateTrigger={["onChange", "onBlur"]}
                                      rules={[
                                        {
                                          required: true,
                                          whitespace: true,
                                          message: "Please input position",
                                        },
                                      ]}
                                      noStyle
                                    >
                                      <FloatInput
                                        label="Position"
                                        placeholder="Position"
                                        className={
                                          index2 !== 0 ? "w-95" : "w-100"
                                        }
                                        required
                                      />
                                    </Form.Item>
                                    {fields2.length > 1 ? (
                                      <MinusCircleOutlined
                                        className="dynamic-delete-button"
                                        onClick={() => remove(field2.name)}
                                      />
                                    ) : null}
                                  </Form.Item>
                                ))}
                                <Form.Item>
                                  <Form.ErrorList errors={errors2} />
                                  <Button
                                    type="link"
                                    onClick={() => add()}
                                    icon={<PlusOutlined />}
                                    className="companyColor3 p-n"
                                  >
                                    Additional Position
                                  </Button>
                                </Form.Item>
                              </>
                            )}
                          </Form.List>
                        </Col>

                        <Col xs={24} sm={24} md={24} className="m-t-md">
                          <Form.Item
                            {...restField}
                            name={[name, "stats"]}
                            rules={[
                              {
                                required: true,
                                message: "This field field is required.",
                              },
                            ]}
                          >
                            <FloatTextArea
                              label="Your Stats"
                              placeholder="Your Stats"
                              required
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Card>
                  ))}

                  <Form.Item>
                    <Button
                      type="link"
                      onClick={() => {
                        let tempEnableAddOrganization = [
                          ...enableAddOrganization,
                          { status: 0 },
                        ];

                        console.log(
                          "tempEnableAddOrganization",
                          tempEnableAddOrganization
                        );
                        setEnableAddOrganization(tempEnableAddOrganization);

                        let arrOrg = [
                          ...orgArrayOption,
                          {
                            state_label: "State",
                            state_options: stateUS,
                            code_label: "Zip Code",
                            code_pattern: /(^\d{5}$)|(^\d{5}[ -]\d{4}$)/,
                            coach_option: [],
                            city_option: [],
                          },
                        ];

                        console.log("arrOrg", arrOrg);
                        setOrgArrayOption(arrOrg);

                        let tempOrganization =
                          modalForm.getFieldsValue().organizations;
                        tempOrganization.push({
                          positions: [""],
                        });

                        console.log("tempOrganization", tempOrganization);
                        modalForm.setFieldsValue({
                          organizations: tempOrganization,
                        });

                        // add();
                      }}
                      icon={<PlusOutlined />}
                      className="companyColor3 p-n"
                    >
                      Add Another Organization
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={8}>
            <Card
              title="SOCIAL MEDIA"
              className="card-dark-head"
              extra={<FontAwesomeIcon icon={faInfo} />}
            >
              <Row gutter={12}>
                <Col xs={24} sm={24} md={24}>
                  <Form.Item name="instagram" className="m-b-sm">
                    <FloatInput
                      label="Instagram Account (Optional)"
                      placeholder="Instagram Account (Optional)"
                    />
                  </Form.Item>
                </Col>

                <Col xs={24} sm={24} md={24}>
                  <Form.Item name="youtube" className="m-b-sm">
                    <FloatInput
                      label="Youtube / Vimeo (Optional)"
                      placeholder="Youtube / Vimeo (Optional)"
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}
