import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import companyInfo from "../providers/companyInfo";

import "antd/dist/antd.css";

// from sass
import "../assets/css/helper/helper.css";
import "../assets/css/main/main.css";
import "../assets/css/sidemenu/sidemenu.css";
import "../assets/css/header/header.css";
import "../assets/css/input/input.css";
import "../assets/css/accordion/accordion.css";
import "../assets/css/card/card.css";
import "../assets/css/button/button.css";
import "../assets/css/navigation/navigation.css";
import "../assets/css/tooltip/tooltip.css";
import "../assets/css/typography/typography.css";
import "../assets/css/dashboard/dashboard.css";
import "../assets/css/spinner/spinner.css";
import "../assets/css/pagination/pagination.css";
import "../assets/css/tabs/tabs.css";
import "../assets/css/collapse/collapse.css";
import "../assets/css/modal/modal.css";
import "../assets/css/table/table.css";
import "../assets/css/checkbox/checkbox.css";
import "../assets/css/datepicker/datepicker.css";
import "../assets/css/steps/steps.css";
import "../assets/css/radio/radio.css";
import "../assets/css/quill/quill.css";
import "../assets/css/message/message.css";

import "../assets/css/maintenance/maintenance.css";
import "../assets/css/login/login.css";
import "../assets/css/create-password/create-password.css";
import "../assets/css/register-layout/register-layout.css";
import "../assets/css/layout-athlete-preview/layout-athlete-preview.css";
import "../assets/css/public-layout/public-layout.css";
import "../assets/css/private-layout/private-layout.css";

import "../assets/css/responsive-1440/responsive-1440.css";
import "../assets/css/responsive-1200/responsive-1200.css";
import "../assets/css/responsive-1199.98/responsive-1199.98.css";
import "../assets/css/responsive-992/responsive-992.css";
import "../assets/css/responsive-992-1199.98/responsive-992-1199.98.css";
import "../assets/css/responsive-991.98/responsive-991.98.css";
import "../assets/css/responsive-768-991.98/responsive-768-991.98.css";
import "../assets/css/responsive-768/responsive-768.css";
import "../assets/css/responisve-767.98/responisve-767.98.css";
import "../assets/css/responsive-576/responsive-576.css";
import "../assets/css/responisve-575.98/responisve-575.98.css";
import "../assets/css/responsive-480/responsive-480.css";
import "../assets/css/responsive-375/responsive-375.css";
import "../assets/css/responsive-320/responsive-320.css";

/** template */
import PublicRoute from "./PublicRoute";
// import PrivateRoute from "./PrivateRoute";
/** end template */

/** errors */

import Error404 from "../views/errors/Error404";
import Error500 from "../views/errors/Error500";
import PageMaintenance from "../views/errors/PageMaintenance";

/** end errors */

/** public views */

import PageLogin from "../views/public/PageLogin/PageLogin";
import PageCreatePassword from "../views/public/PageCreatePassword/PageCreatePassword";
import PageRegister from "../views/public/PageRegister/PageRegister";
import PageRegistrationSetPassword from "../views/public/PageRegister/PageRegistrationSetPassword";
import PageForgotPassword from "../views/public/ForgotPassword/PageForgotPassword";
import PageEvent from "../views/public/PageEvent/PageEvent";
/** end public views */

/** private views */

import RouteAdmin from "./RouteRole/RouteAdmin";
import RouteManager from "./RouteRole/RouteManager";
import RouteAthlete from "./RouteRole/RouteAthlete";
import RouteCoach from "./RouteRole/RouteCoach";
import RouteAssessor from "./RouteRole/RouteAssessor";
import Page2fa from "../views/private/Page2fa/Page2fa";
import PrivateRoute from "./PrivateRoute";

// import PageProfile from "../views/private/PageProfile/PageProfile";

import PageTicketing from "../views/private/PageTicketing/PageTicketing";
import PageTiketingCreate from "../views/private/PageTicketing/PageTicketingCreate";
import PageTicketingReply from "../views/private/PageTicketing/PageTicketingReply";

import PagePolicy from "../views/private/PagePolicy/PagePolicy";
import PageTermAndCondition from "../views/private/PageTermAndCondition/PageTermAndCondition";
import PageCookies from "../views/private/PageCookies/PageCookies";
import PageFaqs from "../views/private/PageFaqs/PageFaqs";

import PageSupportTicketing from "../views/private/PageTicketing/PageTicketing";
import PageSupportTicketingCreate from "../views/private/PageTicketing/PageTicketingCreate";
import PageSupportTicketingReply from "../views/private/PageTicketing/PageTicketingReply";

import PageMessages from "../views/private/PageMessages/PageMessages";
import PageAthleticProfileViewWebpage from "../views/private/PageAthlete/PageAthleticProfile/PageAthleticProfileViewWebpage";
import PageCalendar from "../views/public/PageCalendar/PageCalendar";
import { useEffect } from "react";

import PageLoginMaintenance from "../views/public/PageLogin/PageLoginMaintenance";

import { ClearCacheProvider, useClearCache } from "react-clear-cache";
import { Button } from "antd";
import { GiftOutlined } from "@ant-design/icons";

/** end private views */
const token = localStorage.token;
const userData = companyInfo().userData;
const queryClient = new QueryClient();

const Routes = () => {
	useEffect(() => {
		navigator.serviceWorker.ready.then((registration) => {
			registration.update();
		});

		return () => {};
	}, []);

	// useEffect(() => {
	//   if (!userData) {
	//     window.location.href = "/*";
	//   }
	// }, [userData]);

	return (
		<QueryClientProvider client={queryClient}>
			<Router>
				<Switch>
					{/* public route */}
					<PublicRoute exact path="/" component={PageLogin} title="Login" />
					<PublicRoute
						exact
						path="/create-password"
						component={PageCreatePassword}
						title="Create Password"
					/>
					<PublicRoute
						exact
						path="/register"
						component={PageRegister}
						title="Register"
					/>
					<PublicRoute
						exact
						path="/forgot-password/:token/:id"
						component={PageForgotPassword}
						title="Forgot Password"
					/>
					<PublicRoute
						exact
						path="/register/setup-password/:token"
						// path="/register/setup-password"
						component={PageRegistrationSetPassword}
						title="Register - Setup Password"
					/>
					<Route
						exact
						path="/maintenance"
						component={PageMaintenance}
						title="Maintenance"
					/>{" "}
					<PublicRoute
						exact
						path="/maintenance-login"
						component={PageLoginMaintenance}
					/>
					{/* */}
					{/* end public route */}
					{/* private route */}
					<PrivateRoute
						exact
						path="/policy"
						component={PagePolicy}
						title="Policy"
						permission="Policy"
						breadcrumb={[
							{
								name: "Policy",
								link: "/policy",
							},
						]}
					/>
					<PrivateRoute
						exact
						path="/terms-and-condition"
						component={PageTermAndCondition}
						title="Terms & Condition"
						permission="Terms & Condition"
						breadcrumb={[
							{
								name: "Terms & Condition",
								link: "/terms-and-condition",
							},
						]}
					/>
					<PrivateRoute
						exact
						path="/cookies"
						component={PageCookies}
						title="Cookies"
						permission="Cookies"
						breadcrumb={[
							{
								name: "Cookies",
								link: "/cookies",
							},
						]}
					/>
					<PrivateRoute
						exact
						path="/support/faqs"
						component={PageFaqs}
						title="FAQ"
						permission="Questions"
						subtitle="FREQUENTLY ASKED"
						breadcrumb={[
							{
								name: "Support",
								link: "#",
							},
							{
								name: "FAQ's",
								link: "#",
							},
						]}
					/>
					<PrivateRoute
						exact
						path="/support/ticketing"
						component={PageSupportTicketing}
						title="Ticketing"
						permission="Support - Ticketing"
						breadcrumb={[
							{
								name: "Support",
								link: "",
							},
							{
								name: "Ticketing",
								link: "/support/ticketing",
							},
						]}
					/>
					<PrivateRoute
						exact
						path="/support/ticketing/create"
						component={PageSupportTicketingCreate}
						title="Ticketing"
						permission="Support - Ticketing"
						breadcrumb={[
							{
								name: "Support",
								link: "",
							},
							{
								name: "Ticketing",
								link: "/support/ticketing/create",
							},
						]}
					/>
					<PrivateRoute
						exact
						path="/support/ticketing/reply/:id"
						component={PageSupportTicketingReply}
						title="Ticketing"
						permission="Support - Ticketing"
						breadcrumb={[
							{
								name: "Support",
								link: "",
							},
							{
								name: "Ticketing",
								link: "/support/ticketing/create",
							},
						]}
					/>
					<PrivateRoute
						exact
						path="/ticketing"
						component={PageTicketing}
						title="Ticketing"
						permission="Ticketing"
						breadcrumb={[
							{
								name: "Ticketing",
								link: "#",
							},
						]}
					/>
					<PrivateRoute
						exact
						path="/ticketing/create"
						component={PageTiketingCreate}
						title="Create Ticket"
						permission="Ticket"
						breadcrumb={[
							{
								name: "Ticketing",
								link: "/ticketing",
							},
							{
								name: "Create Ticket",
								link: "#",
							},
						]}
					/>
					<PrivateRoute
						exact
						path="/ticketing/reply/:id"
						component={PageTicketingReply}
						title="Reply Ticket "
						permission="Ticketing"
						breadcrumb={[
							{
								name: "Ticketing",
								link: "/ticketing",
							},
							{
								name: "Reply Ticket ",
								link: "#",
							},
						]}
					/>
					<PrivateRoute
						exact
						path="/messages"
						component={PageMessages}
						title="Messages"
						permission="Messages"
						breadcrumb={[
							{
								name: "Messages",
								link: "/messages",
							},
						]}
					/>
					<Route exact path="/event/:id" component={PageEvent} title="Event" />
					<Route
						exact
						path="/public-calendar"
						component={PageCalendar}
						title="Event"
					/>
					{token && (
						<Route
							exact
							path="/athletic/:name"
							component={PageAthleticProfileViewWebpage}
							title="Athletic Profile"
						/>
					)}
					{/* {token && (
						<Route
							exact
							path="/user/preview/:id"
							title="Edit User"
							permission="Edit User"
							component={PageAthleticProfileViewWebpage}
							breadcrumb={[
								{
									name: "Users",
									link: "",
								},
								{
									name: "Edit User",
									link: "/user/edit",
								},
							]}
						/>
					)} */}
					<PrivateRoute
						exact
						path="/profile/2fa"
						component={Page2fa}
						title="Authentication"
						permission="Authentication"
						breadcrumb={[
							{
								name: "Profile",
								link: "/profile",
							},
							{
								name: "Authentication",
								link: "/profile/2fa",
							},
						]}
					/>
					<Route exact path="/500" component={Error500} />
					{userData && userData.role === "Admin" && <RouteAdmin />}
					{userData && userData.role === "Manager" && <RouteManager />}
					{userData &&
						(userData.role === "Athlete" || userData.role === "Athlete Guardian") && (
							<RouteAthlete />
						)}
					{userData && userData.role === "Coach" && <RouteCoach />}
					{userData && userData.role === "Assessor" && <RouteAssessor />}
					{/* end private route */}
					{/* this should always in the bottom */}
					<Route exact path="/*" component={Error404} />
				</Switch>
			</Router>
		</QueryClientProvider>
	);
};

export default Routes;
