import React, { useRef } from "react";
import { Button, Card, Col, Row } from "antd";
// import moment from "moment";
import ComponentHeader from "../../Components/ComponentHeader";
// import dataSourceTemp from "../../../../providers/dataSourceTemp";
// import { TablePagination } from "../../Components/ComponentTableFilter";
import {
  faHome,
  faCalendarAlt,
  faUser,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TableGroup from "./Tables/TableGroup";
import TablePrivate from "./Tables/TablePrivate";

export default function PageDashboard(props) {
  const { permission } = props;
  // const [tableFilter, setTableFilter] = useState({
  //   page: 1,
  //   page_size: 10,
  //   search: "",
  //   sort_field: "",
  //   sort_order: "",
  //   total: 10000,
  // });

  const myRefGroup = useRef(null);
  const executeScrollGroup = () => {
    myRefGroup.current.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  };

  const myRefPrivate = useRef(null);
  const executeScrollPrivate = () => {
    myRefPrivate.current.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  };

  return (
    <>
      <ComponentHeader
        title={permission}
        sub_title="ALL FEATURES"
        icon={faHome}
      />

      <Card className="w-100 card-min-height" id="PageDashboard">
        <Row gutter={12}>
          <Col xs={24} sm={24} md={24} lg={24}>
            <Button
              type="link"
              className="btn-link-main-1 btn-p-0 btn-assessor-hover"
              onClick={executeScrollGroup}
            >
              <FontAwesomeIcon className="color-3" icon={faCalendarAlt} />{" "}
              &nbsp;Events
            </Button>
            <Button
              type="link"
              className="btn-link-main-1 btn-p-0 btn-assessor-hover"
              onClick={executeScrollPrivate}
            >
              <FontAwesomeIcon className="color-3" icon={faUser} />{" "}
              &nbsp;Private Assessments
            </Button>
            <div className="w-100" ref={myRefGroup}>
              <TableGroup />
            </div>
            <br />
            <div ref={myRefPrivate}>
              <TablePrivate />
            </div>
          </Col>
        </Row>
        <br />
      </Card>
    </>
  );
}
