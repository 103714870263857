import React, { useEffect } from "react";
import { Result, Button } from "antd";
import $ from "jquery";

const Error404 = () => {
  useEffect(() => {
    let userdata = localStorage.getItem("userdata");
    let token = localStorage.getItem("token");
    if (!userdata || !token) {
      localStorage.removeItem("userdata");
      localStorage.removeItem("token");
      localStorage.removeItem("userdata_admin");
      localStorage.removeItem("viewas");
      window.location.href = "/?redirect=" + window.location.href;
    }

    // $("body").removeAttr("style");
    return () => {};
  }, []);

  return (
    <Result
      style={{ color: "#FFF" }}
      status="404"
      title="404"
      subTitle="Sorry, something went wrong."
      extra={
        <Button
          type="primary"
          onClick={(e) => {
            window.location.href = "/?redirect=" + window.location.href;
          }}
          className="btn-primary-default invert"
        >
          Back Home
        </Button>
      }
    />
  );
};

export default Error404;
