import React, { useEffect, useState } from "react";
import {
  Col,
  Divider,
  Form,
  Modal,
  Row,
  Space,
  Typography,
  Collapse,
  notification,
} from "antd";
import FloatInput from "../../../../../providers/FloatInput";
import { POST } from "../../../../../providers/useAxiosQuery";
import companyInfo from "../../../../../providers/companyInfo";

export default function ModalStarterProfessionalAssessment(props) {
  const userdata = companyInfo().userData;
  const { toggleModalPreview, setToggleModalPreview, keyName } = props;
  const [modalForm] = Form.useForm();
  const { Panel } = Collapse;

  const [updateValue, setUpdateValue] = useState("");

  useEffect(() => {
    // console.log("toggleModalPreview", toggleModalPreview.data);
    if (toggleModalPreview.show === false) {
      modalForm.resetFields();
      setToggleModalPreview({ data: null });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toggleModalPreview.show]);

  const onFinishForm = (values) => {
    let status_val;
    if (
      values.cmj_jump_height !== "" &&
      values.cmj_rsi_no !== "" &&
      values.ground_contact_time !== "" &&
      values.cmj_weight !== "" &&
      values.es_height !== "" &&
      values.es_peak_power !== "" &&
      values.es_peak_propulsive_force !== "" &&
      values.ms_peak_force !== "" &&
      values.ms_rfd !== "" &&
      values.ms_force !== "" &&
      values.sj_height !== "" &&
      values.sj_peak_power !== "" &&
      // values.sj_peak_propulsive_force !== "" &&
      values.sprint_time_four_meter !== "" &&
      values.sprint_time_tem_meter !== "" &&
      values.cmj_jump_height !== null &&
      values.cmj_rsi_no !== null &&
      values.ground_contact_time !== null &&
      values.cmj_weight !== null &&
      values.es_height !== null &&
      values.es_peak_power !== null &&
      values.es_peak_propulsive_force !== null &&
      values.ms_peak_force !== null &&
      values.ms_rfd !== null &&
      // values.ms_force !== null &&
      values.sj_height !== null &&
      values.sj_peak_power !== null &&
      // values.sj_peak_propulsive_force !== null &&
      values.sprint_time_four_meter !== null &&
      values.sprint_time_tem_meter !== null &&
      values.cmj_jump_height !== undefined &&
      values.cmj_rsi_no !== undefined &&
      values.ground_contact_time !== undefined &&
      values.cmj_weight !== undefined &&
      values.es_height !== undefined &&
      values.es_peak_power !== undefined &&
      values.es_peak_propulsive_force !== undefined &&
      values.ms_peak_force !== undefined &&
      values.ms_rfd !== undefined &&
      // values.ms_force !== undefined &&
      values.sj_height !== undefined &&
      values.sj_peak_power !== undefined &&
      // values.sj_peak_propulsive_force !== undefined &&
      values.sprint_time_four_meter !== undefined &&
      values.sprint_time_tem_meter !== undefined
    ) {
      status_val = "Complete";
    } else {
      status_val = "Incomplete";
    }
    let data = {
      cmj_jump_height: values.cmj_jump_height ? values.cmj_jump_height : "",
      cmj_rsi_no: values.cmj_rsi_no ? values.cmj_rsi_no : "",
      ground_contact_time: values.ground_contact_time
        ? values.ground_contact_time
        : "",
      cmj_weight: values.cmj_weight ? values.cmj_weight : "",
      es_height: values.es_height ? values.es_height : "",
      es_peak_power: values.es_peak_power ? values.es_peak_power : "",
      es_peak_propulsive_force: values.es_peak_propulsive_force
        ? values.es_peak_propulsive_force
        : "",
      ms_peak_force: values.ms_peak_force ? values.ms_peak_force : "",
      ms_rfd: values.ms_rfd ? values.ms_rfd : "",
      // ms_force: values.ms_force ? values.ms_force : "",
      sj_height: values.sj_height ? values.sj_height : "",
      sj_peak_power: values.sj_peak_power ? values.sj_peak_power : "",
      // sj_peak_propulsive_force: values.sj_peak_propulsive_force
      //   ? values.sj_peak_propulsive_force
      //   : "",
      sprint_time_four_meter: values.sprint_time_four_meter
        ? values.sprint_time_four_meter
        : "",
      sprint_time_tem_meter: values.sprint_time_tem_meter
        ? values.sprint_time_tem_meter
        : "",
      id: toggleModalPreview.data.id,
      assessor_id: userdata.id,
      assessor_name: localStorage.assessor_name,
      status: status_val,
      update_value: values.update_value,
    };

    console.log(toggleModalPreview.data.id);
    mutateApprove(data, {
      onSuccess: (res) => {
        if (res.success) {
          notification.success({
            message: "Success",
            description: "Successfully updated as " + status_val,
          });
          getData();
        }
      },
    });
  };

  const { mutate: mutateApprove } = POST(
    "api/v1/assessment_events_attendees_assess",
    `${keyName}`
  );

  const { mutate: mutateGet } = POST(
    "api/v1/get_modal_attendess_data",
    `${keyName}`
  );

  useEffect(() => {
    if (toggleModalPreview.data) {
      getData();
      console.log("toggleModalPreview.data", toggleModalPreview);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toggleModalPreview.data]);

  const [getDataRecord, setGetDataRecord] = useState();

  const getData = (val) => {
    mutateGet(
      { id: toggleModalPreview.data.id },
      {
        onSuccess: (res) => {
          if (res.success) {
            console.log("getData", res.data);
            setGetDataRecord(res.data[0]);
            modalForm.setFieldsValue({
              cmj_weight: res.data[0].cmj_weight,
              ground_contact_time: res.data[0].ground_contact_time,
              cmj_jump_height: res.data[0].cmj_jump_height,
              cmj_rsi_no: res.data[0].cmj_rsi_no,
              es_height: res.data[0].es_height,
              es_peak_propulsive_force: res.data[0].es_peak_propulsive_force,
              es_peak_power: res.data[0].es_peak_power,
              sj_height: res.data[0].sj_height,
              // sj_peak_propulsive_force: res.data[0].sj_peak_propulsive_force,
              sj_peak_power: res.data[0].sj_peak_power,
              sprint_time_tem_meter: res.data[0].sprint_time_tem_meter,
              sprint_time_four_meter: res.data[0].sprint_time_four_meter,
              ms_peak_force: res.data[0].ms_peak_force,
              ms_rfd: res.data[0].ms_rfd,
              // ms_force: res.data[0].ms_force,
            });
          }
        },
      }
    );
  };

  const [formData, setFormData] = useState("");
  // useEffect(() => {
  // 	const timeoutId = setTimeout(() => {
  // 		if (formData) {
  // 			modalForm.submit();
  // 			setFormData("");
  // 		}
  // 	}, 3000);
  // 	return () => {
  // 		clearTimeout(timeoutId);
  // 	};
  // }, [formData]);

  const hanldeInput = (val) => {
    if (val === "") {
      setFormData("Empty");
    } else {
      setFormData(val);
    }
  };

  const handleInputBlur = (value, field, input) => {
    console.log("value", value);
    console.log("field", field);
    console.log("getDataRecord", getDataRecord[field]);

    // if (
    //   getDataRecord[field] !== value &&
    //   value !== undefined &&
    //   value !== null &&
    //   value !== ""
    // ) {
    //   setUpdateValue(input);
    //   setFormData("");
    //   modalForm.submit();
    // }
    if (formData) {
      // setUpdateValue(input);
      // setFormData("");
      // modalForm.submit();
      modalForm
        .validateFields()
        .then((values) => {
          let newvalues = {
            ...values,
            update_value: `${input}: updated value to "${formData}" by ${userdata.role}: ${localStorage.assessor_name}`,
          };
          console.log("newvalues", newvalues);
          onFinishForm(newvalues);
          setFormData("");
        })
        .catch((info) => {
          console.log("Validate Failed:", info);
        });
    }
  };

  const validate = {
    pattern: {
      pattern: new RegExp(
        /(^[E][F]$)|(^[A][I]$)|(^[A][R][T]$)|^-?(0|[1-9][0-9]*)(\.[0-9]*)?$/
      ),
      message: "Invalid value",
    },
  };

  return (
    toggleModalPreview.data && (
      <Modal
        title={
          <>
            <Typography.Title level={3} className="m-b-none color-3" ellipsis>
              {`${
                toggleModalPreview.data &&
                toggleModalPreview.data.athlete?.firstname
              } ${
                toggleModalPreview.data &&
                toggleModalPreview.data.athlete?.lastname
              }`}
            </Typography.Title>

            <Space
              split={
                <Divider
                  type="vertical"
                  style={{ borderLeft: "1px solid #31302f" }}
                />
              }
              size={0}
            >
              {toggleModalPreview.data && toggleModalPreview.data.sport && (
                <span className="color-1 text-capitalize">
                  {toggleModalPreview.data && toggleModalPreview.data.sport}
                </span>
              )}
              {toggleModalPreview.data && toggleModalPreview.data.position && (
                <span className="color-1 text-capitalize">
                  {toggleModalPreview.data && toggleModalPreview.data.position
                    ? JSON.parse(toggleModalPreview.data.position).join(", ")
                    : ""}
                </span>
              )}
            </Space>
          </>
        }
        visible={toggleModalPreview.show}
        zIndex={3}
        width={700}
        footer={null}
        onCancel={() => setToggleModalPreview({ show: false, data: null })}
        className="modal-primary-default modal-manager-pending-assessment"
      >
        <Form form={modalForm} preserve={false} onFinish={onFinishForm}>
          <Row gutter={12}>
            <Col xs={24} sm={24} md={24}>
              <Collapse
                className="main-1-collapse"
                expandIcon={({ isActive }) =>
                  isActive ? (
                    <span
                      className="ant-menu-submenu-arrow"
                      style={{ color: "#FFF", transform: "rotate(270deg)" }}
                    ></span>
                  ) : (
                    <span
                      className="ant-menu-submenu-arrow"
                      style={{ color: "#FFF", transform: "rotate(90deg)" }}
                    ></span>
                  )
                }
                defaultActiveKey={["1"]}
                expandIconPosition="start"
              >
                <Panel
                  header="STARTER / PROFESSIONAL ASSESSMENTS"
                  key="1"
                  className="accordion bg-darkgray-form"
                >
                  <Row gutter={12}>
                    <Col xs={24} sm={24} md={24}>
                      <Space
                        split={
                          <Divider
                            type="vertical"
                            style={{ borderLeft: "1px solid #31302f" }}
                          />
                        }
                        size={0}
                        className="w-100 assessment-space"
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography.Title
                          level={5}
                          className="m-b-none fs-15 ta-center color-3"
                        >
                          EF - Equipment Failure
                        </Typography.Title>
                        <Typography.Title
                          level={5}
                          className="m-b-none fs-15 ta-center color-3"
                        >
                          AI - Athlete Injured
                        </Typography.Title>
                        <Typography.Title
                          level={5}
                          className="m-b-none fs-15 ta-center color-3"
                        >
                          ART - Athlete Refused Test
                        </Typography.Title>
                      </Space>

                      <Divider className="m-t-sm m-b-sm" />
                    </Col>

                    <Col xs={24} sm={24} md={12} className="m-t-sm">
                      <Typography.Text strong level={5}>
                        Reactive Strength <br /> (Multi-Rebound Jump)
                      </Typography.Text>

                      <Form.Item name="cmj_weight" rules={[validate.pattern]}>
                        <FloatInput
                          label="Weight (N) Calculated into lbs"
                          placeholder="Weight (N) Calculated into lbs"
                          onChange={hanldeInput}
                          onBlurInput={(e) =>
                            handleInputBlur(
                              e,
                              "cmj_weight",
                              "Reactive Strength (CMJ Rebound Jump) - Weight (N) Calculated into lbs"
                            )
                          }
                        />
                      </Form.Item>

                      <Form.Item
                        name="cmj_jump_height"
                        rules={[validate.pattern]}
                      >
                        <FloatInput
                          label="Peak Jump Height (In)"
                          placeholder="Peak Jump Height (In)"
                          onChange={hanldeInput}
                          onBlurInput={(e) =>
                            handleInputBlur(
                              e,
                              "cmj_jump_height",
                              "Jump Height (In)"
                            )
                          }
                        />
                      </Form.Item>

                      <Form.Item name="cmj_rsi_no" rules={[validate.pattern]}>
                        <FloatInput
                          label="RSI Top 3 Jumps Peak RSI"
                          placeholder="RSI Top 3 Jumps Peak RSI"
                          onChange={hanldeInput}
                          onBlurInput={(e) =>
                            handleInputBlur(
                              e,
                              "cmj_rsi_no",
                              "RSI Top 3 Jumps Peak RSI"
                            )
                          }
                        />
                      </Form.Item>

                      <Form.Item
                        name="ground_contact_time"
                        rules={[validate.pattern]}
                      >
                        <FloatInput
                          label="Ground Contact Time"
                          placeholder="Ground Contact Time"
                          onChange={hanldeInput}
                          onBlurInput={(e) =>
                            handleInputBlur(
                              e,
                              "ground_contact_time",
                              "Ground Contact Time"
                            )
                          }
                        />
                      </Form.Item>
                    </Col>

                    <Col xs={24} sm={24} md={12} className="m-t-sm">
                      <Typography.Text strong level={5}>
                        Elastic Strength <br /> (Countermovement Jump)
                      </Typography.Text>

                      <Form.Item name="es_height" rules={[validate.pattern]}>
                        <FloatInput
                          label="Jump Height (In)"
                          placeholder="Jump Height (In)"
                          onChange={hanldeInput}
                          onBlurInput={(e) =>
                            handleInputBlur(
                              e,
                              "es_height",
                              "Elastic Strength (Countermovement Jump) - Jump Height (In)"
                            )
                          }
                        />
                      </Form.Item>

                      <Form.Item
                        rules={[validate.pattern]}
                        name="es_peak_propulsive_force"
                      >
                        <FloatInput
                          label="Peak Propulsive Force (N)"
                          placeholder="Peak Propulsive Force (N)"
                          onChange={hanldeInput}
                          onBlurInput={(e) =>
                            handleInputBlur(
                              e,
                              "es_peak_propulsive_force",
                              "Peak Propulsive Force (N)"
                            )
                          }
                        />
                      </Form.Item>

                      <Form.Item
                        rules={[validate.pattern]}
                        name="es_peak_power"
                        className="letter-space"
                      >
                        <FloatInput
                          label="Relative Average Propulsive Power (W/kg)"
                          placeholder="Relative Average Propulsive Power (W/kg)"
                          onChange={hanldeInput}
                          onBlurInput={(e) =>
                            handleInputBlur(
                              e,
                              "es_peak_power",
                              "Relative Average Propulsive Power (W/kg)"
                            )
                          }
                        />
                      </Form.Item>
                    </Col>

                    <Col xs={24} sm={24} md={12} className="m-t-sm">
                      <Typography.Text strong level={5}>
                        Ballistic Strength (Squat Jump)
                      </Typography.Text>

                      <Form.Item rules={[validate.pattern]} name="sj_height">
                        <FloatInput
                          label="Jump Height (In)"
                          placeholder="Jump Height (In)"
                          onChange={hanldeInput}
                          onBlurInput={(e) =>
                            handleInputBlur(e, "sj_height", "Jump Height (In)")
                          }
                        />
                      </Form.Item>

                      <Form.Item
                        rules={[validate.pattern]}
                        name="sj_peak_power"
                      >
                        <FloatInput
                          label="Relative Peak Propulsive Power (W/kg)"
                          placeholder="Relative Peak Propulsive Power (W/kg)"
                          onChange={hanldeInput}
                          onBlurInput={(e) =>
                            handleInputBlur(
                              e,
                              "sj_peak_power",
                              "Relative Peak Propulsive Power (W/kg)"
                            )
                          }
                        />
                      </Form.Item>
                    </Col>

                    <Col xs={24} sm={24} md={12} className="m-t-sm">
                      <Typography.Text strong level={5}>
                        10YD/40YD Sprint
                      </Typography.Text>

                      <Form.Item
                        rules={[validate.pattern]}
                        name="sprint_time_tem_meter"
                      >
                        <FloatInput
                          label="10YD Time (sec)"
                          placeholder="10YD Time (sec)"
                          onChange={hanldeInput}
                          onBlurInput={(e) =>
                            handleInputBlur(
                              e,
                              "sprint_time_tem_meter",
                              "10YD/40YD Sprint - 10YD Time (sec)"
                            )
                          }
                        />
                      </Form.Item>

                      <Form.Item
                        rules={[validate.pattern]}
                        name="sprint_time_four_meter"
                      >
                        <FloatInput
                          label="40YD Time (sec)"
                          placeholder="40YD Time (sec)"
                          onChange={hanldeInput}
                          onBlurInput={(e) =>
                            handleInputBlur(
                              e,
                              "sprint_time_four_meter",
                              "10YD/40YD Sprint - 40YD Time (sec)"
                            )
                          }
                        />
                      </Form.Item>
                    </Col>

                    <Col xs={24} sm={24} md={12} className="m-t-sm">
                      <Typography.Text strong level={5}>
                        Maximal Strength <br /> (Isometric Mid-Thigh Pull)
                      </Typography.Text>

                      <Form.Item
                        rules={[validate.pattern]}
                        name="ms_peak_force"
                      >
                        <FloatInput
                          label="Peak Force (N)"
                          placeholder="Peak Force (N)"
                          onChange={hanldeInput}
                          onBlurInput={(e) =>
                            handleInputBlur(
                              e,
                              "ms_peak_force",
                              "Maximal Strength (Isometric Mid-Thigh Pull) - Peak Force (N)"
                            )
                          }
                        />
                      </Form.Item>

                      <Form.Item rules={[validate.pattern]} name="ms_rfd">
                        <FloatInput
                          label="Force at 100m/s (N)"
                          placeholder="Force at 100m/s (N)"
                          onChange={hanldeInput}
                          onBlurInput={(e) =>
                            handleInputBlur(e, "ms_rfd", "Force at 100m/s (N)")
                          }
                        />
                      </Form.Item>

                      {/* <Form.Item rules={[validate.pattern]} name="ms_force">
                        <FloatInput
                          label="0-100ms RFD % of Peak Force"
                          placeholder="0-100ms RFD % of Peak Force"
                          onChange={hanldeInput}
                          onBlurInput={(e) =>
                            handleInputBlur(
                              e,
                              "ms_force",
                              "0-100ms RFD % of Peak Force"
                            )
                          }
                        />
                      </Form.Item> */}
                    </Col>
                  </Row>
                </Panel>
              </Collapse>
              <br />
            </Col>
          </Row>
        </Form>
      </Modal>
    )
  );
}
