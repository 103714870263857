import {
  Button,
  Card,
  Col,
  Layout,
  notification,
  Popconfirm,
  Row,
  Space,
  Table,
} from "antd";
import React, { useState, useEffect } from "react";
import { faStar, faTimes } from "@fortawesome/pro-regular-svg-icons";
import ComponentHeader from "../../Components/ComponentHeader";
import {
  TableGlobalSearch,
  TablePageSize,
  TablePagination,
  TableShowingEntries,
} from "../../Components/ComponentTableFilter";
import { GET, POST } from "../../../../providers/useAxiosQuery";
import $ from "jquery";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import companyInfo from "../../../../providers/companyInfo";

export default function PageAthleticProfileEdit({ match, permission }) {
  // const history = useHistory();
  const userdata = companyInfo().userData;
  const [tableFilter, setTableFilter] = useState({
    page: 1,
    page_size: 50,
    search: "",
    sort_field: "name",
    sort_order: "desc",
    from: "coach_search",
  });

  const [tableTotal, setTableTotal] = useState(0);
  const [dataSource, setDataSource] = useState([]);
  const {
    refetch: refetchTable,
    isLoading: isLoadingTable,
    isFetching: isFetchingTable,
  } = GET(
    `api/v1/coach_follow_list?${$.param(tableFilter)}`,
    "coach_follow_list",
    (res) => {
      if (res.success) {
        console.log("coach_follow_list", res);
        setDataSource(res.data && res.data.data);
        setTableTotal(res.data.total);
      }
    }
  );

  const handleTableChange = (pagination, filters, sorter) => {
    setTableFilter({
      ...tableFilter,
      sort_field: sorter.columnKey,
      sort_order: sorter.order ? sorter.order.replace("end", "") : null,
      // page: 1,
    });
  };

  const [searchText, setSearchText] = useState("");
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setTableFilter({
        ...tableFilter,
        search: searchText,
        page: 1,
      });
    }, 1500);
    return () => {
      clearTimeout(timeoutId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText]);

  useEffect(() => {
    refetchTable();
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableFilter]);

  // const handleDelete = (id) => {
  //   let data = {
  //     status: 1,
  //     id: id,
  //   };

  //   mutateDelete(data, {
  //     onSuccess: (res) => {
  //       if (res.success) {
  //         notification.success({
  //           message: "Success",
  //           description: "Successfully deleted.",
  //         });
  //       }
  //     },
  //   });
  // };

  // const { mutate: mutateDelete, isLoading: isLoadingDelete } = UPDATE(
  //   "api/v1/assessment",
  //   "assessment"
  // );

  const [selectionType, setSelectionType] = useState([]);
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      //   console.log(
      //     `selectedRowKeys: ${selectedRowKeys}`,
      //     "selectedRows: ",
      //     selectedRows
      //   );
      setSelectionType(selectedRowKeys);
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User",
      // Column configuration not to be checked
      name: record.name,
    }),
  };

  const handleDeactivate = () => {
    let data = {
      selected: selectionType,
      user_id: userdata.id,
    };
    console.log("handleDeactivate", data);
    mutateDeactivate(data, {
      onSuccess: (res) => {
        if (res.success) {
          // console.log(res);
          notification.success({
            message: "Success",
            description: "Successfully unfollowed",
          });
        }
      },
    });
  };

  const { mutate: mutateDeactivate, isLoading: isLoadingDeactivate } = POST(
    "api/v1/coach_unfollow",
    "coach_follow_list"
  );

  return (
    <>
      <ComponentHeader title="I Follow" sub_title="ATHLETES" icon={faStar} />

      <Card className="card-min-height" id="PageAthleticProfileEdit">
        <Row gutter={12}>
          <Col xs={24} sm={24} md={16} lg={16} xl={18} className="m-b-sm">
            <Space>
              <TablePageSize
                paginationFilter={tableFilter}
                setPaginationFilter={setTableFilter}
              />
              {selectionType.length > 0 && (
                <Popconfirm
                  title="Are you sure you wish to unfollow this athlete(s)?"
                  onConfirm={handleDeactivate}
                  okText="Confirm"
                  cancelText="Cancel"
                >
                  <Button
                    type="primary"
                    className="btn-main-outline-2"
                    // loading={isLoadingDeactivate}
                  >
                    <FontAwesomeIcon icon={faTimes} /> &nbsp; UNFOLLOW
                  </Button>
                </Popconfirm>
              )}
            </Space>
          </Col>
          <Col xs={24} sm={24} md={8} lg={8} xl={6}>
            <TableGlobalSearch
              paginationFilter={searchText}
              setPaginationFilter={setSearchText}
            />
          </Col>
        </Row>
        <Row gutter={24}>
          <Col xs={24} sm={24} md={24} className="m-t-sm m-b-sm">
            <Table
              className="ant-table-default ant-table-striped"
              scroll={{ x: "max-content" }}
              loading={isLoadingTable || isFetchingTable}
              dataSource={dataSource && dataSource}
              rowKey={(record) => record.id}
              pagination={false}
              bordered={false}
              onChange={handleTableChange}
              rowSelection={{
                type: selectionType,
                ...rowSelection,
              }}
            >
              <Table.Column
                title="Full Name"
                key="name"
                dataIndex="name"
                defaultSortOrder="descend"
                sorter={true}
                // render={(text, record) => {
                //   // console.log("record", record);
                //   let defaultWebsite =
                //     record &&
                //     record.athlete_follow &&
                //     record.athlete_follow.athlete_website.filter(
                //       (itemFilter) => itemFilter.is_default === 1
                //     );
                //   // console.log("defaultWebsite", defaultWebsite);
                //   return defaultWebsite ? (
                //     <Link
                //       to={`/athletic/${
                //         defaultWebsite.length > 0 &&
                //         defaultWebsite[0].name &&
                //         defaultWebsite[0].name
                //       }`}
                //       target="_blank"
                //     >
                //       {text}
                //     </Link>
                //   ) : (
                //     text
                //   );
                // }}
                render={(text, record) => {
                  let defaultWebsite =
                    record.athlete_follow.athlete_website.filter(
                      (itemFilter) => itemFilter.is_default === 1
                    );
                  // console.log("defaultWebsite", defaultWebsite);
                  return defaultWebsite ? (
                    <>
                      {defaultWebsite.length > 0 ? (
                        <Link
                          to={`/athletic/${
                            defaultWebsite.length > 0 &&
                            defaultWebsite[0].name &&
                            defaultWebsite[0].name
                          }`}
                          target="_blank"
                        >
                          {text}
                        </Link>
                      ) : (
                        text
                      )}
                    </>
                  ) : (
                    text
                  );
                }}
                width={"200px"}
              />
              {/* <Table.Column
                title="Last Name"
                key="organization_type"
                dataIndex="organization_type"
                sorter={true}
              /> */}
              <Table.Column
                title="School"
                key="school"
                dataIndex="school"
                sorter={true}
                width={"220px"}
              />
              <Table.Column
                title="Sport"
                key="sport"
                dataIndex="sport"
                sorter={true}
                width={"250px"}
              />
              <Table.Column
                title="Position"
                key="position"
                dataIndex="position"
                sorter={true}
                render={(text, record) => {
                  if (record.position) {
                    return JSON.parse(record.position).join(", ");
                  }
                }}
                width={"150px"}
              />
            </Table>
          </Col>
          <Col xs={24} sm={24} md={24}>
            <Space className="ant-space-flex-space-between">
              <TableShowingEntries />
              <TablePagination
                paginationFilter={tableFilter}
                setPaginationFilter={setTableFilter}
                setPaginationTotal={tableTotal}
                showLessItems={true}
                showSizeChanger={false}
              />
            </Space>
          </Col>
          <Col xs={24} sm={24} md={12}></Col>
        </Row>
      </Card>
    </>
  );
}
