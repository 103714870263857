import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarAlt,
  faAlarmClock,
  faHome,
  faSchool,
  faTicketAlt,
  faUsers,
  faUserFriends,
  faCommentDots,
  faClipboardListCheck,
} from "@fortawesome/pro-regular-svg-icons";

const ManagerSideMenu = [
  {
    title: "Dashboard",
    path: "/dashboard",
    icon: <FontAwesomeIcon icon={faHome} style={{ marginTop: "-5px" }} />,
    // permission: "Dashboard",
  },
  {
    title: "Pending Assessments",
    path: "/pending-assessments",
    icon: <FontAwesomeIcon icon={faAlarmClock} style={{ marginTop: "-5px" }} />,
    // permission: "Dashboard",
  },
  {
    title: "Users",
    path: "/user",
    icon: <FontAwesomeIcon icon={faUsers} style={{ marginTop: "-5px" }} />,
    children: [
      {
        title: "View / Edit Active",
        path: "/user/view-edit-active",
        // permission: "User View/Edit Active",
      },
      {
        title: "View / Edit Deactivated",
        path: "/user/view-edit-deactivated",
        // permission: "User View/Edit Deactivated",
      },
      {
        title: "Search Athletes",
        path: "/user/search-athletes",
        // permission: "User Search Athletes",
      },
    ],
  },
  {
    title: "Organizations",
    path: "/organization",
    icon: <FontAwesomeIcon icon={faSchool} style={{ marginTop: "-5px" }} />,
    children: [
      {
        title: "View / Edit",
        path: "/organization/view-edit",
        // permission: "Organization View / Edit",
      },
      {
        title: "Add New",
        path: "/organization/add-new",
        // permission: "Organization Add New",
      },
      {
        title: "Pending Approval",
        path: "/organization/pending-approval",
        // permission: "Organization Pending Approval",
      },
    ],
  },
  {
    title: "My Events Calendar",
    path: "/event-calendar",
    icon: (
      <FontAwesomeIcon icon={faCalendarAlt} style={{ marginTop: "-5px" }} />
    ),
    children: [
      {
        title: "Add Assessment",
        path: "/event-calendar/add-assessment",
        // permission: "Add Assessment",
      },
      {
        title: "Edit Assessment",
        path: "/event-calendar/edit-assessments",
        // permission: "My Event Calendar - View All Event",
      },
      {
        title: "Group Assessments",
        path: "/event-calendar/group-assessment",
        // permission: "My Event Calendar - Group Assessments",
      },
      {
        title: "Private Assessments",
        path: "/event-calendar/private-assessment",
        // permission: "My Event Calendar - Private Assessments",
      },
      {
        title: "Add / Edit Schedule",
        path: "/event-calendar/add-edit-schedule",
        // permission: "My Event Calendar - Private Assessments",
      },
    ],
  },
  {
    title: "Assessors",
    path: "/assessors",
    icon: (
      <FontAwesomeIcon icon={faUserFriends} style={{ marginTop: "-5px" }} />
    ),
    // permission: "Ticketing",
  },
  {
    title: "Ticketing",
    path: "/ticketing",
    icon: <FontAwesomeIcon icon={faTicketAlt} style={{ marginTop: "-5px" }} />,
    // permission: "Ticketing",
  },
  {
    title: "Messages",
    path: "/messages",
    icon: (
      <FontAwesomeIcon icon={faCommentDots} style={{ marginTop: "-5px" }} />
    ),
    // permission: "Ticketing",
  },
  {
    title: "Assessment Logs",
    path: "/assessment-logs",
    icon: (
      <FontAwesomeIcon
        icon={faClipboardListCheck}
        style={{ marginTop: "-5px" }}
      />
    ),
    // permission: "Ticketing",
  },
];

export default ManagerSideMenu;
