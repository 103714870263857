import { Card, Col, Layout, Row } from "antd";
import { FaUser } from "react-icons/fa";
import ComponentHeader from "../../Components/ComponentHeader";

export default function PageTicketing() {
  return (
    <Layout className="site-layout-background" id="PageTicketing">
      <ComponentHeader
        title="Account"
        sub_title="CREATE MANAGER / ASSESSOR"
        Icon={FaUser}
      />

      <Layout.Content>
        <Card className="ant-card-bordered-blue">
          <Row gutter={12}>
            <Col span={24}>PageTicketing</Col>
          </Row>
        </Card>
      </Layout.Content>
    </Layout>
  );
}
