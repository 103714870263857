import {
  Button,
  Card,
  Col,
  Layout,
  notification,
  Popconfirm,
  Row,
  Space,
  Table,
} from "antd";
import React, { useState, useEffect } from "react";
// import { FaTimes } from "react-icons/fa";
import { faUsers, faCheck } from "@fortawesome/pro-regular-svg-icons";
import ComponentHeader from "../../Components/ComponentHeader";
import {
  TableGlobalSearch,
  TablePageSize,
  TablePagination,
  TableShowingEntries,
} from "../../Components/ComponentTableFilter";
import { GET, POST } from "../../../../providers/useAxiosQuery";
import $ from "jquery";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import companyInfo from "../../../../providers/companyInfo";

export default function PageUserViewEditDeactivated({ match, permission }) {
  // const history = useHistory();

  const userdata = companyInfo().userData;
  const [tableFilter, setTableFilter] = useState({
    page: 1,
    page_size: 50,
    search: "",
    sort_field: "firstname",
    sort_order: "desc",
    status: "Deactive",
    // from: "manager",
  });

  const [tableTotal, setTableTotal] = useState(0);
  const [dataSource, setDataSource] = useState([]);
  const {
    refetch: refetchTable,
    isLoading: isLoadingTable,
    isFetching: isFetchingTable,
  } = GET(`api/v1/users?${$.param(tableFilter)}`, "users", (res) => {
    if (res.success) {
      console.log("dataTable", res);
      setDataSource(res.data && res.data.data);
      setTableTotal(res.data.total);
    }
  });

  const handleTableChange = (pagination, filters, sorter) => {
    setTableFilter({
      ...tableFilter,
      sort_field: sorter.columnKey,
      sort_order: sorter.order ? sorter.order.replace("end", "") : null,
      // page: 1,
    });
  };

  useEffect(() => {
    refetchTable();
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableFilter]);

  const [selectionType, setSelectionType] = useState([]);
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      // console.log(
      //   `selectedRowKeys: ${selectedRowKeys}`,
      //   "selectedRows: ",
      //   selectedRows
      // );

      setSelectionType(selectedRowKeys);
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User",
      // Column configuration not to be checked
      name: record.name,
    }),
  };

  const handleDeactivate = () => {
    let data = {
      selected: selectionType,
      status: "Active",
    };
    console.log("handleDeactivate", data);

    mutateDeactivate(data, {
      onSuccess: (res) => {
        if (res.success) {
          console.log(res);
          notification.success({
            message: "Success",
            description: "Successfully activated",
          });
        }
      },
    });
  };

  const { mutate: mutateDeactivate, isLoading: isLoadingDeactivate } = POST(
    "api/v1/user_deactivate",
    "users"
  );

  const [searchText, setSearchText] = useState("");
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setTableFilter({
        ...tableFilter,
        search: searchText,
        page: 1,
      });
    }, 1500);
    return () => {
      clearTimeout(timeoutId);
    };
  }, [searchText]);

  return (
    <>
      <ComponentHeader
        title={permission}
        sub_title="VIEW / EDIT"
        icon={faUsers}
      />

      <Card className="card-min-height" id="PageUserViewEditDeactivated">
        <Row gutter={12}>
          <Col xs={24} sm={24} md={16} lg={16} xl={18} className="m-b-sm">
            <Space>
              <TablePageSize
                paginationFilter={tableFilter}
                setPaginationFilter={setTableFilter}
              />
              {selectionType.length > 0 && (
                <Popconfirm
                  title="Are you sure you wish to activate this user(s)?"
                  onConfirm={handleDeactivate}
                  okText="Confirm"
                  cancelText="Cancel"
                >
                  <Button
                    type="primary"
                    className="btn-main-outline-2"
                    loading={isLoadingDeactivate}
                  >
                    <FontAwesomeIcon icon={faCheck} /> &nbsp; ACTIVATE
                  </Button>
                </Popconfirm>
              )}
            </Space>
          </Col>
          <Col xs={24} sm={24} md={8} lg={8} xl={6}>
            <TableGlobalSearch
              paginationFilter={searchText}
              setPaginationFilter={setSearchText}
            />
          </Col>
        </Row>
        <Row gutter={12}>
          <Col xs={24} sm={24} md={24} className="m-t-sm m-b-sm">
            <Table
              className="ant-table-default ant-table-striped"
              dataSource={dataSource && dataSource}
              rowKey={(record) => record.id}
              loading={isLoadingTable || isFetchingTable}
              pagination={false}
              bordered={false}
              onChange={handleTableChange}
              rowSelection={{
                type: selectionType,
                ...rowSelection,
              }}
              scroll={{ x: "max-content" }}
            >
              <Table.Column
                title="First Name"
                key="firstname"
                dataIndex="firstname"
                defaultSortOrder="descend"
                sorter={true}
                render={(text, record) => {
                  let link = `/user/edit/${record.id}`;
                  if (userdata.role === "Admin") {
                    if (record.role === "Manager") {
                      link = `/user/edit-account/${record.id}`;
                    } else {
                      link = `/user/edit/${record.id}`;
                    }
                    return <Link to={link}>{text ? text : "Edit"}</Link>;
                  } else {
                    link = `/user/edit/${record.id}`;
                    return record.edit_permission === "No" ? (
                      <Link to={link}>{text ? text : "Edit"}</Link>
                    ) : (
                      <>{text ? text : "Edit"}</>
                    );
                  }
                }}
                width={"150px"}
              />
              <Table.Column
                title="Last Name"
                key="lastname"
                dataIndex="lastname"
                sorter={true}
                render={(text, record) => {
                  let link = `/user/edit/${record.id}`;
                  if (userdata.role === "Admin") {
                    if (record.role === "Manager") {
                      link = `/user/edit-account/${record.id}`;
                    } else {
                      link = `/user/edit/${record.id}`;
                    }
                    return <Link to={link}>{text ? text : "Edit"}</Link>;
                  } else {
                    link = `/user/edit/${record.id}`;
                    return record.edit_permission === "No" ? (
                      <Link to={link}>{text ? text : "Edit"}</Link>
                    ) : (
                      <>{text ? text : "Edit"}</>
                    );
                  }
                }}
                width={"150px"}
              />
              <Table.Column
                title="Organization"
                key="organization"
                dataIndex="organization"
                sorter={true}
                width={"200px"}
              />
              {/* <Table.Column
                title="Edit Permissions"
                key="permission"
                dataIndex="permission"
                render={(text, record) => {
                  return "No";
                }}
                sorter={true}
              /> */}
              <Table.Column
                title="Role"
                key="role"
                dataIndex="role"
                sorter={true}
                width={"150px"}
              />
            </Table>
          </Col>
          <Col xs={24} sm={24} md={24}>
            <Space className="ant-space-flex-space-between">
              <TableShowingEntries />
              <TablePagination
                paginationFilter={tableFilter}
                setPaginationFilter={setTableFilter}
                setPaginationTotal={tableTotal}
                showLessItems={true}
                showSizeChanger={false}
              />
            </Space>
          </Col>
          <Col xs={24} sm={24} md={12}></Col>
        </Row>
        <br />
      </Card>
    </>
  );
}
