import { Col, Collapse, Row, Space, Table } from "antd";
import React, { useState, useEffect } from "react";
import {
  TableGlobalSearch,
  TablePageSize,
  TablePagination,
  TableShowingEntries,
} from "../../../Components/ComponentTableFilter";
import $ from "jquery";
import { Link } from "react-router-dom";
import { GET } from "../../../../../providers/useAxiosQuery";
import ModalStarterProfessionalAssessment from "../Modals/ModalStarterProfessionalAssessment";
import ModalEliteAssessment from "../Modals/ModalEliteAssessment";
import companyInfo from "../../../../../providers/companyInfo";

export default function TablePrivate({ match, permission }) {
  const { Panel } = Collapse;
  // const history = useHistory();
  const userdata = companyInfo().userData;
  const [tableFilter1, setTableFilter1] = useState({
    page: 1,
    page_size: 10,
    search: "",
    sort_field: "strdate",
    sort_order: "asc",
    event_type: "Private",
    manager_id: userdata.manager_id,
  });

  const [tableTotal, setTableTotal] = useState(0);
  const [dataSource, setDataSource] = useState([]);
  const {
    refetch: refetchTable,
    isLoading: isLoadingTable,
    isFetching: isFetchingTable,
  } = GET(
    `api/v1/assessment_events_private?${$.param(tableFilter1)}`,
    "assessment_events_attendeess",
    (res) => {
      if (res.success) {
        console.log("assessment_events_attendeess", res);
        setDataSource(res.data && res.data.data);
        setTableTotal(res.data.total);
      }
    }
  );

  const handleTableChange = (pagination, filters, sorter) => {
    setTableFilter1({
      ...tableFilter1,
      sort_field: sorter.columnKey,
      sort_order: sorter.order ? sorter.order.replace("end", "") : null,
      // page: 1,
    });
  };

  const [searchText, setSearchText] = useState("");
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setTableFilter1({
        ...tableFilter1,
        search: searchText,
        page: 1,
      });
    }, 1500);
    return () => {
      clearTimeout(timeoutId);
    };
  }, [searchText]);

  useEffect(() => {
    refetchTable();
    return () => {};
  }, [tableFilter1]);

  const [toggleModalPreview, setToggleModalPreview] = useState({
    show: false,
    data: null,
    type: "Starter",
  });

  return (
    <Collapse
      className="main-1-collapse"
      expandIcon={({ isActive }) =>
        isActive ? (
          <span
            className="ant-menu-submenu-arrow"
            style={{ color: "#FFF", transform: "rotate(270deg)" }}
          ></span>
        ) : (
          <span
            className="ant-menu-submenu-arrow"
            style={{ color: "#FFF", transform: "rotate(90deg)" }}
          ></span>
        )
      }
      defaultActiveKey={["1a"]}
      expandIconPosition="start"
    >
      <Panel
        header="Private Assessments"
        key="1a"
        className="accordion bg-darkgray-form"
      >
        <Row gutter={12}>
          <Col xs={24} sm={24} md={16} lg={16} xl={18} className="m-b-sm">
            <Space>
              <TablePageSize
                paginationFilter={tableFilter1}
                setPaginationFilter={setTableFilter1}
              />
              <br />
            </Space>
          </Col>
          <Col xs={24} sm={24} md={8} lg={8} xl={6}>
            <TableGlobalSearch
              paginationFilter={searchText}
              setPaginationFilter={setSearchText}
            />
            <br />
          </Col>
        </Row>
        <Row gutter={12}>
          <Col xs={24} sm={24} md={24} className="m-t-sm m-b-sm">
            <Table
              className="ant-table-default ant-table-striped"
              dataSource={dataSource && dataSource}
              rowKey={(record) => record.id}
              loading={isLoadingTable || isFetchingTable}
              pagination={false}
              bordered={false}
              onChange={handleTableChange}
              scroll={{ x: "max-content" }}
            >
              <Table.Column
                title="Date"
                key="strdate"
                dataIndex="strdate"
                defaultSortOrder="ascend"
                sorter={true}
                width={"250px"}
              />
              <Table.Column
                title="Time"
                key="strtime"
                dataIndex="strtime"
                sorter={true}
                width={"150px"}
              />
              <Table.Column
                title="Participant"
                key="name"
                dataIndex="name"
                sorter={true}
                width="250px"
                render={(text, record) => {
                  return record.status !== "Approved" ? (
                    <Link
                      // to={`/dashboard/event/${record.id}`}
                      to={`#`}
                      className="companycolor1 cursor"
                      onClick={(e) => {
                        setToggleModalPreview({
                          show: true,
                          data: record,
                          type: record.type,
                        });
                      }}
                    >
                      {text}
                    </Link>
                  ) : (
                    text
                  );
                }}
              />
              <Table.Column
                title="Type"
                key="type"
                dataIndex="type"
                sorter={true}
                width={"150px"}
              />
              <Table.Column
                title="Location"
                key="city"
                dataIndex="city"
                sorter={true}
                width="150px"
              />
              {/* <Table.Column
                title="State"
                key="state"
                dataIndex="state"
                sorter={true}
              /> */}
              <Table.Column
                title="Status"
                key="status"
                dataIndex="status"
                sorter={true}
                width={"150px"}
              />
            </Table>
          </Col>
          <Col xs={24} sm={24} md={24}>
            <Space className="ant-space-flex-space-between table2">
              <TableShowingEntries />

              <TablePagination
                paginationFilter={tableFilter1}
                setPaginationFilter={setTableFilter1}
                setPaginationTotal={tableTotal}
                showLessItems={true}
                showSizeChanger={false}
                parentClass="table2"
              />
            </Space>
          </Col>
        </Row>
      </Panel>

      {/* <ModalStarterProfessionalAssessment /> */}
      {toggleModalPreview.type === "Starter" ||
      toggleModalPreview.type === "Professional" ? (
        <>
          <ModalStarterProfessionalAssessment
            toggleModalPreview={toggleModalPreview}
            setToggleModalPreview={setToggleModalPreview}
            keyName="assessment_events_attendeess"
          />
        </>
      ) : (
        <>
          <ModalEliteAssessment
            toggleModalPreview={toggleModalPreview}
            setToggleModalPreview={setToggleModalPreview}
            keyName="assessment_events_attendeess"
          />
        </>
      )}
    </Collapse>
  );
}
