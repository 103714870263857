import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Layout, Menu, Badge, Image, Space, Typography, Dropdown } from "antd";
import { MenuUnfoldOutlined } from "@ant-design/icons";
import { MdOutlineDashboardCustomize } from "react-icons/md";
import $ from "jquery";
import companyInfo from "../../providers/companyInfo";
import NotificationsAlert from "./Components/NotificationsAlert";
import MessagesAlert from "./Components/MessagesAlert";
import defaultImage from "../../assets/img/default.png";
// import { GET } from "../../../../providers/useAxiosQuery";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBell,
  faCommentAltDots,
  faCreditCard,
  faEdit,
  faPowerOff,
  faUserFriends,
} from "@fortawesome/pro-regular-svg-icons";

import {
  menuLeft as AdminHeaderMenuLeft,
  dropDownMenuLeft as AdminHeaderDropDownMenuLeft,
} from "./RoleMenu/admin/AdminHeader";
import {
  menuLeft as ManagerHeaderMenuLeft,
  dropDownMenuLeft as ManagerHeaderDropDownMenuLeft,
} from "./RoleMenu/manager/ManagerHeader";
import {
  menuLeft as AthleteHeaderMenuLeft,
  dropDownMenuLeft as AthleteHeaderDropDownMenuLeft,
} from "./RoleMenu/athlete/AthleteHeader";
import {
  menuLeft as CoachHeaderMenuLeft,
  dropDownMenuLeft as CoachHeaderDropDownMenuLeft,
} from "./RoleMenu/coach/CoachHeader";
import {
  menuLeft as AssessorHeaderMenuLeft,
  dropDownMenuLeft as AssessorHeaderDropDownMenuLeft,
} from "./RoleMenu/assessor/AssessorHeader";
import { GET } from "../../providers/useAxiosQuery";

const userData = companyInfo().userData;
const role = companyInfo().role;
// const apiUrl = companyInfo().apiUrl;

export default function Header(props) {
  const { width, setSideMenuCollapse } = props;
  const [menuLeft, setMenuLeft] = useState(null);
  const [dropDownMenuLeft, setDropDownMenuLeft] = useState(null);

  // console.log("userData", userData);

  useEffect(() => {
    if (role === "Admin") {
      setMenuLeft(AdminHeaderMenuLeft);
      setDropDownMenuLeft(AdminHeaderDropDownMenuLeft);
    } else if (role === "Manager") {
      setMenuLeft(ManagerHeaderMenuLeft);
      setDropDownMenuLeft(ManagerHeaderDropDownMenuLeft);
    } else if (role === "Athlete" || role === "Athlete Guardian") {
      setMenuLeft(AthleteHeaderMenuLeft);
      setDropDownMenuLeft(AthleteHeaderDropDownMenuLeft);
    } else if (role === "Coach") {
      setMenuLeft(CoachHeaderMenuLeft);
      setDropDownMenuLeft(CoachHeaderDropDownMenuLeft);
    } else if (role === "Assessor") {
      setMenuLeft(AssessorHeaderMenuLeft);
      setDropDownMenuLeft(AssessorHeaderDropDownMenuLeft);
    }

    $(".ant-menu-item-logout").hover(function () {
      console.log("asds");
    });

    // console.log("userData", userData);
  }, []);

  const handleLogout = () => {
    localStorage.removeItem("userdata");
    localStorage.removeItem("token");
    localStorage.removeItem("assessor_name");
    localStorage.removeItem("userdata_admin");
    localStorage.removeItem("viewas");
    window.location.replace("/");
  };

  const [notification, setNotification] = useState({
    count: 0,
    data: [],
  });

  const { refetch: refetchNotification } = GET(
    "api/v1/get_notification_alert",
    "get_notification_alert",
    (res) => {
      if (res.success) {
        console.log("setNotification", res);
        setNotification({
          data: res.data,
          count: res.unread,
        });
      }
    }
  );

  const [unreadMessages, setUnreadMessages] = useState(0);
  const [messages, setMessages] = useState([]);
  const {
    // data: dataMessages,
    // isLoading: isLoadingMessages,
    refetch: refetchMessages,
  } = GET("api/v1/get_messages_alert", "get_messages_alert", (res) => {
    if (res.success) {
      console.log("dataMessages", res);
      setMessages(res.data);
      setUnreadMessages(res.unread);
    }
  });

  // const handleMenuClick = () => {
  // 	console.log("handleMenuClick");
  // };

  // const handleMenuSelect = ({ item, key, keyPath, selectedKeys, domEvent }) => {
  // 	console.log(
  // 		"item, key, keyPath, selectedKeys, domEvent ",
  // 		item,
  // 		key,
  // 		keyPath,
  // 		selectedKeys,
  // 		domEvent
  // 	);
  // };

  const [imageProfile, setImageProfile] = useState(defaultImage);

  GET(`api/v1/users/${userData.id}`, "update_profile", (res) => {
    if (res.success) {
      if (res.data.profile_image) {
        let avatarImage = res.data.profile_image.split("/");
        if (avatarImage[0] === "https:") {
          setImageProfile(res.data.profile_image);
        } else {
          setImageProfile(companyInfo().apiUrl + res.data.profile_image);
        }
      }
    }
  });

  const menuProfile = () => {
    let profile_link = "/profile";

    if (role === "Athlete" || role === "Athlete Guardian") {
      profile_link = "/profile/profile-edit";
    }

    return (
      <Menu>
        <Menu.Item
          key="/profile/details"
          className="ant-menu-item-profile-details"
          disabled
          style={{
            cursor: "context-menu",
          }}
        >
          <Space>
            <Image
              src={imageProfile}
              preview={false}
              className="ant-typography-profile-details-image"
              style={{ objectFit: "cover" }}
            />

            <Typography.Text className="ant-typography-profile-details">
              <Typography.Text>
                {localStorage.assessor_name ? (
                  <>{localStorage.assessor_name}</>
                ) : (
                  <Typography.Text className="ant-typography-profile-details-name-info">
                    {userData.firstname} {userData.lastname}
                  </Typography.Text>
                )}
              </Typography.Text>
              <br />
              <Typography.Text>
                {userData.role === "Manager" && (
                  <span className="color-3">BFSS</span>
                )}{" "}
                {userData.role}
              </Typography.Text>
            </Typography.Text>
          </Space>
        </Menu.Item>

        <Menu.Item
          key="/profile/account"
          icon={<FontAwesomeIcon icon={faEdit} style={{ width: "1.125em" }} />}
        >
          <Link to={profile_link}>Edit Account Profile</Link>
        </Menu.Item>

        {role === "Athlete" || role === "Athlete Guardian" ? (
          <>
            <Menu.Item
              key="/profile/parent-guardian"
              icon={
                <FontAwesomeIcon
                  icon={faUserFriends}
                  style={{ width: "1.125em" }}
                />
              }
            >
              <Link to="/profile/parent-guardian">Parent / Guardian</Link>
            </Menu.Item>

            <Menu.Item
              key="/profile/payments-receipts"
              icon={
                <FontAwesomeIcon
                  icon={faCreditCard}
                  style={{ width: "1.125em" }}
                />
              }
            >
              <Link to="/profile/payments-receipts">Payments / Receipts</Link>
            </Menu.Item>
          </>
        ) : null}

        <Menu.Item
          key="/profile/signout"
          icon={
            <FontAwesomeIcon icon={faPowerOff} style={{ width: "1.125em" }} />
          }
          onClick={handleLogout}
          className="ant-menu-item-logout"
        >
          <Link to="#">Sign Out</Link>
        </Menu.Item>
      </Menu>
    );
  };

  const menuNotification = (
    <NotificationsAlert
      notification={notification.data}
      refetch={refetchNotification}
    />
  );

  useEffect(() => {
    // console.log("notifProps", props.path);
    refetchMessages();
    refetchNotification();
  }, [props.path]);

  // console.log("userData.image_url", userData.image_url);
  return (
    <Layout.Header className="site-layout-background">
      <Space className="ant-space-container">
        <Space className="ant-space-left" size={0}>
          {width <= 576 && (
            <div className="ant-space-icon">
              <MenuUnfoldOutlined
                style={{ padding: "23px" }}
                onClick={() => setSideMenuCollapse(false)}
                className="menuCollapseOnClose"
              />
            </div>
          )}
          {width <= 768 && dropDownMenuLeft !== null ? (
            <div className="ant-space-icon mobile-menu-fold">
              <Dropdown
                overlay={dropDownMenuLeft}
                placement="bottomRight"
                overlayClassName="ant-menu-submenu-left-menus-popup"
              >
                <span className="ant-menu-submenu-left-menus">
                  <MdOutlineDashboardCustomize />
                </span>
              </Dropdown>
            </div>
          ) : null}
          {width > 768 && menuLeft !== null ? menuLeft : null}
        </Space>
        <Space className="ant-space-right" size={0}>
          <Dropdown
            overlay={menuProfile}
            placement="bottomRight"
            overlayClassName="ant-menu-submenu-profile-popup"
          >
            <Image
              className="ant-menu-submenu-profile"
              src={imageProfile}
              preview={false}
              style={{ objectFit: "cover" }}
            />
          </Dropdown>

          <Dropdown
            overlay={menuNotification}
            placement="bottomRight"
            overlayClassName="ant-menu-submenu-notification-popup"
            // trigger={["click"]}
          >
            <Badge
              count={notification.count < 99 ? notification.count : "99+"}
              className="ant-menu-submenu-notification"
            >
              <FontAwesomeIcon icon={faBell} />
            </Badge>
          </Dropdown>

          <Dropdown
            overlay={
              <MessagesAlert messages={messages} refetch={refetchMessages} />
            }
            placement="bottomRight"
            overlayClassName="ant-menu-submenu-message-alert-popup scrollbar-2"
            // trigger={["click"]}
          >
            <Badge
              count={unreadMessages < 99 ? unreadMessages : "99+"}
              className="ant-menu-submenu-message-alert"
            >
              <FontAwesomeIcon icon={faCommentAltDots} />
            </Badge>
          </Dropdown>
        </Space>
      </Space>
    </Layout.Header>
  );
}
