import {
  Button,
  Card,
  Col,
  notification,
  Popconfirm,
  Row,
  Space,
  Table,
} from "antd";
import React, { useState, useEffect } from "react";
import {
  CheckOutlined,
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { faSchool } from "@fortawesome/pro-regular-svg-icons";
// import { useHistory } from "react-router-dom";
// import $ from "jquery";

import ComponentHeader from "../../Components/ComponentHeader";
import {
  TableGlobalSearch,
  TablePageSize,
  TablePagination,
  TableShowingEntries,
} from "../../Components/ComponentTableFilter";
import { GET, POST } from "../../../../providers/useAxiosQuery";
// import companyInfo from "../../../../providers/companyInfo";
import ModalAdd from "./ModalAdd";
import { faTag, faTags } from "@fortawesome/free-solid-svg-icons";
import ModalUpdate from "./ModalUpdate";

export default function PageCoupon({ match, permission }) {
  // const history = useHistory();
  // const userdata = companyInfo().userData;
  const [tableFilter, setTableFilter] = useState({
    page: 1,
    page_size: 50,
    search: "",
    sort_field: "school",
    sort_order: "desc",
    total: 0,
  });

  const [tableTotal, setTableTotal] = useState(0);
  const [dataSource, setDataSource] = useState([]);
  // const { refetch: refetchTable } = GET(
  //   `api/v1/athlete_assessment?${$.param(tableFilter)}`,
  //   "athlete_assessment",
  //   (res) => {
  //     if (res.success) {
  //       console.log("dataTable", res);
  //       setDataSource(res.data && res.data.data);
  //       setTableTotal(res.data.total);
  //     }
  //   }
  // );

  const {
    isLoading: isLoadingTable,
    isFetching: isFetchingTable,
    refetch: refetchTable,
  } = GET(
    `api/v1/coupon?${new URLSearchParams(tableFilter)}`,
    "get_coupons_system",
    (res) => {
      if (res.data) {
        console.log("res", res);
        setDataSource(res.data && res.data.data);
        setTableTotal(res.data.total);
      }
    }
  );

  const handleTableChange = (pagination, filters, sorter) => {
    setTableFilter({
      ...tableFilter,
      sort_field: sorter.columnKey,
      sort_order: sorter.order ? sorter.order.replace("end", "") : null,
      // page: 1,
    });
  };

  const [searchText, setSearchText] = useState("");
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setTableFilter({
        ...tableFilter,
        search: searchText,
        page: 1,
      });
    }, 1500);
    return () => {
      clearTimeout(timeoutId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText]);

  useEffect(() => {
    refetchTable();
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableFilter]);

  const [toggleModalForm, setToggleModalForm] = useState({
    show: false,
    data: null,
  });

  const { mutate: mutateCoupon, isLoading: isLoadingCoupon } = POST(
    "api/v1/delete_coupon_system",
    "get_coupons_system"
  );

  const deleteCoupon = (id, val) => {
    mutateCoupon(
      { id: id, status: val },
      {
        onSuccess: (res) => {
          if (res.success) {
            // console.log(res)
            notification.success({
              message: "Sucess",
              description: "Coupon Sucessfully Deleted",
            });
          }
        },
        onError: (err) => {
          console.log(err);
        },
      }
    );
  };

  function convertDollar(x) {
    return Number.parseFloat(x).toFixed(2);
  }

  return (
    <>
      <ComponentHeader title={permission} sub_title="VIEW" icon={faTag} />

      <Card className="card-min-height" id="PageSchool">
        <Row gutter={12}>
          <Col span={24} className="m-b-md m-t-sm">
            <Button
              className="btn-main-outline-2"
              icon={<PlusOutlined />}
              onClick={(e) => {
                setToggleModalForm({ ...toggleModalForm, show: true });
              }}
            >
              Add Coupon
            </Button>
          </Col>
          <Col xs={24} sm={24} md={16} lg={16} xl={18} className="m-b-sm">
            <Space>
              <TablePageSize
                paginationFilter={tableFilter}
                setPaginationFilter={setTableFilter}
              />
            </Space>
          </Col>
          <Col xs={24} sm={24} md={8} lg={8} xl={6}>
            <TableGlobalSearch
              paginationFilter={searchText}
              setPaginationFilter={setSearchText}
            />
          </Col>
        </Row>
        <Row gutter={12}>
          <Col xs={24} sm={24} md={24} className="m-t-sm m-b-sm">
            <Table
              className="ant-table-default ant-table-striped"
              dataSource={dataSource && dataSource}
              rowKey={(record) => record.id}
              loading={isLoadingTable || isFetchingTable}
              pagination={false}
              bordered={false}
              onChange={handleTableChange}
              scroll={{ x: "max-content" }}
            >
              <Table.Column
                key="coupon_code"
                title="Coupon Code"
                dataIndex="coupon_code"
                sorter={(a, b) => a.coupon_code.localeCompare(b.coupon_code)}
                defaultSortOrder="descend"
              />
              <Table.Column
                key="coupon_name"
                title="Coupon Name"
                dataIndex="coupon_name"
                sorter={(a, b) => a.coupon_name.localeCompare(b.coupon_name)}
              />

              <Table.Column
                key="off"
                title="Discount"
                dataIndex="off"
                sorter={(a, b) => a.off - b.off}
                render={(text, record) => {
                  return (
                    <div key={record.id}>
                      {record.type == "percent" && record.off + "%"}
                      {record.type == "fixed" &&
                        "$" + convertDollar(record.off) + " off"}
                      {record.type == "offer" && record.off + " days"}
                    </div>
                  );
                }}
                // sorter
              />
              <Table.Column
                key="duration_from"
                title="Duration"
                dataIndex="duration_from"
                sorter={(a, b) =>
                  a.duration_from.localeCompare(b.duration_from)
                }
                render={(text, record) => {
                  return (
                    <div key={record.id}>
                      {"" + record.duration_from + " - " + record.duration_to}
                    </div>
                  );
                }}
              />

              <Table.Column
                key="role"
                title="Account type"
                dataIndex="role"
                sorter={(a, b) => a.role.localeCompare(b.role)}
                render={(text, record) => {
                  return (
                    <div key={record.id}>
                      {JSON.parse(record.role).join(", ")}
                    </div>
                  );
                }}
              />
              <Table.Column
                key="redemption"
                title="Redemption"
                dataIndex="redemption"
                sorter={(a, b) => a.redemption - b.redemption}
                render={(text, record) => {
                  return (
                    <div key={record.id}>
                      {record.redemption
                        ? record.redemption + "/" + record.max
                        : record.redemption}
                    </div>
                  );
                }}
              />

              <Table.Column
                key="status"
                title="Status"
                dataIndex="status"
                sorter={(a, b) => a.status.localeCompare(b.status)}
              />

              <Table.Column
                key="use_once"
                title="Use Once"
                dataIndex="use_once"
                sorter={(a, b) => a.status.localeCompare(b.status)}
              />

              <Table.Column
                key="action"
                title="Action"
                // sorter
                render={(text, record) => {
                  return (
                    <Space>
                      <Button
                        size="small"
                        className="btn-warning-outline"
                        icon={<EditOutlined />}
                        onClick={(e) =>
                          setToggleModalForm({ data: record, show: true })
                        }
                      >
                        Edit
                      </Button>

                      {record.status === "active" ? (
                        <Popconfirm
                          placement="topRight"
                          title={
                            "Are you sure you want to deactivate this coupon?"
                          }
                          onConfirm={() => deleteCoupon(record.id, "deactive")}
                          okText="Yes"
                          cancelText="No"
                        >
                          <Button
                            size="small"
                            className="btn-main-outline-9"
                            icon={<CloseOutlined />}
                            style={{ width: 110, textAlign: "left" }}
                          >
                            Deactivate
                          </Button>
                        </Popconfirm>
                      ) : (
                        <Popconfirm
                          placement="topRight"
                          title={
                            "Are you sure you want to activate this coupon?"
                          }
                          onConfirm={() => deleteCoupon(record.id, "active")}
                          okText="Yes"
                          cancelText="No"
                        >
                          <Button
                            size="small"
                            className="btn-main-outline-7"
                            icon={<CheckOutlined />}
                            style={{ width: 110, textAlign: "left" }}
                          >
                            Active
                          </Button>
                        </Popconfirm>
                      )}
                    </Space>
                  );
                }}
              />
            </Table>
          </Col>
          <Col xs={24} sm={24} md={24}>
            <Space className="ant-space-flex-space-between">
              <TableShowingEntries />

              <TablePagination
                paginationFilter={tableFilter}
                setPaginationFilter={setTableFilter}
                setPaginationTotal={tableTotal}
                showLessItems={true}
                showSizeChanger={false}
              />
            </Space>
          </Col>
          <Col xs={24} sm={24} md={12}></Col>
        </Row>

        <br />
      </Card>

      <ModalAdd
        setToggleModalForm={setToggleModalForm}
        toggleModalForm={toggleModalForm}
      />

      {/* <ModalUpdate
        setToggleModalForm={setToggleModalForm}
        toggleModalForm={toggleModalForm}
      /> */}
    </>
  );
}
