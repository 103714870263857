import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  Col,
  Form,
  Modal,
  notification,
  Row,
  Space,
  Collapse,
  Table,
  Popconfirm,
  Typography,
  Input,
  Divider,
  Empty,
} from "antd";
import FloatInput from "../../../../providers/FloatInput";
import FloatSelect from "../../../../providers/FloatSelect";
import $ from "jquery";
import ComponentHeader from "../../Components/ComponentHeader";
import { faSchool } from "@fortawesome/pro-regular-svg-icons";
import {
  GET,
  GETMANUAL,
  POST,
  UPDATE,
} from "../../../../providers/useAxiosQuery";
import companyInfo from "../../../../providers/companyInfo";

import optionCountryCodes from "../../../../providers/optionCountryCodes";
import optionStateCodesUnitedState from "../../../../providers/optionStateCodesUnitedState";
import optionStateCodesCanada from "../../../../providers/optionStateCodesCanada";
import optionOrganizationType from "../../../../providers/optionOrganizationType";
import FloatAutoCompleteOrg from "../../../../providers/FloatAutoCompleteOrg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/pro-solid-svg-icons";
import {
  TablePagination,
  TableShowingEntries,
} from "../../Components/ComponentTableFilter";

const userdata = companyInfo().userData;

export default function PageOrganizationPendingApproval({ match, permission }) {
  const { Panel } = Collapse;

  // const [dataSource, setDataSource] = useState([]);
  const [stateUS, setStateUS] = useState(optionStateCodesUnitedState());
  const [stateCA, setStateCA] = useState(optionStateCodesCanada());
  const [dataPage, setDataPage] = useState([]);
  const [tableTotal, setTableTotal] = useState(0);
  const [tableFilter, setTableFilter] = useState({
    page: 1,
    page_size: 10,
  });

  useEffect(() => {
    refetchPage();
  }, [tableFilter]);

  const [getRowIndex, setGetRowIndex] = useState("");
  const [searchFilter, setSearchFilter] = useState([
    {
      search: "",
      country: null,
      state: null,
      stateOption: [],
      organizationOption: [],
    },
  ]);

  const { refetch: refetchPage, isLoading: isLoadingPage } = GETMANUAL(
    `api/v1/manager_dashboard_pending_list?${$.param(tableFilter)}`,
    "manager_dashboard_pending_list",
    (res) => {
      if (res.success) {
        let row = [];
        let row_search = [];
        res.data?.data.map((item, key) => {
          let member = item.athlete_organization.concat(
            item.coach_organization
          );

          // FOR FORM ORGANIZATION VALUE
          row.push({
            ...item,
            members: member,
          });

          // FOR FORM SEARCH FIELDS
          row_search.push({
            search: "",
            country: null,
            state: null,
            stateOption: [],
            organizationOption: [],
          });
        });
        setDataPage(row);
        setSearchFilter(row_search);
        setTableTotal(res.data.total);
        console.log("searchFilterData", row_search);
      }
    }
  );

  // SEARCH ORGANIZATION
  const [searchCountry, setSearchCountry] = useState([]);
  const [searchFilterData, setSearchFilterData] = useState({
    country: "",
    state: "",
    search: "",
  });

  const { data: dataManagerArea, isLoading: isLoadingManagerArea } = GET(
    "api/v1/get_manager_state_area_data",
    "get_manager_state_area",
    (res) => {
      if (res.success) {
        console.log("get_manager_state_area", res);
        if (userdata.role === "Manager") {
          setSearchCountry(res.countries);
          if (res.statesUS.length > 0) {
            let option_states = [];
            res.statesUS.map((row, key) => {
              stateUS.find((search) => {
                if (search.value == row.value) {
                  option_states.push({
                    ...row,
                    label: search.label,
                  });
                }
              });
            });
            setStateUS(option_states);
          }
          if (res.statesCA.length > 0) {
            let option_states = [];
            res.statesCA.map((row, key) => {
              stateCA.find((search) => {
                if (search.value == row.value) {
                  option_states.push({
                    ...row,
                    label: search.label,
                  });
                }
              });
            });
            setStateCA(option_states);
          }
        } else {
          setSearchCountry(optionCountryCodes);
        }
      }
    }
  );

  const onChangeSearchCountry = (val, opt, key) => {
    let row = [...searchFilter];
    if (val) {
      // SET VALUE SEARCH COUNTRY
      row[key] = {
        ...row[key],
        country: val,
        state: "",
        // search: "",
      };

      // SET CHANGE SEARCH STATE OPTIONS
      if (userdata.role === "Admin") {
        if (val === "United States") {
          row[key] = {
            ...row[key],
            stateOption: stateUS,
          };
        } else {
          row[key] = {
            ...row[key],
            stateOption: stateCA,
          };
        }
      } else {
        if (val === "United States") {
          row[key] = {
            ...row[key],
            stateOption: stateUS,
          };
        } else {
          row[key] = {
            ...row[key],
            stateOption: stateCA,
          };
        }
      }
    } else {
      // SET VALUE SEARCH COUNTRY
      row[key] = {
        ...row[key],
        country: val ? val : "",
        state: "",
        // search: "",
        stateOption: [],
      };
    }
    setSearchFilter(row);

    // SET VALUE SEARCH REQUEST
    setSearchFilterData({
      ...searchFilterData,
      country: val ? val : "",
      state: "",
      // search: "",
    });
    setGetRowIndex(key);
  };

  const onChangeSearchState = (val, opt, key) => {
    let row = [...searchFilter];
    if (val) {
      row[key] = {
        ...row[key],
        state: val,
        // search: "",
      };
    } else {
      row[key] = {
        ...row[key],
        state: val ? val : "",
        // search: "",
      };
    }
    setSearchFilter(row);

    // SET VALUE SEARCH REQUEST
    setSearchFilterData({
      ...searchFilterData,
      state: val ? val : "",
      // search: "",
    });
    setGetRowIndex(key);
  };

  const [searchText, setSearchText] = useState("");
  const [orgSelected, setOrgSelected] = useState();
  const onChangeSearchText = (val, opt, key) => {
    let row = [...searchFilter];
    if (val) {
      row[key] = {
        ...row[key],
        search: val,
      };

      // SELETED ROW
      let selected_row = opt["data-json"];
      setOrgSelected(selected_row);
    } else {
      row[key] = {
        ...row[key],
        search: val ? val : "",
        organizationOption: [],
      };
    }
    setSearchFilter(row);
    setSearchText(val);
    setGetRowIndex(key);
  };

  const { refetch: refetchSearch, isLoading: isLoadingSearch } = GETMANUAL(
    `api/v1/organization/search/get?${new URLSearchParams(searchFilterData)}`,
    "organization/search/get",
    (res) => {
      if (res.success) {
        let arr = [];
        res.data.map((item, key) => {
          arr.push({
            label: item.name,
            value: item.name,
            json: item,
          });
        });
        console.log("searchFilterData_row", arr);

        let opt = [...searchFilter];
        if (getRowIndex || getRowIndex === 0) {
          console.log("searchFilterData 1", getRowIndex);
          opt[getRowIndex] = {
            ...opt[getRowIndex],
            organizationOption: arr,
          };
          console.log("searchFilterData 1", opt);
        }
        setSearchFilter(opt);
      }
    }
  );

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setSearchFilterData({
        ...searchFilterData,
        search: searchText,
      });
    }, 1500);
    return () => {
      clearTimeout(timeoutId);
    };
  }, [searchText]);

  useEffect(() => {
    console.log("searchFilterData_row", searchFilterData);
    if (searchFilterData.search) {
      refetchSearch();
    }
  }, [searchFilterData.search]);

  const [isModalApprove, setIsModalApprove] = useState(false);
  const [isDataApprove, setIsDataApprove] = useState([]);
  const handleSelectedUse = (key) => {
    if (orgSelected) {
      let row = [...dataPage];
      row[key] = {
        ...row[key],
        country: orgSelected.country,
        organization_type: orgSelected.organization_type,
        state: orgSelected.state,
        name: orgSelected.name,
        address1: orgSelected.address1,
        address2: orgSelected.address2 ? orgSelected.address2 : undefined,
        city: orgSelected.city,
        zip: orgSelected.zip,
        // selected_id: orgSelected.id,
        // search_name: val,
      };

      let set_to_update = {
        id: row[key]?.id,
        country: orgSelected.country,
        organization_type: orgSelected.organization_type,
        state: orgSelected.state,
        name: orgSelected.name,
        address1: orgSelected.address1,
        address2: orgSelected.address2 ? orgSelected.address2 : undefined,
        city: orgSelected.city,
        zip: orgSelected.zip,
        selected_id: orgSelected.id,
      };
      console.log("orgSelected", set_to_update);
      setIsDataApprove(set_to_update);
      setDataPage(row);
    }
  };

  const approveOrganization = () => {
    let value = {
      ...isDataApprove,
      status: "Approved",
    };
    console.log("orgSelected", value);
    mutateApprove(value, {
      onSuccess: (res) => {
        if (res.success) {
          notification.success({
            message: "Success",
            description: "Successfully Approved",
          });
          setIsModalApprove(false);
        }
      },
    });
  };

  const { mutate: mutateApprove, isLoading: isLoadingApprove } = POST(
    "api/v1/approve_organization",
    "manager_dashboard_pending_list"
  );

  const removeOrganization = (organization_id) => {
    let data = {
      id: organization_id,
      status: "Deleted",
    };
    console.log("removeOrganization", data);

    mutateDeleteOrganization(data, {
      onSuccess: (res) => {
        if (res.success) {
          notification.success({
            message: "Success",
            description: "Successfully Deleted",
          });
          setIsModalApprove(false);
        }
      },
    });
  };

  const {
    mutate: mutateDeleteOrganization,
    isLoading: isLoadingDeleteOrganization,
  } = UPDATE("api/v1/organization", "manager_dashboard_pending_list");

  const [formEditOrganization] = Form.useForm();
  const [editModalVIew, setEditModalVIew] = useState(false);
  const [selectedOrganizationEdit, setSelectedOrganizationEdit] = useState();
  const handleEditOrganization = (record) => {
    setSelectedOrganizationEdit(record);
    formEditOrganization.setFieldsValue(record);

    if (record.country === "United States") {
      setStateLabel("State");
      setZipLabel("Zip Code");
      setOptionState(stateUS);
      setOptionZip(/(^\d{5}$)|(^\d{5}[ -]\d{4}$)/);

      let get_city = stateUS.filter((item) => record.state === item.value);
      setOptionCity(get_city[0]["json"]);
    } else if (record.country === "Canada") {
      setStateLabel("County");
      setZipLabel("Postal Code");
      setOptionState(stateCA);
      setOptionZip(/^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/);

      let get_city = stateCA.filter((item) => record.state === item.value);
      setOptionCity(get_city[0]["json"]);
    }
  };

  const [stateLabel, setStateLabel] = useState("State");
  const [zipLabel, setZipLabel] = useState("Zip Code");
  const [optionState, setOptionState] = useState([]);
  const [optionZip, setOptionZip] = useState(/(^\d{5}$)|(^\d{5}[ -]\d{4}$)/);
  const [optionCity, setOptionCity] = useState([]);

  const handleCountry = (val, opt) => {
    if (val === "United States") {
      setStateLabel("State");
      setZipLabel("Zip Code");
      setOptionState(stateUS);
      setOptionZip(/(^\d{5}$)|(^\d{5}[ -]\d{4}$)/);
    } else if (val === "Canada") {
      setStateLabel("County");
      setZipLabel("Postal Code");
      setOptionState(stateCA);
      setOptionZip(/^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/);
    }
    formEditOrganization.resetFields(["state", "city", "zip"]);
  };

  const handleEditState = (val, opt) => {
    if (val) {
      let opt_city = opt["data-json"];
      setOptionCity(opt_city);
    } else {
      setOptionCity([]);
    }
    formEditOrganization.resetFields(["city"]);
  };

  const { mutate: mutateEdit, isLoadingEdit } = POST(
    "api/v1/update_org",
    "manager_dashboard_pending_list"
  );

  const onFinishForm = (values) => {
    let data = {
      ...values,
      id: selectedOrganizationEdit.id,
      organizations: undefined,
    };
    console.log("onFinishForm", data);
    mutateEdit(data, {
      onSuccess: (res) => {
        if (res.success) {
          notification.success({
            message: "Success",
            description: "Successfully Updated",
          });
          setSelectedOrganizationEdit([]);
          setEditModalVIew(false);
        }
      },
    });
  };

  return (
    <>
      <ComponentHeader title="Approval" sub_title="PENDING" icon={faSchool} />

      <Card
        className="p-b-lg PageOrganizationPendingApproval"
        id="PageOrganizationPendingApproval"
        // loading={isLoadingPage || isLoadingManagerArea}
        loading={isLoadingPage}
      >
        <Collapse
          className="main-1-collapse"
          expandIcon={({ isActive }) =>
            isActive ? (
              <span
                className="ant-menu-submenu-arrow"
                style={{
                  color: "#FFF",
                  transform: "rotate(270deg)",
                }}
              ></span>
            ) : (
              <span
                className="ant-menu-submenu-arrow"
                style={{
                  color: "#FFF",
                  transform: "rotate(90deg)",
                }}
              ></span>
            )
          }
          defaultActiveKey={[0]}
          expandIconPosition="start"
        >
          {dataPage &&
            dataPage.length > 0 &&
            dataPage.map((item, key) => {
              return (
                <Panel
                  header="ORGANIZATION APPROVAL"
                  key={key}
                  className="accordion bg-darkgray-form m-b-md"
                  extra={
                    <Popconfirm
                      title="Are you sure to delete this organization?"
                      onConfirm={() => {
                        removeOrganization(item.id);
                      }}
                      okText="Confirm"
                      cancelText="Cancel"
                      loading={isLoadingDeleteOrganization}
                    >
                      <FontAwesomeIcon
                        icon={faTrashAlt}
                        style={{ fontSize: "18px" }}
                      />
                    </Popconfirm>
                  }
                >
                  <Row gutter={12}>
                    <Col xs={24} sm={24} md={16}>
                      <Row gutter={12}>
                        <Col className="m-b-md" xs={24} sm={24} md={8}>
                          <FloatInput
                            label="Country"
                            placeholder="Country"
                            disabled={true}
                            required
                            value={item?.country}
                          />
                        </Col>

                        <Col className="m-b-md" xs={24} sm={24} md={8}>
                          <FloatInput
                            label="Type"
                            placeholder="Type"
                            disabled={true}
                            required
                            value={item?.organization_type}
                          />
                        </Col>

                        <Col className="m-b-md" xs={24} sm={24} md={8}>
                          <FloatInput
                            label="State / County"
                            placeholder="State / County"
                            disabled={true}
                            required
                            value={item?.state}
                          />
                        </Col>

                        <Col className="m-b-md" xs={24} sm={24} md={24}>
                          <FloatInput
                            label="Organization Name"
                            placeholder="Organization Name"
                            disabled={true}
                            required
                            value={item?.name}
                          />
                        </Col>

                        <Col className="m-b-md" xs={24} sm={24} md={24}>
                          <FloatInput
                            label="Address 1"
                            placeholder="Address 1"
                            disabled={true}
                            required
                            value={item?.address1}
                          />
                        </Col>

                        <Col className="m-b-md" xs={24} sm={24} md={24}>
                          <FloatInput
                            label="Address 2"
                            placeholder="Address 2"
                            disabled={true}
                            value={item?.address2}
                          />
                        </Col>

                        <Col className="m-b-md" xs={24} sm={24} md={8}>
                          <FloatInput
                            label="City"
                            placeholder="City"
                            disabled={true}
                            required
                            value={item?.city}
                          />
                        </Col>

                        <Col className="m-b-md" xs={24} sm={24} md={8}>
                          <FloatInput
                            label="Zip / Postal Code"
                            placeholder="Zip / Postal Code"
                            disabled={true}
                            required
                            value={item?.zip}
                          />
                        </Col>
                      </Row>
                    </Col>
                    <Col xs={24} sm={24} md={8}>
                      <Row gutter={12}>
                        <Col
                          className="m-b-md"
                          xs={24}
                          sm={24}
                          md={24}
                          style={{ marginTop: 2 }}
                        >
                          <div
                            className="form-select-error"
                            style={{ paddingBottom: 17 }}
                          >
                            <FloatSelect
                              label="Country"
                              placeholder="Country"
                              options={searchCountry}
                              onChange={(val, opt) =>
                                onChangeSearchCountry(val, opt, key)
                              }
                              value={
                                searchFilter.length > 0 &&
                                searchFilter[key]?.country
                              }
                              allowClear={true}
                            />
                          </div>
                        </Col>

                        <Col
                          className="m-b-md"
                          xs={24}
                          sm={24}
                          md={24}
                          style={{ marginTop: 2 }}
                        >
                          <div
                            className="form-select-error"
                            style={{ paddingBottom: 16 }}
                          >
                            <FloatSelect
                              label="State / County"
                              placeholder="State / County"
                              options={
                                searchFilter.length > 0 &&
                                searchFilter[key]?.stateOption
                                  ? searchFilter[key]?.stateOption
                                  : []
                              }
                              onChange={(val, opt) =>
                                onChangeSearchState(val, opt, key)
                              }
                              value={
                                searchFilter.length > 0 &&
                                searchFilter[key]?.state
                              }
                              allowClear={true}
                            />
                          </div>
                        </Col>

                        <Col className="m-b-md" xs={24} sm={24} md={24}>
                          <FloatAutoCompleteOrg
                            label="Search"
                            placeholder="Search"
                            options={
                              searchFilter.length > 0 &&
                              searchFilter[key]?.organizationOption
                                ? searchFilter[key]?.organizationOption
                                : []
                            }
                            allowClear={true}
                            value={
                              searchFilter.length > 0 &&
                              searchFilter[key]?.search
                            }
                            onChange={(val, opt) =>
                              onChangeSearchText(val, opt, key)
                            }
                            // loadingSearch={isLoadingSearch}
                          />
                        </Col>

                        <Col className="m-b-md" xs={24} sm={24} md={24}>
                          <Space>
                            <Button
                              className="btn-main-outline-9"
                              size="small"
                              onClick={(e) => {
                                let row = [...searchFilter];

                                row[key] = {
                                  ...row[key],
                                  search: "",
                                  country: null,
                                  state: null,
                                  stateOption: [],
                                  organizationOption: [],
                                };

                                setSearchFilter(row);
                                setGetRowIndex("");
                                setSearchText("");
                                setOrgSelected();
                                refetchPage();
                              }}
                            >
                              RESET
                            </Button>

                            <Button
                              className="btn-main-outline-2"
                              size="small"
                              // disabled={orgSelected ? false : true}
                              onClick={(e) => handleSelectedUse(key)}
                            >
                              USE
                            </Button>
                          </Space>
                        </Col>
                      </Row>
                    </Col>
                  </Row>

                  <Row gutter={12}>
                    <Col span={24}>
                      <Table
                        dataSource={item && item.members}
                        bordered
                        rowKey={(record) => record.name}
                        scroll={{ x: "max-content" }}
                        pagination={false}
                      >
                        <Table.Column
                          title="Name"
                          key="name"
                          dataIndex="name"
                          width={"150px"}
                        />
                        <Table.Column
                          title="Role"
                          key="role"
                          dataIndex="role"
                          width={"150px"}
                        />
                        <Table.Column
                          title="State"
                          key="state"
                          dataIndex="state"
                          width={"150px"}
                        />
                        <Table.Column
                          title="City"
                          key="city"
                          dataIndex="city"
                          width={"150px"}
                        />
                      </Table>
                    </Col>
                  </Row>

                  <Row gutter={24} className="m-b-md m-t-md">
                    <Col xs={24} sm={24} md={12}>
                      <Space>
                        <Button
                          className="btn-main-outline-7"
                          size="large"
                          onClick={(e) => {
                            setIsDataApprove({
                              id: item.id,
                              country: item.country,
                              organization_type: item.organization_type,
                              state: item.state,
                              name: item.name,
                              address1: item.address1,
                              address2: item.address2
                                ? item.address2
                                : undefined,
                              city: item.city,
                              zip: item.zip,
                            });
                            setIsModalApprove(true);
                          }}
                        >
                          APPROVE
                        </Button>

                        <Button
                          className="btn-main-outline-2"
                          size="large"
                          // onClick={(e) => handleEdit(keu=y)}
                          onClick={(e) => {
                            setEditModalVIew(true);
                            handleEditOrganization(item);
                          }}
                        >
                          EDIT
                        </Button>
                      </Space>
                    </Col>
                  </Row>
                </Panel>
              );
            })}

          {dataPage && dataPage.length === 0 && (
            <Row gutter={24}>
              <Col xs={24} sm={24} md={24}>
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
              </Col>
            </Row>
          )}
        </Collapse>

        <Row gutter={24}>
          <Col xs={24} sm={24} md={24} className="m-b-md"></Col>

          <Col xs={24} sm={24} md={24}>
            <Space className="ant-space-flex-space-between">
              <TableShowingEntries />

              <TablePagination
                paginationFilter={tableFilter}
                setPaginationFilter={setTableFilter}
                setPaginationTotal={tableTotal}
                showLessItems={true}
                showSizeChanger={false}
              />
            </Space>
          </Col>
        </Row>

        <Modal
          title="APPROVE ORGANIZATION"
          visible={isModalApprove}
          onCancel={(e) => setIsModalApprove(false)}
          footer={[
            <Space key={"yes"}>
              <Button
                key="btn"
                loading={isLoadingApprove}
                onClick={(e) => approveOrganization()}
                className="btn-main-outline-2"
              >
                YES, APPROVE
              </Button>
            </Space>,
          ]}
        >
          <p>
            You are about to approve this organization and add it to the system,
            which CAN NOT be undone. Are you sure you have checked the spelling
            and did a search for this organization in the system before
            continuing?
          </p>
        </Modal>

        <Modal
          title="EDIT ORGANIZATION"
          visible={editModalVIew}
          onCancel={(e) => setEditModalVIew(false)}
          okText="UPDATE"
          width={800}
          // cancelText="CANCEL"
          footer={[
            <Button
              key={"edye"}
              htmlType="submit"
              className="btn-main-outline-2"
              loading={isLoadingEdit}
              onClick={() => {
                formEditOrganization
                  .validateFields()
                  .then((values) => {
                    onFinishForm(values);
                  })
                  .catch((info) => {
                    console.log("Validate Failed:", info);
                  });
              }}
            >
              UPDATE
            </Button>,
          ]}
        >
          <Form form={formEditOrganization} name="edit-form" layout="vertical">
            <Row gutter={24}>
              <Col xs={24} sm={24} md={8}>
                <Form.Item
                  name={"organization_type"}
                  className="form-select-error"
                  rules={[
                    {
                      required: true,
                      message: "This field field is required.",
                    },
                  ]}
                >
                  <FloatSelect
                    label="Type"
                    placeholder="Type"
                    options={optionOrganizationType}
                    allowClear={true}
                    required
                  />
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} md={8}>
                <Form.Item
                  name="country"
                  className="form-select-error"
                  rules={[
                    {
                      required: true,
                      message: "This field field is required.",
                    },
                  ]}
                >
                  <FloatSelect
                    label="Country"
                    placeholder="Country"
                    options={searchCountry}
                    onChange={handleCountry}
                    required
                  />
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} md={8}>
                <Form.Item
                  name={"state"}
                  className="form-select-error"
                  rules={[
                    {
                      required: true,
                      message: "This field field is required.",
                    },
                  ]}
                >
                  <FloatSelect
                    label={stateLabel}
                    placeholder={stateLabel}
                    options={optionState}
                    onChange={handleEditState}
                    allowClear={true}
                    required
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={24}>
              <Col xs={24} sm={24} md={24}>
                <Form.Item
                  name={"name"}
                  rules={[
                    {
                      required: true,
                      message: "This field field is required.",
                    },
                  ]}
                >
                  <FloatInput
                    label="Organization Name"
                    placeholder="Organization Name"
                    required
                  />
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} md={24}>
                <Form.Item
                  name={"address1"}
                  rules={[
                    {
                      required: true,
                      message: "This field field is required.",
                    },
                  ]}
                >
                  <FloatInput
                    label="Address 1"
                    placeholder="Address 1"
                    required
                  />
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} md={24}>
                <Form.Item name={"address2"}>
                  <FloatInput label="Address 1" placeholder="Address 1" />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={24}>
              <Col xs={24} sm={24} md={8}>
                <Form.Item
                  name={"city"}
                  className="form-select-error"
                  rules={[
                    {
                      required: true,
                      message: "This field field is required.",
                    },
                  ]}
                >
                  <FloatSelect
                    label="City"
                    placeholder="City"
                    options={optionCity}
                    allowClear={true}
                    required
                  />
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} md={8}>
                <Form.Item
                  name={"zip"}
                  rules={[
                    {
                      required: true,
                      message: "This field field is required.",
                    },
                    {
                      pattern: optionZip,
                      message: "Invalid " + zipLabel,
                    },
                  ]}
                >
                  <FloatInput
                    label={zipLabel}
                    placeholder={zipLabel}
                    required
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Modal>
      </Card>
    </>
  );
}
