import {
  Button,
  Col,
  Form,
  Modal,
  notification,
  Row,
  Space,
  Table,
} from "antd";
import React, { useState, useEffect } from "react";
// import { faHandsUsd } from "@fortawesome/pro-regular-svg-icons";
import {
  TableGlobalSearch,
  TablePageSize,
  TablePagination,
  TableShowingEntries,
} from "../../Components/ComponentTableFilter";
import $ from "jquery";
// import { useHistory } from "react-router-dom";
import { GET, GETMANUAL, POST } from "../../../../providers/useAxiosQuery";
import { faSave } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faUser } from "@fortawesome/pro-regular-svg-icons";
import FloatSelect from "../../../../providers/FloatSelect";
import FloatInput from "../../../../providers/FloatInput";
import { values } from "pdf-lib";
import FloatDatePickerForm from "../../../../providers/FloatDatePickerForm";
import FloatTimePicker from "../../../../providers/FloatTimePicker";
import { Link } from "react-router-dom";
// import companyInfo from "../../../../providers/companyInfo";

import ModalPendingAssessmentPreview from "../../PageManager/PagePendingAssessments/ModalPendingAssessmentPreview";
import ModalPendingAssessmentPreviewElite from "../../PageManager/PagePendingAssessments/ModalPendingAssessmentPreviewElite";

export default function PageUserCreateAccountAthleteAssessment({
  athleteData,
}) {
  const athlete_id = athleteData && athleteData.id;
  const [tableFilter, setTableFilter] = useState({
    page: 1,
    page_size: 50,
    search: "",
    sort_field: "strdate",
    sort_order: "desc",
    from: "pending_assessments",
  });

  const [tableTotal, setTableTotal] = useState(0);
  const [dataSource, setDataSource] = useState([]);

  const refetchTable = (id) => {
    let data = {
      ...tableFilter,
      athlete_id: id,
    };
    mutateGet(data, {
      onSuccess: (res) => {
        if (res.success) {
          // console.log("athlete_assessment", res);
          setDataSource(res.data && res.data.data);
          setTableTotal(res.data.total);
        }
      },
    });
  };

  const {
    mutate: mutateGet,
    isLoading: isLoadingTable,
    isFetching: isFetchingTable,
  } = POST("api/v1/get_athlete_assessment", "athlete_assessment");

  const handleTableChange = (pagination, filters, sorter) => {
    setTableFilter({
      ...tableFilter,
      sort_field: sorter.columnKey,
      sort_order: sorter.order ? sorter.order.replace("end", "") : null,
      // page: 1,
    });
  };

  const [searchText, setSearchText] = useState("");
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setTableFilter({
        ...tableFilter,
        search: searchText,
        athlete_id: athlete_id,
        page: 1,
      });
    }, 1500);
    return () => {
      clearTimeout(timeoutId);
    };
  }, [searchText]);

  const [modal, setModal] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    if (modal === false) {
      form.resetFields();
    }
  }, [modal]);

  useEffect(() => {
    if (athlete_id) {
      refetchTable(athlete_id);
    }
    return () => {};
  }, [tableFilter, athlete_id]);

  const onFinishForm = (values) => {
    let data = {
      ...values,
      date: values.date.format("YYYY-MM-DD"),
      time_start: values.time_start.format("HH:mm"),
      time_end: values.time_end.format("HH:mm"),
      athlete_id: athlete_id,
    };

    mutateAdd(data, {
      onSuccess: (res) => {
        if (res.success) {
          setModal(false);
          form.resetFields();
          notification.success({
            message: "Success",
            description: "Successfully Submitted",
          });
          refetchTable(athlete_id);
        }
      },
    });
  };

  const { mutate: mutateAdd, isLoading: isLoadingAdd } = POST(
    "api/v1/add_assessment",
    "athlete_assessment"
  );

  const [toggleModalPreview, setToggleModalPreview] = useState({
    show: false,
    data: null,
    type: "Starter",
  });

  const [reLoad, setReLoad] = useState(false);
  useEffect(() => {
    // console.log("reLoad", localStorage.getItem("reload"));
  }, localStorage.getItem("reload"));

  const [selectionType, setSelectionType] = useState([]);
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      let row = [];
      selectedRows?.map((item, key) => {
        row.push(item.event_id);
      });
      setSelectionType(row);
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User",
      name: record.name,
    }),
  };

  const [managerList, setManagerList] = useState([]);
  GET("api/v1/get_all_manager", "get_all_manager", (res) => {
    if (res.success) {
      console.log("get_all_manager", res);
      let row = [];
      res?.data?.map((item, key) => {
        row.push({
          label: `${item.firstname} ${item.lastname}`,
          value: item.id,
          json: item,
        });
      });

      setManagerList(row);
    }
  });

  const [modalAssignVisible, setModalAssignVisible] = useState(false);
  const [formAssign] = Form.useForm();
  const handleAssignFInish = (values) => {
    values = {
      ...values,
      event_ids: selectionType,
    };
    console.log("handleAssignFInish", values);

    mutateAssign(values, {
      onSuccess: (res) => {
        if (res.success) {
          console.log("handleAssignFInish", res);
          notification.success({
            message: "Success",
            description: "Successfully assigned!",
          });
        }
      },
    });
  };

  const { mutate: mutateAssign, isLoading: isLoadingAssign } = POST(
    "api/v1/assign_assessment",
    "athlete_assessment"
  );

  return (
    <>
      <Row gutter={24} className="m-b-md">
        <Col xs={24} sm={24} md={8} lg={8} xl={5} className="m-b-sm">
          <Button
            type="primary"
            className="btn-main-outline-2"
            size="large"
            block
            onClick={(e) => setModal(true)}
          >
            <FontAwesomeIcon icon={faPlus} className="m-r-xs" /> Add Assessment
          </Button>
        </Col>
        {selectionType.length > 0 && (
          <Col xs={24} sm={24} md={8} lg={8} xl={4} className="m-b-sm">
            <Button
              type="primary"
              className="btn-main-outline-2"
              size="large"
              block
              onClick={(e) => setModalAssignVisible(true)}
            >
              <FontAwesomeIcon icon={faUser} className="m-r-xs" /> Assign
              Manager
            </Button>
          </Col>
        )}
      </Row>
      <Row gutter={12}>
        <Col xs={24} sm={24} md={16} lg={16} xl={18} className="m-b-sm">
          <Space>
            <TablePageSize
              paginationFilter={tableFilter}
              setPaginationFilter={setTableFilter}
            />
          </Space>
        </Col>
        <Col xs={24} sm={24} md={8} lg={8} xl={6}>
          <TableGlobalSearch
            paginationFilter={searchText}
            setPaginationFilter={setSearchText}
          />
        </Col>
      </Row>
      <Row gutter={24}>
        <Col xs={24} sm={24} md={24} className="m-t-sm m-b-sm">
          <Table
            dataSource={dataSource && dataSource}
            rowKey={(record) => record.id}
            loading={isLoadingTable || isFetchingTable}
            pagination={false}
            bordered={false}
            onChange={handleTableChange}
            className="ant-table-default ant-table-striped"
            scroll={{ x: "max-content" }}
            // rowSelection={{
            //   type: selectionType,
            //   ...rowSelection,
            // }}
          >
            <Table.Column
              title="Date"
              key="strdate"
              dataIndex="strdate"
              defaultSortOrder="descend"
              sorter={true}
              width={"150px"}
            />
            <Table.Column
              title="Name"
              key="name"
              dataIndex="name"
              sorter={true}
              render={(text, record) => {
                return (
                  <Link
                    to="#"
                    className="companycolor1 cursor"
                    onClick={() =>
                      setToggleModalPreview({
                        show: true,
                        data: record,
                        type: record.type,
                      })
                    }
                  >
                    {record.name}
                  </Link>
                );
              }}
              width={"200px"}
            />
            <Table.Column
              title="Type"
              key="type"
              dataIndex="type"
              sorter={true}
              width={"100px"}
            />
            <Table.Column
              title="Organization"
              key="organization"
              dataIndex="organization"
              sorter={true}
              width={"200px"}
            />
            <Table.Column
              title="Status"
              key="status"
              dataIndex="status"
              sorter={true}
              width={"150px"}
            />
            <Table.Column
              title="State"
              key="state"
              dataIndex="state"
              sorter={true}
              width={"100px"}
            />
            <Table.Column
              title="City"
              key="city"
              dataIndex="city"
              sorter={true}
              width={"100px"}
            />
          </Table>
        </Col>
        <Col xs={24} sm={24} md={24}>
          <Space className="ant-space-flex-space-between table-paid">
            <TableShowingEntries />

            <TablePagination
              paginationFilter={tableFilter}
              setPaginationFilter={setTableFilter}
              setPaginationTotal={tableTotal}
              showLessItems={true}
              showSizeChanger={false}
              parentClass="table-paid"
            />
          </Space>
        </Col>
      </Row>

      <Modal
        visible={modal}
        className="assessment-modal"
        title="Add Assessment"
        width={600}
        onCancel={(e) => setModal(false)}
        bodyStyle={{ padding: "15px" }}
        footer={
          <Button
            type="primary"
            className="btn-main-outline-2"
            size="large"
            onClick={(e) => {
              form
                .validateFields()
                .then((values) => {
                  onFinishForm(values);
                })
                .catch((info) => {
                  console.log("Validate Failed:", info);
                });
            }}
          >
            Submit
          </Button>
        }
      >
        <Form form={form}>
          <Row gutter={12}>
            <Col xs={24} sm={24} md={24}>
              <Form.Item
                name={"type"}
                className="form-select-error"
                rules={[
                  {
                    required: true,
                    message: "This field is required.",
                  },
                ]}
              >
                <FloatSelect
                  label="Assessment Type"
                  placeholder="Assessment Type"
                  options={[
                    {
                      label: "Starter",
                      value: "Starter",
                    },
                    {
                      label: "Professional",
                      value: "Professional",
                    },
                    {
                      label: "Elite",
                      value: "Elite",
                    },
                  ]}
                  required
                />
              </Form.Item>
            </Col>

            {/* <Col xs={24} sm={24} md={12}>
              <Form.Item name={"title"}>
                <FloatInput
                  label="Assessment Title"
                  placeholder="Assessment Title"
                />
              </Form.Item>
            </Col> */}

            <Col xs={24} sm={24} md={24}>
              <Form.Item
                name={"date"}
                rules={[
                  {
                    required: true,
                    message: "This field is required.",
                  },
                ]}
              >
                <FloatDatePickerForm
                  label="Date"
                  placeholder="Date"
                  format={"MM/DD/YYYY"}
                  required
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12}>
              <Form.Item
                name={"time_start"}
                rules={[
                  {
                    required: true,
                    message: "This field is required.",
                  },
                ]}
              >
                <FloatTimePicker
                  label="Start Time"
                  placeholder="Start Time"
                  mode
                  required
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12}>
              <Form.Item
                name={"time_end"}
                rules={[
                  {
                    required: true,
                    message: "This field is required.",
                  },
                ]}
              >
                <FloatTimePicker
                  label="End Time"
                  placeholder="End Time"
                  mode
                  required
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>

      {toggleModalPreview.type === "Starter" ||
      toggleModalPreview.type === "Professional" ? (
        <>
          <ModalPendingAssessmentPreview
            toggleModalPreview={toggleModalPreview}
            setToggleModalPreview={setToggleModalPreview}
            setReLoad={setReLoad}
            refetchTable={(e) => refetchTable(athlete_id)}
          />
        </>
      ) : (
        <>
          <ModalPendingAssessmentPreviewElite
            toggleModalPreview={toggleModalPreview}
            setToggleModalPreview={setToggleModalPreview}
            setReLoad={setReLoad}
            refetchTable={(e) => refetchTable(athlete_id)}
          />
        </>
      )}
      <br />

      <Modal
        visible={modalAssignVisible}
        title={"Assign To Manager"}
        onCancel={(e) => setModalAssignVisible(false)}
        footer={
          <Space>
            <Button
              type="primary"
              className="btn-main-outline-2"
              size="large"
              onClick={(e) => {
                formAssign
                  .validateFields()
                  .then((values) => {
                    handleAssignFInish(values);
                  })
                  .catch((info) => {
                    console.log("Validate Failed:", info);
                  });
              }}
            >
              Submit
            </Button>
          </Space>
        }
      >
        <Form form={formAssign}>
          <Form.Item name={"user_id"}>
            <FloatSelect
              label="Manager"
              placeholder="Manager"
              options={managerList}
              required
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}
