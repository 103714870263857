import React, { useState, useEffect } from "react";
import {
  Layout,
  Button,
  Row,
  Col,
  Table,
  Pagination,
  Modal,
  Form,
  Space,
  notification,
  Card,
} from "antd";
import { PlusOutlined, TableOutlined, EditOutlined } from "@ant-design/icons";
import $ from "jquery";
import { useHistory } from "react-router-dom";
import FloatSelect from "../../../providers/FloatSelect";
import FloatInput from "../../../providers/FloatInput";
import ComponentHeader from "../Components/ComponentHeader";
import { GET, POST } from "../../../providers/useAxiosQuery";
import FloatTextArea from "../../../providers/FloatTextArea";
import { faLightbulbOn } from "@fortawesome/pro-regular-svg-icons";
import {
  TableGlobalSearch,
  TablePageSize,
  TablePagination,
  TableShowingEntries,
} from "../Components/ComponentTableFilter";

export default function PageNotification({ match, permission, title }) {
  const sub_title = "View All";
  const history = useHistory();
  const [form] = Form.useForm();

  const [tableFilter, setTableFilter] = useState({
    page: 1,
    page_size: 50,
    search: "",
    sort_field: "id",
    sort_order: "desc",
    status: [],
  });

  const [currentPage, setCurrentPage] = useState(1);
  const [currentSize, setCurrentSize] = useState(50);
  const [searchText, setSearchText] = useState("");
  const [tableTotal, setTableTotal] = useState(0);
  const [dataSource, setDataSource] = useState([]);
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setTableFilter({
        ...tableFilter,
        search: searchText,
        page: 1,
      });
    }, 1500);
    return () => {
      clearTimeout(timeoutId);
    };
  }, [searchText]);

  const {
    data: dataTickets,
    refetch: refetchTickets,
    isLoading: isLoadingTickets,
    isFetching: isFetchingTickets,
  } = GET(
    `api/v1/notification?${$.param(tableFilter)}`,
    `notification`,
    (res) => {
      if (res.success) {
        //   console.log(res);
        setDataSource(res.data && res.data.data);
        setTableTotal(res.data.total);
      }
    }
  );

  useEffect(() => {
    refetchTickets();
    return () => {};
  }, [tableFilter]);

  const onChange = (current, pageSize) => {
    setTableFilter({
      ...tableFilter,
      page: current,
      page_size: pageSize,
    });
    setCurrentSize(pageSize);
    setCurrentPage(current);
    // console.log("current", current);
    // console.log("pageSize", pageSize);
  };

  const [showModalNew, setShowModalNew] = useState(false);
  const handleCancel = () => {
    setShowModalNew(false);
    setSearchFor("");
    setMemberOption([]);
    form.resetFields();
  };

  const onFinishForm = (values) => {
    let data = {
      ...values,
      user_id: memberOption && memberOption.id,
      old_type: oldData && oldData.old_type,
      // old_search_for: oldData && oldData.old_search_for,
    };
    console.log("onFinishForm", data);
    mutateNotification(data, {
      onSuccess: (res) => {
        if (res.success) {
          console.log(res);
          notification.success({
            message: "Success",
            description: "Successfully submited",
          });
          form.resetFields();
          setMemberOption([]);
          setSearchFor("");
          setShowModalNew(false);
        }
      },
    });
  };

  const { mutate: mutateNotification, isLoading: isLoadingNotification } = POST(
    "api/v1/notification",
    "notification"
  );

  const [oldData, setOldData] = useState();
  const handleEdit = (record) => {
    console.log("record", record);
    let type =
      record.search_for === "User" ? JSON.parse(record.type) : record.type;
    form.setFieldsValue({
      id: record.id,
      title: record.title,
      type: type,
      search_for: record.search_for,
      description: record.description,
    });
    setSearchFor(record.search_for);
    setShowModalNew(true);
    setOldData({
      old_type: type,
      old_search_for: type,
    });
    // if (record.search_for === "User") {
    //   setMemberOption({ id: record.user_notification[0].user_id });
    // }
  };

  const [role] = useState([
    {
      label: "All",
      value: "All",
    },
    {
      label: "Athlete",
      value: "Athlete",
    },
    {
      label: "Athlete Guardian",
      value: "Athlete Guardian",
    },
    {
      label: "Assessor",
      value: "Assessor",
    },
    {
      label: "Coach",
      value: "Coach",
    },
    {
      label: "Manager",
      value: "Manager",
    },
  ]);

  const [company, setcompany] = useState([]);
  const {} = GET("api/v1/company_option", "company_option", (res) => {
    if (res.success) {
      // console.log("company_option", res);
      let arr = [];
      res.data &&
        res.data.map((row, index) => {
          arr.push({
            label: row.name,
            value: row.id,
          });
        });
      setcompany(arr);
    }
  });

  const [member, setmember] = useState([]);
  const {} = GET("api/v1/member_options", "member_options", (res) => {
    if (res.success) {
      console.log("member_options", res);
      let arr = [];
      res.data &&
        res.data.map((row, index) => {
          let val =
            row.role
              .match(/(\b\S)?/g)
              .join("")
              .match(/(^\S|\S$)?/g)
              .join("")
              .toUpperCase() +
            " - " +
            row.firstname +
            " " +
            row.lastname;
          arr.push({
            value: row.id,
            label: val,
            json: row,
          });
        });
      setmember(arr);
    }
  });

  const [searchFor, setSearchFor] = useState();
  const handleSearchFor = (val, opt) => {
    // console.log("handleSearchFor", opt);
    setSearchFor(val);
    setMemberOption([]);
    form.resetFields(["type"]);
  };

  const [memberOption, setMemberOption] = useState([]);
  const handleMember = (val, opt) => {
    console.log("handleSearchFor", opt);
    setMemberOption(opt["data-json"]);
  };

  return (
    <>
      <ComponentHeader title={title} sub_title="View" icon={faLightbulbOn} />

      <Card className="card-min-height" id="PageFaqs">
        <br></br>
        <Row>
          <Col md={8} xs={8}>
            <Button
              // size="large"
              className="btn-main-outline-2 m-t-n-md"
              style={{ width: 200 }}
              icon={<PlusOutlined />}
              htmlType="submit"
              onClick={(e) => setShowModalNew(true)}
            >
              Add Notification
            </Button>
          </Col>
        </Row>
        <br></br>
        <Row gutter={12} className="m-b-sm">
          <Col xs={24} sm={24} md={16} lg={16} xl={18} className="m-b-sm">
            <Space>
              <TablePageSize
                paginationFilter={tableFilter}
                setPaginationFilter={setTableFilter}
              />
            </Space>
          </Col>
          <Col xs={24} sm={24} md={8} lg={8} xl={6}>
            <TableGlobalSearch
              paginationFilter={searchText}
              setPaginationFilter={setSearchText}
            />
          </Col>
        </Row>
        <Table
          className="ant-table-default ant-table-striped"
          size="small"
          rowKey={(record) => record.id}
          loading={isLoadingTickets}
          dataSource={dataSource}
          pagination={false}
          scroll={{ x: "max-content" }}
        >
          <Table.Column
            key="title"
            title="Title"
            dataIndex="title"
            defaultSortOrder="descend"
            sorter={(a, b) => a.title.localeCompare(b.title)}
          />
          <Table.Column
            key="description"
            title="Description"
            dataIndex="description"
            sorter={(a, b) => a.description.localeCompare(b.description)}
          />
          <Table.Column
            key="search_for"
            title="Type"
            dataIndex="search_for"
            sorter={(a, b) => a.search_for.localeCompare(b.search_for)}
          />
          <Table.Column
            key="created_str"
            title="Created"
            dataIndex="created_str"
            sorter={(a, b) => a.created_str.localeCompare(b.created_str)}
          />
          <Table.Column
            key="action"
            title="Action"
            render={(text, record) => {
              return (
                <Space>
                  {/* <Button
                      size="small"
                      className="btn-primary"
                      icon={<EyeOutlined />}
                      onClick={(e) =>
                        history.push("/admin/account-types/view/" + record.id)
                      }
                    >
                      View
                    </Button> */}
                  <Button
                    size="small"
                    className="btn-warning-outline"
                    icon={<EditOutlined />}
                    onClick={(e) => handleEdit(record)}
                  >
                    Edit
                  </Button>
                </Space>
              );
            }}
          />
        </Table>
        <Space className="ant-space-flex-space-between table1 m-t-sm">
          <TableShowingEntries />

          <TablePagination
            paginationFilter={tableFilter}
            setPaginationFilter={setTableFilter}
            setPaginationTotal={tableTotal}
            showLessItems={true}
            showSizeChanger={false}
          />
        </Space>
      </Card>

      <Modal
        visible={showModalNew}
        className="modal-login"
        title="Notification Form"
        okText="Submit"
        cancelText="Cancel"
        width={700}
        onCancel={handleCancel}
        footer={[
          <Space>
            <Button
              onClick={() => {
                handleCancel();
              }}
            >
              Cancel
            </Button>
            <Button
              className="btn-main-outline-2"
              onClick={() => {
                form
                  .validateFields()
                  .then((values) => {
                    onFinishForm(values);
                  })
                  .catch((info) => {
                    console.log("Validate Failed:", info);
                  });
              }}
              loading={isLoadingNotification}
            >
              Submit
            </Button>
          </Space>,
        ]}
      >
        <Form
          form={form}
          name="panlistnew"
          layout="vertical"
          // initialValues={{ search_for: "Role" }}
        >
          <Form.Item name="id" style={{ display: "none" }}>
            <FloatInput label="id" placeholder="id" />
          </Form.Item>
          <Row gutter={24}>
            <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
              <Form.Item
                name="search_for"
                rules={[{ required: true, message: "required!" }]}
                className="form-select-error"
              >
                <FloatSelect
                  label="Search For"
                  placeholder="Search For"
                  onChange={handleSearchFor}
                  options={[
                    {
                      label: "Role",
                      value: "Role",
                    },
                    {
                      label: "Organization",
                      value: "Organization",
                    },
                    {
                      label: "User",
                      value: "User",
                    },
                  ]}
                  required
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={16} lg={16} xl={16} xxl={16}>
              {searchFor === "Role" && (
                <Form.Item
                  name="type"
                  rules={[{ required: true, message: "required!" }]}
                  className="form-select-error"
                >
                  <FloatSelect
                    label="Account Type"
                    placeholder="Account Type"
                    options={role}
                    required
                  />
                </Form.Item>
              )}
              {searchFor === "Organization" && (
                <Form.Item
                  name="type"
                  rules={[{ required: true, message: "required!" }]}
                  className="form-select-error"
                >
                  <FloatSelect
                    label="Organization"
                    placeholder="Organization"
                    options={company}
                    required
                  />
                </Form.Item>
              )}
              {searchFor === "User" && (
                <Form.Item
                  name="type"
                  rules={[{ required: true, message: "required!" }]}
                >
                  <FloatSelect
                    label="User"
                    placeholder="User"
                    onChange={handleMember}
                    options={member}
                    multi="multiple"
                    required
                  />
                </Form.Item>
              )}
            </Col>
          </Row>
          <Form.Item
            name="title"
            rules={[{ required: true, message: "required!" }]}
          >
            <FloatInput label="Title" placeholder="Title" required />
          </Form.Item>
          <Form.Item
            name="description"
            rules={[{ required: true, message: "required!" }]}
          >
            <FloatTextArea
              label="Description"
              placeholder="Description"
              required
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}
