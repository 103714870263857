import {
  Button,
  Card,
  Col,
  Layout,
  notification,
  Popconfirm,
  Row,
  Space,
  Table,
} from "antd";
import React, { useState, useEffect } from "react";
import { faCalendarAlt } from "@fortawesome/pro-regular-svg-icons";

import ComponentHeader from "../../../Components/ComponentHeader";
import {
  TableGlobalSearch,
  TablePageSize,
  TablePagination,
  TableShowingEntries,
} from "../../../Components/ComponentTableFilter";
import { GET, UPDATE } from "../../../../../providers/useAxiosQuery";
import $ from "jquery";
import { useHistory } from "react-router-dom";
import companyInfo from "../../../../../providers/companyInfo";

export default function PageEventCalendarViewAll({ match, permission }) {
  const history = useHistory();
  const userdata = companyInfo().userData;
  const [tableFilter, setTableFilter] = useState({
    page: 1,
    page_size: 50,
    search: "",
    sort_field: "title",
    sort_order: "desc",
    type: "Group",
    manager_id: userdata.id,
  });

  const [tableTotal, setTableTotal] = useState(0);
  const [dataSource, setDataSource] = useState([]);
  const {
    refetch: refetchTable,
    isLoading: isLoadingTable,
    isFetching: isFetchingTable,
  } = GET(`api/v1/assessment?${$.param(tableFilter)}`, "assessment", (res) => {
    if (res.success) {
      console.log("dataTable", res);
      setDataSource(res.data && res.data.data);
      setTableTotal(res.data.total);
    }
  });

  const handleTableChange = (pagination, filters, sorter) => {
    setTableFilter({
      ...tableFilter,
      sort_field: sorter.columnKey,
      sort_order: sorter.order ? sorter.order.replace("end", "") : null,
      // page: 1,
    });
  };

  const [searchText, setSearchText] = useState("");
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setTableFilter({
        ...tableFilter,
        search: searchText,
        page: 1,
      });
    }, 1500);
    return () => {
      clearTimeout(timeoutId);
    };
  }, [searchText]);

  useEffect(() => {
    refetchTable();
    return () => {};
  }, [tableFilter]);

  const handleDelete = (id) => {
    let data = {
      status: 1,
      id: id,
    };

    mutateDelete(data, {
      onSuccess: (res) => {
        if (res.success) {
          notification.success({
            message: "Success",
            description: "Successfully deleted.",
          });
        }
      },
    });
  };

  const { mutate: mutateDelete, isLoading: isLoadingDelete } = UPDATE(
    "api/v1/assessment",
    "assessment"
  );

  return (
    <>
      <ComponentHeader
        title={permission}
        sub_title="EDIT"
        icon={faCalendarAlt}
      />

      <Card className="card-min-height" id="PageEventCalendarViewAll">
        <Row gutter={12}>
          <Col xs={24} sm={24} md={16} lg={16} xl={18} className="m-b-sm">
            <Space>
              <TablePageSize
                paginationFilter={tableFilter}
                setPaginationFilter={setTableFilter}
              />
            </Space>
          </Col>
          <Col xs={24} sm={24} md={8} lg={8} xl={6}>
            <TableGlobalSearch
              paginationFilter={searchText}
              setPaginationFilter={setSearchText}
            />
          </Col>
        </Row>
        <Row gutter={12}>
          <Col xs={24} sm={24} md={24} className="m-t-sm m-b-sm">
            <Table
              className="ant-table-default ant-table-striped"
              dataSource={dataSource && dataSource}
              rowKey={(record) => record.id}
              loading={isLoadingTable || isFetchingTable}
              pagination={false}
              bordered={false}
              onChange={handleTableChange}
              scroll={{ x: "max-content" }}
            >
              <Table.Column
                title="Title"
                key="title"
                dataIndex="title"
                defaultSortOrder="descend"
                sorter={true}
                width={"250px"}
              />
              <Table.Column
                title="Date"
                key="date"
                dataIndex="date"
                sorter={true}
                width={"200px"}
              />
              {/* <Table.Column
                title="Type"
                key="type"
                dataIndex="type"
                sorter={true}
              /> */}
              <Table.Column
                title="Location"
                key="location"
                dataIndex="location"
                sorter={true}
                width={"200px"}
              />
              <Table.Column
                title="Action"
                key="action"
                width={"100px"}
                render={(text, item) => {
                  return (
                    <Space>
                      <Button
                        size="small"
                        className="btn-main-outline-7"
                        onClick={(e) =>
                          history.push(
                            "/event-calendar/edit-assessments/" + item.id
                          )
                        }
                      >
                        Edit
                      </Button>
                      <Popconfirm
                        title="Are you sure to delete this assessment?"
                        onConfirm={(e) => handleDelete(item.id)}
                        okText="Confirm"
                        cancelText="Cancel"
                        placement="topRight"
                      >
                        <Button size="small" className="btn-warning-outline">
                          Delete
                        </Button>
                      </Popconfirm>
                    </Space>
                  );
                }}
              />
            </Table>
          </Col>
          <Col xs={24} sm={24} md={24}>
            <Space className="ant-space-flex-space-between">
              <TableShowingEntries />

              <TablePagination
                paginationFilter={tableFilter}
                setPaginationFilter={setTableFilter}
                setPaginationTotal={tableTotal}
                showLessItems={true}
                showSizeChanger={false}
              />
            </Space>
          </Col>
        </Row>
        <br />
      </Card>
    </>
  );
}
