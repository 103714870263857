import {
  Button,
  Card,
  Col,
  Collapse,
  notification,
  Row,
  Space,
  Table,
  Form,
  Divider,
  Typography,
} from "antd";
import React, { useState, useEffect } from "react";
// import { useHistory } from "react-router-dom";
// import $ from "jquery";

import { faFilePdf } from "@fortawesome/free-solid-svg-icons";
import FloatInput from "../../../../../providers/FloatInput";
import FloatSelect from "../../../../../providers/FloatSelect";
import { GETMANUAL, POST } from "../../../../../providers/useAxiosQuery";
import validateRules from "../../../../../providers/validateRules";

export default function PageProfessional({ pdfData, pdfID }) {
  // const history = useHistory();
  // const userdata = companyInfo().userData;

  //   const pdf_data = JSON.parse(pdfData.pdf_data);
  const [form] = Form.useForm();

  useEffect(() => {
    if (pdfData) {
      refetchData();
    }
  }, [pdfID]);

  const [PDFData, setPDFData] = useState();
  const [typeData, setTypeData] = useState("");
  const { refetch: refetchData } = GETMANUAL(
    `api/v1/get_pdf_data_list?id=${pdfID}`,
    "get_data",
    (res) => {
      if (res.success) {
        let pdf_data = JSON.parse(res.data.pdf_data);
        setPDFData(pdf_data);
        setTypeData(res.data);
        console.log("pdf_data", res);

        form.setFieldsValue({
          p_3_rebound_jump:
            pdf_data.p_3_rebound_jump === 0
              ? Number(pdf_data.p_3_rebound_jump).toFixed()
              : pdf_data.p_3_rebound_jump,
          p_3_countermovement_jump:
            pdf_data.p_3_countermovement_jump === 0
              ? Number(pdf_data.p_3_countermovement_jump).toFixed()
              : pdf_data.p_3_countermovement_jump,
          p_3_squat_jump:
            pdf_data.p_3_squat_jump === 0
              ? Number(pdf_data.p_3_squat_jump).toFixed()
              : pdf_data.p_3_squat_jump,
          p_3_ten_yard_sprint_jump:
            pdf_data.p_3_ten_yard_sprint_jump === 0
              ? Number(pdf_data.p_3_ten_yard_sprint_jump).toFixed()
              : pdf_data.p_3_ten_yard_sprint_jump,
          p_3_isometric_mid_thigh_pull:
            pdf_data.p_3_isometric_mid_thigh_pull === 0
              ? Number(pdf_data.p_3_isometric_mid_thigh_pull).toFixed()
              : pdf_data.p_3_isometric_mid_thigh_pull,

          p_3_rebound_jump_average:
            pdf_data.p_3_rebound_jump_average === 0
              ? Number(pdf_data.p_3_rebound_jump_average).toFixed()
              : pdf_data.p_3_rebound_jump_average,
          p_3_countermovement_jump_average:
            pdf_data.p_3_countermovement_jump_average === 0
              ? Number(pdf_data.p_3_countermovement_jump_average).toFixed()
              : pdf_data.p_3_countermovement_jump_average,
          p_3_squat_jump_average:
            pdf_data.p_3_squat_jump_average === 0
              ? Number(pdf_data.p_3_squat_jump_average).toFixed()
              : pdf_data.p_3_squat_jump_average,
          p_3_ten_yard_sprint_jump_average:
            pdf_data.p_3_ten_yard_sprint_jump_average === 0
              ? Number(pdf_data.p_3_ten_yard_sprint_jump_average).toFixed()
              : pdf_data.p_3_ten_yard_sprint_jump_average,
          p_3_isometric_mid_thigh_pull_average:
            pdf_data.p_3_isometric_mid_thigh_pull_average === 0
              ? Number(pdf_data.p_3_isometric_mid_thigh_pull_average).toFixed()
              : pdf_data.p_3_isometric_mid_thigh_pull_average,

          p_3_rebound_jump_benchmark:
            pdf_data.p_3_rebound_jump_benchmark === 0
              ? Number(pdf_data.p_3_rebound_jump_benchmark).toFixed()
              : pdf_data.p_3_rebound_jump_benchmark,
          p_3_countermovement_jump_benchmark:
            pdf_data.p_3_countermovement_jump_benchmark === 0
              ? Number(pdf_data.p_3_countermovement_jump_benchmark).toFixed()
              : pdf_data.p_3_countermovement_jump_benchmark,
          p_3_squat_jump_benchmark:
            pdf_data.p_3_squat_jump_benchmark === 0
              ? Number(pdf_data.p_3_squat_jump_benchmark).toFixed()
              : pdf_data.p_3_squat_jump_benchmark,
          p_3_ten_yard_sprint_jump_benchmark:
            pdf_data.p_3_ten_yard_sprint_jump_benchmark === 0
              ? Number(pdf_data.p_3_ten_yard_sprint_jump_benchmark).toFixed()
              : pdf_data.p_3_ten_yard_sprint_jump_benchmark,
          p_3_isometric_mid_thigh_pull_benchmark:
            pdf_data.p_3_isometric_mid_thigh_pull_benchmark === 0
              ? Number(
                  pdf_data.p_3_isometric_mid_thigh_pull_benchmark
                ).toFixed()
              : pdf_data.p_3_isometric_mid_thigh_pull_benchmark,

          p_6_rsi_score:
            pdf_data.p_6_rsi_score === 0
              ? Number(pdf_data.p_6_rsi_score).toFixed()
              : pdf_data.p_6_rsi_score,
          p_6_jump_height:
            pdf_data.p_6_jump_height === 0
              ? Number(pdf_data.p_6_jump_height).toFixed()
              : pdf_data.p_6_jump_height,
          p_6_ground_contact_time:
            pdf_data.p_6_ground_contact_time === 0
              ? Number(pdf_data.p_6_ground_contact_time).toFixed()
              : pdf_data.p_6_ground_contact_time,
          p_6_rank:
            pdf_data.p_6_rank === 0
              ? Number(pdf_data.p_6_rank).toFixed()
              : pdf_data.p_6_rank,

          p_8_cmj_height:
            pdf_data.p_8_cmj_height === 0
              ? Number(pdf_data.p_8_cmj_height).toFixed()
              : pdf_data.p_8_cmj_height,
          p_8_cmj_height_average:
            pdf_data.p_8_cmj_height_average === 0
              ? Number(pdf_data.p_8_cmj_height_average).toFixed()
              : pdf_data.p_8_cmj_height_average,
          p_8_relative_avg_power:
            pdf_data.p_8_relative_avg_power === 0
              ? Number(pdf_data.p_8_relative_avg_power).toFixed()
              : pdf_data.p_8_relative_avg_power,
          p_8_relative_avg_power_average:
            pdf_data.p_8_relative_avg_power_average === 0
              ? Number(pdf_data.p_8_relative_avg_power_average).toFixed()
              : pdf_data.p_8_relative_avg_power_average,
          p_8_rank:
            pdf_data.p_8_rank === 0
              ? Number(pdf_data.p_8_rank).toFixed()
              : pdf_data.p_8_rank,

          p_10_j_height:
            pdf_data.p_10_j_height === 0
              ? Number(pdf_data.p_10_j_height).toFixed()
              : pdf_data.p_10_j_height,
          p_10_j_height_average:
            pdf_data.p_10_j_height_average === 0
              ? Number(pdf_data.p_10_j_height_average).toFixed()
              : pdf_data.p_10_j_height_average,
          p_10_realative_peak_power:
            pdf_data.p_10_realative_peak_power === 0
              ? Number(pdf_data.p_10_realative_peak_power).toFixed()
              : pdf_data.p_10_realative_peak_power,
          p_10_realative_peak_power_average:
            pdf_data.p_10_realative_peak_power_average === 0
              ? Number(pdf_data.p_10_realative_peak_power_average).toFixed()
              : pdf_data.p_10_realative_peak_power_average,
          p_10_rank:
            pdf_data.p_10_rank === 0
              ? Number(pdf_data.p_10_rank).toFixed()
              : pdf_data.p_10_rank,

          p_11_your_eur_score:
            pdf_data.p_11_your_eur_score === 0
              ? Number(pdf_data.p_11_your_eur_score).toFixed()
              : pdf_data.p_11_your_eur_score,

          p_13_10_yard_sprint:
            pdf_data.p_13_10_yard_sprint === 0
              ? Number(pdf_data.p_13_10_yard_sprint).toFixed()
              : pdf_data.p_13_10_yard_sprint,
          p_13_10_yard_sprint_average:
            pdf_data.p_13_10_yard_sprint_average === 0
              ? Number(pdf_data.p_13_10_yard_sprint_average).toFixed()
              : pdf_data.p_13_10_yard_sprint_average,
          p_13_40_yard_sprint:
            pdf_data.p_13_40_yard_sprint === 0
              ? Number(pdf_data.p_13_40_yard_sprint).toFixed()
              : pdf_data.p_13_40_yard_sprint,
          p_13_40_yard_sprint_average:
            pdf_data.p_13_40_yard_sprint_average === 0
              ? Number(pdf_data.p_13_40_yard_sprint_average).toFixed()
              : pdf_data.p_13_40_yard_sprint_average,
          p_13_rank:
            pdf_data.p_13_rank === 0
              ? Number(pdf_data.p_13_rank).toFixed()
              : pdf_data.p_13_rank,

          p_15_absolute_strenght:
            pdf_data.p_15_absolute_strenght === 0
              ? Number(pdf_data.p_15_absolute_strenght).toFixed()
              : pdf_data.p_15_absolute_strenght,
          p_15_absolute_strenght_pounds:
            pdf_data.p_15_absolute_strenght_pounds === 0
              ? Number(pdf_data.p_15_absolute_strenght_pounds).toFixed()
              : pdf_data.p_15_absolute_strenght_pounds,
          p_15_absolute_strenght_average_1:
            pdf_data.p_15_absolute_strenght_average_1 === 0
              ? Number(pdf_data.p_15_absolute_strenght_average_1).toFixed()
              : pdf_data.p_15_absolute_strenght_average_1,
          p_15_absolute_strenght_average_2:
            pdf_data.p_15_absolute_strenght_average_2 === 0
              ? Number(pdf_data.p_15_absolute_strenght_average_2).toFixed()
              : pdf_data.p_15_absolute_strenght_average_2,

          p_15_relative_strength_peak_force_trength:
            pdf_data.p_15_relative_strength_peak_force_trength === 0
              ? Number(
                  pdf_data.p_15_relative_strength_peak_force_trength
                ).toFixed()
              : pdf_data.p_15_relative_strength_peak_force_trength,
          p_15_relative_strength_peak_force_trength_average:
            pdf_data.p_15_relative_strength_peak_force_trength_average === 0
              ? Number(
                  pdf_data.p_15_relative_strength_peak_force_trength_average
                ).toFixed()
              : pdf_data.p_15_relative_strength_peak_force_trength_average,
          p_15_rank:
            pdf_data.p_15_rank === 0
              ? Number(pdf_data.p_15_rank).toFixed()
              : pdf_data.p_15_rank,

          p_16_peak_force_pounds:
            pdf_data.p_16_peak_force_pounds === 0
              ? Number(pdf_data.p_16_peak_force_pounds).toFixed()
              : pdf_data.p_16_peak_force_pounds,
          p_16_weight:
            pdf_data.p_16_weight === 0
              ? Number(pdf_data.p_16_weight).toFixed()
              : pdf_data.p_16_weight,
          p_16_peak_force_body_weight:
            pdf_data.p_16_peak_force_body_weight === 0
              ? Number(pdf_data.p_16_peak_force_body_weight).toFixed()
              : pdf_data.p_16_peak_force_body_weight,
          p_16_force_0_100_ms:
            pdf_data.p_16_force_0_100_ms === 0
              ? Number(pdf_data.p_16_force_0_100_ms).toFixed()
              : pdf_data.p_16_force_0_100_ms,
          p_16_0_100_ms_rfd:
            pdf_data.p_16_0_100_ms_rfd === 0
              ? Number(pdf_data.p_16_0_100_ms_rfd).toFixed()
              : pdf_data.p_16_0_100_ms_rfd,

          p_17_imtp_peak_force:
            pdf_data.p_17_imtp_peak_force === 0
              ? Number(pdf_data.p_17_imtp_peak_force).toFixed()
              : pdf_data.p_17_imtp_peak_force,
          p_17_cmj_peak_force:
            pdf_data.p_17_cmj_peak_force === 0
              ? Number(pdf_data.p_17_cmj_peak_force).toFixed()
              : pdf_data.p_17_cmj_peak_force,
          p_17_dsi_score:
            pdf_data.p_17_dsi_score === 0
              ? Number(pdf_data.p_17_dsi_score).toFixed()
              : pdf_data.p_17_dsi_score,

          p_26_reactive_strength_index:
            pdf_data.p_26_reactive_strength_index === 0
              ? Number(pdf_data.p_26_reactive_strength_index).toFixed()
              : pdf_data.p_26_reactive_strength_index,
          p_26_dynamic_strength_index:
            pdf_data.p_26_dynamic_strength_index === 0
              ? Number(pdf_data.p_26_dynamic_strength_index).toFixed()
              : pdf_data.p_26_dynamic_strength_index,
          p_26_eccentric_strength_index:
            pdf_data.p_26_eccentric_strength_index === 0
              ? Number(pdf_data.p_26_eccentric_strength_index).toFixed()
              : pdf_data.p_26_eccentric_strength_index,
          p_26_relative_strength_index:
            pdf_data.p_26_relative_strength_index === 0
              ? Number(pdf_data.p_26_relative_strength_index).toFixed()
              : pdf_data.p_26_relative_strength_index,

          p_26_reactive_strength_index_average:
            pdf_data.p_26_reactive_strength_index_average === 0
              ? Number(pdf_data.p_26_reactive_strength_index_average).toFixed()
              : pdf_data.p_26_reactive_strength_index_average,
          p_26_dynamic_strength_index_average:
            pdf_data.p_26_dynamic_strength_index_average === 0
              ? Number(pdf_data.p_26_dynamic_strength_index_average).toFixed()
              : pdf_data.p_26_dynamic_strength_index_average,
          p_26_eccentric_strength_index_average:
            pdf_data.p_26_eccentric_strength_index_average === 0
              ? Number(pdf_data.p_26_eccentric_strength_index_average).toFixed()
              : pdf_data.p_26_eccentric_strength_index_average,
          p_26_relative_strength_index_average:
            pdf_data.p_26_relative_strength_index_average === 0
              ? Number(pdf_data.p_26_relative_strength_index_average).toFixed()
              : pdf_data.p_26_relative_strength_index_average,

          p_26_reactive_strength_index_benchmark:
            pdf_data.p_26_reactive_strength_index_benchmark === 0
              ? Number(
                  pdf_data.p_26_reactive_strength_index_benchmark
                ).toFixed()
              : pdf_data.p_26_reactive_strength_index_benchmark,
          p_26_relative_strength_index_benchmark:
            pdf_data.p_26_relative_strength_index_benchmark === 0
              ? Number(
                  pdf_data.p_26_relative_strength_index_benchmark
                ).toFixed()
              : pdf_data.p_26_relative_strength_index_benchmark,
        });
      }
    }
  );

  const onFinish = (values) => {
    let data = {
      ...PDFData,
      p_3_countermovement_jump: values.p_3_countermovement_jump,
      p_3_isometric_mid_thigh_pull: values.p_3_isometric_mid_thigh_pull,
      p_3_rebound_jump: values.p_3_rebound_jump,
      p_3_rebound_jump_average: values.p_3_rebound_jump_average,
      p_3_rebound_jump_benchmark: values.p_3_rebound_jump_benchmark,

      p_3_rebound_jump_average: values.p_3_rebound_jump_average,
      p_3_countermovement_jump_average: values.p_3_countermovement_jump_average,
      p_3_squat_jump_average: values.p_3_squat_jump_average,
      p_3_ten_yard_sprint_jump_average: values.p_3_ten_yard_sprint_jump_average,
      p_3_isometric_mid_thigh_pull_average:
        values.p_3_isometric_mid_thigh_pull_average,

      p_3_rebound_jump_benchmark: values.p_3_rebound_jump_benchmark,
      p_3_countermovement_jump_benchmark:
        values.p_3_countermovement_jump_benchmark,
      p_3_squat_jump_benchmark: values.p_3_squat_jump_benchmark,
      p_3_ten_yard_sprint_jump_benchmark:
        values.p_3_ten_yard_sprint_jump_benchmark,
      p_3_isometric_mid_thigh_pull_benchmark:
        values.p_3_isometric_mid_thigh_pull_benchmark,

      p_6_rsi_score: values.p_6_rsi_score,
      p_6_jump_height: values.p_6_jump_height,
      p_6_ground_contact_time: values.p_6_ground_contact_time,
      p_6_rank: values.p_6_rank,

      p_8_cmj_height: values.p_8_cmj_height,
      p_8_cmj_height_average: values.p_8_cmj_height_average,
      p_8_relative_avg_power: values.p_8_relative_avg_power,
      p_8_relative_avg_power_average: values.p_8_relative_avg_power_average,
      p_8_rank: values.p_8_rank,

      p_10_j_height: values.p_10_j_height,
      p_10_j_height_average: values.p_10_j_height_average,
      p_10_realative_peak_power: values.p_10_realative_peak_power,
      p_10_realative_peak_power_average:
        values.p_10_realative_peak_power_average,
      p_10_rank: values.p_10_rank,

      p_11_your_eur_score: values.p_11_your_eur_score,

      p_13_10_yard_sprint: values.p_13_10_yard_sprint,
      p_13_10_yard_sprint_average: values.p_13_10_yard_sprint_average,
      p_13_40_yard_sprint: values.p_13_40_yard_sprint,
      p_13_40_yard_sprint_average: values.p_13_40_yard_sprint_average,
      p_13_rank: values.p_13_rank,

      p_15_absolute_strenght: values.p_15_absolute_strenght,
      p_15_absolute_strenght_pounds: values.p_15_absolute_strenght_pounds,
      p_15_absolute_strenght_average_1: values.p_15_absolute_strenght_average_1,
      p_15_absolute_strenght_average_2: values.p_15_absolute_strenght_average_2,

      p_15_relative_strength_peak_force_trength:
        values.p_15_relative_strength_peak_force_trength,
      p_15_relative_strength_peak_force_trength_average:
        values.p_15_relative_strength_peak_force_trength_average,
      p_15_rank: values.p_15_rank,

      p_16_peak_force_pounds: values.p_16_peak_force_pounds,
      p_16_weight: values.p_16_weight,
      p_16_peak_force_body_weight: values.p_16_peak_force_body_weight,
      p_16_force_0_100_ms: values.p_16_force_0_100_ms,
      p_16_0_100_ms_rfd: values.p_16_0_100_ms_rfd,

      p_17_imtp_peak_force: values.p_17_imtp_peak_force,
      p_17_cmj_peak_force: values.p_17_cmj_peak_force,
      p_17_dsi_score: values.p_17_dsi_score,

      p_26_reactive_strength_index: values.p_26_reactive_strength_index,
      p_26_dynamic_strength_index: values.p_26_dynamic_strength_index,
      p_26_eccentric_strength_index: values.p_26_eccentric_strength_index,
      p_26_relative_strength_index: values.p_26_relative_strength_index,

      p_26_reactive_strength_index_average:
        values.p_26_reactive_strength_index_average,
      p_26_dynamic_strength_index_average:
        values.p_26_dynamic_strength_index_average,
      p_26_eccentric_strength_index_average:
        values.p_26_eccentric_strength_index_average,
      p_26_relative_strength_index_average:
        values.p_26_relative_strength_index_average,

      p_26_reactive_strength_index_benchmark:
        values.p_26_reactive_strength_index_benchmark,
      p_26_relative_strength_index_benchmark:
        values.p_26_relative_strength_index_benchmark,
    };

    let result = { id: pdfID, pdf_data: JSON.stringify(data) };

    mutateUpdate(result, {
      onSuccess: (res) => {
        if (res.success) {
          console.log("pdf_data", res);
          notification.success({
            message: "Success",
            description: "Successfully Updated!",
          });
        }
      },
    });
  };

  const { mutate: mutateUpdate, isLoading: isLoadingUpdate } = POST(
    "api/v1/edit_pdf_start",
    "get_data"
  );

  return (
    <>
      <Form form={form} onFinish={onFinish}>
        <Row gutter={12}>
          <Col xs={24} sm={24} md={12}>
            <Collapse
              className="main-1-collapse border-none"
              expandIcon={({ isActive }) =>
                isActive ? (
                  <span
                    className="ant-menu-submenu-arrow"
                    style={{ color: "#FFF", transform: "rotate(270deg)" }}
                  ></span>
                ) : (
                  <span
                    className="ant-menu-submenu-arrow"
                    style={{ color: "#FFF", transform: "rotate(90deg)" }}
                  ></span>
                )
              }
              defaultActiveKey={["0"]}
              expandIconPosition="start"
            >
              {PDFData && (
                <Collapse.Panel
                  header="Athlete Information"
                  key="0"
                  className="accordion bg-darkgray-form m-b-md border"
                >
                  <Typography.Text>
                    <strong>Assessment Type</strong>:{" "}
                    {typeData && typeData.type}
                  </Typography.Text>
                  <br />

                  <Typography.Text>
                    <strong>Name</strong>: {PDFData.name}
                  </Typography.Text>
                  <br />

                  <Typography.Text>
                    <strong>Age</strong>: {PDFData.p_3_age}
                  </Typography.Text>
                  <br />

                  <Typography.Text>
                    <strong>Sport</strong>: {PDFData.p_3_sport}
                  </Typography.Text>
                  <br />

                  <Typography.Text>
                    <strong>Weight</strong>: {PDFData.p_3_weight}
                  </Typography.Text>
                  <br />

                  <Typography.Text>
                    <strong>Sex</strong>: {PDFData.p_3_gender}
                  </Typography.Text>
                  <br />
                </Collapse.Panel>
              )}
            </Collapse>
          </Col>
        </Row>

        <Collapse
          className="main-1-collapse border-none"
          expandIcon={({ isActive }) =>
            isActive ? (
              <span
                className="ant-menu-submenu-arrow"
                style={{ color: "#FFF", transform: "rotate(270deg)" }}
              ></span>
            ) : (
              <span
                className="ant-menu-submenu-arrow"
                style={{ color: "#FFF", transform: "rotate(90deg)" }}
              ></span>
            )
          }
          defaultActiveKey={["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"]}
          expandIconPosition="start"
        >
          <Collapse.Panel
            header="Page 3 - BFS Science Assessment"
            key="1"
            className="accordion bg-darkgray-form m-b-md border"
          >
            <Divider orientation="left" plain style={{ marginTop: "0px" }}>
              <strong>You</strong>
            </Divider>
            <Row gutter={12}>
              <Col xs={24} sm={24} md={8}>
                <Form.Item
                  name="p_3_rebound_jump"
                  rules={[validateRules.assessment_pattern]}
                >
                  <FloatInput
                    label="Reactive Strength (IN)"
                    placeholder="Reactive Strength (IN)"
                  />
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} md={8}>
                <Form.Item
                  name="p_3_countermovement_jump"
                  rules={[validateRules.assessment_pattern]}
                >
                  <FloatInput
                    label="Elastic Strength (IN)"
                    placeholder="Elastic Strength (IN)"
                  />
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} md={8}>
                <Form.Item
                  name="p_3_squat_jump"
                  rules={[validateRules.assessment_pattern]}
                >
                  <FloatInput
                    label="Ballastic Strength (IN)"
                    placeholder="Ballastic Strength (IN)"
                  />
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} md={8}>
                <Form.Item
                  name="p_3_ten_yard_sprint_jump"
                  rules={[validateRules.assessment_pattern]}
                >
                  <FloatInput
                    label="Acceleration/Speed (SEC)"
                    placeholder="Acceleration/Speed (SEC)"
                  />
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} md={8}>
                <Form.Item
                  name="p_3_isometric_mid_thigh_pull"
                  rules={[validateRules.assessment_pattern]}
                >
                  <FloatInput
                    label="Maximal Strength (LBS)"
                    placeholder="Maximal Strength (LBS)"
                  />
                </Form.Item>
              </Col>
            </Row>

            <Divider orientation="left" plain style={{ marginTop: "0px" }}>
              <strong>Performaers AVG</strong>
            </Divider>
            <Row gutter={12}>
              <Col xs={24} sm={24} md={8}>
                <Form.Item
                  name="p_3_rebound_jump_average"
                  rules={[validateRules.assessment_pattern]}
                >
                  <FloatInput
                    label="Reactive Strength (IN)"
                    placeholder="Reactive Strength (IN)"
                  />
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} md={8}>
                <Form.Item
                  name="p_3_countermovement_jump_average"
                  rules={[validateRules.assessment_pattern]}
                >
                  <FloatInput
                    label="Elastic Strength (IN)"
                    placeholder="Elastic Strength (IN)"
                  />
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} md={8}>
                <Form.Item
                  name="p_3_squat_jump_average"
                  rules={[validateRules.assessment_pattern]}
                >
                  <FloatInput
                    label="Ballastic Strength (IN)"
                    placeholder="Ballastic Strength (IN)"
                  />
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} md={8}>
                <Form.Item
                  name="p_3_ten_yard_sprint_jump_average"
                  rules={[validateRules.assessment_pattern]}
                >
                  <FloatInput
                    label="Acceleration/Speed (SEC)"
                    placeholder="Acceleration/Speed (SEC)"
                  />
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} md={8}>
                <Form.Item
                  name="p_3_isometric_mid_thigh_pull_average"
                  rules={[validateRules.assessment_pattern]}
                >
                  <FloatInput
                    label="Maximal Strength (LBS)"
                    placeholder="Maximal Strength (LBS)"
                  />
                </Form.Item>
              </Col>
            </Row>

            <Divider orientation="left" plain style={{ marginTop: "0px" }}>
              <strong>Your Benchmark</strong>
            </Divider>
            <Row gutter={12}>
              <Col xs={24} sm={24} md={8}>
                <Form.Item name="p_3_rebound_jump_benchmark">
                  <FloatInput
                    label="Reactive Strength (IN)"
                    placeholder="Reactive Strength (IN)"
                  />
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} md={8}>
                <Form.Item name="p_3_countermovement_jump_benchmark">
                  <FloatInput
                    label="Elastic Strength (IN)"
                    placeholder="Elastic Strength (IN)"
                  />
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} md={8}>
                <Form.Item name="p_3_squat_jump_benchmark">
                  <FloatInput
                    label="Ballastic Strength (IN)"
                    placeholder="Ballastic Strength (IN)"
                  />
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} md={8}>
                <Form.Item name="p_3_ten_yard_sprint_jump_benchmark">
                  <FloatInput
                    label="Acceleration/Speed (SEC)"
                    placeholder="Acceleration/Speed (SEC)"
                  />
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} md={8}>
                <Form.Item name="p_3_isometric_mid_thigh_pull_benchmark">
                  <FloatInput
                    label="Maximal Strength (LBS)"
                    placeholder="Maximal Strength (LBS)"
                  />
                </Form.Item>
              </Col>
            </Row>
          </Collapse.Panel>

          <Collapse.Panel
            header="Page 5 - Rebound Jump Test"
            key="2"
            className="accordion bg-darkgray-form m-b-md border"
          >
            <Divider orientation="left" plain style={{ marginTop: "0px" }}>
              <strong>Assessment</strong>
            </Divider>
            <Row gutter={12}>
              <Col xs={24} sm={24} md={8}>
                <Form.Item
                  name="p_6_rsi_score"
                  rules={[validateRules.assessment_pattern]}
                >
                  <FloatInput
                    label="Your RSI Score"
                    placeholder="Your RSI Score"
                  />
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} md={8}>
                <Form.Item
                  name="p_6_jump_height"
                  rules={[validateRules.assessment_pattern]}
                >
                  <FloatInput
                    label="Jump Height (IN)"
                    placeholder="Jump Height (IN)"
                  />
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} md={8}>
                <Form.Item
                  name="p_6_ground_contact_time"
                  rules={[validateRules.assessment_pattern]}
                >
                  <FloatInput
                    label="Ground Contact Time (MS)"
                    placeholder="Ground Contact Time (MS)"
                  />
                </Form.Item>
              </Col>
            </Row>

            <Divider orientation="left" plain style={{ marginTop: "0px" }}>
              <strong>Your Rank is:</strong>
            </Divider>
            <Row gutter={12}>
              <Col xs={24} sm={24} md={8}>
                <Form.Item name="p_6_rank">
                  <FloatInput label="Rank" placeholder="Rank" />
                </Form.Item>
              </Col>
            </Row>
          </Collapse.Panel>

          <Collapse.Panel
            header="Page 7 - Counter-Movement Jump Test"
            key="3"
            className="accordion bg-darkgray-form m-b-md border"
          >
            <Divider orientation="left" plain style={{ marginTop: "0px" }}>
              <strong>CMJ Height (IN)</strong>
            </Divider>
            <Row gutter={12}>
              <Col xs={24} sm={24} md={8}>
                <Form.Item
                  name="p_8_cmj_height"
                  rules={[validateRules.assessment_pattern]}
                >
                  <FloatInput label="You" placeholder="You" />
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} md={8}>
                <Form.Item
                  name="p_8_cmj_height_average"
                  rules={[validateRules.assessment_pattern]}
                >
                  <FloatInput
                    label="Elite Performers"
                    placeholder="Elite Performers"
                  />
                </Form.Item>
              </Col>
            </Row>

            <Divider orientation="left" plain style={{ marginTop: "0px" }}>
              <strong>CMJ Peak Force (N)</strong>
            </Divider>
            <Row gutter={12}>
              <Col xs={24} sm={24} md={8}>
                <Form.Item
                  name="p_8_relative_avg_power"
                  rules={[validateRules.assessment_pattern]}
                >
                  <FloatInput label="You" placeholder="You" />
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} md={8}>
                <Form.Item
                  name="p_8_relative_avg_power_average"
                  rules={[validateRules.assessment_pattern]}
                >
                  <FloatInput
                    label="Elite Performers"
                    placeholder="Elite Performers"
                  />
                </Form.Item>
              </Col>
            </Row>

            <Divider orientation="left" plain style={{ marginTop: "0px" }}>
              <strong>Your Rank is:</strong>
            </Divider>
            <Row gutter={12}>
              <Col xs={24} sm={24} md={8}>
                <Form.Item name="p_8_rank">
                  <FloatInput label="Rank" placeholder="Rank" />
                </Form.Item>
              </Col>
            </Row>
          </Collapse.Panel>

          <Collapse.Panel
            header="Page 9 - Squat Jump Test"
            key="4"
            className="accordion bg-darkgray-form m-b-md border"
          >
            <Divider orientation="left" plain style={{ marginTop: "0px" }}>
              <strong>Jump Height (IN)</strong>
            </Divider>
            <Row gutter={12}>
              <Col xs={24} sm={24} md={8}>
                <Form.Item
                  name="p_10_j_height"
                  rules={[validateRules.assessment_pattern]}
                >
                  <FloatInput label="You" placeholder="You" />
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} md={8}>
                <Form.Item
                  name="p_10_j_height_average"
                  rules={[validateRules.assessment_pattern]}
                >
                  <FloatInput
                    label="Elite Performers"
                    placeholder="Elite Performers"
                  />
                </Form.Item>
              </Col>
            </Row>

            <Divider orientation="left" plain style={{ marginTop: "0px" }}>
              <strong>Peak Force Produced (N)</strong>
            </Divider>
            <Row gutter={12}>
              <Col xs={24} sm={24} md={8}>
                <Form.Item
                  name="p_10_realative_peak_power"
                  rules={[validateRules.assessment_pattern]}
                >
                  <FloatInput label="You" placeholder="You" />
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} md={8}>
                <Form.Item
                  name="p_10_realative_peak_power_average"
                  rules={[validateRules.assessment_pattern]}
                >
                  <FloatInput
                    label="Elite Performers"
                    placeholder="Elite Performers"
                  />
                </Form.Item>
              </Col>
            </Row>

            <Divider orientation="left" plain style={{ marginTop: "0px" }}>
              <strong>Your Rank is:</strong>
            </Divider>
            <Row gutter={12}>
              <Col xs={24} sm={24} md={8}>
                <Form.Item name="p_10_rank">
                  <FloatInput label="Rank" placeholder="Rank" />
                </Form.Item>
              </Col>
            </Row>
          </Collapse.Panel>

          <Collapse.Panel
            header="Page 10 - Eccentric Utilization Ratio (EUR)"
            key="5"
            className="accordion bg-darkgray-form m-b-md border"
          >
            <Divider orientation="left" plain style={{ marginTop: "0px" }}>
              <strong>Jump Height (IN)</strong>
            </Divider>
            <Row gutter={12}>
              <Col xs={24} sm={24} md={8}>
                <Form.Item
                  name="p_11_your_eur_score"
                  rules={[validateRules.assessment_pattern]}
                >
                  <FloatInput label="Score" placeholder="Score" />
                </Form.Item>
              </Col>
            </Row>
          </Collapse.Panel>

          <Collapse.Panel
            header="Page 12 - 10YD/40YD sprint Test"
            key="6"
            className="accordion bg-darkgray-form m-b-md border"
          >
            <Divider orientation="left" plain style={{ marginTop: "0px" }}>
              <strong>10 YD Sprint (SEC)</strong>
            </Divider>
            <Row gutter={12}>
              <Col xs={24} sm={24} md={8}>
                <Form.Item
                  name="p_13_10_yard_sprint"
                  rules={[validateRules.assessment_pattern]}
                >
                  <FloatInput label="You" placeholder="You" />
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} md={8}>
                <Form.Item
                  name="p_13_10_yard_sprint_average"
                  rules={[validateRules.assessment_pattern]}
                >
                  <FloatInput
                    label="Elite Performers"
                    placeholder="Elite Performers"
                  />
                </Form.Item>
              </Col>
            </Row>

            <Divider orientation="left" plain style={{ marginTop: "0px" }}>
              <strong>40 YD Sprint (SEC)</strong>
            </Divider>
            <Row gutter={12}>
              <Col xs={24} sm={24} md={8}>
                <Form.Item
                  name="p_13_40_yard_sprint"
                  rules={[validateRules.assessment_pattern]}
                >
                  <FloatInput label="You" placeholder="You" />
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} md={8}>
                <Form.Item
                  name="p_13_40_yard_sprint_average"
                  rules={[validateRules.assessment_pattern]}
                >
                  <FloatInput
                    label="Elite Performers"
                    placeholder="Elite Performers"
                  />
                </Form.Item>
              </Col>
            </Row>

            <Divider orientation="left" plain style={{ marginTop: "0px" }}>
              <strong>Your Rank is:</strong>
            </Divider>
            <Row gutter={12}>
              <Col xs={24} sm={24} md={8}>
                <Form.Item name="p_13_rank">
                  <FloatInput label="Rank" placeholder="Rank" />
                </Form.Item>
              </Col>
            </Row>
          </Collapse.Panel>

          <Collapse.Panel
            header="Page 14 - Isometric Mid-thigh Pull Test (IMTP)"
            key="7"
            className="accordion bg-darkgray-form m-b-md border"
          >
            <Divider orientation="left" plain style={{ marginTop: "0px" }}>
              <strong>Absolute Strength</strong>
            </Divider>
            <Row gutter={12}>
              <Col xs={24} sm={24} md={8}>
                <Form.Item name="p_15_absolute_strenght">
                  <FloatInput label="You (N)" placeholder="You (N)" />
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} md={8}>
                <Form.Item name="p_15_absolute_strenght_pounds">
                  <FloatInput label="You (LBS)" placeholder="You (LBS)" />
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} md={8}>
                <Form.Item name="p_15_absolute_strenght_average_1">
                  <FloatInput
                    label="Average Performers (N)"
                    placeholder="Average Performers (N)"
                  />
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} md={8}>
                <Form.Item name="p_15_absolute_strenght_average_2">
                  <FloatInput
                    label="Average Performers (LBS)"
                    placeholder="Average Performers (LBS)"
                  />
                </Form.Item>
              </Col>
            </Row>

            <Divider orientation="left" plain style={{ marginTop: "0px" }}>
              <strong>Relative Strength Peak Force/Body Weight</strong>
            </Divider>
            <Row gutter={12}>
              <Col xs={24} sm={24} md={8}>
                <Form.Item name="p_15_relative_strength_peak_force_trength">
                  <FloatInput label="You" placeholder="You" />
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} md={8}>
                <Form.Item name="p_15_relative_strength_peak_force_trength_average">
                  <FloatInput
                    label="Elite Performers"
                    placeholder="Elite Performers"
                  />
                </Form.Item>
              </Col>
            </Row>

            <Divider orientation="left" plain style={{ marginTop: "0px" }}>
              <strong>Your Rank is:</strong>
            </Divider>
            <Row gutter={12}>
              <Col xs={24} sm={24} md={8}>
                <Form.Item name="p_15_rank">
                  <FloatInput label="Rank" placeholder="Rank" />
                </Form.Item>
              </Col>
            </Row>
          </Collapse.Panel>

          <Collapse.Panel
            header="Page 15 - Rate of Force Development (RFD)"
            key="8"
            className="accordion bg-darkgray-form m-b-md border"
          >
            <Divider orientation="left" plain style={{ marginTop: "0px" }}>
              <strong>Assessment</strong>
            </Divider>
            <Row gutter={12}>
              <Col xs={24} sm={24} md={8}>
                <Form.Item name="p_16_peak_force_pounds">
                  <FloatInput
                    label="Peak Force (LBS)"
                    placeholder="Peak Force (LBS)"
                  />
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} md={8}>
                <Form.Item name="p_16_weight">
                  <FloatInput
                    label="Your Weight (LBS)"
                    placeholder="Your Weight (LBS)"
                  />
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} md={8}>
                <Form.Item name="p_16_peak_force_body_weight">
                  <FloatInput
                    label="Peak Force/Body Weight"
                    placeholder="Peak Force/Body Weight"
                  />
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} md={8}>
                <Form.Item name="p_16_force_0_100_ms">
                  <FloatInput
                    label="Force (lbs) @0-100ms"
                    placeholder="Force (lbs) @ 0-100ms"
                  />
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} md={8}>
                <Form.Item name="p_16_0_100_ms_rfd">
                  <FloatInput
                    label="0-100ms RFD % of Peak Force"
                    placeholder="0-100ms RFD % of Peak Force"
                  />
                </Form.Item>
              </Col>
            </Row>
          </Collapse.Panel>

          <Collapse.Panel
            header="Page 16 - Dynamic Strength Index (DSI)"
            key="9"
            className="accordion bg-darkgray-form m-b-md border"
          >
            <Divider orientation="left" plain style={{ marginTop: "0px" }}>
              <strong>Dynamic Strength Index</strong>
            </Divider>
            <Row gutter={12}>
              <Col xs={24} sm={24} md={8}>
                <Form.Item name="p_17_imtp_peak_force">
                  <FloatInput
                    label="IMTP Peak Force"
                    placeholder="IMTP Peak Force"
                  />
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} md={8}>
                <Form.Item name="p_17_cmj_peak_force">
                  <FloatInput
                    label="CMJ Peak Force"
                    placeholder="CMJ Peak Force"
                  />
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} md={8}>
                <Form.Item name="p_17_dsi_score">
                  <FloatInput
                    label="Your DSI Score"
                    placeholder="Your DSI Score"
                  />
                </Form.Item>
              </Col>
            </Row>
          </Collapse.Panel>

          <Collapse.Panel
            header="Page 17 - Summary of Findings"
            key="10"
            className="accordion bg-darkgray-form m-b-md border"
          >
            <Divider orientation="left" plain style={{ marginTop: "0px" }}>
              <strong>You</strong>
            </Divider>
            <Row gutter={12}>
              <Col xs={24} sm={24} md={8}>
                <Form.Item name="p_26_reactive_strength_index">
                  <FloatInput
                    label="Reactive Strength Index"
                    placeholder="Reactive Strength Index"
                  />
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} md={8}>
                <Form.Item name="p_26_dynamic_strength_index">
                  <FloatInput
                    label="Dynamic Strength Index"
                    placeholder="Dynamic Strength Index"
                  />
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} md={8}>
                <Form.Item name="p_26_eccentric_strength_index">
                  <FloatInput
                    label="Eccentric Utilization Ratio"
                    placeholder="Eccentric Utilization Ratio"
                  />
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} md={8}>
                <Form.Item name="p_26_relative_strength_index">
                  <FloatInput
                    label="Relative Strength"
                    placeholder="Relative Strength"
                  />
                </Form.Item>
              </Col>
            </Row>

            <Divider orientation="left" plain style={{ marginTop: "0px" }}>
              <strong>Average Performers</strong>
            </Divider>
            <Row gutter={12}>
              <Col xs={24} sm={24} md={8}>
                <Form.Item name="p_26_reactive_strength_index_average">
                  <FloatInput
                    label="Reactive Strength Index"
                    placeholder="Reactive Strength Index"
                  />
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} md={8}>
                <Form.Item name="p_26_dynamic_strength_index_average">
                  <FloatInput
                    label="Dynamic Strength Index"
                    placeholder="Dynamic Strength Index"
                  />
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} md={8}>
                <Form.Item name="p_26_eccentric_strength_index_average">
                  <FloatInput
                    label="Eccentric Utilization Ratio"
                    placeholder="Eccentric Utilization Ratio"
                  />
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} md={8}>
                <Form.Item name="p_26_relative_strength_index_average">
                  <FloatInput
                    label="Relative Strength"
                    placeholder="Relative Strength"
                  />
                </Form.Item>
              </Col>
            </Row>

            <Divider orientation="left" plain style={{ marginTop: "0px" }}>
              <strong>Benchmark</strong>
            </Divider>
            <Row gutter={12}>
              <Col xs={24} sm={24} md={8}>
                <Form.Item name="p_26_reactive_strength_index_benchmark">
                  <FloatInput
                    label="Reactive Strength Index"
                    placeholder="Reactive Strength Index"
                  />
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} md={8}>
                <Form.Item name="p_26_relative_strength_index_benchmark">
                  <FloatInput
                    label="Relative Strength"
                    placeholder="Relative Strength"
                  />
                </Form.Item>
              </Col>
            </Row>
          </Collapse.Panel>
        </Collapse>

        <Row gutter={12}>
          <Col xs={24} sm={8} md={8}>
            <Button
              size="large"
              className="btn-main-outline-2"
              htmlType="submit"
              loading={isLoadingUpdate}
              block
            >
              SUBMIT
            </Button>
          </Col>
        </Row>
      </Form>
    </>
  );
}
