import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  Col,
  Form,
  Modal,
  Layout,
  notification,
  Row,
  Space,
} from "antd";
import { BiHomeCircle } from "react-icons/bi";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import FloatInput from "../../../../providers/FloatInput";
import FloatSelect from "../../../../providers/FloatSelect";
// import optionStateCodes from "../../../../providers/optionStateCodes";
import optionStateCodesUnitedState from "../../../../providers/optionStateCodesUnitedState";
import optionOrganizationType from "../../../../providers/optionOrganizationType";
import ComponentHeader from "../../Components/ComponentHeader";
import { faMapMarkerPlus } from "@fortawesome/pro-regular-svg-icons";
import { POST, UPDATE } from "../../../../providers/useAxiosQuery";

export default function PageOrganizationUpdateNew({ match, permission }) {
  const [form] = Form.useForm();
  const state = optionStateCodesUnitedState();
  const [dataSource, setDataSource] = useState([]);
  const [dataFilter] = useState({
    state: match.params.state,
    search: match.params.city,
  });

  const [formSearch] = Form.useForm();

  useEffect(() => {
    formSearch.setFieldsValue({
      state: match.params.state,
      search: match.params.city,
    });
  }, []);

  useEffect(() => {
    onSearch();
  }, [dataFilter]);

  const onSearch = () => {
    mutateSearch(dataFilter, {
      onSuccess: (res) => {
        if (res.success) {
          setDataSource(res.data.length > 0 ? res.data : []);
        }
      },
    });
  };

  const onSearchFinish = (values) => {
    console.log("onSearchFinish", values);
    mutateSearch(values, {
      onSuccess: (res) => {
        if (res.success) {
          setDataSource(res.data.length > 0 ? res.data : []);
        }
      },
    });
  };

  const { mutate: mutateSearch } = POST(
    "api/v1/organization/search",
    "organization_search"
  );

  const [formData, setFormData] = useState("");
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (formData) {
        formSearch.submit();
        setFormData("");
      }
    }, 1000);
    return () => {
      clearTimeout(timeoutId);
    };
  }, [formData]);

  const hanldeInput = (val) => {
    if (val === "") {
      setFormData("empty");
    } else {
      setFormData(val);
    }
  };

  const onChangeOption = (val, option) => {
    setFormData(val);
  };

  const [isModalApprove, setIsModalApprove] = useState(false);
  const [isDataApprove, setIsDataApprove] = useState([]);
  const handleApprove = (values) => {
    console.log("handleApprove", values);
    setIsDataApprove(values);
    setIsModalApprove(true);
  };

  const { mutate: mutateApprove, isLoading: isLoadingApprove } = UPDATE(
    "api/v1/organization",
    "organization_search"
  );

  const approve = () => {
    // console.log("approve", isDataApprove);
    mutateApprove(
      { id: isDataApprove.id, status: "Approve" },
      {
        onSuccess: (res) => {
          if (res.success) {
            notification.success({
              message: "Success",
              description: "Successfully Approved",
            });
            setIsDataApprove([]);
            formSearch.submit();
            setIsModalApprove(false);
          }
        },
      }
    );
  };

  const [isModalEdit, setIsModalEdit] = useState(false);
  const [isDataEdit, setIsDataEdit] = useState([]);
  const handleEdit = (values) => {
    console.log("handleEdit", values);
    // setIsModalEdit(true);
    // setIsDataEdit(values);
    // form.setFieldsValue({
    //   address1: values.address1,
    //   address2: values.address2,
    //   name: values.name,
    //   organization_type: values.organization_type,
    //   // city: values.city,
    //   // state: values.state,
    //   zip: values.zip,
    // });
  };

  const onFinishForm = (values) => {
    let data = {
      ...values,
      id: isDataEdit.id,
    };
    // console.log("onFinishForm", data);
    mutateApprove(data, {
      onSuccess: (res) => {
        if (res.success) {
          notification.success({
            message: "Success",
            description: "Successfully Updated",
          });
          setIsDataEdit([]);
          formSearch.submit();
          setIsModalEdit(false);
        }
      },
    });
  };

  return (
    <Layout className="site-layout-background">
      <ComponentHeader
        title="Approval"
        sub_title="PENDING"
        icon={faMapMarkerPlus}
      />

      <Card className="p-b-lg card-min-height" id="PageOrganizationUpdateNew">
        <Row gutter={12}>
          <Col xs={24} sm={24} md={16} className="ant-card-form-list">
            {dataSource &&
              dataSource.map((item, key) => {
                return (
                  <Card
                    title="ORGANIZATION APPROVAL"
                    className="card-dark-head m-b-md"
                    key={key}
                  >
                    <Row gutter={24}>
                      <Col xs={24} sm={24} md={24}>
                        <FloatInput
                          label="Organization Name"
                          placeholder="Organization Name"
                          disabled={true}
                          value={item.name}
                        />
                        <br />
                      </Col>
                    </Row>

                    <Row gutter={24}>
                      <Col xs={24} sm={24} md={12}>
                        <div className="form-select-error">
                          <FloatSelect
                            label="Type"
                            placeholder="Type"
                            options={optionOrganizationType}
                            disabled={true}
                            value={item.organization_type}
                          />
                        </div>
                        <br />
                      </Col>

                      <Col xs={24} sm={24} md={12}>
                        <div className="form-select-error">
                          <FloatSelect
                            label="State"
                            placeholder="State"
                            options={state}
                            disabled={true}
                            value={item.state}
                          />
                        </div>
                        <br />
                      </Col>
                    </Row>

                    <Row gutter={24}>
                      <Col xs={24} sm={24} md={24}>
                        <FloatInput
                          label="Address 1"
                          placeholder="Address 1"
                          disabled={true}
                          value={item.address1}
                        />
                        <br />
                      </Col>
                    </Row>

                    <Row gutter={24}>
                      <Col xs={24} sm={24} md={24}>
                        <FloatInput
                          label="Address 1"
                          placeholder="Address 1"
                          disabled={true}
                          value={item.address2}
                        />
                        <br />
                      </Col>
                    </Row>

                    <Row gutter={24}>
                      <Col xs={24} sm={24} md={12}>
                        <FloatInput
                          label="City"
                          placeholder="City"
                          disabled={true}
                          value={item.city}
                        />
                        <br />
                      </Col>

                      <Col xs={24} sm={24} md={12}>
                        <FloatInput
                          label="Zip"
                          placeholder="Zip"
                          disabled={true}
                          value={item.zip}
                        />
                        <br />
                      </Col>
                    </Row>

                    <Row gutter={24}>
                      <Col xs={24} sm={24} md={12}>
                        <Space>
                          <Button
                            className="btn-success-outline"
                            size="large"
                            onClick={(e) => handleApprove(item)}
                          >
                            APPROVE
                          </Button>

                          <Button
                            className="btn-warning-outline"
                            size="large"
                            onClick={(e) => handleEdit(item)}
                          >
                            EDIT
                          </Button>
                        </Space>
                      </Col>
                    </Row>
                  </Card>
                );
              })}
          </Col>

          <Col xs={24} sm={24} md={8}>
            <Card title="ORGANIZATION SEARCH" className="card-dark-head">
              <Form
                layout="vertical"
                onFinish={onSearchFinish}
                autoComplete="off"
                initialValues={{
                  state: match.params.state,
                  search: match.params.city,
                }}
                form={formSearch}
              >
                <Row gutter={12}>
                  <Col span={24}>
                    <Form.Item
                      name="state"
                      className="m-b-md form-select-error"
                    >
                      <FloatSelect
                        label="State"
                        placeholder="State"
                        options={state}
                        onChange={onChangeOption}
                        allowClear={false}
                      />
                    </Form.Item>
                  </Col>

                  <Col span={24}>
                    <Form.Item name="search" className="m-b-sm">
                      <FloatInput
                        label="Search"
                        placeholder="Search"
                        onChange={hanldeInput}
                        // allowClear={true}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </Card>
          </Col>
        </Row>
      </Card>

      <Modal
        title="APPROVE ORGANIZATION"
        visible={isModalApprove}
        onCancel={(e) => setIsModalApprove(false)}
        footer={[
          <Space>
            <Button
              key="btn"
              loading={isLoadingApprove}
              onClick={(e) => approve()}
              className="btn-warning-outline"
            >
              YES, APPROVE
            </Button>
          </Space>,
        ]}
      >
        <p>
          You are about to approve this organization and add it to the system,
          which CAN NOT be undone. Are you sure you have checked the spelling
          and did a search for this organization in the system before continuing
        </p>
      </Modal>

      <Modal
        title="EDIT ORGANIZATION"
        visible={isModalEdit}
        onCancel={(e) => setIsModalEdit(false)}
        okText="UPDATE"
        // cancelText="CANCEL"
        footer={[
          <Button
            htmlType="submit"
            className="btn-warning-outline"
            loading={isLoadingApprove}
            onClick={() => {
              form
                .validateFields()
                .then((values) => {
                  onFinishForm(values);
                })
                .catch((info) => {
                  console.log("Validate Failed:", info);
                });
            }}
          >
            UPDATE
          </Button>,
        ]}
      >
        <Form form={form} name="edit-form" layout="vertical">
          <Row gutter={24}>
            <Col xs={24} sm={24} md={24}>
              <Form.Item
                name={"name"}
                rules={[
                  {
                    required: true,
                    message: "This field field is required.",
                  },
                ]}
              >
                <FloatInput
                  label="Organization Name"
                  placeholder="Organization Name"
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={24}>
            <Col xs={24} sm={24} md={12}>
              <Form.Item
                name={"organization_type"}
                className="form-select-error"
                rules={[
                  {
                    required: true,
                    message: "This field field is required.",
                  },
                ]}
              >
                <FloatSelect
                  label="Type"
                  placeholder="Type"
                  options={optionOrganizationType}
                />
              </Form.Item>
            </Col>

            <Col xs={24} sm={24} md={12}>
              <Form.Item
                name={"state"}
                className="form-select-error"
                rules={[
                  {
                    required: true,
                    message: "This field field is required.",
                  },
                ]}
              >
                <FloatSelect
                  label="State"
                  placeholder="State"
                  options={state}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={24}>
            <Col xs={24} sm={24} md={24}>
              <Form.Item
                name={"address1"}
                rules={[
                  {
                    required: true,
                    message: "This field field is required.",
                  },
                ]}
              >
                <FloatInput label="Address 1" placeholder="Address 1" />
              </Form.Item>
            </Col>

            <Col xs={24} sm={24} md={24}>
              <Form.Item name={"address2"}>
                <FloatInput label="Address 1" placeholder="Address 1" />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={24}>
            <Col xs={24} sm={24} md={12}>
              <Form.Item
                name={"city"}
                rules={[
                  {
                    required: true,
                    message: "This field field is required.",
                  },
                ]}
              >
                <FloatInput label="City" placeholder="City" />
              </Form.Item>
            </Col>

            <Col xs={24} sm={24} md={12}>
              <Form.Item
                name={"zip"}
                rules={[
                  {
                    required: true,
                    message: "This field field is required.",
                  },
                ]}
              >
                <FloatInput label="Zip" placeholder="Zip" />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </Layout>
  );
}
