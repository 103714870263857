import { Button, Card, Col, notification, Row, Space, Table } from "antd";
import React, { useState, useEffect } from "react";
import { PlusOutlined } from "@ant-design/icons";
import { faSchool } from "@fortawesome/pro-regular-svg-icons";
// import { useHistory } from "react-router-dom";
// import $ from "jquery";

import ComponentHeader from "../../Components/ComponentHeader";
import {
  TableGlobalSearch,
  TablePageSize,
  TablePagination,
  TableShowingEntries,
} from "../../Components/ComponentTableFilter";
import { GET, POST } from "../../../../providers/useAxiosQuery";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
// import companyInfo from "../../../../providers/companyInfo";

import pdfElite from "../../../../providers/pdf/pdfElite";
import pdfStarter from "../../../../providers/pdf/pdfStarter";
import pdfPro from "../../../../providers/pdf/pdfPro";

import pdf2base64 from "pdf-to-base64";
// import pdfFileElite from "../../../../assets/pdf/2022-June-BFS-Elite-New-Report-BLANK.pdf";
// import pdfFileStarter from "../../../../assets/pdf/2022-June-BFS-New-Starter-Report-BLANK.pdf";
// import pdfFileProfessional from "../../../../assets/pdf/2022-June-BFS-New-Professional-Report-BLANK.pdf";
import $ from "jquery";

export default function PageAssessmentPdf({ match, permission }) {
  // const history = useHistory();
  // const userdata = companyInfo().userData;
  const [pdfUrlElite, setPdfUrlElite] = useState();
  const [pdfUrlStarter, setPdfUrlStarter] = useState();
  const [pdfUrlPro, setPdfUrlPro] = useState();

  useEffect(() => {
    let elite =
      process.env.REACT_APP_URL +
      "pdf/2022-June-BFS-Elite-New-Report-BLANK.pdf";
    let starter =
      process.env.REACT_APP_URL +
      "pdf/2022-June-BFS-New-Starter-Report-BLANK.pdf";
    let pro =
      process.env.REACT_APP_URL +
      "pdf/2022-June-BFS-New-Professional-Report-BLANK.pdf";
    // elite
    pdf2base64(elite)
      .then((response) => {
        // console.log("response", response); //cGF0aC90by9maWxlLmpwZw==
        setPdfUrlElite(response);
      })
      .catch((error) => {
        console.log(error); //Exepection error....
      });
    // starter
    pdf2base64(starter)
      .then((response) => {
        // console.log("response", response); //cGF0aC90by9maWxlLmpwZw==
        setPdfUrlStarter(response);
      })
      .catch((error) => {
        console.log(error); //Exepection error....
      });
    // pro
    pdf2base64(pro)
      .then((response) => {
        // console.log("response", response); //cGF0aC90by9maWxlLmpwZw==
        setPdfUrlPro(response);
      })
      .catch((error) => {
        console.log(error); //Exepection error....
      });
  }, []);

  const [tableFilter, setTableFilter] = useState({
    page: 1,
    page_size: 50,
    search: "",
    sort_field: "name",
    sort_order: "desc",
    // total: 0,
  });

  const [tableTotal, setTableTotal] = useState(0);
  const [dataSource, setDataSource] = useState([]);
  const {
    isLoading: isLoadingTable,
    isFetching: isFetchingTable,
    refetch: refetchTable,
  } = GET(
    `api/v1/assessment_pdf?${new URLSearchParams(tableFilter)}`,
    "assessment_pdf",
    (res) => {
      if (res.data) {
        console.log("res", res);
        setDataSource(res.data && res.data.data);
        setTableTotal(res.data.total);
      }
    }
  );

  const handleTableChange = (pagination, filters, sorter) => {
    setTableFilter({
      ...tableFilter,
      sort_field: sorter.columnKey,
      sort_order: sorter.order ? sorter.order.replace("end", "") : null,
      // page: 1,
    });
  };

  const [searchText, setSearchText] = useState("");
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setTableFilter({
        ...tableFilter,
        search: searchText,
        page: 1,
      });
    }, 1500);
    return () => {
      clearTimeout(timeoutId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText]);

  useEffect(() => {
    refetchTable();
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableFilter]);

  const [toggleModalForm, setToggleModalForm] = useState({
    show: false,
    data: null,
  });

  const { mutate: mutateCreateSchool, isLoading: isLoadingCreateSchool } = POST(
    "api/v1/school",
    "school_list"
  );

  const handleOnFinish = (values) => {
    console.log("handleOnFinish", values);

    let id =
      toggleModalForm && toggleModalForm.data ? toggleModalForm.data.id : "";

    let data = {
      ...values,
      id,
    };
    console.log("handleOnFinish data", data);

    mutateCreateSchool(data, {
      onSuccess: (res) => {
        if (res.success) {
          notification.success({
            message: "Success",
            description:
              "Data Successfully " + (id === "" ? "Submitted" : "Updated"),
          });

          setToggleModalForm({ show: false, data: null });
        } else {
          notification.success({
            message: "Error",
            description: "Data not " + (id === "" ? "Saved!" : "Updated!"),
          });
        }
      },
    });
  };

  const [pdfDownload, setPdfDownload] = useState({
    type: "",
    data: [],
    permission: [],
  });
  const [pdfData, setPdfData] = useState();
  const handlePdf = (record) => {
    let data = record.pdf_data ? JSON.parse(record.pdf_data) : "";
    $(".globalLoading").removeClass("hide");
    setPdfData(record);
    setPdfDownload({
      type: record.type,
      data: data,
      permission: record.pdf_display_data,
    });
  };

  useEffect(() => {
    if (pdfData) {
      const timeoutId = setTimeout(() => {
        if (
          pdfDownload.type === "Starter" ||
          pdfDownload.type === "Starter $29.99"
        ) {
          pdfStarter(pdfUrlStarter, pdfDownload.data, pdfDownload.permission);
        } else if (
          pdfDownload.type === "Professional" ||
          pdfDownload.type === "Professional $69.99"
        ) {
          pdfPro(pdfUrlPro, pdfDownload.data, pdfDownload.permission);
        } else if (
          pdfDownload.type === "Elite" ||
          pdfDownload.type === "Elite $199.99"
        ) {
          pdfElite(pdfUrlElite, pdfDownload.data, pdfDownload.permission);
        }
        console.log(pdfDownload);
        setPdfDownload({
          type: "",
          data: [],
          permission: [],
        });
        setPdfData("");
        $(".globalLoading").addClass("hide");
      }, 2000);

      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [pdfDownload]);

  return (
    <>
      <ComponentHeader title={permission} sub_title="VIEW" icon={faFilePdf} />

      <Card className="card-min-height" id="PageAssessmentPdf">
        <Row gutter={12}>
          <Col xs={24} sm={24} md={16} lg={16} xl={18} className="m-b-sm">
            <Space>
              <TablePageSize
                paginationFilter={tableFilter}
                setPaginationFilter={setTableFilter}
              />
            </Space>
          </Col>
          <Col xs={24} sm={24} md={8} lg={8} xl={6}>
            <TableGlobalSearch
              paginationFilter={searchText}
              setPaginationFilter={setSearchText}
            />
          </Col>
        </Row>
        <Row gutter={12}>
          <Col xs={24} sm={24} md={24} className="m-t-sm m-b-sm">
            <Table
              className="ant-table-default ant-table-striped"
              dataSource={dataSource && dataSource}
              rowKey={(record) => record.id}
              loading={isLoadingTable || isFetchingTable}
              pagination={false}
              bordered={false}
              onChange={handleTableChange}
              scroll={{ x: "max-content" }}
            >
              <Table.Column
                title="Athlete Name"
                key="name"
                dataIndex="name"
                sorter={true}
                defaultSortOrder="descend"
                width="150px"
                render={(text, record) => {
                  return (
                    <Link to={`/assessment-pdf/edit/${record.id}`}>{text}</Link>
                  );
                }}
              />
              <Table.Column
                title="Assessment Title"
                key="title"
                dataIndex="title"
                sorter={true}
                width="250px"
              />
              <Table.Column
                title="Assessment Type"
                key="type"
                dataIndex="type"
                sorter={true}
                width="150px"
              />
              <Table.Column
                title="Assessment"
                key="assessment_type"
                dataIndex="assessment_type"
                sorter={true}
                width="150px"
              />
              <Table.Column
                title="Action"
                key="action"
                dataIndex="action"
                align="center"
                sorter={false}
                width="100px"
                render={(_, record) => {
                  if (record.type == "Starter") {
                    return (
                      <Button
                        className="btn-main-outline-2"
                        type="link"
                        size="small"
                        onClick={() => handlePdf(record)}
                        disabled={pdfUrlStarter ? false : true}
                        loading={pdfUrlStarter ? false : true}
                      >
                        <FontAwesomeIcon icon={faFilePdf} />
                        &nbsp;Download
                      </Button>
                    );
                  } else if (record.type == "Professional") {
                    return (
                      <Button
                        className="btn-main-outline-2"
                        type="link"
                        size="small"
                        onClick={() => handlePdf(record)}
                        disabled={pdfUrlPro ? false : true}
                        loading={pdfUrlPro ? false : true}
                      >
                        <FontAwesomeIcon icon={faFilePdf} />
                        &nbsp;Download
                      </Button>
                    );
                  } else if (record.type == "Elite") {
                    return (
                      <Button
                        className="btn-main-outline-2"
                        type="link"
                        size="small"
                        onClick={() => handlePdf(record)}
                        disabled={pdfUrlElite ? false : true}
                        loading={pdfUrlElite ? false : true}
                      >
                        <FontAwesomeIcon icon={faFilePdf} />
                        &nbsp;Download
                      </Button>
                    );
                  }
                }}
              />
            </Table>
          </Col>
          <Col xs={24} sm={24} md={24}>
            <Space className="ant-space-flex-space-between">
              <TableShowingEntries />

              <TablePagination
                paginationFilter={tableFilter}
                setPaginationFilter={setTableFilter}
                setPaginationTotal={tableTotal}
                showLessItems={true}
                showSizeChanger={false}
              />
            </Space>
          </Col>
          <Col xs={24} sm={24} md={12}></Col>
        </Row>

        <br />
      </Card>
    </>
  );
}
