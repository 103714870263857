import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Layout,
  notification,
  Row,
  Collapse,
} from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import FloatInput from "../../../../providers/FloatInput";
import FloatSelect from "../../../../providers/FloatSelect";
// import optionStateCodes from "../../../../providers/optionStateCodes";
import ComponentHeader from "../../Components/ComponentHeader";
import { faSchool } from "@fortawesome/pro-regular-svg-icons";
import { GET, POST, UPDATE } from "../../../../providers/useAxiosQuery";
import { useHistory } from "react-router-dom";

import optionCountryCodes from "../../../../providers/optionCountryCodes";
import optionStateCodesUnitedState from "../../../../providers/optionStateCodesUnitedState";
import optionStateCodesCanada from "../../../../providers/optionStateCodesCanada";
import optionOrganizationType from "../../../../providers/optionOrganizationType";
import companyInfo from "../../../../providers/companyInfo";

const userdata = companyInfo().userData;
export default function PageOrganizationEdit({ match, permission }) {
  const { Panel } = Collapse;
  const [form] = Form.useForm();
  const history = useHistory();
  const id = match.params.id;
  const onFinish = (values) => {
    console.log("onFinish", values);
    let data = {
      ...values,
      id: id,
    };
    mudateAdd(data, {
      onSuccess: (res) => {
        if (res.success) {
          notification.success({
            message: "Success",
            description: "Successfully updated",
          });
        }
      },
    });
  };

  const { mutate: mudateAdd, isLoadding: isLoaddingAdd } = UPDATE(
    "api/v1/organization",
    "organization"
  );

  const [country, setCountry] = useState(optionCountryCodes);
  const [stateUS, setStateUS] = useState(optionStateCodesUnitedState());
  const [stateCA, setStateCA] = useState(optionStateCodesCanada());

  const [stateLabel, setStateLabel] = useState("State");
  const [optionState, setOptionState] = useState(stateUS);
  const [zipLabel, setZipLabel] = useState("Zip Code");
  const [optionZip, setOptionZip] = useState(/(^\d{5}$)|(^\d{5}[ -]\d{4}$)/);

  const { data: dataManagerArea, isLoading: isLoadingManagerArea } = GET(
    "api/v1/get_manager_state_area_data",
    "get_manager_state_area",
    (res) => {
      if (res.success) {
        console.log("get_manager_state_area", res);
        if (userdata.role === "Manager") {
          setCountry(res.countries);
          if (res.statesUS.length > 0) {
            let option_states = [];
            res.statesUS.map((row, key) => {
              stateUS.find((search) => {
                if (search.value == row.value) {
                  option_states.push({
                    ...row,
                    label: search.label,
                  });
                }
              });
            });
            setStateUS(option_states);
          }
          if (res.statesCA.length > 0) {
            let option_states = [];
            res.statesCA.map((row, key) => {
              stateCA.find((search) => {
                if (search.value == row.value) {
                  option_states.push({
                    ...row,
                    label: search.label,
                  });
                }
              });
            });
            setStateCA(option_states);
          }
        } else {
          setCountry(optionCountryCodes);
        }
      }
    }
  );

  const handleCountry = (val, opt) => {
    if (val === "United States") {
      setStateLabel("State");
      setZipLabel("Zip Code");
      setOptionState(stateUS);
      setOptionZip(/(^\d{5}$)|(^\d{5}[ -]\d{4}$)/);
    } else if (val === "Canada") {
      setStateLabel("County");
      setZipLabel("Postal Code");
      setOptionState(stateCA);
      setOptionZip(/^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/);
    } else {
      setStateLabel("State");
      setZipLabel("Zip Code");
      setOptionState(stateUS);
      setOptionZip(/(^\d{5}$)|(^\d{5}[ -]\d{4}$)/);
    }
    if (val) {
      setFormData(val);
    } else {
      setFormData("remove");
    }
    setCItyOption([]);
    form.resetFields(["state", "zip", "city"]);
  };

  const [title, setTitle] = useState("");
  const [cItyOption, setCItyOption] = useState([]);
  const {} = GET(`api/v1/organization/${id}`, "organization", (res) => {
    if (res.success) {
      //   console.log(res);
      form.setFieldsValue({
        name: res.data.name ? res.data.name : undefined,
        country: res.data.country ? res.data.country : undefined,
        address1: res.data.address1 ? res.data.address1 : undefined,
        address2: res.data.address2 ? res.data.address2 : undefined,
        organization_type: res.data.organization_type
          ? res.data.organization_type
          : undefined,
        state: res.data.state ? res.data.state : undefined,
        status: res.data.status ? res.data.status : undefined,
        city: res.data.city ? res.data.city : undefined,
        zip: res.data.zip ? res.data.zip : undefined,
      });

      setTitle(res.data.name);

      if (res.data.country === "United States") {
        setStateLabel("State");
        setZipLabel("Zip Code");
        setOptionState(stateUS);
        setOptionZip(/(^\d{5}$)|(^\d{5}[ -]\d{4}$)/);

        let get_city = stateUS.filter((item) => res.data.state === item.value);
        setCItyOption(get_city[0]["json"]);
      } else if (res.data.country === "Canada") {
        setStateLabel("County");
        setZipLabel("Postal Code");
        setOptionState(stateCA);

        let get_city = stateCA.filter((item) => res.data.state === item.value);
        setCItyOption(get_city[0]["json"]);
        setOptionZip(/^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/);
      } else {
        setStateLabel("State");
        setZipLabel("Zip Code");
        setOptionState(stateUS);
        setOptionZip(/(^\d{5}$)|(^\d{5}[ -]\d{4}$)/);

        let get_city = stateUS.filter((item) => res.data.state === item.value);
        setCItyOption(get_city[0]["json"]);
      }
    }
  });

  const [formData, setFormData] = useState("");

  const handleOchangeInput = (val) => {
    if (val) {
      setFormData(val);
    } else {
      setFormData("remove");
    }
  };

  const handleOchangeSelect = (val, opt) => {
    if (val) {
      setFormData(val);
    } else {
      setFormData("remove");
    }
  };

  const handleBlur = (value, field) => {
    if (formData) {
      form.submit();
    }
  };

  const handleBlurSelect = (value, field) => {
    console.log("handleBlurSelect", value);
    console.log("handleBlurSelect", formData);
    if (value) {
      form.submit();
    }
  };

  return (
    <>
      <ComponentHeader
        title="Organization"
        sub_title="VIEW / EDIT"
        icon={faSchool}
      />

      <Card className="p-b-lg card-min-height" id="PageOrganizationEdit">
        <Row gutter={12}>
          <Col xs={24} sm={24} md={16} className="ant-card-form-list">
            <Form
              layout="vertical"
              onFinish={onFinish}
              autoComplete="off"
              initialValues={{ organizations: [""] }}
              form={form}
            >
              <Collapse
                className="main-1-collapse"
                expandIcon={({ isActive }) =>
                  isActive ? (
                    <span
                      className="ant-menu-submenu-arrow"
                      style={{
                        color: "#FFF",
                        transform: "rotate(270deg)",
                      }}
                    ></span>
                  ) : (
                    <span
                      className="ant-menu-submenu-arrow"
                      style={{
                        color: "#FFF",
                        transform: "rotate(90deg)",
                      }}
                    ></span>
                  )
                }
                defaultActiveKey={["1"]}
                expandIconPosition="start"
              >
                <Panel
                  header={`ORGANIZATION - ${title}`}
                  key="1"
                  className="accordion bg-darkgray-form"
                >
                  <Row gutter={24}>
                    <Col xs={24} sm={24} md={8}>
                      <Form.Item
                        name="country"
                        className="form-select-error"
                        rules={[
                          {
                            required: true,
                            message: "This field field is required.",
                          },
                        ]}
                      >
                        <FloatSelect
                          label="Country"
                          placeholder="Country"
                          options={country}
                          onChange={handleCountry}
                          // disabled={true}
                          required
                        />
                      </Form.Item>
                    </Col>

                    <Col xs={24} sm={24} md={8}>
                      <Form.Item
                        name="organization_type"
                        className="form-select-error"
                        rules={[
                          {
                            required: true,
                            message: "This field field is required.",
                          },
                        ]}
                      >
                        <FloatSelect
                          label="Type"
                          placeholder="Type"
                          options={optionOrganizationType}
                          onChange={handleOchangeSelect}
                          onBlurInput={handleBlur}
                          required
                        />
                      </Form.Item>
                    </Col>

                    <Col xs={24} sm={24} md={8}>
                      <Form.Item
                        name="state"
                        className="form-select-error"
                        rules={[
                          {
                            required: true,
                            message: "This field field is required.",
                          },
                        ]}
                      >
                        <FloatSelect
                          label={stateLabel}
                          placeholder={stateLabel}
                          options={optionState}
                          onChange={(val, opt) => {
                            handleOchangeSelect(val, opt);
                            if (val) {
                              setCItyOption(opt["data-json"]);
                            }
                          }}
                          required
                          // disabled={true}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={24}>
                    <Col xs={24} sm={24} md={24}>
                      <Form.Item
                        name="name"
                        rules={[
                          {
                            required: true,
                            message: "This field field is required.",
                          },
                        ]}
                      >
                        <FloatInput
                          label="Organization Name"
                          placeholder="Organization Name"
                          onChange={handleOchangeInput}
                          onBlurInput={handleBlur}
                          required
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row gutter={24}>
                    <Col xs={24} sm={24} md={24}>
                      <Form.Item
                        name="address1"
                        rules={[
                          {
                            required: true,
                            message: "This field field is required.",
                          },
                        ]}
                      >
                        <FloatInput
                          label="Address 1"
                          placeholder="Address 1"
                          onChange={handleOchangeInput}
                          onBlurInput={handleBlur}
                          required
                        />
                      </Form.Item>
                    </Col>

                    <Col xs={24} sm={24} md={24}>
                      <Form.Item name="address2">
                        <FloatInput
                          label="Address 2"
                          placeholder="Address 2"
                          onChange={handleOchangeInput}
                          onBlurInput={handleBlur}
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row gutter={24}>
                    <Col xs={24} sm={24} md={8}>
                      <Form.Item
                        name="city"
                        rules={[
                          {
                            required: true,
                            message: "This field field is required.",
                          },
                        ]}
                        className="form-select-error"
                      >
                        <FloatSelect
                          label="City"
                          placeholder="City"
                          onChange={handleOchangeInput}
                          onBlurInput={handleBlur}
                          options={cItyOption}
                          required
                        />
                      </Form.Item>
                    </Col>

                    <Col xs={24} sm={24} md={8}>
                      <Form.Item
                        name={"zip"}
                        rules={[
                          {
                            required: true,
                            message: "This field field is required.",
                          },
                          {
                            pattern: optionZip,
                            message: "Invalid " + zipLabel,
                          },
                        ]}
                      >
                        <FloatInput
                          label={zipLabel}
                          placeholder={zipLabel}
                          onChange={handleOchangeInput}
                          onBlurInput={handleBlur}
                          required
                        />
                      </Form.Item>
                    </Col>

                    {userdata.role === "Admin" && (
                      <Col xs={24} sm={24} md={8}>
                        <Form.Item
                          name={"status"}
                          rules={[
                            {
                              required: true,
                              message: "This field field is required.",
                            },
                          ]}
                        >
                          <FloatSelect
                            label={"Status"}
                            placeholder={"Status"}
                            options={[
                              {
                                value: "Pending",
                                label: "Pending",
                              },
                              {
                                value: "Approved",
                                label: "Approved",
                              },
                              {
                                value: "Deleted",
                                label: "Deleted",
                              },
                            ]}
                            onChange={(val, opt) => {
                              handleOchangeSelect(val, opt);
                            }}
                            onBlurInput={handleBlurSelect}
                            required
                          />
                        </Form.Item>
                      </Col>
                    )}
                  </Row>
                </Panel>
              </Collapse>
            </Form>
          </Col>
        </Row>
        <br />

        {/* <Button
          type="primary"
          htmlType="submit"
          loading={isLoaddingAdd}
          className="btn-main-outline-2"
          size="large"
          onClick={(e) => form.submit()}
        >
          SAVE ALL CHANGES
        </Button> */}
      </Card>
    </>
  );
}
