import { useState } from "react";
import { Card } from "antd";
import ComponentHeader from "../../Components/ComponentHeader";
// import ModalCalendarUpcomingGroupAssessmentsPreview from "./ModalCalendarUpcomingGroupAssessmentsPreview";
import ComponentDashboard from "../../Components/ComponentDashboard";
import moment from "moment";
import { faCalendarAlt } from "@fortawesome/pro-regular-svg-icons";

export default function PageCalendarScheduledAssessments(props) {
  const query = new URLSearchParams(props.location.search);
  const event_id = query.get("event_id");

  // console.log("event_id", event_id);

  const [dataFilter, setDataFilter] = useState({
    date: moment().format("MM/DD/YYYY"),
    type: "Group",
    search: "",
    category: "",
    tag: "",
    country: "",
    state: "",
    city: "",
    from: "athlete_my_assessments",
    legend: true,
    event_id: event_id ? event_id : null,

    // col_lg: 8,
    // col_xl: 8,
  });

  return (
    <>
      <ComponentHeader
        title="Assessments"
        sub_title="My Scheduled"
        icon={faCalendarAlt}
      />

      <Card className="card-min-height" id="PageCalendarScheduledAssessments">
        <ComponentDashboard
          dataFilter={dataFilter}
          setDataFilter={setDataFilter}
          column={12}
          column_lg={6}
          column_xl={6}
        />
      </Card>
    </>
  );
}
