import React from "react";
import { Route, Redirect } from "react-router-dom";
import Private from "../layouts/private/Private";

let isLoggedIn = localStorage.getItem("token");

const PrivateRoute = (props) => {
  const {
    path: Path,
    component: Component,
    permission: Permission,
    subtitle: Subtitle,
    title: Title,
    breadcrumb: Breadcrumb,
    ...rest
  } = props;

  return (
    <Route
      {...rest}
      render={(props) =>
        isLoggedIn ? (
          <Private
            title={Title}
            permission={Permission}
            subtitle={Subtitle}
            breadcrumb={Breadcrumb}
            path={Path}
          >
            <Component
              title={Title}
              subtitle={Subtitle}
              permission={Permission}
              {...props}
            />
          </Private>
        ) : (
          <Redirect to={{ pathname: "/500" }} />
        )
      }
    />
  );
};

export default PrivateRoute;
