import React, { useState, useEffect } from "react";
import {
  Card,
  Form,
  Button,
  Row,
  Col,
  Radio,
  Divider,
  message,
  Collapse,
  notification,
  Typography,
} from "antd";
import ComponentHeader from "../../Components/ComponentHeader";
import ImgCrop from "antd-img-crop";
import { Upload } from "antd";

import FloatInput from "../../../../providers/FloatInput";
import { faUser } from "@fortawesome/pro-regular-svg-icons";

import ModalProfileChangePassword from "../../Components/ModalProfileChangePassword";
import FloatSelect from "../../../../providers/FloatSelect";
import { GET, POST } from "../../../../providers/useAxiosQuery";
import companyInfo from "../../../../providers/companyInfo";
import FloatInputMask from "../../../../providers/FloatInputMask";
import optionCountryCodes from "../../../../providers/optionCountryCodes";
import optionStateCodesUnitedState from "../../../../providers/optionStateCodesUnitedState";
import optionStateCodesCanada from "../../../../providers/optionStateCodesCanada";
import { Link, useHistory } from "react-router-dom";
import { EditOutlined } from "@ant-design/icons";
import FloatInputMaskNoFeedback from "../../../../providers/FloatInputMaskNoFeedback";

export default function PageProfileManager({ props, permission }) {
  const [form] = Form.useForm();
  const { Panel } = Collapse;
  const userdata = companyInfo().userData;
  const encryptor = companyInfo().encryptor;
  const history = useHistory();

  const validator = {
    require: {
      required: true,
      message: "This field is required.",
    },
  };

  const [fileList, setFileList] = useState([]);
  const [radioData, setRadioData] = useState(1);
  const [imageCrop, setImageCrop] = useState({
    width: 1,
    height: 1,
  });

  const handleResize = (val) => {
    console.log("val", val.target.value);
    setRadioData(val.target.value);
    if (val.target.value === 1) {
      setImageCrop({
        width: 1,
        height: 1,
      });
    } else if (val.target.value === 2) {
      setImageCrop({
        width: 3.9,
        height: 2.6,
      });
    } else if (val.target.value === 3) {
      setImageCrop({
        width: 1,
        height: 1.5,
      });
    }
  };

  const onChange = ({ fileList: newFileList }) => {
    var _file = newFileList;
    console.log(_file);
    if (_file.length !== 0) {
      _file[0].status = "done";
      setFileList(_file);
      // setDataForm("update_image");
      form.submit();
    } else {
      // setDataForm("update_image_remove");
      setFileList([]);
    }
  };

  const onPreview = async (file) => {
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow.document.write(image.outerHTML);
  };

  const beforeUpload = (file) => {
    const isJpgOrPng =
      file.type === "image/jpeg" ||
      file.type === "image/png" ||
      file.type === "image/gif" ||
      file.type === "image/jpg";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG, PNG, GIF, JPEG file!");
      setFileList([]);
      return;
    }
    const isLt2M = file.size / 102400 / 102400 < 10;
    if (!isLt2M) {
      message.error("Image must smaller than 10MB!");
      setFileList([]);
      return;
    }
    return isJpgOrPng && isLt2M;
  };

  const onFinish = (values) => {
    // console.log("onFinish", values);
    const data = new FormData();
    data.append("id", values.id);
    data.append("firstname", values.firstname);
    data.append("lastname", values.lastname);
    data.append("phone_number", values.phone_number);
    data.append("photo_crop", radioData);

    // address
    data.append("country", values.country);
    data.append("address1", values.address1);
    data.append("address2", values.address2 ? values.address2 : "");
    data.append("unit", values.unit ? values.unit : "");
    data.append("state", values.state);
    data.append("city", values.city ? values.city : "");
    data.append("zip_code", values.zip_code);

    if (fileList.length !== 0) {
      if (fileList[0].uid !== "-1") {
        data.append(
          "profile_image",
          fileList[0].originFileObj,
          fileList[0].name
        );
      }
    } else {
      console.log("fileList", fileList);
    }

    mudateUpdate(data, {
      onSuccess: (res) => {
        if (res.success) {
          notification.success({
            message: "Success",
            description: "Successfully updated.",
          });

          setProfileData(res.data);

          // localStorage.userdata = encryptor.encrypt(res.data);
        }
      },
    });
  };

  const { mutate: mudateUpdate, isLoading: isLoadingUpdate } = POST(
    "api/v1/update_profile",
    "update_profile"
  );

  const [isChangePasswordVisible, setIsChangePasswordVisible] = useState(false);

  const [profileData, setProfileData] = useState([]);

  const country = optionCountryCodes;
  const stateUS = optionStateCodesUnitedState();
  const stateCA = optionStateCodesCanada();

  const [cityState, setCityState] = useState([]);
  const { isLoading: isLoadingGet } = GET(
    `api/v1/users/${userdata.id}`,
    "update_profile",
    (res) => {
      if (res.success) {
        console.log("update_profile", res);
        setProfileData(res.data);
        form.setFieldsValue({
          username: res.data.username,

          role: res.data.role,
          id: res.data.id,

          firstname: res.data.firstname,
          lastname: res.data.lastname,
          phone_number: res.data.phone_number,
        });
        if (res.data.phone_number === null || res.data.phone_number === "") {
          form.resetFields(["phone_number"]);
        }

        if (Number(res.data.photo_crop) === 1) {
          setImageCrop({
            width: 1,
            height: 1,
          });
          setRadioData(1);
        } else if (Number(res.data.photo_crop) === 2) {
          setImageCrop({
            width: 3.9,
            height: 2.6,
          });
          setRadioData(2);
        } else if (Number(res.data.photo_crop) === 3) {
          setImageCrop({
            width: 1,
            height: 1.5,
          });
          setRadioData(3);
        } else {
          setImageCrop({
            width: 1,
            height: 1,
          });
          setRadioData(1);
        }

        // stateUS
        // stateCA
        if (res.data.user_address) {
          if (res.data.user_address[0].country === "United States") {
            setOptionState(stateUS);
            let get_city = stateUS.filter(
              (item) => res.data.user_address[0].state === item.value
            );
            console.log("get_city", get_city);
            setCityState(get_city[0]["json"]);
            setOptionZip(/(^\d{5}$)|(^\d{5}[ -]\d{4}$)/);
            setStateLabel("State");
            setZipLabel("Zip Code");
          } else if (res.data.user_address[0].country === "Canada") {
            setOptionState(stateCA);
            let get_city = stateCA.filter(
              (item) => res.data.user_address[0].state === item.value
            );
            setCityState(get_city[0]["json"]);
            setOptionZip(/^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/);
            setStateLabel("County");
            setZipLabel("Postal Code");
          }
        }

        if (res.data.user_address.length > 0) {
          form.setFieldsValue({
            country: res.data.user_address[0].country,
            address1: res.data.user_address[0].address1,
            address2: res.data.user_address[0].address2,
            unit: res.data.user_address[0].unit,
            state: res.data.user_address[0].state,
            city: res.data.user_address[0].city
              ? res.data.user_address[0].city
              : undefined,
            zip_code: res.data.user_address[0].zip_code,
          });

          if (
            res.data.user_address[0].address2 === null ||
            res.data.user_address[0].address2 === ""
          ) {
            form.resetFields(["address2"]);
          }

          if (
            res.data.user_address[0].unit === null ||
            res.data.user_address[0].unit === ""
          ) {
            form.resetFields(["unit"]);
          }
        }

        let image_type = res.data.profile_image
          ? res.data.profile_image.split("/")
          : "";
        if (image_type[0] !== undefined) {
          image_type =
            image_type === "https:"
              ? res.data.profile_image
              : companyInfo().apiUrl + res.data.profile_image;

          setFileList([
            {
              uid: "-1",
              name: "image.png",
              status: "done",
              url: image_type,
            },
          ]);
        } else {
          setFileList([]);
          image_type = "";
        }
      }
    }
  );

  const [stateLabel, setStateLabel] = useState("State");
  const [optionState, setOptionState] = useState(stateUS);
  const [zipLabel, setZipLabel] = useState("Zip Code");
  const [optionZip, setOptionZip] = useState(/(^\d{5}$)|(^\d{5}[ -]\d{4}$)/);

  const [dataForm, setDataForm] = useState("");
  const onChangeInput = (val) => {
    console.log("handleInputBlur", val);
    if (val) {
      setDataForm(val);
    } else {
      setDataForm("remove");
    }
  };

  const onChangeSelect = (val, opt) => {
    console.log("onChangeSelect", val);
    if (val) {
      // setDataForm(val);
      form.submit();
    } else {
      // setDataForm("remove");
    }
  };

  const handleInputBlur = (value, field) => {
    console.log("handleInputBlur", value, field);
    if (dataForm) {
      form.submit();
      setDataForm("");
    }
  };

  const handleCountry = (val, opt) => {
    if (val === "United States") {
      setStateLabel("State");
      setZipLabel("Zip Code");
      setOptionState(stateUS);
      setOptionZip(/(^\d{5}$)|(^\d{5}[ -]\d{4}$)/);
    } else if (val === "Canada") {
      setStateLabel("County");
      setZipLabel("Postal Code");
      setOptionState(stateCA);
      setOptionZip(/^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/);
    } else {
      setStateLabel("State");
      setZipLabel("Zip Code");
      setOptionState(stateUS);
      setOptionZip(/(^\d{5}$)|(^\d{5}[ -]\d{4}$)/);
    }

    form.resetFields(["state", "city", "zip_code"]);
    setCityState([]);
  };

  return (
    <>
      <ComponentHeader title={permission} sub_title="EDIT" icon={faUser} />

      <ModalProfileChangePassword
        toggleModalPreview={isChangePasswordVisible}
        setToggleModalPreview={setIsChangePasswordVisible}
        profileData={profileData}
      />
      <Card className="p-b-lg" id="PageProfileManager">
        <Form
          name="assessment-form"
          form={form}
          onFinish={onFinish}
          loading={isLoadingUpdate || isLoadingGet}
        >
          <Row gutter={12}>
            <Col xs={24} sm={24} md={16}>
              <Collapse
                className="main-1-collapse"
                expandIcon={({ isActive }) =>
                  isActive ? (
                    <span
                      className="ant-menu-submenu-arrow"
                      style={{ color: "#FFF", transform: "rotate(270deg)" }}
                    ></span>
                  ) : (
                    <span
                      className="ant-menu-submenu-arrow"
                      style={{ color: "#FFF", transform: "rotate(90deg)" }}
                    ></span>
                  )
                }
                defaultActiveKey={["1"]}
                expandIconPosition="start"
              >
                <Panel
                  header="LOGIN INFORMATION"
                  key="1"
                  className="accordion bg-darkgray-form"
                >
                  <Row gutter={24}>
                    <Col xs={24} sm={24} md={24}>
                      <Form.Item name="username" rules={[validator.require]}>
                        <FloatInput
                          label="Username"
                          placeholder="Username"
                          disabled={true}
                          required
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row gutter={24}>
                    <Col xs={24} sm={24} md={24}>
                      <Button
                        type="link"
                        className="btn-link-main-3 btn-p-0"
                        onClick={(e) => setIsChangePasswordVisible(true)}
                      >
                        Change Password
                      </Button>
                    </Col>
                  </Row>
                </Panel>
              </Collapse>
              <br />

              <Collapse
                className="main-1-collapse"
                expandIcon={({ isActive }) =>
                  isActive ? (
                    <span
                      className="ant-menu-submenu-arrow"
                      style={{ color: "#FFF", transform: "rotate(270deg)" }}
                    ></span>
                  ) : (
                    <span
                      className="ant-menu-submenu-arrow"
                      style={{ color: "#FFF", transform: "rotate(90deg)" }}
                    ></span>
                  )
                }
                defaultActiveKey={["1"]}
                expandIconPosition="start"
              >
                <Panel
                  header="BANK DETAILS"
                  key="1"
                  className="accordion bg-darkgray-form"
                >
                  {/* <Typography.Text strong>FREE ASSESSMENTS</Typography.Text>
                  <br />
                  <Typography.Text>
                    To post free assessments, bank account details are not
                    required
                  </Typography.Text>
                  <br />
                  <br />
                  */}
                  <Typography.Text strong>SPLIT PAYMENT</Typography.Text>
                  <br />
                  <Typography.Text>
                    To enable split payment, bank account details are required
                  </Typography.Text>
                  <br />
                  <br />

                  <Button
                    size="large"
                    className="btn-danger-outline"
                    icon={<EditOutlined />}
                    onClick={(e) => history.push("/profile/bank-details")}
                  >
                    Edit Bank Details
                  </Button>
                </Panel>
              </Collapse>
              <br />

              <Collapse
                className="main-1-collapse"
                expandIcon={({ isActive }) =>
                  isActive ? (
                    <span
                      className="ant-menu-submenu-arrow"
                      style={{ color: "#FFF", transform: "rotate(270deg)" }}
                    ></span>
                  ) : (
                    <span
                      className="ant-menu-submenu-arrow"
                      style={{ color: "#FFF", transform: "rotate(90deg)" }}
                    ></span>
                  )
                }
                defaultActiveKey={["1"]}
                expandIconPosition="start"
              >
                <Panel
                  header="USER'S INFORMATION"
                  key="1"
                  className="accordion bg-darkgray-form"
                >
                  <Row gutter={24}>
                    <Col xs={24} sm={24} md={12}>
                      <Form.Item name="role" rules={[validator.require]}>
                        <FloatInput
                          label="Role"
                          placeholder="Role"
                          disabled={true}
                          required
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12}>
                      <Form.Item name="id" rules={[validator.require]}>
                        <FloatInput
                          label="Manager ID"
                          placeholder="Manager ID"
                          disabled={true}
                          required
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row gutter={24}>
                    <Col xs={24} sm={24} md={12}>
                      <Form.Item name="firstname" rules={[validator.require]}>
                        <FloatInput
                          label="First Name"
                          placeholder="First Name"
                          onBlurInput={(e) => handleInputBlur(e, "firstname")}
                          onChange={onChangeInput}
                          required
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12}>
                      <Form.Item name="lastname" rules={[validator.require]}>
                        <FloatInput
                          label="Last Name"
                          placeholder="Last Name"
                          onBlurInput={(e) => handleInputBlur(e, "lastname")}
                          onChange={onChangeInput}
                          required
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row gutter={24}>
                    <Col xs={24} sm={24} md={12}>
                      <Form.Item
                        name="phone_number"
                        rules={[
                          validator.require,
                          {
                            pattern:
                              /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/,
                            message: "Invalid Phone Number",
                          },
                        ]}
                      >
                        <FloatInputMaskNoFeedback
                          label="Phone Number"
                          placeholder="Phone Number"
                          maskLabel="phone_number"
                          maskType="999-999-9999"
                          onBlurInput={(e) =>
                            handleInputBlur(e, "phone_number")
                          }
                          onChange={onChangeInput}
                          required
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12}>
                      <Form.Item
                        name="country"
                        className="form-select-error"
                        rules={[validator.require]}
                      >
                        <FloatSelect
                          label="Country"
                          placeholder="Country"
                          options={country}
                          onChange={handleCountry}
                          onBlurInput={(val, opt) =>
                            handleInputBlur(val, "country")
                          }
                          required
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row gutter={24}>
                    <Col xs={24} sm={24} md={24}>
                      <Form.Item name="address1" rules={[validator.require]}>
                        <FloatInput
                          label="Address 1"
                          placeholder="Address 1"
                          onBlurInput={(e) => handleInputBlur(e, "address1")}
                          onChange={onChangeInput}
                          required
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row gutter={24}>
                    <Col xs={24} sm={24} md={16}>
                      <Form.Item name="address2">
                        <FloatInput
                          label="Address 2"
                          placeholder="Address 2"
                          onBlurInput={(e) => handleInputBlur(e, "address2")}
                          onChange={onChangeInput}
                        />
                      </Form.Item>
                    </Col>

                    <Col xs={24} sm={24} md={8}>
                      <Form.Item name="unit">
                        <FloatInput
                          label="Apt/Suite/Unit"
                          placeholder="Apt/Suite/Unit"
                          onBlurInput={(e) => handleInputBlur(e, "unit")}
                          onChange={onChangeInput}
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row gutter={24}>
                    <Col xs={24} sm={24} md={8}>
                      <Form.Item
                        className="form-select-error"
                        name="state"
                        rules={[validator.require]}
                      >
                        <FloatSelect
                          label={stateLabel}
                          placeholder={stateLabel}
                          options={optionState}
                          onBlurInput={(val, opt) =>
                            handleInputBlur(val, "state")
                          }
                          onChange={(val, opt) => {
                            if (val) {
                              onChangeSelect(val, opt);
                              let city_opt = opt["data-json"];
                              setCityState(city_opt);
                              // let country = form.getFieldValue().country;
                              // if (country === "United States") {
                              //   let get_city = stateUS.filter(
                              //     (item) =>
                              //       res.data.user_address[0].state === item.value
                              //   );
                              //   console.log("get_city", get_city);
                              //   setCityState(get_city[0]["json"]);
                              // } else if (country === "Canada") {
                              //   let get_city = stateCA.filter(
                              //     (item) =>
                              //       res.data.user_address[0].state === item.value
                              //   );
                              //   setCityState(get_city[0]["json"]);
                              // }

                              // form.resetFields(["city"]);
                              form.setFieldsValue({
                                city: undefined,
                              });
                            }
                          }}
                          required
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={8}>
                      <Form.Item
                        name="city"
                        rules={[validator.require]}
                        className="form-select-error"
                      >
                        <FloatSelect
                          label="City"
                          placeholder="City"
                          onBlurInput={(val, opt) => {
                            if (val) {
                              handleInputBlur(val, "city");
                            }
                          }}
                          onChange={onChangeSelect}
                          options={cityState}
                          required
                        />
                      </Form.Item>
                    </Col>

                    <Col xs={24} sm={24} md={8}>
                      <Form.Item
                        name="zip_code"
                        rules={[
                          {
                            required: true,
                            message: "This field field is required.",
                          },
                          {
                            pattern: optionZip,
                            message: "Invalid " + zipLabel,
                          },
                        ]}
                      >
                        <FloatInput
                          label={zipLabel}
                          placeholder={zipLabel}
                          onBlurInput={(e) => handleInputBlur(e, "zip_code")}
                          onChange={onChangeInput}
                          required
                        />
                      </Form.Item>
                    </Col>

                    <Col xs={24} sm={24} md={24} lg={24}>
                      <Link className="companyColor1" to="/profile/2fa">
                        <span className="companyColor3">CLICK HERE</span> to
                        enable 2-Factor Authentication (2FA)
                      </Link>
                    </Col>
                  </Row>
                </Panel>
              </Collapse>
              <br />
            </Col>

            <Col xs={24} sm={24} md={8}>
              <Collapse
                className="main-1-collapse"
                expandIcon={({ isActive }) =>
                  isActive ? (
                    <span
                      className="ant-menu-submenu-arrow"
                      style={{ color: "#FFF", transform: "rotate(270deg)" }}
                    ></span>
                  ) : (
                    <span
                      className="ant-menu-submenu-arrow"
                      style={{ color: "#FFF", transform: "rotate(90deg)" }}
                    ></span>
                  )
                }
                defaultActiveKey={["1"]}
                expandIconPosition="start"
              >
                <Panel
                  header="MY PROFILE PHOTO"
                  key="1"
                  className="accordion bg-darkgray-form"
                >
                  <label className="font-red">
                    <b>
                      Photo upload & cropping: select your image orientation
                    </b>
                  </label>
                  <br />
                  <Radio.Group onChange={handleResize} value={radioData}>
                    <Radio value={1}>Square</Radio>
                    <Radio value={2}>Rectangle</Radio>
                    <Radio value={3}>Portrait</Radio>
                  </Radio.Group>
                  <Divider />
                  <div style={{ display: "flex" }}>
                    <ImgCrop rotate aspect={imageCrop.width / imageCrop.height}>
                      <Upload
                        // action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                        listType="picture-card"
                        style={{ width: "200px" }}
                        maxCount={1}
                        action={false}
                        customRequest={false}
                        fileList={fileList}
                        onChange={onChange}
                        onPreview={onPreview}
                        beforeUpload={beforeUpload}
                        className="profilePhoto"
                        showUploadList={{
                          showPreviewIcon: false,
                        }}
                      >
                        {fileList.length < 1 && "+ Upload"}
                      </Upload>
                    </ImgCrop>
                  </div>
                </Panel>
              </Collapse>
            </Col>
          </Row>

          {/* <Row gutter={12}>
            <Col xs={24} sm={24} md={8}>
              <br />
              <Button
                htmlType="submit"
                className="btn-main-outline-2"
                size="large"
                loading={isLoadingUpdate}
              >
                <FontAwesomeIcon icon={faSave} /> &nbsp; SAVE ALL CHANGES
              </Button>
            </Col>
          </Row> */}
        </Form>
      </Card>
    </>
  );
}
