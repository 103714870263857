import {
  Button,
  Card,
  Col,
  Layout,
  notification,
  Popconfirm,
  Row,
  Space,
  Table,
} from "antd";
import React, { useState, useEffect } from "react";
import { faHandHoldingUsd } from "@fortawesome/pro-regular-svg-icons";
import {
  TableGlobalSearch,
  TablePageSize,
  TablePagination,
  TableShowingEntries,
} from "../../Components/ComponentTableFilter";
import $ from "jquery";
// import {  useHistory } from "react-router-dom";
import { GET, POST } from "../../../../providers/useAxiosQuery";
// import companyInfo from "../../../../providers/companyInfo";
import ComponentHeader from "../../Components/ComponentHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

export default function PageRevenueOrganizationsPendingPayment() {
  // const history = useHistory();
  // const { Panel } = Collapse;
  // const userdata = companyInfo().userData;
  const [tableFilter, setTableFilter] = useState({
    page: 1,
    page_size: 50,
    search: "",
    sort_field: "strcreated",
    sort_order: "desc",
    admin_funds_status: "Pending",
  });

  const [tableTotal, setTableTotal] = useState(0);
  const [dataSource, setDataSource] = useState([]);
  const {
    refetch: refetchTable,
    isLoading: isLoadingTable,
    isFetching: isFetchingTable,
  } = GET(
    `api/v1/funds?${$.param(tableFilter)}`,
    "funds_organization",
    (res) => {
      if (res.success) {
        console.log("revenue_manager", res);
        setDataSource(res.data && res.data.data);
        setTableTotal(res.data.total);
      }
    }
  );

  const handleTableChange = (pagination, filters, sorter) => {
    setTableFilter({
      ...tableFilter,
      sort_field: sorter.columnKey,
      sort_order: sorter.order ? sorter.order.replace("end", "") : null,
      // page: 1,
    });
  };

  const [searchText, setSearchText] = useState("");
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setTableFilter({
        ...tableFilter,
        search: searchText,
        page: 1,
      });
    }, 1500);
    return () => {
      clearTimeout(timeoutId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText]);

  useEffect(() => {
    refetchTable();
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableFilter]);

  // const [toggleModalPreview, setToggleModalPreview] = useState({
  //   show: false,
  //   data: null,
  //   type: "Starter",
  // });

  const [selectionType, setSelectionType] = useState([]);
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      // console.log(
      //   `selectedRowKeys: ${selectedRowKeys}`,
      //   "selectedRows: ",
      //   selectedRows
      // );

      setSelectionType(selectedRowKeys);
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User",
      // Column configuration not to be checked
      name: record.name,
    }),
  };

  const handleDeactivate = () => {
    let data = {
      selected: selectionType,
      status: "Deactive",
    };
    console.log("handleDeactivate", data);

    mutateDeactivate(data, {
      onSuccess: (res) => {
        if (res.success) {
          console.log(res);
          notification.success({
            message: "Success",
            description: "Successfully Made Payment",
          });
        }
      },
    });
  };

  const { mutate: mutateDeactivate, isLoading: isLoadingDeactivate } = POST(
    "api/v1/coach_funds",
    "funds_organization"
  );

  return (
    <>
      <ComponentHeader
        title="Payment"
        sub_title="ORGANIZATION PENDING"
        icon={faHandHoldingUsd}
      />

      <Card
        className="p-b-lg card-min-height"
        id="PageRevenueOrganizationsPendingPayment"
      >
        <Row gutter={12}>
          <Col xs={24} sm={24} md={16} lg={16} xl={18} className="m-b-sm">
            <Space>
              <TablePageSize
                paginationFilter={tableFilter}
                setPaginationFilter={setTableFilter}
              />

              {selectionType.length > 0 && (
                <Popconfirm
                  title="Click confirm to pay this coach?"
                  onConfirm={handleDeactivate}
                  okText="Confirm"
                  cancelText="Cancel"
                >
                  <Button
                    type="primary"
                    className="btn-main-outline-2"
                    loading={isLoadingDeactivate}
                  >
                    <FontAwesomeIcon icon={faTimes} /> &nbsp; PAYMENT MADE
                  </Button>
                </Popconfirm>
              )}
            </Space>
          </Col>
          <Col xs={24} sm={24} md={8} lg={8} xl={6}>
            <TableGlobalSearch
              paginationFilter={searchText}
              setPaginationFilter={setSearchText}
            />
          </Col>
        </Row>
        <Row gutter={24}>
          <Col xs={24} sm={24} md={24} className="m-t-sm m-b-sm">
            <Table
              dataSource={dataSource && dataSource}
              rowKey={(record) => record.id}
              loading={isLoadingTable || isFetchingTable}
              pagination={false}
              bordered={false}
              onChange={handleTableChange}
              className="ant-table-default ant-table-striped"
              scroll={{ x: "max-content" }}
              rowSelection={{
                type: selectionType,
                ...rowSelection,
              }}
            >
              <Table.Column
                title="Purchase Date"
                key="strcreated"
                dataIndex="strcreated"
                sorter={true}
                defaultSortOrder={"descend"}
                width={"180px"}
              />
              {/* <Table.Column
                title="Purchase Date"
                key="strdate"
                dataIndex="strdate"
                sorter={true}
                defaultSortOrder={"descend"}
                width={"180px"}
              /> */}
              <Table.Column
                title="Organization Name"
                key="organization"
                dataIndex="organization"
                sorter={true}
                // render={(text, record) => {
                //   return <Link to={"#"}>{record.firstname}</Link>;
                // }}
                width={"250px"}
              />
              <Table.Column
                title="Customer Name"
                key="name"
                dataIndex="name"
                sorter={true}
                // render={(text, record) => {
                //   return <Link to={"#"}>{record.firstname}</Link>;
                // }}
                width={"150px"}
              />
              <Table.Column
                title="City"
                key="city"
                dataIndex="city"
                sorter={true}
                width={"100px"}
              />
              <Table.Column
                title="State"
                key="state"
                dataIndex="state"
                sorter={true}
                width={"100px"}
              />
              <Table.Column
                title="Amount"
                key="amount"
                dataIndex="amount"
                sorter={true}
                width={"150px"}
                render={(text, record) => {
                  // return text.toFixed(2);
                  // return "$" + text.toString().substr(0, 5);
                  // return text.toString().substr(0, 6);
                  if (text === "$0") {
                    return text;
                  } else {
                    let amount = text.split(".");
                    if (amount[1]) {
                      amount =
                        amount[0] + "." + amount[1].toString().substr(0, 2);
                    } else {
                      amount = amount[0];
                    }
                    return amount;
                  }
                }}
              />
              <Table.Column
                title="BFSS Manager"
                key="manager_name"
                dataIndex="manager_name"
                sorter={true}
                width={"150px"}
              />
            </Table>
          </Col>
          <Col xs={24} sm={24} md={24}>
            <Space className="ant-space-flex-space-between table-paid">
              <TableShowingEntries />

              <TablePagination
                paginationFilter={tableFilter}
                setPaginationFilter={setTableFilter}
                setPaginationTotal={tableTotal}
                showLessItems={true}
                showSizeChanger={false}
                parentClass="table-paid"
              />
            </Space>
          </Col>
        </Row>
      </Card>
    </>
  );
}
