import React, { useEffect, useState } from "react";

import {
  Layout,
  Card,
  Form,
  Input,
  Button,
  Row,
  Col,
  Image,
  Divider,
  Collapse,
  Select,
  notification,
  Checkbox,
  Alert,
  Modal,
  Space,
} from "antd";
import {
  FormOutlined,
  PlusOutlined,
  MinusOutlined,
  EyeOutlined,
} from "@ant-design/icons";
import ComponentHeader from "../../Components/ComponentHeader";
import { faTicketAlt } from "@fortawesome/pro-solid-svg-icons";
import moment from "moment";
import { useHistory } from "react-router-dom";

import FloatInput from "../../../../providers/FloatInput";
import FloatSelect from "../../../../providers/FloatSelect";
import FloatInputPasswordStrength from "../../../../providers/FloatInputPasswordStrength";

import states from "states-us";

import { GET, POST } from "../../../../providers/useAxiosQuery";
import { faEye } from "@fortawesome/pro-regular-svg-icons";
import companyInfo from "../../../../providers/companyInfo";
export default function ViewAs({ showModalNew, permission }) {
  const encryptor = companyInfo().encryptor;
  const { Panel } = Collapse;
  const [form] = Form.useForm();
  const sub_title = "User";
  const [dataUsers, setDataUsers] = useState([]);
  const { data: dataMember, isLoading: isLoadingDataMember } = GET(
    "api/v1/user_options",
    "user_options",
    (res) => {
      if (res.success) {
        console.log("member_options", res);
        let arr = [];
        res.data &&
          res.data.map((row, index) => {
            let val =
              row.firstname + " " + row.lastname + " - (" + row.role + ")";
            arr.push({
              value: row.id,
              label: val,
              json: row,
            });
          });
        console.log(arr);
        setDataUsers(arr);
      }
    }
  );

  const { mutate: mutateGenerateToken, isLoading: isLoadingtickets } = POST(
    "api/v1/generate/token/viewas",
    "viewas_mutate"
  );

  const handleFinish = (val) => {
    console.log(val.users);
    mutateGenerateToken(
      { id: val.users, viewas: localStorage.viewas },
      {
        onSuccess: (res) => {
          console.log(res);
          localStorage.token = res.token;
          if (!localStorage.viewas) {
            localStorage.userdata_admin = localStorage.userdata;
          }
          localStorage.userdata = encryptor.encrypt(res.data);
          localStorage.viewas = true;
          var url = window.location.origin;
          window.location.href = url;
        },
      }
    );
  };

  const onChangeSelectUser = () => {
    form.submit();
  };
  return (
    <>
      <ComponentHeader title="User" sub_title="View As" icon={faEye} />
      {/* <ComponentFaqs
      linkVid={"https://player.vimeo.com/video/644213846?autoplay=0&muted=1"}
    /> */}
      <br></br>
      <Card className="card-min-height" id="ViewAs">
        <Row gutter={24}>
          <Col xs={24} sm={24} md={16} lg={16} xl={16} xxl={16}>
            <Collapse
              className="ant-collapse-primary"
              expandIcon={({ isActive }) =>
                isActive ? (
                  <span
                    className="ant-menu-submenu-arrow"
                    style={{ color: "#FFF", transform: "rotate(270deg)" }}
                  ></span>
                ) : (
                  <span
                    className="ant-menu-submenu-arrow"
                    style={{ color: "#FFF", transform: "rotate(90deg)" }}
                  ></span>
                )
              }
              expandIconPosition="right"
              defaultActiveKey={["1", "2", "3"]}
            >
              <Panel
                header="SELECT USER TO VIEW"
                key="1"
                className="accordion bg-darkgray-form"
              >
                <Form
                  wrapperCol={{ span: 24 }}
                  layout="horizontal"
                  form={form}
                  onFinish={handleFinish}
                >
                  <Form.Item
                    name="users"
                    rules={[{ required: true, message: "required!" }]}
                    className="form-select-error"
                  >
                    <FloatSelect
                      label="Select User"
                      placeholder="Select User"
                      options={dataUsers}
                      onChange={() => onChangeSelectUser()}
                      required
                    />
                  </Form.Item>
                </Form>
              </Panel>
            </Collapse>
          </Col>
        </Row>
      </Card>
    </>
  );
}
