import React, { useEffect, useState } from "react";
import { Alert, Button, Card, Col, List, Row, Typography } from "antd";
import ComponentHeader from "../../Components/ComponentHeader";
import ModalFormWelcome from "./Modals/ModalFormWelcome";

import moment from "moment";
import companyInfo from "../../../../providers/companyInfo";
import { faDollarSign, faHome } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt, faChartBar } from "@fortawesome/free-solid-svg-icons";
import {
  faChartNetwork,
  faLightbulbOn,
} from "@fortawesome/pro-solid-svg-icons";
import { GET } from "../../../../providers/useAxiosQuery";
import { useHistory } from "react-router-dom";

import ComponentDashboard from "../../Components/ComponentDashboard";
import ModalAssessmentPreview from "../../Components/ModalAssessmentPreview";
// import ModalAssessmentPreview from "../../Components/ModalAssessmentPreview";

// const apiUrl = companyInfo().apiUrl;
const userData = companyInfo().userData;
// const logo = companyInfo().logo;

export default function PageDashboard(props) {
  const { permission } = props;

  // console.log("PageDashboard", userData);

  const history = useHistory();

  const [dataFilter, setDataFilter] = useState({
    date: moment().format("MM/DD/YYYY"),
    type: "Group",
    search: "",
    category: "",
    tag: "",
    country: "",
    state: "",
    city: "",
    user_id: userData.id,
    from: "athlete_dashboard",
    // col_lg: ,
  });

  const [toggleModalPreview, setToggleModalPreview] = useState({
    show: false,
    data: null,
    hideTicketingButton: false,
  });

  const [
    assessmentListFilter,
    // setAssessmentListFilter
  ] = useState({
    from: "athlete_dashboard",
    user_id: userData.id,
    start: 0,
    type: "Group",
    // date_now: moment().format("L"),
  });
  const [assessmentList, setAssessmentList] = useState([]);
  useState("");
  const { refetch: refetchAssessmentList } = GET(
    `api/v1/get_my_assessment?${new URLSearchParams(assessmentListFilter)}`,
    "dasboard_assessment",
    (res) => {
      if (res.data) {
        console.log("assessmentList_data", res);
        setAssessmentList(res.data);
      }
    }
  );

  const handleAssessmentInfo = (data) => {
    let row = data.event;
    let newdata = {
      ...row,
      day_modal: data.day_modal,
      month_modal: data.month_modal,
      from: "dashboard_table_assessment",
    };

    console.log("handleAssessmentInfo", newdata);

    setToggleModalPreview({
      ...toggleModalPreview,
      show: true,
      data: newdata,
      hideTicketingButton: true,
    });
  };

  useEffect(() => {
    refetchAssessmentList();

    return () => {};
  }, [assessmentListFilter]);

  const [freeCreditAmount, setFreeCreditAmount] = useState();
  const [notificationAlert, setNotificationAlert] = useState("");
  GET("api/v1/get_free_credit", "get_free_credit", (res) => {
    if (res.success) {
      console.log("get_free_credit", res);
      setFreeCreditAmount(res);
      setNotificationAlert(res.alert);
    }
  });

  // useEffect(() => {
  //   if (userData.one_time_modal === 1) {
  //     console.log("one_time_modal", userData.one_time_modal);
  //   }
  // }, [userData]);

  // GET("api/v1/get_reg_fee", "get_reg_fee", (res) => {
  //   if (res.success) {
  //     console.log("get_reg_fee", res);
  //   }
  // });

  return (
    <>
      <ComponentHeader
        title={permission}
        sub_title="ALL FEATURES"
        icon={faHome}
      />

      <Card className="card-quick-links p-b-lg card-min-height">
        {freeCreditAmount && (
          <Alert
            description={
              notificationAlert ? (
                notificationAlert
              ) : (
                <>
                  <strong>Welcome!</strong> Just a reminder that you have a $
                  {freeCreditAmount.data.registration_credit_amount} credit you
                  can use towards your first assessment.
                </>
              )
            }
            type="success"
            icon={<FontAwesomeIcon icon={faDollarSign} />}
            showIcon
            className="m-b-sm"
          />
        )}
        <Row gutter={12}>
          <Col xs={24} sm={24} md={24} lg={24} xl={16}>
            <Card
              title="QUICK LINKS"
              className="card-transparent-head border-bottom-none no-side-border p-none"
              bodyStyle={{ padding: "0px" }}
            >
              <Row gutter={[12, 12]} className="ant-row-quick-link">
                <Col xs={12} sm={12} md={6}>
                  <Button
                    type="link"
                    className="ant-btn-quick-link"
                    onClick={() =>
                      history.push("/calendar/scheduled-assessments")
                    }
                  >
                    <FontAwesomeIcon icon={faCalendarAlt} />
                    <Typography.Text>
                      My Scheduled
                      <br />
                      Assessments
                    </Typography.Text>
                  </Button>
                </Col>
                <Col xs={12} sm={12} md={6}>
                  <Button
                    type="link"
                    className="ant-btn-quick-link"
                    onClick={() => history.push("/assessment-stats")}
                  >
                    <FontAwesomeIcon icon={faChartBar} />
                    <Typography.Text>
                      My Assessment
                      <br />
                      Stats
                    </Typography.Text>
                  </Button>
                </Col>
                <Col xs={12} sm={12} md={6}>
                  <Button
                    type="link"
                    className="ant-btn-quick-link"
                    onClick={() => history.push("/athletic-profile/edit")}
                  >
                    <FontAwesomeIcon icon={faChartNetwork} />
                    <Typography.Text>
                      My Athletic
                      <br />
                      Profile
                    </Typography.Text>
                  </Button>
                </Col>
                <Col xs={12} sm={12} md={6}>
                  <Button
                    type="link"
                    className="ant-btn-quick-link"
                    onClick={() => history.push("/support/faqs")}
                  >
                    <FontAwesomeIcon icon={faLightbulbOn} />
                    <Typography.Text>
                      Frequently Asked
                      <br />
                      Questions
                    </Typography.Text>
                  </Button>
                </Col>
              </Row>
            </Card>

            <ComponentDashboard
              dataFilter={dataFilter}
              setDataFilter={setDataFilter}
              column={12}
            />
          </Col>

          <Col xs={24} sm={24} md={24} lg={24} xl={8}>
            <Card
              title="MY ASSESSMENTS"
              className="card-dark-head no-side-border p-none card-dashboard-assessment "
              bodyStyle={{ padding: "0px" }}
              style={{ marginTop: "47px" }}
            >
              <Row gutter={[12, 12]}>
                <Col xs={24} sm={24} md={24}>
                  <div
                    id="scrollableDiv"
                    style={{
                      maxHeight: 300,
                      overflow: "auto",
                      paddingLeft: "10px",
                      paddingRight: "10px",
                      border: "1px solid rgba(140, 140, 140, 0.35)",
                    }}
                    className="scrollbar-2"
                  >
                    <List
                      style={{ paddingLeft: "13px", paddingRight: "13px" }}
                      dataSource={assessmentList}
                      renderItem={(item) => (
                        <List.Item>
                          <Typography.Text className="companyColor1">
                            {/* {item.event && item.event.type === "Group" ? ( */}
                            <Typography.Link
                              to="#"
                              onClick={() => handleAssessmentInfo(item)}
                            >
                              <span
                                className={
                                  // item.event && item.event.type === "Group"
                                  //   ?
                                  "companyColor3"
                                  // : ""
                                }
                              >
                                {item.event && item.event.title
                                  ? item.event.title
                                  : ""}
                              </span>
                            </Typography.Link>
                            {/* ) : (
                              <span
                                className={
                                  item.event && item.event.type === "Group"
                                    ? "companyColor3"
                                    : ""
                                }
                              >
                                {item.event && item.event.title
                                  ? item.event.title
                                  : ""}
                              </span>
                            )} */}
                            <br />
                            <span>{item.type}</span>
                            <br />
                            <span>
                              {moment(item.date_assessment).format(
                                "MMM DD, YYYY"
                              )}
                            </span>
                            <br />
                            <span>
                              TYPE:{" "}
                              {item.event && item.event.type && item.event.type}
                            </span>
                          </Typography.Text>
                        </List.Item>
                      )}
                    />
                  </div>
                </Col>

                {/* {assessmentListCountMessage && (
                  <Col xs={24} sm={24} md={24} className="text-center">
                    <Button
                      className="btn-primary-default"
                      icon={
                        <FontAwesomeIcon
                          icon={faAngleDoubleDown}
                          className="m-r-xs"
                        />
                      }
                      onClick={handleLoadMoreAssessment}
                    >
                      Load More
                    </Button>
                  </Col>
                )} */}
              </Row>
            </Card>
          </Col>
        </Row>
      </Card>

      <ModalAssessmentPreview
        toggleModalPreview={toggleModalPreview}
        setToggleModalPreview={setToggleModalPreview}
        hideTicketingButton={toggleModalPreview.hideTicketingButton}
      />

      {userData.one_time_modal === 0 ? <ModalFormWelcome /> : null}
    </>
  );
}
