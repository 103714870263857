import {
  Button,
  Card,
  Col,
  Layout,
  notification,
  Popconfirm,
  Row,
  Space,
  Table,
} from "antd";
import React, { useState, useEffect } from "react";
import { faCheck, faStar } from "@fortawesome/pro-regular-svg-icons";
import ComponentHeader from "../../Components/ComponentHeader";
import {
  TableGlobalSearch,
  TablePageSize,
  TablePagination,
  TableShowingEntries,
} from "../../Components/ComponentTableFilter";
import { GET, POST } from "../../../../providers/useAxiosQuery";
import $ from "jquery";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import companyInfo from "../../../../providers/companyInfo";

export default function PageMyAthlete({ match, permission }) {
  // const history = useHistory();
  const userdata = companyInfo().userData;
  const [tableFilter, setTableFilter] = useState({
    page: 1,
    page_size: 50,
    search: "",
    sort_field: "name",
    sort_order: "desc",
    coach_id: userdata.id,
  });

  const [tableTotal, setTableTotal] = useState(0);
  const [dataSource, setDataSource] = useState([]);
  const {
    refetch: refetchTable,
    isLoading: isLoadingTable,
    isFetching: isFetchingTable,
  } = GET(`api/v1/my_athlete?${$.param(tableFilter)}`, "my_athlete", (res) => {
    if (res.success) {
      console.log("dataTable", res);
      setDataSource(res.data && res.data.data);
      setTableTotal(res.data.total);
    }
  });

  const handleTableChange = (pagination, filters, sorter) => {
    setTableFilter({
      ...tableFilter,
      sort_field: sorter.columnKey,
      sort_order: sorter.order ? sorter.order.replace("end", "") : null,
      // page: 1,
    });
  };

  const [searchText, setSearchText] = useState("");
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setTableFilter({
        ...tableFilter,
        search: searchText,
        page: 1,
      });
    }, 1500);
    return () => {
      clearTimeout(timeoutId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText]);

  useEffect(() => {
    refetchTable();
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableFilter]);

  // const handleDelete = (id) => {
  //   let data = {
  //     status: 1,
  //     id: id,
  //   };

  //   mutateDelete(data, {
  //     onSuccess: (res) => {
  //       if (res.success) {
  //         notification.success({
  //           message: "Success",
  //           description: "Successfully deleted.",
  //         });
  //       }
  //     },
  //   });
  // };

  // const { mutate: mutateDelete, isLoading: isLoadingDelete } = UPDATE(
  //   "api/v1/assessment",
  //   "assessment"
  // );

  const [selectionType, setSelectionType] = useState([]);
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      // let arr = [];
      // selectedRows.companyInfo((item) => {
      //   arr.push([item.user_id]);
      // });

      console.log("rowSelection", selectedRows);
      setSelectionType(selectedRows);
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User",
      // Column configuration not to be checked
      name: record.name,
    }),
  };

  const handleDeactivate = () => {
    let data = {
      selected: selectionType,
      user_id: userdata.id,
    };
    console.log("handleDeactivate", data);
    mutateDeactivate(data, {
      onSuccess: (res) => {
        if (res.success) {
          // console.log(res);
          notification.success({
            message: "Success",
            description: "Successfully followed",
          });
        }
      },
    });
  };

  const { mutate: mutateDeactivate, isLoading: isLoadingDeactivate } = POST(
    "api/v1/coach_follow_bulk",
    "coach_follow_list"
  );

  return (
    <>
      <ComponentHeader title={permission} sub_title={"VIEW"} icon={faStar} />

      <Card className="card-min-height" id="PageMyAthlete">
        <Row gutter={12}>
          <Col xs={24} sm={24} md={16} lg={16} xl={18} className="m-b-sm">
            <Space>
              <TablePageSize
                paginationFilter={tableFilter}
                setPaginationFilter={setTableFilter}
              />
              {selectionType.length > 0 && (
                <Popconfirm
                  title="Are you sure you wish to follow this athlete(s)?"
                  onConfirm={handleDeactivate}
                  okText="Confirm"
                  cancelText="Cancel"
                >
                  <Button
                    type="primary"
                    className="btn-main-outline-2"
                    loading={isLoadingDeactivate}
                  >
                    <FontAwesomeIcon icon={faCheck} /> &nbsp; FOLLOW
                  </Button>
                </Popconfirm>
              )}
            </Space>
          </Col>
          <Col xs={24} sm={24} md={8} lg={8} xl={6}>
            <TableGlobalSearch
              paginationFilter={searchText}
              setPaginationFilter={setSearchText}
            />
          </Col>
        </Row>
        <Row gutter={24}>
          <Col xs={24} sm={24} md={24} className="m-t-sm m-b-sm">
            <Table
              className="ant-table-default ant-table-striped"
              scroll={{ x: "max-content" }}
              loading={isLoadingTable || isFetchingTable}
              dataSource={dataSource && dataSource}
              rowKey={(record) => record.id}
              pagination={false}
              bordered={false}
              onChange={handleTableChange}
              rowSelection={{
                type: selectionType,
                ...rowSelection,
              }}
            >
              <Table.Column
                title="Full Name"
                key="name"
                dataIndex="name"
                defaultSortOrder="descend"
                sorter={true}
                // render={(text, record) => {
                //   return (
                //     <Link
                //       // to={`/organization/pending-approval/${record.state}/${record.city}`}
                //       to={`/search/preview/${record.user_id}`}
                //       target="_blank"
                //     >
                //       {text}
                //     </Link>
                //   );
                // }}
                render={(text, record) => {
                  return (
                    <>
                      {record.web_name ? (
                        <Link
                          to={`/athletic/${record.web_name}`}
                          target="_blank"
                        >
                          {text}
                        </Link>
                      ) : (
                        text
                      )}
                    </>
                  );
                }}
                width={"200px"}
              />
              {/* <Table.Column
                title="Last Name"
                key="organization_type"
                dataIndex="organization_type"
                sorter={true}
              /> */}
              <Table.Column
                title="School"
                key="organization_name"
                dataIndex="organization_name"
                sorter={true}
                width={"250px"}
              />
              <Table.Column
                title="Sport"
                key="sport"
                dataIndex="sport"
                sorter={true}
                width={"200px"}
              />
              <Table.Column
                title="Position"
                key="position"
                dataIndex="position"
                sorter={true}
                render={(text, record) => {
                  if (record.position) {
                    return JSON.parse(record.position).join(", ");
                  }
                }}
                width={"150px"}
              />
            </Table>
          </Col>
          <Col xs={24} sm={24} md={24}>
            <Space className="ant-space-flex-space-between">
              <TableShowingEntries />
              <TablePagination
                paginationFilter={tableFilter}
                setPaginationFilter={setTableFilter}
                setPaginationTotal={tableTotal}
                showLessItems={true}
                showSizeChanger={false}
              />
            </Space>
          </Col>
          <Col xs={24} sm={24} md={12}></Col>
        </Row>
      </Card>
    </>
  );
}
