import { Card, Col, Layout, Row, Space, Table } from "antd";
import React, { useState, useEffect } from "react";
import { faSearch } from "@fortawesome/pro-regular-svg-icons";
import ComponentHeader from "../../Components/ComponentHeader";
import {
  TableGlobalSearch,
  TablePageSize,
  TablePagination,
  TableShowingEntries,
} from "../../Components/ComponentTableFilter";
import $ from "jquery";
import { Link } from "react-router-dom";
import { GET } from "../../../../providers/useAxiosQuery";
import companyInfo from "../../../../providers/companyInfo";

export default function PageUserSearchAthletes({ match, permission }) {
  const userdata = companyInfo().userData;
  const [tableFilter, setTableFilter] = useState({
    page: 1,
    page_size: 50,
    search: "",
    sort_field: "name",
    sort_order: "desc",
    manager_id: userdata.id,
  });

  const [tableTotal, setTableTotal] = useState(0);
  const [dataSource, setDataSource] = useState([]);
  const {
    refetch: refetchTable,
    isLoading: isLoadingTable,
    isFetching: isFetchingTable,
  } = GET(
    `api/v1/user_search_athlete?${$.param(tableFilter)}`,
    "search_athletes",
    (res) => {
      if (res.success) {
        setDataSource(res.data && res.data.data);
        setTableTotal(res.data.total);
      }
    }
  );

  const handleTableChange = (pagination, filters, sorter) => {
    setTableFilter({
      ...tableFilter,
      sort_field: sorter.columnKey,
      sort_order: sorter.order ? sorter.order.replace("end", "") : null,
      // page: 1,
    });
  };

  useEffect(() => {
    refetchTable();
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableFilter]);

  // const handleDelete = (id) => {
  //   let data = {
  //     status: 1,
  //     id: id,
  //   };

  //   mutateDelete(data, {
  //     onSuccess: (res) => {
  //       if (res.success) {
  //         notification.success({
  //           message: "Success",
  //           description: "Successfully deleted.",
  //         });
  //       }
  //     },
  //   });
  // };

  // const { mutate: mutateDelete, isLoading: isLoadingDelete } = UPDATE(
  // 	"api/v1/search_athletes",
  // 	"search_athletes"
  // );

  // const [toggleModalPreview, setToggleModalPreview] = useState({
  //   show: false,
  //   data: null,
  //   type: "Starter",
  // });

  const [searchText, setSearchText] = useState("");
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setTableFilter({
        ...tableFilter,
        search: searchText,
        page: 1,
      });
    }, 1500);
    return () => {
      clearTimeout(timeoutId);
    };
  }, [searchText]);

  return (
    <>
      <ComponentHeader title={permission} sub_title="SEARCH" icon={faSearch} />

      <Card className="card-min-height" id="PageUserSearchAthletes">
        <Row gutter={12}>
          <Col xs={24} sm={24} md={16} lg={16} xl={18} className="m-b-sm">
            <Space>
              <TablePageSize
                paginationFilter={tableFilter}
                setPaginationFilter={setTableFilter}
              />
            </Space>
          </Col>
          <Col xs={24} sm={24} md={8} lg={8} xl={6}>
            <TableGlobalSearch
              paginationFilter={searchText}
              setPaginationFilter={setSearchText}
              r
            />
          </Col>
        </Row>
        <Row gutter={12}>
          <Col xs={24} sm={24} md={24} className="m-t-sm m-b-sm">
            <Table
              className="ant-table-default ant-table-striped"
              dataSource={dataSource && dataSource}
              rowKey={(record) => record.id}
              loading={isLoadingTable || isFetchingTable}
              pagination={false}
              bordered={false}
              onChange={handleTableChange}
              scroll={{ x: "max-content" }}
            >
              <Table.Column
                title="Name"
                key="name"
                dataIndex="name"
                sorter={true}
                defaultSortOrder="descend"
                render={(text, record) => {
                  let defaultWebsite = record.athlete_website.filter(
                    (itemFilter) => itemFilter.is_default === 1
                  );
                  if (record.edit_permission === "No") {
                    return defaultWebsite ? (
                      <>
                        {defaultWebsite.length > 0 ? (
                          <Link
                            to={`/athletic/${
                              defaultWebsite.length > 0 &&
                              defaultWebsite[0].name &&
                              defaultWebsite[0].name
                            }`}
                            target="_blank"
                          >
                            {text}
                          </Link>
                        ) : (
                          text
                        )}
                      </>
                    ) : (
                      text
                    );
                  } else {
                    return text;
                  }
                }}
                width={"250px"}
              />
              <Table.Column
                title="Organization"
                key="organization"
                dataIndex="organization"
                sorter={true}
                width={"200px"}
              />
              <Table.Column
                title="Status"
                key="status"
                dataIndex="status"
                sorter={true}
                width={"150px"}
              />
              <Table.Column
                title="State"
                key="state"
                dataIndex="state"
                sorter={true}
                width={"150px"}
              />
              <Table.Column
                title="City"
                key="city"
                dataIndex="city"
                sorter={true}
                width={"150px"}
              />
              {userdata.role === "Admin" && (
                <Table.Column
                  title="Action"
                  key="action"
                  width={"150px"}
                  render={(text, record) => {
                    return (
                      <Link to={`/user/view-account-athlete/${record.id}`}>
                        Add Assessment
                      </Link>
                    );
                  }}
                />
              )}
              {/* <Table.Column
                title="Assessed By"
                key="assessed_by"
                dataIndex="assessed_by"
                sorter={true}
              /> */}
            </Table>
          </Col>
          <Col xs={24} sm={24} md={24}>
            <Space className="ant-space-flex-space-between">
              <TableShowingEntries />

              <TablePagination
                paginationFilter={tableFilter}
                setPaginationFilter={setTableFilter}
                setPaginationTotal={tableTotal}
                showLessItems={true}
                showSizeChanger={false}
              />
            </Space>
          </Col>
          <Col xs={24} sm={24} md={12}></Col>
        </Row>
        <br />
      </Card>
    </>
  );
}
