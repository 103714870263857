import { faCommentDots, faHome } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const AssessorSideMenu = [
  {
    title: "Dashboard",
    path: "/dashboard",
    icon: <FontAwesomeIcon icon={faHome} style={{ marginTop: "-5px" }} />,
    permission: "Dashboard",
  },
  {
    title: "Messages",
    path: "/messages",
    icon: (
      <FontAwesomeIcon icon={faCommentDots} style={{ marginTop: "-5px" }} />
    ),
    // permission: "Ticketing",
  },
];

export default AssessorSideMenu;
