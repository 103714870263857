import {
  Button,
  Checkbox,
  Form,
  Modal,
  notification,
  Radio,
  Space,
} from "antd";
import { useEffect, useState } from "react";
import FloatInput from "../../../../providers/FloatInput";
import FloatSelect from "../../../../providers/FloatSelect";
import FloatInputNumber from "../../../../providers/FloatInputNumber";
import FloatDatePickerForm from "../../../../providers/FloatDatePickerForm";
import { POST } from "../../../../providers/useAxiosQuery";
import moment from "moment";

export default function ModalSchool(props) {
  const { toggleModalForm, setToggleModalForm } = props;

  const [form] = Form.useForm();

  const [useOnce, setUseOnce] = useState(false);
  useEffect(() => {
    if (toggleModalForm.data !== null && toggleModalForm.data !== undefined) {
      console.log("toggleModalForm", toggleModalForm.data);
      setUseOnce(
        toggleModalForm.data && toggleModalForm.data.use_once === "True"
          ? true
          : false
      );

      setIsFixed(toggleModalForm.data && toggleModalForm.data.type);
      form.setFieldsValue({
        ...toggleModalForm.data,
        duration_from: moment(toggleModalForm.data.duration_from),
        duration_to: moment(toggleModalForm.data.duration_to),
        role: toggleModalForm.data.role
          ? JSON.parse(toggleModalForm.data.role)
          : [],
      });
    } else {
      form.resetFields();
    }

    return () => {};
  }, [toggleModalForm, form]);

  const validator = {
    require: {
      required: true,
      message: "This field is required!",
    },
  };

  const [isFixed, setIsFixed] = useState("percent");
  const [isRepeating, setIsRepeating] = useState(false);

  const { mutate: mutateCoupon, isLoading: isLoadingCoupon } = POST(
    "api/v1/add_coupon_system",
    "get_coupons_system"
  );
  const { mutate: mutateCouponUpdate, isLoading: isLoadingCouponUpdate } = POST(
    "api/v1/update_coupon_system",
    "get_coupons_system"
  );

  const onFinish = (values) => {
    let data = {
      ...values,
      type: isFixed,
      duration_from: values.duration_from.format("YYYY-MM-DD"),
      duration_to: values.duration_to.format("YYYY-MM-DD"),
      max_redemptions: values.max_redemptions
        ? Math.floor(values.max_redemptions)
        : null,
      user_once: useOnce,
    };

    if (toggleModalForm.data && toggleModalForm.data.id) {
      console.log("onFinish", data);
      mutateCouponUpdate(data, {
        onSuccess: (res) => {
          if (res.success) {
            // console.log(res)
            notification.success({
              message: "Sucess",
              description: "Coupon Sucessfully Updated",
            });
            setToggleModalForm({ show: false, data: null });
            setIsFixed("percent");
            form.resetFields();
          }
        },
        onError: (err) => {
          notification.error({
            message: "Error",
            description: err.response.data.message,
          });
        },
      });
    } else {
      console.log("onFinish", data);
      mutateCoupon(data, {
        onSuccess: (res) => {
          if (res.success) {
            // console.log(res)
            notification.success({
              message: "Sucess",
              description: "Coupon Sucessfully Added",
            });
            setToggleModalForm({ show: false, data: null });
            setIsFixed("percent");
            form.resetFields();
          } else {
            notification.error({
              message: "Error",
              description: "Coupon Already Exists",
            });
          }
        },
        onError: (err) => {
          notification.error({
            message: "Error",
            description: err.response.data.message,
          });
        },
      });
    }
  };

  const onChange = (e) => {
    // console.log(`checked = ${e.target.checked}`);
    setUseOnce(e.target.checked);
  };

  return (
    <Modal
      visible={toggleModalForm.show}
      width={600}
      title=" Coupon Form"
      onCancel={() => setToggleModalForm({ show: false, data: null })}
      footer={
        <>
          <Button
            type="primary"
            loading={isLoadingCoupon || isLoadingCouponUpdate}
            className="btn-main-outline-2"
            size="large"
            style={{ width: "33%" }}
            onClick={() => form.submit()}
          >
            SUBMIT
          </Button>
        </>
      }
      className="dashboard-modal-event-preview"
    >
      <Form
        form={form}
        onFinish={onFinish}
        autoComplete="off"
        onValuesChange={(change, values) => {
          if (change.duration) {
            if (change.duration == "repeating") {
              setIsRepeating(true);
            } else {
              setIsRepeating(false);
            }
          }
          if (change.type) {
            if (change.type == "percent") {
              setIsFixed("percent");
            }
            if (change.type == "fixed") {
              setIsFixed("fixed");
            }
            if (change.type == "offer") {
              setIsFixed("offer");
            }
          }
        }}
      >
        <Form.Item name="coupon_code" rules={[validator.require]}>
          <FloatInput
            label="Coupon Code"
            placeholder="Coupon Code"
            disabled={
              toggleModalForm.data && toggleModalForm.data.id ? true : false
            }
            required
          />
        </Form.Item>
        <Form.Item name="coupon_name" rules={[validator.require]}>
          <FloatInput label="Coupon Name" placeholder="Coupon Name" required />
        </Form.Item>
        <Space direction="vertical">
          <Form.Item name="type" label="Type Discount">
            <Radio.Group defaultValue={isFixed}>
              <Radio value="percent">Percentage </Radio>
              <Radio value="fixed">Fixed </Radio>
              {/* <Radio value="offer">Offer Free Days</Radio> */}
            </Radio.Group>
          </Form.Item>
        </Space>

        {isFixed == "percent" && (
          <Form.Item name="off" rules={[validator.require]}>
            <FloatInputNumber
              label="Percentege off"
              placeholder="Percentege off"
              required
            />
          </Form.Item>
        )}

        {isFixed == "fixed" && (
          <Form.Item name="off" rules={[validator.require]}>
            <FloatInputNumber
              label="Amount off"
              placeholder="Amount off"
              required
            />
          </Form.Item>
        )}
        {isFixed == "offer" && (
          <Form.Item name="off" rules={[validator.require]}>
            <FloatInputNumber
              label="No. of Days"
              placeholder="No. of Days"
              required
            />
          </Form.Item>
        )}

        <Form.Item name="duration_from" rules={[validator.require]}>
          <FloatDatePickerForm
            label={"Duration From"}
            placeholder={"Duration From"}
            mode
            required
          />
        </Form.Item>

        <Form.Item name="duration_to" rules={[validator.require]}>
          <FloatDatePickerForm
            label={"Duration To"}
            placeholder={"Duration To"}
            mode
            required
          />
        </Form.Item>

        <Form.Item
          name="role"
          rules={[validator.require]}
          // className="form-select-error-multi"
        >
          <FloatSelect
            label="Applied on Account type"
            placeholder="Applied on Account type"
            multi="multiple"
            options={[
              {
                label: "Athlete",
                value: "Athlete",
              },
              {
                label: "Athlete Guardian",
                value: "Athlete Guardian",
              },
            ]}
            required
          />
        </Form.Item>

        <Form.Item name="max" rules={[validator.require]}>
          <FloatInputNumber
            label="Max redemptions"
            placeholder="Max redemptions"
            required
          />
        </Form.Item>

        {/* <Form.Item name="max" hasFeedback>
          <FloatInputNumber
            label="Max redemptions"
            placeholder="Max redemptions"
            rules={[validator.require]}
          />
        </Form.Item> */}

        <Checkbox checked={useOnce} onChange={onChange}>
          <b>Use Once Per (Athlete / Athlete Guardian)</b>
        </Checkbox>
      </Form>
    </Modal>
  );
}
