import React from "react";
import {
  Layout,
  Card,
  // Form,
  // Input,
  // Button,
  Row,
  Col,
  Image,
  Divider,
  // notification,
  Typography,
  Result,
  // Alert,
} from "antd";
// import { Link, useHistory, useLocation } from "react-router-dom";
import moment from "moment";
import companyInfo from "../../providers/companyInfo";
import warningLogo from "../../assets/img/warning.png";

const logo = companyInfo().logo;
const description = companyInfo().description;

export default function PageMaintenance() {
  // let history = useHistory();

  return (
    <Layout className="public-layout">
      <div className="divMaintenance">
        <div className="divMaintenanceChild">
          <Result
            status="warning"
            title={
              <span className="textMaintenance">
                Our site is currently undergoing scheduled maintenance and
                upgrades, but will return shorthly. We apologize for the
                inconvenience, thank you for your patience.
              </span>
            }
          />
        </div>
      </div>
    </Layout>
  );
}
