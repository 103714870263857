import React, { useState } from "react";
import { Card, Col, Row } from "antd";
import ComponentHeader from "../../../Components/ComponentHeader";
import { faCalendarAlt } from "@fortawesome/pro-regular-svg-icons";
import moment from "moment";

import ComponentDashboard from "../../../Components/ComponentDashboard";

import companyInfo from "../../../../../providers/companyInfo";

export default function PageEventCalendarGroupAssessment({
  match,
  permission,
}) {
  // dashboard assessment view
  const userdata = companyInfo().userData;
  const [dataFilter, setDataFilter] = useState({
    date: moment().format("MM/DD/YYYY"),
    type: "Group",
    search: "",
    category: "",
    tag: "",
    country: "",
    state: "",
    city: "",
    manager_id: userdata.id,
    from: "Group Assessments",
    col_lg: 6,
  });

  return (
    <>
      <ComponentHeader
        title={permission}
        sub_title="GROUP"
        icon={faCalendarAlt}
      />

      <Card className="card-min-height" id="PageEventCalendarGroupAssessment">
        <Row gutter={12}>
          <Col xs={24} sm={24} md={24}>
            <ComponentDashboard
              dataFilter={dataFilter}
              setDataFilter={setDataFilter}
              column={12}
              // column_lg={6}
              // column_xl={6}
            />
          </Col>
        </Row>
        <br />
      </Card>
    </>
  );
}
