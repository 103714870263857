import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Button, Card, Col, Collapse, Form, notification, Row } from "antd";
import { DeleteFilled, PlusOutlined } from "@ant-design/icons";
import { faUser } from "@fortawesome/pro-solid-svg-icons";

import ComponentHeader from "../../Components/ComponentHeader";

import FloatInput from "../../../../providers/FloatInput";
import FloatInputMask from "../../../../providers/FloatInputMask";
import FloatSelect from "../../../../providers/FloatSelect";
import optionCountryCodes from "../../../../providers/optionCountryCodes";
import optionStateCodesUnitedState from "../../../../providers/optionStateCodesUnitedState";
import optionStateCodesCanada from "../../../../providers/optionStateCodesCanada";
// import optionStateCodes from "../../../../providers/optionStateCodes";
import { GET, GETMANUAL, POST } from "../../../../providers/useAxiosQuery";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave } from "@fortawesome/free-solid-svg-icons";
import FloatInputPassword from "../../../../providers/FloatInputPassword";
import PageUserCreateAccountAthleteAssessment from "./PageUserCreateAccountAthleteAssessment";
import FloatDatePickerForm from "../../../../providers/FloatDatePickerForm";
import moment from "moment";

import optionOrganizationType from "../../../../providers/optionOrganizationType";
import ComponentAthleteOgranization from "../../Components/ComponentAthleteOgranization";

export default function PageUserCreateAccountAthlete(props) {
  const history = useHistory();
  const { permission, match } = props;
  const { Panel } = Collapse;
  const [form] = Form.useForm();
  const athlete_id = match.params.id;

  const onFinish = (values) => {
    // console.log("asdas", values);
    // console.log("asdas", values);
    // let position = values.position.map((item) => item.role);
    let data = {
      ...values,
      birthdate: values.date_of_birth.format("YYYY-MM-DD"),
      // position: JSON.stringify(position),
    };

    if (athlete_id) {
      data = {
        ...data,
        id: athlete_id,
      };
    } else {
      data = {
        ...data,
        organization: organizationData,
      };
    }

    if (organizationData.length === 0) {
      notification.warning({
        message: "Warning",
        description: "Please add organization.",
      });
      return false;
    }

    console.log("onFinish", data);
    mutateAdd(data, {
      onSuccess: (res) => {
        if (res.success) {
          form.resetFields();
          notification.success({
            message: "Success",
            description: "Successfully Submitted.",
          });
          setOrganizationData([]);
          if (athlete_id) {
            refetchAthlete(athlete_id);
          } else {
            history.push("/user/view-account-athlete/" + res.data.id);
          }
        } else {
          notification.error({
            message: "Warning!",
          });
        }
      },
    });
  };

  const { mutate: mutateAdd, isLoading: isLoadingAdd } = POST(
    "api/v1/create_athlete",
    "create_athlete"
  );

  const country = optionCountryCodes;
  const stateUS = optionStateCodesUnitedState();
  const stateCA = optionStateCodesCanada();

  const [stateLabel, setStateLabel] = useState("State");
  const [optionState, setOptionState] = useState(stateUS);
  const [zipLabel, setZipLabel] = useState("Zip Code");
  const [optionZip, setOptionZip] = useState(/(^\d{5}$)|(^\d{5}[ -]\d{4}$)/);

  const handleCountry = (val, opt) => {
    if (val === "United States") {
      setStateLabel("State");
      setZipLabel("Zip Code");
      setOptionState(stateUS);
      setOptionZip(/(^\d{5}$)|(^\d{5}[ -]\d{4}$)/);
    } else if (val === "Canada") {
      setStateLabel("County");
      setZipLabel("Postal Code");
      setOptionState(stateCA);
      setOptionZip(/^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/);
    }

    form.resetFields(["state", "zip", "city", "zip_code"]);
  };

  const [cityOption, setCityOption] = useState([]);

  const [athleteData, setAthleteData] = useState();
  const [athleteTitle, setAthleteTitle] = useState("CREATE ATHLETE");
  useEffect(() => {
    if (athlete_id) {
      console.log("athlete_id", athlete_id);
      form.resetFields();
      refetchAthlete(athlete_id);
      setAthleteTitle("VIEW ATHLETE");
    } else {
      setAthleteTitle("CREATE ATHLETE");
      form.resetFields();
    }
  }, [athlete_id]);

  const refetchAthlete = (id) => {
    mutateGetAthlete(
      { id: id },
      {
        onSuccess: (res) => {
          if (res.success) {
            // console.log("users", res.data);
            setAthleteData(res.data);
            let org = [];
            res.data.athlete_organization.map((item, key) => {
              if (item.default === 1) {
                org.push(item);
              }
            });

            let organization_id =
              org.length > 0 ? org[0].organization_id : undefined;
            let sport = org.length > 0 ? org[0].sport : undefined;
            let stats = org.length > 0 ? org[0].stats : undefined;
            let role = org.length > 0 ? JSON.parse(org[0].position) : [];
            let position = [];
            role.map((item, key) => {
              position.push({
                role: item,
              });
            });
            console.log("users", position);

            let height = res.data.athlete_info.height
              ? res.data.athlete_info.height
              : undefined;
            let weight = res.data.athlete_info.weight
              ? res.data.athlete_info.weight
              : undefined;

            let address = res.data.user_address;
            let country =
              address.length > 0 && address[0].country
                ? address[0].country
                : undefined;
            let state =
              address.length > 0 && address[0].state
                ? address[0].state
                : undefined;
            let address1 =
              address.length > 0 && address[0].address1
                ? address[0].address1
                : undefined;
            let address2 =
              address.length > 0 && address[0].address2
                ? address[0].address2
                : undefined;
            let city =
              address.length > 0 && address[0].city
                ? address[0].city
                : undefined;
            let zip_code =
              address.length > 0 && address[0].zip_code
                ? address[0].zip_code
                : undefined;

            form.setFieldsValue({
              username: res.data.username,
              email: res.data.email,
              firstname: res.data.firstname,
              lastname: res.data.lastname,
              date_of_birth:
                res.data.birthdate && res.data.birthdate !== "0000-00-00"
                  ? moment(res.data.birthdate)
                  : null,
              gender: res.data.gender,
              organization_id: organization_id,
              sport: sport,
              position: position,
              stats: stats,
              height: height,
              weight: weight,
              country: country,
              state: state,
              address1: address1,
              address2: address2,
              city: city,
              zip_code: zip_code,
            });

            if (res.data.user_address[0].country === "United States") {
              setStateLabel("State");
              setZipLabel("Zip Code");
              setOptionState(stateUS);
              setOptionZip(/(^\d{5}$)|(^\d{5}[ -]\d{4}$)/);

              let get_city = stateUS.filter(
                (item) => res.data.user_address[0].state === item.value
              );
              setCityOption(get_city[0]["json"]);
            } else {
              setStateLabel("County");
              setZipLabel("Postal Code");
              setOptionState(stateCA);
              setOptionZip(/^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/);

              let get_city = stateCA.filter(
                (item) => res.data.user_address[0].state === item.value
              );
              setCityOption(get_city[0]["json"]);
            }
          }
        },
      }
    );
  };
  const { mutate: mutateGetAthlete, isLoading: isLoadingGetAthlete } = POST(
    "api/v1/show_post",
    "show_post"
  );

  // Athlete organization start
  const [organizationData, setOrganizationData] = useState([]);

  // get athlete added organization
  const { refetch: refetchGetMyOrganization } = GETMANUAL(
    `api/v1/get_my_organization?user_id=${athlete_id}`,
    "get_my_organization",
    (res) => {
      if (res.success) {
        setOrganizationData(res.data.length > 0 ? res.data : []);
      }
    }
  );

  useEffect(() => {
    if (athlete_id) {
      refetchGetMyOrganization();
    } else {
      setOrganizationData([]);
    }
  }, [athlete_id]);

  return (
    <>
      <ComponentHeader
        sub_title={athleteTitle}
        title={permission}
        icon={faUser}
      />

      <Card id="PageUserCreateAccount" loading={isLoadingGetAthlete}>
        <Form
          layout="vertical"
          onFinish={onFinish}
          autoComplete="off"
          form={form}
          initialValues={{ position: [""] }}
          scrollToFirstError
        >
          <Collapse
            className="ant-collapse-primary"
            expandIcon={({ isActive }) =>
              isActive ? (
                <span
                  className="ant-menu-submenu-arrow"
                  style={{ color: "#FFF", transform: "rotate(270deg)" }}
                ></span>
              ) : (
                <span
                  className="ant-menu-submenu-arrow"
                  style={{ color: "#FFF", transform: "rotate(90deg)" }}
                ></span>
              )
            }
            expandIconPosition="right"
            defaultActiveKey={["1", "2", "3"]}
          >
            <Panel
              header="ATHLETE'S INFORMATION"
              key="1"
              className="accordion bg-darkgray-form"
            >
              <Row gutter={12}>
                <Col xs={24} sm={24} md={12}>
                  <Form.Item
                    name="firstname"
                    rules={[
                      {
                        required: true,
                        message: "This field is required.",
                      },
                    ]}
                  >
                    <FloatInput
                      label="First Name"
                      placeholder="First Name"
                      onBlurInput={(value) => {
                        let lastname = form.getFieldsValue().lastname;
                        if (value && lastname) {
                          let email = `${value.toLowerCase()}${lastname.toLowerCase()}@bfssathlete.com`;
                          form.setFieldsValue({
                            email: email,
                          });
                        } else {
                          form.resetFields(["email"]);
                        }
                      }}
                      required
                    />
                  </Form.Item>
                </Col>

                <Col xs={24} sm={24} md={12}>
                  <Form.Item
                    name="lastname"
                    rules={[
                      {
                        required: true,
                        message: "This field is required.",
                      },
                    ]}
                  >
                    <FloatInput
                      label="Last Name"
                      placeholder="Last Name"
                      onBlurInput={(value) => {
                        let firstname = form.getFieldsValue().firstname;
                        if (value && firstname) {
                          let email = `${firstname.toLowerCase()}${value.toLowerCase()}@bfssathlete.com`;
                          form.setFieldsValue({
                            email: email,
                          });
                        } else {
                          form.resetFields(["email"]);
                        }
                      }}
                      required
                    />
                  </Form.Item>
                </Col>

                <Col xs={24} sm={24} md={12}>
                  <Form.Item
                    name="username"
                    rules={[
                      {
                        required: true,
                        message: "This field is required.",
                      },
                    ]}
                  >
                    <FloatInput
                      label="Username"
                      placeholder="Username"
                      disabled={athlete_id ? true : false}
                      required
                    />
                  </Form.Item>
                </Col>

                <Col xs={24} sm={24} md={12}>
                  <Form.Item
                    name="email"
                    rules={[
                      {
                        type: "email",
                        message: "Invalid email!",
                      },
                      {
                        required: true,
                        message: "This field is required.",
                      },
                    ]}
                  >
                    <FloatInput
                      label="Email"
                      placeholder="Email"
                      disabled={athlete_id ? true : false}
                      required
                    />
                  </Form.Item>
                </Col>

                <Col xs={24} sm={24} md={12}>
                  <Form.Item
                    name="date_of_birth"
                    rules={[
                      {
                        required: true,
                        message: "This field is required.",
                      },
                    ]}
                  >
                    <FloatDatePickerForm
                      label="DOB: MM/DD/YYYY"
                      placeholder="DOB: MM/DD/YYYY"
                      dropdownClassName="date-picker-dropdown-in-modal"
                      format="MM/DD/YYYY"
                      required
                    />
                  </Form.Item>
                </Col>

                <Col xs={24} sm={24} md={12}>
                  <Form.Item
                    name="gender"
                    rules={[
                      {
                        required: true,
                        message: "This field field is required.",
                      },
                    ]}
                    className="form-select-error"
                  >
                    <FloatSelect
                      label="Birth Gender"
                      placeholder="Birth Gender"
                      options={[
                        // { label: "Both", value: "Both" },
                        { label: "Male", value: "Male" },
                        { label: "Female", value: "Female" },
                      ]}
                      dropdownClassName="select-dropdown-in-modal"
                      required
                    />
                  </Form.Item>
                </Col>

                <Col xs={24} sm={24} md={12}>
                  <Form.Item
                    name="height"
                    rules={[
                      {
                        required: true,
                        message: "This field field is required.",
                      },
                    ]}
                  >
                    <FloatInput
                      label="Height In Inches"
                      placeholder="Height In Inches"
                      required
                    />
                  </Form.Item>
                </Col>

                <Col xs={24} sm={24} md={12}>
                  <Form.Item
                    name="weight"
                    rules={[
                      {
                        required: true,
                        message: "This field field is required.",
                      },
                    ]}
                  >
                    <FloatInput
                      label="Weight In Pounds"
                      placeholder="Weight In Pounds"
                      required
                    />
                  </Form.Item>
                </Col>

                <Col xs={24} sm={24} md={12}>
                  <Form.Item
                    name="country"
                    className="form-select-error"
                    rules={[
                      {
                        required: true,
                        message: "This field is required.",
                      },
                    ]}
                  >
                    <FloatSelect
                      label="Country"
                      placeholder="Country"
                      options={country}
                      onChange={handleCountry}
                      required
                    />
                  </Form.Item>
                </Col>

                <Col xs={24} sm={24} md={12}>
                  <Form.Item
                    name="state"
                    className="form-select-error"
                    rules={[
                      {
                        required: true,
                        message: "This field is required.",
                      },
                    ]}
                  >
                    <FloatSelect
                      label={stateLabel}
                      placeholder={stateLabel}
                      options={optionState}
                      onChange={(val, opt) => {
                        let rows = opt["data-json"];
                        setCityOption(rows);
                        form.resetFields(["city"]);
                      }}
                      required
                    />
                  </Form.Item>
                </Col>

                <Col xs={24} sm={24} md={24}>
                  <Form.Item
                    name="address1"
                    rules={[
                      {
                        required: true,
                        message: "This field is required.",
                      },
                    ]}
                  >
                    <FloatInput
                      label="Address 1"
                      placeholder="Address 1"
                      required
                    />
                  </Form.Item>
                </Col>

                <Col xs={24} sm={24} md={24}>
                  <Form.Item name="address2">
                    <FloatInput label="Address 2" placeholder="Address 2" />
                  </Form.Item>
                </Col>

                <Col xs={24} sm={24} md={12}>
                  <Form.Item
                    name="city"
                    className="form-select-error"
                    rules={[
                      {
                        required: true,
                        message: "This field is required.",
                      },
                    ]}
                  >
                    <FloatSelect
                      label="City"
                      placeholder="City"
                      options={cityOption}
                      required
                    />
                  </Form.Item>
                </Col>

                <Col xs={24} sm={24} md={12}>
                  <Form.Item
                    name="zip_code"
                    rules={[
                      {
                        required: true,
                        message: "This field is required.",
                      },
                      {
                        pattern: optionZip,
                        message: "Invalid " + zipLabel,
                      },
                    ]}
                  >
                    <FloatInput
                      label={zipLabel}
                      placeholder={zipLabel}
                      required
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Panel>

            <Panel
              header="ATHLETE'S ORGANIZATION"
              key="2"
              className="accordion bg-darkgray-form"
            >
              <ComponentAthleteOgranization
                Ogranization
                organizationData={organizationData}
                setOrganizationData={setOrganizationData}
                athleteId={athlete_id}
              />
            </Panel>

            {athlete_id && (
              <>
                <Panel
                  header="Assessments"
                  key="3"
                  className="accordion bg-darkgray-form m-t-md"
                >
                  <PageUserCreateAccountAthleteAssessment
                    athleteData={athleteData}
                  />
                </Panel>
              </>
            )}
          </Collapse>
          <br />

          <Row gutter={24} justify="end">
            <Col xs={24} sm={24} md={10} lg={9} xl={6}>
              <Button
                type="primary"
                htmlType="submit"
                loading={isLoadingAdd}
                className="btn-main-outline-2"
                size="large"
                block
              >
                <FontAwesomeIcon icon={faSave} className="m-r-xs" /> SUBMIT
              </Button>
            </Col>
          </Row>
          <br />
          <br />
        </Form>
      </Card>
    </>
  );
}
