import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarAlt,
  faChartBar,
  faChartNetwork,
  faCommentDots,
  faHome,
  faQuestionCircle,
} from "@fortawesome/pro-regular-svg-icons";

const AthleteSideMenu = [
  {
    title: "Dashboard",
    path: "/dashboard",
    icon: <FontAwesomeIcon icon={faHome} style={{ marginTop: "-5px" }} />,
    permission: "Dashboard",
  },
  {
    title: "Calendar - View & Book",
    path: "/calendar",
    icon: (
      <FontAwesomeIcon icon={faCalendarAlt} style={{ marginTop: "-5px" }} />
    ),
    className: "menu-item-calendar",
    children: [
      {
        title: "All Assessments",
        path: "/calendar/all-assessments",
        permission: "Calendar - All Assessments",
      },
      {
        title: "Book a Private Assessment",
        path: "/calendar/book-assessment",
        permission: "Calendar - Book a Private Assessment",
      },
      {
        title: "My Scheduled Assessments",
        path: "/calendar/scheduled-assessments",
        permission: "Calendar - My Scheduled Assessments",
      },
    ],
  },
  {
    title: "My Assessment Stats",
    path: "/assessment-stats",
    icon: <FontAwesomeIcon icon={faChartBar} style={{ marginTop: "-5px" }} />,
    permission: "My Assessment Stats",
  },
  {
    title: "My Athletic Profile",
    path: "/athletic-profile",
    icon: (
      <FontAwesomeIcon
        icon={faChartNetwork}
        style={{ marginTop: "-3px !important" }}
      />
    ),
    children: [
      {
        title: "Edit My Athletic Profile",
        path: "/athletic-profile/edit",
        permission: "Athletic Profile - Edit My Athletic Profile",
      },
      {
        title: "View My Webpage",
        path: "/athletic-profile/view-webpage",
        permission: "Athletic Profile - View My Webpage",
        targetNew: 1,
      },
    ],
  },
  {
    title: "Support",
    path: "/support",
    icon: (
      <FontAwesomeIcon icon={faQuestionCircle} style={{ marginTop: "-5px" }} />
    ),
    children: [
      {
        title: "FAQs",
        path: "/support/faqs",
        permission: "Support - FAQs",
      },
      {
        title: "Ticketing",
        path: "/support/ticketing",
        permission: "Support - Ticketing",
      },
    ],
  },
  {
    title: "Messages",
    path: "/messages",
    icon: (
      <FontAwesomeIcon icon={faCommentDots} style={{ marginTop: "-5px" }} />
    ),
    permission: "Messages",
  },
];

export default AthleteSideMenu;
