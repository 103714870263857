import React from "react";
import { Link } from "react-router-dom";
import { Menu, Typography } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faCalendarAlt,
	faChartNetwork,
} from "@fortawesome/pro-regular-svg-icons";

export const menuLeft = (
	<>
		<div className="ant-space-icon">
			<Link to="/calendar/scheduled-assessments">
				<FontAwesomeIcon icon={faCalendarAlt} />
				<Typography.Text className="ant-typography-text">
					Scheduled <br /> Assessments
				</Typography.Text>
			</Link>
		</div>

		<div className="ant-space-icon">
			<Link to="/athletic-profile/edit">
				<FontAwesomeIcon icon={faChartNetwork} />

				<Typography.Text className="ant-typography-text">
					My Athletic <br /> Profile
				</Typography.Text>
			</Link>
		</div>
	</>
);

export const dropDownMenuLeft = (
	<Menu>
		<Menu.Item
			key="/calendar/scheduled-assessments"
			icon={<FontAwesomeIcon icon={faCalendarAlt} />}
		>
			<Link to="/calendar/scheduled-assessments">Scheduled Assessments</Link>
		</Menu.Item>

		<Menu.Item
			key="/athletic-profile/edit"
			icon={<FontAwesomeIcon icon={faChartNetwork} />}
		>
			<Link to="/athletic-profile/edit">My Athletic Profile</Link>
		</Menu.Item>
	</Menu>
);
