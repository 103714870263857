import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Alert,
  Button,
  Card,
  Col,
  Collapse,
  Divider,
  Form,
  Image,
  message,
  notification,
  Popconfirm,
  Radio,
  Row,
  Space,
  Switch,
  Typography,
} from "antd";
import {
  faChartNetwork,
  faSave,
  faTrashAlt,
} from "@fortawesome/pro-regular-svg-icons";
import ComponentHeader from "../../Components/ComponentHeader";
import FloatInput from "../../../../providers/FloatInput";
import FloatSelect from "../../../../providers/FloatSelect";
// import FloatSelect2 from "../../../../providers/FloatSelect2";
import FloatDatePicker from "../../../../providers/FloatDatePicker";
import FloatTextArea from "../../../../providers/FloatTextArea";
import $ from "jquery";
import optionCountryCodes from "../../../../providers/optionCountryCodes";
import optionStateCodesUnitedState from "../../../../providers/optionStateCodesUnitedState";
import optionStateCodesCanada from "../../../../providers/optionStateCodesCanada";
import optionStateCodesMexico from "../../../../providers/optionStateCodesMexico";
import optionOrganizationType from "../../../../providers/optionOrganizationType";
import optionSports from "../../../../providers/optionSports";

import companyInfo from "../../../../providers/companyInfo";

import {
  DELETE,
  GET,
  POST,
  GETMANUAL,
} from "../../../../providers/useAxiosQuery";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import ImgCrop from "antd-img-crop";
import { Upload } from "antd";
import moment from "moment";
import {
  formats,
  modulesToolBarV2,
} from "../../../../providers/reactQuillOptions";

import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import ImageResize from "quill-image-resize-module-react";
import FloatDatePickerForm from "../../../../providers/FloatDatePickerForm";
import FloatSelectCoach from "../../../../providers/FloatSelectCoach";
Quill.register("modules/imageResize", ImageResize);

const userData = companyInfo().userData;

export default function PageAthleticProfileEdit() {
  const [formInformation] = Form.useForm();
  const [formWebsite] = Form.useForm();

  // console.log("userData", userData);
  const [optionStateLabel, setOptionStateLabel] = useState("State");
  const [optionState, setOptionState] = useState([]);

  const [selectedData, setSelectedData] = useState();
  const [selectedDataOrg, setSelectedDataOrg] = useState([]);
  const [getData, setGetData] = useState();
  const [getDataOrg, setGetDataOrg] = useState([]);
  const [getDataWebDefault, setGetDataWebDefault] = useState([
    {
      is_default: 1,
    },
  ]);

  const [dataOptionOganizationType, setDataOptionOganizationType] = useState([
    [],
  ]);
  // const [activePanelOrganization, setActivePanelOrganization] = useState([]);
  const [optionOrganization, setOptionOrganization] = useState([[]]);
  const [optionOrganizationWebsite, setOptionOrganizationWebsite] = useState([
    [],
  ]);
  const [optionOrganizationState, setOptionOrganizationState] = useState([[]]);
  const [optionOrganizationZip, setOptionOrganizationZip] = useState([[]]);
  const [optionCoachs, setOptionCoachs] = useState([]);

  const stateUS = optionStateCodesUnitedState();
  const stateCA = optionStateCodesCanada();
  const stateMX = optionStateCodesMexico();

  const [radioData, setRadioData] = useState(1);
  const [imageCrop, setImageCrop] = useState({
    width: 1,
    height: 1,
  });

  const [club, setClub] = useState([]);
  const [school, setSchool] = useState([]);
  const [university, setUniversity] = useState([]);
  const [selectOptionOrganization, setSelectOptionOrganization] = useState([]);
  const [optionAthleteOrganization, setOptionAthleteOrganization] = useState(
    []
  );

  const [orgArrayOption, setOrgArrayOption] = useState([
    {
      organization_type: "",
      organization_option: [],
      coach_option: [],
      listed: 0,
      orgStatus: "Create",
      changedState: false,
      code_pattern: /(^\d{5}$)|(^\d{5}[ -]\d{4}$)/,
      code_label: " Zip Code",
      state_label: "state",
    },
  ]);

  const [enableAddOrganization, setEnableAddOrganization] = useState([
    { status: 1 },
  ]);

  const [load, setLoad] = useState(false);

  const [cityOtionsInfo, setCityOtionsInfo] = useState([]);
  GET(
    `api/v1/athlete_organization?user_id=${userData.id}`,
    "athlete_organization",
    (res) => {
      if (res.success) {
        console.log("athlete_organization", res);
        setClub(res.club);
        setSchool(res.school);
        setUniversity(res.university);
        let dataOptionAthleteOrganization = res.athleteOrganization.map(
          (item) => {
            return {
              value: item.id,
              label: item.organization.name,
            };
          }
        );

        setOptionAthleteOrganization(dataOptionAthleteOrganization);
        setLoad(true);
      }
    }
  );

  useEffect(() => {
    if (load) {
      refetchProfile();
    }
    return () => {};
  }, [load]);

  const { refetch: refetchProfile } = GETMANUAL(
    `api/v1/user_athlete_info?id=${userData.id}`,
    "athletic_user_athlete_info",
    (res) => {
      console.log("user_athlete_info_res", res.data);
      if (res.data) {
        let data = res.data;
        let user_address = data.user_address.filter(
          (itemFilter) => itemFilter.is_primary === 1
        );
        setSelectedData(data);

        setRadioData(data.athlete_info.photo_crop);
        if (data.athlete_info.photo_crop === 1) {
          setImageCrop({
            width: 1,
            height: 1,
          });
        } else if (data.athlete_info.photo_crop === 2) {
          setImageCrop({
            width: 3.9,
            height: 2.6,
          });
        } else if (data.athlete_info.photo_crop === 3) {
          setImageCrop({
            width: 1,
            height: 1.5,
          });
        }

        if (user_address && user_address[0].state) {
          // stateUS;
          // stateCA;

          if (user_address[0].country === "United States") {
            let get_city = stateUS.filter(
              (item) => user_address[0].state === item.value
            );

            console.log(
              "countrylist",
              get_city.length > 0 ? get_city[0]["json"] : []
            );
            setCityOtionsInfo(get_city.length > 0 ? get_city[0]["json"] : []);
          } else if (user_address[0].country === "Canada") {
            let get_city = stateCA.filter(
              (item) => user_address[0].state === item.value
            );
            console.log(
              "countrylist",
              get_city.length > 0 ? get_city[0]["json"] : []
            );
            setCityOtionsInfo(get_city.length > 0 ? get_city[0]["json"] : []);
          }
        }

        let organizations = [];
        let tempOptionoptionOrganization = [];
        let tempOptionOrganizationState = [];
        let tempOptionOrganizationZip = [];
        let tempActivePanelOrganization = [];

        let org_data = [];
        let org_dataenableAddOrganization = [];
        let defaultState = data.user_address && data.user_address[0].state;
        if (data.athlete_organization && data.athlete_organization.length > 0) {
          data.athlete_organization.map((item, index) => {
            organizations.push({
              id: item.id,
              organization_type: item.organization.organization_type,
              organization_id: item.organization_id,
              organization_name_edit: item.organization.name,
              organization_id_view_name: item.organization.name,
              sport: item.sport,
              positions: JSON.parse(item.position),
              coach_id: item.coach_id ? item.coach_id.toString() : "",
              coach_id_view_name: item.coach_id_view_name
                ? item.coach_id_view_name
                : "",
              stats: item.stats,
            });

            tempOptionoptionOrganization.push([
              {
                value: item.organization.id,
                label: item.organization.name,
              },
            ]);

            let state_list = [];
            let state_lbl = "State";
            let zip_lbl = "Zip Code";
            let zip_patt = /(^\d{5}$)|(^\d{5}[ -]\d{4}$)/;
            if (item.organization.country === "United States") {
              tempOptionOrganizationState.push(stateUS);
              tempOptionOrganizationZip.push(/(^\d{5}$)|(^\d{5}[ -]\d{4}$)/);
              state_list = stateUS;
              state_lbl = "State";
              zip_lbl = "Zip Code";
              zip_patt = /(^\d{5}$)|(^\d{5}[ -]\d{4}$)/;
            } else if (item.organization.country === "Canada") {
              tempOptionOrganizationState.push(stateCA);
              tempOptionOrganizationZip.push(
                /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/
              );
              state_list = stateCA;
              state_lbl = "County";
              zip_lbl = "Postal Code";
              zip_patt = /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/;
            }
            tempActivePanelOrganization.push(`${index}`);
            let orgType = [];
            let orgCoach = [];
            if (item.organization.organization_type === "School") {
              let data_coach = school.filter(
                (val) => val["id"] === item.organization_id
              );

              orgType = school;
              orgCoach = data_coach;
            } else if (item.organization.organization_type === "Club") {
              let data_coach = club.filter(
                (val) => val["id"] === item.organization_id
              );

              orgType = club;
              orgCoach = data_coach;
            } else if (item.organization.organization_type === "University") {
              let data_coach = university.filter(
                (val) => val["id"] === item.organization_id
              );

              orgType = university;
              orgCoach = data_coach;
            }

            org_dataenableAddOrganization.push({
              status: 0,
            });

            let status =
              defaultState === item.organization.state ? true : false;
            org_data.push({
              organization_type: item.organization.organization_type,
              organization_option: orgType,
              coach_option: orgCoach,
              listed: 0,
              orgStatus:
                item.organization.status === "Deleted"
                  ? "Pending"
                  : item.organization.status,
              changedState: status,
              state_label: state_lbl,
              state_options: state_list,
              code_label: zip_lbl,
              code_pattern: zip_patt,
            });
          });
        }

        setEnableAddOrganization(org_dataenableAddOrganization);
        console.log("org_data", organizations);
        setOrgArrayOption(org_data);

        let athlete_website = [];
        let athlete_website_default = [];
        data.athlete_website.map((item, index) => {
          let find = data?.athlete_organization?.filter(
            (i) => i.organization_id === item.organization_id
          );
          athlete_website.push({
            id: item.id,
            organization_id:
              find && find.length > 0
                ? item?.athlete_organization_id
                : item?.organization?.name,
            sport: item.sport,
            positions: item.position ? JSON.parse(item.position) : [""],
            name: item.name,
            status: item.status,
            is_default: item.is_default,
          });

          athlete_website_default.push({
            is_default: item.is_default,
          });
          console.log("athlete_website_default find", find);
        });
        setGetDataWebDefault(
          athlete_website_default.length > 0
            ? athlete_website_default
            : [{ status: 0, is_default: 1 }]
        );
        // console.log("athlete_website", athlete_website);

        // console.log("tempActivePanelOrganization", tempActivePanelOrganization);
        setOptionOrganizationState(tempOptionOrganizationState);
        setOptionOrganizationZip(tempOptionOrganizationZip);
        setOptionOrganization(tempOptionoptionOrganization);
        // setActivePanelOrganization(tempActivePanelOrganization);

        let filterdData = {
          ...data,
          country: user_address[0].country,
          state: user_address[0].state,
          city: user_address[0].city ? user_address[0].city : undefined,
          birthdate: moment(data.birthdate).format("DD/MM/YYYY"),
          grade:
            data.athlete_info && data.athlete_info.grade
              ? data.athlete_info.grade
              : "",
          graduation_year:
            data.athlete_info && data.athlete_info.graduation_year
              ? moment(data.athlete_info.graduation_year).format("YYYY")
              : "",
          height:
            data.athlete_info && data.athlete_info.height
              ? data.athlete_info.height
              : "",
          weight:
            data.athlete_info && data.athlete_info.weight
              ? data.athlete_info.weight
              : "",
          about_me:
            data.athlete_info && data.athlete_info.about_me
              ? data.athlete_info.about_me
              : "",
          personal_stats:
            data.athlete_info && data.athlete_info.personal_stats
              ? data.athlete_info.personal_stats
              : "",
          instagram_acc:
            data.athlete_info && data.athlete_info.instagram_acc
              ? data.athlete_info.instagram_acc
              : "",
          youtube_vimeo_channel_acc:
            data.athlete_info && data.athlete_info.youtube_vimeo_channel_acc
              ? data.athlete_info.youtube_vimeo_channel_acc
              : "",
          video_link:
            data.athlete_info && data.athlete_info.video_link
              ? data.athlete_info.video_link
              : "",
          organizations: organizations.length > 0 ? organizations : [],
          athlete_website:
            athlete_website.length > 0
              ? athlete_website
              : [{ positions: [""] }],
        };
        console.log("get_data", filterdData);
        console.log("get_data user_address", user_address);
        setGetData(filterdData);
        setGetDataOrg(
          organizations.length > 0
            ? organizations
            : [
                {
                  coach_id: "",
                  organization_id: "",
                  organization_type: "",
                  positions: [],
                  sport: "",
                  stats: "",
                },
              ]
        );

        console.log("organizations", organizations);

        // selectedDataOrg
        setSelectedDataOrg(organizations.length > 0 ? organizations : []);
        // console.log("user_address", user_address);

        // console.log("info_state", user_address[0].state);

        formInformation.setFieldsValue({
          firstname: data.firstname,
          lastname: data.lastname,
          email: data.email,
          country:
            user_address && user_address[0].country
              ? user_address[0].country
              : undefined,
          state:
            user_address && user_address[0].state
              ? user_address[0].state
              : undefined,
          city:
            user_address && user_address[0]?.city
              ? user_address[0]?.city
              : undefined,
          gender: data.gender,
          birthdate: moment(data.birthdate),
          grade:
            data.athlete_info && data.athlete_info.grade
              ? data.athlete_info.grade
              : undefined,
          graduation_year:
            data.athlete_info && data.athlete_info.graduation_year
              ? moment(data.athlete_info.graduation_year, "YYYY")
              : undefined,
          height:
            data.athlete_info && data.athlete_info.height
              ? data.athlete_info.height
              : "",
          weight:
            data.athlete_info && data.athlete_info.weight
              ? data.athlete_info.weight
              : "",
          about_me:
            data.athlete_info && data.athlete_info.about_me
              ? data.athlete_info.about_me
              : "",
          personal_stats:
            data.athlete_info && data.athlete_info.personal_stats
              ? data.athlete_info.personal_stats
              : "",
          instagram_acc:
            data.athlete_info && data.athlete_info.instagram_acc
              ? data.athlete_info.instagram_acc
              : undefined,
          facebook_acc:
            data.athlete_info && data.athlete_info.facebook_acc
              ? data.athlete_info.facebook_acc
              : undefined,
          twitter_acc:
            data.athlete_info && data.athlete_info.twitter_acc
              ? data.athlete_info.twitter_acc
              : undefined,
          youtube_vimeo_channel_acc:
            data.athlete_info && data.athlete_info.youtube_vimeo_channel_acc
              ? data.athlete_info.youtube_vimeo_channel_acc
              : "",
          video_link:
            data.athlete_info && data.athlete_info.video_link
              ? data.athlete_info.video_link
              : "",

          organizations: organizations.length > 0 ? organizations : [],
        });

        // if (organizations.length > 0) {
        //   formWebsite.setFieldsValue({
        //     organizations: organizations,
        //   });
        // } else {
        //   formWebsite.setFieldsValue({
        //     organizations: [],
        //   });
        // }

        formWebsite.setFieldsValue({
          athlete_website:
            athlete_website.length > 0
              ? athlete_website
              : [{ positions: [""] }],
        });

        let image_type =
          data.athlete_info && data.athlete_info.website_photo
            ? data.athlete_info.website_photo.split("/")
            : "";

        if (image_type[0] !== undefined) {
          if (image_type === "https:") {
            image_type =
              data.athlete_info && data.athlete_info.website_photo
                ? data.athlete_info.website_photo
                : "";
          } else {
            image_type =
              data.athlete_info && data.athlete_info.website_photo
                ? companyInfo().apiUrl + data.athlete_info.website_photo
                : "";
          }

          setFileList([
            {
              uid: "-1",
              name: "image.png",
              status: "done",
              url: image_type,
            },
          ]);
        } else {
          setFileList([
            {
              uid: "-1",
              name: "image.png",
              status: "done",
              url: userData.profile_image,
            },
          ]);
          image_type = "";
        }

        if (user_address && user_address[0].country === "United States") {
          setOptionState(stateUS);
        } else if (user_address && user_address[0].country === "Canada") {
          setOptionState(stateCA);
        }
      }
    }
  );

  useEffect(() => {
    console.log("orgArrayOption", orgArrayOption);
  }, [orgArrayOption]);

  GET(`api/v1/user_coach`, "user_coach_select", (res) => {
    // console.log("res", res.data);
    if (res.data) {
      let data = [];
      res.data.map((item) => {
        data.push({
          value: item.id,
          label: item.lastname,
        });
        return "";
      });
      setOptionCoachs(data);
    }
  });

  GET("api/v1/organization", "organization_select", (res) => {
    // console.log("res", res);
    if (res.data) {
      let dataOrganizationType = [];
      let dataOrganizationWebsite = res.data.map((item2) => {
        return {
          value: item2.id,
          label: item2.name,
        };
      });
      optionOrganizationType.map((item) => {
        dataOrganizationType.push({
          ...item,
          organization: res.data
            .filter(
              (itemFilter) =>
                itemFilter.organization_type === item.value &&
                itemFilter.status === "Approved"
            )
            .map((item2) => {
              return {
                value: item2.id,
                label: item2.name,
              };
            }),
        });
        return "";
      });
      // console.log("dataOrganizationType", dataOrganizationType);

      setDataOptionOganizationType(dataOrganizationType);
      setOptionOrganizationWebsite(dataOrganizationWebsite);
    }
  });

  const {
    mutate: mutateUpdateAthleticUserAthlete,
    // isLoading: isLoadingUpdateAthleticUserAthlete,
  } = POST(
    "api/v1/update_user_athletic_profile",
    "update_user_athletic_profile"
  );

  const {
    mutate: mutateCheckWebsiteName,
    // isLoading: isLoadingUpdateAthleticUserAthlete,
  } = POST("api/v1/check_website_name", "check_website_name");

  const {
    mutate: mutateUserAthleteDefaultWebsite,
    // isLoading: isLoadingUserAthleteEditPermission,
  } = POST("api/v1/user_athlete_default_website", "athletic_user_athlete_info");

  const {
    mutate: mutateUserAthleteWebsite,
    isLoading: isLoadingUserAthleteWebsite,
  } = POST("api/v1/user_athlete_website", "athletic_user_athlete_info");

  const {
    mutate: mutateDeleteOrganization,
    isLoading: isLoadingDeleteOrganization,
  } = DELETE("api/v1/athlete_organization", "delete_user_athlete_organization");

  const {
    mutate: mutateDeleteAthleteWebsite,
    isLoading: isLoadingDeleteAthleteWebsite,
  } = DELETE("api/v1/athlete_website", "delete_user_athlete_website");

  // const handleChangeWebsiteVisibility = (val, opt) => {};
  const handleChangeCountry = (e, opt) => {
    // console.log("e, opt", e, opt);
    if (e === "United States") {
      setOptionState(stateUS);
      setOptionStateLabel("State");
    } else if (e === "Canada") {
      setOptionState(stateCA);
      setOptionStateLabel("County");
    } else {
      setOptionState(stateUS);
      setOptionStateLabel("State");
    }

    formInformation.resetFields(["state", "city"]);
    setCityOtionsInfo([]);
  };

  const [fileList, setFileList] = useState([]);

  const handleResize = (val) => {
    // console.log("val", val.target.value);
    setRadioData(val.target.value);
    if (val.target.value === 1) {
      setImageCrop({
        width: 1,
        height: 1,
      });
    } else if (val.target.value === 2) {
      setImageCrop({
        width: 3.9,
        height: 2.6,
      });
    } else if (val.target.value === 3) {
      setImageCrop({
        width: 1,
        height: 1.5,
      });
    }
  };

  const onChangeUpload = ({ fileList: newFileList }) => {
    var _file = newFileList;
    // console.log(_file);
    if (_file.length !== 0) {
      _file[0].status = "done";
      setFileList(_file);
      formInformation.submit();
    } else {
      setFileList([]);
    }
  };

  const onPreviewUpload = async (file) => {
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow.document.write(image.outerHTML);
  };

  const beforeUpload = (file) => {
    const isJpgOrPng =
      file.type === "image/jpeg" ||
      file.type === "image/png" ||
      file.type === "image/gif" ||
      file.type === "image/jpg";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG, PNG, GIF, JPEG file!");

      return;
    }
    const isLt2M = file.size / 102400 / 102400 < 10;
    if (!isLt2M) {
      message.error("Image must smaller than 10MB!");

      return;
    }

    return isJpgOrPng && isLt2M;
  };

  const onFinishInfomation = (values) => {
    console.log("onFinishInfomation", values);

    const data = new FormData();

    data.append("from_section", "information");
    data.append("id", userData.id);
    data.append("photo_crop", radioData);

    Object.keys(values).map(function (key) {
      // console.log("key, values[key]", key, values[key]);
      if (key === "birthdate") {
        data.append(key, values[key] ? values[key].format("YYYY-MM-DD") : "");
      } else if (key === "graduation_year") {
        console.log("graduation_year", values[key]);
        data.append(key, values[key] ? values[key].format("YYYY") : "");
      } else if (key === "organizations") {
        // console.log("organizations", values[key]);
        data.append(
          "organizations",
          values[key] ? JSON.stringify(values[key]) : JSON.stringify([])
        );
      } else if (key === "athlete_website") {
        data.append(
          "athlete_website",
          values[key] ? JSON.stringify(values[key]) : JSON.stringify([])
        );
      } else {
        data.append(key, values[key] ? values[key] : "");
      }
      return "";
    });

    if (fileList.length !== 0) {
      if (fileList[0].uid !== "-1") {
        data.append(
          "website_photo",
          fileList[0].originFileObj,
          fileList[0].name
        );
      }
    }

    mutateUpdateAthleticUserAthlete(data, {
      onSuccess: (res) => {
        if (res.success) {
          notification.success({
            message: "Athletic Profile",
            description: "Successfully Updated",
          });

          setSelectedData(res.data);

          refetchProfile();
        } else {
          notification.error({
            message: "Athletic Profile",
            description: "Data not updated!",
          });

          refetchProfile();
        }
      },
    });
  };

  const handleRemoveOrganization = (index) => {
    let tempDataOrganization = [...selectedData.athlete_organization];
    // console.log("tempDataOrganization", tempDataOrganization);

    let tempDataOrganizationFiltered = tempDataOrganization[index];
    // console.log("tempDataOrganizationFiltered", tempDataOrganizationFiltered);

    if (tempDataOrganizationFiltered) {
      mutateDeleteOrganization(tempDataOrganizationFiltered, {
        onSuccess: (res) => {
          if (res.success) {
            notification.success({
              message: "Organization",
              description: "Successfully Deleted",
            });
            let tempOptionOrganization = [...optionOrganization];
            // console.log(
            // 	"tempOptionOrganization",
            // 	tempOptionOrganization[index]
            // );
            tempOptionOrganization.splice(index, 1);
            setOptionOrganization(tempOptionOrganization);

            tempDataOrganization.splice(index, 1);

            let tempSelectedData = {
              ...selectedData,
              athlete_organization: tempDataOrganization,
            };

            // console.log("tempSelectedData", tempSelectedData);

            setSelectedData(tempSelectedData);
          } else {
            notification.error({
              message: "Organization",
              description: "Data Not Deleted",
            });
          }
        },
        onError: (err) => {},
      });
    } else {
      let tempOptionOrganization = [...optionOrganization];
      // console.log("tempOptionOrganization", tempOptionOrganization[index]);
      tempOptionOrganization.splice(index, 1);
      setOptionOrganization(tempOptionOrganization);
    }
  };

  const handleRemoveWebsite = (index) => {
    let tempDataWebsite = [...selectedData.athlete_website];
    // console.log("tempDataWebsite", tempDataWebsite);
    //
    let tempDataWebsiteFiltered = tempDataWebsite[index];
    // console.log("tempDataWebsiteFiltered", tempDataWebsiteFiltered);

    if (tempDataWebsiteFiltered) {
      mutateDeleteAthleteWebsite(tempDataWebsiteFiltered, {
        onSuccess: (res) => {
          if (res.success) {
            notification.success({
              message: "Website",
              description: "Data Successfully Deleted!",
            });

            tempDataWebsite.splice(index, 1);

            let tempSelectedData = {
              ...selectedData,
              athlete_website: tempDataWebsite,
            };

            // console.log("tempSelectedData", tempSelectedData);

            setSelectedData(tempSelectedData);
          } else {
            notification.error({
              message: "Website",
              description: "Data Not Deleted!",
            });
          }
        },
        onError: (err) => {},
      });
    }
  };

  const [findWebName, setFindWebName] = useState("");
  const handleOnchageWebname = (val) => {
    if (val) {
      setFindWebName(val);
    } else {
      setFindWebName("remove");
    }
  };

  const handleBlurSavePickAName = (value, index, field) => {
    // console.log("value", value);
    // console.log("index", index);
    // console.log("field", field);
    // console.log("data", getData.athlete_website[index][field]);
    // if (getData.athlete_website[index][field] !== value) {
    if (findWebName) {
      let data = { name: findWebName, user_id: userData.id };
      mutateCheckWebsiteName(data, {
        onSuccess: (res) => {
          if (res.success) {
            // notification.success({
            // 	message: res.data,
            // 	description: "Data Successfully Deleted!",
            // });
            // formInformation.submit();
          } else {
            notification.error({
              message: res.message,
              description: res.description,
            });
          }
        },
        onError: (err) => {},
      });
    }
  };

  const onChangeDefaultWebsite = (checked, id) => {
    let data = {
      id: id,
      user_id: userData.id,
      is_default: checked ? "1" : "0",
    };
    setGetDataWebDefault([
      {
        is_default: 0,
      },
    ]);
    mutateUserAthleteDefaultWebsite(data, {
      onSuccess: (res) => {
        if (res.success) {
          notification.success({
            message: res.message,
            description: res.description,
          });

          let tempSelectedData = { ...selectedData, athlete_website: res.data };
          // console.log("tempSelectedData", tempSelectedData);
          setSelectedData(tempSelectedData);

          refetchProfile();
        } else {
          notification.warning({
            message: res.message,
            description: res.description,
          });
        }
      },
    });
  };

  const handleBlur = (value, field) => {
    let valueRecord = getData[field];
    if (field === "facebook_acc") {
      if (
        getData?.athlete_info?.facebook_acc === "<p><br></p>" ||
        getData?.athlete_info?.facebook_acc === undefined ||
        getData?.athlete_info?.facebook_acc === null
      ) {
        valueRecord = "";
      }
    } else if (field === "instagram_acc") {
      if (
        getData?.athlete_info?.instagram_acc === "<p><br></p>" ||
        getData?.athlete_info?.instagram_acc === undefined ||
        getData?.athlete_info?.instagram_acc === null
      ) {
        valueRecord = "";
      }
    } else if (field === "twitter_acc") {
      if (
        getData?.athlete_info?.twitter_acc === "<p><br></p>" ||
        getData?.athlete_info?.twitter_acc === undefined ||
        getData?.athlete_info?.twitter_acc === null
      ) {
        valueRecord = "";
      }
    }

    console.log("getData fetch", valueRecord);
    console.log("getData", value);

    if (valueRecord !== value) {
      formInformation
        .validateFields()
        .then((values) => {
          onFinishInfomation(values);
        })
        .catch((info) => {
          console.log("Validate Failed:", info);
        });
    }
  };

  const handleBlurQuill = (value, field) => {
    let valueRecord = getData[field];
    if (valueRecord === "<p><br></p>") {
      valueRecord = "";
    }

    if (value === "<p><br></p>") {
      value = "";
    }
    console.log("getData fetch", getData[field]);
    console.log("getData", value);

    if (valueRecord !== value) {
      formInformation
        .validateFields()
        .then((values) => {
          onFinishInfomation(values);
        })
        .catch((info) => {
          console.log("Validate Failed:", info);
        });
    }
  };

  const handleBlurDate = (date, dateString) => {
    console.log("handleBlurDate", date);
    console.log("handleBlurDate", dateString);

    if (dateString) {
      formInformation
        .validateFields()
        .then((values) => {
          onFinishInfomation(values);
        })
        .catch((info) => {
          console.log("Validate Failed:", info);
        });
    }
  };

  const handleBlurSave = (value, index, field) => {
    console.log("handleBlurSave value", value);
    console.log("handleBlurSave index", index);
    console.log("handleBlurSave field", field);
    // console.log("field", getData[index].organizations[index][field]);
    console.log("handleBlurSave field", getDataOrg[index]);
    // console.log("field val", value);

    if (getDataOrg[index][field] !== value) {
      formInformation
        .validateFields()
        .then((values) => {
          onFinishInfomation(values);
        })
        .catch((info) => {
          console.log("Validate Failed:", info);
        });
    }
  };

  const handleBlurSaveArr = (value, index, field, indexArr) => {
    // console.log("value", value);
    // console.log("index", index);
    // console.log("field", field);
    // console.log("indexArr", indexArr);
    // console.log("field", getData.organizations[index][field][indexArr]);
    // console.log("field", field);
    // let tempOrganization = formInformation.getFieldsValue().organizations;

    console.log("handleBlurSaveArr", getDataOrg[index][field]);
    console.log("handleBlurSaveArr", field);

    if (getDataOrg[index][field][indexArr] !== value) {
      formInformation
        .validateFields()
        .then((values) => {
          onFinishInfomation(values);
        })
        .catch((info) => {
          console.log("Validate Failed:", info);
        });
    }
  };

  const handletOrgType = (val, opt, index) => {
    if (val) {
      console.log("athlete_organization ", opt);

      let row = [...orgArrayOption];
      if (val === "School") {
        row[index] = {
          ...row[index],
          organization_type: val,
          organization_option: school,
          coach_option: [],
        };
        setOrgArrayOption(row);
      } else if (val === "Club") {
        row[index] = {
          ...row[index],
          organization_type: val,
          organization_option: club,
          coach_option: [],
        };
        setOrgArrayOption(row);
      } else if (val === "University") {
        row[index] = {
          ...row[index],
          organization_type: val,
          organization_option: university,
          coach_option: [],
        };
        setOrgArrayOption(row);
      }

      let tempOrganization = formInformation.getFieldsValue().organizations;
      tempOrganization[index] = {
        ...tempOrganization[index],
        organization_id: undefined,
        coach_id: undefined,
      };

      console.log("athlete_organization ", row);
      formInformation.setFieldsValue({
        organizations: tempOrganization,
      });

      $(`.select-org-id-${index} .ant-form-item-feedback-icon-success`).hide();

      // const [orgArrayOption, setOrgArrayOption] = useState([
      //   {
      //     organization_type: "",
      //     organization_option: [],
      //     coach_option: [],
      //   },
      // ]);
    }
  };

  const handleOrgName = (val, opt, index) => {
    if (val) {
      let form = formInformation.getFieldsValue().organizations;
      console.log("athlete_organization", form);

      let check_org = form.filter((item, key) => {
        if (item.organization_id === val && index !== key) {
          return item;
        }
      });

      if (check_org.length > 0) {
        notification.warning({
          message: "Warning",
          description: "You already selected this organization.",
        });
        let row = [...form];
        row[index] = {
          ...row[index],
          organization_id: null,
          organization_name: null,
        };

        formInformation.setFieldsValue({
          organizations: row,
        });

        $(
          `.input-org-name-${index} .ant-form-item-feedback-icon-success`
        ).hide();
        $(
          `.select-org-id-${index} .ant-form-item-feedback-icon-success`
        ).hide();
      } else {
        mutateCheckOrganization(
          { organization_id: val, user_id: userData.id },
          {
            onSuccess: (res) => {
              if (res.success) {
                notification.warning({
                  message: "Warning",
                  description: "You already selected this organization.",
                });

                let row = [...form];
                row[index] = {
                  ...row[index],
                  organization_id: undefined,
                  organization_name: undefined,
                };

                formInformation.setFieldsValue({
                  organizations: row,
                });
                console.log("athlete_organization", row);

                $(
                  `.input-org-name-${index} .ant-form-item-feedback-icon-success`
                ).hide();
                $(
                  `.select-org-id-${index} .ant-form-item-feedback-icon-success`
                ).hide();
              } else {
                let organization_type = form[index].organization_type;
                let row = [...orgArrayOption];
                if (organization_type === "School") {
                  let data_coach = school.filter(
                    (val) => val["id"] === opt["value"]
                  );
                  row[index] = {
                    ...row[index],
                    coach_option: data_coach,
                  };
                  console.log(row);
                  setOrgArrayOption(row);
                } else if (organization_type === "Club") {
                  let data_coach = club.filter(
                    (val) => val["id"] === opt["value"]
                  );
                  row[index] = {
                    ...row[index],
                    coach_option: data_coach,
                  };
                  setOrgArrayOption(row);
                } else if (organization_type === "University") {
                  let data_coach = university.filter(
                    (val) => val["id"] === opt["value"]
                  );
                  row[index] = {
                    ...row[index],
                    coach_option: data_coach,
                  };
                  setOrgArrayOption(row);
                }
              }
            },
          }
        );
      }
    }
  };

  const { mutate: mutateCheckOrganization } = POST(
    "api/v1/check_organization_athlete",
    "check_organization_athlete"
  );

  const removeItemOrg = (index) => {
    // orgArrayOption.splice(index, 1);
    // setOrgArrayOption(orgArrayOption);
    orgArrayOption.splice(index, 1);
    setOrgArrayOption(orgArrayOption);

    getDataOrg.splice(index, 1);
    setGetDataOrg(getDataOrg);

    enableAddOrganization.splice(index, 1);
    setEnableAddOrganization(enableAddOrganization);
  };

  // const [webPageDataUpdate, setwebPageDataUpdate] = useState("");

  const handleWebPageInput = (e) => {
    console.log("handleWebPageInput", e);
    if (e) {
      // setwebPageDataUpdate(e);
    }
  };

  const handleWebPageSelect = (val, opt) => {
    if (val) {
      // setwebPageDataUpdate(val);
    }
  };

  const onFinishWebsite = (values) => {
    // console.log("onFinishWebsite", values);
    // console.log("onFinishWebsite", getDataWebDefault);
    let merge_val = [];
    values.athlete_website.map((item, key) => {
      merge_val.push({
        ...item,
        is_default: getDataWebDefault[key].is_default,
      });
    });
    // console.log("onFinishWebsite", merge_val);

    const data = { athlete_website: merge_val, id: userData.id };

    console.log("onFinishWebsite", data);
    mutateUserAthleteWebsite(data, {
      onSuccess: (res) => {
        console.log("mutateUserAthleteWebsite res", res);
        if (res.success) {
          notification.success({
            message: res.message,
            description: res.description,
          });
          refetchProfile();
        } else {
          notification.warning({
            message: res.message,
            description: res.description,
          });
        }
      },
      onError: (err) => {
        notification.success({
          message: "Athlete Website",
          description: err.response.data.message,
        });
      },
    });
  };

  const handleOrgCountry = (val, opt, index) => {
    let row = [...orgArrayOption];
    if (val === "United States") {
      row[index] = {
        ...row[index],
        state_label: "State",
        state_options: stateUS,
        code_label: "Zip Code",
        code_pattern: /(^\d{5}$)|(^\d{5}[ -]\d{4}$)/,
      };
      setOrgArrayOption(row);
    } else if (val === "Canada") {
      row[index] = {
        ...row[index],
        state_label: "County",
        state_options: stateCA,
        code_label: "Postal Code",
        code_pattern: /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/,
      };
      setOrgArrayOption(row);
    }

    // console.log("rowrowrowrow", row[index].state_options);
    // console.log("handleOrgCountry, val, row, index", val, row, index);
  };

  const handleInfoState = (val, opt) => {
    if (val) {
      let option = opt["data-json"];
      setCityOtionsInfo(option);
    } else {
      setCityOtionsInfo([]);
    }
    formInformation.resetFields(["city"]);
  };

  const { data: data_get_manager_state_area } = GET(
    "api/v1/get_manager_state_area2",
    "get_manager_state_area",
    (res) => {
      if (res.success) {
        console.log("get_manager_state_area", res);
      }
    }
  );

  const [sportList, setSportList] = useState([]);
  GET("api/v1/get_all_sport", "get_all_sport", (res) => {
    if (res.success) {
      console.log("get_all_sport", res);
      let row = [];
      res.data &&
        res.data.map((item, key) => {
          row.push({
            value: item.sport,
            label: item.sport,
          });
        });
      setSportList(row);
    }
  });

  return (
    <>
      <ComponentHeader
        title="Athletic Profile"
        sub_title="EDIT MY"
        icon={faChartNetwork}
      />

      <Card
        className="page-athletic-profile card-min-height"
        id="PageAthleticProfileEdit-bu"
      >
        <Row gutter={12}>
          <Col xs={24} sm={24} md={24} lg={14}>
            <Form
              form={formInformation}
              onFinish={onFinishInfomation}
              initialValues={{
                about_me: "",
                personal_stats: "",
              }}
            >
              <Collapse
                className="main-1-collapse border-none"
                expandIcon={({ isActive }) =>
                  isActive ? (
                    <span
                      className="ant-menu-submenu-arrow"
                      style={{ color: "#FFF", transform: "rotate(270deg)" }}
                    ></span>
                  ) : (
                    <span
                      className="ant-menu-submenu-arrow"
                      style={{ color: "#FFF", transform: "rotate(90deg)" }}
                    ></span>
                  )
                }
                defaultActiveKey={["1", "2", "3"]}
                expandIconPosition="start"
              >
                <Collapse.Panel
                  header="ATHLETE'S INFORMATION"
                  key="1"
                  className="accordion bg-darkgray-form m-b-md border"
                >
                  <Row gutter={12}>
                    <Col xs={24} sm={24} md={24} lg={12}>
                      <Form.Item name="firstname">
                        <FloatInput
                          label="First Name"
                          placeholder="First Name"
                          disabled={true}
                          required
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={12}>
                      <Form.Item name="lastname">
                        <FloatInput
                          label="Last Name"
                          placeholder="Last Name"
                          disabled={true}
                          required
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={12}>
                      <Form.Item
                        name="email"
                        rules={[
                          {
                            required: true,
                            message: "This field email is required.",
                          },
                        ]}
                      >
                        <FloatInput
                          label="Preferred Contact Email"
                          placeholder="Preferred Contact Email"
                          onBlurInput={(e) => handleBlur(e, "email")}
                          required
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={12}>
                      <Form.Item
                        name="country"
                        rules={[
                          {
                            required: true,
                            message: "This field country is required.",
                          },
                        ]}
                        className="form-select-error"
                      >
                        <FloatSelect
                          label="Country"
                          placeholder="Country"
                          options={optionCountryCodes}
                          onBlurInput={(e) => handleBlur(e, "country")}
                          onChange={(e, opt) => {
                            handleChangeCountry(e, opt);
                          }}
                          required
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={12}>
                      <Form.Item
                        name="state"
                        rules={[
                          {
                            required: true,
                            message: "This field state is required.",
                          },
                        ]}
                        className="form-select-error"
                      >
                        <FloatSelect
                          label={optionStateLabel}
                          placeholder={optionStateLabel}
                          options={optionState}
                          onChange={handleInfoState}
                          onBlurInput={(e) => handleBlur(e, "state")}
                          required
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={12}>
                      <Form.Item
                        name="city"
                        className="form-select-error"
                        rules={[
                          {
                            required: true,
                            message: "This field city is required.",
                          },
                        ]}
                      >
                        <FloatSelect
                          label="City"
                          placeholder="City"
                          onBlurInput={(e) => handleBlur(e, "city")}
                          options={cityOtionsInfo}
                          required
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={12}>
                      <Form.Item
                        name="gender"
                        rules={[
                          {
                            required: true,
                            message: "This field sex is required.",
                          },
                        ]}
                        className="form-select-error"
                      >
                        <FloatSelect
                          label="Sex"
                          placeholder="Sex"
                          options={[
                            // { label: "Both", value: "Both" },
                            { label: "Male", value: "Male" },
                            { label: "Female", value: "Female" },
                          ]}
                          onBlurInput={(e) => handleBlur(e, "gender")}
                          disabled={true}
                          required
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={12}>
                      <Form.Item
                        name="birthdate"
                        rules={[
                          {
                            required: true,
                            message: "This field date of birth is required.",
                          },
                        ]}
                      >
                        <FloatDatePickerForm
                          label="Date of birth"
                          placeholder="Date of birth"
                          // onBlurInput={(e) => handleBlurDate(e, "birthdate")}
                          onChange={handleBlurDate}
                          disabled={true}
                          required
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={12}>
                      <Form.Item
                        name="grade"
                        rules={[
                          {
                            required: true,
                            message: "This field grade is required.",
                          },
                        ]}
                      >
                        <FloatInput
                          label="Grade"
                          placeholder="Grade"
                          onBlurInput={(e) => handleBlur(e, "grade")}
                          required
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={12}>
                      <Form.Item
                        name="graduation_year"
                        rules={[
                          {
                            required: true,
                            message: "This field graduation year is required.",
                          },
                        ]}
                      >
                        <FloatDatePicker
                          label="Graduation Year"
                          placeholder="Graduation Year"
                          // onBlurInput={(e) => handleBlur(e, "graduation_year")}
                          onChange={handleBlurDate}
                          format="YYYY"
                          picker="year"
                          required
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={12}>
                      <Form.Item
                        name="height"
                        rules={[
                          {
                            required: true,
                            message: "This field height is required.",
                          },
                        ]}
                      >
                        <FloatInput
                          label="Height in Inches"
                          placeholder="Height in Inches"
                          onBlurInput={(e) => handleBlur(e, "height")}
                          required
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={12}>
                      <Form.Item
                        name="weight"
                        rules={[
                          {
                            required: true,
                            message: "This field weight is required.",
                          },
                        ]}
                      >
                        <FloatInput
                          label="Weight in Pounds"
                          placeholder="Weight in Pounds"
                          onBlurInput={(e) => handleBlur(e, "weight")}
                          required
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Collapse.Panel>
                <Collapse.Panel
                  header="ABOUT ME"
                  key="2"
                  className="accordion bg-darkgray-form m-b-md border"
                >
                  <Row>
                    <Col xs={24} sm={24} md={24}>
                      <Form.Item name="about_me" className="quill-input">
                        <ReactQuill
                          theme="snow"
                          style={{ height: 200 }}
                          formats={formats}
                          modules={modulesToolBarV2}
                          onBlur={(range, source, quill) => {
                            handleBlurQuill(quill.getHTML(), "about_me");
                          }}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Collapse.Panel>
                <Collapse.Panel
                  header="PERSONAL STATS"
                  key="3"
                  className="accordion bg-darkgray-form m-b-md border"
                >
                  <Row>
                    <Col xs={24} sm={24} md={24}>
                      <Form.Item name="personal_stats" className="quill-input">
                        <ReactQuill
                          theme="snow"
                          style={{ height: 200 }}
                          formats={formats}
                          modules={modulesToolBarV2}
                          onBlur={(range, source, quill) => {
                            handleBlurQuill(quill.getHTML(), "personal_stats");
                          }}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Collapse.Panel>
                <Collapse.Panel
                  header="SOCIAL MEDIA"
                  key="4"
                  className="accordion bg-darkgray-form m-b-md border"
                >
                  <Row gutter={12}>
                    <Col xs={24} sm={24} md={24} lg={12}>
                      <Form.Item name="facebook_acc">
                        <FloatInput
                          label="Facebook Acc"
                          placeholder="Facebook Acc"
                          onBlurInput={(e) => handleBlur(e, "facebook_acc")}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={12}>
                      <Form.Item name="instagram_acc">
                        <FloatInput
                          label="Instagram Acc"
                          placeholder="Instagram Acc"
                          onBlurInput={(e) => handleBlur(e, "instagram_acc")}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={12}>
                      <Form.Item name="twitter_acc">
                        <FloatInput
                          label="Twitter Acc"
                          placeholder="Twitter Acc"
                          onBlurInput={(e) => handleBlur(e, "twitter_acc")}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Collapse.Panel>
                <Collapse.Panel
                  header="YOUTUBE LINK"
                  key="5"
                  className="accordion bg-darkgray-form m-b-md border"
                >
                  <Row>
                    <Col xs={24} sm={24} md={24}>
                      <Form.Item name="video_link">
                        <FloatInput
                          label="Video Link"
                          placeholder="Video Link"
                          onBlurInput={(e) => handleBlur(e, "video_link")}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Collapse.Panel>
              </Collapse>

              <Row>
                <Col xs={24} sm={24} md={24}>
                  <Form.List name="organizations">
                    {(fields, { add, remove }) => (
                      <>
                        {fields.map(({ key, name, ...restField }, index) => (
                          <Collapse
                            className="main-1-collapse border-none"
                            expandIcon={({ isActive }) =>
                              isActive ? (
                                <span
                                  className="ant-menu-submenu-arrow"
                                  style={{
                                    color: "#FFF",
                                    transform: "rotate(270deg)",
                                  }}
                                ></span>
                              ) : (
                                <span
                                  className="ant-menu-submenu-arrow"
                                  style={{
                                    color: "#FFF",
                                    transform: "rotate(90deg)",
                                  }}
                                ></span>
                              )
                            }
                            defaultActiveKey={["0"]}
                            expandIconPosition="start"
                            key={`${index}`}
                          >
                            <Collapse.Panel
                              header="SCHOOL / CLUB / UNIVERSITY"
                              className="accordion bg-darkgray-form m-b-md border"
                              extra={
                                fields.length > 1 ? (
                                  <Popconfirm
                                    placement="top"
                                    title="Are you sure to delete this organization?"
                                    onConfirm={() => {
                                      if (!isLoadingDeleteOrganization) {
                                        remove(name);
                                        removeItemOrg(name);
                                        handleRemoveOrganization(index);
                                      }
                                    }}
                                    okText="Yes"
                                    cancelText="No"
                                  >
                                    <FontAwesomeIcon
                                      icon={faTrashAlt}
                                      className="cursor fa-lg"
                                    />
                                  </Popconfirm>
                                ) : null
                              }
                              key="0"
                            >
                              {orgArrayOption[name] &&
                                orgArrayOption[name].orgStatus ===
                                  "Pending" && (
                                  <Alert
                                    type="warning"
                                    message="This organization is currently Pending or Deleted, and none editable"
                                    className="m-b-md"
                                  />
                                )}
                              {orgArrayOption[name] &&
                                orgArrayOption[name].changedState === false && (
                                  <Alert
                                    type="warning"
                                    message="This organization is from your previous state"
                                    className="m-b-md"
                                  />
                                )}
                              <Form.Item
                                {...restField}
                                name={[name, "id"]}
                                className="m-b-md hide"
                              >
                                <FloatInput label="ss" placeholder="ss" />
                              </Form.Item>

                              <Row gutter={12}>
                                <Col xs={24} sm={24} md={24}>
                                  <Form.Item
                                    {...restField}
                                    name={[name, "organization_type"]}
                                    className="form-select-error"
                                    rules={[
                                      {
                                        required: true,
                                        message:
                                          "This field field is required.",
                                      },
                                    ]}
                                  >
                                    <FloatSelect
                                      label="Organization Type"
                                      placeholder="Organization Type"
                                      options={optionOrganizationType}
                                      onChange={(val, opt) =>
                                        handletOrgType(val, opt, name)
                                      }
                                      disabled={
                                        (orgArrayOption[name] &&
                                          orgArrayOption[name].orgStatus ===
                                            "Pending") ||
                                        (orgArrayOption[name] &&
                                          orgArrayOption[name].changedState ===
                                            false)
                                          ? true
                                          : false
                                      }
                                      required
                                    />
                                  </Form.Item>
                                </Col>

                                {enableAddOrganization &&
                                enableAddOrganization[name].status === 0 ? (
                                  <>
                                    <Col xs={24} sm={24} md={24}>
                                      {orgArrayOption[name] &&
                                      orgArrayOption[name].orgStatus ===
                                        "Pending" ? (
                                        <Form.Item
                                          {...restField}
                                          name={[
                                            name,
                                            "organization_name_edit",
                                          ]}
                                          rules={[
                                            {
                                              required: true,
                                              message:
                                                "This field field is required.",
                                            },
                                          ]}
                                          className={`input-org-name-${index}`}
                                        >
                                          <FloatInput
                                            label="Organization Name"
                                            placeholder="Organization Name"
                                            disabled={
                                              (orgArrayOption[name] &&
                                                orgArrayOption[name]
                                                  .orgStatus === "Pending") ||
                                              orgArrayOption[name]
                                                .changedState === false
                                                ? true
                                                : false
                                            }
                                            onChange={(e) =>
                                              $(
                                                `.input-org-name-${index} .ant-form-item-feedback-icon-success`
                                              ).show()
                                            }
                                            required
                                          />
                                        </Form.Item>
                                      ) : (
                                        <>
                                          <Form.Item
                                            {...restField}
                                            name={[name, "organization_id"]}
                                            className={`form-select-error ${
                                              orgArrayOption[name]
                                                .changedState === false
                                                ? "hide"
                                                : ""
                                            } select-org-id-${index}`}
                                            rules={[
                                              {
                                                required: true,
                                                message:
                                                  "This field field is required.",
                                              },
                                            ]}
                                          >
                                            <FloatSelect
                                              label="Organization Name"
                                              placeholder="Organization Name"
                                              onChange={(val, opt) => {
                                                handleOrgName(val, opt, name);

                                                $(
                                                  `.select-org-id-${index} .ant-form-item-feedback-icon-success`
                                                ).show();
                                              }}
                                              options={
                                                orgArrayOption[name]
                                                  .organization_option.length >
                                                0
                                                  ? orgArrayOption[name]
                                                      .organization_option
                                                  : []
                                              }
                                              onBlurInput={(e) =>
                                                handleBlurSave(
                                                  e,
                                                  name,
                                                  "organization_id"
                                                )
                                              }
                                              disabled={
                                                orgArrayOption[name]
                                                  .orgStatus === "Pending" ||
                                                orgArrayOption[name]
                                                  .changedState === false
                                                  ? true
                                                  : false
                                              }
                                              required
                                            />
                                          </Form.Item>

                                          <Form.Item
                                            {...restField}
                                            name={[
                                              name,
                                              "organization_id_view_name",
                                            ]}
                                            className={`${
                                              orgArrayOption[name]
                                                .changedState === false
                                                ? ""
                                                : "hide"
                                            }`}
                                          >
                                            <FloatInput
                                              label="Organization Name"
                                              placeholder="Organization Name"
                                              disabled={
                                                orgArrayOption[name]
                                                  .orgStatus === "Pending" ||
                                                orgArrayOption[name]
                                                  .changedState === false
                                                  ? true
                                                  : false
                                              }
                                              required
                                            />
                                          </Form.Item>
                                        </>
                                      )}
                                    </Col>{" "}
                                    {orgArrayOption[name] &&
                                    orgArrayOption[name].orgStatus ===
                                      "Pending" ? (
                                      ""
                                    ) : (
                                      <>
                                        {orgArrayOption[name] &&
                                          orgArrayOption[name].orgStatus !==
                                            "Approved" && (
                                            <Col
                                              xs={24}
                                              sm={24}
                                              md={24}
                                              className="m-b-md"
                                            >
                                              <Typography.Text>
                                                Create new organization,{" "}
                                                <Typography.Link
                                                  className="companyColor3"
                                                  onClick={() => {
                                                    let tempEnableAddOrganization =
                                                      [
                                                        ...enableAddOrganization,
                                                      ];
                                                    tempEnableAddOrganization[
                                                      index
                                                    ] = {
                                                      ...tempEnableAddOrganization[
                                                        index
                                                      ],
                                                      status: 1,
                                                    };
                                                    setEnableAddOrganization(
                                                      tempEnableAddOrganization
                                                    );

                                                    // console.log(
                                                    // 	"orgArrayOptionorgArrayOption",
                                                    // 	orgArrayOption[name]
                                                    // 		.state_options &&
                                                    // 		orgArrayOption[name]
                                                    // 			.state_options
                                                    // );

                                                    let dataFields =
                                                      formInformation.getFieldsValue();

                                                    let tempOrganization =
                                                      formInformation.getFieldsValue()
                                                        .organizations;
                                                    tempOrganization[index] = {
                                                      ...tempOrganization[
                                                        index
                                                      ],
                                                      organization_id: "",
                                                      organization_address1: "",
                                                      organization_address2: "",
                                                      organization_city:
                                                        dataFields.city,
                                                      organization_country:
                                                        dataFields.country,
                                                      organization_name: "",
                                                      organization_state:
                                                        dataFields.state,
                                                      organization_zip: "",
                                                    };
                                                    formInformation.setFieldsValue(
                                                      {
                                                        organizations:
                                                          tempOrganization,
                                                      }
                                                    );
                                                  }}
                                                >
                                                  click here.
                                                </Typography.Link>
                                              </Typography.Text>
                                            </Col>
                                          )}
                                      </>
                                    )}
                                  </>
                                ) : (
                                  <>
                                    <Col xs={24} sm={24} md={24}>
                                      <Form.Item
                                        {...restField}
                                        name={[name, "organization_name"]}
                                        rules={[
                                          {
                                            required: true,
                                            message:
                                              "This field organization name is required.",
                                          },
                                        ]}
                                      >
                                        <FloatInput
                                          label="Organization Name"
                                          placeholder="Organization Name"
                                          onBlurInput={(e) =>
                                            handleBlurSave(
                                              e,
                                              name,
                                              "organization_name"
                                            )
                                          }
                                          required
                                        />
                                      </Form.Item>
                                    </Col>

                                    <Col
                                      xs={24}
                                      sm={24}
                                      md={24}
                                      className="m-b-md"
                                    >
                                      <Typography.Text>
                                        Cancel,{" "}
                                        <Typography.Link
                                          className="companyColor3"
                                          onClick={() => {
                                            let tempEnableAddOrganization = [
                                              ...enableAddOrganization,
                                            ];
                                            tempEnableAddOrganization[index] = {
                                              ...tempEnableAddOrganization[
                                                index
                                              ],
                                              status: 0,
                                            };
                                            setEnableAddOrganization(
                                              tempEnableAddOrganization
                                            );

                                            let tempOrganization =
                                              formInformation.getFieldsValue()
                                                .organizations;

                                            tempOrganization[index] = {
                                              ...tempOrganization[index],
                                              organization_address1: "",
                                              organization_address2: "",
                                              organization_city: "",
                                              organization_country: "",
                                              organization_name: "",
                                              organization_state: "",
                                              organization_zip: "",
                                            };
                                            formInformation.setFieldsValue({
                                              organizations: tempOrganization,
                                            });
                                          }}
                                        >
                                          click here.
                                        </Typography.Link>
                                      </Typography.Text>
                                    </Col>

                                    <Col xs={24} sm={24} md={24}>
                                      <Form.Item
                                        {...restField}
                                        name={[name, "organization_country"]}
                                        className="form-select-error"
                                        rules={[
                                          {
                                            required: true,
                                            message:
                                              "This field field is required.",
                                          },
                                        ]}
                                      >
                                        <FloatSelect
                                          label="Country"
                                          placeholder="Country"
                                          options={optionCountryCodes}
                                          onChange={(val, opt) =>
                                            handleOrgCountry(val, opt, name)
                                          }
                                          onBlurInput={(e) =>
                                            handleBlurSave(
                                              e,
                                              name,
                                              "organization_country"
                                            )
                                          }
                                          disabled={true}
                                          required
                                        />
                                      </Form.Item>
                                    </Col>

                                    <Col xs={24} sm={24} md={24}>
                                      <Form.Item
                                        {...restField}
                                        name={[name, "organization_state"]}
                                        className="form-select-error"
                                        rules={[
                                          {
                                            required: true,
                                            message:
                                              "This field field is required.",
                                          },
                                        ]}
                                      >
                                        <FloatSelect
                                          label={
                                            orgArrayOption &&
                                            orgArrayOption[name]?.state_label
                                          }
                                          placeholder={
                                            orgArrayOption &&
                                            orgArrayOption[name]?.state_label
                                          }
                                          options={
                                            orgArrayOption[name] &&
                                            orgArrayOption[name].state_options
                                              ? orgArrayOption[name]
                                                  .state_options
                                              : []
                                          }
                                          dropdownClassName="select-dropdown-in-modal"
                                          // onChange={handleOrganizationState}
                                          onBlurInput={(e) =>
                                            handleBlurSave(
                                              e,
                                              name,
                                              "organization_state"
                                            )
                                          }
                                          disabled={true}
                                          required
                                        />
                                      </Form.Item>
                                    </Col>

                                    <Col xs={24} sm={24} md={24}>
                                      <Form.Item
                                        {...restField}
                                        name={[name, "organization_address1"]}
                                        rules={[
                                          {
                                            required: true,
                                            message:
                                              "This field city is required.",
                                          },
                                        ]}
                                      >
                                        <FloatInput
                                          label="Address 1"
                                          placeholder="Address 1"
                                          onBlurInput={(e) =>
                                            handleBlurSave(
                                              e,
                                              name,
                                              "organization_address1"
                                            )
                                          }
                                          required
                                        />
                                      </Form.Item>
                                    </Col>

                                    <Col xs={24} sm={24} md={24}>
                                      <Form.Item
                                        {...restField}
                                        name={[name, "organization_address2"]}
                                      >
                                        <FloatInput
                                          label="Address 2"
                                          placeholder="Address 2"
                                          onBlurInput={(e) =>
                                            handleBlurSave(
                                              e,
                                              name,
                                              "organization_address2"
                                            )
                                          }
                                        />
                                      </Form.Item>
                                    </Col>

                                    <Col xs={24} sm={24} md={24}>
                                      <Form.Item
                                        {...restField}
                                        name={[name, "organization_city"]}
                                        className="form-select-error"
                                        rules={[
                                          {
                                            required: true,
                                            message:
                                              "This field city is required.",
                                          },
                                        ]}
                                      >
                                        <FloatSelect
                                          label="City"
                                          placeholder="City"
                                          onBlurInput={(e) =>
                                            handleBlurSave(
                                              e,
                                              name,
                                              "organization_city"
                                            )
                                          }
                                          options={cityOtionsInfo}
                                          required
                                        />
                                      </Form.Item>
                                    </Col>

                                    <Col xs={24} sm={24} md={24}>
                                      <Form.Item
                                        {...restField}
                                        name={[name, "organization_zip"]}
                                        rules={[
                                          {
                                            required: true,
                                            message:
                                              "This field zip is required.",
                                          },
                                          {
                                            pattern:
                                              orgArrayOption[index]
                                                .code_pattern,
                                            message:
                                              "Invalid " +
                                              orgArrayOption[index].code_label,
                                          },
                                        ]}
                                      >
                                        <FloatInput
                                          label={
                                            orgArrayOption[index].code_label
                                          }
                                          placeholder={
                                            orgArrayOption[index].code_label
                                          }
                                          onBlurInput={(e) =>
                                            handleBlurSave(
                                              e,
                                              name,
                                              "organization_zip"
                                            )
                                          }
                                          required
                                        />
                                      </Form.Item>
                                    </Col>
                                  </>
                                )}

                                <Col xs={24} sm={24} md={24}>
                                  {(orgArrayOption[name] &&
                                    orgArrayOption[name].orgStatus ===
                                      "Pending") ||
                                  (orgArrayOption[name] &&
                                    orgArrayOption[name].changedState ===
                                      false) ||
                                  (orgArrayOption[name] &&
                                    orgArrayOption[name].changedState ===
                                      false) ? (
                                    <Form.Item
                                      {...restField}
                                      name={[name, "coach_id_view_name"]}
                                    >
                                      <FloatInput
                                        label="Coach's Name (Optional)"
                                        placeholder="Coach's Name (Optional)"
                                        disabled
                                      />
                                    </Form.Item>
                                  ) : (
                                    <Form.Item
                                      {...restField}
                                      name={[name, "coach_id"]}
                                      className="form-select-error"
                                    >
                                      <FloatSelectCoach
                                        label="Coach's Name (Optional)"
                                        placeholder="Coach's Name (Optional)"
                                        options={
                                          orgArrayOption[name] &&
                                          orgArrayOption[name].coach_option
                                            .length > 0
                                            ? orgArrayOption[name]
                                                .coach_option[0]["json"]
                                            : []
                                        }
                                        onBlurInput={(e) =>
                                          handleBlurSave(e, name, "coach_id")
                                        }
                                        disabled={
                                          (orgArrayOption[name] &&
                                            orgArrayOption[name].orgStatus ===
                                              "Pending") ||
                                          (orgArrayOption[name] &&
                                            orgArrayOption[name]
                                              .changedState === false) ||
                                          (orgArrayOption[name] &&
                                            orgArrayOption[name]
                                              .changedState === false)
                                            ? true
                                            : false
                                        }
                                      />
                                    </Form.Item>
                                  )}
                                </Col>

                                <Col xs={24} sm={24} md={24}>
                                  <Form.Item
                                    {...restField}
                                    name={[name, "sport"]}
                                    className="form-select-error"
                                    rules={[
                                      {
                                        required: true,
                                        message:
                                          "This field field is required.",
                                      },
                                    ]}
                                  >
                                    <FloatSelect
                                      label="Sport"
                                      placeholder="Sport"
                                      options={sportList}
                                      onBlurInput={(e) =>
                                        handleBlurSave(e, name, "sport")
                                      }
                                      disabled={
                                        (orgArrayOption[name] &&
                                          orgArrayOption[name].orgStatus ===
                                            "Pending") ||
                                        (orgArrayOption[name] &&
                                          orgArrayOption[name].changedState ===
                                            false)
                                          ? true
                                          : false
                                      }
                                      required
                                    />
                                  </Form.Item>
                                </Col>

                                <Col
                                  xs={24}
                                  sm={24}
                                  md={24}
                                  className="athletic-form-list-positions"
                                >
                                  <Form.List
                                    {...restField}
                                    name={[name, "positions"]}
                                    rules={[
                                      {
                                        validator: async (_, names2) => {
                                          if (!names2 || names2.length < 1) {
                                            return Promise.reject(
                                              new Error("At least 1 position")
                                            );
                                          }
                                        },
                                      },
                                    ]}
                                  >
                                    {(
                                      fields2,
                                      { add, remove },
                                      { errors2 }
                                    ) => (
                                      <>
                                        {fields2.map((field2, index2) => (
                                          <Form.Item
                                            required={false}
                                            key={field2.key}
                                            className="p-b-md"
                                          >
                                            <Form.Item
                                              {...field2}
                                              validateTrigger={[
                                                "onChange",
                                                "onBlur",
                                              ]}
                                              rules={[
                                                {
                                                  required: true,
                                                  whitespace: true,
                                                  message:
                                                    "Please input position or delete this field.",
                                                },
                                              ]}
                                              noStyle
                                            >
                                              <FloatInput
                                                label="Position"
                                                placeholder="Position"
                                                className={`${
                                                  index2 !== 0
                                                    ? "w-95"
                                                    : "w-100"
                                                }`}
                                                onBlurInput={(e) =>
                                                  handleBlurSaveArr(
                                                    e,
                                                    name,
                                                    "positions",
                                                    index2
                                                  )
                                                }
                                                disabled={
                                                  orgArrayOption[name]
                                                    .orgStatus === "Pending" ||
                                                  orgArrayOption[name]
                                                    .changedState === false
                                                    ? true
                                                    : false
                                                }
                                                required
                                              />
                                            </Form.Item>
                                            {fields2.length > 1 ? (
                                              <MinusCircleOutlined
                                                className={
                                                  orgArrayOption[name]
                                                    .orgStatus === "Pending" ||
                                                  orgArrayOption[name]
                                                    .changedState === false
                                                    ? "dynamic-delete-button hide"
                                                    : `dynamic-delete-button`
                                                }
                                                onClick={() =>
                                                  remove(field2.name)
                                                }
                                              />
                                            ) : null}
                                          </Form.Item>
                                        ))}

                                        {(orgArrayOption[name] &&
                                          orgArrayOption[name].orgStatus ===
                                            "Pending") ||
                                        (orgArrayOption[name] &&
                                          orgArrayOption[name].changedState ===
                                            false) ? (
                                          ""
                                        ) : (
                                          <Form.Item>
                                            <Form.ErrorList errors={errors2} />
                                            <Button
                                              type="link"
                                              onClick={() => add()}
                                              icon={<PlusOutlined />}
                                              className={`companyColor3 p-n `}
                                            >
                                              Additional Position
                                            </Button>
                                          </Form.Item>
                                        )}
                                      </>
                                    )}
                                  </Form.List>
                                </Col>

                                <Col
                                  xs={24}
                                  sm={24}
                                  md={24}
                                  className={`${
                                    orgArrayOption[name] &&
                                    orgArrayOption[name].changedState === false
                                      ? "m-t-sm"
                                      : "m-t-md"
                                  }`}
                                >
                                  <Form.Item
                                    {...restField}
                                    name={[name, "stats"]}
                                    className="form-select-error"
                                    hasFeedback
                                    rules={[
                                      {
                                        required: true,
                                        message:
                                          "This field field is required.",
                                      },
                                    ]}
                                  >
                                    <FloatTextArea
                                      label="Your Stats"
                                      placeholder="Your Stats"
                                      onBlurInput={(e) =>
                                        handleBlurSave(
                                          e.target.value,
                                          name,
                                          "stats"
                                        )
                                      }
                                      disabled={
                                        (orgArrayOption[name] &&
                                          orgArrayOption[name].orgStatus ===
                                            "Pending") ||
                                        (orgArrayOption[name] &&
                                          orgArrayOption[name].changedState ===
                                            false)
                                          ? true
                                          : false
                                      }
                                      required
                                    />
                                  </Form.Item>
                                </Col>
                              </Row>
                            </Collapse.Panel>
                          </Collapse>
                        ))}

                        <Form.Item>
                          <Button
                            type="link"
                            onClick={() => {
                              let tempOptionOrganization = [
                                ...optionOrganization,
                              ];
                              tempOptionOrganization.push([]);
                              setOptionOrganization(tempOptionOrganization);

                              let tempOptionOrganizationState = [
                                ...optionOrganizationState,
                              ];
                              tempOptionOrganizationState.push(stateUS);
                              setOptionOrganizationState(
                                tempOptionOrganizationState
                              );
                              let tempOptionOrganizationZip = [
                                ...optionOrganizationZip,
                              ];
                              tempOptionOrganizationZip.push(
                                /(^\d{5}$)|(^\d{5}[ -]\d{4}$)/
                              );

                              setOptionOrganizationZip(
                                tempOptionOrganizationZip
                              );

                              let tempOrganization =
                                formInformation.getFieldsValue().organizations;
                              tempOrganization.push({
                                positions: [""],
                              });

                              add();

                              formInformation.setFieldsValue({
                                organizations: tempOrganization,
                              });
                              // formInformation.setFieldsValue(
                              //   ...tempOrganization,
                              //   {
                              //     positions: [""],
                              //   }
                              // );

                              let arrOrg = [
                                ...orgArrayOption,
                                {
                                  organization_type: "",
                                  organization_option: [],
                                  coach_option: [],
                                  state_label:
                                    selectedData?.user_address &&
                                    selectedData?.user_address.length > 0 &&
                                    selectedData?.user_address[0]?.country ===
                                      "United States"
                                      ? "State"
                                      : "County",
                                  state_options:
                                    selectedData?.user_address &&
                                    selectedData?.user_address.length > 0 &&
                                    selectedData?.user_address[0]?.country ===
                                      "United States"
                                      ? stateUS
                                      : stateCA,
                                  code_pattern:
                                    selectedData?.user_address &&
                                    selectedData?.user_address.length > 0 &&
                                    selectedData?.user_address[0]?.country ===
                                      "United States"
                                      ? /(^\d{5}$)|(^\d{5}[ -]\d{4}$)/
                                      : /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/,
                                  code_label:
                                    selectedData?.user_address &&
                                    selectedData?.user_address.length > 0 &&
                                    selectedData?.user_address[0]?.country ===
                                      "United States"
                                      ? "Zip Code"
                                      : "Postal Code",
                                  orgStatus: "Create",
                                },
                              ];

                              setOrgArrayOption(arrOrg);
                              let arrOrgenableAddOrganization = [
                                ...enableAddOrganization,
                                {
                                  status: 0,
                                  orgStatus: "Pending",
                                },
                              ];

                              setEnableAddOrganization(
                                arrOrgenableAddOrganization
                              );

                              // console.log("arrOrgenableAddOrganization", [
                              //   ...tempOrganization,
                              //   {
                              //     positions: [""],
                              //   },
                              // ]);

                              // getData, setGetData

                              let arrOr2 = [
                                ...getDataOrg,
                                {
                                  coach_id: "",
                                  organization_id: "",
                                  organization_type: "",
                                  positions: [""],
                                  sport: "",
                                  stats: "",
                                },
                              ];

                              setGetDataOrg(arrOr2);

                              console.log("getData_add", getDataOrg);
                            }}
                            icon={<PlusOutlined />}
                            className="companyColor3 p-n"
                          >
                            Add Another Organization
                          </Button>
                        </Form.Item>
                      </>
                    )}
                  </Form.List>
                </Col>
              </Row>
            </Form>
          </Col>

          <Col xs={24} sm={24} md={24} lg={10}>
            <Collapse
              className="main-1-collapse border-none"
              expandIcon={({ isActive }) =>
                isActive ? (
                  <span
                    className="ant-menu-submenu-arrow"
                    style={{ color: "#FFF", transform: "rotate(270deg)" }}
                  ></span>
                ) : (
                  <span
                    className="ant-menu-submenu-arrow"
                    style={{ color: "#FFF", transform: "rotate(90deg)" }}
                  ></span>
                )
              }
              defaultActiveKey={["1"]}
              expandIconPosition="start"
            >
              <Collapse.Panel
                header="MY WEBSITE PHOTO "
                key="1"
                className="accordion bg-darkgray-form m-b-md border"
              >
                <Row gutter={12} justify="center">
                  <Col xs={24} sm={24} md={24}>
                    <label className="font-red">
                      <b>
                        Photo upload & cropping: select your image orientation
                      </b>
                    </label>
                    <br />
                    <Radio.Group onChange={handleResize} value={radioData}>
                      <Radio value={1}>Square</Radio>
                      <Radio value={2}>Rectangle</Radio>
                      <Radio value={3}>Portrait</Radio>
                    </Radio.Group>
                  </Col>
                  <Divider />
                  <Col xs={24} sm={24} md={24}>
                    <div style={{ display: "flex" }}>
                      <ImgCrop
                        rotate
                        aspect={imageCrop.width / imageCrop.height}
                      >
                        <Upload
                          // action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                          listType="picture-card"
                          style={{ width: "200px" }}
                          maxCount={1}
                          action={false}
                          customRequest={false}
                          fileList={fileList}
                          onChange={onChangeUpload}
                          // onPreview={onPreviewUpload}
                          showUploadList={{
                            showPreviewIcon: false,
                          }}
                          beforeUpload={beforeUpload}
                          className="profilePhoto"
                        >
                          {fileList.length < 1 && "+ Upload"}
                        </Upload>
                      </ImgCrop>
                    </div>
                  </Col>
                </Row>
              </Collapse.Panel>
            </Collapse>

            <Row>
              <Col xs={24} sm={24} md={24}>
                <Form
                  form={formWebsite}
                  onFinish={onFinishWebsite}
                  initialValues={{
                    athlete_website: [{ positions: [""] }],
                  }}
                >
                  <Form.List name="athlete_website">
                    {(fields, { add, remove }) => (
                      <>
                        {fields.map(({ key, name, ...restField }, index) => (
                          <Collapse
                            className="main-1-collapse border-none"
                            expandIcon={({ isActive }) =>
                              isActive ? (
                                <span
                                  className="ant-menu-submenu-arrow"
                                  style={{
                                    color: "#FFF",
                                    transform: "rotate(270deg)",
                                  }}
                                ></span>
                              ) : (
                                <span
                                  className="ant-menu-submenu-arrow"
                                  style={{
                                    color: "#FFF",
                                    transform: "rotate(90deg)",
                                  }}
                                ></span>
                              )
                            }
                            defaultActiveKey={["0"]}
                            expandIconPosition="start"
                            key={`${index}`}
                          >
                            <Collapse.Panel
                              header="MY WEBSITE"
                              key="0"
                              className="accordion bg-darkgray-form m-b-md border collapse-panel-my-website"
                              extra={
                                fields.length > 1 ? (
                                  <Popconfirm
                                    placement="top"
                                    title="Are you sure to delete this website?"
                                    onConfirm={() => {
                                      if (!isLoadingDeleteAthleteWebsite) {
                                        remove(name);

                                        handleRemoveWebsite(index);

                                        // getDataWebDefault
                                        // setGetDataWebDefault

                                        getDataWebDefault.splice(index, 1);
                                        setGetDataWebDefault(getDataWebDefault);
                                      }
                                    }}
                                    okText="Yes"
                                    cancelText="No"
                                  >
                                    <FontAwesomeIcon
                                      icon={faTrashAlt}
                                      className="cursor fa-lg"
                                    />
                                  </Popconfirm>
                                ) : null
                              }
                            >
                              <Form.Item
                                {...restField}
                                name={[name, "id"]}
                                className="m-b-md form-select-error hide"
                              >
                                <FloatInput label="ss" placeholder="ss" />
                              </Form.Item>

                              <Row gutter={12}>
                                <Col xs={24} sm={24} md={24}>
                                  <Form.Item
                                    {...restField}
                                    name={[name, "organization_id"]}
                                    className="m-b-md form-select-error "
                                    rules={[
                                      {
                                        required: true,
                                        message:
                                          "This field field is required.",
                                      },
                                    ]}
                                  >
                                    <FloatSelect
                                      label="Organization Name"
                                      placeholder="Organization Name"
                                      options={optionAthleteOrganization}
                                      onChange={handleWebPageSelect}
                                      required
                                      // onBlurInput={(e) =>
                                      //   handleWebBlurSave(
                                      //     e,
                                      //     name,
                                      //     "organization_id"
                                      //   )
                                      // }
                                    />
                                  </Form.Item>
                                </Col>

                                <Col xs={24} sm={24} md={24}>
                                  <Form.Item
                                    {...restField}
                                    name={[name, "sport"]}
                                    className="m-b-md form-select-error"
                                    rules={[
                                      {
                                        required: true,
                                        message:
                                          "This field field is required.",
                                      },
                                    ]}
                                  >
                                    <FloatSelect
                                      label="Sport"
                                      placeholder="Sport"
                                      options={sportList}
                                      // onBlurInput={(e) =>
                                      //   handleWebBlurSave(e, name, "sport")
                                      // }
                                      onChange={handleWebPageSelect}
                                      required
                                    />
                                  </Form.Item>
                                </Col>

                                <Col
                                  xs={24}
                                  sm={24}
                                  md={24}
                                  className="athletic-form-list-positions"
                                >
                                  <Form.List
                                    {...restField}
                                    name={[name, "positions"]}
                                    rules={[
                                      {
                                        validator: async (_, names2) => {
                                          if (!names2 || names2.length < 1) {
                                            return Promise.reject(
                                              new Error("At least 1 position")
                                            );
                                          }
                                        },
                                      },
                                    ]}
                                  >
                                    {(
                                      fields2,
                                      { add, remove },
                                      { errors2 }
                                    ) => (
                                      <>
                                        {fields2.map((field2, index2) => (
                                          <Form.Item
                                            required={false}
                                            key={field2.key}
                                            className={
                                              index2 !== 0 ? "p-t-md" : ""
                                            }
                                          >
                                            <Form.Item
                                              {...field2}
                                              validateTrigger={[
                                                "onChange",
                                                "onBlur",
                                              ]}
                                              rules={[
                                                {
                                                  required: true,
                                                  whitespace: true,
                                                  message:
                                                    "Please input position or delete this field.",
                                                },
                                              ]}
                                              noStyle
                                            >
                                              <FloatInput
                                                label="Position"
                                                placeholder="Position"
                                                className={`${
                                                  index2 !== 0
                                                    ? "w-95"
                                                    : "w-100"
                                                }`}
                                                // onBlurInput={(e) =>
                                                //   handleWebBlurSaveArr(
                                                //     e,
                                                //     name,
                                                //     "positions",
                                                //     index2
                                                //   )
                                                // }
                                                // onChange={handleWebPageInput}
                                              />
                                            </Form.Item>
                                            {fields2.length > 1 ? (
                                              <MinusCircleOutlined
                                                className="dynamic-delete-button"
                                                onClick={() =>
                                                  remove(field2.name)
                                                }
                                              />
                                            ) : null}
                                          </Form.Item>
                                        ))}
                                        <Form.Item>
                                          <Form.ErrorList errors={errors2} />
                                          <Button
                                            type="link"
                                            onClick={() => add()}
                                            icon={<PlusOutlined />}
                                            className="companyColor3 p-n"
                                          >
                                            Additional Position
                                          </Button>
                                        </Form.Item>
                                      </>
                                    )}
                                  </Form.List>
                                </Col>

                                <Col xs={24} sm={24} md={24} className="m-b-sm">
                                  <Space className="w-100 ant-space-pick-a-name">
                                    <Typography.Text
                                      style={{ fontSize: "18px" }}
                                    >
                                      bfssathlete.com/athlete/
                                    </Typography.Text>
                                    <div>
                                      <Form.Item
                                        {...restField}
                                        name={[name, "name"]}
                                        className="m-b-md form-select-error"
                                        rules={[
                                          {
                                            required: true,
                                            message:
                                              "This field field is required.",
                                          },
                                        ]}
                                        noStyle
                                      >
                                        <FloatInput
                                          label="Pick a Name"
                                          placeholder="Pick a Name"
                                          //   onBlurInput={handleBlurSavePickAName}
                                          onBlurInput={(e) =>
                                            handleBlurSavePickAName(
                                              e,
                                              name,
                                              "name"
                                            )
                                          }
                                          onChange={handleOnchageWebname}
                                          required
                                          // onChange={handleWebPageInput}
                                        />
                                      </Form.Item>
                                    </div>
                                  </Space>
                                </Col>
                                <Col xs={24} sm={24} md={24} className="m-b-md">
                                  <Typography.Text>
                                    Create your unique website profile. eg,{" "}
                                    bfsathlete.com/athlete/<b>Jodibloggs</b>
                                  </Typography.Text>
                                </Col>
                                <Col xs={24} sm={24} md={24} className="m-b-md">
                                  {selectedData?.athlete_website[index]?.id && (
                                    <Link
                                      className="btn-primary-default active p-xs"
                                      size="large"
                                      to={`/athletic/${selectedData.athlete_website[index]?.name}`}
                                      target="_blank"
                                    >
                                      View my Webpage
                                    </Link>
                                  )}
                                </Col>
                                <Col xs={24} sm={24} md={24} className="m-b-xs">
                                  <Form.Item
                                    {...restField}
                                    name={[name, "status"]}
                                    className="m-b-md form-select-error "
                                    rules={[
                                      {
                                        required: true,
                                        message:
                                          "This field field is required.",
                                      },
                                    ]}
                                  >
                                    <FloatSelect
                                      label="Website Visibility"
                                      placeholder="Website Visibility"
                                      options={[
                                        { label: "Visible", value: "Visible" },
                                        {
                                          label: "Not Visible",
                                          value: "Not Visible",
                                        },
                                      ]}
                                      // onChange={handleChangeWebsiteVisibility}
                                      //   onBlurInput={handleBlurSave}
                                      // onBlurInput={(e) =>
                                      //   handleWebBlurSave(e, name, "status")
                                      // }
                                      onChange={handleWebPageSelect}
                                      required
                                    />
                                  </Form.Item>
                                </Col>

                                <Col xs={24} sm={24} md={24} className="m-b-xs">
                                  <Row gutter={12}>
                                    <Col
                                      xs={24}
                                      sm={24}
                                      md={24}
                                      lg={24}
                                      className="m-t-sm"
                                    >
                                      <div className="form-athletic-website-default-website-and-save">
                                        <div>
                                          <>
                                            <Typography.Text>
                                              Default Website
                                            </Typography.Text>
                                            <Switch
                                              className="companyBGColor3 m-l-sm"
                                              // defaultChecked={
                                              //   selectedData &&
                                              //   selectedData.athlete_website
                                              //     ? selectedData.athlete_website.filter(
                                              //         (itemFiltered) =>
                                              //           itemFiltered.is_default ===
                                              //           1
                                              //       ).length > 0
                                              //       ? selectedData.athlete_website[
                                              //           index
                                              //         ]?.is_default
                                              //         ? true
                                              //         : false
                                              //       : true
                                              //     : true
                                              // }
                                              checked={
                                                getDataWebDefault[name] &&
                                                getDataWebDefault[name]
                                                  .is_default === 1
                                                  ? true
                                                  : false
                                              }
                                              onChange={(e) => {
                                                // onChangeDefaultWebsite(
                                                //   e,
                                                //   selectedData?.athlete_website[
                                                //     index
                                                //   ]?.id
                                                // );

                                                let dataFrom =
                                                  getDataWebDefault.map(
                                                    (item) => {
                                                      return {
                                                        is_default: 0,
                                                      };
                                                    }
                                                  );

                                                let row = [...dataFrom];
                                                row[name] = {
                                                  ...row[name],
                                                  is_default: e ? 1 : 0,
                                                };
                                                console.log(
                                                  "onFinishWebsite",
                                                  dataFrom
                                                );
                                                console.log(
                                                  "onFinishWebsite",
                                                  row
                                                );
                                                setGetDataWebDefault(row);
                                              }}
                                            />
                                          </>
                                        </div>
                                        <div>
                                          <Button
                                            className="btn-primary-default invert"
                                            icon={
                                              <FontAwesomeIcon
                                                icon={faSave}
                                                className="m-r-xs"
                                              />
                                            }
                                            htmlType="submit"
                                            loading={
                                              isLoadingUserAthleteWebsite
                                            }
                                          >
                                            Save
                                          </Button>
                                        </div>
                                      </div>
                                    </Col>
                                  </Row>
                                </Col>
                              </Row>
                            </Collapse.Panel>
                          </Collapse>
                        ))}

                        <Form.Item>
                          <Button
                            type="link"
                            onClick={() => {
                              let tempAthleteWebsite =
                                formWebsite.getFieldsValue().athlete_website;
                              // console.log("tempAthleteWebsite", tempAthleteWebsite);
                              tempAthleteWebsite.push({ positions: [""] });

                              let row = [
                                ...getDataWebDefault,
                                { is_default: 0 },
                              ];
                              console.log("getDataWebDefault", row);
                              setGetDataWebDefault(row);

                              // row

                              add();

                              formWebsite.setFieldsValue({
                                athlete_website: tempAthleteWebsite,
                              });
                            }}
                            icon={<PlusOutlined />}
                            className="companyColor3 p-n"
                          >
                            Add Another Website
                          </Button>
                        </Form.Item>
                      </>
                    )}
                  </Form.List>
                </Form>
              </Col>
            </Row>
          </Col>
        </Row>
      </Card>
    </>
  );
}
