import React, { useState, useEffect } from "react";
import {
  Card,
  Button,
  Row,
  Col,
  Badge,
  Typography,
  Alert,
  Space,
  Modal,
  Form,
  Tag,
  Input,
} from "antd";
import { CalendarOutlined, ReloadOutlined } from "@ant-design/icons";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import moment from "moment";
import $ from "jquery";

import { GET } from "../../../providers/useAxiosQuery";
import optionAssessmentCategory from "../../../providers/optionAssessmentCategory";
import optionCountryCodes from "../../../providers/optionCountryCodes";
import optionStateCodesUnitedState from "../../../providers/optionStateCodesUnitedState";
import optionStateCodesCanada from "../../../providers/optionStateCodesCanada";

import companyInfo from "../../../providers/companyInfo";
import ModalAssessmentPreviewPublic from "./ModalAssessmentPreviewPublic";
import FloatSelect from "../../../providers/FloatSelect";
// import FloatInput from "../../../providers/FloatInput";
import logoDef from "../../../assets/img/BFSS-youtube.jpg";

export default function ComponentDashboardPublic(props) {
  const {
    dataFilter,
    setDataFilter,
    column,
    column_lg,
    column_xl,
    // legend
  } = props;

  // console.log("ComponentDashboard dataFilter", dataFilter);

  const apiUrl = companyInfo().apiUrl;
  const userdata = companyInfo().userData;

  const [dataEventCalendar, setDataEventCalendar] = useState([]);
  const [showMonthView, setShowMonthView] = useState(false);
  const [assessmentCard, setAssessmentCard] = useState([]);
  // const [searchTags, setSearchTags] = useState([]);

  const { refetch: refetchCard } = GET(
    `api/v1/dasboard_card_public?${$.param(dataFilter)}`,
    "dasboard_card",
    (res) => {
      if (res.success) {
        console.log("dasboard_card", res);
        setAssessmentCard(res.data);
      }
    }
  );

  useEffect(() => {
    console.log("assessmentCard", dataFilter);
    if (dataFilter.event_id) {
      // console.log("dataFilter", dataFilter.event_id);

      let arr = [];
      assessmentCard.map((item) => {
        if (dataFilter.event_id === item.id) {
          arr.push(item);
        }
        return "";
      });

      setToggleModalPreview({
        ...toggleModalPreview,
        show: true,
        data: arr[0],
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataFilter]);

  const { refetch: refetchCalendar } = GET(
    `api/v1/dasboard_calendar_public?${$.param(dataFilter)}`,
    "dasboard_calendar",
    (res) => {
      if (res.success) {
        console.log("dasboard_calendar", res);
        let arr = [];
        res.data.map((item, key) => {
          let colors = "#23bf08";
          if (item.assessment) {
            if (item.assessment.status === "Upcoming / Pending") {
              colors = "#ff8303";
            } else {
              colors = "#0866c6";
            }
          } else {
            colors = "#23bf08";
          }
          // console.log("item", item);
          arr.push({
            id: item.id,
            title: item.event.title,
            description: "asdasd",
            start: item.start,
            end: item.end,
            data: item,
            color: colors,
            extendedProps: item,
            // color: "#ff8303",
            // color: "#0866c6",
          });

          return "";
        });
        setDataEventCalendar(arr);
      }
    }
  );

  const [toggleModalPreview, setToggleModalPreview] = useState({
    show: false,
    data: null,
  });

  const [searchText, setSearchText] = useState("");
  useEffect(() => {
    // console.log("dataFilter", dataFilter);
    if (dataFilter.from) {
      setToggleModalPreview({ ...toggleModalPreview, from: dataFilter.from });
    }
    const timeoutId = setTimeout(() => {
      setDataFilter({
        ...dataFilter,
        search: searchText,
      });
    }, 1500);
    return () => {
      clearTimeout(timeoutId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText]);

  useEffect(() => {
    refetchCard();
    refetchCalendar();
    // console.log("dashboard_Filter", dataFilter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataFilter]);

  const [viewCategory, setViewCategory] = useState(false);
  const [viewTag, setViewTag] = useState(false);

  const [form] = Form.useForm();
  const [isVisible, setIsVisible] = useState(false);
  const handleModalCancel = () => {
    setIsVisible(false);
  };

  const onFinishForm = (values) => {
    setDataFilter({
      ...dataFilter,
      country: values.country ? values.country : "",
      state: values.state ? values.state : "",
      city: values.city ? values.city : "",
    });
    setIsVisible(false);
  };

  const country = optionCountryCodes;
  const stateUS = optionStateCodesUnitedState();
  const stateCA = optionStateCodesCanada();

  const [stateLabel, setStateLabel] = useState("State");
  const [optionState, setOptionState] = useState(stateUS);
  const [optionCityLocation, setOptionCityLocation] = useState([]);

  const handleCountry = (val, opt) => {
    if (val === "United States") {
      setStateLabel("State");
      setOptionState(stateUS);
    } else if (val === "Canada") {
      setStateLabel("County");
      setOptionState(stateCA);
    } else {
      setStateLabel("State");
      setOptionState(stateUS);
    }
  };

  const handleStateLocation = (val, opt) => {
    console.log("handleStateLocation", opt);
    // form.resetFields(["city_location"]);
    // console.log(optionLocationUS);
    setOptionCityLocation(opt["data-json"]);
  };

  const [categoryValue, setCategoryValue] = useState([]);
  const handleCategory = (val, opt) => {
    setCategoryValue(val);
    setDataFilter({
      ...dataFilter,
      category: val,
    });
  };

  const [tagValue, setTagValue] = useState([]);
  const handleTag = (val, opt) => {
    setTagValue(val);
    setDataFilter({
      ...dataFilter,
      tag: val,
    });
  };

  const handleReset = () => {
    setViewCategory(false);
    setViewTag(false);
    setSearchText("");
    setCategoryValue([]);
    setTagValue([]);
    form.resetFields();
    setDataFilter({
      ...dataFilter,
      search: [],
      category: "",
      tag: "",
      country: "",
      state: "",
      city: "",
    });
  };

  const handleClickCalendar = (e) => {
    let row = {
      ...e.event.extendedProps.data.event,
      day_modal: e.event.extendedProps.data.day_modal,
      month_modal: e.event.extendedProps.data.month_modal,
      date_sort: e.event.extendedProps.data.date_sort,
      date_time_end: e.event.extendedProps.data.date_time_end,
      date_time_start: e.event.extendedProps.data.date_time_start,
    };

    console.log("handleClickCalendar", row);

    setToggleModalPreview({ ...toggleModalPreview, show: true, data: row });
  };

  const renderContent = (eventInfo) => {
    console.log(
      "handleTagRemove",
      eventInfo.event.extendedProps.datetime_start
    );
  };

  const handleRenderPrivateImg = (item) => {
    if (item) {
      let assessments = item.assessments.filter(
        (itemFilter) => itemFilter.user_id === userdata.id
      );
      // console.log("handleRenderPrivateImg", assessments, userdata);
      let sport = assessments.length > 0 && assessments[0].athlete_sport;
      if (sport === "Badminton") {
        return apiUrl + "images/sports/badminton.png";
      } else if (sport === "Baseball (Boys)") {
        return apiUrl + "images/sports/basketball.jpeg";
      } else if (sport === "Basketball") {
        return apiUrl + "images/sports/basketball.jpeg";
      } else if (sport === "Cheerleading") {
        return apiUrl + "images/sports/Cheerleaders.jpg";
      } else if (sport === "Field Hockey") {
        return apiUrl + "images/sports/FieldHockey.jpg";
      } else if (sport === "Flag Football (Girls)") {
        return apiUrl + "images/sports/FlagFootball(Girls).png";
      } else if (sport === "Football (Boys)") {
        return apiUrl + "images/sports/Football(Boys).jpg";
      } else if (sport === "Golf") {
        return apiUrl + "images/sports/Golf.png";
      } else if (sport === "Gymnastics") {
        return apiUrl + "images/sports/Gymnastics.jpg";
      } else if (sport === "Hockey") {
        return apiUrl + "images/sports/Hockey.png";
      } else if (sport === "Lacrosse") {
        return apiUrl + "images/sports/Lacrosse.jpg";
      } else if (sport === "Soccer") {
        return apiUrl + "images/sports/Soccer.jpg";
      } else if (sport === "Softball (Girls)") {
        return apiUrl + "images/sports/Softball(Girls).jpg";
      } else if (sport === "Swimming & Diving") {
        return apiUrl + "images/sports/SwimmingandDiving.jpg";
      } else if (sport === "Tennis") {
        return apiUrl + "images/sports/Tennis.jpg";
      } else if (sport === "Tack & Field") {
        return apiUrl + "images/sports/TackAndField.jpg";
      } else if (sport === "Volleyball") {
        return apiUrl + "images/sports/Volleyball.jpg";
      } else if (sport === "Water Polo") {
        return apiUrl + "images/sports/WaterPolo.jpg";
      } else if (sport === "Weightlifting") {
        return apiUrl + "images/sports/Weightlifting.jpg";
      } else if (sport === "Wrestling") {
        return apiUrl + "images/sports/basketWrestling.jpg";
      } else {
        return logoDef;
      }
    } else {
      return logoDef;
    }
  };

  const [sportList, setSportList] = useState([]);
  GET("api/v1/get_all_sport", "get_all_sport", (res) => {
    if (res.success) {
      console.log("get_all_sport", res);
      let row = [];
      res.data &&
        res.data.map((item, key) => {
          row.push({
            value: item.sport,
            label: item.sport,
          });
        });
      setSportList(row);
    }
  });

  return (
    <>
      <Card
        title={
          <div>
            <Row gutter={12}>
              <Col xs={24} sm={24} md={16} className="m-b-sm">
                <Button
                  className="btn-main-outline-2"
                  onClick={() => setShowMonthView(!showMonthView)}
                  icon={<CalendarOutlined />}
                >
                  {!showMonthView ? "Month View" : "Poster Board View"}
                </Button>
              </Col>
              <Col xs={24} sm={24} md={8}>
                {/* <FloatInput
									className="search-dashboard"
									label="Search"
									placeholder="Search"
									onChange={setSearchText}
									value={searchText}
								/> */}
                <Input.Search
                  onChange={(e) => setSearchText(e.target.value)}
                  value={searchText}
                  label="Search"
                  placeholder="Search"
                  className="search_dashboard"
                  onSearch={(e) => setSearchText(e)}
                />
              </Col>
            </Row>
          </div>
        }
        className="card-transparent-head no-side-border"
        // className="card-transparent-head no-side-border p-none"
        // headStyle={{ padding: "5px 5px" }}
        // bodyStyle={{ padding: "5px 5px" }}
      >
        <Row
          //   gutter={12}
          className="filter-rows p-t-sm"
          // style={{
          //   marginLeft: "5px",
          //   marginRight: "5px",
          // }}
        >
          <Col xs={24} sm={24} md={14}>
            <Space direction="vertical" size={0}>
              {/* {dataFilter.filterText ? (
                <></>
              ) : (
                <>
                  <Typography.Text style={{ fontSize: 25 }} className="m-b-n">
                    Filter
                  </Typography.Text>
                </>
              )} */}
              <Space>
                <Button
                  type="link"
                  className="btn-link-main-8 p-l-none"
                  onClick={(e) => {
                    setViewCategory(!viewCategory);
                    setViewTag(false);
                  }}
                >
                  Categories
                </Button>
                <Button
                  type="link"
                  className="btn-link-main-8 p-l-none"
                  onClick={(e) => {
                    setViewTag(!viewTag);
                    setViewCategory(false);
                  }}
                >
                  Tags
                </Button>
                <Button
                  type="link"
                  className="btn-link-main-8 p-l-none"
                  onClick={(e) => {
                    setIsVisible(true);
                    setViewCategory(false);
                    setViewTag(false);
                  }}
                >
                  Venues
                </Button>
                <Button
                  type="link"
                  className="btn-link-main-8 p-l-none"
                  onClick={(e) => handleReset()}
                >
                  {/* Reset */}
                  {/* <FontAwesomeIcon icon={faArrowAltToLeft} /> */}
                  <ReloadOutlined />
                </Button>
              </Space>
            </Space>
          </Col>
        </Row>

        <Row
        // gutter={12}
        // style={{ marginLeft: "0px", marginRight: "0px" }}
        >
          <Col xs={24} sm={24} className="m-b-sm">
            {categoryValue.length > 0 && (
              <Tag
                closable
                onClose={(e) => {
                  setCategoryValue([]);
                  setDataFilter({
                    ...dataFilter,
                    category: "",
                  });
                  setViewCategory(false);
                }}
              >
                Categories:{" "}
                {categoryValue
                  .map((item) => {
                    return item;
                  })
                  .join(", ")}
              </Tag>
            )}

            {tagValue.length > 0 && (
              <Tag
                closable
                onClose={(e) => {
                  setTagValue([]);
                  setViewTag(false);
                  setDataFilter({
                    ...dataFilter,
                    tag: "",
                  });
                }}
              >
                Tags:{" "}
                {tagValue
                  .map((item) => {
                    return item;
                  })
                  .join(", ")}
              </Tag>
            )}

            {dataFilter.country ||
            dataFilter.state ||
            dataFilter.city.length > 0 ? (
              <Tag
                closable
                onClose={(e) => {
                  setDataFilter({
                    ...dataFilter,
                    country: "",
                    state: "",
                    city: "",
                  });
                  form.resetFields();
                }}
              >
                {dataFilter.country && (
                  <>
                    Country: {dataFilter.country}
                    {"; "}
                  </>
                )}
                {dataFilter.state && (
                  <>
                    State: {dataFilter.state}
                    {"; "}
                  </>
                )}
                {dataFilter.city && (
                  <>
                    Cities:{" "}
                    {dataFilter.city &&
                      dataFilter.city
                        .map((item) => {
                          return item;
                        })
                        .join(", ")}
                  </>
                )}
              </Tag>
            ) : (
              ""
            )}
          </Col>
        </Row>

        <Row
        // gutter={12}
        // style={{ marginLeft: "0px", marginRight: "0px" }}
        >
          {viewCategory && (
            <Col xs={24} sm={24} md={8}>
              <FloatSelect
                label="Categories"
                placeholder="Categories"
                options={optionAssessmentCategory}
                onChange={handleCategory}
                value={categoryValue}
                multi="multiple"
              />
            </Col>
          )}
          {viewTag && (
            <Col xs={24} sm={24} md={8}>
              <FloatSelect
                label="Tags"
                placeholder="Tags"
                options={sportList}
                onChange={handleTag}
                value={tagValue}
                multi="multiple"
              />
            </Col>
          )}
        </Row>

        <br />
        {showMonthView && (
          <FullCalendar
            defaultView="dayGridMonth"
            headerToolbar={{
              left: "prev,next",
              center: "title",
              right: "dayGridMonth,timeGridWeek,timeGridDay",
            }}
            plugins={[dayGridPlugin, timeGridPlugin]}
            events={dataEventCalendar}
            eventClick={(e) => handleClickCalendar(e)}
            eventAf={function (event, element) {
              console.log(event);
              // if (event.type == "X")
              //   element
              //     .find(".fc-event-dot")
              //     .css("background-color", "#08b394");
              // if (event.type == "Y")
              //   element
              //     .find(".fc-event-dot")
              //     .css("background-color", "#2a7568");
            }}
            eventContent={renderContent}
          />
        )}

        {!showMonthView && (
          <Row
            gutter={[16, 16]}
            className="ant-row-event-list"
            // style={{ marginLeft: "0px", marginRight: "0px" }}
          >
            {assessmentCard &&
              assessmentCard.map((item, key) => {
                // console.log("assessmentCard item", item);
                let category = [];
                if (item.category.length <= 2) {
                  item.category.map((item, key) => {
                    category.push(item.category);
                    return "";
                  });
                } else {
                  item.category.map((item, key) => {
                    if (key <= 2) {
                      category.push(item.category);
                    }
                    return "";
                  });
                }

                let description = item.description;

                let dot_status = "dot-open";

                if (item.booked && item.booked.length > 0) {
                  dot_status = "dot-registered";
                  if (item.booked[0].status !== "Upcoming / Pending") {
                    dot_status = "dot-assessed";
                  }
                }

                return (
                  <Col
                    xs={24}
                    sm={24}
                    md={column ? column : 12}
                    lg={column_lg ? column_lg : 12}
                    xl={column_xl ? column_xl : 8}
                    key={key}
                    onClick={(e) => {
                      setToggleModalPreview({
                        ...toggleModalPreview,
                        show: true,
                        data: item,
                      });
                      // console.log("data", item);
                    }}
                  >
                    {/* {item.assessments && item.assessments[0].status} */}
                    <Badge.Ribbon
                      text={
                        <div>
                          {/* {moment(
                            item.schedule && item.schedule[0]["date"]
                          ).format("MMM DD, YYYY")} */}
                          {item.date_sort}
                        </div>
                      }
                      className="dash-badge-color"
                    >
                      {/* {dot_status} */}
                      {item.booked && item.booked.length > 0 && (
                        <div className="assessment-card">
                          <span>Already Booked this Assessment</span>
                        </div>
                      )}

                      <Card
                        hoverable
                        cover={
                          <>
                            {item.type === "Group" ? (
                              <img
                                alt="example"
                                src={
                                  item.upload ? apiUrl + item.upload : logoDef
                                }
                              />
                            ) : (
                              <img
                                alt="example"
                                src={handleRenderPrivateImg(item)}
                              />
                            )}
                          </>
                        }
                      >
                        <Card.Meta
                          title={
                            <div
                              style={{
                                whiteSpace: "normal",
                              }}
                            >
                              {dataFilter &&
                              (dataFilter.from === "athlete_dashboard" ||
                                dataFilter.from === "athlete_all_assessments" ||
                                dataFilter.from === "athlete_my_assessments" ||
                                dataFilter.from === "coach_dashboard") ? (
                                // <ul className="ul-list-style-none">
                                //   <li >
                                //   </li>
                                // </ul>
                                <Typography.Text className="font-18px white-space-normal">
                                  {item.title}
                                </Typography.Text>
                              ) : (
                                <Typography.Text className="font-18px white-space-normal">
                                  {item.title}
                                </Typography.Text>
                              )}

                              <p className="c-secondary dash-time-font-size dash-role">
                                {item.category.length <= 3
                                  ? category.join(", ")
                                  : category.join(", ").slice(0, -1) + " ..."}
                              </p>
                              <ul style={{ marginTop: "-12px" }}>
                                {/* {moment(item.date_sort).format(
                                      "MMM. DD YYYY"
                                    )}{" "}
                                    {item.date_time_start}
                                    {" - "}
                                    {item.date_time_end} */}
                                {item.schedule.map((item, key) => {
                                  return (
                                    <li key={key}>
                                      <Typography.Text>
                                        {moment(item["date"]).format("ll")} -{" "}
                                        {item["time_start"]} -{" "}
                                        {item["time_end"]}
                                      </Typography.Text>
                                    </li>
                                  );
                                })}

                                <li>
                                  <Typography.Text>
                                    {item.location}, {item.city}, {item.state}
                                  </Typography.Text>
                                </li>
                              </ul>
                            </div>
                          }
                          description={
                            <>
                              <Typography.Paragraph
                                // ellipsis={{
                                //   rows: 3,
                                // }}
                                type="secondary"
                              >
                                {/* {item.description} */}
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: description,
                                  }}
                                ></div>
                              </Typography.Paragraph>

                              {dataFilter.from === "athlete_my_assessments" && (
                                <Typography.Text>
                                  Type: {item.type}
                                </Typography.Text>
                              )}
                            </>
                          }
                        />

                        {item.is_attended ? (
                          <div
                            style={{
                              position: "absolute",
                              top: "17%",
                              left: 0,
                              width: "100%",
                              textAlign: "center",
                            }}
                          >
                            <Alert
                              style={{
                                background: "#23bf08",
                                borderColor: "#23bf08",
                              }}
                              message={
                                <div style={{ color: "white" }}>
                                  You Already Attended on this Event
                                </div>
                              }
                            />
                          </div>
                        ) : null}
                      </Card>
                    </Badge.Ribbon>
                  </Col>
                );
              })}
          </Row>
        )}
      </Card>

      <ModalAssessmentPreviewPublic
        toggleModalPreview={toggleModalPreview}
        setToggleModalPreview={setToggleModalPreview}
        assessmentCard={assessmentCard}
      />

      <Modal
        title="VENUES"
        visible={isVisible}
        onCancel={handleModalCancel}
        className="modal-dashboard"
        footer={[
          <Space key="footer">
            <Button
              size="large"
              className="btn-main-outline-2"
              onClick={() => {
                form
                  .validateFields()
                  .then((values) => {
                    onFinishForm(values);
                  })
                  .catch((info) => {
                    // console.log("Validate Failed:", info);
                  });
              }}
            >
              SUBMIT
            </Button>
          </Space>,
        ]}
      >
        <Form layout="vertical" form={form}>
          <Form.Item name="country" className="form-select-error">
            <FloatSelect
              label="Country"
              placeholder="Country"
              options={country}
              onChange={handleCountry}
            />
          </Form.Item>
          <Form.Item name="state" className="form-select-error">
            <FloatSelect
              label={stateLabel}
              placeholder={stateLabel}
              options={optionState}
              onChange={handleStateLocation}
            />
          </Form.Item>
          {/* <FloatInput label="City" placeholder="City " /> */}
          <Form.Item name="city" className="form-select-error-multi">
            <FloatSelect
              label="Cities"
              placeholder="Cities"
              options={optionCityLocation}
              multi="multiple"
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}
