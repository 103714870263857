import React, { useState } from "react";
import { Card, Col, Layout, Row } from "antd";
import { faCalendarAlt } from "@fortawesome/pro-regular-svg-icons";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";

import ComponentHeader from "../../Components/ComponentHeader";
import ComponentDashboard from "../../Components/ComponentDashboard";
// import FloatInput from "../../../../providers/FloatInput";

export default function PageEventsCalendarGroupAssessments() {
  const [dataFilter, setDataFilter] = useState({
    date: moment().format("MM/DD/YYYY"),
    type: "Group",
    search: "",
    category: "",
    tag: "",
    country: "",
    state: "",
    city: "",
    filterText: true,
    column: 6,
  });

  return (
    <>
      <ComponentHeader
        title="Assessments"
        sub_title="GROUP"
        icon={faCalendarAlt}
      />

      <Card
        className="card-min-height"
        style={{ paddingBottom: "230px !important" }}
        id="PageEventsCalendarGroupAssessments"
      >
        <Row gutter={12}>
          <Col span={24}>
            <ComponentDashboard
              dataFilter={dataFilter}
              setDataFilter={setDataFilter}
              column={8}
              column_lg={6}
              column_xl={6}
            />
          </Col>
        </Row>
      </Card>
    </>
  );
}
