import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Layout,
  Card,
  Form,
  Button,
  Row,
  Col,
  Image,
  Typography,
  Alert,
  Collapse,
  Space,
  Radio,
  notification,
  Input,
} from "antd";
import {
  LoadingOutlined,
  MinusSquareOutlined,
  PlusSquareOutlined,
} from "@ant-design/icons";
import moment from "moment";
import companyInfo from "../../../providers/companyInfo";
import { GET, POST } from "../../../providers/useAxiosQuery";
import FloatInput from "../../../providers/FloatInput";
import FloatInputMask from "../../../providers/FloatInputMask";
import ComponentHeader from "../Components/ComponentHeader";
import FloatSelect from "../../../providers/FloatSelect";
import optionCountryCodes from "../../../providers/optionCountryCodes";

import optionStateCodesUnitedState from "../../../providers/optionStateCodesUnitedState";
import optionStateCodesCanada from "../../../providers/optionStateCodesCanada";
import $ from "jquery";
import ReCAPTCHA from "react-google-recaptcha";

import { faEdit } from "@fortawesome/pro-regular-svg-icons";
import FloatSelectWithDangerouslySetInnerHTML from "../../../providers/FloatSelectWithDangerouslySetInnerHTML";

const logo = companyInfo().logo;
const description = companyInfo().description;
// const encryptor = companyInfo().encryptor;

export default function PageRegister(props) {
  useEffect(() => {
    console.log("props", props);
    return () => {};
  }, [props]);
  const history = useHistory();
  const [form2] = Form.useForm();
  const [form4] = Form.useForm();
  const [errorMessageStep2, setErrorMessageStep2] = useState({
    type: "success",
    message: "",
  });
  const [errorMessageStep4, setErrorMessageStep4] = useState({
    type: "success",
    message: "",
  });
  const [collapseActiveKey, setCollapseActiveKey] = useState("1");

  // const registrationAmmount = 29.99;
  // const registrationAmmount = 1;
  const [registrationAmmount, setRegistrationAmmount] = useState(1.0);

  const [stepData, setStepData] = useState({
    step1: {
      done: false,
      data: null,
    },
    step2: {
      done: false,
      data: null,
    },
    step3: {
      done: false,
      data: {
        amount: registrationAmmount,
      },
    },
    step4: {
      done: false,
      data: null,
    },
  });

  const [couponDiscount, setCouponDiscount] = useState({
    discountType: "",
    discount: 0,
    total: registrationAmmount,
  });
  GET("api/v1/get_reg_fee", "get_reg_fee", (res) => {
    if (res.success) {
      let amount = parseFloat(res.data.amount);
      console.log("get_reg_fee", res);
      setRegistrationAmmount(amount);
      setStepData({
        ...stepData,
        step3: {
          done: false,
          data: {
            amount: amount,
          },
        },
      });
      setCouponDiscount({
        ...couponDiscount,
        total: amount,
      });
    }
  });

  const [step4YesNo, setStep4YesNo] = useState({
    isOver16YearsOld: "",
    guardiaPermission: "",
  });

  const [optionBillingState, setOptionBillingState] = useState([]);
  const [optionBillingZip, setOptionBillingZip] = useState();

  const [accountTypeOption, setAccountTypeOption] = useState([]);
  const [accountPlanOption, setAccountPlanOption] = useState([]);

  const { isLoading: isLoadingAT } = GET("api/v1/at", "at", (res) => {
    if (res.success) {
      // console.log("accountTypeOption", res.data);
      let arr = [];
      res.data.map((row, index) => {
        arr.push({
          label: row.description,
          value: row.id,
          policy: row.privacy && row.privacy.privacy_policy,
          account_plan: row.account_plan,
          json: row,
        });
      });

      // console.log("dataAccountType", arr);
      setAccountTypeOption(arr);
    }
  });

  const { mutate: mutateRegister, isLoading: isLoadingRegister } = POST(
    "api/v1/register",
    "register"
  );

  const [optionState, setOptionState] = useState([]);
  const [stateLabel, setStateLabel] = useState("State");
  const [billingStateLabel, setBillingStateLabel] = useState("State");
  const [billingZipLabel, setBillingZipLabel] = useState("Zip Code");

  const stateUS = optionStateCodesUnitedState();
  const stateCA = optionStateCodesCanada();

  const handleCountry = (e, opt) => {
    if (e === "United States") {
      setOptionState(stateUS);
      setStateLabel("State");
    } else if (e === "Canada") {
      setOptionState(stateCA);
      setStateLabel("County");
    } else {
      setOptionState(stateUS);
      setStateLabel("State");
    }

    form2.resetFields(["state"]);
  };

  const handleChangeBillingCountry = (e, opt) => {
    // console.log("e, opt", e, opt);
    if (e === "United States") {
      setOptionBillingState(stateUS);
      setOptionBillingZip(/(^\d{5}$)|(^\d{5}[ -]\d{4}$)/);
      setBillingStateLabel("State");
      setBillingZipLabel("Zip Code");
    } else if (e === "Canada") {
      setOptionBillingState(stateCA);
      setOptionBillingZip(/^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/);
      setBillingStateLabel("County");
      setBillingZipLabel("Postal Code");
    } else {
      setOptionBillingState(stateUS);
      setOptionBillingZip(/(^\d{5}$)|(^\d{5}[ -]\d{4}$)/);
      setBillingStateLabel("State");
      setBillingZipLabel("Zip Code");
    }

    form4.resetFields(["billing_state", "billing_city", "billing_zip"]);
  };

  const onFinishStep2 = (values) => {
    if (stepData.step1.data === "Coach") {
      let formData = {
        ...values,
        account_type: "Coach",
        link_origin: window.location.origin,
      };

      mutateRegister(formData, {
        onSuccess: (res) => {
          if (res.success) {
            setErrorMessageStep2({
              type: "success",
              message:
                "A confirmation e-mail has been send please check your inbox or your spam folder for the next step.",
            });
            // setTimeout(() => {
            // 	window.location.replace("/");
            // }, 1000);
            // autoLogin(formData.confirm_email);
          } else {
            setErrorMessageStep2({
              type: "error",
              message: res.message,
            });
          }
        },
      });
    } else {
      setErrorMessageStep2({
        type: "success",
        message: "",
      });
      setStepData({
        ...stepData,
        step2: { done: true, data: values },
      });
      setCollapseActiveKey(3);
    }
  };

  const onFinishStep4 = (values) => {
    let formData = {
      account_type: stepData.step1.data,
      ...stepData.step2.data,
      program: stepData.step3.data,
      ...values,
      link_origin: window.location.origin,
    };

    console.log("onFinishStep4", formData);

    mutateRegister(formData, {
      onSuccess: (res) => {
        if (res.success) {
          setErrorMessageStep4({
            type: "success",
            message:
              "A confirmation e-mail has been send please check your inbox or your spam folder for the next step.",
          });
          localStorage.bfssRegStepData = JSON.stringify(stepData);

          //   autoLogin(formData.confirm_email);
        } else {
          setErrorMessageStep4({
            type: "error",
            message: res.message,
          });
        }
      },
      onError: (err) => {
        setErrorMessageStep4({
          type: "error",
          message: err.response.data.message,
        });
      },
    });
  };

  const { mutate: mutateApplyCoupon, isLoading: isLoadingApplyCoupon } = POST(
    "api/v1/apply_coupon_stripe_system",
    "apply_coupon_stripe_system"
  );

  const [formCoupon] = Form.useForm();
  const [counpoCode, setCounpoCode] = useState();
  const [dataCounponSelected, setDataCounponSelected] = useState();
  const [couponError, setCouponError] = useState(false);
  const handleApplyCoupon = (e) => {
    var coupon = formCoupon.getFieldValue("coupon");
    // console.log("coupon", dataPlanSelected);
    if (coupon) {
      mutateApplyCoupon(
        { code: coupon, role: stepData.step1.data },
        {
          onSuccess: (res) => {
            if (res.success) {
              if (res.data) {
                if (parseInt(res.data.max) > parseInt(res.data.redemption)) {
                  setCouponError(true);
                  setDataCounponSelected(res.data);
                  notification.success({
                    message: "Success",
                    description: " Code Successfully applied",
                  });
                  if (res.data.type == "fixed") {
                    let discountData = res.data.off;
                    let totalData = registrationAmmount - discountData;
                    setCouponDiscount({
                      discountType: `$${discountData} off`,
                      discount: discountData,
                      total: totalData < 0 ? 0 : totalData,
                    });
                  }
                  if (res.data.type == "percent") {
                    let discountData = res.data.off / 100;
                    let totalData =
                      registrationAmmount - discountData * registrationAmmount;
                    setCouponDiscount({
                      discountType: `${res.data.off}% off`,
                      discount: discountData,
                      total: totalData < 0 ? 0 : totalData,
                    });

                    console.log(discountData);
                  }
                  if (res.data.type == "offer") {
                    let discountData = res.data.off;
                    let totalData = registrationAmmount - discountData;
                    setCouponDiscount({
                      discountType: `$${discountData} off`,
                      discount: discountData,
                      total: totalData < 0 ? 0 : totalData,
                    });
                  }
                  setCounpoCode(coupon);
                  formCoupon.submit();
                } else {
                  notification.error({
                    message: "Coupon Denied",
                    description: "Coupon usage limit has been reached",
                  });
                  setCouponError(false);
                  setDataCounponSelected(null);
                  formCoupon.resetFields(["coupon"]);
                  setCouponDiscount({
                    discountType: ``,
                    discount: 0,
                    total: registrationAmmount,
                  });
                  setCouponValue("");
                }
              }
            } else {
              notification.error({
                message: "Coupon Denied",
                description: "Coupon code is not available",
              });
              setCouponError(false);
              setDataCounponSelected(null);
              formCoupon.resetFields(["coupon"]);
              setCouponDiscount({
                discountType: ``,
                discount: 0,
                total: registrationAmmount,
              });
              setCouponValue("");
            }
          },
          onError: (err) => {
            console.log(err.response.data);
            notification.error({
              message: "Coupon Denied",
              description: "Coupon code is not available.",
            });
            setDataCounponSelected(null);
            formCoupon.resetFields(["coupon"]);
            setCouponError(false);
            setCouponDiscount({
              discountType: ``,
              discount: 0,
              total: registrationAmmount,
            });
            setCouponValue("");
          },
        }
      );
    } else {
      //   setCouponError(false);
    }
  };

  const handleFinishCoupon = (values) => {
    // console.log("handleFinishCoupon", dataPlanSelected);
    // console.log("onFinishStep4", dataCounponSelected);

    // if (dataPlanSelected) {
    let amount = registrationAmmount;
    let coupon_amount = 0;
    let discount = 0;
    let type = "";
    if (dataCounponSelected) {
      if (dataCounponSelected.type == "fixed") {
        discount = dataCounponSelected.off;
        coupon_amount = discount;
        amount = amount - discount;
        amount = amount.toFixed(6);
        type = "fixed";
        amount = amount.split(".");
        amount = `${amount[0]}.${amount[1].substr(0, 2)}`;
      }
      if (dataCounponSelected.type == "percent") {
        coupon_amount = dataCounponSelected.off;
        discount =
          dataCounponSelected.off === 100 ? 0 : dataCounponSelected.off / 100;
        discount = amount * discount;
        amount = amount - discount;
        amount = amount.toFixed(6);
        type = "percent";
        amount = amount.split(".");
        amount = `${amount[0]}.${amount[1].substr(0, 2)}`;
      }
      if (dataCounponSelected.type == "offer") {
        discount = dataCounponSelected.off;
        coupon_amount = discount;
        amount = amount - discount;
        amount = amount.toFixed(6);
        type = "offer";
        amount = amount.split(".");
        amount = `${amount[0]}.${amount[1].substr(0, 2)}`;
      }
    }

    let program = `Total Registration Fee $${amount}`;
    let step3Data = {
      amount: amount,
      free_credit: registrationAmmount,
      discount: coupon_amount,
      coupon_type: type,
      program,
    };

    if (counpoCode) {
      step3Data = {
        ...step3Data,
        coupon_code: counpoCode,
      };
    }

    // console.log("step3Data", step3Data);

    setStepData({
      ...stepData,
      step3: {
        done: true,
        data: step3Data,
      },
    });
    setCollapseActiveKey("4");
    localStorage.tempBFSSData = JSON.stringify({
      ...JSON.parse(localStorage.tempBFSSData),
      step3Data,
    });
  };

  const [cityOption, setCityOption] = useState([]);
  const handleBillingState = (val, opt) => {
    console.log("handleBillingState", opt);
    if (val) {
      let option = opt["data-json"];
      setCityOption(option);
    } else {
      setCityOption([]);
    }
    form4.resetFields(["billing_city", "billing_zip"]);
  };

  useEffect(() => {
    console.log("stepData", stepData);
    return () => {};
  }, [stepData]);

  const [captchaToken, setCaptchaToken] = useState("");

  useEffect(() => {
    if (captchaToken) {
      console.log("captchaToken", captchaToken);
    }
  }, [captchaToken]);

  const [couponValue, setCouponValue] = useState("");

  const checkAccount = (value, type) => {
    let data = {
      value: value,
      type: type,
    };
    console.log("checkAccount", data);

    mutateCheckAccount(data, {
      onSuccess: (res) => {
        if (res.success) {
        } else {
          notification.error({
            message: "Warning",
            description: res.message,
          });

          if (type === "username") {
            form2.resetFields(["username"]);
          } else if (type === "email") {
            form2.resetFields(["email", "confirm_email"]);
          } else if (type === "guardian_email") {
            form2.resetFields(["guardian_email", "guardian_confirm_email"]);
          } else if (type === "guardian_username") {
            form2.resetFields(["guardian_username"]);
          }
        }
      },
    });
  };

  const { mutate: mutateCheckAccount } = POST(
    "api/v1/check_account",
    "check_account"
  );

  const [widthScreen, setWidthScreen] = useState($(window).width());
  useEffect(() => {
    function handleResize() {
      setWidthScreen($(window).width());
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [widthScreen]);

  useEffect(() => {
    console.log("width", widthScreen);
  }, [widthScreen]);

  return (
    <Layout className="public-layout register-layout">
      <Layout.Content className="p-t-lg">
        <Row>
          <Col xs={24}>
            <Image
              className="zoom-in-out-box"
              onClick={() =>
                history.push({
                  pathname: `/`,
                  state: props.location.state,
                })
              }
              src={logo}
              preview={false}
            />

            <Card className="m-t-md">
              <ComponentHeader
                title="Registration"
                sub_title={
                  stepData.step1.data
                    ? `${
                        stepData.step1.data !== "Coach" ? "Athlete" : "Coach"
                      }'s`
                    : "New User"
                }
                icon={faEdit}
              />

              <Collapse
                accordion
                expandIconPosition="right"
                activeKey={collapseActiveKey}
                onChange={(e) => setCollapseActiveKey(e)}
                expandIcon={({ isActive }) =>
                  isActive ? <MinusSquareOutlined /> : <PlusSquareOutlined />
                }
              >
                <Collapse.Panel header="Step 1" key="1">
                  <Typography.Text>Select Account</Typography.Text>

                  <Form
                    name="basic"
                    layout="vertical"
                    className="login-form"
                    //   form={form}
                    autoComplete="off"
                  >
                    <Form.Item name="account_option" hasFeedback>
                      <FloatSelectWithDangerouslySetInnerHTML
                        label="Account Options"
                        placeholder="Account Options"
                        options={accountTypeOption}
                        loading={isLoadingAT}
                        onChange={(e) => {
                          // console.log("Account Options", e);
                          if (e) {
                            let account_type = "";
                            if (e === "1" || e === 1) {
                              account_type = "Athlete";
                            } else if (e === "2" || e === 2) {
                              account_type = "Athlete Guardian";
                            } else if (e === "3" || e === 3) {
                              account_type = "Coach";
                            }

                            let accountTypeOptionTemp =
                              accountTypeOption.filter(
                                (itemFilter) => itemFilter.value === e
                              );
                            // console.log(
                            // 	"accountTypeOptionTemp",
                            // 	accountTypeOptionTemp
                            // );
                            if (accountTypeOptionTemp.length > 0) {
                              let account_plan_temp = [];
                              accountTypeOptionTemp[0].account_plan.map(
                                (item) => {
                                  if (item.plan === "Starter") {
                                    account_plan_temp.push({
                                      value: item.id,
                                      label: item.description,
                                      json: item,
                                    });
                                  }
                                }
                              );
                              setAccountPlanOption(account_plan_temp);
                            } else {
                              setAccountPlanOption([]);
                            }

                            setErrorMessageStep2({
                              type: "success",
                              message: "",
                            });
                            setStepData({
                              ...stepData,
                              step1: { done: true, data: account_type },
                            });
                            setCollapseActiveKey("2");
                            localStorage.tempBFSSData = JSON.stringify({
                              account_type: e,
                            });
                          } else {
                            setStepData({
                              ...stepData,
                              step1: { done: true, data: null },
                            });

                            setErrorMessageStep2({
                              type: "success",
                              message: "",
                            });
                          }
                        }}
                      />
                    </Form.Item>
                  </Form>
                </Collapse.Panel>

                {stepData.step1.done && (
                  <>
                    <Collapse.Panel header="Step 2" key="2">
                      <Form
                        layout="vertical"
                        className="form-step2"
                        onFinish={onFinishStep2}
                        autoComplete="off"
                        form={form2}
                      >
                        <Typography.Text>
                          Complete All Fields Below
                        </Typography.Text>

                        <Typography.Title
                          level={3}
                          className="font-weight-normal"
                        >
                          {stepData.step1.data === "Athlete" ||
                          stepData.step1.data === "Athlete Guardian"
                            ? "Athlete's"
                            : "Coach's"}{" "}
                          Information
                        </Typography.Title>

                        <Form.Item
                          name="firstname"
                          hasFeedback
                          rules={[
                            {
                              required: true,
                              message: "This field field is required.",
                            },
                          ]}
                        >
                          <FloatInput
                            label="First Name"
                            placeholder="First Name"
                          />
                        </Form.Item>

                        <Form.Item
                          name="lastname"
                          hasFeedback
                          rules={[
                            {
                              required: true,
                              message: "This field field is required.",
                            },
                          ]}
                        >
                          <FloatInput
                            label="Last Name"
                            placeholder="Last Name"
                          />
                        </Form.Item>

                        <Form.Item
                          name="email"
                          hasFeedback
                          rules={[
                            {
                              type: "email",
                              message: "The input is not valid email!",
                            },
                            {
                              required: true,
                              message: "Please input your email!",
                            },
                          ]}
                        >
                          <FloatInput
                            label="Email"
                            placeholder="Email"
                            onBlurInput={(e) => checkAccount(e, "email")}
                          />
                        </Form.Item>

                        <Form.Item
                          name="confirm_email"
                          hasFeedback
                          rules={[
                            {
                              required: true,
                              message: "This field field is required.",
                            },
                            ({ getFieldValue }) => ({
                              validator(_, value) {
                                if (
                                  !value ||
                                  getFieldValue("email") === value
                                ) {
                                  return Promise.resolve();
                                }
                                return Promise.reject(
                                  new Error(
                                    "The two emails that you entered do not match!"
                                  )
                                );
                              },
                            }),
                          ]}
                        >
                          <FloatInput
                            label="Confirm Email"
                            placeholder="Confirm Email"
                          />
                        </Form.Item>

                        <Form.Item
                          name="username"
                          hasFeedback
                          rules={[
                            {
                              required: true,
                              message: "This field field is required.",
                            },
                          ]}
                        >
                          <FloatInput
                            label="Create Username"
                            placeholder="Create Username"
                            onBlurInput={(e) => checkAccount(e, "username")}
                          />
                        </Form.Item>

                        <Form.Item
                          name="country"
                          hasFeedback
                          className="form-select-error"
                          rules={[
                            {
                              required: true,
                              message: "This field field is required.",
                            },
                          ]}
                        >
                          <FloatSelect
                            label="Country"
                            placeholder="Country"
                            options={optionCountryCodes}
                            onChange={handleCountry}
                          />
                        </Form.Item>

                        <Form.Item
                          name="state"
                          hasFeedback
                          className="form-select-error"
                          rules={[
                            {
                              required: true,
                              message: "This field field is required.",
                            },
                          ]}
                        >
                          <FloatSelect
                            label={stateLabel}
                            placeholder={stateLabel}
                            options={optionState}
                          />
                        </Form.Item>

                        {stepData.step1.data === "Athlete Guardian" && (
                          <>
                            <Typography.Title
                              level={3}
                              className="font-weight-normal"
                            >
                              Parent / Guardian's Information
                            </Typography.Title>

                            <Form.Item
                              name="guardian_firstname"
                              hasFeedback
                              rules={[
                                {
                                  required: true,
                                  message: "This field field is required.",
                                },
                              ]}
                            >
                              <FloatInput
                                label="First Name"
                                placeholder="First Name"
                              />
                            </Form.Item>

                            <Form.Item
                              name="guardian_lastname"
                              hasFeedback
                              rules={[
                                {
                                  required: true,
                                  message: "This field field is required.",
                                },
                              ]}
                            >
                              <FloatInput
                                label="Last Name"
                                placeholder="Last Name"
                              />
                            </Form.Item>

                            <Form.Item
                              name="guardian_email"
                              hasFeedback
                              rules={[
                                {
                                  type: "email",
                                  message: "The input is not valid email!",
                                },
                                {
                                  required: true,
                                  message: "Please input your email!",
                                },
                              ]}
                            >
                              <FloatInput
                                label="Email"
                                placeholder="Email"
                                onBlurInput={(e) =>
                                  checkAccount(e, "guardian_email")
                                }
                              />
                            </Form.Item>

                            <Form.Item
                              name="guardian_confirm_email"
                              hasFeedback
                              rules={[
                                {
                                  required: true,
                                  message: "This field field is required.",
                                },
                                ({ getFieldValue }) => ({
                                  validator(_, value) {
                                    if (
                                      !value ||
                                      getFieldValue("guardian_email") === value
                                    ) {
                                      return Promise.resolve();
                                    }
                                    return Promise.reject(
                                      new Error(
                                        "The two emails that you entered do not match!"
                                      )
                                    );
                                  },
                                }),
                              ]}
                            >
                              <FloatInput
                                label="Confirm E-mail"
                                placeholder="Confirm E-mail"
                              />
                            </Form.Item>

                            <Form.Item
                              name="guardian_username"
                              hasFeedback
                              rules={[
                                {
                                  required: true,
                                  message: "This field field is required.",
                                },
                              ]}
                            >
                              <FloatInput
                                label="Create Username"
                                placeholder="Create Username"
                                onBlurInput={(e) =>
                                  checkAccount(e, "guardian_username")
                                }
                              />
                            </Form.Item>
                          </>
                        )}

                        <Button
                          type="primary"
                          htmlType="submit"
                          loading={isLoadingRegister}
                          className="btn-primary-default"
                          block
                          size="large"
                        >
                          {stepData.step1.data === "Coach"
                            ? "SUBMIT"
                            : "CONTINUE"}
                        </Button>

                        {errorMessageStep2.message && (
                          <>
                            <Alert
                              className="m-t-sm"
                              type={errorMessageStep2.type}
                              message={errorMessageStep2.message}
                            />
                            {/* <div className="forgot m-t-md">
															<Link
																type="link"
																className="login-form-button companycolor1"
																size="small"
																to="#"
															>
																Need Help?
															</Link>
														</div> */}
                          </>
                        )}
                      </Form>
                    </Collapse.Panel>
                  </>
                )}

                {stepData.step1.data !== "Coach" && stepData.step2.done && (
                  <Collapse.Panel header="Step 3" key="3">
                    <Typography.Text>Select Program Assessment</Typography.Text>
                    <Form
                      name="basic"
                      layout="vertical"
                      className="m-t-md"
                      form={formCoupon}
                      onFinish={handleFinishCoupon}
                      autoComplete="off"
                    >
                      <Typography.Title
                        level={3}
                        className="font-weight-normal w-100"
                      >
                        Registration Fee ${registrationAmmount.toFixed(2)}
                      </Typography.Title>

                      {/* {dataPlanSelected && (
                        <> */}
                      <Form.Item
                        name="coupon"
                        rules={[
                          {
                            required: false,
                            message: "This field field is required.",
                          },
                        ]}
                        // hasFeedback
                      >
                        <FloatInput
                          label="Coupon"
                          placeholder="Coupon"
                          onChange={(val) => {
                            if (val) {
                              setCouponValue(val);
                            } else {
                              setCouponValue("");
                            }
                          }}
                        />
                      </Form.Item>

                      {couponError === false && (
                        <h3
                          style={{
                            fontWeight: "bold",
                            // marginTop: "-23px",
                            // position: "absolute",
                          }}
                        >
                          {/* Total: ${stepData.step3.data.amount} */}
                          Total: ${couponDiscount.total.toFixed(2)}
                        </h3>
                      )}

                      {/* </>
                      )} */}

                      {couponValue ? (
                        <Button
                          className="btn-primary-default m-t-sm"
                          disabled={isLoadingApplyCoupon}
                          block
                          size="large"
                          // style={{
                          //   // height: "46px",
                          //   marginTop: "-1px",
                          //   borderRadius: "0px",
                          // }}
                          onClick={(e) => handleApplyCoupon(e)}
                        >
                          {isLoadingApplyCoupon ? <LoadingOutlined /> : ""}{" "}
                          APPLY
                        </Button>
                      ) : (
                        <Button
                          type="primary"
                          htmlType="submit"
                          className="btn-primary-default m-t-sm"
                          block
                          size="large"
                        >
                          SUBMIT
                        </Button>
                      )}
                    </Form>
                  </Collapse.Panel>
                )}

                {stepData.step1.data !== "Coach" && stepData.step3.done && (
                  <Collapse.Panel header="Step 4" key="4">
                    <Form
                      layout="vertical"
                      className="form-step-4"
                      onFinish={onFinishStep4}
                      autoComplete="off"
                      form={form4}
                    >
                      <Row gutter={12}>
                        <Col xs={24} sm={24} md={24}>
                          <Typography.Text className="w-100 m-t-md">
                            {couponError && (
                              <>
                                <span
                                  style={{
                                    color: "#23BF08",
                                    // marginTop: "-23px",
                                  }}
                                >
                                  Code Successfully applied (
                                  {couponDiscount.discountType})
                                </span>
                                <br />
                              </>
                            )}

                            {couponError && (
                              <>
                                <span>Subtotal: ${registrationAmmount}</span>
                                <br />
                                <span>
                                  Discount: {couponDiscount.discountType}
                                </span>
                                {/* <h3
                                style={{
                                  fontWeight: "bold",
                                }}
                              >
                                Total: ${`${couponDiscount.total.toFixed(2)}`}
                              </h3> */}
                              </>
                            )}
                          </Typography.Text>
                          <Typography.Title
                            level={3}
                            className="font-weight-normal w-100 m-t-xs"
                          >
                            <strong>{stepData.step3.data.program}</strong>
                          </Typography.Title>
                        </Col>
                        <Col xs={24} sm={24} md={24}>
                          <Space>
                            <span>Over 16 years old?</span>

                            <Form.Item
                              name="is_over_16_years_old"
                              hasFeedback
                              className="m-b-sm"
                            >
                              <Radio.Group
                                value={step4YesNo.isOver16YearsOld}
                                onChange={(e) =>
                                  setStep4YesNo({
                                    ...step4YesNo,
                                    isOver16YearsOld: e.target.value,
                                  })
                                }
                              >
                                <Radio value="Yes">Yes</Radio>
                                <Radio value="No">No</Radio>
                              </Radio.Group>
                            </Form.Item>
                          </Space>
                        </Col>
                        <Col xs={24} sm={24} md={24}>
                          <Space>
                            <span
                              className={
                                step4YesNo.isOver16YearsOld === "yes"
                                  ? "text-muted"
                                  : ""
                              }
                            >
                              Do you have permission from your parents / legal
                              guardian to create this account?
                            </span>

                            <Form.Item
                              name="guardian_permission"
                              hasFeedback
                              className="m-b-sm"
                            >
                              <Radio.Group
                                value={step4YesNo.guardiaPermission}
                                onChange={(e) =>
                                  setStep4YesNo({
                                    ...step4YesNo,
                                    guardiaPermission: e.target.value,
                                  })
                                }
                                disabled={
                                  step4YesNo.isOver16YearsOld === "Yes"
                                    ? true
                                    : false
                                }
                              >
                                <Radio value="Yes">Yes</Radio>
                                <Radio value="No">No</Radio>
                              </Radio.Group>
                            </Form.Item>
                          </Space>
                        </Col>

                        {step4YesNo.isOver16YearsOld === "No" &&
                        step4YesNo.guardiaPermission === "No" ? (
                          <Col xs={24} sm={24} md={24}>
                            <Alert
                              className="m-t-sm"
                              type="error"
                              message="You have permission from your parents."
                            />
                          </Col>
                        ) : (
                          ""
                        )}

                        {step4YesNo.isOver16YearsOld === "Yes" ||
                        (step4YesNo.isOver16YearsOld === "No" &&
                          step4YesNo.guardiaPermission === "Yes") ? (
                          <>
                            <Col xs={24} sm={24} md={24}>
                              <Typography.Title
                                level={3}
                                className="m-t-md font-weight-normal w-100"
                              >
                                Credit Card Information
                              </Typography.Title>
                            </Col>
                            <Col xs={24} sm={24} md={24}>
                              <Form.Item
                                name="credit_card_name"
                                hasFeedback
                                className="w-100"
                              >
                                <FloatInput
                                  label="Name on Card"
                                  placeholder="Name on Card"
                                />
                              </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={24}>
                              <Form.Item
                                name="credit_card_number"
                                hasFeedback
                                className="w-100"
                                rules={[
                                  {
                                    required: true,
                                    message: "This field field is required.",
                                  },
                                ]}
                              >
                                <FloatInputMask
                                  label="Card Number"
                                  placeholder="Card Number"
                                  maskLabel="credit_card_number"
                                  // onChange={handleChangeCreditCardNumber}
                                  // value={creditCardNumber}
                                />
                              </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={12}>
                              <Form.Item
                                name="card_expiry"
                                hasFeedback
                                className="w-100"
                                rules={[
                                  {
                                    required: true,
                                    message: "This field field is required.",
                                  },
                                  // {
                                  //   pattern:
                                  //     /^(0[1-9]|1[0-2])\/?(([0-9]{4}|[0-9]{2})$)/,
                                  //   message: "Invalid Zip",
                                  // },
                                ]}
                              >
                                <FloatInputMask
                                  label="Expiration"
                                  placeholder="Expiration"
                                  maskLabel="card_expiry"
                                  maskType="99/99"
                                  // onChange={(e) => {
                                  //   if (e) {
                                  //     let val_last = e ? e.slice(-1) : "_";
                                  //     if (val_last !== "_") {
                                  //       let day = e.split("/")[1];
                                  //       if (day <= 31) {
                                  //         console.log("dateScheduleData", day);
                                  //       }
                                  //     }
                                  //   }
                                  // }}
                                />
                              </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={12}>
                              <Form.Item
                                name="credit_cvv"
                                hasFeedback
                                className="w-100"
                                rules={[
                                  {
                                    required: true,
                                    message: "This field field is required.",
                                  },
                                ]}
                              >
                                <FloatInputMask
                                  label="Security Code (CVV)"
                                  placeholder="Security Code (CVV)"
                                  maskLabel="cvv"
                                  maskType="999"
                                />
                              </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={24}>
                              <Typography.Title
                                level={3}
                                className="font-weight-normal w-100"
                              >
                                Billing Address
                              </Typography.Title>
                            </Col>
                            <Col xs={24} sm={24} md={24}>
                              <Form.Item
                                name="billing_street_address1"
                                hasFeedback
                                className="w-100"
                                rules={[
                                  {
                                    required: true,
                                    message: "This field field is required.",
                                  },
                                ]}
                              >
                                <FloatInput
                                  label="Street Address"
                                  placeholder="Street Address"
                                />
                              </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={24}>
                              <Form.Item
                                name="billing_street_address2"
                                hasFeedback
                                className="w-100"
                              >
                                <FloatInput
                                  label="Street Address 2"
                                  placeholder="Street Address 2"
                                />
                              </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={12}>
                              <Form.Item
                                name="billing_country"
                                hasFeedback
                                className="form-select-error w-100"
                                rules={[
                                  {
                                    required: true,
                                    message: "This field field is required.",
                                  },
                                ]}
                              >
                                <FloatSelect
                                  label="Country"
                                  placeholder="Country"
                                  options={optionCountryCodes}
                                  onChange={handleChangeBillingCountry}
                                />
                              </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={12}>
                              <Form.Item
                                name="billing_state"
                                hasFeedback
                                className="form-select-error w-100"
                                rules={[
                                  {
                                    required: true,
                                    message: "This field field is required.",
                                  },
                                ]}
                              >
                                <FloatSelect
                                  label={billingStateLabel}
                                  placeholder={billingStateLabel}
                                  options={optionBillingState}
                                  onChange={handleBillingState}
                                />
                              </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={12}>
                              <Form.Item
                                name="billing_city"
                                hasFeedback
                                className="form-select-error w-100"
                                rules={[
                                  {
                                    required: true,
                                    message: "This field city is required.",
                                  },
                                ]}
                              >
                                <FloatSelect
                                  label="City"
                                  placeholder="City"
                                  options={cityOption}
                                />
                              </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={12}>
                              <Form.Item
                                name="billing_zip"
                                hasFeedback
                                className="w-100"
                                rules={[
                                  {
                                    required: true,
                                    message: "This field zip is required.",
                                  },
                                  {
                                    pattern: optionBillingZip,
                                    message: "Invalid Zip",
                                  },
                                ]}
                              >
                                <FloatInput
                                  label={billingZipLabel}
                                  placeholder={billingZipLabel}
                                />
                              </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={24} className="captchaDiv">
                              <ReCAPTCHA
                                size={widthScreen < 372 ? "compact" : "normal"}
                                onChange={(token) => setCaptchaToken(token)}
                                className="captcha-registration m-t-n-xs"
                                // theme="dark"
                                render="explicit"
                                // render="explicit"
                                sitekey="6LfNfXgfAAAAAAbDCNuzI35Cm0hW_YwJ4UC0TYgw"
                                // onloadCallback={() => {}}
                              />
                            </Col>
                            <Col xs={24} sm={24} md={24}>
                              <Button
                                type="primary"
                                htmlType="submit"
                                loading={isLoadingRegister}
                                className="btn-primary-default m-t-sm"
                                block
                                size="large"
                                disabled={captchaToken ? false : true}
                              >
                                COMPLETE PURCHASE
                              </Button>
                            </Col>

                            {errorMessageStep4.message && (
                              <Col xs={24} sm={24} md={24}>
                                <Alert
                                  className="m-t-sm p-t-xs"
                                  type={errorMessageStep4.type}
                                  message={errorMessageStep4.message}
                                />
                                {/* <div className="forgot m-t-md">
																	<Link
																		type="link"
																		className="login-form-button companycolor1"
																		size="small"
																		to="#"
																	>
																		Need Help?
																	</Link>
																</div> */}
                              </Col>
                            )}
                          </>
                        ) : (
                          ""
                        )}
                      </Row>
                    </Form>
                  </Collapse.Panel>
                )}
              </Collapse>

              <div>
                <Typography.Text>
                  This page is protected by reCAPTCHA, and subject to the Google{" "}
                  <a
                    href="https://policies.google.com/privacy?hl=en"
                    className="companyColor3"
                    target="new"
                  >
                    Privacy Policy
                  </a>{" "}
                  and{" "}
                  <a
                    href="https://policies.google.com/terms?hl=en"
                    className="companyColor3"
                    target="new"
                  >
                    Terms of Services.
                  </a>
                </Typography.Text>
              </div>
            </Card>
          </Col>
        </Row>
      </Layout.Content>
      <Layout.Footer className="text-center">
        <Typography.Text>
          © Copyright {moment().format("YYYY")} {description}. All Rights
          Reserved..
        </Typography.Text>
      </Layout.Footer>
    </Layout>
  );
}
