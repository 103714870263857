import { Button, Form, Modal } from "antd";
import { useEffect } from "react";
import FloatInput from "../../../../providers/FloatInput";

export default function ModalSport(props) {
  const {
    toggleModalForm,
    setToggleModalForm,
    handleOnFinish,
    isLoadingCreateSport,
  } = props;

  const [form] = Form.useForm();

  useEffect(() => {
    if (toggleModalForm.data !== null) {
      form.setFieldsValue({ ...toggleModalForm.data });
    } else {
      form.resetFields();
    }

    return () => {};
  }, [toggleModalForm, form]);

  return (
    <Modal
      visible={toggleModalForm.show}
      width={600}
      title="Sport Form"
      onCancel={() => setToggleModalForm({ show: false, data: null })}
      footer={
        <>
          {/* <Button
						size="large"
						style={{ width: "33%" }}
						onClick={() => setToggleModalForm({ show: false, data: null })}
					>
						CLOSE
					</Button> */}

          <Button
            type="primary"
            loading={isLoadingCreateSport}
            className="btn-main-outline-2"
            size="large"
            style={{ width: "33%" }}
            onClick={() => form.submit()}
          >
            SUBMIT
          </Button>
        </>
      }
      className="dashboard-modal-event-preview"
    >
      <Form form={form} onFinish={handleOnFinish} autoComplete="off">
        <Form.Item
          name="sport"
          rules={[
            {
              required: true,
              message: "This field sport is required.",
            },
          ]}
        >
          <FloatInput label="Sport" placeholder="Sport" required />
        </Form.Item>
      </Form>
    </Modal>
  );
}
