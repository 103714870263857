import React, { useEffect, useState } from "react";
import {
  Card,
  Col,
  Row,
  Form,
  Collapse,
  message,
  Radio,
  Divider,
  notification,
} from "antd";
import ImgCrop from "antd-img-crop";
import { Upload } from "antd";
import ComponentHeader from "../../Components/ComponentHeader";
import FloatInput from "../../../../providers/FloatInput";

import { faEdit } from "@fortawesome/pro-regular-svg-icons";
import { GET, POST } from "../../../../providers/useAxiosQuery";

import companyInfo from "../../../../providers/companyInfo";
import ModalProfileChangePassword from "../../Components/ModalProfileChangePassword";

export default function PageProfileEdit() {
  const { Panel } = Collapse;
  const [form] = Form.useForm();
  const userdata = companyInfo().userData;
  const encryptor = companyInfo().encryptor;

  const [fileList, setFileList] = useState([]);
  const [radioData, setRadioData] = useState(1);
  const [imageCrop, setImageCrop] = useState({
    width: 1,
    height: 1,
  });

  const handleResize = (val) => {
    console.log("val", val.target.value);
    setRadioData(val.target.value);
    if (val.target.value === 1) {
      setImageCrop({
        width: 1,
        height: 1,
      });
    } else if (val.target.value === 2) {
      setImageCrop({
        width: 3.9,
        height: 2.6,
      });
    } else if (val.target.value === 3) {
      setImageCrop({
        width: 1,
        height: 1.5,
      });
    }
  };

  const onChange = ({ fileList: newFileList }) => {
    var _file = newFileList;
    console.log(_file);
    if (_file.length !== 0) {
      _file[0].status = "done";
      setFileList(_file);
      setFormData("upload");
    } else {
      setFileList([]);
    }
  };

  const onPreview = async (file) => {
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow.document.write(image.outerHTML);
  };

  const beforeUpload = (file) => {
    const isJpgOrPng =
      file.type === "image/jpeg" ||
      file.type === "image/png" ||
      file.type === "image/gif" ||
      file.type === "image/jpg";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG, PNG, GIF, JPEG file!");
      setFileList([]);
      return;
    }
    const isLt2M = file.size / 102400 / 102400 < 10;
    if (!isLt2M) {
      message.error("Image must smaller than 10MB!");
      setFileList([]);
      return;
    }
    return isJpgOrPng && isLt2M;
  };

  const [profileData, setProfileData] = useState([]);
  GET(`api/v1/users/${userdata.id}`, "update_profile", (res) => {
    if (res.success) {
      console.log("update_profile", Number(res.data.photo_crop));
      setProfileData(res.data);

      // let loc = "";
      if (res.data.city && res.data.state) {
        // loc = res.data.city + ", " + res.data.state;
      } else if (
        res.data.city &&
        res.data.state === "" &&
        res.data.state === undefined
      ) {
        // loc = res.data.city;
      } else if (
        res.data.state &&
        res.data.city === "" &&
        res.data.city === undefined
      ) {
        // loc = res.data.state;
      }

      if (Number(res.data.photo_crop) === 1) {
        setImageCrop({
          width: 1,
          height: 1,
        });
        setRadioData(1);
      } else if (Number(res.data.photo_crop) === 2) {
        setImageCrop({
          width: 3.9,
          height: 2.6,
        });
        setRadioData(2);
      } else if (Number(res.data.photo_crop) === 3) {
        setImageCrop({
          width: 1,
          height: 1.5,
        });
        setRadioData(3);
      } else {
        setImageCrop({
          width: 1,
          height: 1,
        });
        setRadioData(1);
      }

      form.setFieldsValue({
        username: res.data.username,
        email: res.data.email,
        role: res.data.role,
        //   address: loc,

        //   role: res.data.role,
        //   id: res.data.id,

        //   firstname: res.data.firstname,
        //   lastname: res.data.lastname,
        //   phone_number: res.data.phone_number,
      });

      if (res.data.user_address.length > 0) {
        let loc = "";
        if (res.data.user_address[0].city && res.data.user_address[0].state) {
          loc =
            res.data.user_address[0].city +
            ", " +
            res.data.user_address[0].state;
        } else if (
          res.data.user_address[0].city &&
          res.data.user_address[0].state === "" &&
          res.data.user_address[0].state === undefined
        ) {
          loc = res.data.user_address[0].city;
        } else if (
          res.data.user_address[0].state &&
          res.data.user_address[0].city === "" &&
          res.data.user_address[0].city === undefined
        ) {
          loc = res.data.user_address[0].state;
        }
        form.setFieldsValue({
          address: loc,
        });

        if (loc === "") {
          form.resetFields(["address"]);
        }
      }

      let image_type = res.data.profile_image
        ? res.data.profile_image.split("/")
        : "";
      if (image_type[0] !== undefined) {
        image_type =
          image_type === "https:"
            ? res.data.profile_image
            : companyInfo().apiUrl + res.data.profile_image;

        setFileList([
          {
            uid: "-1",
            name: "image.png",
            status: "done",
            url: image_type,
          },
        ]);
      } else {
        setFileList([]);
        image_type = "";
      }
    }
  });

  const [isChangePasswordVisible, setIsChangePasswordVisible] = useState(false);

  const { mutate: mudateUpdate } = POST(
    "api/v1/update_profile",
    "update_profile"
  );

  const onFinish = (values) => {
    // console.log("onFinish", values);
    const data = new FormData();
    data.append("id", userdata.id);
    data.append("photo_crop", radioData);
    if (fileList.length !== 0) {
      if (fileList[0].uid !== "-1") {
        data.append(
          "profile_image",
          fileList[0].originFileObj,
          fileList[0].name
        );
      }
    }

    mudateUpdate(data, {
      onSuccess: (res) => {
        if (res.success) {
          notification.success({
            message: "Success",
            description: "Successfully updated.",
          });
          localStorage.userdata = encryptor.encrypt(res.data);
        }
      },
    });
  };

  const [formData, setFormData] = useState("");
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (formData) {
        console.log("dataForm", formData);
        form.submit();
        setFormData("");
      }
    }, 5000);
    return () => {
      clearTimeout(timeoutId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData]);

  return (
    <>
      <ComponentHeader title="Profile" sub_title="EDIT" icon={faEdit} />

      <ModalProfileChangePassword
        toggleModalPreview={isChangePasswordVisible}
        setToggleModalPreview={setIsChangePasswordVisible}
        profileData={profileData}
      />

      <Card className="card-min-height" id="PageProfileEdit">
        <Form form={form} onFinish={onFinish}>
          <Row gutter={12}>
            <Col xs={24} sm={24} md={16}>
              <Collapse
                className="main-1-collapse"
                expandIcon={({ isActive }) =>
                  isActive ? (
                    <span
                      className="ant-menu-submenu-arrow"
                      style={{ color: "#FFF", transform: "rotate(270deg)" }}
                    ></span>
                  ) : (
                    <span
                      className="ant-menu-submenu-arrow"
                      style={{ color: "#FFF", transform: "rotate(90deg)" }}
                    ></span>
                  )
                }
                defaultActiveKey={["1"]}
                expandIconPosition="start"
              >
                <Panel
                  header="LOGIN INFORMATION"
                  key="1"
                  className="accordion bg-darkgray-form"
                >
                  <Row gutter={12}>
                    <Col xs={24} sm={24} md={24} lg={24}>
                      <Form.Item name="username">
                        <FloatInput
                          label="User Name"
                          placeholder="User Name"
                          disabled={true}
                          required
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={24}>
                      <Form.Item name="email">
                        <FloatInput
                          label="Email"
                          placeholder="Email"
                          disabled={true}
                          required
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  {/* <Row gutter={24}>
                    <Col xs={24} sm={24} md={24}>
                      <Button
                        type="link"
                        className="btn-link-main-3 btn-p-0"
                        onClick={(e) => setIsChangePasswordVisible(true)}
                      >
                        Change Password
                      </Button>
                    </Col>
                  </Row> */}
                </Panel>
              </Collapse>

              <br />
              <Collapse
                className="main-1-collapse"
                expandIcon={({ isActive }) =>
                  isActive ? (
                    <span
                      className="ant-menu-submenu-arrow"
                      style={{ color: "#FFF", transform: "rotate(270deg)" }}
                    ></span>
                  ) : (
                    <span
                      className="ant-menu-submenu-arrow"
                      style={{ color: "#FFF", transform: "rotate(90deg)" }}
                    ></span>
                  )
                }
                defaultActiveKey={["1a"]}
                expandIconPosition="start"
              >
                <Panel
                  header="ASSESSOR'S INFORMATION"
                  key="1a"
                  className="accordion bg-darkgray-form"
                >
                  <Row gutter={12}>
                    <Col xs={24} sm={24} md={24} lg={24}>
                      <Form.Item name="role">
                        <FloatInput
                          label="Role"
                          placeholder="Role"
                          disabled={true}
                          required
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={24}>
                      <Form.Item name="address">
                        <FloatInput
                          label="Location"
                          placeholder="Location"
                          disabled={true}
                          required
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Panel>
              </Collapse>
              <br />
            </Col>

            <Col xs={24} sm={24} md={8}>
              <Collapse
                className="main-1-collapse"
                expandIcon={({ isActive }) =>
                  isActive ? (
                    <span
                      className="ant-menu-submenu-arrow"
                      style={{ color: "#FFF", transform: "rotate(270deg)" }}
                    ></span>
                  ) : (
                    <span
                      className="ant-menu-submenu-arrow"
                      style={{ color: "#FFF", transform: "rotate(90deg)" }}
                    ></span>
                  )
                }
                defaultActiveKey={["1"]}
                expandIconPosition="start"
              >
                <Panel
                  header="USER'S INFORMATION"
                  key="1"
                  className="accordion bg-darkgray-form"
                >
                  <label className="font-red">
                    <b>
                      Photo upload & cropping: select your image orientation
                    </b>
                  </label>
                  <br />
                  <Radio.Group onChange={handleResize} value={radioData}>
                    <Radio value={1}>Square</Radio>
                    <Radio value={2}>Rectangle</Radio>
                    <Radio value={3}>Portrait</Radio>
                  </Radio.Group>
                  <Divider />
                  <div style={{ display: "flex" }}>
                    <ImgCrop rotate aspect={imageCrop.width / imageCrop.height}>
                      <Upload
                        // action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                        listType="picture-card"
                        style={{ width: "200px" }}
                        maxCount={1}
                        action={false}
                        customRequest={false}
                        fileList={fileList}
                        onChange={onChange}
                        onPreview={onPreview}
                        beforeUpload={beforeUpload}
                        className="profilePhoto"
                        showUploadList={{
                          showPreviewIcon: false,
                        }}
                      >
                        {fileList.length < 1 && "+ Upload"}
                      </Upload>
                    </ImgCrop>
                  </div>
                </Panel>
              </Collapse>
            </Col>
          </Row>

          {/* <Row gutter={12}>
            <Col xs={24} sm={24} md={8}>
              <br />
              <Button
                htmlType="submit"
                className="btn-main-outline-2"
                size="large"
                loading={isLoadingUpdate}
              >
                <FontAwesomeIcon icon={faSave} /> &nbsp; SAVE ALL CHANGES
              </Button>
            </Col>
          </Row> */}
        </Form>
        <br />
      </Card>
    </>
  );
}
