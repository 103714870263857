import { Checkbox, Collapse, Form, notification, Typography } from "antd";
import { useEffect } from "react";
import { POST } from "../../../../../providers/useAxiosQuery";

export default function FormPdfElite(props) {
	const { assessment_id, dataSource } = props;

	const [form] = Form.useForm();
	const initialValues = {
		p3_cmj_rsi_no: true,
		p3_es_height: true,
		p3_sj_height: true,
		p3_sprint_time_tem_meter: true,
		p3_isometric_pull: true,
		p3_age_avg_cmj_rsi_no_sum: true,
		p3_age_avg_es_height_sum: true,
		p3_age_avg_sj_height_sum: true,
		p3_age_avg_sprint_time_tem_meter_sum: true,
		p3_age_avg_isometric_pull: true,
		p3_average_1: true,
		p3_average_2: true,
		p3_average_3: true,
		p3_average_4: true,
		p3_average_5: true,
		p4_spm_power: true,
		p4_rm_power: true,
		p4_se_pull_ups: true,
		p4_se_chin_ups: true,
		p4_cod_power: true,
		p4_age_avg_spm_power_sum: true,
		p4_age_avg_rm_power_sum: true,
		p4_age_avg_se_pull_ups_sum: true,
		p4_age_avg_se_chin_ups_sum: true,
		p4_age_avg_cod_power_sum: true,
		p4_average_1: true,
		p4_average_2: true,
		p4_average_3: true,
		p4_average_4: true,
		p4_average_5: true,
		p6_cmj_rsi_no: true,
		p6_es_height: true,
		p6_ground_contact_time: true,
		p8_cmj_jump_height: true,
		p8_avg_cmj_jump_height: true,
		p8_es_peak_power: true,
		p8_cmj_relative_avg_power: true,
		p10_sj_height: true,
		p10_age_avg_sj_height_sum: true,
		p10_sj_peak_power: true,
		p10_relative_sj_peak_power: true,
		p13_sprint_time_tem_meter: true,
		p13_age_avg_sprint_time_tem_meter_sum: true,
		p13_sprint_time_four_meter: true,
		p13_age_avg_sprint_time_four_meter_sum: true,
		p15_weight: true,
		p15_ms_peak_force: true,
		p15_ms_peak_force_lbs: true,
		p15_age_avg_isometric_pull_n: true,
		p15_age_avg_isometric_pull: true,
		p15_relative_stregth_pf_bw: true,
		p15_relative_stregth_pf_bw_avg_per: true,
		p16_ms_peak_force_lbs: true,
		p16_weight: true,
		p16_relative_stregth_pf_bw_lbs: true,
		p16_ms_rfd_lbs: true,
		p16_RFD_percent_of_peak_force_lbs: true,
		p16_2_peak_force_low: true,
		p16_2_peak_force_average: true,
		p16_2_peak_force_high: true,
		p17_ms_peak_force: true,
		p17_es_peak_propulsive_force: true,
		p17_dsi_score: true,
		p19_es_peak_propulsive_force: true,
		p19_es_peak_propulsive_force_sum_avg: true,
		p19_relative_peak_power: true,
		p19_relative_peak_power_avg: true,
		p21_rm_power: true,
		p21_age_avg_rm_power_sum: true,
		p23_se_chin_ups: true,
		p23_age_avg_se_chin_ups_sum: true,
		p23_se_pull_ups: true,
		p23_age_avg_se_pull_ups_sum: true,
		p25_cod_power: true,
		p25_age_avg_cod_power_sum: true,
		p26_cmj_rsi_no: true,
		p26_dsi_score: true,
		p26_eur: true,
		p26_ms_peak_force: true,
		p26_age_avg_cmj_rsi_no_sum: true,
		p26_dsi_score_avg_tot: true,
		p26_avg_eccentric_utilization_ratio: true,
		p26_avg_relative_strength: true,
		p26_average_1: true,
		p26_average_2: true,
		p26_average_3: true,
		p26_average_4: true,
	};

	const {
		mutate: mutatePdfDisplayData,
		// isLoading: isLoadingUpdateAthleteUser,
	} = POST("api/v1/athlete_pdf_display_data", "athlete_pdf_display_data_elite");

	const handleForm = (values) => {
		console.log("handleForm", values);

		let data = [];

		Object.keys(values).map(function (key) {
			if (values[key] !== undefined) {
				if (values[key]) {
					let objKeyVal = {};
					objKeyVal[key] = "1";
					data.push(objKeyVal);
				} else {
					let objKeyVal = {};
					objKeyVal[key] = "0";
					data.push(objKeyVal);
				}
			} else {
				let objKeyVal = {};
				objKeyVal[key] = "1";
				data.push(objKeyVal);
			}

			return "";
		});

		console.log("data", data);

		mutatePdfDisplayData(
			{ assessment_id, data },
			{
				onSuccess: (res) => {
					if (res.success) {
						notification.success({
							message: res.message,
							description: res.description,
						});
					} else {
						notification.error({
							message: "Error",
							description: "Data not updated!",
						});
					}
				},
				onError: (err) => {
					notification.error({
						message: "Athlete PDF Display Data",
						description: err,
					});
				},
			}
		);
	};

	const handleChangeCheckbox = () => {
		form.submit();
	};

	useEffect(() => {
		// console.log("dataSource", dataSource);

		if (dataSource) {
			dataSource.map((item) => {
				form.setFieldsValue({ [item.name]: item.status ? true : false });
				// console.log(item.name, item.status ? true : false);
				return "";
			});
		}

		return () => {};
	}, [form, dataSource]);

	return (
		<Form form={form} onFinish={handleForm} initialValues={initialValues}>
			<Collapse
				className="main-1-collapse border-none"
				expandIcon={({ isActive }) =>
					isActive ? (
						<span
							className="ant-menu-submenu-arrow"
							style={{ color: "#FFF", transform: "rotate(270deg)" }}
						></span>
					) : (
						<span
							className="ant-menu-submenu-arrow"
							style={{ color: "#FFF", transform: "rotate(90deg)" }}
						></span>
					)
				}
				defaultActiveKey={["1"]}
				expandIconPosition="start"
			>
				<Collapse.Panel
					header="PAGE 3"
					key="1"
					className="accordion bg-darkgray-form m-b-md border"
				>
					<div className="table-responsive">
						<table className="table">
							<thead>
								<tr>
									<th>BFS TESTING: PERFORMANCE TESTS</th>
									<th>REBOUND JUMP (RSI)</th>
									<th>COUNTERMOVEMENT JUMP (IN)</th>
									<th>SQUAT JUMP (IN)</th>
									<th>10YD SPRINT (SECS)</th>
									<th>ISOMETRIC MID-THIGH PULL (LBS)</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<th>YOU</th>
									<td>
										<Form.Item valuePropName="checked" name="p3_cmj_rsi_no">
											<Checkbox onChange={handleChangeCheckbox} />
										</Form.Item>
									</td>
									<td>
										<Form.Item valuePropName="checked" name="p3_es_height">
											<Checkbox onChange={handleChangeCheckbox} />
										</Form.Item>
									</td>
									<td>
										<Form.Item valuePropName="checked" name="p3_sj_height">
											<Checkbox onChange={handleChangeCheckbox} />
										</Form.Item>
									</td>
									<td>
										<Form.Item
											valuePropName="checked"
											name="p3_sprint_time_tem_meter"
										>
											<Checkbox onChange={handleChangeCheckbox} />
										</Form.Item>
									</td>
									<td>
										<Form.Item valuePropName="checked" name="p3_isometric_pull">
											<Checkbox onChange={handleChangeCheckbox} />
										</Form.Item>
									</td>
								</tr>
								<tr>
									<th>Performers Avg (10 yrs old)</th>
									<td>
										<Form.Item
											valuePropName="checked"
											name="p3_age_avg_cmj_rsi_no_sum"
										>
											<Checkbox onChange={handleChangeCheckbox} />
										</Form.Item>
									</td>
									<td>
										<Form.Item
											valuePropName="checked"
											name="p3_age_avg_es_height_sum"
										>
											<Checkbox onChange={handleChangeCheckbox} />
										</Form.Item>
									</td>
									<td>
										<Form.Item
											valuePropName="checked"
											name="p3_age_avg_sj_height_sum"
										>
											<Checkbox onChange={handleChangeCheckbox} />
										</Form.Item>
									</td>
									<td>
										<Form.Item
											valuePropName="checked"
											name="p3_age_avg_sprint_time_tem_meter_sum"
										>
											<Checkbox onChange={handleChangeCheckbox} />
										</Form.Item>
									</td>
									<td>
										<Form.Item
											valuePropName="checked"
											name="p3_age_avg_isometric_pull"
										>
											<Checkbox onChange={handleChangeCheckbox} />
										</Form.Item>
									</td>
								</tr>
								<tr>
									<th>Your Benchmark</th>
									<td>
										<Form.Item valuePropName="checked" name="p3_average_1">
											<Checkbox onChange={handleChangeCheckbox} />
										</Form.Item>
									</td>
									<td>
										<Form.Item valuePropName="checked" name="p3_average_2">
											<Checkbox onChange={handleChangeCheckbox} />
										</Form.Item>
									</td>
									<td>
										<Form.Item valuePropName="checked" name="p3_average_3">
											<Checkbox onChange={handleChangeCheckbox} />
										</Form.Item>
									</td>
									<td>
										<Form.Item valuePropName="checked" name="p3_average_4">
											<Checkbox onChange={handleChangeCheckbox} />
										</Form.Item>
									</td>
									<td>
										<Form.Item valuePropName="checked" name="p3_average_5">
											<Checkbox onChange={handleChangeCheckbox} />
										</Form.Item>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</Collapse.Panel>
			</Collapse>

			<Collapse
				className="main-1-collapse border-none"
				expandIcon={({ isActive }) =>
					isActive ? (
						<span
							className="ant-menu-submenu-arrow"
							style={{ color: "#FFF", transform: "rotate(270deg)" }}
						></span>
					) : (
						<span
							className="ant-menu-submenu-arrow"
							style={{ color: "#FFF", transform: "rotate(90deg)" }}
						></span>
					)
				}
				defaultActiveKey={["1"]}
				expandIconPosition="start"
			>
				<Collapse.Panel
					header="PAGE 4"
					key="1"
					className="accordion bg-darkgray-form m-b-md border"
				>
					<div className="table-responsive">
						<table className="table">
							<thead>
								<tr>
									<th>BFS Testing: Performance Tests</th>
									<th>Plyometric MED BALL THROW (W)</th>
									<th>ROTATIONAL MED BALL THROW (W)</th>
									<th>PUSH-UPS (#)</th>
									<th>Chin Ups (#)</th>
									<th>5-10-5 Agility Test (Secs)</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<th>YOU</th>
									<td>
										<Form.Item valuePropName="checked" name="p4_spm_power">
											<Checkbox onChange={handleChangeCheckbox} />
										</Form.Item>
									</td>
									<td>
										<Form.Item valuePropName="checked" name="p4_rm_power">
											<Checkbox onChange={handleChangeCheckbox} />
										</Form.Item>
									</td>
									<td>
										<Form.Item valuePropName="checked" name="p4_se_pull_ups">
											<Checkbox onChange={handleChangeCheckbox} />
										</Form.Item>
									</td>
									<td>
										<Form.Item valuePropName="checked" name="p4_se_chin_ups">
											<Checkbox onChange={handleChangeCheckbox} />
										</Form.Item>
									</td>
									<td>
										<Form.Item valuePropName="checked" name="p4_cod_power">
											<Checkbox onChange={handleChangeCheckbox} />
										</Form.Item>
									</td>
								</tr>
								<tr>
									<th>Performers Avg (10 yrs old)</th>
									<td>
										<Form.Item
											valuePropName="checked"
											name="p4_age_avg_spm_power_sum"
										>
											<Checkbox onChange={handleChangeCheckbox} />
										</Form.Item>
									</td>
									<td>
										<Form.Item
											valuePropName="checked"
											name="p4_age_avg_rm_power_sum"
										>
											<Checkbox onChange={handleChangeCheckbox} />
										</Form.Item>
									</td>
									<td>
										<Form.Item
											valuePropName="checked"
											name="p4_age_avg_se_pull_ups_sum"
										>
											<Checkbox onChange={handleChangeCheckbox} />
										</Form.Item>
									</td>
									<td>
										<Form.Item
											valuePropName="checked"
											name="p4_age_avg_se_chin_ups_sum"
										>
											<Checkbox onChange={handleChangeCheckbox} />
										</Form.Item>
									</td>
									<td>
										<Form.Item
											valuePropName="checked"
											name="p4_age_avg_cod_power_sum"
										>
											<Checkbox onChange={handleChangeCheckbox} />
										</Form.Item>
									</td>
								</tr>
								<tr>
									<th>Your Benchmark</th>
									<td>
										<Form.Item valuePropName="checked" name="p4_average_1">
											<Checkbox onChange={handleChangeCheckbox} />
										</Form.Item>
									</td>
									<td>
										<Form.Item valuePropName="checked" name="p4_average_2">
											<Checkbox onChange={handleChangeCheckbox} />
										</Form.Item>
									</td>
									<td>
										<Form.Item valuePropName="checked" name="p4_average_3">
											<Checkbox onChange={handleChangeCheckbox} />
										</Form.Item>
									</td>
									<td>
										<Form.Item valuePropName="checked" name="p4_average_4">
											<Checkbox onChange={handleChangeCheckbox} />
										</Form.Item>
									</td>
									<td>
										<Form.Item valuePropName="checked" name="p4_average_5">
											<Checkbox onChange={handleChangeCheckbox} />
										</Form.Item>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</Collapse.Panel>
			</Collapse>

			<Collapse
				className="main-1-collapse border-none"
				expandIcon={({ isActive }) =>
					isActive ? (
						<span
							className="ant-menu-submenu-arrow"
							style={{ color: "#FFF", transform: "rotate(270deg)" }}
						></span>
					) : (
						<span
							className="ant-menu-submenu-arrow"
							style={{ color: "#FFF", transform: "rotate(90deg)" }}
						></span>
					)
				}
				defaultActiveKey={["1"]}
				expandIconPosition="start"
			>
				<Collapse.Panel
					header="PAGE 6"
					key="1"
					className="accordion bg-darkgray-form m-b-md border"
				>
					<div className="table-responsive">
						<table className="table">
							<thead>
								<tr>
									<th colSpan={3}>Results: Rebound Jump Test</th>
								</tr>
								<tr>
									<th>Your RSI Score </th>
									<th>Jump Height (in)</th>
									<th>Ground Contact Time (ms)</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>
										<Form.Item valuePropName="checked" name="p6_cmj_rsi_no">
											<Checkbox onChange={handleChangeCheckbox} />
										</Form.Item>
									</td>
									<td>
										<Form.Item valuePropName="checked" name="p6_es_height">
											<Checkbox onChange={handleChangeCheckbox} />
										</Form.Item>
									</td>
									<td>
										<Form.Item
											valuePropName="checked"
											name="p6_ground_contact_time"
										>
											<Checkbox onChange={handleChangeCheckbox} />
										</Form.Item>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</Collapse.Panel>
			</Collapse>

			<Collapse
				className="main-1-collapse border-none"
				expandIcon={({ isActive }) =>
					isActive ? (
						<span
							className="ant-menu-submenu-arrow"
							style={{ color: "#FFF", transform: "rotate(270deg)" }}
						></span>
					) : (
						<span
							className="ant-menu-submenu-arrow"
							style={{ color: "#FFF", transform: "rotate(90deg)" }}
						></span>
					)
				}
				defaultActiveKey={["1"]}
				expandIconPosition="start"
			>
				<Collapse.Panel
					header="PAGE 8"
					key="1"
					className="accordion bg-darkgray-form m-b-md border"
				>
					<div className="table-responsive">
						<table className="table">
							<thead>
								<tr>
									<th colSpan={3}>Results: Counter-Movement Jump Test</th>
								</tr>
								<tr>
									<th>Measurement </th>
									<th>You</th>
									<th>Average Performers</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>CMJ Height (in)</td>
									<td>
										<Form.Item
											valuePropName="checked"
											name="p8_cmj_jump_height"
										>
											<Checkbox onChange={handleChangeCheckbox} />
										</Form.Item>
									</td>
									<td>
										<Form.Item
											valuePropName="checked"
											name="p8_avg_cmj_jump_height"
										>
											<Checkbox onChange={handleChangeCheckbox} />
										</Form.Item>
									</td>
								</tr>
								<tr>
									<td>Relative Avg Power (W/kg)</td>
									<td>
										<Form.Item valuePropName="checked" name="p8_es_peak_power">
											<Checkbox onChange={handleChangeCheckbox} />
										</Form.Item>
									</td>
									<td>
										<Form.Item
											valuePropName="checked"
											name="p8_cmj_relative_avg_power"
										>
											<Checkbox onChange={handleChangeCheckbox} />
										</Form.Item>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</Collapse.Panel>
			</Collapse>

			<Collapse
				className="main-1-collapse border-none"
				expandIcon={({ isActive }) =>
					isActive ? (
						<span
							className="ant-menu-submenu-arrow"
							style={{ color: "#FFF", transform: "rotate(270deg)" }}
						></span>
					) : (
						<span
							className="ant-menu-submenu-arrow"
							style={{ color: "#FFF", transform: "rotate(90deg)" }}
						></span>
					)
				}
				defaultActiveKey={["1"]}
				expandIconPosition="start"
			>
				<Collapse.Panel
					header="PAGE 10"
					key="1"
					className="accordion bg-darkgray-form m-b-md border"
				>
					<div className="table-responsive">
						<table className="table">
							<thead>
								<tr>
									<th colSpan={3}>Results: Squat Jump Test</th>
								</tr>
								<tr>
									<th>Measurement </th>
									<th>You</th>
									<th>Average Performers</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>Jump Height (in)</td>
									<td>
										<Form.Item valuePropName="checked" name="p10_sj_height">
											<Checkbox onChange={handleChangeCheckbox} />
										</Form.Item>
									</td>
									<td>
										<Form.Item
											valuePropName="checked"
											name="p10_age_avg_sj_height_sum"
										>
											<Checkbox onChange={handleChangeCheckbox} />
										</Form.Item>
									</td>
								</tr>
								<tr>
									<td>Relative Peak Power (W/kg)</td>
									<td>
										<Form.Item valuePropName="checked" name="p10_sj_peak_power">
											<Checkbox onChange={handleChangeCheckbox} />
										</Form.Item>
									</td>
									<td>
										<Form.Item
											valuePropName="checked"
											name="p10_relative_sj_peak_power"
										>
											<Checkbox onChange={handleChangeCheckbox} />
										</Form.Item>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</Collapse.Panel>
			</Collapse>

			<Collapse
				className="main-1-collapse border-none"
				expandIcon={({ isActive }) =>
					isActive ? (
						<span
							className="ant-menu-submenu-arrow"
							style={{ color: "#FFF", transform: "rotate(270deg)" }}
						></span>
					) : (
						<span
							className="ant-menu-submenu-arrow"
							style={{ color: "#FFF", transform: "rotate(90deg)" }}
						></span>
					)
				}
				defaultActiveKey={["1"]}
				expandIconPosition="start"
			>
				<Collapse.Panel
					header="PAGE 13"
					key="1"
					className="accordion bg-darkgray-form m-b-md border"
				>
					<div className="table-responsive">
						<table className="table">
							<thead>
								<tr>
									<th colSpan={4}>Results: 10YD/40YD sprint Test</th>
								</tr>
								<tr>
									<th>Measurement </th>
									<th>You</th>
									<th>Average Performers</th>
									<th>Training Recommendation</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>10YD Sprint (sec)</td>
									<td>
										<Form.Item
											valuePropName="checked"
											name="p13_sprint_time_tem_meter"
										>
											<Checkbox onChange={handleChangeCheckbox} />
										</Form.Item>
									</td>
									<td>
										<Form.Item
											valuePropName="checked"
											name="p13_age_avg_sprint_time_tem_meter_sum"
										>
											<Checkbox onChange={handleChangeCheckbox} />
										</Form.Item>
									</td>
									<td>
										Maximal strength, Horizontal force development and ankle
										stiffness.
									</td>
								</tr>
								<tr>
									<td>40YD Sprint (sec)</td>
									<td>
										<Form.Item
											valuePropName="checked"
											name="p13_sprint_time_four_meter"
										>
											<Checkbox onChange={handleChangeCheckbox} />
										</Form.Item>
									</td>
									<td>
										<Form.Item
											valuePropName="checked"
											name="p13_age_avg_sprint_time_four_meter_sum"
										>
											<Checkbox onChange={handleChangeCheckbox} />
										</Form.Item>
									</td>
									<td>
										Comprehensive training including maximal strength, ballistic
										strength and plyometrics
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</Collapse.Panel>
			</Collapse>

			<Collapse
				className="main-1-collapse border-none"
				expandIcon={({ isActive }) =>
					isActive ? (
						<span
							className="ant-menu-submenu-arrow"
							style={{ color: "#FFF", transform: "rotate(270deg)" }}
						></span>
					) : (
						<span
							className="ant-menu-submenu-arrow"
							style={{ color: "#FFF", transform: "rotate(90deg)" }}
						></span>
					)
				}
				defaultActiveKey={["1"]}
				expandIconPosition="start"
			>
				<Collapse.Panel
					header="PAGE 15"
					key="1"
					className="accordion bg-darkgray-form m-b-md border"
				>
					<Typography.Title level={4} className="text-center">
						Results: Isometric Mid-thigh Pull Test (IMTP)
					</Typography.Title>
					<div className="table-responsive">
						<table className="table m-t-md">
							<thead>
								<tr>
									<th>Your Weight</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>
										<Form.Item valuePropName="checked" name="p15_weight">
											<Checkbox onChange={handleChangeCheckbox} />
										</Form.Item>
									</td>
								</tr>
							</tbody>
						</table>
						<table className="table m-t-md">
							<thead>
								<tr>
									<th>Measurement </th>
									<th>You (N)</th>
									<th>You (lbs)</th>
									<th>Average Performers</th>
									<th>Average Performers</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>Absolute Strength</td>
									<td>
										<Form.Item valuePropName="checked" name="p15_ms_peak_force">
											<Checkbox onChange={handleChangeCheckbox} />
										</Form.Item>
									</td>
									<td>
										<Form.Item
											valuePropName="checked"
											name="p15_ms_peak_force_lbs"
										>
											<Checkbox onChange={handleChangeCheckbox} />
										</Form.Item>
									</td>
									<td>
										<Form.Item
											valuePropName="checked"
											name="p15_age_avg_isometric_pull_n"
										>
											<Checkbox onChange={handleChangeCheckbox} />
										</Form.Item>
									</td>
									<td>
										<Form.Item
											valuePropName="checked"
											name="p15_age_avg_isometric_pull"
										>
											<Checkbox onChange={handleChangeCheckbox} />
										</Form.Item>
									</td>
								</tr>
							</tbody>
						</table>
						<table className="table m-t-md">
							<thead>
								<tr>
									<th>Measurement </th>
									<th>You</th>
									<th>Average Performers</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>Relative Strength Peak Force/Body Weight</td>
									<td>
										<Form.Item
											valuePropName="checked"
											name="p15_relative_stregth_pf_bw"
										>
											<Checkbox onChange={handleChangeCheckbox} />
										</Form.Item>
									</td>
									<td>
										<Form.Item
											valuePropName="checked"
											name="p15_relative_stregth_pf_bw_avg_per"
										>
											<Checkbox onChange={handleChangeCheckbox} />
										</Form.Item>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</Collapse.Panel>
			</Collapse>

			<Collapse
				className="main-1-collapse border-none"
				expandIcon={({ isActive }) =>
					isActive ? (
						<span
							className="ant-menu-submenu-arrow"
							style={{ color: "#FFF", transform: "rotate(270deg)" }}
						></span>
					) : (
						<span
							className="ant-menu-submenu-arrow"
							style={{ color: "#FFF", transform: "rotate(90deg)" }}
						></span>
					)
				}
				defaultActiveKey={["1"]}
				expandIconPosition="start"
			>
				<Collapse.Panel
					header="PAGE 16"
					key="1"
					className="accordion bg-darkgray-form m-b-md border"
				>
					<Typography.Title level={4} className="text-center">
						Rate of Force Development (RFD)
					</Typography.Title>
					<div className="table-responsive">
						<table className="table m-t-md">
							<thead>
								<tr>
									<th>Peak Force (lbs)</th>
									<th>Your Weight (lbs)</th>
									<th>Peak Force/Body Weight</th>
									<th>Force (lbs) @ 0-100ms</th>
									<th>0-100ms RFD % of Peak Force</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>
										<Form.Item
											valuePropName="checked"
											name="p16_ms_peak_force_lbs"
										>
											<Checkbox onChange={handleChangeCheckbox} />
										</Form.Item>
									</td>
									<td>
										<Form.Item valuePropName="checked" name="p16_weight">
											<Checkbox onChange={handleChangeCheckbox} />
										</Form.Item>
									</td>
									<td>
										<Form.Item
											valuePropName="checked"
											name="p16_relative_stregth_pf_bw_lbs"
										>
											<Checkbox onChange={handleChangeCheckbox} />
										</Form.Item>
									</td>
									<td>
										<Form.Item valuePropName="checked" name="p16_ms_rfd_lbs">
											<Checkbox onChange={handleChangeCheckbox} />
										</Form.Item>
									</td>
									<td>
										<Form.Item
											valuePropName="checked"
											name="p16_RFD_percent_of_peak_force_lbs"
										>
											<Checkbox onChange={handleChangeCheckbox} />
										</Form.Item>
									</td>
								</tr>
							</tbody>
						</table>
						<table className="table m-t-md">
							<thead>
								<tr>
									<th>Peak Force</th>
									<th>Rank</th>
									<th>Training Emphasis Recommendations</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>
										<Form.Item
											valuePropName="checked"
											name="p16_2_peak_force_low"
										>
											<Checkbox onChange={handleChangeCheckbox} />
										</Form.Item>
									</td>
									<td>
										<Form.Item
											valuePropName="checked"
											name="p16_2_peak_force_average"
										>
											<Checkbox onChange={handleChangeCheckbox} />
										</Form.Item>
									</td>
									<td>
										<Form.Item
											valuePropName="checked"
											name="p16_2_peak_force_high"
										>
											<Checkbox onChange={handleChangeCheckbox} />
										</Form.Item>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</Collapse.Panel>
			</Collapse>

			<Collapse
				className="main-1-collapse border-none"
				expandIcon={({ isActive }) =>
					isActive ? (
						<span
							className="ant-menu-submenu-arrow"
							style={{ color: "#FFF", transform: "rotate(270deg)" }}
						></span>
					) : (
						<span
							className="ant-menu-submenu-arrow"
							style={{ color: "#FFF", transform: "rotate(90deg)" }}
						></span>
					)
				}
				defaultActiveKey={["1"]}
				expandIconPosition="start"
			>
				<Collapse.Panel
					header="PAGE 17"
					key="1"
					className="accordion bg-darkgray-form m-b-md border"
				>
					<Typography.Title level={4} className="text-center">
						Dynamic Strength Index (DSI)
					</Typography.Title>

					<div className="table-responsive">
						<table className="table m-t-md">
							<thead>
								<tr>
									<th>IMTP Peak Force</th>
									<th>CMJ Peak Force</th>
									<th>Your DSI Score</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>
										<Form.Item valuePropName="checked" name="p17_ms_peak_force">
											<Checkbox onChange={handleChangeCheckbox} />
										</Form.Item>
									</td>
									<td>
										<Form.Item
											valuePropName="checked"
											name="p17_es_peak_propulsive_force"
										>
											<Checkbox onChange={handleChangeCheckbox} />
										</Form.Item>
									</td>
									<td>
										<Form.Item valuePropName="checked" name="p17_dsi_score">
											<Checkbox onChange={handleChangeCheckbox} />
										</Form.Item>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</Collapse.Panel>
			</Collapse>

			<Collapse
				className="main-1-collapse border-none"
				expandIcon={({ isActive }) =>
					isActive ? (
						<span
							className="ant-menu-submenu-arrow"
							style={{ color: "#FFF", transform: "rotate(270deg)" }}
						></span>
					) : (
						<span
							className="ant-menu-submenu-arrow"
							style={{ color: "#FFF", transform: "rotate(90deg)" }}
						></span>
					)
				}
				defaultActiveKey={["1"]}
				expandIconPosition="start"
			>
				<Collapse.Panel
					header="PAGE 19"
					key="1"
					className="accordion bg-darkgray-form m-b-md border"
				>
					<Typography.Title level={4} className="text-center">
						Results: Supine Med Ball Throw test
					</Typography.Title>

					<div className="table-responsive">
						<table className="table m-t-md">
							<thead>
								<tr>
									<th>Measurement</th>
									<th>You</th>
									<th>Average Performers</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>Peak Propulsive Power</td>
									<td>
										<Form.Item
											valuePropName="checked"
											name="p19_es_peak_propulsive_force"
										>
											<Checkbox onChange={handleChangeCheckbox} />
										</Form.Item>
									</td>
									<td>
										<Form.Item
											valuePropName="checked"
											name="p19_es_peak_propulsive_force_sum_avg"
										>
											<Checkbox onChange={handleChangeCheckbox} />
										</Form.Item>
									</td>
								</tr>
								<tr>
									<td>Relative Peak Power (W/lb)</td>
									<td>
										<Form.Item
											valuePropName="checked"
											name="p19_relative_peak_power"
										>
											<Checkbox onChange={handleChangeCheckbox} />
										</Form.Item>
									</td>
									<td>
										<Form.Item
											valuePropName="checked"
											name="p19_relative_peak_power_avg"
										>
											<Checkbox onChange={handleChangeCheckbox} />
										</Form.Item>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</Collapse.Panel>
			</Collapse>

			<Collapse
				className="main-1-collapse border-none"
				expandIcon={({ isActive }) =>
					isActive ? (
						<span
							className="ant-menu-submenu-arrow"
							style={{ color: "#FFF", transform: "rotate(270deg)" }}
						></span>
					) : (
						<span
							className="ant-menu-submenu-arrow"
							style={{ color: "#FFF", transform: "rotate(90deg)" }}
						></span>
					)
				}
				defaultActiveKey={["1"]}
				expandIconPosition="start"
			>
				<Collapse.Panel
					header="PAGE 21"
					key="1"
					className="accordion bg-darkgray-form m-b-md border"
				>
					<Typography.Title level={4} className="text-center">
						Results: Rotational med Ball Throw Test
					</Typography.Title>

					<div className="table-responsive">
						<table className="table m-t-md">
							<thead>
								<tr>
									<th>Measurement</th>
									<th>You</th>
									<th>Average Performers</th>
									<th>Training Recommendations</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>Peak Rotational Power (W)</td>
									<td>
										<Form.Item valuePropName="checked" name="p21_rm_power">
											<Checkbox onChange={handleChangeCheckbox} />
										</Form.Item>
									</td>
									<td>
										<Form.Item
											valuePropName="checked"
											name="p21_age_avg_rm_power_sum"
										>
											<Checkbox onChange={handleChangeCheckbox} />
										</Form.Item>
									</td>
									<td>Various Med Ball Throws</td>
								</tr>
							</tbody>
						</table>
					</div>
				</Collapse.Panel>
			</Collapse>

			<Collapse
				className="main-1-collapse border-none"
				expandIcon={({ isActive }) =>
					isActive ? (
						<span
							className="ant-menu-submenu-arrow"
							style={{ color: "#FFF", transform: "rotate(270deg)" }}
						></span>
					) : (
						<span
							className="ant-menu-submenu-arrow"
							style={{ color: "#FFF", transform: "rotate(90deg)" }}
						></span>
					)
				}
				defaultActiveKey={["1"]}
				expandIconPosition="start"
			>
				<Collapse.Panel
					header="PAGE 23"
					key="1"
					className="accordion bg-darkgray-form m-b-md border"
				>
					<Typography.Title level={4} className="text-center">
						Results: Strength Endurance Testing
					</Typography.Title>

					<div className="table-responsive">
						<table className="table m-t-md">
							<thead>
								<tr>
									<th>Test Measurement</th>
									<th>Your Repetitions</th>
									<th>Average Performers Repetitions</th>
									<th>Training Recommendations</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>Chin Ups</td>
									<td>
										<Form.Item valuePropName="checked" name="p23_se_chin_ups">
											<Checkbox onChange={handleChangeCheckbox} />
										</Form.Item>
									</td>
									<td>
										<Form.Item
											valuePropName="checked"
											name="p23_age_avg_se_chin_ups_sum"
										>
											<Checkbox onChange={handleChangeCheckbox} />
										</Form.Item>
									</td>
									<td>
										Maximal Pulling Strength Training Increased Volume w/
										Reduced Rest between Sets. Density Training
									</td>
								</tr>
								<tr>
									<td>PUSH-UPS</td>
									<td>
										<Form.Item valuePropName="checked" name="p23_se_pull_ups">
											<Checkbox onChange={handleChangeCheckbox} />
										</Form.Item>
									</td>
									<td>
										<Form.Item
											valuePropName="checked"
											name="p23_age_avg_se_pull_ups_sum"
										>
											<Checkbox onChange={handleChangeCheckbox} />
										</Form.Item>
									</td>
									<td>
										Maximal Pressing Strength Training Increased Volume w/
										Reduce Rest between Sets. Density Training
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</Collapse.Panel>
			</Collapse>

			<Collapse
				className="main-1-collapse border-none"
				expandIcon={({ isActive }) =>
					isActive ? (
						<span
							className="ant-menu-submenu-arrow"
							style={{ color: "#FFF", transform: "rotate(270deg)" }}
						></span>
					) : (
						<span
							className="ant-menu-submenu-arrow"
							style={{ color: "#FFF", transform: "rotate(90deg)" }}
						></span>
					)
				}
				defaultActiveKey={["1"]}
				expandIconPosition="start"
			>
				<Collapse.Panel
					header="PAGE 25"
					key="1"
					className="accordion bg-darkgray-form m-b-md border"
				>
					<Typography.Title level={4} className="text-center">
						Results: 5-10-5 Agility Test
					</Typography.Title>

					<div className="table-responsive">
						<table className="table m-t-md">
							<thead>
								<tr>
									<th>Measurement</th>
									<th>You</th>
									<th>Elite Performers</th>
									<th>Training Emphasis Recommendations</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>5-10-5 Agility</td>
									<td>
										<Form.Item valuePropName="checked" name="p25_cod_power">
											<Checkbox onChange={handleChangeCheckbox} />
										</Form.Item>
									</td>
									<td>
										<Form.Item
											valuePropName="checked"
											name="p25_age_avg_cod_power_sum"
										>
											<Checkbox onChange={handleChangeCheckbox} />
										</Form.Item>
									</td>
									<td>
										<ul>
											<li>Lateral Jumps, Hops, Bounds</li>
											<li>Olympic Lift Variations</li>
											<li>Eccentric Strength Training</li>
										</ul>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</Collapse.Panel>
			</Collapse>

			<Collapse
				className="main-1-collapse border-none"
				expandIcon={({ isActive }) =>
					isActive ? (
						<span
							className="ant-menu-submenu-arrow"
							style={{ color: "#FFF", transform: "rotate(270deg)" }}
						></span>
					) : (
						<span
							className="ant-menu-submenu-arrow"
							style={{ color: "#FFF", transform: "rotate(90deg)" }}
						></span>
					)
				}
				defaultActiveKey={["1"]}
				expandIconPosition="start"
			>
				<Collapse.Panel
					header="PAGE 26"
					key="1"
					className="accordion bg-darkgray-form m-b-md border"
				>
					<Typography.Title level={4} className="text-center">
						Summary of Findings
					</Typography.Title>

					<div className="table-responsive">
						<table className="table m-t-md">
							<thead>
								<tr>
									<th>BFS Testing: Performance Tests </th>
									<th>Reactive Strength Index</th>
									<th>Dynamic Strength Index</th>
									<th>Eccentric Utilization Ratio</th>
									<th>relative Strength</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>You </td>
									<td>
										<Form.Item valuePropName="checked" name="p26_cmj_rsi_no">
											<Checkbox onChange={handleChangeCheckbox} />
										</Form.Item>
									</td>
									<td>
										<Form.Item valuePropName="checked" name="p26_dsi_score">
											<Checkbox onChange={handleChangeCheckbox} />
										</Form.Item>
									</td>
									<td>
										<Form.Item valuePropName="checked" name="p26_eur">
											<Checkbox onChange={handleChangeCheckbox} />
										</Form.Item>
									</td>
									<td>
										<Form.Item valuePropName="checked" name="p26_ms_peak_force">
											<Checkbox onChange={handleChangeCheckbox} />
										</Form.Item>
									</td>
								</tr>
								<tr>
									<td>Average Performers</td>
									<td>
										<Form.Item
											valuePropName="checked"
											name="p26_age_avg_cmj_rsi_no_sum"
										>
											<Checkbox onChange={handleChangeCheckbox} />
										</Form.Item>
									</td>
									<td>
										<Form.Item
											valuePropName="checked"
											name="p26_dsi_score_avg_tot"
										>
											<Checkbox onChange={handleChangeCheckbox} />
										</Form.Item>
									</td>
									<td>
										<Form.Item
											valuePropName="checked"
											name="p26_avg_eccentric_utilization_ratio"
										>
											<Checkbox onChange={handleChangeCheckbox} />
										</Form.Item>
									</td>
									<td>
										<Form.Item
											valuePropName="checked"
											name="p26_avg_relative_strength"
										>
											<Checkbox onChange={handleChangeCheckbox} />
										</Form.Item>
									</td>
								</tr>
								<tr>
									<td>Benchmark</td>
									<td>
										<Form.Item valuePropName="checked" name="p26_average_1">
											<Checkbox onChange={handleChangeCheckbox} />
										</Form.Item>
									</td>
									<td>
										<Form.Item valuePropName="checked" name="p26_average_2">
											<Checkbox onChange={handleChangeCheckbox} />
										</Form.Item>
									</td>
									<td>
										<Form.Item valuePropName="checked" name="p26_average_3">
											<Checkbox onChange={handleChangeCheckbox} />
										</Form.Item>
									</td>
									<td>
										<Form.Item valuePropName="checked" name="p26_average_4">
											<Checkbox onChange={handleChangeCheckbox} />
										</Form.Item>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</Collapse.Panel>
			</Collapse>
		</Form>
	);
}
