import React, { useState } from "react";
import { Button, Card, Col, List, Row, Typography, Collapse } from "antd";
import { faHome } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHandsUsd, faLightbulbOn } from "@fortawesome/pro-solid-svg-icons";
import { faStar, faTicketAlt } from "@fortawesome/free-solid-svg-icons";
import { useHistory } from "react-router-dom";
import moment from "moment";

import ComponentHeader from "../../Components/ComponentHeader";
import ModalFormWelcome from "./Modals/ModalFormWelcome";
import ComponentDashboard from "../../Components/ComponentDashboard";

import companyInfo from "../../../../providers/companyInfo";
import { GET } from "../../../../providers/useAxiosQuery";

export default function PageDashboard(props) {
  const { permission } = props;
  const { Panel } = Collapse;
  const userdata = companyInfo().userData;
  const history = useHistory();

  // dashboard assessment view
  const [dataFilter, setDataFilter] = useState({
    date: moment().format("MM/DD/YYYY"),
    type: "Group",
    search: "",
    category: "",
    tag: "",
    country: "",
    state: "",
    city: "",
    from: "coach_dashboard",
    legend: true,
  });

  const [fundData, setFundData] = useState([]);
  GET(`api/v1/funds`, "funds_paid", (res) => {
    if (res.success) {
      // console.log("funds", res);
      setFundData(res.data);
    }
  });

  return (
    <>
      <ComponentHeader
        title={permission}
        sub_title="ALL FEATURES"
        icon={faHome}
      />

      <Card
        className="card-quick-links p-b-lg card-min-height"
        id="PageDashboard"
      >
        <h1>QUICK LINKS</h1>
        <Row gutter={12}>
          <Col xs={24} sm={24} md={24} lg={24} xl={16}>
            <Card
              // title="QUICK LINKS"
              className="card-transparent-head border-bottom-none no-side-border p-none"
              bodyStyle={{ padding: "0px" }}
            >
              <Row gutter={[12, 12]} className="ant-row-quick-link">
                <Col xs={12} sm={12} md={6}>
                  <Button
                    type="link"
                    className="ant-btn-quick-link"
                    onClick={(e) => history.push("/funds/funding")}
                  >
                    <FontAwesomeIcon icon={faHandsUsd} />
                    <Typography.Text>
                      Funds from
                      <br />
                      BFSS
                    </Typography.Text>
                  </Button>
                </Col>
                <Col xs={12} sm={12} md={6}>
                  <Button
                    type="link"
                    className="ant-btn-quick-link"
                    onClick={() => history.push("/athlete/follow")}
                  >
                    <FontAwesomeIcon icon={faStar} />
                    <Typography.Text>
                      Athletes
                      <br />I Follow
                    </Typography.Text>
                  </Button>
                </Col>
                <Col xs={12} sm={12} md={6}>
                  <Button
                    type="link"
                    className="ant-btn-quick-link"
                    onClick={(e) => history.push("/support/faqs")}
                  >
                    <FontAwesomeIcon icon={faLightbulbOn} />
                    <Typography.Text>
                      Frequently Asked
                      <br />
                      Questions
                    </Typography.Text>
                  </Button>
                </Col>
                <Col xs={12} sm={12} md={6}>
                  <Button
                    type="link"
                    className="ant-btn-quick-link"
                    onClick={(e) => history.push("/support/ticketing")}
                  >
                    <FontAwesomeIcon icon={faTicketAlt} />
                    <Typography.Text>
                      Support
                      <br />
                      Ticket
                    </Typography.Text>
                  </Button>
                </Col>
              </Row>
            </Card>

            <ComponentDashboard
              dataFilter={dataFilter}
              setDataFilter={setDataFilter}
              column={12}
            />
          </Col>

          <Col
            xs={24}
            sm={24}
            md={24}
            lg={24}
            xl={8}
            className="coach-dashboard-fund-raised"
          >
            <Collapse
              className="main-1-collapse"
              expandIcon={({ isActive }) =>
                isActive ? (
                  <span
                    className="ant-menu-submenu-arrow"
                    style={{ color: "#FFF", transform: "rotate(270deg)" }}
                  ></span>
                ) : (
                  <span
                    className="ant-menu-submenu-arrow"
                    style={{ color: "#FFF", transform: "rotate(90deg)" }}
                  ></span>
                )
              }
              defaultActiveKey={["1"]}
              expandIconPosition="start"
            >
              <Panel
                header="FUNDS RAISED"
                key="1"
                // className="accordion bg-darkgray-form dashbaord-collapse"
                className="card-dark-head no-side-border p-none card-dashboard-assessment coach-funds"
                bodyStyle={{ padding: "0px" }}
              >
                <div
                  id="scrollableDiv"
                  style={{
                    maxHeight: 300,
                    overflow: "auto",
                    paddingLeft: "10px",
                    paddingRight: "10px",
                    border: "1px solid rgba(140, 140, 140, 0.35)",
                  }}
                  className="scrollbar-2"
                >
                  <List
                    style={{ padding: "7px" }}
                    dataSource={fundData}
                    renderItem={(item) => {
                      let amount = item.amount.split(".");
                      if (item.amount !== "$0") {
                        amount =
                          amount[0] + "." + amount[1].toString().substr(0, 2);
                      }
                      return (
                        <List.Item>
                          <Typography.Text>
                            <span className="color-3">
                              <b>{item.firstname}</b>
                            </span>
                            <br />
                            <span
                              className={
                                item.admin_funds_status === "Pending"
                                  ? "color-7"
                                  : ""
                              }
                            >
                              <b>
                                +{" "}
                                {item.amount && item.amount !== "$0"
                                  ? amount
                                  : "0.00"}{" "}
                                {item.admin_funds_status === "Pending"
                                  ? "Pending"
                                  : "Paid"}
                              </b>
                            </span>
                          </Typography.Text>
                        </List.Item>
                      );
                    }}
                  />
                </div>
              </Panel>
            </Collapse>
          </Col>
        </Row>
      </Card>

      {userdata.one_time_modal === 0 ? <ModalFormWelcome /> : null}
    </>
  );
}
