import { Card, Col, Divider, Row, Space, Typography } from "antd";
import React, { useState } from "react";
import ComponentHeader from "../../Components/ComponentHeader";
import { GET } from "../../../../providers/useAxiosQuery";
import { faFilePdf } from "@fortawesome/pro-regular-svg-icons";

import FormPdfStarter from "./Component/FormPdfStarter";
import FormPdfProfessional from "./Component/FormPdfProfessional";
import FormPdfElite from "./Component/FormPdfElite";
import moment from "moment";

export default function PageAthletePdfDisplayDataForm(props) {
  const { location } = props;
  console.log("PageAthletePdfDisplayDataForm props", props);

  const [dataSource, setDataSource] = useState();

  GET(
    `api/v1/athlete_pdf_display_data?assessment_id=${
      location.state && location.state.id
    }`,
    "athlete_pdf_display_data_info",
    (res) => {
      // console.log("athlete_pdf_display_data res", res);

      setDataSource(res.data);
    }
  );

  const handleFormRender = () => {
    if (location.state && location.state.type === "Starter") {
      return (
        <FormPdfStarter
          assessment_id={location.state && location.state.id}
          dataSource={dataSource}
        />
      );
    } else if (location.state && location.state.type === "Professional") {
      return (
        <FormPdfProfessional
          assessment_id={location.state && location.state.id}
          dataSource={dataSource}
        />
      );
    } else if (location.state && location.state.type === "Elite") {
      return (
        <FormPdfElite
          assessment_id={location.state && location.state.id}
          dataSource={dataSource}
        />
      );
    }
  };

  return (
    <>
      <ComponentHeader
        title="Display Data"
        sub_title="ATHLETE PDF FORM"
        icon={faFilePdf}
      />

      <Card
        className="card-page-athlete-pdf-display-data"
        id="PageAthletePdfDisplayDataForm"
      >
        <Row gutter={12}>
          <Col xs={24} sm={24} md={24}>
            <Space>
              <Typography.Text strong>Type:</Typography.Text>
              <Typography.Text strong>
                {location.state && location.state.type}
              </Typography.Text>
            </Space>
          </Col>
          <Col xs={24} sm={24} md={24}>
            <Space>
              <Typography.Text strong>AGE:</Typography.Text>
              <Typography.Text strong>
                {location.state &&
                  location.state.athlete &&
                  moment().diff(location.state.athlete.birthdate, "years")}
              </Typography.Text>
            </Space>
          </Col>
          <Col xs={24} sm={24} md={24}>
            <Space>
              <Typography.Text strong>SPORT:</Typography.Text>
              <Typography.Text strong>
                {location.state && location.state.sport}
              </Typography.Text>
            </Space>
          </Col>
          <Col xs={24} sm={24} md={24}>
            <Space>
              <Typography.Text strong>WEIGHT (LBS):</Typography.Text>
              <Typography.Text strong>
                {location.state &&
                  location.state.athlete &&
                  location.state.athlete.athlete_info &&
                  location.state.athlete.athlete_info.weight}
              </Typography.Text>
            </Space>
          </Col>
          <Col xs={24} sm={24} md={24}>
            <Space>
              <Typography.Text strong>SEX:</Typography.Text>
              <Typography.Text strong>
                {location.state &&
                  location.state.athlete &&
                  location.state.athlete.gender}
              </Typography.Text>
            </Space>
          </Col>
          <Divider />
          <Col xs={24} sm={24} md={24}>
            {handleFormRender()}
          </Col>
        </Row>
      </Card>
    </>
  );
}
