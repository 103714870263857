import {
  //  degrees,
  PDFDocument,
  rgb,
  StandardFonts,
} from "pdf-lib";
import download from "downloadjs";
import moment from "moment";
// import truncateDecimalNumber from "./truncateDecimalNumber";

const pdfPro = async (pdfUrl, data, display_data) => {
  let dataRender = [
    [
      [
        {
          value: `${data.name}`,
          x: 185,
          y: ["+", 240],
          fontSize: 46,
          font: StandardFonts.HelveticaBoldBold,
          color: rgb(1, 1, 1),
          posxx: true,
        },
      ],
    ], // page 1
    [], // page 2
    [
      [
        {
          value: `${
            data.p_3_age === null ||
            data.p_3_age === 0 ||
            data.p_3_age === "EF" ||
            data.p_3_age === "AI" ||
            data.p_3_age === "ART"
              ? "NA"
              : `${data.p_3_age}`
          }`,
          x: 85,
          y: ["+", 309],
          fontSize: 18,
          color: rgb(0, 0, 0),
          posxx: true,
        },
        {
          value: `${
            data.p_3_sport === null ||
            data.p_3_sport === 0 ||
            data.p_3_sport === "EF" ||
            data.p_3_sport === "AI" ||
            data.p_3_sport === "ART"
              ? "NA"
              : `${data.p_3_sport}`
          }`,
          x: 185,
          y: ["+", 309],
          fontSize: 18,
          color: rgb(0, 0, 0),
          posxx: true,
        },
        {
          value: `${
            data.p_3_weight === null ||
            data.p_3_weight === 0 ||
            data.p_3_weight === "EF" ||
            data.p_3_weight === "AI" ||
            data.p_3_weight === "ART"
              ? "NA"
              : `${data.p_3_weight}`
          }`,
          x: 828,
          y: ["+", 310],
          fontSize: 18,
          color: rgb(0, 0, 0),
          posxx: true,
        },
        {
          value: `${
            data.p_3_gender === null ||
            data.p_3_gender === 0 ||
            data.p_3_gender === "EF" ||
            data.p_3_gender === "AI" ||
            data.p_3_gender === "ART"
              ? "NA"
              : `${data.p_3_gender}`
          }`,
          x: 913,
          y: ["+", 310],
          fontSize: 18,
          color: rgb(0, 0, 0),
          posxx: true,
        },
      ],
      [
        {
          value: `${
            data.p_3_rebound_jump === null ||
            data.p_3_rebound_jump === 0 ||
            data.p_3_rebound_jump === "EF" ||
            data.p_3_rebound_jump === "AI" ||
            data.p_3_rebound_jump === "ART"
              ? "NA"
              : `${data.p_3_rebound_jump}`
          }`,
          x: 285,
          y: ["+", 135],
          fontSize: 18,
          color: rgb(0, 0, 0),
          name: "p3_cmj_rsi_no",
        },
        {
          value: `${
            data.p_3_countermovement_jump === null ||
            data.p_3_countermovement_jump === 0 ||
            data.p_3_countermovement_jump === "EF" ||
            data.p_3_countermovement_jump === "AI" ||
            data.p_3_countermovement_jump === "ART"
              ? "NA"
              : `${data.p_3_countermovement_jump}"`
          }`,
          x: 432,
          y: ["+", 135],
          fontSize: 18,
          color: rgb(0, 0, 0),
          name: "p3_es_height",
        },
        {
          value: `${
            data.p_3_squat_jump === null ||
            data.p_3_squat_jump === 0 ||
            data.p_3_squat_jump === "EF" ||
            data.p_3_squat_jump === "AI" ||
            data.p_3_squat_jump === "ART"
              ? "NA"
              : `${data.p_3_squat_jump}"`
          }`,
          x: 584,
          y: ["+", 135],
          fontSize: 18,
          color: rgb(0, 0, 0),
          name: "p3_sj_height",
        },
        {
          value: `${
            data.p_3_ten_yard_sprint_jump === null ||
            data.p_3_ten_yard_sprint_jump === 0 ||
            data.p_3_ten_yard_sprint_jump === "EF" ||
            data.p_3_ten_yard_sprint_jump === "AI" ||
            data.p_3_ten_yard_sprint_jump === "ART"
              ? "NA"
              : `${data.p_3_ten_yard_sprint_jump}s`
          }`,
          x: 736,
          y: ["+", 135],
          fontSize: 18,
          color: rgb(0, 0, 0),
          name: "p3_sprint_time_tem_meter",
        },
        {
          value: `${
            data.p_3_isometric_mid_thigh_pull === null ||
            data.p_3_isometric_mid_thigh_pull === 0 ||
            data.p_3_isometric_mid_thigh_pull === "EF" ||
            data.p_3_isometric_mid_thigh_pull === "AI" ||
            data.p_3_isometric_mid_thigh_pull === "ART"
              ? "NA"
              : `${data.p_3_isometric_mid_thigh_pull}lbs`
          }`,
          x: 890,
          y: ["+", 135],
          fontSize: 18,
          color: rgb(0, 0, 0),
          name: "p3_isometric_pull",
        },
      ],
      [
        {
          value: `${
            data.p_3_age === null ||
            data.p_3_age === 0 ||
            data.p_3_age === "EF" ||
            data.p_3_age === "AI" ||
            data.p_3_age === "ART"
              ? "NA"
              : `${data.p_3_age}`
          }`,
          x: 112,
          y: ["+", 52],
          fontSize: 20,
          color: rgb(1, 1, 1),
          name: "p3_p_3_age1",
        },
        {
          value: `${
            data.p_3_rebound_jump === null ||
            data.p_3_rebound_jump === 0 ||
            data.p_3_rebound_jump === "EF" ||
            data.p_3_rebound_jump === "AI" ||
            data.p_3_rebound_jump === "ART"
              ? "NA"
              : `${data.p_3_rebound_jump_average}`
          }`,
          x: 285,
          y: ["+", 60],
          fontSize: 18,
          color: rgb(0, 0, 0),
          name: "p3_age_avg_cmj_rsi_no_sum",
        },
        {
          value: `${
            data.p_3_countermovement_jump === null ||
            data.p_3_countermovement_jump === 0 ||
            data.p_3_countermovement_jump === "EF" ||
            data.p_3_countermovement_jump === "AI" ||
            data.p_3_countermovement_jump === "ART"
              ? "NA"
              : `${data.p_3_countermovement_jump_average}"`
          }`,
          x: 432,
          y: ["+", 60],
          fontSize: 18,
          color: rgb(0, 0, 0),
          name: "p3_age_avg_es_height_sum",
        },
        {
          value: `${
            data.p_3_squat_jump === null ||
            data.p_3_squat_jump === 0 ||
            data.p_3_squat_jump === "EF" ||
            data.p_3_squat_jump === "AI" ||
            data.p_3_squat_jump === "ART"
              ? "NA"
              : `${data.p_3_squat_jump_average}"`
          }`,
          x: 584,
          y: ["+", 60],
          fontSize: 18,
          color: rgb(0, 0, 0),
          name: "p3_age_avg_sj_height_sum",
        },
        {
          value: `${
            data.p_3_ten_yard_sprint_jump === null ||
            data.p_3_ten_yard_sprint_jump === 0 ||
            data.p_3_ten_yard_sprint_jump === "EF" ||
            data.p_3_ten_yard_sprint_jump === "AI" ||
            data.p_3_ten_yard_sprint_jump === "ART"
              ? "NA"
              : `${data.p_3_ten_yard_sprint_jump_average}s`
          }`,
          x: 736,
          y: ["+", 60],
          fontSize: 18,
          color: rgb(0, 0, 0),
          name: "p3_age_avg_sprint_time_tem_meter_sum",
        },
        {
          value: `${
            data.p_3_isometric_mid_thigh_pull === null ||
            data.p_3_isometric_mid_thigh_pull === 0 ||
            data.p_3_isometric_mid_thigh_pull === "EF" ||
            data.p_3_isometric_mid_thigh_pull === "AI" ||
            data.p_3_isometric_mid_thigh_pull === "ART"
              ? "NA"
              : `${data.p_3_isometric_mid_thigh_pull_average}lbs`
          }`,
          x: 890,
          y: ["+", 60],
          fontSize: 18,
          color: rgb(0, 0, 0),
          name: "p3_age_avg_isometric_pull",
        },
      ],
      [
        {
          value: `${
            data.p_3_rebound_jump === null ||
            data.p_3_rebound_jump === 0 ||
            data.p_3_rebound_jump === "EF" ||
            data.p_3_rebound_jump === "AI" ||
            data.p_3_rebound_jump === "ART"
              ? ""
              : `${data.p_3_rebound_jump_benchmark}`
          }`,
          x: 280,
          y: ["-", 2],
          fontSize: 18,
          color: rgb(0, 0, 0),
          name: "p3_average_1",
        },
        {
          value: `${
            data.p_3_countermovement_jump === null ||
            data.p_3_countermovement_jump === 0 ||
            data.p_3_countermovement_jump === "EF" ||
            data.p_3_countermovement_jump === "AI" ||
            data.p_3_countermovement_jump === "ART"
              ? ""
              : `${data.p_3_countermovement_jump_benchmark}`
          }`,
          x: 430,
          y: ["-", 2],
          fontSize: 18,
          color: rgb(0, 0, 0),
          name: "p3_average_2",
        },
        {
          value: `${
            data.p_3_squat_jump === null ||
            data.p_3_squat_jump === 0 ||
            data.p_3_squat_jump === "EF" ||
            data.p_3_squat_jump === "AI" ||
            data.p_3_squat_jump === "ART"
              ? ""
              : `${data.p_3_squat_jump_benchmark}`
          }`,
          x: 586,
          y: ["-", 2],
          fontSize: 18,
          color: rgb(0, 0, 0),
          name: "p3_average_3",
        },
        {
          value: `${
            data.p_3_ten_yard_sprint_jump === null ||
            data.p_3_ten_yard_sprint_jump === 0 ||
            data.p_3_ten_yard_sprint_jump === "EF" ||
            data.p_3_ten_yard_sprint_jump === "AI" ||
            data.p_3_ten_yard_sprint_jump === "ART"
              ? ""
              : `${data.p_3_ten_yard_sprint_jump_benchmark}`
          }`,
          x: 738,
          y: ["-", 2],
          fontSize: 18,
          color: rgb(0, 0, 0),
          name: "p3_average_4",
        },
        {
          value: `${
            data.p_3_isometric_mid_thigh_pull === null ||
            data.p_3_isometric_mid_thigh_pull === 0 ||
            data.p_3_isometric_mid_thigh_pull === "EF" ||
            data.p_3_isometric_mid_thigh_pull === "AI" ||
            data.p_3_isometric_mid_thigh_pull === "ART"
              ? ""
              : `${data.p_3_isometric_mid_thigh_pull_benchmark}`
          }`,
          x: 890,
          y: ["-", 2],
          fontSize: 18,
          color: rgb(0, 0, 0),
          name: "p3_average_5",
        },
      ],
      [
        {
          value: `${
            data.p_3_rebound_jump === null ||
            data.p_3_rebound_jump === 0 ||
            data.p_3_rebound_jump === "EF" ||
            data.p_3_rebound_jump === "AI" ||
            data.p_3_rebound_jump === "ART"
              ? ""
              : `PERCENTILE`
          }`,
          x: 280,
          y: ["-", 25],
          fontSize: 18,
          color: rgb(0, 0, 0),
          name: "p3_average_1",
        },
        {
          value: `${
            data.p_3_countermovement_jump === null ||
            data.p_3_countermovement_jump === 0 ||
            data.p_3_countermovement_jump === "EF" ||
            data.p_3_countermovement_jump === "AI" ||
            data.p_3_countermovement_jump === "ART"
              ? ""
              : `PERCENTILE`
          }`,
          x: 430,
          y: ["-", 25],
          fontSize: 18,
          color: rgb(0, 0, 0),
          name: "p3_average_2",
        },
        {
          value: `${
            data.p_3_squat_jump === null ||
            data.p_3_squat_jump === 0 ||
            data.p_3_squat_jump === "EF" ||
            data.p_3_squat_jump === "AI" ||
            data.p_3_squat_jump === "ART"
              ? ""
              : `PERCENTILE`
          }`,
          x: 586,
          y: ["-", 25],
          fontSize: 18,
          color: rgb(0, 0, 0),
          name: "p3_average_3",
        },
        {
          value: `${
            data.p_3_ten_yard_sprint_jump === null ||
            data.p_3_ten_yard_sprint_jump === 0 ||
            data.p_3_ten_yard_sprint_jump === "EF" ||
            data.p_3_ten_yard_sprint_jump === "AI" ||
            data.p_3_ten_yard_sprint_jump === "ART"
              ? ""
              : `PERCENTILE`
          }`,
          x: 738,
          y: ["-", 25],
          fontSize: 18,
          color: rgb(0, 0, 0),
          name: "p3_average_4",
        },
        {
          value: `${
            data.p_3_isometric_mid_thigh_pull === null ||
            data.p_3_isometric_mid_thigh_pull === 0 ||
            data.p_3_isometric_mid_thigh_pull === "EF" ||
            data.p_3_isometric_mid_thigh_pull === "AI" ||
            data.p_3_isometric_mid_thigh_pull === "ART"
              ? ""
              : `PERCENTILE`
          }`,
          x: 890,
          y: ["-", 25],
          fontSize: 18,
          color: rgb(0, 0, 0),
          name: "p3_average_5",
        },
      ],
      [
        {
          value: `${
            data.p_3_rebound_jump === null ||
            data.p_3_rebound_jump === 0 ||
            data.p_3_rebound_jump === "EF" ||
            data.p_3_rebound_jump === "AI" ||
            data.p_3_rebound_jump === "ART"
              ? "NA"
              : ``
          }`,
          x: 280,
          y: ["-", 15],
          fontSize: 18,
          color: rgb(0, 0, 0),
          name: "p3_average_1",
        },
        {
          value: `${
            data.p_3_countermovement_jump === null ||
            data.p_3_countermovement_jump === 0 ||
            data.p_3_countermovement_jump === "EF" ||
            data.p_3_countermovement_jump === "AI" ||
            data.p_3_countermovement_jump === "ART"
              ? "NA"
              : ``
          }`,
          x: 430,
          y: ["-", 15],
          fontSize: 18,
          color: rgb(0, 0, 0),
          name: "p3_average_2",
        },
        {
          value: `${
            data.p_3_squat_jump === null ||
            data.p_3_squat_jump === 0 ||
            data.p_3_squat_jump === "EF" ||
            data.p_3_squat_jump === "AI" ||
            data.p_3_squat_jump === "ART"
              ? "NA"
              : ``
          }`,
          x: 586,
          y: ["-", 15],
          fontSize: 18,
          color: rgb(0, 0, 0),
          name: "p3_average_3",
        },
        {
          value: `${
            data.p_3_ten_yard_sprint_jump === null ||
            data.p_3_ten_yard_sprint_jump === 0 ||
            data.p_3_ten_yard_sprint_jump === "EF" ||
            data.p_3_ten_yard_sprint_jump === "AI" ||
            data.p_3_ten_yard_sprint_jump === "ART"
              ? "NA"
              : ``
          }`,
          x: 738,
          y: ["-", 15],
          fontSize: 18,
          color: rgb(0, 0, 0),
          name: "p3_average_4",
        },
        {
          value: `${
            data.p_3_isometric_mid_thigh_pull === null ||
            data.p_3_isometric_mid_thigh_pull === 0 ||
            data.p_3_isometric_mid_thigh_pull === "EF" ||
            data.p_3_isometric_mid_thigh_pull === "AI" ||
            data.p_3_isometric_mid_thigh_pull === "ART"
              ? "NA"
              : ``
          }`,
          x: 890,
          y: ["-", 15],
          fontSize: 18,
          color: rgb(0, 0, 0),
          name: "p3_average_5",
        },
      ],
    ], // page 3
    [], // page 4
    [
      [
        {
          value: `${
            data.p_6_rsi_score === null ||
            data.p_6_rsi_score === 0 ||
            data.p_6_rsi_score === "EF" ||
            data.p_6_rsi_score === "AI" ||
            data.p_6_rsi_score === "ART"
              ? "NA"
              : `${data.p_6_rsi_score}`
          }`,
          x: 615,
          y: ["+", 215],
          fontSize: 12,
          color: rgb(0, 0, 0),
          name: "p5_cmj_rsi_no",
        },
        {
          value: `${
            data.p_6_jump_height === null ||
            data.p_6_jump_height === 0 ||
            data.p_6_jump_height === "EF" ||
            data.p_6_jump_height === "AI" ||
            data.p_6_jump_height === "ART"
              ? "NA"
              : `${data.p_6_jump_height}`
          }`,
          x: 735,
          y: ["+", 215],
          fontSize: 12,
          color: rgb(0, 0, 0),
          name: "p5_es_height",
        },
        {
          value: `${
            data.p_6_ground_contact_time === null ||
            data.p_6_ground_contact_time === 0 ||
            data.p_6_ground_contact_time === "EF" ||
            data.p_6_ground_contact_time === "AI" ||
            data.p_6_ground_contact_time === "ART"
              ? "NA"
              : `${data.p_6_ground_contact_time}`
          }`,
          x: 890,
          y: ["+", 215],
          fontSize: 12,
          color: rgb(0, 0, 0),
          name: "p5_ground_contact_time",
        },
        {
          value: `${
            data.p_6_rsi_score === null ||
            data.p_6_rsi_score === 0 ||
            data.p_6_rsi_score === "EF" ||
            data.p_6_rsi_score === "AI" ||
            data.p_6_rsi_score === "ART"
              ? "NA"
              : `${data.p_6_rank}`
          }`,
          x: 134,
          y: ["+", 41],
          fontSize: 16,
          color: rgb(0, 0, 0),
          name: "p5_rank",
          posxx: true,
        },
      ],
    ], // page 5
    [], // page 6
    [
      [
        {
          value: `${
            data.p_8_cmj_height === null ||
            data.p_8_cmj_height === 0 ||
            data.p_8_cmj_height === "EF" ||
            data.p_8_cmj_height === "AI" ||
            data.p_8_cmj_height === "ART"
              ? "NA"
              : `${data.p_8_cmj_height}`
          }`,
          x: 775,
          y: ["+", 212],
          fontSize: 12,
          color: rgb(0, 0, 0),
          name: "p7_cmj_jump_height",
        },
        {
          value: `${
            data.p_8_cmj_height === null ||
            data.p_8_cmj_height === 0 ||
            data.p_8_cmj_height === "EF" ||
            data.p_8_cmj_height === "AI" ||
            data.p_8_cmj_height === "ART"
              ? "NA"
              : `${data.p_8_cmj_height_average}`
          }`,
          x: 905,
          y: ["+", 212],
          fontSize: 12,
          color: rgb(0, 0, 0),
          name: "p7_avg_cmj_jump_height",
        },
      ],
      [
        {
          value: `${
            data.p_8_relative_avg_power === null ||
            data.p_8_relative_avg_power === 0 ||
            data.p_8_relative_avg_power === "EF" ||
            data.p_8_relative_avg_power === "AI" ||
            data.p_8_relative_avg_power === "ART"
              ? "NA"
              : `${data.p_8_relative_avg_power}`
          }`,
          x: 775,
          y: ["+", 190],
          fontSize: 12,
          color: rgb(0, 0, 0),
          name: "p7_es_peak_power",
        },
        {
          value: `${
            data.p_8_relative_avg_power === null ||
            data.p_8_relative_avg_power === 0 ||
            data.p_8_relative_avg_power === "EF" ||
            data.p_8_relative_avg_power === "AI" ||
            data.p_8_relative_avg_power === "ART"
              ? "NA"
              : `${data.p_8_relative_avg_power_average}`
          }`,
          x: 905,
          y: ["+", 190],
          fontSize: 12,
          color: rgb(0, 0, 0),
          name: "p7_cmj_relative_avg_power",
        },
        {
          value: `${
            data.p_8_cmj_height === null ||
            data.p_8_cmj_height === 0 ||
            data.p_8_cmj_height === "EF" ||
            data.p_8_cmj_height === "AI" ||
            data.p_8_cmj_height === "ART"
              ? "NA"
              : `${data.p_8_rank}`
          }`,
          x: 135,
          y: ["+", 61],
          fontSize: 16,
          color: rgb(0, 0, 0),
          name: "p7_rank",
          posxx: true,
        },
      ],
    ], // page 7
    [], // page 8
    [
      [
        {
          value: `${
            data.p_10_j_height === null ||
            data.p_10_j_height === 0 ||
            data.p_10_j_height === "EF" ||
            data.p_10_j_height === "AI" ||
            data.p_10_j_height === "ART"
              ? "NA"
              : `${data.p_10_j_height}`
          }`,
          x: 760,
          y: ["+", 202],
          fontSize: 12,
          color: rgb(0, 0, 0),
          name: "p9_sj_height",
        },
        {
          value: `${
            data.p_10_j_height === null ||
            data.p_10_j_height === 0 ||
            data.p_10_j_height === "EF" ||
            data.p_10_j_height === "AI" ||
            data.p_10_j_height === "ART"
              ? "NA"
              : `${data.p_10_j_height_average}`
          }`,
          x: 885,
          y: ["+", 202],
          fontSize: 12,
          color: rgb(0, 0, 0),
          name: "p9_age_avg_sj_height_sum",
        },
      ],
      [
        {
          value: `${
            data.p_10_realative_peak_power === null ||
            data.p_10_realative_peak_power === 0 ||
            data.p_10_realative_peak_power === "EF" ||
            data.p_10_realative_peak_power === "AI" ||
            data.p_10_realative_peak_power === "ART"
              ? "NA"
              : `${data.p_10_realative_peak_power}`
          }`,
          x: 760,
          y: ["+", 180],
          fontSize: 12,
          color: rgb(0, 0, 0),
          name: "p9_sj_peak_power",
        },
        {
          value: `${
            data.p_10_realative_peak_power === null ||
            data.p_10_realative_peak_power === 0 ||
            data.p_10_realative_peak_power === "EF" ||
            data.p_10_realative_peak_power === "AI" ||
            data.p_10_realative_peak_power === "ART"
              ? "NA"
              : `${data.p_10_realative_peak_power_average}`
          }`,
          x: 885,
          y: ["+", 180],
          fontSize: 12,
          color: rgb(0, 0, 0),
          name: "p9_relative_sj_peak_power",
        },
        {
          value: `${
            data.p_10_j_height === null ||
            data.p_10_j_height === 0 ||
            data.p_10_j_height === "EF" ||
            data.p_10_j_height === "AI" ||
            data.p_10_j_height === "ART"
              ? "NA"
              : `${data.p_10_rank}`
          }`,
          x: 135,
          y: ["+", 67],
          fontSize: 16,
          color: rgb(0, 0, 0),
          name: "p9_rank",
          posxx: true,
        },
      ],
    ], // page 9
    [
      [
        {
          value: `${
            data.p_11_your_eur_score === null ||
            data.p_11_your_eur_score === 0 ||
            data.p_11_your_eur_score === "EF" ||
            data.p_11_your_eur_score === "AI" ||
            data.p_11_your_eur_score === "ART"
              ? "NA"
              : `${data.p_11_your_eur_score}`
          }`,
          x: 210,
          y: ["+", -136],
          fontSize: 16,
          color: rgb(0, 0, 0),
          name: "p10_your_eur_score",
          posxx: true,
        },
      ],
    ], // page 10
    [], // page 11
    [
      [
        {
          value: `${
            data.p_13_10_yard_sprint === null ||
            data.p_13_10_yard_sprint === 0 ||
            data.p_13_10_yard_sprint === "EF" ||
            data.p_13_10_yard_sprint === "AI" ||
            data.p_13_10_yard_sprint === "ART"
              ? "NA"
              : `${data.p_13_10_yard_sprint}`
          }`,
          x: 720,
          y: ["+", 185],
          fontSize: 14,
          color: rgb(0, 0, 0),
          name: "p12_sprint_time_tem_meter",
        },
        {
          value: `${
            data.p_13_10_yard_sprint === null ||
            data.p_13_10_yard_sprint === 0 ||
            data.p_13_10_yard_sprint === "EF" ||
            data.p_13_10_yard_sprint === "AI" ||
            data.p_13_10_yard_sprint === "ART"
              ? "NA"
              : `${data.p_13_10_yard_sprint_average}`
          }`,
          x: 815,
          y: ["+", 185],
          fontSize: 14,
          color: rgb(0, 0, 0),
          name: "p12_age_avg_sprint_time_tem_meter_sum",
        },
      ],
      [
        {
          value: `${
            data.p_13_40_yard_sprint === null ||
            data.p_13_40_yard_sprint === 0 ||
            data.p_13_40_yard_sprint === "EF" ||
            data.p_13_40_yard_sprint === "AI" ||
            data.p_13_40_yard_sprint === "ART"
              ? "NA"
              : `${data.p_13_40_yard_sprint}`
          }`,
          x: 720,
          y: ["+", 110],
          fontSize: 14,
          color: rgb(0, 0, 0),
          name: "p12_sprint_time_four_meter",
        },
        {
          value: `${
            data.p_13_40_yard_sprint === null ||
            data.p_13_40_yard_sprint === 0 ||
            data.p_13_40_yard_sprint === "EF" ||
            data.p_13_40_yard_sprint === "AI" ||
            data.p_13_40_yard_sprint === "ART"
              ? "NA"
              : `${data.p_13_40_yard_sprint_average}`
          }`,
          x: 815,
          y: ["+", 110],
          fontSize: 14,
          color: rgb(0, 0, 0),
          name: "p12_age_avg_sprint_time_four_meter_sum",
        },
        {
          value: `${
            data.p_13_10_yard_sprint === null ||
            data.p_13_10_yard_sprint === 0 ||
            data.p_13_10_yard_sprint === "EF" ||
            data.p_13_10_yard_sprint === "AI" ||
            data.p_13_10_yard_sprint === "ART"
              ? "NA"
              : `${data.p_13_rank}`
          }`,
          x: 135,
          y: ["+", 67],
          fontSize: 16,
          color: rgb(0, 0, 0),
          name: "p12_rank",
          posxx: true,
        },
      ],
    ], // page 12
    [], // page 13
    [
      [
        {
          value: `${
            data.p_15_weight === null ||
            data.p_15_weight === 0 ||
            data.p_15_weight === "EF" ||
            data.p_15_weight === "AI" ||
            data.p_15_weight === "ART"
              ? "NA"
              : `${data.p_15_weight}`
          }`,
          x: 775,
          y: ["+", 225],
          fontSize: 12,
          color: rgb(0, 0, 0),
          name: "p14_weight",
        },
      ],
      [
        {
          value: `${
            data.p_15_absolute_strenght === null ||
            data.p_15_absolute_strenght === 0 ||
            data.p_15_absolute_strenght === "EF" ||
            data.p_15_absolute_strenght === "AI" ||
            data.p_15_absolute_strenght === "ART"
              ? "NA"
              : `${data.p_15_absolute_strenght}`
          }`,
          x: 700,
          y: ["+", 135],
          fontSize: 12,
          color: rgb(0, 0, 0),
          name: "p14_ms_peak_force",
        },
        {
          value: `${
            data.p_15_absolute_strenght === null ||
            data.p_15_absolute_strenght === 0 ||
            data.p_15_absolute_strenght === "EF" ||
            data.p_15_absolute_strenght === "AI" ||
            data.p_15_absolute_strenght === "ART"
              ? "NA"
              : `${data.p_15_absolute_strenght_pounds}`
          }`,
          x: 780,
          y: ["+", 135],
          fontSize: 12,
          color: rgb(0, 0, 0),
          name: "p14_ms_peak_force_lbs",
        },
        {
          value: `${
            data.p_15_absolute_strenght === null ||
            data.p_15_absolute_strenght === 0 ||
            data.p_15_absolute_strenght === "EF" ||
            data.p_15_absolute_strenght === "AI" ||
            data.p_15_absolute_strenght === "ART"
              ? "NA"
              : `${data.p_15_absolute_strenght_average_1}`
          }`,
          x: 860,
          y: ["+", 135],
          fontSize: 12,
          color: rgb(0, 0, 0),
          name: "p14_age_avg_isometric_pull_n",
        },
        {
          value: `${
            data.p_15_absolute_strenght === null ||
            data.p_15_absolute_strenght === 0 ||
            data.p_15_absolute_strenght === "EF" ||
            data.p_15_absolute_strenght === "AI" ||
            data.p_15_absolute_strenght === "ART"
              ? "NA"
              : `${data.p_15_absolute_strenght_average_2}`
          }`,
          x: 935,
          y: ["+", 135],
          fontSize: 12,
          color: rgb(0, 0, 0),
          name: "p14_age_avg_isometric_pull",
        },
      ],
      [
        {
          value: `${
            data.p_15_relative_strength_peak_force_trength === null ||
            data.p_15_relative_strength_peak_force_trength === 0 ||
            data.p_15_relative_strength_peak_force_trength === "EF" ||
            data.p_15_relative_strength_peak_force_trength === "AI" ||
            data.p_15_relative_strength_peak_force_trength === "ART"
              ? "NA"
              : `${data.p_15_relative_strength_peak_force_trength}x`
          }`,
          x: 785,
          y: ["+", 40],
          fontSize: 12,
          color: rgb(0, 0, 0),
          name: "p14_relative_stregth_pf_bw",
        },
        {
          value: `${
            data.p_15_relative_strength_peak_force_trength === null ||
            data.p_15_relative_strength_peak_force_trength === 0 ||
            data.p_15_relative_strength_peak_force_trength === "EF" ||
            data.p_15_relative_strength_peak_force_trength === "AI" ||
            data.p_15_relative_strength_peak_force_trength === "ART"
              ? "NA"
              : `${data.p_15_relative_strength_peak_force_trength_average}x`
          }`,
          x: 905,
          y: ["+", 40],
          fontSize: 12,
          color: rgb(0, 0, 0),
          name: "p14_relative_stregth_pf_bw_avg_per",
        },
        {
          value: `${
            data.p_15_relative_strength_peak_force_trength === null ||
            data.p_15_relative_strength_peak_force_trength === 0 ||
            data.p_15_relative_strength_peak_force_trength === "EF" ||
            data.p_15_relative_strength_peak_force_trength === "AI" ||
            data.p_15_relative_strength_peak_force_trength === "ART"
              ? "NA"
              : `${data.p_15_rank}`
          }`,
          x: 150,
          y: ["+", 40],
          fontSize: 16,
          color: rgb(0, 0, 0),
          name: "p_14_rank",
          posxx: true,
        },
      ],
    ], // page 14
    [
      [
        {
          value: `${
            data.p_16_peak_force_pounds === null ||
            data.p_16_peak_force_pounds === 0 ||
            data.p_16_peak_force_pounds === "EF" ||
            data.p_16_peak_force_pounds === "AI" ||
            data.p_16_peak_force_pounds === "ART"
              ? "NA"
              : `${data.p_16_peak_force_pounds}`
          }`,
          x: 565,
          y: ["+", 216],
          fontSize: 12,
          color: rgb(0, 0, 0),
          name: "p15_ms_peak_force_lbs",
        },
        {
          value: `${data && data.p_16_weight}`,
          x: 660,
          y: ["+", 216],
          fontSize: 12,
          color: rgb(0, 0, 0),
          name: "p15_weight",
        },
        {
          value: `${
            data.p_16_peak_force_body_weight === null ||
            data.p_16_peak_force_body_weight === 0 ||
            data.p_16_peak_force_body_weight === "EF" ||
            data.p_16_peak_force_body_weight === "AI" ||
            data.p_16_peak_force_body_weight === "ART"
              ? "NA"
              : `${data.p_16_peak_force_body_weight}x`
          }`,
          x: 760,
          y: ["+", 216],
          fontSize: 12,
          color: rgb(0, 0, 0),
          name: "p15_relative_stregth_pf_bw_lbs",
        },
        {
          value: `${
            data.p_16_force_0_100_ms === null ||
            data.p_16_force_0_100_ms === 0 ||
            data.p_16_force_0_100_ms === "EF" ||
            data.p_16_force_0_100_ms === "AI" ||
            data.p_16_force_0_100_ms === "ART"
              ? "NA"
              : `${data.p_16_force_0_100_ms}`
          }`,
          x: 865,
          y: ["+", 216],
          fontSize: 12,
          color: rgb(0, 0, 0),
          name: "p15_ms_rfd_lbs",
        },
        {
          value: `${
            data.p_16_0_100_ms_rfd === null ||
            data.p_16_0_100_ms_rfd === 0 ||
            data.p_16_0_100_ms_rfd === "EF" ||
            data.p_16_0_100_ms_rfd === "AI" ||
            data.p_16_0_100_ms_rfd === "ART"
              ? "NA"
              : `${data.p_16_0_100_ms_rfd}%`
          }`,
          x: 955,
          y: ["+", 216],
          fontSize: 12,
          color: rgb(0, 0, 0),
          name: "p15_RFD_percent_of_peak_force_lbs",
        },
      ],
    ], // page 15
    [
      [
        {
          value: `${
            data.p_17_imtp_peak_force === null ||
            data.p_17_imtp_peak_force === 0 ||
            data.p_17_imtp_peak_force === "EF" ||
            data.p_17_imtp_peak_force === "AI" ||
            data.p_17_imtp_peak_force === "ART"
              ? "NA"
              : `${data.p_17_imtp_peak_force}`
          }`,
          x: 140,
          y: ["-", 138],
          fontSize: 12,
          color: rgb(0, 0, 0),
          name: "p16_ms_peak_force",
        },
        {
          value: `${
            data.p_17_cmj_peak_force === null ||
            data.p_17_cmj_peak_force === 0 ||
            data.p_17_cmj_peak_force === "EF" ||
            data.p_17_cmj_peak_force === "AI" ||
            data.p_17_cmj_peak_force === "ART"
              ? "NA"
              : `${data.p_17_cmj_peak_force}`
          }`,
          x: 265,
          y: ["-", 138],
          fontSize: 12,
          color: rgb(0, 0, 0),
          name: "p16_es_peak_propulsive_force",
        },
        {
          value: `${
            data.p_17_dsi_score === null ||
            data.p_17_dsi_score === 0 ||
            data.p_17_dsi_score === "EF" ||
            data.p_17_dsi_score === "AI" ||
            data.p_17_dsi_score === "ART"
              ? "NA"
              : `${data.p_17_dsi_score}`
          }`,
          x: 395,
          y: ["-", 138],
          fontSize: 12,
          color: rgb(0, 0, 0),
          name: "p16_dsi_score",
        },
      ],
    ], // page 16
    [
      [
        {
          value: `${
            data.p_26_reactive_strength_index === null ||
            data.p_26_reactive_strength_index === 0 ||
            data.p_26_reactive_strength_index === "EF" ||
            data.p_26_reactive_strength_index === "AI" ||
            data.p_26_reactive_strength_index === "ART"
              ? "NA"
              : `${data.p_26_reactive_strength_index}`
          }`,
          x: 330,
          y: ["+", 67],
          fontSize: 20,
          color: rgb(0, 0, 0),
          name: "p17_cmj_rsi_no",
        },
        {
          value: `${data && data.p_26_dynamic_strength_index}`,
          x: 510,
          y: ["+", 67],
          fontSize: 20,
          color: rgb(0, 0, 0),
          name: "p17_dsi_score",
        },
        {
          value: `${
            data.p_26_eccentric_strength_index === null ||
            data.p_26_eccentric_strength_index === 0 ||
            data.p_26_eccentric_strength_index === "EF" ||
            data.p_26_eccentric_strength_index === "AI" ||
            data.p_26_eccentric_strength_index === "ART"
              ? "NA"
              : `${data.p_26_eccentric_strength_index}`
          }`,
          x: 700,
          y: ["+", 67],
          fontSize: 20,
          color: rgb(0, 0, 0),
          name: "p17_eur",
        },
        {
          value: `${
            data.p_26_relative_strength_index === null ||
            data.p_26_relative_strength_index === 0 ||
            data.p_26_relative_strength_index === "EF" ||
            data.p_26_relative_strength_index === "AI" ||
            data.p_26_relative_strength_index === "ART"
              ? "NA"
              : `${data.p_26_relative_strength_index}x`
          }`,
          x: 895,
          y: ["+", 67],
          fontSize: 20,
          color: rgb(0, 0, 0),
          name: "p17_ms_peak_force",
        },
      ],
      [
        {
          value: `${
            data.p_26_reactive_strength_index === null ||
            data.p_26_reactive_strength_index === 0 ||
            data.p_26_reactive_strength_index === "EF" ||
            data.p_26_reactive_strength_index === "AI" ||
            data.p_26_reactive_strength_index === "ART"
              ? "NA"
              : `${data.p_26_reactive_strength_index_average}`
          }`,
          x: 330,
          y: ["-", 40],
          fontSize: 20,
          color: rgb(0, 0, 0),
          name: "p17_age_avg_cmj_rsi_no_sum",
        },
        {
          value: `${data && data.p_26_dynamic_strength_index_average}`,
          x: 510,
          y: ["-", 40],
          fontSize: 20,
          color: rgb(0, 0, 0),
          name: "p17_dsi_score_avg_tot",
        },
        {
          value: `${data && data.p_26_eccentric_strength_index_average}`,
          x: 700,
          y: ["-", 40],
          fontSize: 20,
          color: rgb(0, 0, 0),
          name: "p17_avg_eccentric_utilization_ratio",
        },
        {
          value: `${data && data.p_26_relative_strength_index_average}x`,
          x: 895,
          y: ["-", 40],
          fontSize: 20,
          color: rgb(0, 0, 0),
          name: "p17_avg_relative_strength",
        },
      ],
      [
        {
          value: `${
            data.p_26_reactive_strength_index === null ||
            data.p_26_reactive_strength_index === 0 ||
            data.p_26_reactive_strength_index === "EF" ||
            data.p_26_reactive_strength_index === "AI" ||
            data.p_26_reactive_strength_index === "ART"
              ? ""
              : `${data.p_26_reactive_strength_index_benchmark}`
          }`,
          x: 330,
          y: ["-", 138],
          fontSize: 20,
          color: rgb(0, 0, 0),
          name: "p17_average_1",
        },
        {
          value: `${
            data.p_26_relative_strength_index === null ||
            data.p_26_relative_strength_index === 0 ||
            data.p_26_relative_strength_index === "EF" ||
            data.p_26_relative_strength_index === "AI" ||
            data.p_26_relative_strength_index === "ART"
              ? ""
              : `${data.p_26_relative_strength_index_benchmark}`
          }`,
          x: 893,
          y: ["-", 138],
          fontSize: 20,
          color: rgb(0, 0, 0),
          name: "p17_average_4",
        },
      ],
      [
        {
          value: `${
            data.p_26_reactive_strength_index === null ||
            data.p_26_reactive_strength_index === 0 ||
            data.p_26_reactive_strength_index === "EF" ||
            data.p_26_reactive_strength_index === "AI" ||
            data.p_26_reactive_strength_index === "ART"
              ? ""
              : `PERCENTILE`
          }`,
          x: 325,
          y: ["-", 160],
          fontSize: 20,
          color: rgb(0, 0, 0),
          name: "p26_average_1",
        },
        {
          value: `${
            data.p_26_relative_strength_index === null ||
            data.p_26_relative_strength_index === 0 ||
            data.p_26_relative_strength_index === "EF" ||
            data.p_26_relative_strength_index === "AI" ||
            data.p_26_relative_strength_index === "ART"
              ? ""
              : `PERCENTILE`
          }`,
          x: 890,
          y: ["-", 160],
          fontSize: 20,
          color: rgb(0, 0, 0),
          name: "p26_average_4",
        },
      ],
      [
        {
          value: `${
            data.p_26_reactive_strength_index === null ||
            data.p_26_reactive_strength_index === 0 ||
            data.p_26_reactive_strength_index === "EF" ||
            data.p_26_reactive_strength_index === "AI" ||
            data.p_26_reactive_strength_index === "ART"
              ? "NA"
              : ``
          }`,
          x: 325,
          y: ["-", 150],
          fontSize: 20,
          color: rgb(0, 0, 0),
          name: "p26_average_1",
        },
        {
          value: `${
            data.p_26_relative_strength_index === null ||
            data.p_26_relative_strength_index === 0 ||
            data.p_26_relative_strength_index === "EF" ||
            data.p_26_relative_strength_index === "AI" ||
            data.p_26_relative_strength_index === "ART"
              ? "NA"
              : ``
          }`,
          x: 890,
          y: ["-", 150],
          fontSize: 20,
          color: rgb(0, 0, 0),
          name: "p26_average_4",
        },
      ],
    ], // page 17
    [], // page 18
  ];

  // Load a PDFDocument from the existing PDF bytes
  const pdfDoc = await PDFDocument.load(pdfUrl);
  // console.log("pdfDoc", pdfDoc);

  // Embed the HelveticaBold font
  const helveticaFont = await pdfDoc.embedFont(StandardFonts.HelveticaBold);

  // Get the first page of the document
  const pages = pdfDoc.getPages();
  // console.log("pages", pages);

  for (let p = 0; p < pages.length; p++) {
    if (dataRender && dataRender[p]?.length > 0) {
      const {
        // width,
        height,
      } = pages[p].getSize();
      for (let x = 0; x < dataRender[p].length; x++) {
        let drpx = dataRender[p][x];
        for (let y = 0; y < drpx.length; y++) {
          let drpxy = drpx[y];
          let yVal = 0;

          if (drpxy.y[0] === "+") {
            yVal = height / 2 + drpxy.y[1];
          } else {
            yVal = height / 2 - drpxy.y[1];
          }

          const textWidth = helveticaFont.widthOfTextAtSize(
            drpxy.value,
            drpxy.fontSize
          );

          let subtractXPos = textWidth / 2;

          if (display_data && display_data.length > 0) {
            let show_data = display_data.filter(
              (itemFilter) => itemFilter.name === drpxy.name
            );

            let show = 1;
            if (show_data && show_data.length > 0) {
              show = show_data[0].status;
            }

            if (show) {
              pages[p].drawText(`${drpxy.value}`, {
                x: drpxy.posxx ? drpxy.x : drpxy.x - subtractXPos,
                y: yVal,
                size: drpxy.fontSize,
                font: helveticaFont,
                color: drpxy.color,
                // maxWidth: 100,
                // rotate: drpxy.degrees(drpxy.degrees),
              });
            }
          } else {
            pages[p].drawText(`${drpxy.value}`, {
              x: drpxy.posxx ? drpxy.x : drpxy.x - subtractXPos,
              y: yVal,
              size: drpxy.fontSize,
              font: helveticaFont,
              color: drpxy.color,
              // maxWidth: 100,
              // rotate: drpxy.degrees(drpxy.degrees),
            });
          }
        }
      }
    }
  }

  // Serialize the PDFDocument to bytes (a Uint8Array)
  const pdfBytes = await pdfDoc.save();
  download(
    pdfBytes,
    // "2022-June-BFS-New-Professional-Report-BLANK.pdf",
    `${moment().format("YYYY")}-BFS-New-Professional-Report-${data.name}.pdf`,
    "application/pdf"
  );
};

export default pdfPro;
