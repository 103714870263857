import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Collapse,
  Form,
  Input,
  Modal,
  notification,
  Popconfirm,
  Row,
  Space,
  Switch,
  Typography,
} from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faSave } from "@fortawesome/pro-regular-svg-icons";
import {
  DeleteFilled,
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { DELETE, GET, GETMANUAL, POST } from "../../../providers/useAxiosQuery";

import FloatInput from "../../../providers/FloatInput";
import FloatSelect from "../../../providers/FloatSelect";
import FloatTextArea from "../../../providers/FloatTextArea";
import validateRules from "../../../providers/validateRules";
import optionOrganizationType from "../../../providers/optionOrganizationType";
import optionCountryCodes from "../../../providers/optionCountryCodes";
import optionStateCodesCanada from "../../../providers/optionStateCodesCanada";
import optionStateCodesUnitedState from "../../../providers/optionStateCodesUnitedState";

export default function ComponentAthleteOgranization({
  organizationData,
  setOrganizationData,
  athleteId,
}) {
  const [modalVisible, setModalVisible] = useState(false);
  const [createNewOrganization, setCreateNewOrganization] = useState(false);
  const [form] = Form.useForm();

  const [modalVisibleEdit, setModalVisibleEdit] = useState(false);
  const [formEdit] = Form.useForm();

  // If Modal Form is closed, reset form value
  useEffect(() => {
    if (!modalVisible) {
      form.resetFields();
      setCreateNewOrganization(false);
      setSubmitType(false);
      setSubmitTypeKey(0);
      setCity([]);
      setoptionState([]);
      setCoach([]);
    }

    if (!modalVisibleEdit) {
      formEdit.resetFields();
      setCoachEdit([]);
    }
  }, [modalVisible, modalVisibleEdit]);

  // Sport Options
  const [optionSport, setOptionSport] = useState([[]]);
  GET("api/v1/get_all_sport", "get_all_sport", (res) => {
    if (res.data) {
      let arr = [];
      res.data.map((item) => {
        arr.push({
          value: item.sport,
          label: item.sport,
        });
      });
      setOptionSport(arr);
    }
  });

  // useStates for Organizations config
  const [club, setClub] = useState([]);
  const [school, setSchool] = useState([]);
  const [university, setUniversity] = useState([]);
  const [optionOrganizationName, setOptionOrganizationName] = useState([]);

  // Server fetch for Organizations
  const { isLoading: isLoadingGetOrganization } = GET(
    "api/v1/coach_organization",
    "get_organization",
    (res) => {
      if (res.success) {
        setClub(res.club);
        setSchool(res.school);
        setUniversity(res.university);
      }
    }
  );

  // onChange Function for Organization Type Option
  const handleOrganizationTypeOnChange = (val, opt) => {
    if (val === "School") {
      setOptionOrganizationName(school);
    } else if (val === "Club") {
      setOptionOrganizationName(club);
    } else if (val === "University") {
      setOptionOrganizationName(university);
    }
    form.resetFields(["organization_id", "coach_id"]);
    setCoach([]);
  };

  // useState for coach options config
  const [coach, setCoach] = useState([]);
  const [coachEdit, setCoachEdit] = useState([]);

  // onChange function for organization ID option
  const handleOrganizationChange = (val, opt) => {
    if (val) {
      setCoach(opt["data-json"]);
      setCoachEdit(opt["data-json"]);
    } else {
      setCoach([]);
      setCoachEdit([]);
    }
    form.resetFields(["coach_id"]);
    formEdit.resetFields(["coach_id"]);
  };

  // useState for state options config
  const [optionState, setoptionState] = useState([]);
  const [optionStateLabel, setoptionStateLabel] = useState("State");
  const [zipCodePattern, setzipCodePattern] = useState("");
  const [zipCodePatternLabel, setzipCodePatternLabel] = useState("Zip Code");
  // onChange function for Country Option
  const handleCountryOnChange = (val, opt) => {
    if (val === "United States") {
      // United States option state config
      setoptionStateLabel("State");
      setoptionState(optionStateCodesUnitedState());

      // United States zip code config
      setzipCodePatternLabel("Zip Code");
      setzipCodePattern(/(^\d{5}$)|(^\d{5}[ -]\d{4}$)/);
    } else if (val === "Canada") {
      // Canada option state config
      setoptionStateLabel("County");
      setoptionState(optionStateCodesCanada());

      // Canada zip code config
      setzipCodePatternLabel("Postal Code");
      setzipCodePattern(/^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/);
    }
    form.resetFields(["state", "city", "zip"]);
    setCity([]);
  };

  // useState for city options config
  const [city, setCity] = useState([]);

  // onChange function for State Option
  const handleOptionState = (val, opt) => {
    if (val) {
      setCity(opt["data-json"]);
    }
    form.resetFields(["city"]);
  };

  // function for submit modal form
  const onFinish = (values) => {
    console.log("values", values);
    let position = values.position.map((item) => item.role);
    if (athleteId) {
      values = {
        ...values,
        user_id: athleteId,
        position: JSON.stringify(position),
      };
      mutateCreateAthleteOrganization(values, {
        onSuccess: (res) => {
          if (res.success) {
            notification.success({
              message: "Success",
              description: "Successfully Submitted!",
            });
          }
        },
      });
    } else {
      let orgData = [...organizationData];
      let data = [];
      if (values.organization_type === "School") {
        data = school.filter((item) => item.value === values.organization_id);
      } else if (values.organization_type === "Club") {
        data = club.filter((item) => item.value === values.organization_id);
      } else if (values.organization_type === "University") {
        data = university.filter(
          (item) => item.value === values.organization_id
        );
      }

      let orgCoach = [];
      if (values.coach_id && data[0]?.json) {
        orgCoach = data[0]?.json?.filter(
          (item) => item.value === values.coach_id
        );
      }
      if (submitType) {
        if (values.organization_id) {
          orgData[submitTypeKey] = {
            // ...orgData[submitTypeKey],
            ...values,
            position: JSON.stringify(position),
            address1: data[0]?.address1,
            address2: data[0]?.address2,
            city: data[0]?.city,
            country: data[0]?.country,
            name: data[0]?.name,
            state: data[0]?.state,
            zip: data[0]?.zip,
            coach_name: orgCoach[0]?.label,
          };
        } else {
          orgData[submitTypeKey] = {
            // ...orgData[submitTypeKey],
            ...values,
            position: JSON.stringify(position),
            coach_name: orgCoach[0]?.label,
            // organization_id: undefined,
          };
        }
        setOrganizationData(orgData);
      } else {
        if (values.organization_id) {
          orgData = [
            ...orgData,
            {
              ...values,
              position: JSON.stringify(position),
              address1: data[0]?.address1,
              address2: data[0]?.address2,
              city: data[0]?.city,
              country: data[0]?.country,
              name: data[0]?.name,
              state: data[0]?.state,
              zip: data[0]?.zip,
              coach_name: orgCoach[0]?.label,
            },
          ];
        } else {
          orgData = [
            ...orgData,
            {
              ...values,
              position: JSON.stringify(position),
              coach_name: orgCoach[0]?.label,
            },
          ];
        }
        setOrganizationData(orgData);
      }
    }
    setModalVisible(false);
    setCreateNewOrganization(false);
  };

  const {
    mutate: mutateCreateAthleteOrganization,
    isLoading: isLoadingCreateAthleteOrganization,
  } = POST("api/v1/create_athlete_organization_admin", "get_my_organization");

  // click function for click here button
  const handleChangeClickHere = () => {
    form.resetFields([
      "organization_id",
      "name",
      "country",
      "state",
      "address1",
      "address2",
      "city",
      "zip",
      "coach_id",
    ]);
    setCreateNewOrganization(!createNewOrganization);
    setCity([]);
    setoptionState([]);
    setoptionStateLabel("State");
    setzipCodePattern("");
    setzipCodePatternLabel("Zip Code");
    setCoach([]);
  };

  // function for edit organization
  const [submitType, setSubmitType] = useState(false);
  const [submitTypeKey, setSubmitTypeKey] = useState(0);
  const handleEditOrganization = (record, index) => {
    let position = JSON.parse(record.position)?.map((item) => {
      return {
        role: item,
      };
    });

    record = {
      ...record,
      position: position,
    };

    if (athleteId) {
      record = {
        ...record,
        coach_id: position,
        coach_id: record.coach_id ? Number(record.coach_id).toString() : "",
      };
      setModalVisibleEdit(true);
      formEdit.setFieldsValue(record);
    } else {
      if (record.organization_id) {
        setCreateNewOrganization(false);
      } else {
        setCreateNewOrganization(true);
        if (record.country === "United States") {
          // United States option state config
          setoptionStateLabel("State");
          setoptionState(optionStateCodesUnitedState());

          // United States zip code config
          setzipCodePatternLabel("Zip Code");
          setzipCodePattern(/(^\d{5}$)|(^\d{5}[ -]\d{4}$)/);

          // city
          let city = optionStateCodesUnitedState().filter(
            (item) => item.value === record.state
          );
          setCity(city && city[0]?.json ? city[0]?.json : []);
        } else if (record.country === "Canada") {
          // Canada option state config
          setoptionStateLabel("County");
          setoptionState(optionStateCodesCanada());

          // Canada zip code config
          setzipCodePatternLabel("Postal Code");
          setzipCodePattern(/^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/);

          // city
          let city = optionStateCodesCanada().filter(
            (item) => item.value === record.state
          );
          setCity(city && city[0]?.json ? city[0]?.json : []);
        }
      }
      console.log("onEditFinish 1", record);

      form.setFieldsValue(record);
      setModalVisible(true);
      setSubmitType(true);
      setSubmitTypeKey(index);
    }

    if (record.organization_type === "School") {
      setOptionOrganizationName(school);

      let coach = school.filter(
        (item) => item.value === record.organization_id
      );
      setCoach(
        coach && coach.length > 0 && coach[0]?.json ? coach[0]?.json : []
      );
      setCoachEdit(
        coach && coach.length > 0 && coach[0]?.json ? coach[0]?.json : []
      );
      console.log("onEditFinish", coach);
    } else if (record.organization_type === "Club") {
      setOptionOrganizationName(club);

      let coach = club.filter((item) => item.value === record.organization_id);
      setCoach(
        coach && coach.length > 0 && coach[0]?.json ? coach[0]?.json : []
      );
      setCoachEdit(
        coach && coach.length > 0 && coach[0]?.json ? coach[0]?.json : []
      );
    } else if (record.organization_type === "University") {
      setOptionOrganizationName(university);

      let coach = university.filter(
        (item) => item.value === record.organization_id
      );
      setCoach(
        coach && coach.length > 0 && coach[0]?.json ? coach[0]?.json : []
      );
      setCoachEdit(
        coach && coach.length > 0 && coach[0]?.json ? coach[0]?.json : []
      );
    }
  };

  const onEditFinish = (values) => {
    let position = values.position.map((item) => item.role);
    values = {
      ...values,
      position: JSON.stringify(position),
    };
    console.log("onEditFinish", values);
    mutateUpdateAthleteOrganization(values, {
      onSuccess: (res) => {
        if (res.success) {
          notification.success({
            message: "Success",
            description: "Successfully submitted!",
          });
          setModalVisibleEdit(false);
        }
      },
    });
  };

  const {
    mutate: mutateUpdateAthleteOrganization,
    isLoading: isLoadingUpdateAthleteOrganization,
  } = POST("api/v1/update_athlete_organization_admin", "get_my_organization");

  const handleDeleteOrganization = (record, index) => {
    if (athleteId) {
      mutateDelete(
        { id: record.id },
        {
          onSuccess: (res) => {
            if (res.success) {
              notification.success({
                message: "Success",
                description: "Successfully deleted!",
              });
            }
          },
        }
      );
    } else {
      let filteredArray = organizationData.filter((item, key) => key !== index);
      setOrganizationData(filteredArray);

      notification.success({
        message: "Success",
        description: "Successfully deleted!",
      });
    }
  };

  useEffect(() => {
    console.log("organizationData", organizationData);
  }, [organizationData]);

  const { mutate: mutateDelete, isLoading: isLoadingDelete } = DELETE(
    "api/v1/athlete_organization",
    "get_my_organization"
  );

  return (
    <>
      <Button
        type="primary"
        className="btn-main-outline-2"
        size="large"
        onClick={(e) => setModalVisible(true)}
      >
        <FontAwesomeIcon icon={faPlus} className="m-r-xs" />
        Add Organization
      </Button>

      <Row gutter={12} className="m-t-md">
        {organizationData.length > 0 && (
          <>
            {organizationData.map((item, key) => {
              return (
                <Col xs={24} sm={24} md={8} key={key}>
                  <Collapse
                    className="ant-collapse-primary"
                    expandIcon={({ isActive }) =>
                      isActive ? (
                        <span
                          className="ant-menu-submenu-arrow"
                          style={{ color: "#FFF", transform: "rotate(270deg)" }}
                        ></span>
                      ) : (
                        <span
                          className="ant-menu-submenu-arrow"
                          style={{ color: "#FFF", transform: "rotate(90deg)" }}
                        ></span>
                      )
                    }
                    expandIconPosition="right"
                    defaultActiveKey={["0"]}
                  >
                    <Collapse.Panel
                      header={item?.name}
                      key={"0"}
                      className="accordion bg-darkgray-form organization-card"
                    >
                      <Typography.Text
                        ellipsis
                        className="organization-card-name"
                      >
                        {item?.name}
                      </Typography.Text>
                      <br />
                      <Typography.Text>{item?.address1}</Typography.Text>
                      <br />
                      <Typography.Text>{`${item?.city}, ${item?.state}, ${item?.zip}`}</Typography.Text>
                      <br />
                      <Typography.Text>{item?.country}</Typography.Text>
                      <br />
                      <Typography.Text>
                        Organization Type: {item?.organization_type}
                      </Typography.Text>
                      <br />
                      <Typography.Text>
                        Coach Name: {item?.coach_name ? item?.coach_name : "NA"}
                      </Typography.Text>
                      <br />
                      <Typography.Text>Sport: {item?.sport}</Typography.Text>
                      <br />
                      <Typography.Text>
                        Position:{" "}
                        {item?.position
                          ? JSON.parse(item?.position).join(", ")
                          : ""}
                      </Typography.Text>
                      <br />
                      {/* <Typography.Text>
                        Default Organization <Switch defaultChecked size="small" />
                      </Typography.Text> */}

                      <Space className="m-t-md">
                        <Button
                          size="small"
                          type="primary"
                          className="btn-main-outline-2 float-right"
                          onClick={(e) => handleEditOrganization(item, key)}
                        >
                          <EditOutlined />
                        </Button>

                        {organizationData.length !== 1 && (
                          <Popconfirm
                            title="Are you sure to delete this Organization?"
                            onConfirm={(e) =>
                              handleDeleteOrganization(item, key)
                            }
                            okText="Confirm"
                            cancelText="Cancel"
                          >
                            <Button
                              size="small"
                              type="primary"
                              className="btn-main-outline-9 float-right"
                              loading={isLoadingDelete}
                            >
                              <DeleteOutlined />
                            </Button>
                          </Popconfirm>
                        )}
                      </Space>
                    </Collapse.Panel>
                  </Collapse>
                </Col>
              );
            })}
          </>
        )}
      </Row>

      <Modal
        zIndex={3}
        width={600}
        visible={modalVisible}
        onCancel={(e) => setModalVisible(false)}
        title="Athlete Organization Form"
        key="Athlete-Organization-Modal"
        footer={[
          <Space key={"space"}>
            <Button
              key={"submit"}
              type="primary"
              className="btn-main-outline-2"
              size="large"
              onClick={(e) => {
                form
                  .validateFields()
                  .then((values) => {
                    // if (submitType) {
                    //   onEditFinish(values);
                    // } else {
                    //   onFinish(values);
                    // }
                    onFinish(values);
                  })
                  .catch((info) => {
                    notification.warning({
                      message: "Warning",
                      description: "Please fill-up required fields!",
                    });
                  });
              }}
              loading={isLoadingCreateAthleteOrganization}
            >
              <FontAwesomeIcon icon={faSave} className="m-r-xs" />
              Submit
            </Button>
          </Space>,
        ]}
      >
        <Form
          form={form}
          key="Athlete-Organization-Form"
          initialValues={{ position: [""] }}
        >
          <Form.Item
            className="form-select-error"
            name={"organization_type"}
            rules={[validateRules.required]}
          >
            <FloatSelect
              label="Organization Type"
              placeholder="Organization Type"
              options={optionOrganizationType}
              onChange={(val, opt) => handleOrganizationTypeOnChange(val, opt)}
              allowClear
              required
            />
          </Form.Item>

          {!createNewOrganization ? (
            <Form.Item
              className="form-select-error"
              name={"organization_id"}
              rules={[validateRules.required]}
            >
              <FloatSelect
                label="Organization Name"
                placeholder="Organization Name"
                options={optionOrganizationName}
                onChange={handleOrganizationChange}
                loading={isLoadingGetOrganization}
                allowClear
                required
              />
            </Form.Item>
          ) : (
            <Form.Item name={"name"} rules={[validateRules.required]}>
              <FloatInput
                label="Organization Name"
                placeholder="Organization Name"
                required
              />
            </Form.Item>
          )}

          <Typography.Text>
            {!createNewOrganization
              ? "Create new organization"
              : "Cancel new organization"}
            ,{" "}
            <Button
              type="link"
              size="small"
              className="companyColor3 p-l-none"
              onClick={(e) => {
                handleChangeClickHere();
              }}
            >
              click here.
            </Button>
          </Typography.Text>

          {createNewOrganization && (
            <>
              <Form.Item
                className="form-select-error"
                name={"country"}
                rules={[validateRules.required]}
              >
                <FloatSelect
                  label="Country"
                  placeholder="Country"
                  options={optionCountryCodes}
                  onChange={handleCountryOnChange}
                  allowClear
                  required
                />
              </Form.Item>

              <Form.Item
                className="form-select-error"
                name={"state"}
                rules={[validateRules.required]}
              >
                <FloatSelect
                  label={optionStateLabel}
                  placeholder={optionStateLabel}
                  options={optionState}
                  onChange={handleOptionState}
                  allowClear
                  required
                />
              </Form.Item>

              <Form.Item name={"address1"} rules={[validateRules.required]}>
                <FloatInput
                  label="Address 1"
                  placeholder="Address 1"
                  options={[]}
                  required
                />
              </Form.Item>

              <Form.Item name={"address2"}>
                <FloatInput
                  label="Address 2"
                  placeholder="Address 2"
                  options={[]}
                />
              </Form.Item>

              <Form.Item
                className="form-select-error"
                name={"city"}
                rules={[validateRules.required]}
              >
                <FloatSelect
                  label="City"
                  placeholder="City"
                  options={city}
                  allowClear
                  required
                />
              </Form.Item>

              <Form.Item
                name={"zip"}
                rules={[
                  validateRules.required,
                  {
                    pattern: zipCodePattern,
                    message: "Invalid " + zipCodePatternLabel,
                  },
                ]}
              >
                <FloatInput
                  label={zipCodePatternLabel}
                  placeholder={zipCodePatternLabel}
                  required
                />
              </Form.Item>
            </>
          )}

          <Form.Item className="form-select-error" name={"coach_id"}>
            <FloatSelect
              label="Coach's Name (Optional)"
              placeholder="Coach's Name (Optional)"
              options={coach}
              allowClear
            />
          </Form.Item>

          <Form.Item
            className="form-select-error"
            name={"sport"}
            rules={[validateRules.required]}
          >
            <FloatSelect
              label="Sport"
              placeholder="Sport"
              options={optionSport}
              allowClear
              required
            />
          </Form.Item>

          <Form.List name="position">
            {(fields, { add, remove }, { errors }) => (
              <>
                {fields.map(({ key, name, ...restField }) => (
                  <div key={key}>
                    <Row gutter={24}>
                      <Col
                        xs={restField.fieldKey !== 0 ? 18 : 24}
                        sm={restField.fieldKey !== 0 ? 18 : 24}
                        md={restField.fieldKey !== 0 ? 20 : 24}
                      >
                        <Form.Item
                          {...restField}
                          name={[name, "role"]}
                          rules={[
                            {
                              required: true,
                              message: "This field is required.",
                            },
                          ]}
                        >
                          <FloatInput
                            label="Position"
                            placeholder="Position"
                            required
                          />
                        </Form.Item>
                      </Col>

                      <Col
                        xs={restField.fieldKey !== 0 ? 2 : 2}
                        sm={restField.fieldKey !== 0 ? 2 : 2}
                        md={restField.fieldKey !== 0 ? 2 : 2}
                        className="p-l-n"
                      >
                        {restField.fieldKey !== 0 ? (
                          <Button
                            onClick={() => {
                              remove(name);
                            }}
                            className="p-l-n color-9"
                            type="link"
                          >
                            <DeleteFilled />
                          </Button>
                        ) : null}
                      </Col>
                    </Row>
                  </div>
                ))}
                <Form.Item className="m-t-n-sm m-b-sm">
                  <Button
                    onClick={() => {
                      add();
                    }}
                    type="link"
                    className="btn-link-main-3 p-l-none"
                    icon={<PlusOutlined />}
                  >
                    Add Another
                  </Button>
                  <Form.ErrorList errors={errors} />
                </Form.Item>
              </>
            )}
          </Form.List>

          <Form.Item name={"stats"} rules={[validateRules.required]}>
            <FloatTextArea
              label="Your Stats"
              placeholder="Your Stats"
              required
            />
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        zIndex={3}
        width={600}
        visible={modalVisibleEdit}
        onCancel={(e) => setModalVisibleEdit(false)}
        title="Athlete Organization Form"
        key="Athlete-Organization-Modal-Edit"
        footer={[
          <Space key={"space"}>
            <Button
              key={"submit"}
              type="primary"
              className="btn-main-outline-2"
              size="large"
              onClick={(e) => {
                formEdit
                  .validateFields()
                  .then((values) => {
                    onEditFinish(values);
                  })
                  .catch((info) => {
                    notification.warning({
                      message: "Warning",
                      description: "Please fill-up required fields!",
                    });
                  });
              }}
              loading={isLoadingUpdateAthleteOrganization}
            >
              <FontAwesomeIcon icon={faSave} className="m-r-xs" />
              Submit
            </Button>
          </Space>,
        ]}
      >
        <Form
          form={formEdit}
          key="Athlete-Organization-Form"
          initialValues={{ position: [""] }}
        >
          <Form.Item name={"id"} className="hide">
            <Input />
          </Form.Item>

          <Form.Item
            className="form-select-error"
            name={"organization_type"}
            rules={[validateRules.required]}
          >
            <FloatSelect
              label="Organization Type"
              placeholder="Organization Type"
              options={optionOrganizationType}
              onChange={(val, opt) => handleOrganizationTypeOnChange(val, opt)}
              allowClear
              required
            />
          </Form.Item>

          <Form.Item
            className="form-select-error"
            name={"organization_id"}
            rules={[validateRules.required]}
          >
            <FloatSelect
              label="Organization Name"
              placeholder="Organization Name"
              options={optionOrganizationName}
              onChange={handleOrganizationChange}
              loading={isLoadingGetOrganization}
              allowClear
              required
            />
          </Form.Item>

          <Form.Item className="form-select-error" name={"coach_id"}>
            <FloatSelect
              label="Coach's Name (Optional)"
              placeholder="Coach's Name (Optional)"
              options={coachEdit}
              allowClear
            />
          </Form.Item>

          <Form.Item
            className="form-select-error"
            name={"sport"}
            rules={[validateRules.required]}
          >
            <FloatSelect
              label="Sport"
              placeholder="Sport"
              options={optionSport}
              allowClear
              required
            />
          </Form.Item>

          <Form.List name="position">
            {(fields, { add, remove }, { errors }) => (
              <>
                {fields.map(({ key, name, ...restField }) => (
                  <div key={key}>
                    <Row gutter={24}>
                      <Col
                        xs={restField.fieldKey !== 0 ? 18 : 24}
                        sm={restField.fieldKey !== 0 ? 18 : 24}
                        md={restField.fieldKey !== 0 ? 20 : 24}
                      >
                        <Form.Item
                          {...restField}
                          name={[name, "role"]}
                          rules={[
                            {
                              required: true,
                              message: "This field is required.",
                            },
                          ]}
                        >
                          <FloatInput
                            label="Position"
                            placeholder="Position"
                            required
                          />
                        </Form.Item>
                      </Col>

                      <Col
                        xs={restField.fieldKey !== 0 ? 2 : 2}
                        sm={restField.fieldKey !== 0 ? 2 : 2}
                        md={restField.fieldKey !== 0 ? 2 : 2}
                        className="p-l-n"
                      >
                        {restField.fieldKey !== 0 ? (
                          <Button
                            onClick={() => {
                              remove(name);
                            }}
                            className="p-l-n color-9"
                            type="link"
                          >
                            <DeleteFilled />
                          </Button>
                        ) : null}
                      </Col>
                    </Row>
                  </div>
                ))}
                <Form.Item className="m-t-n-sm m-b-sm">
                  <Button
                    onClick={() => {
                      add();
                    }}
                    type="link"
                    className="btn-link-main-3 p-l-none"
                    icon={<PlusOutlined />}
                  >
                    Add Another
                  </Button>
                  <Form.ErrorList errors={errors} />
                </Form.Item>
              </>
            )}
          </Form.List>

          <Form.Item name={"stats"} rules={[validateRules.required]}>
            <FloatTextArea
              label="Your Stats"
              placeholder="Your Stats"
              required
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}
