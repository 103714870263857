import { Card, Collapse, Layout } from "antd";
import React from "react";
import { faHandsUsd } from "@fortawesome/pro-regular-svg-icons";
import ComponentHeader from "../../../Components/ComponentHeader";
import PageFundsPending from "./PageFundsPending";
import PageFundsPaid from "./PageFundsPaid";

export default function PageFundsFunding({ match, permission }) {
  const { Panel } = Collapse;

  return (
    <>
      <ComponentHeader
        title={permission}
        sub_title="SEARCH"
        icon={faHandsUsd}
      />

      <Card className="card-min-height" id="PageFundsFunding">
        <Collapse
          className="main-1-collapse"
          expandIcon={({ isActive }) =>
            isActive ? (
              <span
                className="ant-menu-submenu-arrow"
                style={{ color: "#FFF", transform: "rotate(270deg)" }}
              ></span>
            ) : (
              <span
                className="ant-menu-submenu-arrow"
                style={{ color: "#FFF", transform: "rotate(90deg)" }}
              ></span>
            )
          }
          defaultActiveKey={["1"]}
          expandIconPosition="start"
        >
          <Panel
            header="BFSS PAYMENTS PENDING"
            key="1"
            className="accordion bg-darkgray-form"
          >
            <PageFundsPending />
          </Panel>
        </Collapse>
        <br />

        <Collapse
          className="main-1-collapse"
          expandIcon={({ isActive }) =>
            isActive ? (
              <span
                className="ant-menu-submenu-arrow"
                style={{ color: "#FFF", transform: "rotate(270deg)" }}
              ></span>
            ) : (
              <span
                className="ant-menu-submenu-arrow"
                style={{ color: "#FFF", transform: "rotate(90deg)" }}
              ></span>
            )
          }
          defaultActiveKey={["1"]}
          expandIconPosition="start"
        >
          <Panel
            header="BFSS PAYMENTS PAID"
            key="1"
            className="accordion bg-darkgray-form"
          >
            <PageFundsPaid />
          </Panel>
        </Collapse>
        <br />
      </Card>
    </>
  );
}
