import { faFileInvoiceDollar } from "@fortawesome/pro-regular-svg-icons";
import { Card, Col, Row } from "antd";
import ComponentHeader from "../../Components/ComponentHeader";

export default function PageProfilePaymentReceiptView(props) {
	console.log("PageProfilePaymentReceiptView", props);
	return (
		<>
			<ComponentHeader
				title="Payments & Receipts"
				sub_title="VIEW"
				icon={faFileInvoiceDollar}
			/>

			<Card>
				<Row gutter={12}>
					<Col xs={24} sm={24} md={24}></Col>
				</Row>
			</Card>
		</>
	);
}
