import React, { useState } from "react";
import {
  Card,
  Col,
  Image,
  Row,
  Typography,
  Form,
  Divider,
  Button,
  Radio,
  message,
  Collapse,
  Modal,
  notification,
  Switch,
  Checkbox,
  Popconfirm,
} from "antd";
import ComponentHeader from "../../Components/ComponentHeader";
import FloatInput from "../../../../providers/FloatInput";
import { faEdit } from "@fortawesome/pro-regular-svg-icons";
import companyInfo from "../../../../providers/companyInfo";
import moment from "moment";
import ImgCrop from "antd-img-crop";
import { Upload } from "antd";
import FloatInputPassword from "../../../../providers/FloatInputPassword";
import { GET, POST } from "../../../../providers/useAxiosQuery";
import $ from "jquery";
import FloatInputMask from "../../../../providers/FloatInputMask";
import { Link } from "react-router-dom";
import FloatInputPasswordStrength from "../../../../providers/FloatInputPasswordStrength";
import validateRules from "../../../../providers/validateRules";
import FloatInputMaskNoFeedback from "../../../../providers/FloatInputMaskNoFeedback";
import { MaskedInput } from "antd-mask-input/build/module";

const userData = companyInfo().userData;
const apiUrl = companyInfo().apiUrl;
const encryptor = companyInfo().encryptor;

export default function PageProfileEdit() {
  const [form] = Form.useForm();
  const [modalFormChangePassword] = Form.useForm();

  const [modalChangePasswordVisible, setModalChangePasswordVisible] =
    useState(false);
  const [fileList, setFileList] = useState([]);
  const [radioData, setRadioData] = useState(1);
  const [imageCrop, setImageCrop] = useState({
    width: 1,
    height: 1,
  });
  const [editPermission, setEditPermission] = useState(0);
  const [profileData, setProfileData] = useState([]);

  const [deactivate, setDeactivate] = useState(false);
  GET(
    `api/v1/user_athlete_info?id=${userData.id}`,
    "athletic_user_athlete_info",
    (res) => {
      console.log("profileres", res.data);
      if (res.data) {
        let data = res.data;
        setProfileData(data);
        form.setFieldsValue({
          username: data.username ? data.username : undefined,
          firstname: data.firstname ? data.firstname : undefined,
          lastname: data.lastname ? data.lastname : undefined,
          email: data.email ? data.email : undefined,
          phone_number: data.phone_number ? data.phone_number : undefined,
        });

        setEditPermission(data.edit_permission === "Yes" ? true : false);

        let image_type = data.profile_image
          ? data.profile_image.split("/")
          : "";

        // console.log("image_type", image_type);
        if (image_type[0] !== undefined) {
          image_type =
            image_type[0] === "https:"
              ? data.profile_image
              : companyInfo().apiUrl + data.profile_image;

          setFileList([
            {
              uid: "-1",
              name: "image.png",
              status: "done",
              url: image_type,
            },
          ]);
        } else {
          setFileList([]);
          image_type = "";
        }

        if (Number(res.data.photo_crop) === 1) {
          setRadioData(1);
          setImageCrop({
            width: 1,
            height: 1,
          });
        } else if (Number(res.data.photo_crop) === 2) {
          setRadioData(2);
          setImageCrop({
            width: 3.9,
            height: 2.6,
          });
        } else if (Number(res.data.photo_crop) === 3) {
          setRadioData(3);
          setImageCrop({
            width: 1,
            height: 1.5,
          });
        } else {
          // setRadioData(1);
          setImageCrop({
            width: 1,
            height: 1,
          });
        }

        console.log("radioData", res.data.photo_crop);
      }
    }
  );

  // useEffect(() => {
  // 	console.log("radioData", radioData);
  // }, [radioData]);

  const {
    mutate: mutateUpdateAthleteUser,
    // isLoading: isLoadingUpdateAthleteUser,
  } = POST("api/v1/update_user_athlete_profile", "athletic_user_athlete_info");

  const {
    mutate: mutateUpdateUserPassword,
    isLoading: isLoadingUpdateUserPassword,
  } = POST("api/v1/profile_change_password", "athletic_user_athlete_info");

  const {
    mutate: mutateUserAthleteEditPermission,
    // isLoading: isLoadingUserAthleteEditPermission,
  } = POST("api/v1/edit_permission", "athletic_user_athlete_info");

  const handleResize = (val) => {
    console.log("val", val.target.value);
    setRadioData(val.target.value);
    if (val.target.value === 1) {
      setImageCrop({
        width: 1,
        height: 1,
      });
    } else if (val.target.value === 2) {
      setImageCrop({
        width: 3.9,
        height: 2.6,
      });
    } else if (val.target.value === 3) {
      setImageCrop({
        width: 1,
        height: 1.5,
      });
    }
  };

  const onChangeUpload = ({ fileList: newFileList }) => {
    var _file = newFileList;
    console.log(_file);
    if (_file.length !== 0) {
      _file[0].status = "done";
      setFileList(_file);
      form.submit();
    } else {
      setFileList([]);
    }
  };

  const onPreviewUpload = async (file) => {
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow.document.write(image.outerHTML);
  };

  const beforeUpload = (file) => {
    const isJpgOrPng =
      file.type === "image/jpeg" ||
      file.type === "image/png" ||
      file.type === "image/gif" ||
      file.type === "image/jpg";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG, PNG, GIF, JPEG file!");

      return;
    }
    const isLt2M = file.size / 102400 / 102400 < 10;
    if (!isLt2M) {
      message.error("Image must smaller than 10MB!");

      return;
    }

    return isJpgOrPng && isLt2M;
  };

  const onFinish = (values) => {
    console.log("onFinishInfomation", values);

    const data = new FormData();

    data.append("id", userData.id);
    data.append("photo_crop", radioData);
    data.append("firstname", values.firstname);
    data.append("lastname", values.lastname);
    data.append(
      "phone_number",
      values.phone_number ? values.phone_number : undefined
    );

    Object.keys(values).map(function (key) {
      // console.log("key, values[key]", key, values[key]);
      data.append(key, values[key] ? values[key] : "");
      return "";
    });

    if (fileList.length !== 0) {
      if (fileList[0].uid !== "-1") {
        data.append(
          "profile_image",
          fileList[0].originFileObj,
          fileList[0].name
        );
      }
    }

    mutateUpdateAthleteUser(data, {
      onSuccess: (res) => {
        console.log("res", res);
        if (res.success) {
          notification.success({
            message: "Success",
            description: "Successfully Updated",
          });

          let data = res.data;

          localStorage.userdata = encryptor.encrypt({
            ...data,
          });

          if (data.profile_image) {
            let image_type = data.profile_image.split("/");

            if (image_type[0] === "https:") {
              $(".ant-menu-submenu-profile").attr("src", data.profile_image);
              $(".ant-typography-profile-details-image").attr(
                "src",
                data.profile_image
              );
            } else {
              $(".ant-menu-submenu-profile").attr(
                "src",
                apiUrl + data.profile_image
              );
              $(".ant-typography-profile-details-image").attr(
                "src",
                apiUrl + data.profile_image
              );
            }
          }

          $(".ant-typography-profile-details-name-info").html(
            res.data.firstname + " " + res.data.lastname
          );
        } else {
          notification.success({
            message: "Error",
            description: "Data not updated!",
          });
        }
      },
      onError: (err) => {
        // notification.success({
        // 	message: "Error",
        // 	description: err,
        // });
      },
    });
  };

  const handleOnFinishChangePassword = (values) => {
    // console.log("handleOnFinishChangePassword", values);
    let data = {
      ...values,
      id: userData.id,
    };
    mutateUpdateUserPassword(data, {
      onSuccess: (res) => {
        if (res.success) {
          notification.success({
            message: "Success",
            description: "Successfully Updated",
          });
          setModalChangePasswordVisible(false);
          localStorage.removeItem("userdata");
          localStorage.removeItem("token");
          localStorage.removeItem("userdata_admin");
          localStorage.removeItem("viewas");
          window.location.replace("/");
        } else {
          notification.success({
            message: "Error",
            description: "Data not updated!",
          });
        }
      },
      onError: (err) => {
        // notification.success({
        // 	message: "Error",
        // 	description: err,
        // });
      },
    });
  };

  const onBlurInput = (value, field) => {
    console.log("value", value);
    // console.log("profileData", field);
    // console.log("profileData", profileData);
    let col = profileData[field] ? profileData[field] : null;
    let val = value ? value : null;

    if (col !== val) {
      form.submit();
    }
  };

  const onChangeManagerPermission = (checked) => {
    console.log(`switch to ${checked}`);

    let data = {
      id: userData.id,
      edit_permission: checked ? "Yes" : "No",
    };

    mutateUserAthleteEditPermission(data, {
      onSuccess: (res) => {
        if (res.success) {
          notification.success({
            message: res.message,
            description: res.description,
          });
          setEditPermission(checked);
        } else {
          notification.success({
            message: res.message,
            description: res.description,
          });
        }
      },
    });
  };

  const { mutate: mutateDeactive, isLoading: isLoadingDeactive } = POST(
    "api/v1/user_deactive",
    "user_deactive"
  );

  const handleDeactivate = () => {
    mutateDeactive(
      { id: userData.id, link: window.location.origin },
      {
        onSuccess: (res) => {
          if (res.success) {
            notification.success({
              message: res.message,
              description: res.description,
            });

            let data = encryptor.encrypt(res.data);
            localStorage.setItem("userdata", data);
            console.log("mutateDeactive", data);
            window.location.reload(true);
            // mutateLogout(
            //   { user_id: userdata.id },
            //   {
            //     onSuccess: (res) => {
            //       console.log("userdata", res);
            //       if (res.success) {
            //         localStorage.removeItem("token");
            //         window.location.href = window.location.origin;
            //       }
            //     },
            //   }
            // );
          }
        },
        onError: (err) => {},
      }
    );
  };

  return (
    <>
      <ComponentHeader title="Profile" sub_title="EDIT" icon={faEdit} />

      <Card>
        <Form form={form} onFinish={onFinish}>
          <Row gutter={12}>
            <Col xs={24} sm={24} md={16}>
              <Collapse
                className="main-1-collapse border-none"
                expandIcon={({ isActive }) =>
                  isActive ? (
                    <span
                      className="ant-menu-submenu-arrow"
                      style={{ color: "#FFF", transform: "rotate(270deg)" }}
                    ></span>
                  ) : (
                    <span
                      className="ant-menu-submenu-arrow"
                      style={{ color: "#FFF", transform: "rotate(90deg)" }}
                    ></span>
                  )
                }
                defaultActiveKey={["1", "2"]}
                expandIconPosition="start"
              >
                <Collapse.Panel
                  header="LOGIN INFORMATION"
                  key="1"
                  className="accordion bg-darkgray-form m-b-md border"
                >
                  <Row gutter={12}>
                    <Col xs={24} sm={24} md={24} lg={24}>
                      <Form.Item name="username">
                        <FloatInput
                          label="User Name"
                          placeholder="User Name"
                          disabled={true}
                          required
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={24}>
                      <Form.Item name="email">
                        <FloatInput
                          label="Email"
                          placeholder="Email"
                          disabled={true}
                          required
                          // onBlurInput={onBlurInput}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={24}>
                      <Typography.Link
                        className="companyColor3"
                        onClick={() => setModalChangePasswordVisible(true)}
                      >
                        Change Password
                      </Typography.Link>
                    </Col>
                  </Row>
                </Collapse.Panel>

                <Collapse.Panel
                  header="ATHLETE'S INFORMATION"
                  key="2"
                  className="accordion bg-darkgray-form m-b-md border"
                >
                  <Row gutter={12}>
                    <Col xs={24} sm={24} md={24} lg={12}>
                      <Form.Item
                        name="firstname"
                        rules={[validateRules.required]}
                      >
                        <FloatInput
                          label="First Name"
                          placeholder="First Name"
                          onBlurInput={(e) => onBlurInput(e, "firstname")}
                          required={true}
                        />
                      </Form.Item>
                    </Col>

                    <Col xs={24} sm={24} md={24} lg={12}>
                      <Form.Item
                        name="lastname"
                        rules={[validateRules.required]}
                      >
                        <FloatInput
                          label="Last Name"
                          placeholder="Last Name"
                          onBlurInput={(e) => onBlurInput(e, "lastname")}
                          required={true}
                        />
                      </Form.Item>
                    </Col>

                    <Col xs={24} sm={24} md={24} lg={12}>
                      <Form.Item
                        name="phone_number"
                        rules={[
                          {
                            pattern:
                              /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/,
                            message: "Invalid Phone Number",
                          },
                        ]}
                      >
                        <FloatInputMaskNoFeedback
                          label="Contact Number"
                          placeholder="Contact Number"
                          maskLabel="phone_number"
                          maskType="999-999-9999"
                          onBlurInput={(e) => onBlurInput(e, "phone_number")}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={12}>
                      <FloatInput
                        label="Date Joined"
                        placeholder="Date Joined"
                        value={moment(userData.created_at).format("MM-DD-YYYY")}
                        disabled={true}
                      />
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={24}>
                      <Link className="companyColor1" to="/profile/2fa">
                        <span className="companyColor3">CLICK HERE</span> to
                        enable 2-Factor Authentication (2FA)
                      </Link>
                    </Col>
                  </Row>
                </Collapse.Panel>

                <Collapse.Panel
                  header="MANAGER'S PERMISSION"
                  key="2"
                  className="accordion bg-darkgray-form m-b-md border"
                >
                  <Row gutter={12}>
                    <Col xs={24} sm={24} md={24} lg={16} className="m-t-sm">
                      Enable or disabled to allow BFSS Manager access to your
                      account
                    </Col>
                    <Col
                      xs={24}
                      sm={24}
                      md={24}
                      lg={8}
                      className="m-t-sm text-right"
                    >
                      <Switch
                        checked={editPermission}
                        onChange={onChangeManagerPermission}
                      />
                    </Col>
                  </Row>
                </Collapse.Panel>
              </Collapse>
            </Col>

            <Col xs={24} sm={24} md={8}>
              <Collapse
                className="main-1-collapse border-none"
                expandIcon={({ isActive }) =>
                  isActive ? (
                    <span
                      className="ant-menu-submenu-arrow"
                      style={{ color: "#FFF", transform: "rotate(270deg)" }}
                    ></span>
                  ) : (
                    <span
                      className="ant-menu-submenu-arrow"
                      style={{ color: "#FFF", transform: "rotate(90deg)" }}
                    ></span>
                  )
                }
                defaultActiveKey={["1", "2"]}
                expandIconPosition="start"
              >
                <Collapse.Panel
                  header="PROFILE PHOTO"
                  key="1"
                  className="accordion bg-darkgray-form m-b-md border"
                >
                  <Row gutter={12}>
                    <Col xs={24} sm={24} md={24}>
                      <label className="font-red">
                        <b>
                          Photo upload & cropping: select your image orientation
                        </b>
                      </label>
                      <br />
                      <Radio.Group onChange={handleResize} value={radioData}>
                        <Radio value={1}>Square</Radio>
                        <Radio value={2}>Rectangle</Radio>
                        <Radio value={3}>Portrait</Radio>
                      </Radio.Group>
                    </Col>
                    <Divider />
                    <Col xs={24} sm={24} md={24}>
                      <div style={{ display: "flex" }}>
                        <ImgCrop
                          rotate
                          aspect={imageCrop.width / imageCrop.height}
                        >
                          <Upload
                            // action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                            listType="picture-card"
                            style={{ width: "200px" }}
                            maxCount={1}
                            action={false}
                            customRequest={false}
                            fileList={fileList}
                            onChange={onChangeUpload}
                            onPreview={onPreviewUpload}
                            beforeUpload={beforeUpload}
                            showUploadList={{
                              showPreviewIcon: false,
                            }}
                            className="profilePhoto"
                          >
                            {fileList.length < 1 && "+ Upload"}
                          </Upload>
                        </ImgCrop>
                      </div>
                    </Col>
                    <Divider />
                    <Col xs={24} sm={24} md={24}>
                      {userData.role === "Athlete" ? (
                        <Typography.Text strong>
                          No longer need your account and want to deactivate it?
                          You can request deactivating you account via our
                          ticketing system.
                        </Typography.Text>
                      ) : (
                        <Typography.Text strong>
                          No longer need your Parent / Guardian on your account
                          and want to remove them? You can request Parent /
                          Guardian removal from your account via our ticketing
                          system.
                        </Typography.Text>
                      )}
                    </Col>
                  </Row>
                </Collapse.Panel>
              </Collapse>

              <Collapse
                className="main-1-collapse border-none"
                expandIcon={({ isActive }) =>
                  isActive ? (
                    <span
                      className="ant-menu-submenu-arrow"
                      style={{ color: "#FFF", transform: "rotate(270deg)" }}
                    ></span>
                  ) : (
                    <span
                      className="ant-menu-submenu-arrow"
                      style={{ color: "#FFF", transform: "rotate(90deg)" }}
                    ></span>
                  )
                }
                expandIconPosition="right"
                defaultActiveKey={["0"]}
              >
                <Collapse.Panel
                  header="Deactivate Account"
                  key="0"
                  className="accordion bg-darkgray-form m-b-md border"
                >
                  <div>
                    No longer need your account and want to deactivate it?
                  </div>
                  <br></br>
                  <Checkbox onChange={(e) => setDeactivate(e.target.checked)}>
                    Yes I understand that by deactivating my account I will no
                    longer have access to my account information and all
                    historical data.
                  </Checkbox>
                  <br></br>
                  <br></br>

                  <Popconfirm
                    title="Are you sure to Deactivate this account?"
                    onConfirm={() => handleDeactivate()}
                    okText="Confirm"
                    cancelText="Cancel"
                    disabled={deactivate ? false : true}
                  >
                    <Button
                      size="large"
                      className="btn-main-outline-9"
                      disabled={deactivate ? false : true}
                      block
                      loading={isLoadingDeactive}
                    >
                      Deactivate My Account
                    </Button>
                  </Popconfirm>
                </Collapse.Panel>
              </Collapse>
            </Col>

            {/* <Col xs={24} sm={24} md={6}>
							<Button
								htmlType="submit"
								className="btn-primary-default invert"
								size="large"
								block
								icon={<FontAwesomeIcon icon={faSave} className="m-r-xs" />}
								loading={isLoadingUpdateAthleteUser}
							>
								Save
							</Button>
						</Col> */}
          </Row>
        </Form>
        <br />
      </Card>

      <Modal
        visible={modalChangePasswordVisible}
        title="CHANGE PASSWORD"
        centered
        onCancel={() => setModalChangePasswordVisible(false)}
        footer={
          <>
            <Button
              type="primary"
              htmlType="submit"
              loading={isLoadingUpdateUserPassword}
              className="btn-primary-default"
              size="large"
              style={{ width: "33%" }}
              onClick={() => modalFormChangePassword.submit()}
            >
              UPDATE
            </Button>
          </>
        }
      >
        <Form
          form={modalFormChangePassword}
          onFinish={handleOnFinishChangePassword}
          autoComplete="off"
        >
          <Form.Item
            name="password_1"
            rules={[
              {
                required: true,
                message: "Please input your password!",
              },
              {
                pattern:
                  /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,64}$/,
                message: "Invalid Password",
              },
            ]}
          >
            <FloatInputPasswordStrength
              label="New Password"
              placeholder="New Password"
            />
          </Form.Item>

          <Form.Item
            name="password_2"
            dependencies={["password_1"]}
            rules={[
              {
                required: true,
                message: "Please confirm your password!",
              },
              {
                pattern:
                  /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,64}$/,
                message: "Invalid Password",
              },

              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("password_1") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error(
                      "The two passwords that you entered do not match!"
                    )
                  );
                },
              }),
            ]}
          >
            <FloatInputPasswordStrength
              label="Confirm New Password"
              placeholder="Confirm New Password"
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}
