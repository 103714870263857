import React from "react";
import { Switch, Route } from "react-router-dom";
/** template */
import PrivateRoute from "../PrivateRoute";

import Error404 from "../../views/errors/Error404";
import Error500 from "../../views/errors/Error500";

import PageDashboard from "../../views/private/PageAdmin/PageDashboard/PageDashboard";
import PageUserViewEditActive from "../../views/private/PageManager/PageUser/PageUserViewEditActive";
import PageUserViewEditDeactivated from "../../views/private/PageManager/PageUser/PageUserViewEditDeactivated";
import PageUserCreateAccount from "../../views/private/PageAdmin/PageUser/PageUserCreateAccount";
import PageUserSearchAthletes from "../../views/private/PageManager/PageUser/PageUserSearchAthletes";
// import PageUserTypePermission from "../../views/private/PageAdmin/PageUser/PageUserTypePermission";
import PageOrganizationViewEdit from "../../views/private/PageManager/PageOrganization/PageOrganizationViewEdit";
import PageOrganizationAddNew from "../../views/private/PageManager/PageOrganization/PageOrganizationAddNew";
import PageOrganizationPendingApproval from "../../views/private/PageManager/PageOrganization/PageOrganizationPendingApproval";
import PageEventsCalendarGroupAssessments from "../../views/private/PageAdmin/PageEventsCalendar/PageEventsCalendarGroupAssessments";
import PageEventsCalendarPrivateAssessments from "../../views/private/PageAdmin/PageEventsCalendar/PageEventsCalendarPrivateAssessments";
// import PageAssessmentsPendingAssessments from "../../views/private/PageAdmin/PageAssessments/PageAssessmentsPendingAssessments";
import PageEditableTemplates from "../../views/private/PageAdmin/PageEditableTemplates/PageEditableTemplates";
import PageRevenueManagersPendingPayment from "../../views/private/PageAdmin/PageRevenue/PageRevenueManagersPendingPayment";
import PageRevenueManagersPaid from "../../views/private/PageAdmin/PageRevenue/PageRevenueManagersPaid";
import PageRevenueOrganizationsPendingPayment from "../../views/private/PageAdmin/PageRevenue/PageRevenueOrganizationsPendingPayment";
import PageRevenueOrganizationsPaid from "../../views/private/PageAdmin/PageRevenue/PageRevenueOrganizationsPaid";
import PageFAQ from "../../views/private/PageAdmin/PageFAQ/PageFAQ";
import PageTicketing from "../../views/private/PageAdmin/PageTicketing/PageTicketing";
import PageAccountType from "../../views/private/PageAdmin/PageAccountType/PageAccountType";
import PageMessages from "../../views/private/PageMessages/PageMessages";
import PageProfileEdit from "../../views/private/PageAdmin/PageProfile/PageProfile";
import PageSport from "../../views/private/PageAdmin/PageSport/PageSport";
import PageSchool from "../../views/private/PageAdmin/PageSchool/PageSchool";
import PageUserEdit from "../../views/private/PageManager/PageUser/PageUserEdit";

import PagePendingAssessments from "../../views/private/PageManager/PagePendingAssessments/PagePendingAssessments";
import PageOrganizationUpdateNew from "../../views/private/PageAdmin/PageOrganization/PageOrganizationUpdateNew";
import PageOrganizationEdit from "../../views/private/PageAdmin/PageOrganization/PageOrganizationEdit";
import PageAthletePdfDisplayData from "../../views/private/PageAdmin/PageAthletePdfDisplayData/PageAthletePdfDisplayData";
import PageAthletePdfDisplayDataForm from "../../views/private/PageAdmin/PageAthletePdfDisplayData/PageAthletePdfDisplayDataForm";
import PageUserEditAccount from "../../views/private/PageAdmin/PageUser/PageUserEditAccount";
import PageAssessmentPdf from "../../views/private/PageAdmin/PageAssessmentPdf/PageAssessmentPdf";
import PageAssessmentPdfEdit from "../../views/private/PageAdmin/PageAssessmentPdf/PageAssessmentPdfEdit";
import PageRegistrationFee from "../../views//private/PageAdmin/PageRegistrationFee/PageRegistrationFee";

import ViweAs from "../../views/private/PageAdmin/ViewAs/ViewAs";
import PageNotification from "../../views/private/PageNotification/PageNotification";
import PageCoupon from "../../views/private/PageAdmin/PageCoupon/PageCoupon";
import PageUserCreateAccountAthlete from "../../views/private/PageAdmin/PageUser/PageUserCreateAccountAthlete";
import MaintenanceAdmin from "../../views/private/PageAdmin/Maintenance/Maintenance";
export default function RouteAdmin() {
  return (
    <Switch>
      <PrivateRoute
        exact
        path="/dashboard"
        permission="Dashboard"
        component={PageDashboard}
        breadcrumb={[
          {
            name: "Dashboard",
            link: "/dashboard",
          },
        ]}
      />

      <PrivateRoute
        exact
        path="/account-type/athlete"
        title="Athlete"
        permission="Account Type - Athlete"
        component={PageAccountType}
        breadcrumb={[
          {
            name: "Account Type",
            link: "",
          },
          {
            name: "Athlete",
            link: "/account-type/athlete",
          },
        ]}
      />

      <PrivateRoute
        exact
        path="/account-type/athlete-guardian"
        title="Athlete Guardian"
        permission="Account Type - Athlete Guardian"
        component={PageAccountType}
        breadcrumb={[
          {
            name: "Account Type",
            link: "",
          },
          {
            name: "Athlete Guardian",
            link: "/account-type/athlete-guardian",
          },
        ]}
      />

      <PrivateRoute
        exact
        path="/account-type/coach"
        title="Coach"
        permission="Account Type - Coach"
        component={PageAccountType}
        breadcrumb={[
          {
            name: "Account Type",
            link: "",
          },
          {
            name: "Coach",
            link: "/account-type/coach",
          },
        ]}
      />

      <PrivateRoute
        exact
        path="/user/view-edit-active"
        title="View / Edit Active"
        permission="Active"
        component={PageUserViewEditActive}
        breadcrumb={[
          {
            name: "Users",
            link: "",
          },
          {
            name: "View / Edit Active",
            link: "/user/view-edit-active",
          },
        ]}
      />

      <PrivateRoute
        exact
        path="/user/view-edit-deactivated"
        title="View / Edit Deactivated"
        permission="Deactivated"
        component={PageUserViewEditDeactivated}
        breadcrumb={[
          {
            name: "Users",
            link: "",
          },
          {
            name: "View / Edit Deactivated",
            link: "/user/view-edit-Deactivated",
          },
        ]}
      />

      <PrivateRoute
        exact
        path="/user/create-account"
        title="Create Account"
        permission="Account"
        component={PageUserCreateAccount}
        breadcrumb={[
          {
            name: "Users",
            link: "",
          },
          {
            name: "Create Account",
            link: "/user/create-account",
          },
        ]}
      />

      <PrivateRoute
        exact
        path="/user/create-account-athlete"
        title="Create Athlete"
        permission="Account"
        component={PageUserCreateAccountAthlete}
        breadcrumb={[
          {
            name: "Users",
            link: "",
          },
          {
            name: "Create Athlete",
            link: "/user/create-account-athlete",
          },
        ]}
      />

      <PrivateRoute
        exact
        path="/user/view-account-athlete/:id"
        title="View Athlete"
        permission="Account"
        component={PageUserCreateAccountAthlete}
        breadcrumb={[
          {
            name: "Users",
            link: "",
          },
          {
            name: "View Athlete",
            link: "/user/view-account-athlete",
          },
        ]}
      />

      <PrivateRoute
        exact
        path="/user/edit-account/:id"
        title="Edit Account"
        permission="Account"
        component={PageUserEditAccount}
        breadcrumb={[
          {
            name: "Users",
            link: "",
          },
          {
            name: "Edit Account",
            link: "/user/edit-account",
          },
        ]}
      />

      <PrivateRoute
        exact
        path="/user/edit/:id"
        title="Edit User"
        permission="Edit User"
        component={PageUserEdit}
        breadcrumb={[
          {
            name: "Users",
            link: "#",
          },
          {
            name: "Edit User",
            link: "#",
          },
        ]}
      />

      {/* <PrivateRoute
				exact
				path="/user/user-type-permission"
				title="User Type Permission"
				permission="User Type Permission"
				component={PageUserTypePermission}
				breadcrumb={[
					{
						name: "Users",
						link: "",
					},
					{
						name: "User Type Permission",
						link: "/user/user-type-permission",
					},
				]}
			/> */}

      <PrivateRoute
        exact
        path="/user/search-athletes"
        title="Search Athletes"
        permission="Athletes"
        component={PageUserSearchAthletes}
        breadcrumb={[
          {
            name: "Users",
            link: "#",
          },
          {
            name: "Search Athletes",
            link: "/user/search-athletes",
          },
        ]}
      />

      <PrivateRoute
        exact
        path="/organization/view-edit"
        title="View / Edit Organization"
        permission="Organization"
        component={PageOrganizationViewEdit}
        breadcrumb={[
          {
            name: "Organizations",
            link: "",
          },
          {
            name: "View / Edit Organization",
            link: "/organization/view-edit",
          },
        ]}
      />

      <PrivateRoute
        exact
        path="/organization/edit/:id"
        title="Organizations"
        permission="Organizations"
        component={PageOrganizationEdit}
        breadcrumb={[
          {
            name: "Organizations",
            link: "/organization/view-edit",
          },
          {
            name: "Edit",
            link: "/organization/edit/:id",
          },
        ]}
      />

      <PrivateRoute
        exact
        path="/organization/add-new"
        title="Add New Organization"
        permission="Organization"
        component={PageOrganizationAddNew}
        breadcrumb={[
          {
            name: "Organizations",
            link: "/organization/view-edit",
          },
          {
            name: "Add New Organization",
            link: "/organization/add-new",
          },
        ]}
      />

      <PrivateRoute
        exact
        path="/organization/pending-approval/:state/:city"
        title="Pending Approval"
        permission="Organization Pending Approval"
        component={PageOrganizationUpdateNew}
        breadcrumb={[
          {
            name: "Organizations",
            link: "",
          },
          {
            name: "Pending Approval",
            link: "/organization/pending-approval",
          },
        ]}
      />

      <PrivateRoute
        exact
        path="/organization/pending-approval"
        title="Pending Approval"
        permission="Organization Pending Approval"
        component={PageOrganizationPendingApproval}
        breadcrumb={[
          {
            name: "Organizations",
            link: "",
          },
          {
            name: "Pending Approval",
            link: "/organization/pending-approval",
          },
        ]}
      />

      <PrivateRoute
        exact
        path="/events-calendar/group-assessment"
        title="Group Assessments"
        permission="Events Calendar Group Assessments"
        component={PageEventsCalendarGroupAssessments}
        breadcrumb={[
          {
            name: "Events Calendar",
            link: "",
          },
          {
            name: "Group Assessments",
            link: "/events-calendar/group-assessment",
          },
        ]}
      />

      <PrivateRoute
        exact
        path="/events-calendar/private-assessment"
        title="Private Assessments"
        permission="Assessments"
        component={PageEventsCalendarPrivateAssessments}
        breadcrumb={[
          {
            name: "Events Calendar",
            link: "",
          },
          {
            name: "Private Assessments",
            link: "/events-calendar/private-assessment",
          },
        ]}
      />

      <PrivateRoute
        exact
        path="/sport"
        title="Sport"
        permission="Sport"
        component={PageSport}
        breadcrumb={[
          {
            name: "Sport",
            link: "",
          },
        ]}
      />

      <PrivateRoute
        exact
        path="/school"
        title="School"
        permission="School"
        component={PageSchool}
        breadcrumb={[
          {
            name: "School",
            link: "",
          },
        ]}
      />

      <PrivateRoute
        exact
        path="/assessment/pending-assessment"
        title="Pending Assessments"
        permission="Assessments"
        component={PagePendingAssessments}
        breadcrumb={[
          {
            name: "Assessments",
            link: "",
          },
          {
            name: "Pending Assessments",
            link: "/assessment/pending-assessment",
          },
        ]}
      />

      <PrivateRoute
        exact
        path="/athlete-pdf-display-data"
        title="Athlete Pdf Display Data"
        permission="Athlete Pdf Display Data"
        component={PageAthletePdfDisplayData}
        breadcrumb={[
          {
            name: "Athlete Pdf Display Data",
            link: "/athlete-pdf-display-data",
          },
        ]}
      />

      <PrivateRoute
        exact
        path="/athlete-pdf-display-data/form"
        title="Athlete Pdf Display Data"
        permission="Athlete Pdf Display Data"
        component={PageAthletePdfDisplayDataForm}
        breadcrumb={[
          {
            name: "Athlete Pdf Display Data",
            link: "/athlete-pdf-display-data/form",
          },
        ]}
      />

      <PrivateRoute
        exact
        path="/editable-template"
        title="Editable Templates"
        permission="Editable Templates"
        component={PageEditableTemplates}
        breadcrumb={[
          {
            name: "Editable Templates",
            link: "/editable-template",
          },
        ]}
      />

      <PrivateRoute
        exact
        path="/revenue-manager/pending-payment"
        title="Managers Pending Payment"
        permission="Revenue Managers Pending Payment"
        component={PageRevenueManagersPendingPayment}
        breadcrumb={[
          {
            name: "Revenue / Manager",
            link: "",
          },
          {
            name: "Pending Payment",
            link: "/revenue-manager/pending-payment",
          },
        ]}
      />

      <PrivateRoute
        exact
        path="/revenue-manager/paid"
        component={PageRevenueManagersPaid}
        title="Managers Paid"
        permission="Revenue Managers Paid"
        breadcrumb={[
          {
            name: "Revenue / Manager",
            link: "",
          },
          {
            name: "Paid",
            link: "/revenue-manager/paid",
          },
        ]}
      />

      <PrivateRoute
        exact
        path="/revenue-organization/pending-payment"
        title="Organization Pending Payment"
        permission="Revenue Organization Pending Payment"
        component={PageRevenueOrganizationsPendingPayment}
        breadcrumb={[
          {
            name: "Revenue / Organization",
            link: "",
          },
          {
            name: "Organization Pending Payment",
            link: "/revenue-organization/pending-payment",
          },
        ]}
      />

      <PrivateRoute
        exact
        path="/revenue-organization/paid"
        title="Organization Paid"
        permission="Revenue Organization Paid"
        component={PageRevenueOrganizationsPaid}
        breadcrumb={[
          {
            name: "Revenue / Organization",
            link: "",
          },
          {
            name: "Organization Paid",
            link: "/revenue-organization/paid",
          },
        ]}
      />

      <PrivateRoute
        exact
        path="/faq"
        title="FAQ's"
        permission="FAQ"
        component={PageFAQ}
        breadcrumb={[
          {
            name: "FAQ'S",
            link: "/faq",
          },
        ]}
      />

      <PrivateRoute
        exact
        path="/ticketing"
        title="Ticketing"
        permission="Ticketing"
        component={PageTicketing}
        breadcrumb={[
          {
            name: "Ticketing",
            link: "/ticketing",
          },
        ]}
      />

      <PrivateRoute
        exact
        path="/messages"
        component={PageMessages}
        title="Messages"
        permission="Messages"
        breadcrumb={[
          {
            name: "Messages",
            link: "/messages",
          },
        ]}
      />

      <PrivateRoute
        exact
        path="/profile"
        component={PageProfileEdit}
        title="Edit Profile"
        permission="Athletic Profile - Edit Profile"
        breadcrumb={[
          {
            name: "Dashboard",
            link: "",
          },
          {
            name: "Edit Profile",
            link: "/profile",
          },
        ]}
      />

      <PrivateRoute
        exact
        path="/assessment-pdf"
        component={PageAssessmentPdf}
        title="Assessment PDF"
        permission="Assessment PDF"
        breadcrumb={[
          {
            name: "Dashboard",
            link: "",
          },
          {
            name: "Assessment PDF",
            link: "/assessment-pdf",
          },
        ]}
      />

      <PrivateRoute
        exact
        path="/assessment-pdf/edit/:id"
        component={PageAssessmentPdfEdit}
        title="Assessment PDF"
        permission="Assessment PDF"
        breadcrumb={[
          {
            name: "Dashboard",
            link: "",
          },
          {
            name: "Assessment PDF",
            link: "/assessment-pdf",
          },
          {
            name: "Edit",
            link: "",
          },
        ]}
      />

      <PrivateRoute
        exact
        path="/fee-setup"
        component={PageRegistrationFee}
        title="Fee Setup"
        permission="Fee Setup"
        breadcrumb={[
          {
            name: "Dashboard",
            link: "",
          },
          {
            name: "Fee Setup",
            link: "/fee-setup",
          },
        ]}
      />

      <PrivateRoute
        exact
        path="/notification"
        component={PageNotification}
        title="Notification"
        permission="Notification"
        breadcrumb={[
          {
            name: "Dashboard",
            link: "",
          },
          {
            name: "Notification",
            link: "/notification",
          },
        ]}
      />

      <PrivateRoute
        exact
        path="/coupon"
        component={PageCoupon}
        title="Coupon"
        permission="Coupon"
        breadcrumb={[
          {
            name: "Dashboard",
            link: "",
          },
          {
            name: "Coupon",
            link: "/coupon",
          },
        ]}
      />

      <PrivateRoute
        exact
        path="/viewas"
        component={ViweAs}
        permission="View As"
        breadcrumb={[
          {
            name: "View As",
            link: "",
          },
        ]}
      />
      <PrivateRoute
        exact
        path="/maintenance-configuration"
        component={MaintenanceAdmin}
        permission="View"
        breadcrumb={[
          {
            name: "Maintenance Configuration",
            link: "",
          },
        ]}
      />

      {/* this should always in the bottom */}

      <Route exact path="/500" component={Error500} />
      <Route exact path="/*" component={Error404} />
    </Switch>
  );
}
