import {
  faCommentDots,
  faHandsUsd,
  faHome,
  faQuestionCircle,
  faSearch,
  faStar,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const CoachSideMenu = [
  {
    title: "Dashboard",
    path: "/dashboard",
    icon: <FontAwesomeIcon icon={faHome} style={{ marginTop: "-5px" }} />,
    permission: "Dashboard",
  },
  {
    title: "Search",
    path: "/search",
    icon: <FontAwesomeIcon icon={faSearch} style={{ marginTop: "-5px" }} />,
    permission: "Search",
  },
  {
    title: "Funds",
    path: "/funds",
    icon: <FontAwesomeIcon icon={faHandsUsd} style={{ marginTop: "-5px" }} />,
    className: "menu-item-funds",
    children: [
      {
        title: "Funding",
        path: "/funds/funding",
        permission: "Funds - Funding",
      },
      {
        // title: "Terms & Conditions of Funds",
        title: "Terms & Conditions",
        path: "/funds/terms-conditions-of-funds",
        permission: "Funds - Terms and Conditions of Funds",
      },
    ],
  },
  //   {
  //     title: "Athletes I Follow",
  //     path: "/athletes-follow",
  //     icon: <FontAwesomeIcon icon={faStar}  style={{ marginTop: "-5px" }}/>,
  //     permission: "Athletes I Follow",
  //   },
  {
    title: "Athlete",
    path: "/athlete",
    icon: <FontAwesomeIcon icon={faStar} style={{ marginTop: "-5px" }} />,
    children: [
      {
        title: "My Athletes",
        path: "/athlete/my-athletes",
        permission: "My Athletes",
      },
      {
        title: "Athletes I Follow",
        path: "/athlete/follow",
        permission: "Athletes I Follow",
      },
    ],
  },
  {
    title: "Support",
    path: "/support",
    icon: (
      <FontAwesomeIcon icon={faQuestionCircle} style={{ marginTop: "-5px" }} />
    ),
    children: [
      {
        title: "FAQs",
        path: "/support/faqs",
        permission: "Support - FAQs",
      },
      {
        title: "Ticketing",
        path: "/support/ticketing",
        permission: "Support - Ticketing",
      },
    ],
  },
  {
    title: "Messages",
    path: "/messages",
    icon: (
      <FontAwesomeIcon icon={faCommentDots} style={{ marginTop: "-5px" }} />
    ),
    // permission: "Ticketing",
  },
];

export default CoachSideMenu;
