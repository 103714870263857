import React, { useState, useEffect } from "react";
import {
  Card,
  Form,
  Button,
  Row,
  Col,
  Radio,
  Divider,
  message,
  Collapse,
  notification,
} from "antd";
import ComponentHeader from "../../Components/ComponentHeader";
import ImgCrop from "antd-img-crop";
import { Upload } from "antd";
import moment from "moment";
import FloatInput from "../../../../providers/FloatInput";
import { faUser } from "@fortawesome/pro-regular-svg-icons";

import ModalProfileChangePassword from "../../Components/ModalProfileChangePassword";
import { GET, POST } from "../../../../providers/useAxiosQuery";
import companyInfo from "../../../../providers/companyInfo";
import FloatInputMask from "../../../../providers/FloatInputMask";
import FloatDatePickerForm from "../../../../providers/FloatDatePickerForm";
import FloatDatePicker from "../../../../providers/FloatDatePicker";
import FloatInputMaskNoFeedback from "../../../../providers/FloatInputMaskNoFeedback";

export default function PageUserEdit({ match, permission }) {
  const [form] = Form.useForm();
  const { Panel } = Collapse;
  const user_id = match.params.id;

  const validator = {
    require: {
      required: true,
      message: "This field is required.",
    },
  };

  const [fileList, setFileList] = useState([]);
  const [radioData, setRadioData] = useState(1);
  const [imageCrop, setImageCrop] = useState({
    width: 1,
    height: 1,
  });

  const handleResize = (val) => {
    console.log("val", val.target.value);
    setRadioData(val.target.value);
    if (val.target.value === 1) {
      setImageCrop({
        width: 1,
        height: 1,
      });
    } else if (val.target.value === 2) {
      setImageCrop({
        width: 3.9,
        height: 2.6,
      });
    } else if (val.target.value === 3) {
      setImageCrop({
        width: 1,
        height: 1.5,
      });
    }
  };

  const onChange = ({ fileList: newFileList }) => {
    var _file = newFileList;
    console.log(_file);
    if (_file.length != 0) {
      _file[0].status = "done";
      setFileList(_file);
      setDataForm("update_image");
    } else {
      // setDataForm("update_image_remove");
      setFileList([]);
    }
  };

  const onPreview = async (file) => {
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow.document.write(image.outerHTML);
  };

  const beforeUpload = (file) => {
    const isJpgOrPng =
      file.type === "image/jpeg" ||
      file.type === "image/png" ||
      file.type === "image/gif" ||
      file.type === "image/jpg";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG, PNG, GIF, JPEG file!");
      setFileList([]);
      return;
    }
    const isLt2M = file.size / 102400 / 102400 < 10;
    if (!isLt2M) {
      message.error("Image must smaller than 10MB!");
      setFileList([]);
      return;
    }
    return isJpgOrPng && isLt2M;
  };

  const onFinish = (values) => {
    console.log("onFinish", values);
    const data = new FormData();
    data.append("id", user_id);
    data.append("birthdate", moment(values.birthdate).format("YYYY-MM-DD"));
    data.append("phone_number", values.phone_number);
    data.append("firstname", values.firstname);
    data.append("lastname", values.lastname);

    data.append("photo_crop", radioData);
    if (fileList.length !== 0) {
      if (fileList[0].uid != "-1") {
        data.append(
          "profile_image",
          fileList[0].originFileObj,
          fileList[0].name
        );
      }
    } else {
      console.log("fileList", fileList);
    }

    mudateUpdate(data, {
      onSuccess: (res) => {
        if (res.success) {
          notification.success({
            message: "Success",
            description: "Successfully updated.",
          });
        }
      },
    });
  };

  const { mutate: mudateUpdate, isLoading: isLoadingUpdate } = POST(
    "api/v1/update_user",
    "update_user"
  );

  const [isChangePasswordVisible, setIsChangePasswordVisible] = useState(false);

  const [profileData, setProfileData] = useState([]);
  const { isLoading: isLoadingGet } = GET(
    `api/v1/users/${user_id}`,
    "update_user",
    (res) => {
      if (res.success) {
        console.log("update_profile", res);
        setProfileData(res.data);
        form.setFieldsValue({
          username: res.data.username,
          email: res.data.email,
          firstname: res.data.firstname,
          lastname: res.data.lastname,
          birthdate: res.data.birthdate,
          phone_number: res.data.phone_number,
        });
        if (res.data.birthdate === null || res.data.birthdate === "") {
          form.resetFields(["birthdate"]);
        }
        if (res.data.phone_number === null || res.data.phone_number === "") {
          form.resetFields(["phone_number"]);
        }

        if (Number(res.data.photo_crop) === 1) {
          setImageCrop({
            width: 1,
            height: 1,
          });
          setRadioData(1);
        } else if (Number(res.data.photo_crop) === 2) {
          setImageCrop({
            width: 3.9,
            height: 2.6,
          });
          setRadioData(2);
        } else if (Number(res.data.photo_crop) === 3) {
          setImageCrop({
            width: 1,
            height: 1.5,
          });
          setRadioData(3);
        } else {
          setImageCrop({
            width: 1,
            height: 1,
          });
          setRadioData(1);
        }

        let image_type = res.data.profile_image
          ? res.data.profile_image.split("/")
          : "";

        console.log("image_type", image_type);
        if (image_type[0] != undefined) {
          image_type =
            image_type[0] == "https:"
              ? res.data.profile_image
              : companyInfo().apiUrl + res.data.profile_image;

          setFileList([
            {
              uid: "-1",
              name: "image.png",
              status: "done",
              url: image_type,
            },
          ]);
        } else {
          setFileList([]);
          image_type = "";
        }
      }
    }
  );

  const [dataForm, setDataForm] = useState("");
  // useEffect(() => {
  //   if (dataForm) {
  //     console.log("dataForm", dataForm);
  //     form.submit();
  //     setDataForm("");
  //   }
  //   // const timeoutId = setTimeout(() => {
  //   // }, 5000);
  //   // return () => {
  //   //   clearTimeout(timeoutId);
  //   // };
  // }, [dataForm]);

  const handleInputBlur = (value, field) => {
    let col = profileData[field] ? profileData[field] : null;
    let val = value ? value : null;
    if (field === "birthdate") {
      val = value ? moment(value.target.value).format("YYYY-MM-DD") : null;
    }
    if (col !== val) {
      form.submit();
    }
  };

  return (
    <>
      <ComponentHeader title={"User"} sub_title="VIEW/EDIT" icon={faUser} />

      <ModalProfileChangePassword
        toggleModalPreview={isChangePasswordVisible}
        setToggleModalPreview={setIsChangePasswordVisible}
        profileData={profileData}
        clear={true}
      />
      <Card className="p-b-lg card-min-height" id="PageUserEdit">
        <Form
          name="assessment-form"
          form={form}
          onFinish={onFinish}
          loading={isLoadingUpdate || isLoadingGet}
        >
          <Row gutter={12}>
            <Col xs={24} sm={24} md={16}>
              <Collapse
                className="main-1-collapse"
                expandIcon={({ isActive }) =>
                  isActive ? (
                    <span
                      className="ant-menu-submenu-arrow"
                      style={{ color: "#FFF", transform: "rotate(270deg)" }}
                    ></span>
                  ) : (
                    <span
                      className="ant-menu-submenu-arrow"
                      style={{ color: "#FFF", transform: "rotate(90deg)" }}
                    ></span>
                  )
                }
                defaultActiveKey={["1"]}
                expandIconPosition="start"
              >
                <Panel
                  header="LOGIN INFORMATION"
                  key="1"
                  className="accordion bg-darkgray-form"
                >
                  <Row gutter={24}>
                    <Col xs={24} sm={24} md={24}>
                      <Form.Item name="username">
                        <FloatInput
                          label="Username"
                          placeholder="Username"
                          disabled={true}
                          required
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={24}>
                      <Form.Item name="email">
                        <FloatInput
                          label="Email"
                          placeholder="Email"
                          disabled={true}
                          required
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row gutter={24}>
                    <Col xs={24} sm={24} md={24}>
                      <Button
                        type="link"
                        className="btn-link-main-3 btn-p-0"
                        onClick={(e) => setIsChangePasswordVisible(true)}
                      >
                        Change Password
                      </Button>
                    </Col>
                  </Row>
                </Panel>
              </Collapse>

              <br />
              <Collapse
                className="main-1-collapse"
                expandIcon={({ isActive }) =>
                  isActive ? (
                    <span
                      className="ant-menu-submenu-arrow"
                      style={{ color: "#FFF", transform: "rotate(270deg)" }}
                    ></span>
                  ) : (
                    <span
                      className="ant-menu-submenu-arrow"
                      style={{ color: "#FFF", transform: "rotate(90deg)" }}
                    ></span>
                  )
                }
                defaultActiveKey={["1"]}
                expandIconPosition="start"
              >
                <Panel
                  header="USER'S INFORMATION"
                  key="1"
                  className="accordion bg-darkgray-form"
                >
                  <Row gutter={24}>
                    <Col xs={24} sm={24} md={24}>
                      <Form.Item name="firstname" rules={[validator.require]}>
                        <FloatInput
                          label="First Name"
                          placeholder="First Name"
                          onBlurInput={(e) => handleInputBlur(e, "firstname")}
                          disabled={
                            companyInfo().userData.role === "Admin"
                              ? false
                              : true
                          }
                          required
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={24}>
                      <Form.Item name="lastname" rules={[validator.require]}>
                        <FloatInput
                          label="Last Name"
                          placeholder="Last Name"
                          onBlurInput={(e) => handleInputBlur(e, "lastname")}
                          disabled={
                            companyInfo().userData.role === "Admin"
                              ? false
                              : true
                          }
                          required
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row gutter={24}>
                    <Col xs={24} sm={24} md={24}>
                      <Form.Item name="birthdate" rules={[validator.require]}>
                        <FloatDatePickerForm
                          label="Birth Date"
                          placeholder="Birth Date"
                          onBlurInput={(e) => handleInputBlur(e, "birthdate")}
                          format={"MM/DD/YYYY"}
                          required
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={24}>
                      <Form.Item
                        name="phone_number"
                        rules={[
                          validator.require,
                          {
                            pattern:
                              /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/,
                            message: "Invalid Phone Number",
                          },
                        ]}
                      >
                        <FloatInputMaskNoFeedback
                          label="Phone Number"
                          placeholder="Phone Number"
                          maskLabel="phone_number"
                          maskType="999-999-9999"
                          onBlurInput={(e) =>
                            handleInputBlur(e, "phone_number")
                          }
                          required
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Panel>
              </Collapse>
            </Col>

            <Col xs={24} sm={24} md={8}>
              <Collapse
                className="main-1-collapse"
                expandIcon={({ isActive }) =>
                  isActive ? (
                    <span
                      className="ant-menu-submenu-arrow"
                      style={{ color: "#FFF", transform: "rotate(270deg)" }}
                    ></span>
                  ) : (
                    <span
                      className="ant-menu-submenu-arrow"
                      style={{ color: "#FFF", transform: "rotate(90deg)" }}
                    ></span>
                  )
                }
                defaultActiveKey={["1"]}
                expandIconPosition="start"
              >
                <Panel
                  header="User's Profile Photo"
                  key="1"
                  className="accordion bg-darkgray-form"
                >
                  <label className="font-red">
                    <b>
                      Photo upload & cropping: select your image orientation
                    </b>
                  </label>
                  <br />
                  <Radio.Group onChange={handleResize} value={radioData}>
                    <Radio value={1}>Square</Radio>
                    <Radio value={2}>Rectangle</Radio>
                    <Radio value={3}>Portrait</Radio>
                  </Radio.Group>
                  <Divider />
                  <div style={{ display: "flex" }}>
                    <ImgCrop rotate aspect={imageCrop.width / imageCrop.height}>
                      <Upload
                        // action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                        listType="picture-card"
                        style={{ width: "200px" }}
                        maxCount={1}
                        action={false}
                        customRequest={false}
                        fileList={fileList}
                        onChange={onChange}
                        onPreview={onPreview}
                        beforeUpload={beforeUpload}
                        className="profilePhoto"
                        showUploadList={{
                          showPreviewIcon: false,
                        }}
                      >
                        {fileList.length < 1 && "+ Upload"}
                      </Upload>
                    </ImgCrop>
                  </div>
                </Panel>
              </Collapse>
            </Col>
          </Row>

          {/* <Row gutter={12}>
            <Col xs={24} sm={24} md={8}>
              <br />
              <Button
                htmlType="submit"
                className="btn-main-outline-2"
                size="large"
                loading={isLoadingUpdate}
              >
                <FontAwesomeIcon icon={faSave} /> &nbsp; SAVE ALL CHANGES
              </Button>
            </Col>
          </Row> */}
        </Form>
        <br />
      </Card>
    </>
  );
}
