import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row, Space, Typography } from "antd";
import NumberFormat from "react-number-format";
import FloatInput from "../../../../../providers/FloatInput";
import FloatInputMask from "../../../../../providers/FloatInputMask";
import FloatSelect from "../../../../../providers/FloatSelect";
import FloatInputMaskNoFeedback from "../../../../../providers/FloatInputMaskNoFeedback";

export default function BookComponentStep3(props) {
  const {
    freeCreditAmount,
    amountValue,
    optionCountryCodes,
    setOptionState,
    stateUS,
    setOptionBillingZip,
    setOptionStateLabel,
    setZipLabel,
    stateCA,
    optionStateLabel,
    optionState,
    optionBillingZip,
    zipLabel,
    stepData,
    setStepData,
    setCurrent,
    current,
    handleForm3,
  } = props;
  const [form] = Form.useForm();

  const handleBillingCountry = (e, opt) => {
    if (e === "United States") {
      setOptionState(stateUS);
      setOptionBillingZip(/(^\d{5}$)|(^\d{5}[ -]\d{4}$)/);
      setOptionStateLabel("State");
      setZipLabel("Zip Code");
    } else if (e === "Canada") {
      setOptionState(stateCA);
      setOptionBillingZip(/^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/);
      setOptionStateLabel("County");
      setZipLabel("Postal Code");
    } else {
      setOptionState(stateUS);
      setOptionBillingZip(/(^\d{5}$)|(^\d{5}[ -]\d{4}$)/);
      setOptionStateLabel("State");
      setZipLabel("Zip Code");
    }

    form.resetFields(["billing_state", "billing_zip", "billing_city"]);
  };

  const [cityOption, setCityOption] = useState([]);
  const handleStateOption = (val, opt) => {
    if (val) {
      let option = opt["data-json"];
      console.log("val", opt);
      setCityOption(option);
    } else {
      setCityOption([]);
    }
    console.log("getFieldsValue", form.getFieldsValue());
    form.resetFields(["billing_city"]);
  };

  useEffect(() => {
    console.log("stepData", stepData[3]);
    if (stepData[3]?.step === "process") {
      form.setFieldsValue({
        // ...stepData[3]?.data,
        billing_address2: stepData[3]?.data.billing_address2
          ? stepData[3]?.data.billing_address2
          : undefined,
        billing_address1: stepData[3]?.data.billing_address1
          ? stepData[3]?.data.billing_address1
          : undefined,
        billing_city: stepData[3]?.data.billing_city
          ? stepData[3]?.data.billing_city
          : undefined,
        billing_country: stepData[3]?.data.billing_country
          ? stepData[3]?.data.billing_country
          : undefined,
        billing_state: stepData[3]?.data.billing_state
          ? stepData[3]?.data.billing_state
          : undefined,
        billing_zip: stepData[3]?.data.billing_zip
          ? stepData[3]?.data.billing_zip
          : undefined,
        card_cvv: stepData[3]?.data.card_cvv
          ? stepData[3]?.data.card_cvv
          : undefined,
        card_expiry: stepData[3]?.data.card_expiry
          ? stepData[3]?.data.card_expiry
          : undefined,
        card_name: stepData[3]?.data.card_name
          ? stepData[3]?.data.card_name
          : undefined,
        card_number: stepData[3]?.data.card_number
          ? stepData[3]?.data.card_number
          : undefined,
      });
    }
  }, [stepData]);

  return (
    <Form form={form} onFinish={handleForm3}>
      <Row gutter={12}>
        <Col span={24}>
          <div className="book-assessment-payment-info">
            <div>
              <Typography.Text strong>
                Please add your payment details to continue.
              </Typography.Text>
            </div>

            <div className="book-assessment-payment-total-info">
              <table>
                {freeCreditAmount && (
                  <thead>
                    <tr>
                      <th className="p-b-md text-left">Credit Balance:</th>
                      <th className="p-b-md"></th>
                      <th className="p-b-md text-left">
                        <NumberFormat
                          value={
                            freeCreditAmount &&
                            freeCreditAmount.data.registration_credit_amount
                          }
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"$"}
                        />
                      </th>
                    </tr>
                  </thead>
                )}
                <tbody>
                  <tr>
                    <td className="font-bold">Assessment:</td>
                    <td></td>
                    <td className="font-bold">
                      <NumberFormat
                        value={amountValue.service_amount}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={"$"}
                      />
                    </td>
                  </tr>

                  {freeCreditAmount && (
                    <>
                      <tr>
                        <td className="font-bold">Credit Amount:</td>
                        <td className="font-bold">-</td>
                        <td className="font-bold">
                          <NumberFormat
                            value={
                              freeCreditAmount &&
                              freeCreditAmount.data.registration_credit_amount
                            }
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$"}
                          />
                        </td>
                      </tr>
                      {amountValue?.credit_bal && (
                        <tr>
                          <td className="font-bold">Credit Remaining:</td>
                          <td className="font-bold"></td>
                          <td className="font-bold">
                            <NumberFormat
                              value={amountValue?.credit_bal}
                              displayType={"text"}
                              thousandSeparator={true}
                              prefix={"$"}
                            />
                          </td>
                        </tr>
                      )}
                    </>
                  )}

                  {amountValue.discount_label && (
                    <tr>
                      <td className="font-bold">Discount:</td>
                      <td></td>
                      <td className="font-bold">
                        {amountValue.discount_label}
                      </td>
                    </tr>
                  )}
                  <tr>
                    <td className="font-bold">Total:</td>
                    <td></td>
                    <td className="font-bold">
                      <NumberFormat
                        value={amountValue.total_amount}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={"$"}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </Col>

        <Col span={24} className="m-t-xl">
          <Typography.Text strong>Credit Card Information</Typography.Text>
        </Col>

        <Col xs={24} sm={24} md={12}>
          <Form.Item
            name="card_name"
            rules={[
              {
                required: true,
                message: "This field state is required.",
              },
            ]}
          >
            <FloatInput
              label="Name on the card"
              placeholder="Name on the card"
              required
            />
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={12}>
          <Form.Item
            name="card_number"
            rules={[
              {
                required: true,
                message: "This field state is required.",
              },
            ]}
          >
            <FloatInputMaskNoFeedback
              label="Credit Card Number"
              placeholder="Credit Card Number"
              maskLabel="credit_card_number"
              required
            />
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={12}>
          <Form.Item
            name="card_expiry"
            rules={[
              {
                required: true,
                message: "This field state is required.",
              },
            ]}
          >
            <FloatInputMaskNoFeedback
              label="Expiration"
              placeholder="Expiration"
              maskLabel="card_expiry"
              maskType="99/99"
              required
            />
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={12}>
          <Form.Item
            name="card_cvv"
            rules={[
              {
                required: true,
                message: "This field state is required.",
              },
            ]}
          >
            <FloatInputMaskNoFeedback
              label="Security Code (CVV)"
              placeholder="Security Code (CVV)"
              maskLabel="card_cvv"
              maskType="999"
              required
            />
          </Form.Item>
        </Col>

        <Col span={24} className="m-t-xl">
          <Typography.Text strong>Billing Address</Typography.Text>
        </Col>

        <Col xs={24} sm={24} md={12}>
          <Form.Item
            name="billing_address1"
            rules={[
              {
                required: true,
                message: "This field state is required.",
              },
            ]}
          >
            <FloatInput label="Address 1" placeholder="Address 1" required />
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={12}>
          <Form.Item name="billing_address2">
            <FloatInput label="Address 2" placeholder="Address 2" />
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={12}>
          <Form.Item
            name="billing_country"
            rules={[
              {
                required: true,
                message: "This field state is required.",
              },
            ]}
            className="form-select-error"
          >
            <FloatSelect
              label="Country"
              placeholder="Country"
              options={optionCountryCodes}
              onChange={handleBillingCountry}
              required
            />
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={12}>
          <Form.Item
            name="billing_state"
            rules={[
              {
                required: true,
                message: "This field state is required.",
              },
            ]}
            className="form-select-error"
          >
            <FloatSelect
              label={optionStateLabel}
              placeholder={optionStateLabel}
              options={optionState}
              onChange={handleStateOption}
              required
            />
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={12}>
          <Form.Item
            name="billing_city"
            rules={[
              {
                required: true,
                message: "This field state is required.",
              },
            ]}
            className="form-select-error"
          >
            <FloatSelect
              label="City"
              placeholder="City"
              options={cityOption}
              required
            />
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={12}>
          <Form.Item
            name="billing_zip"
            rules={[
              {
                required: true,
                message: "This field is required.",
              },
              {
                pattern: optionBillingZip,
                message: "Invalid " + zipLabel,
              },
            ]}
          >
            <FloatInput label={zipLabel} placeholder={zipLabel} required />
          </Form.Item>
        </Col>

        <Col span={24}>
          <Space
            className="m-t-lg"
            style={{ display: "flex", flexDirection: "row-reverse" }}
          >
            <Button htmlType="submit" className="btn-main-outline-2">
              Next
            </Button>
            <Button
              className="btn-main-outline-2"
              onClick={() => {
                let stepDataTemp = [...stepData];
                stepDataTemp[2] = {
                  ...stepDataTemp[2],
                  step: "process",
                };
                stepDataTemp[3] = {
                  ...stepDataTemp[3],
                  step: "wait",
                };
                setStepData(stepDataTemp);
                setCurrent(current - 1);
              }}
            >
              Back
            </Button>
          </Space>
        </Col>
      </Row>
    </Form>
  );
}
