import { useEffect, useState } from "react";
import {
  Avatar,
  Button,
  Col,
  Divider,
  Image,
  Layout,
  Row,
  Space,
  Typography,
} from "antd";
import { FaShareAlt, FaYoutube, FaInstagram, FaRegStar } from "react-icons/fa";
import { RiSingleQuotesR } from "react-icons/ri";

import companyInfo from "../../../../providers/companyInfo";
import logo from "../../../../assets/img/BFSS-CondensedLogo.png";
import BFSSAthlete from "../../../../assets/img/BFSSAthlete.jpg";

import Highcharts from "highcharts";

const name = companyInfo().name;

export default function PageSearchAthleteProfile(props) {
  // console.log("props", props);
  const { match } = props;
  let id = match.params.id;

  const [dataInfo, setDataInfo] = useState();

  useEffect(() => {
    const dataSource = [
      {
        key: "1",
        firstname: "Christoper",
        lastname: "Roberts",
        organization_name: "Williams Field High School",
        organization_type: "School",
        state: "AZ",
        city: "Gilbert",
      },
      {
        key: "2",
        firstname: "Hayden",
        lastname: "Blake",
        organization_name: "San Tan Charter School Campus 1",
        organization_type: "School",
        state: "TX",
        city: "Austin",
      },
      {
        key: "3",
        firstname: "Sam",
        lastname: "Knorr",
        organization_name: "Chandler Hisgh School",
        organization_type: "School",
        state: "AZ",
        city: "Chandler",
      },
      {
        key: "4",
        firstname: "Jessica",
        lastname: "Collinsworth",
        organization_name: "BFSS - Gilbert, AZ",
        organization_type: "School",
        state: "CA",
        city: "Whittler",
      },
      {
        key: "5",
        firstname: "Cayden",
        lastname: "Holmes",
        organization_name: "Williams Field High School",
        organization_type: "Club",
        state: "FL",
        city: "Datona",
      },
      {
        key: "6",
        firstname: "Harley",
        lastname: "Wallace",
        organization_name: "BFSS - Columbus, OH",
        organization_type: "University",
        state: "AZ",
        city: "Gilbert",
      },
    ];

    let dataSourceFiltered = dataSource.filter((item) => item.key === id);
    if (dataSourceFiltered) {
      setDataInfo(dataSourceFiltered[0]);
    }

    Highcharts.chart("graphPie", {
      credits: false,
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        backgroundColor: null,
        type: "pie",
      },
      title: {
        text: "",
      },
      legend: false,
      tooltip: {
        pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>",
      },
      accessibility: {
        point: {
          valueSuffix: "%",
        },
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: "pointer",
          dataLabels: {
            enabled: false,
            format: "<b>{point.name}</b>: {point.percentage:.1f} %",
          },
        },
      },
      series: [
        {
          name: "YOUR BENCHMARK",
          colorByPoint: true,
          colors: ["#f56900", "#6d6e71"],
          data: [
            {
              name: "HIGH",
              y: 45,
            },
            {
              name: "LOW",
              y: 55,
            },
          ],
        },
      ],
    });
    return () => {};
  }, [id]);

  return (
    <Layout className="layout-athlete-preview">
      <Layout.Header style={{ height: "100px" }}>
        <Row style={{ height: "100%" }}>
          <Col span={6} className="text-right">
            <Typography.Link>
              <Avatar size={60} src={logo} shape="square" />
            </Typography.Link>
          </Col>
          <Col span={12}></Col>
          <Col span={6}></Col>
        </Row>
      </Layout.Header>

      <Layout.Content style={{ padding: "30px 160px" }}>
        <Row gutter={12}>
          <Col span={24}>
            <Typography.Title level={3} className="athletes-title">
              {dataInfo && dataInfo.firstname} {dataInfo && dataInfo.lastname}
            </Typography.Title>
          </Col>
          <Col span={10} className="col-left">
            <Row>
              <Col span={24} className="athletes-sub-title">
                <Space split={<Divider type="vertical" />}>
                  <Typography.Title level={5}>
                    Woman's Volleyball
                  </Typography.Title>
                  <Typography.Title level={5}>Middle Blocker</Typography.Title>
                </Space>
              </Col>
              <Col span={24} className="athletes-social">
                <Space split={<Divider type="vertical" />}>
                  <Space>
                    <FaShareAlt />
                    <FaYoutube />
                    <FaInstagram />
                  </Space>
                  <Space>
                    <FaRegStar />
                    <Typography.Text>
                      COACHES - FOLLOW THIS ATHLETE
                    </Typography.Text>
                  </Space>
                </Space>
              </Col>
              <Col span={24} className="m-t-lg">
                <Space style={{ display: "flex", alignItems: "flex-start" }}>
                  <Space direction="vertical">
                    <Image src={BFSSAthlete} />

                    <Button
                      className="btn-primary-default active"
                      block
                      size="large"
                    >
                      EMAIL ATHLETE
                    </Button>
                  </Space>
                  <Space direction="vertical" style={{ display: "flex" }}>
                    <Typography.Title level={5} style={{ margin: "0px" }}>
                      Female
                    </Typography.Title>
                    <Typography.Title level={5} style={{ margin: "0px" }}>
                      16 yrs
                    </Typography.Title>
                    <Typography.Title level={5} style={{ margin: "0px" }}>
                      Sophmore year
                    </Typography.Title>
                    <Typography.Title level={5} style={{ margin: "0px" }}>
                      Graduates 2021
                    </Typography.Title>
                    <Typography.Title level={5} style={{ margin: "0px" }}>
                      62"
                    </Typography.Title>
                    <Typography.Title level={5} style={{ margin: "0px" }}>
                      135 lbs
                    </Typography.Title>
                    <Typography.Title
                      level={5}
                      className="m-t-xl"
                      style={{ margin: "0px" }}
                    >
                      {dataInfo && dataInfo.organization_name}
                      <br />
                      {dataInfo && dataInfo.city} {dataInfo && dataInfo.state}
                    </Typography.Title>
                  </Space>
                </Space>
              </Col>
            </Row>
          </Col>
          <Col span={14} className="col-right">
            <Row>
              <Col span={24}>
                <Typography.Title level={3}>
                  Latest Assessment Snapshot
                </Typography.Title>
                <Typography.Text>
                  TO REVIEW ALL MY ASSESSMENTS AND STATS, SELECT THE "BFSS
                  ASSESSMENT ATATS" SECTION BELOW
                </Typography.Text>
              </Col>

              <Col span={12}>
                <Space>
                  <Typography.Title level={1}>
                    14.1 <RiSingleQuotesR />
                  </Typography.Title>

                  <Space>
                    <Typography.Title level={5}>
                      MY REACTIVE STRENGTH
                    </Typography.Title>
                    <Typography.Title level={3}>(IN)</Typography.Title>
                  </Space>
                </Space>
              </Col>
              <Col span={12}>
                <Space>
                  <Typography.Title level={1}>
                    14.8 <RiSingleQuotesR />
                  </Typography.Title>

                  <Space>
                    <Typography.Title level={5}>
                      REACTIVE STRENGTH
                    </Typography.Title>
                    <Typography.Title level={4}>
                      NATIONAL AVERAGE
                    </Typography.Title>
                    <Typography.Title level={3}>(IN)</Typography.Title>
                  </Space>
                </Space>
              </Col>
              <Col span={12}>
                <Space>
                  <Typography.Title level={1}>5.89</Typography.Title>

                  <Space>
                    <Typography.Title level={5}>
                      <span>ACCELERATION</span> <span>SPEED</span>
                    </Typography.Title>
                    <Typography.Title level={3}>SECS</Typography.Title>
                  </Space>
                </Space>
              </Col>
              <Col span={12}>
                <Space>
                  <Typography.Title level={1}>213</Typography.Title>

                  <Space>
                    <Typography.Title level={5}>
                      <span>MAXIMAL</span> <span>STRENGTH</span>
                    </Typography.Title>
                    <Typography.Title level={3}>LBS</Typography.Title>
                  </Space>
                </Space>
              </Col>

              <Col span={24}>
                <Row gutter={12}>
                  <Col span={12}>
                    <Space>
                      <div id="graphPie" style={{ width: "200px" }} />

                      <Space direction="vertical">
                        <Typography.Title level={1}>213</Typography.Title>
                        <Typography.Title level={5}>
                          YOUR BENCHMARK
                        </Typography.Title>
                      </Space>
                    </Space>
                  </Col>
                  <Col span={12}>
                    <Row>
                      <Col span={24}>
                        <Space>
                          <Typography.Title level={1}>
                            16.4 <RiSingleQuotesR />
                          </Typography.Title>

                          <Space direction="vertical">
                            <Typography.Title level={5}>
                              <span>ELASTIC</span> <span>STRENGTH (IN)</span>
                            </Typography.Title>
                          </Space>
                        </Space>
                      </Col>
                      <Col span={24}>
                        <Space>
                          <Typography.Title level={1}>
                            14.9 <RiSingleQuotesR />
                          </Typography.Title>

                          <Space direction="vertical">
                            <Typography.Title level={5}>
                              <span>BALLISTIC</span> <span>STRENGTH (IN)</span>
                            </Typography.Title>
                          </Space>
                        </Space>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Layout.Content>

      <Layout.Footer style={{ textAlign: "center" }}>
        © Copyright {name} {new Date().getFullYear()} Alright Reserve.
      </Layout.Footer>
    </Layout>
  );
}
