import { useState } from "react";
import { Card, Col, Row, Collapse } from "antd";
import ComponentHeader from "../Components/ComponentHeader";
import { GET } from "../../../providers/useAxiosQuery";
import companyInfo from "../../../providers/companyInfo";
import { faLightbulbOn } from "@fortawesome/pro-regular-svg-icons";

const role = companyInfo().role;

export default function PageFaqs({ match, permission, subtitle }) {
  const [faqData, setFaqData] = useState([]);

  GET(`api/v1/faq?role=${role}`, "faq_by_role", (res) => {
    // console.log("res", res.data);
    if (res.data) {
      let data = res.data;
      setFaqData(data);
    }
  });

  const handleRenderFaq = () => {
    if (faqData) {
      return (
        <Collapse
          className="main-1-collapse border-none"
          expandIcon={({ isActive }) =>
            isActive ? (
              <span
                className="ant-menu-submenu-arrow"
                style={{ color: "#FFF", transform: "rotate(270deg)" }}
              />
            ) : (
              <span
                className="ant-menu-submenu-arrow"
                style={{ color: "#FFF", transform: "rotate(90deg)" }}
              />
            )
          }
          expandIconPosition="right"
          defaultActiveKey={["0"]}
        >
          {faqData.map((item, index) => {
            return (
              <Collapse.Panel
                header={item.title}
                key={`${index}`}
                className="accordion bg-darkgray-form m-b-md border"
              >
                <div dangerouslySetInnerHTML={{ __html: item.description }} />
              </Collapse.Panel>
            );
          })}
        </Collapse>
      );
    }
  };

  return (
    <>
      <ComponentHeader
        title="Questions"
        sub_title="FREQUENTLY ASKED"
        icon={faLightbulbOn}
      />

      <Card className="card-min-height" id="PageFaqs">
        <Row gutter={12}>
          <Col span={24}>{handleRenderFaq()}</Col>
        </Row>
        <br />
      </Card>
    </>
  );
}
