import React, { useEffect, useState } from "react";
import { Button, Card, Col, Row, Typography } from "antd";

import {
  faUser,
  faSchool,
  faUsers,
  faRunning,
  faMapMarkerPlus,
  faHandsUsd,
  faTicketAlt,
  faSearch,
} from "@fortawesome/pro-solid-svg-icons";
import { faHome } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useHistory } from "react-router-dom";
// import moment from "moment";

import ComponentHeader from "../../Components/ComponentHeader";
// import ComponentDashboard from "../../Components/ComponentDashboard";
import PageDashboardRevenue from "./PageDashboardRevenue";
import ComponentsPendingApproval from "./Components/ComponentsPendingApproval";

export default function PageDashboard(props) {
  let history = useHistory();
  // let userdata = companyInfo().userData;
  const { permission } = props;

  return (
    <>
      <ComponentHeader
        title={permission}
        sub_title="ALL FEATURES"
        icon={faHome}
      />

      <Card
        className="card-quick-links p-b-lg card-min-height"
        id="PageDashboard"
      >
        <h1>QUICK LINKS</h1>
        <Row gutter={24}>
          <Col xs={24} sm={24} md={16} lg={16}>
            <Card
              // title="QUICK LINKS"
              className="card-transparent-head border-bottom-none no-side-border p-none"
              bodyStyle={{ padding: "0px" }}
            >
              <Row gutter={[12, 12]} className="ant-row-quick-link">
                <Col xs={12} sm={12} md={6}>
                  <Button
                    onClick={(e) => history.push("/user/view-edit-active")}
                    type="link"
                    className="ant-btn-quick-link"
                  >
                    <FontAwesomeIcon icon={faUser} />
                    <Typography.Text>
                      View / Edit
                      <br />
                      Users
                    </Typography.Text>
                  </Button>
                </Col>
                <Col xs={12} sm={12} md={6}>
                  <Button
                    onClick={(e) => history.push("/organization/view-edit")}
                    type="link"
                    className="ant-btn-quick-link"
                  >
                    <FontAwesomeIcon icon={faSchool} />
                    <Typography.Text>
                      View / Edit
                      <br />
                      Organization
                    </Typography.Text>
                  </Button>
                </Col>
                <Col xs={12} sm={12} md={6}>
                  <Button
                    onClick={(e) =>
                      history.push("/events-calendar/group-assessment")
                    }
                    type="link"
                    className="ant-btn-quick-link"
                  >
                    <FontAwesomeIcon icon={faUsers} />
                    <Typography.Text>
                      Group
                      <br />
                      Assessments
                    </Typography.Text>
                  </Button>
                </Col>
                <Col xs={12} sm={12} md={6}>
                  <Button
                    onClick={(e) =>
                      history.push("/events-calendar/private-assessment")
                    }
                    type="link"
                    className="ant-btn-quick-link"
                  >
                    <FontAwesomeIcon icon={faRunning} />
                    <Typography.Text>
                      Private
                      <br />
                      Assessments
                    </Typography.Text>
                  </Button>
                </Col>
                <Col xs={12} sm={12} md={6}>
                  <Button
                    onClick={(e) => history.push("/organization/add-new")}
                    type="link"
                    className="ant-btn-quick-link"
                  >
                    <FontAwesomeIcon icon={faMapMarkerPlus} />
                    <Typography.Text>
                      Add New
                      <br />
                      Organization
                    </Typography.Text>
                  </Button>
                </Col>
                <Col xs={12} sm={12} md={6}>
                  <Button
                    onClick={(e) =>
                      history.push("/revenue-manager/pending-payment")
                    }
                    type="link"
                    className="ant-btn-quick-link"
                  >
                    <FontAwesomeIcon icon={faHandsUsd} />
                    <Typography.Text>
                      Revenue
                      <br />
                      Generated
                    </Typography.Text>
                  </Button>
                </Col>
                <Col xs={12} sm={12} md={6}>
                  <Button
                    onClick={(e) => history.push("/user/search-athletes")}
                    type="link"
                    className="ant-btn-quick-link"
                  >
                    <FontAwesomeIcon icon={faSearch} />
                    <Typography.Text>
                      Search
                      <br />
                      Athletes
                    </Typography.Text>
                  </Button>
                </Col>
                <Col xs={12} sm={12} md={6}>
                  <Button
                    onClick={(e) => history.push("/ticketing")}
                    type="link"
                    className="ant-btn-quick-link"
                  >
                    <FontAwesomeIcon icon={faTicketAlt} />
                    <Typography.Text>
                      Ticketing
                      <br />
                      System
                    </Typography.Text>
                  </Button>
                </Col>
              </Row>
            </Card>
          </Col>

          <Col xs={24} sm={24} md={8} lg={8}>
            <ComponentsPendingApproval />
          </Col>
        </Row>

        <Col span={24} className="m-t-md m-b-sm">
          <Typography.Text strong className="font-weight-normal">
            LATEST REVENUE
          </Typography.Text>
        </Col>

        <PageDashboardRevenue />
      </Card>
    </>
  );
}
