import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PageHeader, Space } from "antd";
// import { data } from "jquery";

const ComponentHeader = (props) => {
	const { title, sub_title, icon, iconClassName } = props;

	const handleTitle = (title) => {
		// let data;
		if (title === "Pending Assessments") {
			return (
				<h2 className="sh-pagetitle-title">
					<span className="color-3">Pending</span> Assessments
				</h2>
			);
		} else if (title === "Athletes I Follow") {
			return (
				<h2 className="sh-pagetitle-title">
					<span className="color-3">Athletes</span> I Follow
				</h2>
			);
		} else {
			return <h2 className="sh-pagetitle-title">{title}</h2>;
		}
	};

	return (
		<PageHeader
			className="site-page-header"
			// breadcrumb={{ routes }}
			title={
				<Space>
					<div className="ant-icon-container">
						<FontAwesomeIcon icon={icon} className={iconClassName ?? ""} />
					</div>
					<div className="ant-sh-container">
						<span className="sh-pagetitle-title-sub">{sub_title}</span>
						{handleTitle(title)}
					</div>
				</Space>
			}
		/>
	);
};

export default ComponentHeader;
