import { Button, Card, Col, Layout, Row, Space, Table } from "antd";
import React, { useState, useEffect } from "react";
import { faUserFriends, faPlus } from "@fortawesome/pro-regular-svg-icons";
import $ from "jquery";

import { GET } from "../../../../providers/useAxiosQuery";
import { Link, useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  TableGlobalSearch,
  TablePageSize,
  TablePagination,
  TableShowingEntries,
} from "../../Components/ComponentTableFilter";
import ComponentHeader from "../../Components/ComponentHeader";
import companyInfo from "../../../../providers/companyInfo";

export default function PageAssessors({ match, permission }) {
  const history = useHistory();
  const userdata = companyInfo().userData;
  const [tableFilter, setTableFilter] = useState({
    page: 1,
    page_size: 50,
    search: "",
    sort_field: "name",
    sort_order: "desc",
    status: "Active",
    role: "Assessor",
    from: "manager-assessor",
    manager_id: userdata.id,
  });

  const [tableTotal, setTableTotal] = useState(0);
  const [dataSource, setDataSource] = useState([]);
  const {
    refetch: refetchTable,
    isLoading: isLoadingTable,
    isFetching: isFetchingTable,
  } = GET(`api/v1/users?${$.param(tableFilter)}`, "users", (res) => {
    if (res.success) {
      console.log("dataTable", res);
      setDataSource(res.data && res.data.data);
      setTableTotal(res.data.total);
    }
  });

  const handleTableChange = (pagination, filters, sorter) => {
    setTableFilter({
      ...tableFilter,
      sort_field: sorter.columnKey,
      sort_order: sorter.order ? sorter.order.replace("end", "") : null,
      // page: 1,
    });
  };

  const [searchText, setSearchText] = useState("");
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setTableFilter({
        ...tableFilter,
        search: searchText,
        page: 1,
      });
    }, 1500);
    return () => {
      clearTimeout(timeoutId);
    };
  }, [searchText]);

  useEffect(() => {
    refetchTable();
    return () => {};
  }, [tableFilter]);

  return (
    <>
      <ComponentHeader
        title={permission}
        sub_title="VIEW / EDIT"
        icon={faUserFriends}
      />

      <Card className="card-min-height" id="PageAssessors">
        {/* {dataSource.length === 0 && (
          <>
            <Row gutter={12}>
              <Col xs={24} sm={24} md={8}>
                <Button
                  className="btn-main-outline-2"
                  onClick={(e) => history.push("/assessors/edit")}
                >
                  <FontAwesomeIcon icon={faPlus} /> &nbsp; Add Assessor
                </Button>
              </Col>
            </Row>
            <br />
          </>
        )} */}
        <Row gutter={12}>
          <Col xs={24} sm={24} md={16} lg={16} xl={18} className="m-b-sm">
            <Space>
              <TablePageSize
                paginationFilter={tableFilter}
                setPaginationFilter={setTableFilter}
              />
            </Space>
          </Col>
          <Col xs={24} sm={24} md={8} lg={8} xl={6}>
            <TableGlobalSearch
              paginationFilter={searchText}
              setPaginationFilter={setSearchText}
            />
          </Col>
        </Row>
        <Row gutter={12}>
          <Col xs={24} sm={24} md={24} className="m-t-sm m-b-sm">
            <Table
              className="ant-table-default ant-table-striped"
              dataSource={dataSource && dataSource}
              rowKey={(record) => record.id}
              loading={isLoadingTable || isFetchingTable}
              pagination={false}
              bordered={false}
              onChange={handleTableChange}
              scroll={{ x: "max-content" }}
            >
              <Table.Column
                title="Username"
                key="username"
                dataIndex="username"
                defaultSortOrder="descend"
                sorter={true}
                render={(text, record) => {
                  return (
                    <Link to={`/assessors/edit/${record.id}`}>{text}</Link>
                  );
                }}
                width={"200px"}
              />
              <Table.Column
                title="Email"
                key="email"
                dataIndex="email"
                sorter={true}
                render={(text, record) => {
                  return (
                    <Link to={`/assessors/edit/${record.id}`}>{text}</Link>
                  );
                }}
                width={"250px"}
              />
              <Table.Column
                title="Country"
                key="country"
                dataIndex="country"
                sorter={true}
                width={"150px"}
              />
              <Table.Column
                title="State"
                key="state"
                dataIndex="state"
                sorter={true}
                width={"150px"}
              />
              <Table.Column
                title="City"
                key="city"
                dataIndex="city"
                sorter={true}
                width={"150px"}
              />
            </Table>
          </Col>
          <Col xs={24} sm={24} md={24}>
            <Space className="ant-space-flex-space-between">
              <TableShowingEntries />
              <TablePagination
                paginationFilter={tableFilter}
                setPaginationFilter={setTableFilter}
                setPaginationTotal={tableTotal}
                showLessItems={true}
                showSizeChanger={false}
              />
            </Space>
          </Col>
          <Col xs={24} sm={24} md={12}></Col>
        </Row>
        <br />
      </Card>
    </>
  );
}
