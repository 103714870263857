import { useState } from "react";
import {
	Button,
	Card,
	Col,
	Collapse,
	Form,
	Modal,
	Row,
	Typography,
} from "antd";
import { FaAngleUp, FaAngleDown, FaRegEdit, FaTrashAlt } from "react-icons/fa";
import FloatInput from "../../../../../providers/FloatInput";
import FloatTextArea from "../../../../../providers/FloatTextArea";

export default function ComponentFAQAdd() {
	const [collapseActiveKey, setCollapseActiveKey] = useState("1");
	const [toggleModalDelete, setToggleModalDelete] = useState(false);

	const onFinish = (values) => {
		console.log("onFinish", values);
	};

	const handleDelete = () => {
		setToggleModalDelete(true);
	};

	return (
		<Row gutter={12}>
			<Col span={24}>
				<Card title="Add FAQ" className="card-dark-head">
					<Form
						layout="vertical"
						onFinish={onFinish}
						autoComplete="off"
						initialValues={{ organizations: [""] }}
					>
						<Row gutter={12}>
							<Col span={24}>
								<Form.Item
									name="question"
									hasFeedback
									className="m-b-md form-select-error"
									rules={[
										{
											required: true,
											message: "This field field is required.",
										},
									]}
								>
									<FloatInput label="Question" placeholder="Question" />
								</Form.Item>
							</Col>

							<Col span={24}>
								<Form.Item name="answer" className="m-b-sm">
									<FloatTextArea label="Answer" placeholder="Answer" />
								</Form.Item>
							</Col>

							<Col span={24}>
								<Button
									type="primary"
									// loading={isLoadingButtonLogin}
									className="ant-btn-company-default ant-btn-company-default-active"
									size="large"
								>
									SAVE
								</Button>
							</Col>
						</Row>
					</Form>
				</Card>
			</Col>

			<Col span={24}>
				<Collapse
					accordion
					expandIconPosition="right"
					activeKey={collapseActiveKey}
					onChange={(e) => setCollapseActiveKey(e)}
					expandIcon={({ isActive }) =>
						isActive ? <FaAngleUp /> : <FaAngleDown />
					}
					className="ant-collapse-primary ant-collapse-faq"
				>
					<Collapse.Panel header="How do I set up an Elite Assessment?" key="1">
						<Card
							actions={[
								<div>asd</div>,
								<FaRegEdit key="edit" />,
								<FaTrashAlt key="delete" onClick={() => handleDelete()} />,
							]}
						>
							Sed ut perspiciatis unde omnis iste natus error sit voluptatem
							accusantium doloremque laudantum, totam rem aperiam, eaque ipsa
							quae ab illo inventore veritatis et quasi architecto beatae vitae
							dicta sunt explicabo. Nemo enim ipsam voluptatem qula voluptas sit
							aspernatur aut odit aut fugit, sed qula consequuntur magnl dolores
							eos qul ratione voluptatem sequl nesclunt. Neque porro qulsquam
							est, qul dolorem ipsum qula dolor sit amet, consectetur.
						</Card>
					</Collapse.Panel>

					<Collapse.Panel
						header="Ut enim minima veniam, quis nostrum exercitationem ullam corporis suscipit laborlosam, nisi ut allquid ex ea commodi consequatur?"
						key="2"
					>
						<Card
							actions={[
								<div>asd</div>,
								<FaRegEdit key="edit" />,
								<FaTrashAlt key="delete" />,
							]}
						>
							Sed ut perspiciatis unde omnis iste natus error sit voluptatem
							accusantium doloremque laudantum, totam rem aperiam, eaque ipsa
							quae ab illo inventore veritatis et quasi architecto beatae vitae
							dicta sunt explicabo. Nemo enim ipsam voluptatem qula voluptas sit
							aspernatur aut odit aut fugit, sed qula consequuntur magnl dolores
							eos qul ratione voluptatem sequl nesclunt. Neque porro qulsquam
							est, qul dolorem ipsum qula dolor sit amet, consectetur.
						</Card>
					</Collapse.Panel>

					<Collapse.Panel
						header="Ut enim minima veniam, quis nostrum exercitationem ullam corporis suscipit laborlosam, nisi ut allquid ex ea commodi consequatur?"
						key="3"
					>
						<Card
							actions={[
								<div>asd</div>,
								<FaRegEdit key="edit" />,
								<FaTrashAlt key="delete" />,
							]}
						>
							Sed ut perspiciatis unde omnis iste natus error sit voluptatem
							accusantium doloremque laudantum, totam rem aperiam, eaque ipsa
							quae ab illo inventore veritatis et quasi architecto beatae vitae
							dicta sunt explicabo. Nemo enim ipsam voluptatem qula voluptas sit
							aspernatur aut odit aut fugit, sed qula consequuntur magnl dolores
							eos qul ratione voluptatem sequl nesclunt. Neque porro qulsquam
							est, qul dolorem ipsum qula dolor sit amet, consectetur.
						</Card>
					</Collapse.Panel>

					<Collapse.Panel
						header="Ut enim minima veniam, quis nostrum exercitationem ullam corporis suscipit laborlosam, nisi ut allquid ex ea commodi consequatur?"
						key="4"
					>
						<Card
							actions={[
								<div>asd</div>,
								<FaRegEdit key="edit" />,
								<FaTrashAlt key="delete" />,
							]}
						>
							Sed ut perspiciatis unde omnis iste natus error sit voluptatem
							accusantium doloremque laudantum, totam rem aperiam, eaque ipsa
							quae ab illo inventore veritatis et quasi architecto beatae vitae
							dicta sunt explicabo. Nemo enim ipsam voluptatem qula voluptas sit
							aspernatur aut odit aut fugit, sed qula consequuntur magnl dolores
							eos qul ratione voluptatem sequl nesclunt. Neque porro qulsquam
							est, qul dolorem ipsum qula dolor sit amet, consectetur.
						</Card>
					</Collapse.Panel>

					<Collapse.Panel
						header="Ut enim minima veniam, quis nostrum exercitationem ullam corporis suscipit laborlosam, nisi ut allquid ex ea commodi consequatur?"
						key="5"
					>
						<Card
							actions={[
								<div>asd</div>,
								<FaRegEdit key="edit" />,
								<FaTrashAlt key="delete" />,
							]}
						>
							Sed ut perspiciatis unde omnis iste natus error sit voluptatem
							accusantium doloremque laudantum, totam rem aperiam, eaque ipsa
							quae ab illo inventore veritatis et quasi architecto beatae vitae
							dicta sunt explicabo. Nemo enim ipsam voluptatem qula voluptas sit
							aspernatur aut odit aut fugit, sed qula consequuntur magnl dolores
							eos qul ratione voluptatem sequl nesclunt. Neque porro qulsquam
							est, qul dolorem ipsum qula dolor sit amet, consectetur.
						</Card>
					</Collapse.Panel>
				</Collapse>
			</Col>

			<Modal
				title="DELETE FAQ?"
				visible={toggleModalDelete}
				onCancel={() => setToggleModalDelete(false)}
				footer={[
					<Button
						key="/delete"
						size="large"
						className="btn-success"
						onClick={() => setToggleModalDelete(false)}
					>
						YES, DELETE
					</Button>,
				]}
			>
				<Typography.Text>
					Are you sure you want to delete this FAQ permanently?
				</Typography.Text>
			</Modal>
		</Row>
	);
}
