import {
  Button,
  Card,
  Col,
  Layout,
  notification,
  Popconfirm,
  Row,
  Space,
  Table,
} from "antd";
import React, { useState, useEffect } from "react";
// import { FaTimes } from "react-icons/fa";
import { faUsers, faTimes } from "@fortawesome/pro-regular-svg-icons";
import ComponentHeader from "../../Components/ComponentHeader";
import {
  TableGlobalSearch,
  TablePageSize,
  TablePagination,
  TableShowingEntries,
} from "../../Components/ComponentTableFilter";
import { GET, POST } from "../../../../providers/useAxiosQuery";
import $ from "jquery";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import companyInfo from "../../../../providers/companyInfo";

export default function PageUserViewEditActive({ match, permission }) {
  // const history = useHistory();
  const userdata = companyInfo().userData;
  const [tableFilter, setTableFilter] = useState({
    page: 1,
    page_size: 50,
    search: "",
    sort_field: "created_at_str",
    sort_order: "desc",
    status: "Active",
    from: "manager",
  });

  const [tableTotal, setTableTotal] = useState(0);
  const [dataSource, setDataSource] = useState([]);
  const {
    refetch: refetchTable,
    isLoading: isLoadingTable,
    isFetching: isFetchingTable,
  } = GET(`api/v1/users?${$.param(tableFilter)}`, "users", (res) => {
    if (res.success) {
      console.log("dataTable", res);
      setDataSource(res.data && res.data.data);
      setTableTotal(res.data.total);
    }
  });

  const handleTableChange = (pagination, filters, sorter) => {
    setTableFilter({
      ...tableFilter,
      sort_field: sorter.columnKey,
      sort_order: sorter.order ? sorter.order.replace("end", "") : null,
      // page: 1,
    });
  };

  useEffect(() => {
    refetchTable();
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableFilter]);

  const [selectionType, setSelectionType] = useState([]);
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      // console.log(
      //   `selectedRowKeys: ${selectedRowKeys}`,
      //   "selectedRows: ",
      //   selectedRows
      // );

      setSelectionType(selectedRowKeys);
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User",
      // Column configuration not to be checked
      name: record.name,
    }),
  };

  const [searchText, setSearchText] = useState("");
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setTableFilter({
        ...tableFilter,
        search: searchText,
        page: 1,
      });
    }, 1500);
    return () => {
      clearTimeout(timeoutId);
    };
  }, [searchText]);

  const handleDeactivate = () => {
    let data = {
      selected: selectionType,
      status: "Deactive",
    };
    console.log("handleDeactivate", data);

    mutateDeactivate(data, {
      onSuccess: (res) => {
        if (res.success) {
          console.log(res);
          notification.success({
            message: "Success",
            description: "Successfully deactivated",
          });
        }
      },
    });
  };

  const { mutate: mutateDeactivate, isLoading: isLoadingDeactivate } = POST(
    "api/v1/user_deactivate",
    "users"
  );

  return (
    <>
      <ComponentHeader
        title={permission}
        sub_title="VIEW / EDIT"
        icon={faUsers}
      />

      <Card className="card-min-height" id="PageUserViewEditActive">
        <Row gutter={12}>
          <Col
            xs={24}
            sm={24}
            md={16}
            lg={16}
            xl={18}
            className="m-t-sm m-b-sm"
          >
            <Space>
              <TablePageSize
                paginationFilter={tableFilter}
                setPaginationFilter={setTableFilter}
              />
              {selectionType.length > 0 && (
                <Popconfirm
                  title="Are you sure you wish to deactivate this user(s)?"
                  onConfirm={handleDeactivate}
                  okText="Confirm"
                  cancelText="Cancel"
                >
                  <Button
                    type="primary"
                    className="btn-main-outline-2"
                    loading={isLoadingDeactivate}
                  >
                    <FontAwesomeIcon icon={faTimes} /> &nbsp; DEACTIVATE
                  </Button>
                </Popconfirm>
              )}
            </Space>
          </Col>
          <Col xs={24} sm={24} md={8} lg={8} xl={6}>
            <TableGlobalSearch
              paginationFilter={searchText}
              setPaginationFilter={setSearchText}
            />
          </Col>
        </Row>
        <Row gutter={12}>
          <Col xs={24} sm={24} md={24} className="m-t-sm m-b-sm">
            <Table
              className="ant-table-default ant-table-striped"
              dataSource={dataSource && dataSource}
              rowKey={(record) => record.id}
              loading={isLoadingTable || isFetchingTable}
              pagination={false}
              bordered={false}
              onChange={handleTableChange}
              rowSelection={{
                type: selectionType,
                ...rowSelection,
              }}
              scroll={{ x: "max-content" }}
            >
              <Table.Column
                title="Date Created"
                key="created_at_str"
                dataIndex="created_at_str"
                defaultSortOrder="descend"
                sorter={true}
                width={"150px"}
              />
              <Table.Column
                title="First Name"
                key="firstname"
                dataIndex="firstname"
                // defaultSortOrder="descend"
                sorter={true}
                render={(text, record) => {
                  let link = `/user/edit/${record.id}`;
                  if (userdata.role === "Admin") {
                    if (record.role === "Manager") {
                      link = `/user/edit-account/${record.id}`;
                    } else {
                      link = `/user/edit/${record.id}`;
                    }
                    return <Link to={link}>{text ? text : "Edit"}</Link>;
                  } else {
                    link = `/user/edit/${record.id}`;
                    return record.edit_permission === "No" ? (
                      <Link to={link}>{text ? text : "Edit"}</Link>
                    ) : (
                      <>{text ? text : "Edit"}</>
                    );
                  }
                }}
                width={"150px"}
              />
              <Table.Column
                title="Last Name"
                key="lastname"
                dataIndex="lastname"
                sorter={true}
                render={(text, record) => {
                  let link = `/user/edit/${record.id}`;
                  if (userdata.role === "Admin") {
                    if (record.role === "Manager") {
                      link = `/user/edit-account/${record.id}`;
                    } else {
                      link = `/user/edit/${record.id}`;
                    }
                    return <Link to={link}>{text ? text : "Edit"}</Link>;
                  } else {
                    link = `/user/edit/${record.id}`;
                    return record.edit_permission === "No" ? (
                      <Link to={link}>{text ? text : "Edit"}</Link>
                    ) : (
                      <>{text ? text : "Edit"}</>
                    );
                  }
                }}
                width={"150px"}
              />{" "}
              <Table.Column
                title="State"
                key="state"
                dataIndex="state"
                sorter={true}
                width={"200px"}
                render={(text, record) => {
                  return record.user_address.length > 0
                    ? record.user_address[0].state
                    : "";
                }}
              />
              <Table.Column
                title="Organization"
                key="organization"
                dataIndex="organization"
                sorter={true}
                width={"200px"}
                render={(text, record) => {
                  if (record.role === "Athlete") {
                    let org = record.athlete_organization
                      .map((item) => {
                        return item.name;
                      })
                      .join(", ");
                    return org;
                  } else {
                    let org = record.coach_organization
                      .map((item) => {
                        return item.name;
                      })
                      .join(", ");
                    return org;
                  }
                }}
              />
              {userdata.role === "Manager" && (
                <Table.Column
                  title="City"
                  key="city"
                  dataIndex="city"
                  sorter={true}
                  width={"200px"}
                />
              )}
              {userdata.role === "Admin" && (
                <Table.Column
                  title="Coverage"
                  key="coverage_area"
                  dataIndex="coverage_area"
                  sorter={true}
                  width={"200px"}
                  // render={(text, record) => {
                  //   let area = record.user_manager_area.map((item) => {
                  //     let coverages = [];
                  //     if (item.city && item.city != "") {
                  //       JSON.parse(item.city).map((city, key) => {
                  //         coverages.push(
                  //           <div>
                  //             {item.country + ", " + item.state + ", " + city}
                  //           </div>
                  //         );
                  //       });
                  //     }
                  //     return coverages;
                  //   });

                  //   return <div>{area}</div>;
                  // }}
                />
              )}
              <Table.Column
                title="Role"
                key="role"
                dataIndex="role"
                sorter={true}
                width={"150px"}
              />
            </Table>
          </Col>
          <Col xs={24} sm={24} md={24}>
            <Space className="ant-space-flex-space-between">
              <TableShowingEntries />
              <TablePagination
                paginationFilter={tableFilter}
                setPaginationFilter={setTableFilter}
                setPaginationTotal={tableTotal}
                showLessItems={true}
                showSizeChanger={false}
              />
            </Space>
          </Col>
          <Col xs={24} sm={24} md={12}></Col>
        </Row>
        <br />
      </Card>
    </>
  );
}
