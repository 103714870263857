import {
  Button,
  Card,
  Col,
  Layout,
  notification,
  Popconfirm,
  Row,
  Space,
  Table,
} from "antd";
import React, { useState, useEffect } from "react";
import { faSchool } from "@fortawesome/pro-regular-svg-icons";
import ComponentHeader from "../../Components/ComponentHeader";
import {
  TableGlobalSearch,
  TablePageSize,
  TablePagination,
  TableShowingEntries,
} from "../../Components/ComponentTableFilter";
import { GET, POST, UPDATE } from "../../../../providers/useAxiosQuery";
import $ from "jquery";
import { Link, useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/pro-solid-svg-icons";
export default function PageOrganizationViewEdit({ match, permission }) {
  const history = useHistory();
  const [tableFilter, setTableFilter] = useState({
    page: 1,
    page_size: 50,
    search: "",
    sort_field: "name",
    sort_order: "desc",
  });

  const [tableTotal, setTableTotal] = useState(0);
  const [dataSource, setDataSource] = useState([]);
  const {
    datra: dataTable,
    refetch: refetchTable,
    isLoading: isLoadingTable,
    isFetching: isFetchingTable,
  } = GET(
    `api/v1/organization?${$.param(tableFilter)}`,
    "organization",
    (res) => {
      if (res.success) {
        console.log("dataTable", res);
        setDataSource(res.data && res.data.data);
        setTableTotal(res.data.total);
      }
    }
  );

  const handleTableChange = (pagination, filters, sorter) => {
    setTableFilter({
      ...tableFilter,
      sort_field: sorter.columnKey,
      sort_order: sorter.order ? sorter.order.replace("end", "") : null,
      // page: 1,
    });
  };

  const [searchText, setSearchText] = useState("");
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setTableFilter({
        ...tableFilter,
        search: searchText,
        page: 1,
      });
    }, 1500);
    return () => {
      clearTimeout(timeoutId);
    };
  }, [searchText]);

  useEffect(() => {
    refetchTable();
    return () => {};
  }, [tableFilter]);

  const { mutate: mudateDelete, isLoadingDelete } = POST(
    "api/v1/delete_organization",
    "organization"
  );

  const handleDelete = (record) => {
    let data = {
      id: record.id,
    };

    mudateDelete(data, {
      onSuccess: (res) => {
        if (res.success) {
          notification.success({
            message: "Success",
            description: "Successfully deleted!",
          });
        } else {
          notification.warning({
            message: "Warning",
            description: "No data found!",
          });
        }
      },
    });
  };

  return (
    <>
      <ComponentHeader
        title={permission}
        sub_title="VIEW / EDIT"
        icon={faSchool}
      />

      <Card className="card-min-height" id="PageOrganizationViewEdit">
        <Row gutter={12}>
          <Col xs={24} sm={24} md={16} lg={16} xl={18} className="m-b-sm">
            <Space>
              <TablePageSize
                paginationFilter={tableFilter}
                setPaginationFilter={setTableFilter}
              />
            </Space>
          </Col>
          <Col xs={24} sm={24} md={8} lg={8} xl={6}>
            <TableGlobalSearch
              paginationFilter={searchText}
              setPaginationFilter={setSearchText}
            />
          </Col>
        </Row>
        <Row gutter={12}>
          <Col xs={24} sm={24} md={24} className="m-t-sm m-b-sm">
            <Table
              className="ant-table-default ant-table-striped"
              // dataSource={dataTable && dataTable.data.data}
              dataSource={dataSource}
              loading={isLoadingTable || isFetchingTable}
              rowKey={(record) => record.id}
              pagination={false}
              bordered={false}
              scroll={{ x: "max-content" }}
              onChange={handleTableChange}
            >
              <Table.Column
                title="Organization"
                key="name"
                dataIndex="name"
                defaultSortOrder="descend"
                sorter={true}
                render={(text, record) => {
                  return (
                    <Link to={`/organization/edit/${record.id}`}>{text}</Link>
                  );
                }}
                width={"200px"}
              />
              <Table.Column
                title="Type"
                key="organization_type"
                dataIndex="organization_type"
                sorter={true}
                width={"150px"}
              />
              <Table.Column
                title="Country"
                key="country"
                dataIndex="country"
                sorter={true}
                width={"150px"}
              />
              <Table.Column
                title="State"
                key="state"
                dataIndex="state"
                sorter={true}
                width={"150px"}
              />
              <Table.Column
                title="City"
                key="city"
                dataIndex="city"
                sorter={true}
                width={"150px"}
              />
              <Table.Column
                title="Status"
                key="status"
                dataIndex="status"
                sorter={true}
                width={"150px"}
              />
            </Table>
          </Col>
          <Col xs={24} sm={24} md={24}>
            <Space className="ant-space-flex-space-between">
              <TableShowingEntries />
              <TablePagination
                paginationFilter={tableFilter}
                setPaginationFilter={setTableFilter}
                setPaginationTotal={tableTotal}
                showLessItems={true}
                showSizeChanger={false}
              />
            </Space>
          </Col>
          <Col xs={24} sm={24} md={12}></Col>
        </Row>
        <br />
      </Card>
    </>
  );
}
