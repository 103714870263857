import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  Layout,
  Card,
  Form,
  Button,
  Row,
  Col,
  Image,
  Typography,
  Alert,
} from "antd";
import moment from "moment";
import axios from "axios";
import companyInfo from "../../../providers/companyInfo";
import FloatInputPasswordStrength from "../../../providers/FloatInputPasswordStrength";
import ComponentHeader from "../Components/ComponentHeader";

import { faEdit } from "@fortawesome/pro-regular-svg-icons";
import FloatInputPassword from "../../../providers/FloatInputPassword";

const apiUrl = companyInfo().apiUrl;
const logo = companyInfo().logo;
const description = companyInfo().description;
const encryptor = companyInfo().encryptor;

export default function PageRegistrationSetPassword(props) {
  let token = props.match.params.token;
  let roleParam = props.location.search
    ? props.location.search.substring(1)
    : "";
  let history = useHistory();

  // console.log("roleParam", roleParam);

  // console.log("props", props);
  // console.log("token", token);

  const [errorMessagePassword, setErrorMessagePassword] = useState({
    type: "success",
    message: "",
    already_verified: false,
  });
  const [pageLoading, setPageLoading] = useState(true);

  const [role, setRole] = useState();

  useEffect(() => {
    if (token) {
      axios
        .post(
          `${apiUrl}api/v1/set_password/auth`,
          { roleParam },
          {
            headers: {
              Authorization: "Bearer " + token,
            },
          }
        )
        .then((res) => {
          console.log("useEffect success", res.data);
          setErrorMessagePassword({
            type: "error",
            message: res.data.message,
            already_verified: res.data.already_verified,
          });
          setRole(res.data.role);
          setPageLoading(false);
        })
        .catch((err) => {
          if (err.response.status === 401) {
            history.push("/error-500");
          }
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [isLoading, setIsLoading] = useState(false);
  const onFinishPassword = (values) => {
    setIsLoading(true);
    // console.log("apiUrl", apiUrl);
    let data = { ...values, roleParam };

    console.log("onFinishPassword", data);
    axios
      .post(`${apiUrl}api/v1/set_password/auth`, data, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((res) => {
        setIsLoading(false);
        // console.log("res.data", res);
        if (res.data.success) {
          localStorage.userdata = encryptor.encrypt(res.data.authUser.data);
          localStorage.token = res.data.authUser.token;

          setErrorMessagePassword({
            type: "success",
            message: "Set password successfully.",
          });

          localStorage.removeItem("bfssRegStepData");
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        } else {
          setErrorMessagePassword({
            type: "error",
            message: "This email already verified!",
          });
        }
      });
  };

  const [level, setLevel] = useState(0);
  const [level1, setLevel1] = useState(0);

  const minLevel = 1;
  const errorMessage = "Password is too weak";

  const validatePassword = (rule, value, callback) => {
    if (value && value !== "Secret") {
      callback("Error!");
    } else {
      callback();
    }
  };

  return (
    <Layout className="public-layout register-layout">
      <Layout.Content className="p-t-lg">
        <Row>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Image
              className="zoom-in-out-box"
              onClick={() => history.push("/")}
              src={logo}
              preview={false}
            />

            <Card className="m-t-md" loading={pageLoading}>
              <ComponentHeader
                title="Registration"
                sub_title={
                  role
                    ? `${role !== "Coach" ? "Athlete" : "Coach"}'s`
                    : "New User"
                }
                icon={faEdit}
              />
              {errorMessagePassword.already_verified === false ? (
                <Form
                  layout="vertical"
                  className="form-create-password"
                  onFinish={onFinishPassword}
                  autoComplete="off"
                >
                  <Typography.Title level={3} className="font-weight-normal">
                    Set up Password
                  </Typography.Title>

                  <Typography.Text>
                    Your password must be at least 8 characters and contain at
                    least one number, one uppercase letter and one special
                    character.
                  </Typography.Text>

                  <Form.Item
                    style={{ marginTop: 10 }}
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: "This field field is required.",
                      },
                      {
                        pattern:
                          /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,64}$/,
                        message: "Invalid Password",
                      },
                    ]}
                    hasFeedback
                  >
                    <FloatInputPasswordStrength
                      placeholder="Password"
                      label="Password"
                      onLevelChange={setLevel}
                    />
                  </Form.Item>
                  <Form.Item
                    name="confirm-password"
                    dependencies={["password"]}
                    rules={[
                      {
                        required: true,
                        message: "This field field is required.",
                      },
                      {
                        pattern:
                          /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,64}$/,
                        message: "Invalid Password",
                      },
                    ]}
                    hasFeedback
                    // className="m-b-sm"
                  >
                    <FloatInputPasswordStrength
                      label="Confirm Password"
                      placeholder="Confirm Password"
                      // onLevelChange={setLevel1}
                    />
                  </Form.Item>

                  <Button
                    type="primary"
                    htmlType="submit"
                    className="btn-primary-default m-t-sm"
                    block
                    size="large"
                    loading={isLoading}
                  >
                    SUBMIT
                  </Button>

                  {errorMessagePassword.message !==
                    "Please setup password now!" && (
                    <>
                      <Alert
                        className="m-t-sm text-center"
                        type={errorMessagePassword.type}
                        message={errorMessagePassword.message}
                      />
                    </>
                  )}
                </Form>
              ) : (
                <Alert
                  className="m-t-sm text-center"
                  type={errorMessagePassword.type}
                  message={errorMessagePassword.message}
                />
              )}
            </Card>
          </Col>
        </Row>
      </Layout.Content>
      <Layout.Footer className="text-center">
        <Typography.Text>
          © Copyright {moment().format("YYYY")} {description}. All Rights
          Reserved.
        </Typography.Text>
      </Layout.Footer>
    </Layout>
  );
}
