import React, { useState, useEffect } from "react";
import { Divider, Layout, Space, Typography, Modal } from "antd";
import companyInfo from "../../providers/companyInfo";
import { Link, useHistory } from "react-router-dom";
import $ from "jquery";
import { GET, POST, GETMANUAL } from "../../providers/useAxiosQuery";
import ModalTooltip from "./Modaltooltips";
import { getSelector } from "../../providers/getSelector";
const description = companyInfo().description;
const encryptor = companyInfo().encryptor;
export default function Footer({ match }) {
  let history = useHistory();
  let userdata = companyInfo().userData;
  // console.log("match", match);
  const [showTooltipModal, setShowTooltipModal] = useState(false);

  const [selector, setSelector] = useState();
  const [selectorInsertat, setSelectorInsertat] = useState();
  const [selectorIsreq, setSelectorIsreq] = useState();
  const [formDataTooltip, setFormDataTooltip] = useState({
    tooltip_color: "",
    selector: "",
    description: "",
    position: "",
    inserted_at: "",
    video_url: "",
    tooltip_type: "text",
  });

  const setClickEvents = () => {
    $("body").on("click", function (e) {
      if (e.altKey) {
        e.preventDefault();

        var class_name = e.target.className;

        var isRequired = e.target.nextSibling
          ? e.target.nextSibling.firstChild
            ? e.target.nextSibling.firstChild
            : null
          : null;

        console.log(isRequired);
        var _isRequired = false;
        if (isRequired != null) {
          if (isRequired.className) {
            _isRequired = true;
          }
        }

        // class_name.includes("ant-select-selection-item") ||
        // class_name.includes("ant-select-selection-overflow") ||
        // class_name.includes("ant-select-selection-search-input")

        if (
          class_name.includes("ant-collapse-header") ||
          class_name.includes("ant-input") ||
          class_name.includes("mask-input-antd")
        ) {
          let _selector = getSelector(e.target);

          console.log(_selector);
          // console.log("isR", isRequired);

          var inserted_at = "";
          var is_req = "tooltip-is-not-required";
          if (
            class_name.includes("ant-input") ||
            class_name.includes("mask-input-antd")
          ) {
            inserted_at = "tooltip-input";

            if (_isRequired) {
              is_req = "tooltip-is-required";
            } else {
              is_req = "tooltip-is-not-required";
            }
          }
          if (class_name.includes("ant-collapse-header")) {
            inserted_at = "tooltip-collapse";
          }
          // if (class_name.includes("ant-select-selection-item")) {
          //   inserted_at = "tooltip-select";
          // }
          // if (class_name.includes("ant-select-selection-overflow")) {
          //   inserted_at = "tooltip-selection";
          // }
          // if (class_name.includes("ant-select-selection-search-input")) {
          //   inserted_at = "tooltip-select";
          // }
          setSelectorIsreq(is_req);
          getTooltipBySelector(_selector);
          setSelector(_selector);
          setSelectorInsertat(inserted_at);
          setShowTooltipModal(true);
        }
      }
    });
  };

  useEffect(() => {
    var view_as_role = localStorage.userdata_admin
      ? encryptor.decrypt(localStorage.userdata_admin)
      : "";

    if (
      userdata.role == "Super Admin" ||
      userdata.role == "Admin" ||
      view_as_role.role == "Admin" ||
      view_as_role.role == "Super Admin"
    ) {
      setClickEvents();
    }

    setTimeout(() => {
      getToolTips();
    }, 1500);

    return () => {};
  }, [window.location.href]);

  const { mutate: mutateGetSelector, isLoading: isLoadingGetSelector } = POST(
    "api/v1/tooltips/selector",
    "tooltip_selector"
  );

  GET("api/v1/maintenance", "maintenance", (res) => {
    if (res.success == true) {
      var view_as_role = localStorage.userdata_admin
        ? encryptor.decrypt(localStorage.userdata_admin)
        : "";

      if (
        userdata.role == "Super Admin" ||
        userdata.role == "Admin" ||
        view_as_role.role == "Admin" ||
        view_as_role.role == "Super Admin"
      ) {
      } else {
        if (res.data.system_maintenance == 1) {
          history.push("/maintenance");
        }
      }
    }
  });

  const getTooltipBySelector = (selector) => {
    mutateGetSelector(
      { selector: selector, role: userdata.role },
      {
        onSuccess: (res) => {
          if (res.success) {
            if (res.data.length != 0) {
              // console.log("getTooltip", res.data[0]);
              setFormDataTooltip(res.data[0]);
            } else {
              setFormDataTooltip({
                tooltip_color: "",
                selector: "",
                description: "",
                position: "",
                inserted_at: "",
                video_url: "",
                tooltip_type: "text",
              });
            }
          }
        },
        onError: (err) => {
          console.log(err);
        },
      }
    );
  };

  const {
    data: dataToolTips,
    isLoading: isLoadingToolTips,
    refetch: getToolTips,
    isFetching: isFetchingToolTips,
  } = GETMANUAL(`api/v1/tooltips`, `get_tooltiips`, (res) => {
    if (res.success) {
      let role = userdata.role;

      res.data.map((tooltip, key) => {
        if (tooltip.role == role) {
          let description = "";
          if (tooltip.tooltip_type == "video") {
            description =
              "<iframe style='width: 185px; margin-top: 4px; ' allowfullscreen frameborder='0' allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; fullscreen' src=" +
              tooltip.video_url +
              "></iframe>";
          }
          if (tooltip.tooltip_type == "text") {
            description = tooltip.description;
          }

          // console.log("wew", description);

          if (tooltip.insert_at == "tooltip-collapse") {
            $(tooltip.selector).append(
              `<div class="tooltip ` +
                tooltip.insert_at +
                `">
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-info-square" viewBox="0 0 16 16">
                <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"/>
                <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
              </svg>
                    <span class=" ` +
                tooltip.position +
                ` tooltiptext ` +
                tooltip.tooltip_color +
                `"> ` +
                description +
                `</span>
                </span>
                </div>`
            );
          } else {
            $(tooltip.selector).after(
              `<div  class="tooltip ` +
                tooltip.insert_at +
                ` ` +
                tooltip.is_req +
                `">
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-info-square" viewBox="0 0 16 16">
                <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"/>
                <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
              </svg>
                    <span  class=" ` +
                tooltip.position +
                ` tooltiptext ` +
                tooltip.tooltip_color +
                `" > ` +
                description +
                `</span>
                </span>
                </div>`
            );
          }
        }
      });
    }
  });

  return (
    <Layout.Footer>
      <Space direction="vertical" className="sh-footer" size={0}>
        <Space className="footer-links" split={<Divider type="vertical" />}>
          <Link to="/policy" target={"_blank"} className="footer-hover">
            Privacy Policy
          </Link>
          <Link
            to="/terms-and-condition"
            target={"_blank"}
            className="footer-hover"
          >
            Terms & Conditions
          </Link>
          {/* {userdata.role !== "Manager" && ( */}
          <Link to="/cookies" target={"_blank"} className="footer-hover">
            Cookie Policy
          </Link>
          {/* )} */}
        </Space>

        <Space className="footer-contacts" split={<Divider type="vertical" />}>
          <Typography className="color-8 footer-text">
            2898 E Citadel Ct, Gilbert, AZ 85298
          </Typography>
          <Typography className="color-8 footer-text">
            Tel. (480) 232-1234
          </Typography>
          <Typography className="color-8 footer-text">
            info@bfsscience.com
          </Typography>
        </Space>
        <Typography.Text className="color-8">
          © {new Date().getFullYear()} {description} All Rights Reserved.
        </Typography.Text>
      </Space>
      {showTooltipModal && (
        <ModalTooltip
          showTooltipModal={showTooltipModal}
          setShowTooltipModal={setShowTooltipModal}
          selector={selector}
          formDataTooltip={formDataTooltip}
          setFormDataTooltip={setFormDataTooltip}
          selectorInsertat={selectorInsertat}
          selectorIsreq={selectorIsreq}
          // getToolTips={getToolTips}
        />
      )}
    </Layout.Footer>
  );
}
