import { Card } from "antd";
import ComponentHeader from "../../Components/ComponentHeader";
// import { CalendarOutlined } from "@ant-design/icons";
// import FullCalendar from "@fullcalendar/react";
// import dayGridPlugin from "@fullcalendar/daygrid";
// import timeGridPlugin from "@fullcalendar/timegrid";
import moment from "moment";
import { useState } from "react";
import { faCalendarAlt } from "@fortawesome/pro-regular-svg-icons";
// import companyInfo from "../../../../providers/companyInfo";
// import ModalCalendarUpcomingGroupAssessmentsPreview from "./ModalCalendarUpcomingGroupAssessmentsPreview";
// import { GET } from "../../../../providers/useAxiosQuery";
import ComponentDashboard from "../../Components/ComponentDashboard";

// const apiUrl = companyInfo().apiUrl;

export default function PageCalendarUpcomingGroupAssessments() {
  const [dataFilter, setDataFilter] = useState({
    date: moment().format("MM/DD/YYYY"),
    type: "Group",
    search: "",
    category: "",
    tag: "",
    country: "",
    state: "",
    city: "",
    columnNumber: 6,
    from: "athlete_all_assessments",
    col_lg: 6,
  });

  return (
    <>
      <ComponentHeader
        title="Assessments"
        sub_title="ALL"
        icon={faCalendarAlt}
      />

      <Card
        className="card-min-height"
        id="PageCalendarUpcomingGroupAssessments"
      >
        <ComponentDashboard
          dataFilter={dataFilter}
          setDataFilter={setDataFilter}
          column={12}
          column_lg={6}
          column_xl={6}
        />
      </Card>
    </>
  );
}
