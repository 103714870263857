import {
  Button,
  Card,
  Col,
  Collapse,
  notification,
  Row,
  Space,
  Table,
} from "antd";
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
// import $ from "jquery";

import ComponentHeader from "../../Components/ComponentHeader";

import { GET, POST } from "../../../../providers/useAxiosQuery";
import { faFilePdf } from "@fortawesome/free-solid-svg-icons";
import PageStarter from "./component/PageStarter";
import PageProfessional from "./component/PageProfessional";
import PageElite from "./component/PageElite";

export default function PageAssessmentPdfEdit({ match, permission }) {
  const history = useHistory();
  const pdf_id = match.params.id;

  const [pdfData, setPdfData] = useState();
  GET(`api/v1/assessment_pdf/${pdf_id}`, "assessment_pdf_edit", (res) => {
    if (res.success) {
      //   console.log("assessment_pdf_edit", res);
      setPdfData(res.data);
    }
  });

  return (
    <>
      <ComponentHeader title={permission} sub_title="EDIT" icon={faFilePdf} />

      <Card className="card-min-height" id="PageAssessmentPdfEdit">
        <Row gutter={12}>
          <Col xs={24} sm={24} md={24} className="m-b-sm">
            {pdfData && pdfData.type === "Starter" && (
              <PageStarter pdfData={pdfData} pdfID={pdf_id} />
            )}
            {pdfData && pdfData.type === "Professional" && (
              <PageProfessional pdfData={pdfData} pdfID={pdf_id} />
            )}
            {pdfData && pdfData.type === "Elite" && (
              <PageElite pdfData={pdfData} pdfID={pdf_id} />
            )}
          </Col>
        </Row>

        <br />
      </Card>
    </>
  );
}
