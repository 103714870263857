import React, { useEffect, useState } from "react";
import {
  Alert,
  Button,
  Col,
  Collapse,
  Popconfirm,
  Row,
  Form,
  Modal,
  Typography,
  notification,
  Input,
} from "antd";
import { FaTimes } from "react-icons/fa";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faPlus, faTrash } from "@fortawesome/pro-solid-svg-icons";

import moment from "moment";

import { GET, POST } from "../../../../providers/useAxiosQuery";
import FloatInput from "../../../../providers/FloatInput";
import FloatSelect from "../../../../providers/FloatSelect";
import optionStateCodesUnitedState from "../../../../providers/optionStateCodesUnitedState";
import optionStateCodesCanada from "../../../../providers/optionStateCodesCanada";
import optionOrganizationType from "../../../../providers/optionOrganizationType";
import optionCountryCodes from "../../../../providers/optionCountryCodes";
import FloatDatePicker from "../../../../providers/FloatDatePicker";

export default function CoachFormOrganization({
  user_id,
  selectedData,
  refetchPage,
}) {
  const [modalCreate, setModalCreate] = useState(false);
  const [modalCreateNew, setModalCreateNew] = useState(false);
  const [cityOption, setCityOption] = useState([]);
  const [modalForm] = Form.useForm();

  const [country] = useState(optionCountryCodes);
  const stateUS = optionStateCodesUnitedState();
  const stateCA = optionStateCodesCanada();

  const [stateLabel, setStateLabel] = useState("State");
  const [optionState, setOptionState] = useState();

  const [zipLabel, setZipLabel] = useState("Zip Code");
  const [optionZip, setOptionZip] = useState(/(^\d{5}$)|(^\d{5}[ -]\d{4}$)/);

  const [organizationList, setOrganizationList] = useState([]);

  const { refetch: refetchOne } = GET(
    `api/v1/get_coach_org?user_id=${user_id}`,
    "get_coach_org",
    (res) => {
      if (res.success) {
        console.log("mutateGetOrg", res);
        setOrganizationList(res.data);
      }
    }
  );

  const [sportList, setsportList] = useState([]);
  const { refetch: refetchTwo } = GET(
    "api/v1/get_all_sport",
    "get_all_sport",
    (res) => {
      if (res.success) {
        // console.log("get_all_sport", res);
        let arr = [];
        res.data.map((item) => {
          arr.push({
            value: item.sport,
            label: item.sport,
            json: item,
          });
          return "";
        });
        setsportList(arr);
      }
    }
  );

  const [school, setSchool] = useState([]);
  const [club, setClub] = useState([]);
  const [university, setUniversity] = useState([]);
  const [organization, setOrganization] = useState([]);

  const { refetch: refetchThree } = GET(
    "api/v1/athlete_organization",
    "coach_organization",
    (res) => {
      if (res.success) {
        console.log("coach_organization", res);
        setClub(res.club);
        setSchool(res.school);
        setUniversity(res.university);
      }
    }
  );

  useEffect(() => {
    if (selectedData) {
      console.log("selectedData", selectedData);
      let user_address = selectedData.user_address
        ? selectedData.user_address[0]
        : [];
      if (user_address.country === "United States") {
        setStateLabel("State");
        setOptionState(stateUS);

        setZipLabel("Zip Code");
        setOptionZip(/(^\d{5}$)|(^\d{5}[ -]\d{4}$)/);

        let get_city = stateUS.filter(
          (item) => user_address.state === item.value
        );
        setCityOption(get_city[0]["json"]);
      } else if (user_address.country === "Canada") {
        setStateLabel("County");
        setOptionState(stateCA);

        setZipLabel("Postal Code");
        setOptionZip(/^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/);

        let get_city = stateCA.filter(
          (item) => user_address.state === item.value
        );
        setCityOption(get_city[0]["json"]);
      }
      refetchOne();
      refetchTwo();
      refetchThree();
    }
  }, [selectedData]);

  useEffect(() => {
    if (!modalCreate) {
      setOrganization([]);
      setModalCreateNew(false);
      modalForm.resetFields();
    }
  }, [modalCreate]);

  const onFinishForm = (values) => {
    let data = {
      ...values,
      user_id: user_id,
    };
    console.log("onFinishForm", data);

    mutateNew(data, {
      onSuccess: (res) => {
        if (res.success) {
          notification.success({
            message: "Success",
            description: "Successfully Submited",
          });
          setModalCreate(false);
          refetchPage();
        }
      },
    });
  };

  const { mutate: mutateNew, isLoading: isLoadingNew } = POST(
    "api/v1/update_user_coach_profile_organization",
    "get_coach_org"
  );

  const [modalEdit, setModalEdit] = useState(false);
  const [modalEditData, setModalEditData] = useState();
  const [modalFormEdit] = Form.useForm();

  const onFinishFormEdit = (values) => {
    let data = {
      ...values,
      user_id: user_id,
      id: modalEditData.id,
    };
    console.log("onFinishFormEdit", data);

    mutateEdit(data, {
      onSuccess: (res) => {
        if (res.success) {
          notification.success({
            message: "Success",
            description: "Successfully Updated",
          });
          setModalEdit(false);
          refetchPage();
        }
      },
    });
  };

  const { mutate: mutateEdit, isLoading: isLoadingEdit } = POST(
    "api/v1/update_user_coach_profile_organization_update",
    "get_coach_org"
  );

  useEffect(() => {
    if (!modalEdit) {
      setOrganization([]);
      setModalEditData(false);
      modalFormEdit.resetFields();
    }
  }, [modalEdit]);

  const { mutate: mutateDelete, isLoading: isLoadingDelete } = POST(
    "api/v1/update_user_coach_profile_organization_delete",
    "get_coach_org"
  );

  const handleRemoveOrganization = (values) => {
    console.log("handleRemoveOrganization", values);

    mutateDelete(
      { id: values.id },
      {
        onSuccess: (res) => {
          if (res.success) {
            notification.success({
              message: "Success",
              description: "Successfully Deleted!",
            });
          }
        },
      }
    );
  };

  return (
    <>
      <Row gutter={24}>
        <Col xs={24} sm={24} md={12}>
          <Button
            className="btn-main-outline-2 m-b-md"
            onClick={(e) => {
              setModalCreate(true);
              let user_address = selectedData.user_address[0];

              console.log("selectedData", user_address);
              modalForm.setFieldsValue({
                country: user_address.country,
                state: user_address.state,
              });
            }}
            block
          >
            <FontAwesomeIcon icon={faPlus} />
            &nbsp;Add Organization
          </Button>
        </Col>
      </Row>

      {organizationList &&
        organizationList.map((item, key) => {
          return (
            <Collapse
              className="main-1-collapse m-b-md"
              expandIcon={({ isActive }) =>
                isActive ? (
                  <span
                    className="ant-menu-submenu-arrow"
                    style={{
                      color: "#FFF",
                      transform: "rotate(270deg)",
                    }}
                  ></span>
                ) : (
                  <span
                    className="ant-menu-submenu-arrow"
                    style={{
                      color: "#FFF",
                      transform: "rotate(90deg)",
                    }}
                  ></span>
                )
              }
              defaultActiveKey={["0"]}
              expandIconPosition="start"
            >
              <Collapse.Panel
                header="SCHOOL / CLUB / UNIVERSITY"
                key={key}
                className="accordion bg-darkgray-form m-b-md"
                // extra={

                // }
              >
                {item.organization.status === "Pending" && (
                  <Alert
                    type="warning"
                    message="This organization is currently pending approval and none editable"
                    className="m-b-md"
                  />
                )}

                <Row gutter={24}>
                  <Col xs={24} sm={24} md={24} className="m-b-md">
                    <FloatInput
                      label="Type"
                      placeholder="Type"
                      value={item.organization.organization_type}
                      disabled={true}
                    />
                  </Col>

                  <Col xs={24} sm={24} md={24} className="m-b-md">
                    <FloatInput
                      label="Organization Name"
                      placeholder="Organization Name"
                      value={item.organization.name}
                      disabled={true}
                    />
                  </Col>
                  <Col xs={24} sm={24} md={24} className="m-b-md">
                    <FloatInput
                      label="Sport"
                      placeholder="Sport"
                      value={item.sport}
                      disabled={true}
                    />
                  </Col>
                  <Col xs={24} sm={24} md={24} className="m-b-md">
                    <FloatInput
                      label="Gender"
                      placeholder="Gender"
                      value={item.gender}
                      disabled={true}
                    />
                  </Col>
                  <Col xs={24} sm={24} md={24} className="m-b-md">
                    <FloatInput
                      label="Coach Title"
                      placeholder="Coach Title"
                      value={item.title}
                      disabled={true}
                    />
                  </Col>
                </Row>

                {item.organization.status === "Approved" && (
                  <Row gutter={12}>
                    <Col xs={24} sm={24} md={6}>
                      <Button
                        // size="large"
                        className="btn-main-outline-2 m-b-md"
                        htmlType="submit"
                        block
                        onClick={(e) => {
                          console.log("item", item);
                          setModalEditData(item);
                          setModalEdit(true);
                          modalFormEdit.setFieldsValue({
                            organization_type:
                              item.organization.organization_type,
                            organization_id: item.organization_id,
                            sport: item.sport,
                            title: item.title,
                            gender: item.gender,
                            year: item.year,
                          });

                          if (
                            item.organization.organization_type === "School"
                          ) {
                            setOrganization(school);
                          } else if (
                            item.organization.organization_type === "Club"
                          ) {
                            setOrganization(club);
                          } else if (
                            item.organization.organization_type === "University"
                          ) {
                            setOrganization(university);
                          }
                        }}
                      >
                        <FontAwesomeIcon icon={faEdit} />
                        &nbsp;Edit
                      </Button>
                    </Col>
                    {key !== 0 && (
                      <Col xs={24} sm={24} md={6}>
                        <Popconfirm
                          placement="top"
                          title="Are you sure to delete this Organization?"
                          onConfirm={() => {
                            handleRemoveOrganization(item);
                          }}
                          okText="Yes"
                          cancelText="No"
                        >
                          <Button
                            // size="large"
                            className="btn-main-outline-9 m-b-md"
                            htmlType="submit"
                            block
                          >
                            <FontAwesomeIcon icon={faTrash} />
                            &nbsp;Delete
                          </Button>
                        </Popconfirm>
                      </Col>
                    )}
                  </Row>
                )}
              </Collapse.Panel>
            </Collapse>
          );
        })}

      <Modal
        visible={modalCreate}
        width={600}
        onCancel={(e) => setModalCreate(false)}
        maskStyle={{ zIndex: 2 }}
        zIndex={2}
        closeIcon={<FaTimes />}
        title="ADD FORM ORGANIZATION"
        className="assessment-modal"
        bodyStyle={{ padding: "15px" }}
        footer={[
          <Row gutter={12}>
            <Col xs={24} sm={24} md={10}>
              <Button
                block
                className="btn-main-outline-2"
                onClick={(e) => {
                  modalForm
                    .validateFields()
                    .then((values) => {
                      onFinishForm(values);
                    })
                    .catch((info) => {
                      console.log("Validate Failed:", info);
                    });
                }}
              >
                SUBMIT
              </Button>
            </Col>
          </Row>,
        ]}
      >
        <Form name="change-password" layout="vertical" form={modalForm}>
          <Row gutter={24}>
            <Col xs={24} sm={24} md={24}>
              <Form.Item
                name={"organization_type"}
                className="form-select-error"
                rules={[
                  {
                    required: true,
                    message: "This field is required.",
                  },
                ]}
              >
                <FloatSelect
                  label="Type"
                  placeholder="Type"
                  options={optionOrganizationType}
                  onChange={(val, opt) => {
                    if (val) {
                      if (val === "School") {
                        setOrganization(school);
                      } else if (val === "Club") {
                        setOrganization(club);
                      } else if (val === "University") {
                        setOrganization(university);
                      }
                    }
                  }}
                  required
                />
              </Form.Item>
            </Col>

            {!modalCreateNew ? (
              <Col xs={24} sm={24} md={24}>
                <Form.Item
                  name={"organization_id"}
                  className="form-select-error"
                  rules={[
                    {
                      required: true,
                      message: "This field is required.",
                    },
                  ]}
                >
                  <FloatSelect
                    label="Organization Name"
                    placeholder="Organization Name"
                    options={organization}
                    required
                  />
                </Form.Item>
              </Col>
            ) : (
              <Col xs={24} sm={24} md={24}>
                <Form.Item
                  name={"organization_name"}
                  rules={[
                    {
                      required: true,
                      message: "This field is required.",
                    },
                  ]}
                >
                  <FloatInput
                    label="Organization Name"
                    placeholder="Organization Name"
                    required
                  />
                </Form.Item>
              </Col>
            )}

            {modalCreateNew ? (
              <>
                <Col xs={24} sm={24} md={24} className="m-b-md">
                  <Typography.Text
                    onClick={(e) => {
                      setModalCreateNew(false);
                      modalForm.resetFields(["organization_id"]);
                    }}
                  >
                    Cancel,{" "}
                    <Typography.Link className="companyColor3">
                      click here.
                    </Typography.Link>
                  </Typography.Text>
                </Col>

                <Col xs={24} sm={24} md={24}>
                  <Form.Item
                    name={"country"}
                    rules={[
                      {
                        required: true,
                        message: "This field is required.",
                      },
                    ]}
                    className="form-select-error"
                  >
                    <FloatSelect
                      label="Country"
                      placeholder="Country"
                      options={country}
                      disabled={true}
                      required
                    />
                  </Form.Item>
                </Col>

                <Col xs={24} sm={24} md={24}>
                  <Form.Item
                    name={"state"}
                    rules={[
                      {
                        required: true,
                        message: "This field is required.",
                      },
                    ]}
                    className="form-select-error"
                  >
                    <FloatSelect
                      label={stateLabel}
                      placeholder={stateLabel}
                      options={optionState}
                      disabled={true}
                      required
                    />
                  </Form.Item>
                </Col>

                <Col xs={24} sm={24} md={24}>
                  <Form.Item
                    name={"address1"}
                    rules={[
                      {
                        required: true,
                        message: "This field is required.",
                      },
                    ]}
                  >
                    <FloatInput
                      label="Address 1"
                      placeholder="Address 1"
                      required
                    />
                  </Form.Item>
                </Col>

                <Col xs={24} sm={24} md={24}>
                  <Form.Item name={"address2"}>
                    <FloatInput label="Address 2" placeholder="Address 2" />
                  </Form.Item>
                </Col>

                <Col xs={24} sm={24} md={24}>
                  <Form.Item
                    name={"city"}
                    rules={[
                      {
                        required: true,
                        message: "This field is required.",
                      },
                    ]}
                    className="form-select-error"
                  >
                    <FloatSelect
                      label="City"
                      placeholder="City"
                      options={cityOption}
                      required
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24}>
                  <Form.Item
                    name={"zip"}
                    rules={[
                      {
                        required: true,
                        message: "This field is required.",
                      },
                      {
                        pattern: optionZip,
                        message: "Invalid " + zipLabel,
                      },
                    ]}
                  >
                    <FloatInput
                      label={zipLabel}
                      placeholder={zipLabel}
                      required
                    />
                  </Form.Item>
                </Col>
              </>
            ) : (
              <>
                <Col xs={24} sm={24} md={24} className="m-b-md">
                  <Typography.Text
                    onClick={(e) => {
                      setModalCreateNew(true);
                      modalForm.resetFields(["organization_name"]);
                    }}
                  >
                    Create new organization,{" "}
                    <Typography.Link className="companyColor3">
                      click here.
                    </Typography.Link>
                  </Typography.Text>
                </Col>
              </>
            )}

            <Col xs={24} sm={24} md={24}>
              <Form.Item
                name={"sport"}
                rules={[
                  {
                    required: true,
                    message: "This field is required.",
                  },
                ]}
                className="form-select-error"
              >
                <FloatSelect
                  label="Sport"
                  placeholder="Sport"
                  options={sportList}
                  required
                />
              </Form.Item>
            </Col>

            <Col xs={24} sm={24} md={24}>
              <Form.Item
                name={"gender"}
                rules={[
                  {
                    required: true,
                    message: "This field is required.",
                  },
                ]}
                className="form-select-error"
              >
                <FloatSelect
                  label="Gender"
                  placeholder="Gender"
                  options={[
                    {
                      label: "Male",
                      value: "Male",
                    },
                    {
                      label: "Female",
                      value: "Female",
                    },
                  ]}
                  required
                />
              </Form.Item>
            </Col>

            <Col xs={24} sm={24} md={24}>
              <Form.Item
                name={"title"}
                rules={[
                  {
                    required: true,
                    message: "This field is required.",
                  },
                ]}
              >
                <FloatInput
                  label="Coach Title"
                  placeholder="Coach Title"
                  required
                />
              </Form.Item>
            </Col>

            {/* <Col xs={24} sm={24} md={24}>
              <Form.Item
                name={"year"}
                rules={[
                  {
                    required: true,
                    message: "This field is required.",
                  },
                ]}
              >
                <FloatDatePicker
                  label="Year"
                  placeholder="Year"
                  format="YYYY"
                  picker="year"
                />
              </Form.Item>
            </Col> */}
          </Row>
        </Form>
      </Modal>

      <Modal
        visible={modalEdit}
        width={600}
        onCancel={(e) => setModalEdit(false)}
        maskStyle={{ zIndex: 2 }}
        zIndex={2}
        closeIcon={<FaTimes />}
        title="EDIT FORM ORGANIZATION"
        className="assessment-modal"
        bodyStyle={{ padding: "15px" }}
        footer={[
          <Row gutter={12}>
            <Col xs={24} sm={24} md={10}>
              <Button
                block
                className="btn-main-outline-2"
                onClick={(e) => {
                  modalFormEdit
                    .validateFields()
                    .then((values) => {
                      onFinishFormEdit(values);
                    })
                    .catch((info) => {
                      console.log("Validate Failed:", info);
                    });
                }}
              >
                SUBMIT
              </Button>
            </Col>
          </Row>,
        ]}
      >
        <Form name="change-password" layout="vertical" form={modalFormEdit}>
          <Row gutter={24}>
            <Col xs={24} sm={24} md={24}>
              <Form.Item
                name={"organization_type"}
                className="form-select-error"
                rules={[
                  {
                    required: true,
                    message: "This field is required.",
                  },
                ]}
              >
                <FloatSelect
                  label="Type"
                  placeholder="Type"
                  options={optionOrganizationType}
                  onChange={(val, opt) => {
                    if (val) {
                      if (val === "School") {
                        setOrganization(school);
                      } else if (val === "Club") {
                        setOrganization(club);
                      } else if (val === "University") {
                        setOrganization(university);
                      }
                    }
                  }}
                  required
                />
              </Form.Item>
            </Col>

            <Col xs={24} sm={24} md={24}>
              <Form.Item
                name={"organization_id"}
                className="form-select-error"
                rules={[
                  {
                    required: true,
                    message: "This field is required.",
                  },
                ]}
              >
                <FloatSelect
                  label="Organization Name"
                  placeholder="Organization Name"
                  options={organization}
                  required
                />
              </Form.Item>
            </Col>

            <Col xs={24} sm={24} md={24}>
              <Form.Item
                name={"sport"}
                rules={[
                  {
                    required: true,
                    message: "This field is required.",
                  },
                ]}
                className="form-select-error"
              >
                <FloatSelect
                  label="Sport"
                  placeholder="Sport"
                  options={sportList}
                  required
                />
              </Form.Item>
            </Col>

            <Col xs={24} sm={24} md={24}>
              <Form.Item
                name={"gender"}
                rules={[
                  {
                    required: true,
                    message: "This field is required.",
                  },
                ]}
                className="form-select-error"
              >
                <FloatSelect
                  label="Gender"
                  placeholder="Gender"
                  options={[
                    {
                      label: "Male",
                      value: "Male",
                    },
                    {
                      label: "Female",
                      value: "Female",
                    },
                  ]}
                  required
                />
              </Form.Item>
            </Col>

            <Col xs={24} sm={24} md={24}>
              <Form.Item
                name={"title"}
                rules={[
                  {
                    required: true,
                    message: "This field is required.",
                  },
                ]}
              >
                <FloatInput
                  label="Coach Title"
                  placeholder="Coach Title"
                  required
                />
              </Form.Item>
            </Col>
            {/* 
            <Col xs={24} sm={24} md={24}>
              <Form.Item
                name={"year"}
                rules={[
                  {
                    required: true,
                    message: "This field is required.",
                  },
                ]}
              >
                <FloatDatePicker
                  label="Year"
                  placeholder="Year"
                  format="YYYY"
                  picker="year"
                />
              </Form.Item>
            </Col> */}
          </Row>
        </Form>
      </Modal>
    </>
  );
}
