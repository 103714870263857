import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Layout, Menu, Space, Typography } from "antd";
import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";

import companyInfo from "../../providers/companyInfo";

import AdminSideMenu from "./RoleMenu/admin/AdminSideMenu";
import ManagerSideMenu from "./RoleMenu/manager/ManagerSideMenu";
import AthleteSideMenu from "./RoleMenu/athlete/AthleteSideMenu";
import CoachSideMenu from "./RoleMenu/coach/CoachSideMenu";
import AssessorSideMenu from "./RoleMenu/assessor/AssessorSideMenu";

const name = companyInfo().name;
const role = companyInfo().role;
const sidelogo = companyInfo().sidelogo;

export default function SideMenu(props) {
  const { history, sideMenuCollapse, setSideMenuCollapse } = props;

  const [menuItems, setMenuItems] = useState([]);

  // console.log(window.location.origin);

  useEffect(() => {
    if (role === "Admin") {
      setMenuItems(AdminSideMenu);
    } else if (role === "Manager") {
      setMenuItems(ManagerSideMenu);
    } else if (role === "Athlete" || role === "Athlete Guardian") {
      setMenuItems(AthleteSideMenu);
    } else if (role === "Coach") {
      setMenuItems(CoachSideMenu);
    } else if (role === "Assessor") {
      setMenuItems(AssessorSideMenu);
    }

    return () => {};
  }, []);

  let pathname = history.location.pathname;
  pathname = pathname.split("/");
  pathname = "/" + pathname[1];

  const [openKeys, setOpenKeys] = useState();

  useEffect(() => {
    setOpenKeys(
      menuItems
        .filter((item) => item.path === pathname)
        .map((item) => item.path)
    );
  }, [pathname, menuItems, sideMenuCollapse]);

  const onOpenChange = (keys) => {
    console.log("onOpenChange", keys);
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    const menuItemsFilter = menuItems
      .filter((item) => item.path === latestOpenKey)
      .map((item) => item.path);

    if (menuItemsFilter.indexOf(latestOpenKey) === -1) {
      setOpenKeys(menuItemsFilter);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  const [width, setWidth] = useState(window.innerWidth);
  // const [height, setHeight] = useState(window.innerHeight);
  const updateDimensions = () => {
    setWidth(window.innerWidth);
    // setHeight(window.innerHeight);
  };
  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  useEffect(() => {
    if (width <= 768) {
      setSideMenuCollapse(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const activeRoute = (routeName) => {
    const pathname = history.location.pathname;
    return pathname === routeName ? "ant-menu-item-selected" : "";
  };

  const activeSubRoute = (routeName) => {
    const pathname1 = history.location.pathname;
    const pathname2 = routeName;

    if (pathname2 === pathname1) {
      console.log("activeSubRoute", pathname1, pathname2);
    }
    return pathname2 === pathname1 ? "ant-menu-item-selected" : "";
  };

  const handleMenuRender = () => {
    return menuItems.map((item, index) => {
      if (item.children && item.children.length > 0) {
        let children_list = item.children.map((item2, index2) => {
          let link = "";

          if (item2.targetNew === 1) {
            link = (
              <Typography.Link
                target="_blank"
                href={window.location.origin + item2.path}
              >
                {item2.title ?? item2.permission}
              </Typography.Link>
            );
          } else {
            link = (
              <Link to={item2.path}>{item2.title ?? item2.permission}</Link>
            );
          }

          return (
            <Menu.Item
              key={item2.path}
              className={`${activeSubRoute(item2.path)}`}
            >
              {link}
            </Menu.Item>
          );
        });

        if (children_list && children_list.length > 0) {
          return (
            <Menu.SubMenu
              key={item.path}
              icon={item.icon}
              title={item.title}
              className={`${item.className ?? ""}`}
            >
              {children_list}
            </Menu.SubMenu>
          );
        }
      } else {
        return (
          <Menu.Item
            key={item.path}
            className={`${activeRoute(item.path)} ${item.className ?? ""}`}
            icon={item.icon}
          >
            {/* <span>{item.icon}</span> */}
            {item.targetNew === 1 ? (
              <Typography.Link
                target="new"
                href={window.location.origin + item.path}
              >
                {item.title ?? item.permission}
              </Typography.Link>
            ) : (
              <Link
                onClick={() => {
                  setOpenKeys([]);
                }}
                to={item.path}
              >
                {item.title ?? item.permission}
              </Link>
            )}
          </Menu.Item>
        );
      }

      return "";
    });
  };

  return (
    <Layout.Sider
      trigger={null}
      collapsible
      collapsed={sideMenuCollapse}
      className="scrollbar-2"
      style={{ zIndex: 3 }}
    >
      <Space className="ant-side-header" size={0}>
        {sideMenuCollapse ? (
          <MenuUnfoldOutlined onClick={() => setSideMenuCollapse(false)} />
        ) : (
          <MenuFoldOutlined onClick={() => setSideMenuCollapse(true)} />
        )}

        {!sideMenuCollapse && <img src={sidelogo} alt={name} width="180px" />}
      </Space>

      <Menu
        mode="inline"
        theme="light"
        className="sideMenu"
        openKeys={openKeys}
        selectedKeys={[pathname]}
        onOpenChange={onOpenChange}
      >
        {handleMenuRender()}
      </Menu>
    </Layout.Sider>
  );
}
