import { Link } from "react-router-dom";
import { Menu, Typography } from "antd";
import { faStar } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const menuLeft = (
	<div className="ant-space-icon">
		<Link to="/athlete/follow">
			<FontAwesomeIcon icon={faStar} />
			<Typography.Text className="ant-typography-text">
				Athletes <br /> I Follow
			</Typography.Text>
		</Link>
	</div>
);

export const dropDownMenuLeft = (
	<Menu>
		<Menu.Item key="/athlete/follow" icon={<FontAwesomeIcon icon={faStar} />}>
			<Link to="/athlete/follow">Athletes I Follow</Link>
		</Menu.Item>
	</Menu>
);
