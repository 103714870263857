import { Result, Button } from "antd";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import companyInfo from "../../providers/companyInfo";
// const userData = companyInfo().userData;
// const token = companyInfo().token;

const Error500 = () => {
  let history = useHistory();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    let userdata = localStorage.getItem("userdata");
    let token = localStorage.getItem("token");
    if (!userdata || !token) {
      localStorage.removeItem("userdata");
      localStorage.removeItem("token");
      localStorage.removeItem("userdata_admin");
      localStorage.removeItem("viewas");
      window.location.href = "/?redirect=" + window.location.href;
    } else {
      setLoading(true);
    }

    // $("body").removeAttr("style");
    return () => {};
  }, []);
  return (
    loading && (
      <Result
        style={{ color: "#FFF" }}
        status="500"
        title="500"
        subTitle="Sorry, something went wrong."
        extra={
          <Button
            type="primary"
            onClick={(e) => {
              window.location.href = "/?redirect=" + window.location.href;
            }}
            className="btn-primary-default invert"
          >
            Back Home
          </Button>
        }
      />
    )
  );
};

export default Error500;
