import React, { useState, useEffect } from "react";
import { Card, Col, Row, Table, Space, Button } from "antd";
import ComponentHeader from "../Components/ComponentHeader";
import {
  faArrowAltFromLeft,
  faPlus,
  faTicketAlt,
} from "@fortawesome/pro-solid-svg-icons";
import { GET } from "../../../providers/useAxiosQuery";
import $ from "jquery";
import { Link, useHistory } from "react-router-dom";
import {
  TablePageSize,
  TableGlobalSearch,
  TableShowingEntries,
  TablePagination,
} from "../Components/ComponentTableFilter";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import companyInfo from "../../../providers/companyInfo";

const role = companyInfo().role;
export default function PageTiketing({ match, permission }) {
  const history = useHistory();
  // const userdata = companyInfo().userData;

  const [tableFilter, setTableFilter] = useState({
    page: 1,
    page_size: 50,
    search: "",
    sort_field: "subject",
    sort_order: "desc",
    status: [],
  });

  const [tableTotal, setTableTotal] = useState(0);
  const [dataSource, setDataSource] = useState([]);
  const {
    refetch: refetchTable,
    isLoading: isLoadingTable,
    isFetching: isFetchingTable,
  } = GET(`api/v1/ticket?${$.param(tableFilter)}`, "ticket", (res) => {
    if (res.success) {
      console.log("dataTable", res);
      setDataSource(res.data && res.data.data);
      setTableTotal(res.data.total);
    }
  });

  const handleTableChange = (pagination, filters, sorter, extra) => {
    // console.log(
    // 	"pagination, filters, sorter,extra",
    // 	pagination,
    // 	filters,
    // 	sorter,
    // 	extra
    // );
    setTableFilter({
      ...tableFilter,
      sort_field: sorter.columnKey,
      sort_order: sorter.order ? sorter.order.replace("end", "") : null,
      // page: 1,
      status: filters.status,
    });
  };

  const [searchText, setSearchText] = useState("");
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setTableFilter({
        ...tableFilter,
        search: searchText,
        page: 1,
      });
    }, 1500);
    return () => {
      clearTimeout(timeoutId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText]);

  useEffect(() => {
    refetchTable();
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableFilter]);

  // const handleDelete = (id) => {
  //   let data = {
  //     status: 1,
  //     id: id,
  //   };

  //   mutateDelete(data, {
  //     onSuccess: (res) => {
  //       if (res.success) {
  //         notification.success({
  //           message: "Success",
  //           description: "Successfully deleted.",
  //         });
  //       }
  //     },
  //   });
  // };

  // const { mutate: mutateDelete, isLoading: isLoadingDelete } = UPDATE(
  //   "api/v1/assessment",
  //   "assessment"
  // );

  const handleAddTicket = () => {
    if (role === "Athlete") {
      history.push("/support/ticketing/create");
    } else {
      history.push("/ticketing/create");
    }
  };

  return (
    <>
      <ComponentHeader title="Ticketing" sub_title="VIEW" icon={faTicketAlt} />

      <Card className="card-min-height" id="PageTicketing">
        <Row gutter={12}>
          <Col xs={24} sm={24} md={18}>
            <Button className="btn-main-outline-2" onClick={handleAddTicket}>
              <FontAwesomeIcon icon={faPlus} style={{ marginRight: "5px" }} />{" "}
              Add Ticket
            </Button>
            <br />
            <br />
          </Col>
        </Row>
        <Row gutter={12}>
          <Col xs={24} sm={24} md={16} lg={16} xl={18} className="m-b-sm">
            <Space>
              <TablePageSize
                paginationFilter={tableFilter}
                setPaginationFilter={setTableFilter}
              />
            </Space>
          </Col>
          <Col xs={24} sm={24} md={8} lg={8} xl={6}>
            <TableGlobalSearch
              paginationFilter={searchText}
              setPaginationFilter={setSearchText}
            />
          </Col>
        </Row>
        <Row gutter={24}>
          <Col xs={24} sm={24} md={24} className="m-t-sm m-b-sm">
            <Table
              className="ant-table-default ant-table-striped"
              dataSource={dataSource && dataSource}
              rowKey={(record) => record.id}
              loading={isLoadingTable || isFetchingTable}
              pagination={false}
              bordered={false}
              onChange={handleTableChange}
              scroll={{ x: "max-content" }}
            >
              <Table.Column
                title="Subject & Description"
                key="subject"
                dataIndex="subject"
                defaultSortOrder="descend"
                sorter={true}
                width={"230px"}
              />
              <Table.Column
                title="View/Reply"
                key="reply"
                dataIndex="reply"
                render={(text, record) => {
                  let location = `/ticketing/reply/${record.id}`;
                  if (role === "Athlete") {
                    location = `/support/ticketing/reply/${record.id}`;
                  }

                  return (
                    <Link to={location}>
                      <FontAwesomeIcon icon={faArrowAltFromLeft} />
                    </Link>
                  );
                }}
                width={"100px"}
              />
              <Table.Column
                title="Created"
                key="created_at_str"
                dataIndex="created_at_str"
                sorter={true}
                width={"100px"}
              />
              <Table.Column
                title="Requester"
                key="requester_user"
                dataIndex="requester_user"
                sorter={true}
                width={"200px"}
              />
              <Table.Column
                title="Assigned"
                key="assigned_user"
                dataIndex="assigned_user"
                sorter={true}
                width={"200px"}
              />
              <Table.Column
                title="Status"
                key="status"
                dataIndex="status"
                sorter={true}
                width={"150px"}
                filters={[
                  { text: "Open", value: "Open" },
                  {
                    text: "Awaiting Customer Reply",
                    value: "Awaiting Customer Reply",
                  },
                  {
                    text: "Awaiting Support Reply",
                    value: "Awaiting Support Reply",
                  },
                  { text: "On Hold", value: "On Hold" },
                  { text: "Closed", value: "Closed" },
                  { text: "Archive", value: "Archive" },
                ]}
                onFilter={(value, record) => record.status.indexOf(value) === 0}
              />
              <Table.Column
                title="Priority"
                key="priority"
                dataIndex="priority"
                sorter={true}
                width={"150px"}
              />
            </Table>
          </Col>
          <Col xs={24} sm={24} md={24}>
            <Space className="ant-space-flex-space-between table1">
              <TableShowingEntries />

              <TablePagination
                paginationFilter={tableFilter}
                setPaginationFilter={setTableFilter}
                setPaginationTotal={tableTotal}
                showLessItems={true}
                showSizeChanger={false}
              />
            </Space>
          </Col>
          <Col xs={24} sm={24} md={12}></Col>
        </Row>
        <br />
      </Card>
    </>
  );
}
