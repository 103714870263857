import { useEffect, useState } from "react";
import "../../../../assets/css/private-layout-athlete-view-webpage/private-layout-athlete-view-webpage.css";
import {
  Button,
  Carousel,
  Col,
  Collapse,
  Divider,
  Image,
  Layout,
  Modal,
  notification,
  Row,
  Space,
  Table,
  Typography,
} from "antd";
import companyInfo from "../../../../providers/companyInfo";
import { GET, POST } from "../../../../providers/useAxiosQuery";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faShareAlt } from "@fortawesome/free-solid-svg-icons";
import {
  faYoutube,
  faInstagram,
  faFacebookSquare,
  faTwitterSquare,
  faInstagramSquare,
  faFacebook,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";
// import { faStar } from "@fortawesome/free-regular-svg-icons";
// import { faStar as faStar2 } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
// import $ from "jquery";
import {
  RiDoubleQuotesL,
  RiDoubleQuotesR,
  RiSingleQuotesR,
} from "react-icons/ri";
import Highcharts from "highcharts";
// import { Link } from "react-router-dom";
// import ReactPlayer from "react-player";
import { StarFilled, StarOutlined } from "@ant-design/icons";

import pdf2base64 from "pdf-to-base64";

import pdfElite from "../../../../providers/pdf/pdfElite";
import pdfPro from "../../../../providers/pdf/pdfPro";
import pdfStarter from "../../../../providers/pdf/pdfStarter";

// import pdfFileElite from "../../../../assets/pdf/2022-June-BFS-Elite-New-Report-BLANK.pdf";
// import pdfFileStarter from "../../../../assets/pdf/2022-June-BFS-New-Starter-Report-BLANK.pdf";
// import pdfFileProfessional from "../../../../assets/pdf/2022-June-BFS-New-Professional-Report-BLANK.pdf";
import $ from "jquery";
import { SpinnerDotted } from "spinners-react";
// import average from "../../../../providers/pdf/average";
// import newtonPound from "../../../../providers/pdf/newtonPound";
// import averageText from "../../../../providers/pdf/averageText";
// import averagePercentage from "../../../../providers/pdf/averagePercentage";
// import truncateDecimalNumber from "../../../../providers/pdf/truncateDecimalNumber";

import ReactiveBg from "../../../../assets/img/Speed-1.jpg";

const sidelogo = companyInfo().sidelogo;
const logo = companyInfo().logo;
const userData = companyInfo().userData;
// const apiUrl = companyInfo().apiUrl;

export default function PageAthleticProfileViewWebpage(props) {
  const { match } = props;
  const [pdfUrlElite, setPdfUrlElite] = useState();
  const [pdfUrlStarter, setPdfUrlStarter] = useState();
  const [pdfUrlPro, setPdfUrlPro] = useState();

  useEffect(() => {
    let elite =
      process.env.REACT_APP_URL +
      "pdf/2022-June-BFS-Elite-New-Report-BLANK.pdf";
    let starter =
      process.env.REACT_APP_URL +
      "pdf/2022-June-BFS-New-Starter-Report-BLANK.pdf";
    let pro =
      process.env.REACT_APP_URL +
      "pdf/2022-June-BFS-New-Professional-Report-BLANK.pdf";
    // elite
    pdf2base64(elite)
      .then((response) => {
        // console.log("response", response); //cGF0aC90by9maWxlLmpwZw==
        setPdfUrlElite(response);
      })
      .catch((error) => {
        console.log(error); //Exepection error....
      });
    // starter
    pdf2base64(starter)
      .then((response) => {
        // console.log("response", response); //cGF0aC90by9maWxlLmpwZw==
        setPdfUrlStarter(response);
      })
      .catch((error) => {
        console.log(error); //Exepection error....
      });
    // pro
    pdf2base64(pro)
      .then((response) => {
        // console.log("response", response); //cGF0aC90by9maWxlLmpwZw==
        setPdfUrlPro(response);
      })
      .catch((error) => {
        console.log(error); //Exepection error....
      });
  }, []);

  // console.log("props", props);
  // console.log("userData", userData);

  const [tableFilter, setTableFilter] = useState({
    page: 1,
    page_size: 50,
    sort_field: "",
    sort_order: "",
    name: match.params.name,
  });

  const [tableFilterAssessment, setTableFilterAssessment] = useState({
    page: 1,
    page_size: 50,
    sort_field: "",
    sort_order: "",
    // user_id: userData.id,
    from: "athlete_profile",
  });

  useEffect(() => {
    if (match.params) {
      if (match.params.name) {
        setTableFilter({
          ...tableFilter,
          name: match.params.name,
          from: `Preview Website${
            userData.role === "Coach" ? `&coach_id=${userData.id}` : ""
          }`,
        });
      } else {
        setTableFilter({
          ...tableFilter,
          name: match.params.name,
          from: `Preview Website${
            userData.role === "Coach" ? `&coach_id=${userData.id}` : ""
          }`,
        });
      }
    } else {
      setTableFilter({
        ...tableFilter,
        user_id: userData.id,
        from: "Preview Website",
      });
    }
    console.log("match", match.params.name);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match.params]);

  const [dataAthleteWebsite, setDataAthleteWebsite] = useState();
  const [dataAthleteWebsiteAss, setDataAthleteWebsiteAss] = useState();
  // const [dataCoachFollow, setDataCoachFollow] = useState();
  const [websitePhoto, setWebsitePhoto] = useState();
  const [assessmentList, setAssessmentList] = useState([]);

  const [hasWebsite, setHasWebsite] = useState(true);

  const {
    // isLoading: isLoadingUserAthleteInfo,
    refetch: refetchUserAthleteInfo,
  } = GET(
    `api/v1/athlete_website?${
      match.params && match.params.name
        ? `name=${match.params.name}&from=Preview Website${
            userData.role === "Coach" ? `&coach_id=${userData.id}` : ""
          }`
        : `user_id=${userData.id}&from=Preview Website`
    }`,
    "website__website",
    (res) => {
      if (res.data && res.data.length > 0) {
        getPDF(res.data[0].user_id);
        refetchFollow(res.data[0].user_id);

        let data = [];
        if (match.params) {
          data = res.data[0];
        } else {
          let dataFiltered = res.data.filter(
            (itemFiltered) => itemFiltered.is_default === 1
          );
          if (dataFiltered.length > 0) {
            data = dataFiltered[0];
          } else {
            data = res.data[0];
          }
        }

        console.log("dataAthleteWebsite", data);

        if (data.user.athlete_info && data.user.athlete_info.video_link) {
          getYoutube(
            data.user.athlete_info && data.user.athlete_info.video_link
          );
        }

        setDataAthleteWebsite(data);
        if (
          data &&
          data.user &&
          data.user.athlete_assessment &&
          data.user.athlete_assessment.length > 0
        ) {
          let athlete_assessment = data.user.athlete_assessment;
          setDataAthleteWebsiteAss(athlete_assessment[0]);
        }

        if (res.dataCoachFollow) {
          // setDataCoachFollow(
          //   res.dataCoachFollow.length > 0 && res.dataCoachFollow[0]
          // );
        }
        // setTableTotal(res.data.total);

        let image_type =
          data.user.athlete_info && data.user.athlete_info.website_photo
            ? data.user.athlete_info.website_photo.split("/")
            : "";

        // console.log("image_type out", data.user.athlete_info.website_photo);
        if (data.user.athlete_info && image_type[0] !== undefined) {
          if (image_type[0] === "https:") {
            image_type = data.user.athlete_info.website_photo;
            // console.log("data.user.profile_image", data.user.profile_image);
          } else {
            image_type =
              companyInfo().apiUrl + data.user.athlete_info.website_photo;
          }
          // console.log("image_type", image_type);
          setWebsitePhoto(image_type);
        } else {
          // console.log("logo", logo);
          setWebsitePhoto(logo);
        }

        // Highcharts.chart("pie_container", {
        // 	chart: {
        // 		backgroundColor: null,
        // 		plotBackgroundColor: null,
        // 		plotBorderWidth: null,
        // 		plotShadow: false,
        // 		type: "pie",
        // 	},
        // 	credits: false,
        // 	title: {
        // 		text: "",
        // 	},
        // 	tooltip: {
        // 		pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>",
        // 	},
        // 	accessibility: {
        // 		point: {
        // 			valueSuffix: "%",
        // 		},
        // 	},
        // 	plotOptions: {
        // 		pie: {
        // 			allowPointSelect: true,
        // 			cursor: "pointer",
        // 			dataLabels: {
        // 				enabled: false,
        // 				format: "<b>{point.name}</b>: {point.percentage:.1f} %",
        // 			},
        // 		},
        // 	},
        // 	legend: { enabled: false },
        // 	series: [
        // 		{
        // 			name: "Benchmark",
        // 			colorByPoint: true,
        // 			colors: ["#ff8303", "#a8a9ac"],
        // 			data: [
        // 				{
        // 					name: "benchmark 1",
        // 					y: 0,
        // 				},
        // 				{
        // 					name: "benchmark 2",
        // 					y: 0,
        // 				},
        // 			],
        // 		},
        // 	],
        // });
      } else {
        setHasWebsite(false);
      }
    }
  );

  const getPDF = (val) => {
    let data = {
      ...tableFilterAssessment,
      user_id: val,
    };
    console.log("getPDF", data);

    mutatePdf(data, {
      onSuccess: (res) => {
        if (res.success) {
          console.log("getPDF", res.data);
          setAssessmentList(res.data);
        }
      },
    });
  };

  const {
    mutate: mutatePdf,
    isLoading: isLoadingPdf,
    isFetching: isFetchingPdf,
  } = POST("api/v1/get_web_data", "get_web_data");

  useEffect(() => {
    refetchUserAthleteInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableFilter, tableFilterAssessment]);

  const [dataFollow, setDataFollow] = useState([]);
  const refetchFollow = (id) => {
    let data = {
      athlete_id: id,
      user_id: userData.id,
    };
    mutateGetFollow(data, {
      onSuccess: (res) => {
        if (res.success) {
          console.log("refetchFollow", res);
          setDataFollow(res.data);
        }
      },
    });
  };

  const { mutate: mutateGetFollow } = POST(
    "api/v1/check_follow",
    "check_follow"
  );

  const handleFollow = () => {
    let data = {
      user_id: userData.id,
      athlete_id: dataAthleteWebsite.user_id,
      status: dataFollow.length > 0 && dataFollow[0].status === 1 ? 0 : 1,
    };

    // console.log("check_follow", data);

    mutateFollowAdd(data, {
      onSuccess: (res) => {
        if (res.success) {
          notification.success({
            message: "Athlete Follow",
            description: "Successfully Followed this Athlete",
          });
          refetchFollow(dataAthleteWebsite.user_id);
        }
      },
    });
  };

  const { mutate: mutateFollowAdd } = POST(
    "api/v1/coach_follow",
    "check_follow"
  );

  const handleTableChange = (pagination, filters, sorter) => {
    setTableFilterAssessment({
      ...tableFilterAssessment,
      sort_field: sorter.columnKey,
      sort_order: sorter.order ? sorter.order.replace("end", "") : null,
      // page: 1,
    });
  };

  // const { mutate: mutateGetSum } = POST("api/v1/getSum", "getSum");
  const [pdfDownload, setPdfDownload] = useState({
    type: "",
    data: [],
    permission: [],
  });
  const [pdfData, setPdfData] = useState();
  const handlePdf = (record) => {
    let data = record.pdf_data ? JSON.parse(record.pdf_data) : "";
    $(".globalLoading").removeClass("hide");
    setPdfData(record);
    setPdfDownload({
      type: record.type,
      data: data,
      permission: record.athlete_pdf_display_data,
    });
    // if (record.type === "Starter" || record.type === "Starter $29.99") {
    //   if (pdfUrlStarter) {
    //     pdfStarter(pdfUrlStarter, data, record.athlete_pdf_display_data);
    //   } else {
    //     notification.warning({
    //       message: "Warning",
    //       description:
    //         "Please wait PDF file is still rendering, you may re-download after.",
    //     });
    //   }
    // } else if (
    //   record.type === "Professional" ||
    //   record.type === "Professional $69.99"
    // ) {
    //   if (pdfUrlPro) {
    //     pdfPro(pdfUrlPro, data, record.athlete_pdf_display_data);
    //   } else {
    //     notification.warning({
    //       message: "Warning",
    //       description:
    //         "Please wait PDF file is still rendering, you may re-download after.",
    //     });
    //   }
    // } else if (record.type === "Elite" || record.type === "Elite $199.99") {
    //   if (pdfUrlElite) {
    //     pdfPro(pdfUrlElite, data, record.athlete_pdf_display_data);
    //   } else {
    //     notification.warning({
    //       message: "Warning",
    //       description:
    //         "Please wait PDF file is still rendering, you may re-download after.",
    //     });
    //   }
    // }
  };

  useEffect(() => {
    if (pdfData) {
      const timeoutId = setTimeout(() => {
        if (
          pdfDownload.type === "Starter" ||
          pdfDownload.type === "Starter $29.99"
        ) {
          pdfStarter(pdfUrlStarter, pdfDownload.data, pdfDownload.permission);
        } else if (
          pdfDownload.type === "Professional" ||
          pdfDownload.type === "Professional $69.99"
        ) {
          pdfPro(pdfUrlPro, pdfDownload.data, pdfDownload.permission);
        } else if (
          pdfDownload.type === "Elite" ||
          pdfDownload.type === "Elite $199.99"
        ) {
          pdfElite(pdfUrlElite, pdfDownload.data, pdfDownload.permission);
        }
        console.log(pdfDownload);
        setPdfDownload({
          type: "",
          data: [],
          permission: [],
        });
        setPdfData("");
        $(".globalLoading").addClass("hide");
      }, 2000);

      return () => {
        clearTimeout(timeoutId);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pdfDownload]);

  const [dataVideo, setDataVideo] = useState([]);
  const [dataVideoType, setDataVideoType] = useState("watch");
  const getYoutube = (val) => {
    // return "";
    let data = {
      link: val,
    };
    mutateVideo(data, {
      onSuccess: (res) => {
        if (res.success) {
          console.log("get_youtube", res);
          if (res.type === "channel") {
            setDataVideo(res.data);
          } else {
            let src = res.data.replace("watch?v=", "embed/");

            let split_link = src.split("&list=");

            if (split_link[1]) {
              setDataVideo(split_link[0]);
            } else {
              setDataVideo(src);
            }
            console.log("get_youtube", src);
            console.log("get_youtube", split_link);
            // console.log(
            //   "get_youtube",
            //   "https://i.ytimg.com/vi_webp/zCRw1eSofCE/maxresdefault.webp"
            // );
          }
          setDataVideoType(res.type);
        }
      },
    });
  };

  const { mutate: mutateVideo } = POST("api/v1/get_youtube", "get_youtube");

  // const onChange = (currentSlide) => {
  // 	console.log(currentSlide);
  // };

  return (
    <Layout
      className="private-layout-athlete-view-webpage"
      id="PageAthleticProfileViewWebpage"
    >
      <div className="globalLoading hide">
        <SpinnerDotted thickness="100" color="ff8303" enabled={true} />
      </div>
      <Layout.Header>
        <Row gutter={12}>
          <Col xs={24} sm={24} md={24} lg={24}>
            <Image src={sidelogo} preview={false} />
          </Col>
        </Row>
      </Layout.Header>

      <Layout.Content>
        <Row className="row-main-content">
          <Col xs={24} sm={24} md={24} lg={24}>
            <Typography.Title level={2} className="blogs-name">
              {dataAthleteWebsite &&
                `${dataAthleteWebsite.user.firstname} ${dataAthleteWebsite.user.lastname}`}
            </Typography.Title>
          </Col>
          <Col xs={24} sm={24} md={24} lg={10} className="webpage-left">
            <Row gutter={12}>
              <Col xs={24} sm={24} md={24}>
                {dataAthleteWebsite && (
                  <Space
                    split={<Divider type="vertical" />}
                    className="space-athlete-organization"
                  >
                    <Typography.Text className="text-sport">
                      {dataAthleteWebsite.sport}
                    </Typography.Text>
                    {dataAthleteWebsite.position && (
                      <Typography.Text className="text-position">
                        {JSON.parse(dataAthleteWebsite.position).join(", ")}
                      </Typography.Text>
                    )}
                  </Space>
                )}
              </Col>

              <Col xs={24} sm={24} md={24} className="m-t-sm">
                {userData.role === "Coach" && (
                  <span
                    style={{ cursor: "pointer" }}
                    onClick={(e) => handleFollow()}
                  >
                    {/* <FontAwesomeIcon
                          icon={faStar}
                          className="companyColor3 m-r-xs"
                        /> */}
                    {dataFollow.length > 0 ? (
                      <StarFilled className="companyColor3 m-r-xs" />
                    ) : (
                      <StarOutlined className="companyColor3 m-r-xs" />
                    )}
                    <Typography.Text style={{ fontSize: "10px" }}>
                      COACHES - FOLLOW THIS ATHLETE
                    </Typography.Text>
                    <span className="companyColor3 m-l-sm m-r-sm">|</span>
                  </span>
                )}

                {dataAthleteWebsite?.user?.athlete_info?.facebook_acc ||
                dataAthleteWebsite?.user?.athlete_info?.instagram_acc ||
                dataAthleteWebsite?.user?.athlete_info?.twitter_acc ? (
                  <Space size={20}>
                    {dataAthleteWebsite &&
                      dataAthleteWebsite.user.athlete_info &&
                      dataAthleteWebsite.user.athlete_info.facebook_acc && (
                        <Typography.Link
                          href={
                            dataAthleteWebsite.user.athlete_info.facebook_acc
                          }
                          target="_blank"
                        >
                          <FontAwesomeIcon
                            style={{ fontSize: "18px" }}
                            icon={faFacebook}
                            className="companyColor3"
                          />
                        </Typography.Link>
                      )}

                    {dataAthleteWebsite &&
                      dataAthleteWebsite.user.athlete_info &&
                      dataAthleteWebsite.user.athlete_info.instagram_acc && (
                        <Typography.Link
                          href={
                            dataAthleteWebsite.user.athlete_info.instagram_acc
                          }
                          target="_blank"
                        >
                          <FontAwesomeIcon
                            style={{ fontSize: "18px" }}
                            icon={faInstagram}
                            className="companyColor3"
                          />
                        </Typography.Link>
                      )}

                    {dataAthleteWebsite &&
                      dataAthleteWebsite.user.athlete_info &&
                      dataAthleteWebsite.user.athlete_info.twitter_acc && (
                        <Typography.Link
                          href={
                            dataAthleteWebsite.user.athlete_info.twitter_acc
                          }
                          target="_blank"
                        >
                          <FontAwesomeIcon
                            style={{ fontSize: "18px" }}
                            icon={faTwitter}
                            className="companyColor3"
                          />
                        </Typography.Link>
                      )}
                  </Space>
                ) : (
                  ""
                )}
              </Col>

              <Col xs={24} sm={24} md={24} className="m-t-sm">
                <Row gutter={20}>
                  <Col xs={24} sm={24} md={12}>
                    <div className="view-webpage-website-photo">
                      <Image src={websitePhoto} />
                    </div>
                    <Button
                      className="btn-primary-default active m-t-xs b-r-none"
                      href={`mailto:${
                        dataAthleteWebsite &&
                        dataAthleteWebsite.user.email &&
                        dataAthleteWebsite.user.email
                      }`}
                      target="_blank"
                      block
                    >
                      EMAIL ME
                    </Button>
                  </Col>
                  <Col xs={24} sm={24} md={12}>
                    <Row gutter={24} style={{ marginTop: "-5px" }}>
                      <Col xs={24} sm={24} md={24} className="info-1">
                        <strong>
                          Graduates{" "}
                          {dataAthleteWebsite &&
                            dataAthleteWebsite.user.athlete_info &&
                            dataAthleteWebsite.user.athlete_info
                              .graduation_year}
                        </strong>
                      </Col>

                      <Col xs={12} sm={12} md={24} className="info-2">
                        {dataAthleteWebsite && dataAthleteWebsite.user.gender}
                        <br />
                        {dataAthleteWebsite &&
                          dataAthleteWebsite.user.birthdate &&
                          Math.floor(
                            moment(new Date()).diff(
                              dataAthleteWebsite.user.birthdate,
                              "years",
                              true
                            )
                          )}{" "}
                        yrs
                        <br />
                        {dataAthleteWebsite &&
                          dataAthleteWebsite.user.athlete_info &&
                          dataAthleteWebsite.user.athlete_info.height}
                        ”<br />
                        {dataAthleteWebsite &&
                          dataAthleteWebsite.user.athlete_info &&
                          dataAthleteWebsite.user.athlete_info.weight}{" "}
                        lbs
                      </Col>

                      <Col xs={12} sm={12} md={24}>
                        <div className="info-3">
                          {dataAthleteWebsite &&
                            dataAthleteWebsite.organization &&
                            dataAthleteWebsite.organization.name}
                        </div>
                        <div className="info-4">
                          {dataAthleteWebsite &&
                            dataAthleteWebsite.organization &&
                            dataAthleteWebsite.organization.city + ", "}
                          {dataAthleteWebsite &&
                            dataAthleteWebsite.organization &&
                            dataAthleteWebsite.organization.state}
                          <br />
                          {dataAthleteWebsite &&
                            dataAthleteWebsite.organization &&
                            dataAthleteWebsite.organization.country}
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>

            <br />
            <br />
          </Col>

          <Col
            xs={24}
            sm={24}
            md={24}
            lg={14}
            className="webpage-latest-assessment-snapshot"
          >
            <Row gutter={[12, 12]}>
              <Col xs={24} sm={24} md={24}>
                <Typography.Title level={4} className="title m-b-n">
                  Latest Assessment Snapshot
                </Typography.Title>
              </Col>

              <Col xs={24} sm={24} md={24} className="m-t-sm">
                <Typography.Text className="sub-title">
                  TO REVIEW ALL MY ASSESSMENTS AND STATS, SELECT THE “BFSS
                  ASSESSMENT STATS” SECTION BELOW
                </Typography.Text>
              </Col>
            </Row>

            {/* <img scr={ReactiveBg} style={{ height: "100px", width: "100px" }} /> */}

            <Row gutter={[12, 12]} className="m-t-xs">
              <Col xs={24} sm={24} md={12}>
                <Row gutter={12}>
                  <Col xs={24} sm={24} md={24} className="content-left m-b-md">
                    <div className="content-left-body reactive-bg">
                      <Row gutter={24}>
                        <Col xs={14}>
                          <Typography.Title level={1} className="count">
                            {dataAthleteWebsiteAss &&
                            dataAthleteWebsiteAss.cmj_rsi_no ? (
                              <>
                                <span>{dataAthleteWebsiteAss.cmj_rsi_no}</span>
                                {/* <RiSingleQuotesR /> */}
                              </>
                            ) : (
                              "N/A"
                            )}
                          </Typography.Title>
                        </Col>

                        <Col xs={10}>
                          <div className="title">
                            <Typography.Text strong>
                              REACTIVE STRENGTH
                            </Typography.Text>
                            <br />
                            <Typography.Text
                              level={4}
                              strong
                              className="text-gray"
                            >
                              (RSI)
                            </Typography.Text>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Col>

                  <Col xs={24} sm={24} md={24} className="content-left m-b-md">
                    <div className="content-left-body maximal-bg">
                      <Row gutter={24}>
                        <Col xs={14}>
                          <Typography.Title level={1} className="count">
                            {dataAthleteWebsiteAss &&
                            dataAthleteWebsiteAss.ms_peak_force ? (
                              <>
                                <span>
                                  {(
                                    dataAthleteWebsiteAss.ms_peak_force /
                                    4.448222
                                  ).toFixed()}
                                </span>
                              </>
                            ) : (
                              "N/A"
                            )}
                          </Typography.Title>
                        </Col>

                        <Col xs={10}>
                          <div className="title">
                            <Typography.Text strong>
                              MAXIMAL STRENGTH
                            </Typography.Text>
                            <br />
                            <Typography.Text
                              level={4}
                              strong
                              className="text-gray"
                            >
                              (LBS)
                            </Typography.Text>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>
              </Col>

              <Col xs={24} sm={24} md={12}>
                <Row gutter={12} className="content-right-mb">
                  <Col xs={24} sm={24} md={24} className="content-right m-b-md">
                    <div className="content-right-body acc-mb">
                      <Row gutter={24}>
                        <Col xs={12}>
                          <Typography.Title level={1} className="count">
                            {dataAthleteWebsiteAss &&
                            dataAthleteWebsiteAss.sprint_time_tem_meter ? (
                              <>
                                <span>
                                  {dataAthleteWebsiteAss.sprint_time_tem_meter}
                                </span>
                              </>
                            ) : (
                              "N/A"
                            )}
                          </Typography.Title>
                        </Col>

                        <Col xs={12}>
                          <div className="title">
                            <Typography.Text strong>
                              ACCELERATION SPEED
                            </Typography.Text>
                            <br />
                            <Typography.Text
                              level={4}
                              strong
                              className="text-gray"
                            >
                              (SECS)
                            </Typography.Text>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Col>

                  <Col xs={24} sm={24} md={24} className="content-right m-b-md">
                    <div className="content-right-body elastic-bg">
                      <Row gutter={24}>
                        <Col xs={12}>
                          <Typography.Title level={1} className="count">
                            {dataAthleteWebsiteAss &&
                            dataAthleteWebsiteAss.es_height ? (
                              <>
                                <span>{dataAthleteWebsiteAss.es_height}</span>
                                {/* <RiSingleQuotesR /> */}
                                <RiDoubleQuotesR
                                  style={{ marginLeft: "-5px" }}
                                />
                              </>
                            ) : (
                              "N/A"
                            )}
                          </Typography.Title>
                        </Col>

                        <Col xs={12}>
                          <div className="title elastic-title">
                            <Typography.Text strong>
                              ELASTIC STRENGTH
                            </Typography.Text>
                            <br />
                            <Typography.Text
                              level={4}
                              strong
                              className="text-gray"
                            >
                              (IN)
                            </Typography.Text>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Col>

                  <Col xs={24} sm={24} md={24} className="content-right m-b-md">
                    <div className="content-right-body ball-bg">
                      <Row gutter={24}>
                        <Col xs={12}>
                          <Typography.Title level={1} className="count">
                            {dataAthleteWebsiteAss &&
                            dataAthleteWebsiteAss.sj_height ? (
                              <>
                                <span>{dataAthleteWebsiteAss.sj_height}</span>
                                <RiDoubleQuotesR
                                  style={{ marginLeft: "-5px" }}
                                />
                              </>
                            ) : (
                              "N/A"
                            )}
                          </Typography.Title>
                        </Col>

                        <Col xs={12}>
                          <div className="title">
                            <Typography.Text strong>
                              BALLISTIC STRENGTH
                            </Typography.Text>
                            <br />
                            <Typography.Text
                              level={4}
                              strong
                              className="text-gray"
                            >
                              (IN)
                            </Typography.Text>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>

          <Col xs={24} sm={24} md={24} lg={24} className="m-t-lg">
            <Collapse
              className="main-1-collapse border-none"
              expandIcon={({ isActive }) =>
                isActive ? (
                  <span
                    className="ant-menu-submenu-arrow"
                    style={{ color: "#FFF", transform: "rotate(270deg)" }}
                  ></span>
                ) : (
                  <span
                    className="ant-menu-submenu-arrow"
                    style={{ color: "#FFF", transform: "rotate(90deg)" }}
                  ></span>
                )
              }
              defaultActiveKey={["1"]}
              expandIconPosition="start"
            >
              <Collapse.Panel
                header="BFSS ASSESSMENTS"
                key="1"
                className="accordion bg-darkgray-form m-b-md border "
              >
                <div className="table-responsive">
                  <Table
                    className="ant-table-default ant-table-striped"
                    dataSource={assessmentList && assessmentList.data}
                    rowKey={(record) => record.id}
                    // loading={isLoadingTable || isFetchingTable}
                    loading={isLoadingPdf || isFetchingPdf}
                    pagination={false}
                    bordered={false}
                    onChange={handleTableChange}
                    scroll={{ x: "max-content" }}
                  >
                    <Table.Column
                      title="Date"
                      key="date_assessment"
                      dataIndex="date_assessment"
                      defaultSortOrder={"descend"}
                      sorter={true}
                      width={"150px"}
                    />
                    <Table.Column
                      title="Report (PDFs)"
                      key="type"
                      dataIndex="type"
                      sorter={true}
                      render={(_, record) => {
                        if (record.status === "Approved") {
                          let disabled = true;
                          if (record.type === "Starter") {
                            if (pdfUrlStarter) {
                              disabled = false;
                            }
                          }
                          if (record.type === "Professional") {
                            if (pdfUrlPro) {
                              disabled = false;
                            }
                          }
                          if (record.type === "Elite") {
                            if (pdfUrlElite) {
                              disabled = false;
                            }
                          }
                          return (
                            <Button
                              type="link"
                              style={{ padding: "0px" }}
                              className={"companyColor3"}
                              onClick={() => handlePdf(record)}
                              disabled={disabled}
                              loading={disabled}
                            >
                              {record.type}
                            </Button>
                          );
                        } else {
                          return record.type;
                        }
                      }}
                    />
                    <Table.Column
                      title="Location"
                      key="location"
                      dataIndex="location"
                      sorter={true}
                    />
                    <Table.Column
                      title="Status"
                      key="status"
                      dataIndex="status"
                      sorter={true}
                    />
                  </Table>
                </div>
              </Collapse.Panel>

              <Collapse.Panel
                header="ABOUT ME"
                key="2"
                className="accordion bg-darkgray-form m-b-md border"
              >
                <div
                  dangerouslySetInnerHTML={{
                    __html:
                      dataAthleteWebsite &&
                      dataAthleteWebsite.user.athlete_info &&
                      dataAthleteWebsite.user.athlete_info.about_me,
                  }}
                />
              </Collapse.Panel>

              <Collapse.Panel
                header="PERSONAL STATS"
                key="3"
                className="accordion bg-darkgray-form m-b-md border"
              >
                <div
                  dangerouslySetInnerHTML={{
                    __html:
                      dataAthleteWebsite &&
                      dataAthleteWebsite.user.athlete_info &&
                      dataAthleteWebsite.user.athlete_info.personal_stats,
                  }}
                />
                {/* {dataAthleteWebsite &&
								dataAthleteWebsite.user.athlete_info &&
								dataAthleteWebsite.user.athlete_info.personal_stats
									? dataAthleteWebsite.user.athlete_info.personal_stats
									: "No Personal Stats"} */}
              </Collapse.Panel>

              <Collapse.Panel
                header="MY VIDEOS"
                key="4"
                className="accordion bg-darkgray-form m-b-md border"
              >
                <div className="my-videos">
                  {dataAthleteWebsite &&
                  dataAthleteWebsite.user.athlete_info &&
                  dataAthleteWebsite.user.athlete_info.video_link ? (
                    <>
                      {dataVideoType === "channel" ? (
                        <>
                          <Carousel>
                            {dataVideo.map((item, key) => {
                              let src = item.replace("watch?v=", "embed/");
                              return (
                                <div
                                  style={{
                                    height: "160px",
                                    color: "#fff",
                                    lineHeight: "160px",
                                    textAlign: "center",
                                    background: "#364d79",
                                  }}
                                >
                                  <iframe
                                    title="video"
                                    style={{ width: "100%" }}
                                    height="723"
                                    src={src}
                                    frameBorder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen
                                  ></iframe>
                                </div>
                              );
                            })}
                          </Carousel>
                        </>
                      ) : (
                        <>
                          <iframe
                            src={dataVideo}
                            title="video"
                            style={{ width: "100%" }}
                            height="723"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                          />
                        </>
                      )}
                    </>
                  ) : (
                    "NO VIDEOS AVAILABLE"
                  )}
                </div>
              </Collapse.Panel>
            </Collapse>
          </Col>
        </Row>

        {!hasWebsite && (
          <Modal visible={true} width={600} footer={null} closable={false}>
            <Row gutter={0}>
              <Col span={24} className="text-center">
                <Typography.Title level={2}>
                  Please edit your athletic profile!
                </Typography.Title>
              </Col>
            </Row>
          </Modal>
        )}
      </Layout.Content>

      <Layout.Footer>
        <div>
          © {process.env.REACT_APP_APP_NAME} {new Date().getFullYear()} Better
          Faster Stronger Science, LLC All Rights Reserved.
          {/* © Copyright 2022 Alright Reserve. -> © 2022 Better Faster Stronger Science, LLC All Rights Reserved. */}
        </div>
      </Layout.Footer>
    </Layout>
  );
}
