import { Col, Collapse, Row, Space, Table } from "antd";
import React, { useState, useEffect } from "react";
import {
  TableGlobalSearch,
  TablePageSize,
  TablePagination,
  TableShowingEntries,
} from "../../../Components/ComponentTableFilter";
import $ from "jquery";
import { Link } from "react-router-dom";
import { GET } from "../../../../../providers/useAxiosQuery";
import companyInfo from "../../../../../providers/companyInfo";

export default function TableGroup({ match, permission }) {
  const { Panel } = Collapse;
  // const history = useHistory();
  const userdata = companyInfo().userData;
  const [tableFilter, setTableFilter] = useState({
    page: 1,
    page_size: 10,
    search: "",
    sort_field: "strdate",
    sort_order: "asc",
    manager_id: userdata.manager_id,
  });

  // console.log(userdata);

  const [tableTotal, setTableTotal] = useState(0);
  const [dataSource, setDataSource] = useState([]);
  const {
    refetch: refetchTable,
    isLoading: isLoadingTable,
    isFetching: isFetchingTable,
  } = GET(
    `api/v1/assessment_events?${$.param(tableFilter)}`,
    "Table_Group",
    (res) => {
      if (res.success) {
        console.log("Table_Group", res);
        setDataSource(res.data && res.data.data);
        setTableTotal(res.data.total);
      }
    }
  );

  const handleTableChange = (pagination, filters, sorter) => {
    setTableFilter({
      ...tableFilter,
      sort_field: sorter.columnKey,
      sort_order: sorter.order ? sorter.order.replace("end", "") : null,
      // page: 1,
    });
  };

  const [searchText, setSearchText] = useState("");
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setTableFilter({
        ...tableFilter,
        search: searchText,
        page: 1,
      });
    }, 1500);
    return () => {
      clearTimeout(timeoutId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText]);

  useEffect(() => {
    refetchTable();
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableFilter]);

  // const handleDelete = (id) => {
  //   let data = {
  //     status: 1,
  //     id: id,
  //   };

  //   mutateDelete(data, {
  //     onSuccess: (res) => {
  //       if (res.success) {
  //         notification.success({
  //           message: "Success",
  //           description: "Successfully deleted.",
  //         });
  //       }
  //     },
  //   });
  // };

  // const { mutate: mutateDelete, isLoading: isLoadingDelete } = UPDATE(
  //   "api/v1/athlete_assessment",
  //   "Table_Group"
  // );

  // const [toggleModalPreview, setToggleModalPreview] = useState({
  //   show: false,
  //   data: null,
  // });

  return (
    <>
      <br />
      <Collapse
        className="main-1-collapse"
        expandIcon={({ isActive }) =>
          isActive ? (
            <span
              className="ant-menu-submenu-arrow"
              style={{ color: "#FFF", transform: "rotate(270deg)" }}
            ></span>
          ) : (
            <span
              className="ant-menu-submenu-arrow"
              style={{ color: "#FFF", transform: "rotate(90deg)" }}
            ></span>
          )
        }
        defaultActiveKey={["1"]}
        expandIconPosition="start"
      >
        <Panel header="Events" key="1" className="accordion bg-darkgray-form">
          <Row gutter={12}>
            <Col xs={24} sm={24} md={16} lg={16} xl={18} className="m-b-sm">
              <Space>
                <TablePageSize
                  paginationFilter={tableFilter}
                  setPaginationFilter={setTableFilter}
                />
              </Space>
              <br />
            </Col>
            <Col xs={24} sm={24} md={8} lg={8} xl={6}>
              <TableGlobalSearch
                paginationFilter={searchText}
                setPaginationFilter={setSearchText}
              />
              <br />
            </Col>
          </Row>
          <Row gutter={12}>
            <Col xs={24} sm={24} md={24} className="m-t-sm m-b-sm">
              <Table
                className="ant-table-default ant-table-striped"
                dataSource={dataSource && dataSource}
                loading={isLoadingTable || isFetchingTable}
                rowKey={(record) => record.id}
                pagination={false}
                bordered={false}
                onChange={handleTableChange}
                scroll={{ x: "max-content" }}
              >
                <Table.Column
                  title="Date"
                  key="strdate"
                  dataIndex="strdate"
                  defaultSortOrder="ascend"
                  sorter={true}
                  width={"300px"}
                />

                <Table.Column
                  title="Event Name"
                  key="title"
                  dataIndex="title"
                  sorter={true}
                  render={(text, record) => {
                    return (
                      <Link
                        to={`/dashboard/event/${record.id}/${text}`}
                        className="companycolor1 cursor"
                        // onClick={() =>
                        //   setToggleModalPreview({ show: true, data: record })
                        // }
                      >
                        {text}
                      </Link>
                    );
                  }}
                  width={"250px"}
                />

                <Table.Column
                  title="Location"
                  key="location"
                  dataIndex="location"
                  sorter={true}
                  width={"250px"}
                />

                <Table.Column
                  title="Attendees"
                  key="attendees"
                  dataIndex="attendees"
                  sorter={true}
                  width={"150px"}
                />
              </Table>
            </Col>
            <Col xs={24} sm={24} md={24}>
              <Space className="ant-space-flex-space-between table1">
                <TableShowingEntries />

                <TablePagination
                  className="table-right"
                  paginationFilter={tableFilter}
                  setPaginationFilter={setTableFilter}
                  setPaginationTotal={tableTotal}
                  showLessItems={true}
                  showSizeChanger={false}
                  parentClass="table1"
                />
              </Space>
            </Col>
          </Row>
        </Panel>
      </Collapse>
    </>
  );
}
